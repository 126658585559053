import * as actions from "./actions"
import { createSlice } from "@reduxjs/toolkit"
import { setOrgCase, setOrgsCase, deleteOrgCase } from "./case-reducers"
import { IOrgNormalized } from "../interfaces/org-normalized"

const initialState: OrganisationsState = {
  byIds: {},
  allIds: [],
}

const organisationsSlice = createSlice({
  name: "organisations",
  initialState,
  reducers: {
    setOrgs: setOrgsCase,
    setOrg: setOrgCase,
  },
  extraReducers: (builder) => {
    builder.addCase(actions.CreateOrganisation.fulfilled, setOrgCase)
    builder.addCase(actions.FetchOrganisation.fulfilled, setOrgCase)
    builder.addCase(actions.FetchOrganisations.fulfilled, setOrgsCase)
    builder.addCase(actions.UpdateOrganisationInfo.fulfilled, setOrgCase)
    builder.addCase(actions.DeleteOrganisation.fulfilled, deleteOrgCase)
  },
})

export interface OrganisationsState {
  byIds: {
    [id: string]: IOrgNormalized
  }
  allIds: string[]
}

export default organisationsSlice.reducer
