import { useSnackbar } from "notistack"
import { useI18n } from "../../hooks"
import * as holidayApi from "../api"
import { HolidayViewModel } from "../api/holiday"
import { TreeGridHoliday } from "../../treegrid/holiday-list/types"
import { HolidayType } from "../api/holiday-response"

export const useHolidayMutations = () => {
  const translations = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const [createHolidayMutation] = holidayApi.useCreateHolidayMutation()
  const [deleteHolidayMutation] = holidayApi.useDeleteHolidayMutation()
  const [updateHolidayMutation] = holidayApi.useUpdateHolidayMutation()

  const handleError = (error: any) => {
    const errorMessage = "error" in error ? error.error : "message" in error ? error.message : "Unknown error"
    enqueueSnackbar(errorMessage, { variant: "error" })
  }

  const createHoliday = (newHolidayData: holidayApi.NewHolidayData) => {
    return createHolidayMutation(newHolidayData)
      .unwrap()
      .then((createdHoliday) => {
        enqueueSnackbar(translations.holidayCreationSuccessful, { variant: "success" })
        return createdHoliday
      })
      .catch(() => handleError({ message: translations.holidayCreationFailed }))
  }

  const updateHoliday = (holidayId: string, updateData: holidayApi.UpdateHolidayData) => {
    return updateHolidayMutation({ holidayId, ...updateData })
      .unwrap()
      .then((updatedHoliday) => updatedHoliday)
      .catch(handleError)
  }

  const deleteHoliday = (holidayId: string) => {
    return deleteHolidayMutation({ holidayId })
      .unwrap()
      .then(() => {
        enqueueSnackbar(translations.holidayDeletionSuccessful, { variant: "success" })
        return true
      })
      .catch(() => handleError({ message: translations.holidayDeletionFailed }))
  }

  const intiHolidayUpdate = async (
    row: TRow & TreeGridHoliday,
    updateData: IntiHolidayUpdateData
  ): Promise<void | HolidayViewModel> => {
    const holidayId = row.id
    const holidayType = row.type as HolidayType
    const isSingleHoliday = holidayType === "Single"

    switch (updateData.field) {
      case "name":
        return updateHoliday(holidayId, { [updateData.field]: updateData.value })
      case "date":
        const date = new Date(updateData.value)
        return updateHoliday(holidayId, { date })
      case "startDate":
        const startDate = new Date(updateData.value)
        if (isSingleHoliday) {
          return updateHoliday(holidayId, { date: startDate })
        } else {
          return updateHoliday(holidayId, { startDate })
        }
      case "endDate":
        const endDate = updateData.value ? new Date(updateData.value) : null
        return updateHoliday(holidayId, { endDate })
      case "isRecurring":
        const recurring = Boolean(updateData.value)
        return updateHoliday(holidayId, { recurring })
      default:
        // @ts-expect-error
        const error = `Field ${updateData.field} can not be updated via initHolidayUpdate`
        // eslint-disable-next-line no-console
        console.error(error)
        return
    }
  }

  return {
    createHoliday,
    updateHoliday,
    deleteHoliday,
    intiHolidayUpdate,
  }
}

const defaultTranslations = {
  holidayCreationSuccessful: "Holiday created successfully",
  holidayCreationFailed: "Failed to create holiday",
  holidayDeletionSuccessful: "Holiday deleted successfully",
  holidayDeletionFailed: "Failed to delete holiday",
}

const useTranslations = (defaults = defaultTranslations) => {
  const { t } = useI18n("translation")

  return {
    holidayCreationSuccessful: t("holidayCreationSuccessful", defaults.holidayCreationSuccessful),
    holidayCreationFailed: t("holidayCreationFailed", defaults.holidayCreationFailed),
    holidayDeletionSuccessful: t("holidayDeletionSuccessful", defaults.holidayDeletionSuccessful),
    holidayDeletionFailed: t("holidayDeletionFailed", defaults.holidayDeletionFailed),
  }
}

type IntiHolidayUpdateData =
  | {
      field: "name" | "startDate" | "endDate" | "date"
      value: string
    }
  | {
      field: "isRecurring"
      value: boolean
    }
