import { RouteComponentProps } from "react-router-dom"
import { useTask } from "../../../tasks/hooks/use-task"
import { NewTodoData, useGetTodosQuery } from "../../../todos/api"
import { useDateFormat } from "../../../users/hooks/use-date-format"
import { useUserTimeZone } from "../../../hooks/use-user-time-zone"
import { useOrgOptions } from "../../../options/hooks/use-org-options"
import { useTodoMutations } from "../../../todos/hooks/use-todo-mutations"
import CircularProgress from "@material-ui/core/CircularProgress"
import TodoList from "../../../treegrid/todo-list/TodoList"
import { useI18n } from "../../../hooks"
import { StringMap } from "i18next"
import ErrorList from "../../../components/ErrorList"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { useIsSmallScreen } from "../../../hooks/use-is-small-screen"
import NewTodoDialog from "../../../todos/components/NewTodoDialog"
import TodoListMobile from "../../../todos/components/TodoListMobile"
import TodoListEmpty from "../../../todos/components/TodoListEmpty"
import { useTheme } from "@material-ui/core"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TaskTodosRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const isSmallScreen = useIsSmallScreen()
  const translations = useTranslations()
  const { taskId } = match.params
  const { task, isLoading: isLoadingTask, isError: isErrorTask } = useTask(taskId, queryOptions)

  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
  } = useGetTodosQuery({ task: taskId }, queryOptions)
  const { dateFormat, dateSeparator } = useDateFormat()
  const userTimeZone = useUserTimeZone()
  const { options } = useOrgOptions(task?.maintainerId || "")
  const todoActions = useTodoMutations()
  const theme = useTheme()

  if (isLoadingTask || isLoadingTodos) return <CircularProgress />
  if (isErrorTask) return <ErrorList errors={[translations.fetchErrorTask]} />
  if (isErrorTodos) return <ErrorList errors={[translations.fetchErrorTodos]} />
  if (!task) return <ErrorList errors={[translations.taskNotFoundError]} />

  const onAddTodo = (todoData: NewTodoData) => todoActions.createTodo({ ...todoData, task: taskId })
  const canAddTodos = task.canCreateTaskTodos && !task.archived

  if (isSmallScreen) {
    return (
      <div
        style={{
          marginTop: theme.spacing(2),
          borderTop: `1px solid ${theme.palette.divider}`,
          paddingTop: theme.spacing(2),
        }}
      >
        <Grid container style={{ marginBottom: theme.spacing(2) }}>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography variant="h5">
              {translations.task} {translations.todos.toLowerCase()}
            </Typography>
          </Grid>
          {todos.length ? (
            <Grid item xs={4} container justifyContent="flex-end" style={{ marginRight: theme.spacing(0.5) }}>
              <NewTodoDialog canAddTodos={canAddTodos} onAddTodo={onAddTodo} />
            </Grid>
          ) : null}
        </Grid>
        {todos.length ? (
          <TodoListMobile todos={todos} />
        ) : (
          <TodoListEmpty canAddTodos={canAddTodos} onAddTodo={onAddTodo} />
        )}
      </div>
    )
  }

  return (
    <TodoList
      gridInfo={`${task?.title} - Todos`}
      dateFormat={dateFormat}
      dateSeparator={dateSeparator}
      firstDayOfWeek={task.firstDayOfWeek}
      timeZone={userTimeZone}
      options={options}
      todos={todos}
      canCreateTodos={task.canCreateTaskTodos}
      onAddTodo={onAddTodo}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = t || ({} as StringMap)
  const todos = translations?.todosViewHeader?.pageTitle

  const {
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorTask = defaults.fetchErrorTask,
    taskNotFoundError = defaults.taskNotFoundError,
    task = defaults.task,
  } = translations

  return {
    todos,
    task,
    fetchErrorTask,
    fetchErrorTodos,
    taskNotFoundError,
  }
}

const defaultTranslations = {
  fetchErrorTask: "Failed to fetch task",
  fetchErrorTodos: "Failed to fetch todos",
  taskNotFoundError: "Task not found",
  task: "Task",
  todos: "To-dos",
}
type Translations = typeof defaultTranslations
type RouteParams = { taskId: string }
export default TaskTodosRoute
