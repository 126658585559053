import React from "react"
import { useLanguage } from "../i18n/use-language"
import { TurnstileInstance, TurnstileProps } from "../components/Turnstile"

const apiBaseUrl = process.env.REACT_APP_API_URL
const turnstileVerificationEndpoint = `${apiBaseUrl}/turnstile-token`
const turnstileSiteKey = process.env.REACT_APP_TURNSTILE_SITE_KEY

if (!turnstileSiteKey) {
  throw new Error("TURNSTILE_SITE_KEY is not set")
}

if (!apiBaseUrl) {
  throw new Error("API_URL is not set")
}

const initialTurnstileState = {
  widgetId: null as string | null,
  token: null as string | null,
  status: undefined as TurnstileStatus | undefined,
  error: null as string | null,
}

export const useTurnstile = () => {
  const ref = React.useRef<TurnstileInstance>(null)
  const [turnstile, setTurnstile] = React.useState(initialTurnstileState)
  const language = useLanguage()

  const isSolved = turnstile.status === "solved"

  const onSuccess = (token: string) => {
    setTurnstile((prev) => ({ ...prev, token, status: "solved", error: null }))
  }

  const onExpire = () => {
    setTurnstile((prev) => ({ ...prev, status: "expired", error: null }))
    ref.current?.reset()
  }

  const onWidgetLoad = (widgetId: string) => {
    setTurnstile((prev) => ({ ...prev, widgetId }))
  }

  const verifyToken = async (): Promise<boolean> => {
    if (!turnstile.widgetId || !turnstile.token || !isSolved) return false
    const vefificationResponse = await fetch(turnstileVerificationEndpoint, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token: turnstile.token }),
    }).then((response) => response.json())

    if (!vefificationResponse.success) {
      setTurnstile((prev) => ({ ...prev, token: null, error: vefificationResponse.error, status: "error" }))
      ref.current?.reset()
      return false
    }
    return vefificationResponse.success
  }

  const props: TurnstileProps & {
    ref: React.RefObject<TurnstileInstance>
  } = {
    ref,
    onSuccess,
    onExpire,
    onWidgetLoad,
    siteKey: turnstileSiteKey,
    options: {
      theme: "light",
      language,
    },
  }

  return {
    ...turnstile,
    isSolved,
    verifyToken,
    props,
  }
}

type TurnstileStatus = "solved" | "expired" | "error"
