import { api } from "../../api"
import { makeUrlSearchParams } from "../../utils/url"
import { makeLinkApiEndpoints } from "./endpoints"
import { LinkViewModel, makeLinkViewModel } from "./link"
import { LinkResponse } from "./link-response"

const apiEndpoints = makeLinkApiEndpoints()

export const linkApi = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getLinks: builder.query<LinkViewModel[], GetLinksQuery>({
        query: (params) => {
          return {
            method: "GET",
            url: apiEndpoints.links(),
            params: params ? makeUrlSearchParams(params) : undefined,
          }
        },
        transformResponse: (response: LinkResponse[]) => response.map(makeLinkViewModel),
        providesTags: ["Link"],
      }),
      createLink: builder.mutation<void, NewLinkData>({
        query: (linkData) => {
          return {
            method: "POST",
            url: apiEndpoints.links(),
            body: linkData,
          }
        },
        invalidatesTags: ["Link"],
      }),
      updateLink: builder.mutation<void, { linkId: string } & LinkUpdateData>({
        query: ({ linkId, ...updateData }) => {
          return {
            method: "PUT",
            url: apiEndpoints.linkItem(linkId),
            body: updateData,
          }
        },
        invalidatesTags: ["Link"],
      }),
      deleteLink: builder.mutation<void, { linkId: string }>({
        query: ({ linkId }) => {
          return {
            method: "DELETE",
            url: apiEndpoints.linkItem(linkId),
          }
        },
        invalidatesTags: ["Link"],
      }),
    }
  },
})

export const { useGetLinksQuery, useCreateLinkMutation, useUpdateLinkMutation, useDeleteLinkMutation } = linkApi

type GetLinksQuery = {
  entityId: string
  entityType: "task" | "project"
}

export type NewLinkData = {
  entityId: string
  entityType: "task" | "project"
  url: string
  title: string
}

export type LinkUpdateData = {
  title?: string
  url?: string
}
