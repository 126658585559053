import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import NotificationSettingItem from "../../components/NotificationSettingItem"
import { Theme, makeStyles } from "@material-ui/core"
import { useGetUserInfoQuery } from "../api"
import { useUserMutations } from "../hooks/use-user-mutations"
import { useI18n } from "../../hooks"
import { NotificationChannel, NotificationType } from "../../notifications/notifications"

const UserNotificationSettings = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const { data: user, isLoading } = useGetUserInfoQuery()
  const { enableNotification, disableNotification } = useUserMutations()

  if (isLoading || !user) return <CircularProgress />

  const { notificationSettings } = user

  const onEnableNotification = (type: NotificationType, channel: NotificationChannel) => {
    enableNotification(user.id, type, channel)
  }
  const onDisableNotification = (type: NotificationType, channel: NotificationChannel) => {
    disableNotification(user.id, type, channel)
  }

  const isProjectOverdueEmailNotificationEnabled = notificationSettings.ProjectOverdue?.includes("email")
  const isProjectNotStartedEmailNotificationEnabled = notificationSettings.ProjectNotStarted?.includes("email")
  const isAssignedToProjectEmailNotificationEnabled = notificationSettings.AssignedToProject?.includes("email")
  const isTaskOverdueEmailNotificationEnabled = notificationSettings.TaskOverdue?.includes("email")
  const isTaskNotStartedEmailNotificationEnabled = notificationSettings.TaskNotStarted?.includes("email")
  const isAssignedToTaskEmailNotificationEnabled = notificationSettings.AssignedToTask?.includes("email")
  const isTodoOverdueEmailNotificationEnabled = notificationSettings.TodoOverdue?.includes("email")
  const isAssignedToTodoEmailNotificationEnabled = notificationSettings.AssignedToTodo?.includes("email")

  return (
    <div className={classes.container}>
      <Typography variant="h6" component="h5" className={classes.header}>
        {translations.notificationSettings}
      </Typography>
      <section className={classes.section}>
        <Typography variant="subtitle1" component="h6" className="sectionHeader">
          {translations.projectNotifications}
        </Typography>
        <NotificationSettingItem
          label={translations.projectOverdueLabel}
          description={translations.projectOverdueDescription}
          enabled={isProjectOverdueEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("ProjectOverdue", "email")}
          onDisableNotification={() => onDisableNotification("ProjectOverdue", "email")}
        />
        <NotificationSettingItem
          label={translations.projectNotStartedLabel}
          description={translations.projectNotStartedDescription}
          enabled={isProjectNotStartedEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("ProjectNotStarted", "email")}
          onDisableNotification={() => onDisableNotification("ProjectNotStarted", "email")}
        />
        <NotificationSettingItem
          label={translations.assignedToProjectLabel}
          description={translations.assignedToProjectDescription}
          enabled={isAssignedToProjectEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("AssignedToProject", "email")}
          onDisableNotification={() => onDisableNotification("AssignedToProject", "email")}
        />
      </section>
      <section className={classes.section}>
        <Typography variant="subtitle1" component="h6" className="sectionHeader">
          {translations.taskNotifications}
        </Typography>
        <NotificationSettingItem
          label={translations.taskOverdueLabel}
          description={translations.taskOverdueDescription}
          enabled={isTaskOverdueEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("TaskOverdue", "email")}
          onDisableNotification={() => onDisableNotification("TaskOverdue", "email")}
        />
        <NotificationSettingItem
          label={translations.taskNotStartedLabel}
          description={translations.taskNotStartedDescription}
          enabled={isTaskNotStartedEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("TaskNotStarted", "email")}
          onDisableNotification={() => onDisableNotification("TaskNotStarted", "email")}
        />
        <NotificationSettingItem
          label={translations.assignedToTaskLabel}
          description={translations.assignedToTaskDescription}
          enabled={isAssignedToTaskEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("AssignedToTask", "email")}
          onDisableNotification={() => onDisableNotification("AssignedToTask", "email")}
        />
      </section>
      <section className={classes.section}>
        <Typography variant="subtitle1" component="h6" className="sectionHeader">
          {translations.todoNotifications}
        </Typography>
        <NotificationSettingItem
          label={translations.todoOverdueLabel}
          description={translations.todoOverdueDescription}
          enabled={isTodoOverdueEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("TodoOverdue", "email")}
          onDisableNotification={() => onDisableNotification("TodoOverdue", "email")}
        />
        <NotificationSettingItem
          label={translations.assignedToTodoLabel}
          description={translations.assignedToTodoDescription}
          enabled={isAssignedToTodoEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("AssignedToTodo", "email")}
          onDisableNotification={() => onDisableNotification("AssignedToTodo", "email")}
        />
      </section>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(4),
  },
  section: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    "& .sectionHeader": {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(2),
      fontSize: 18,
    },
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations = {} } = useI18n("translation")

  return {
    notificationSettings: translate("notificationSettings"),
    projectNotifications: translate("projectNotifications"),
    taskNotifications: translate("taskNotifications"),
    todoNotifications: translate("todoNotifications"),
    projectOverdueLabel: translate("projectOverdueLabel"),
    projectOverdueDescription: translate("projectOverdueDescription"),
    projectNotStartedLabel: translate("projectNotStartedLabel"),
    projectNotStartedDescription: translate("projectNotStartedDescription"),
    assignedToProjectLabel: translate("assignedToProjectLabel"),
    assignedToProjectDescription: translate("assignedToProjectDescription"),
    taskOverdueLabel: translate("taskOverdueLabel"),
    taskOverdueDescription: translate("taskOverdueDescription"),
    taskNotStartedLabel: translate("taskNotStartedLabel"),
    taskNotStartedDescription: translate("taskNotStartedDescription"),
    assignedToTaskLabel: translate("assignedToTaskLabel"),
    assignedToTaskDescription: translate("assignedToTaskDescription"),
    todoOverdueLabel: translate("todoOverdueLabel"),
    todoOverdueDescription: translate("todoOverdueDescription"),
    assignedToTodoLabel: translate("assignedToTodoLabel"),
    assignedToTodoDescription: translate("assignedToTodoDescription"),
  }

  function translate(key: keyof Translations): string {
    return translations[key] || defaults[key]
  }
}

const defaultTranslations = {
  notificationSettings: "Notification settings",
  projectNotifications: "Project notifications",
  taskNotifications: "Task notifications",
  todoNotifications: "To-do notifications",
  projectOverdueLabel: "Enable project overdue notifications",
  projectOverdueDescription: "You will receive an email when a project you are managing is not completed  on time.",
  projectNotStartedLabel: "Enable project not started notifications",
  projectNotStartedDescription:
    "You will receive an email when a project you are managing has not been started on time.",
  assignedToProjectLabel: "Enable added to project notifications",
  assignedToProjectDescription: "You will receive an email when you are added as a manager to a project.",
  taskOverdueLabel: "Enable task overdue notifications",
  taskOverdueDescription: "You will receive an email when a task you are managing is not completed on time.",
  taskNotStartedLabel: "Enable task not started notifications",
  taskNotStartedDescription: "You will receive an email when a task you are managing has not been started on time.",
  assignedToTaskLabel: "Enable added to task notifications",
  assignedToTaskDescription: "You will receive an email when you are added as a manager to a task.",
  todoOverdueLabel: "Enable to-do overdue notifications",
  todoOverdueDescription: "You will receive an email when a to-do you are managing is not completed on time.",
  assignedToTodoLabel: "Enable added to to-do notifications",
  assignedToTodoDescription: "You will receive an email when you are added as a responsible to a to-do.",
}

type Translations = typeof defaultTranslations

export default UserNotificationSettings
