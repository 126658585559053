import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import TasksViewSelect from "./TasksViewSelect"

import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"
import { Theme, useMediaQuery } from "@material-ui/core"

const TasksViewHeader = ({ titlePrefix, showArchivedTitle = false }: TasksViewHeaderProps) => {
  const translations = useTranslations()
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))

  if (isMobile) {
    return <TasksViewSelect />
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h6">
          {titlePrefix && (
            <Box component="span" color="text.secondary">
              {`${titlePrefix} - `}
            </Box>
          )}
          {!showArchivedTitle && <Box component="span"> {translations.pageTitle} </Box>}
          {showArchivedTitle && (
            <Box component="span" style={{ color: "red" }}>
              {translations.archivedPageTitle}
            </Box>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <Grid spacing={1} container>
          <Grid item>
            <TasksViewSelect />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t?.tasksViewHeader || {}) as StringMap

  const {
    pageTitle = defaults.pageTitle,
    showFiltersButtonLabel = defaults.showFiltersButtonLabel,
    archivedPageTitle = defaults.archivedPageTitle,
  } = translations

  return {
    pageTitle,
    showFiltersButtonLabel,
    archivedPageTitle,
  }
}

const defaultTranslations = {
  pageTitle: "Tasks",
  showFiltersButtonLabel: "Filters",
  archivedPageTitle: "Archived tasks",
}

type Translations = typeof defaultTranslations

type TasksViewHeaderProps = {
  titlePrefix?: string
  showArchivedTitle?: boolean
}

export default TasksViewHeader
