import { TimeZoneType } from "../../constants/timezones"
import { DateFormatOptionType, DateFormatSeparatorOptionType } from "../../organisations/constants"
import { AccountStatus, UserAvatar } from "../../types/common"
import { Language } from "../../utils/languages"
import { IUserResponse } from "./user-response"

export const normalizeUser = (user: IUserResponse): IUserNormalized => {
  const isActive = user.accountStatus === AccountStatus.ACTIVE
  const isWaitingVerification = user.accountStatus === AccountStatus.WAITING_VERIFICATION
  const isDeactivated = user.accountStatus === AccountStatus.DEACTIVATED

  return {
    ...user,
    isActive,
    isWaitingVerification,
    isDeactivated,
  }
}

export interface IUserNormalized {
  id: string
  schemaVersion: number
  createdAt: string
  updatedAt: string
  firstname: string
  lastname: string
  fullname: string
  initials: string
  email: string
  accountStatus: AccountStatus
  avatarURL: string
  userAvatar: UserAvatar
  weekendDays: DayOfWeek[]
  firstDayOfWeek: DayOfWeek
  timeZone: TimeZoneType
  dateFormatSeparator: DateFormatSeparatorOptionType
  dateFormat: DateFormatOptionType
  isActive: boolean
  isWaitingVerification: boolean
  isDeactivated: boolean
  language: Language
}
