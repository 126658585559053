import Typography from "@material-ui/core/Typography"
import WorkspaceStatusWidget from "../components/WorkspaceStatusWidget"
import WorkspaceNameEditor from "../components/WorkspaceNameEditor"

import { Grid, Theme, makeStyles } from "@material-ui/core"
import { useWorkspace } from "../hooks/use-workspace"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"

const WorkspaceSettingsView = ({ workspaceId }: { workspaceId: string }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { workspace } = useWorkspace(workspaceId)

  if (!workspace) {
    return <p>{translations.workspaceNotFoundMessage}</p>
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid item className={classes.item}>
        <Typography variant="h5" color="primary" data-test="page-header">
          {translations.pageTitle}
        </Typography>
      </Grid>
      <Grid item className={classes.item}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="subtitle1">{translations.workspaceNameLabel}</Typography>
          </Grid>
          <Grid item>
            <WorkspaceNameEditor workspace={workspace} disabled={!workspace.canUpdate} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.item}>
        <WorkspaceStatusWidget workspace={workspace} />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 1200,
    background: theme.palette.common.white,
    margin: theme.spacing(8, "auto"),
    borderRadius: 5,
    border: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(4, 0),
    },
  },
  item: {
    width: "100%",
    padding: theme.spacing(4, 9),
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&:last-child": {
      borderBottom: "1px solid transparent",
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 2),
    },
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("workspace")
  const translations = (t?.workspaceSettingsPage || {}) as StringMap

  const {
    pageTitle = defaults.pageTitle,
    workspaceNameLabel = defaults.workspaceNameLabel,
    workspaceNotFoundMessage = defaults.workspaceNotFoundMessage,
  } = translations

  return {
    pageTitle,
    workspaceNameLabel,
    workspaceNotFoundMessage,
  }
}

const defaultTranslations = {
  pageTitle: "Workspace settings",
  workspaceNameLabel: "Workspace name",
  workspaceNotFoundMessage: "Workspace not found",
}

type Translations = typeof defaultTranslations
export default WorkspaceSettingsView
