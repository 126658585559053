import clsx from "clsx"
import Button from "@material-ui/core/Button"
import { alpha, makeStyles, Theme } from "@material-ui/core"
import { COLOR_LIGHT } from "../constants"
import { useI18n } from "../hooks"
import { StringMap } from "i18next"

const StateSelect = ({ state, onStateChange, disabled = false }: StateSelectProps) => {
  const isNotReported = state === "Not reported"
  const isGreen = state === "Green"
  const isYellow = state === "Yellow"
  const isRed = state === "Red"
  const translations = useTranslations(defaultTranslations)
  const classes = useStyles()
  return (
    <>
      <div className={classes.root}>
        <Button
          className={clsx(isNotReported && "not_reported")}
          onClick={() => onStateChange("Not reported")}
          disabled={disabled}
        >
          {translations.notReported}
        </Button>
        <Button className={clsx(isGreen && "green")} onClick={() => onStateChange("Green")} disabled={disabled}>
          <span className="material-symbols-rounded">sentiment_satisfied</span>
          {translations.green}
        </Button>
        <Button className={clsx(isYellow && "yellow")} onClick={() => onStateChange("Yellow")} disabled={disabled}>
          <span className="material-symbols-rounded">sentiment_neutral</span>
          {translations.yellow}
        </Button>
        <Button className={clsx(isRed && "red")} onClick={() => onStateChange("Red")} disabled={disabled}>
          <span className="material-symbols-rounded">sentiment_dissatisfied</span>
          {translations.red}
        </Button>
      </div>
    </>
  )
}
const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      display: "flex",
      gap: theme.spacing(1),
      marginTop: theme.spacing(1),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: theme.spacing(1),
        "& button": {
          width: "100%",
          padding: 0,
          maxHeight: 36,
          "& .material-symbols-rounded": {
            fontSize: 20,
          },
        },
      },
      "& button": {
        fontWeight: theme.typography.body1.fontWeight,
        letterSpacing: theme.typography.body1.letterSpacing,
        fontSize: theme.typography.body1.fontSize,
        padding: theme.spacing(1, 2),
        border: `2px solid ${COLOR_LIGHT}`,
        "&.not_reported": {
          backgroundColor: alpha(theme.palette.info.main, 0.1),
          borderColor: theme.palette.info.main,
        },
        "&.green": {
          backgroundColor: alpha(theme.palette.secondary.main, 0.2),
          borderColor: theme.palette.secondary.main,
        },
        "&.yellow": {
          backgroundColor: alpha(theme.palette.warning.main, 0.2),
          borderColor: theme.palette.warning.main,
        },
        "&.red": {
          backgroundColor: alpha(theme.palette.error.main, 0.2),
          borderColor: theme.palette.error.main,
        },
        "& .material-symbols-rounded": {
          marginRight: theme.spacing(),
        },
        "&.Mui-disabled": {
          color: "unset",
        },
      },
    },
  }
})
const useTranslations = (defaults = defaultTranslations): typeof defaultTranslations => {
  const { translations: t } = useI18n("project")
  const translations = (t?.projectState || {}) as StringMap
  const {
    notReported = defaults.notReported,
    green = defaults.green,
    yellow = defaults.yellow,
    red = defaults.red,
  } = translations
  return {
    notReported,
    green,
    yellow,
    red,
  }
}
const defaultTranslations = {
  notReported: "Not reported",
  green: "Very good",
  yellow: "Good",
  red: "Not good",
}

type StateType = "Not reported" | "Green" | "Yellow" | "Red"

type StateSelectProps = {
  state: StateType
  onStateChange: (state: StateType) => void
  disabled?: boolean
}
export default StateSelect
