import React from "react"
import { AbilitiesByResource } from "../../../organisations/interfaces/role-response"
import { useAppDispatch } from "../../../store"
import { FetchRoles } from "../../../roles/store/actions"
import { entries } from "lodash"
import { useOrgRoles } from "../../../organisations/hooks/use-org-roles"

// This hook is used to manage the state of abilities table in the membership form
// It is responsible for following:
// 1. It fetches the roles from the server when the component is mounted
// 2. When the type of new member changes to either limited or standard member, it sets groupedAbilities state to the abilities of the role of the new member type
// 3. When user makes some changes in abilties of new member via the AbilitiesTable (in the UI), it synchronizes those changes with the groupedAbilities state

export const useNewMemberAbilities = (props: UseAbilitiesTableProps) => {
  const dispatch = useAppDispatch()
  const { orgId, isLimitedMember, isStandardMember, abilitiesToAdd, abilitiesToRemove } = props
  const [groupedAbilities, setGroupedAbilities] = React.useState<GroupedAbilities>([])
  const { memberRole, limitedMemberRole } = useOrgRoles(orgId)

  const fetchRoles = () => {
    dispatch(FetchRoles(orgId))
  }

  const syncGroupedAbilitiesWithRoleAbilities = () => {
    const shouldSyncToStandardMember = isStandardMember && memberRole
    const shouldSyncToLimitedMember = isLimitedMember && limitedMemberRole

    if (shouldSyncToStandardMember) setGroupedAbilities(memberRole.abilitiesByResource)
    if (shouldSyncToLimitedMember) setGroupedAbilities(limitedMemberRole.abilitiesByResource)
  }

  const syncGroupedAbilitiesWithAbilitiesToAddAndRemove = () => {
    const nextGroupedAbilities = getUpdatedGroupedAbilities(groupedAbilities, abilitiesToAdd, abilitiesToRemove)
    setGroupedAbilities(nextGroupedAbilities)
  }

  React.useEffect(fetchRoles, [dispatch, orgId])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(syncGroupedAbilitiesWithRoleAbilities, [isLimitedMember, isStandardMember])
  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(syncGroupedAbilitiesWithAbilitiesToAddAndRemove, [abilitiesToAdd, abilitiesToRemove])

  return {
    groupedAbilities,
  }
}

type UseAbilitiesTableProps = {
  orgId: string
  isLimitedMember: boolean
  isStandardMember: boolean
  abilitiesToAdd: string[]
  abilitiesToRemove: string[]
}

const getUpdatedGroupedAbilities = (
  groupedAbilities: GroupedAbilities,
  abilitiesToAdd: string[],
  abilitiesToRemove: string[]
): GroupedAbilities => {
  return groupedAbilities.map((group) => {
    const newAbilities: { [k in string]: boolean } = {}
    const abilities = entries(group.abilities) as Entries<typeof group.abilities>

    abilities.forEach(([ability]) => {
      if (abilitiesToAdd.includes(ability)) newAbilities[ability] = true
      if (abilitiesToRemove.includes(ability)) newAbilities[ability] = false
    })

    return {
      ...group,
      abilities: { ...group.abilities, ...newAbilities },
    }
  })
}

type Entries<T> = {
  [K in keyof T]: [K, T[K]]
}[keyof T][]

type GroupedAbilities = AbilitiesByResource[]
