import { TaskViewModel } from "../api/task"
import { sortTasksByPlan } from "../utils/sort"
import Stack from "../../components/Stack"
import TaskCardMobile from "./TaskCardMobile"
import TaskEmptyMessage from "./TasksEmptyMessage"

const TaskCards = ({ tasks }: { tasks: TaskViewModel[] }) => {
  if (tasks.length) {
    const sortedTasks = sortTasksByPlan(tasks)

    return (
      <Stack spacing={1}>
        {sortedTasks.map((task) => (
          <TaskCardMobile key={task.id} task={task} />
        ))}
      </Stack>
    )
  }

  return <TaskEmptyMessage />
}

export default TaskCards
