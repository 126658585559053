import { addDays } from "date-fns"
import { RangeBaseHolidaySchema, SingleBaseHolidaySchema } from "../api/holiday-response"
import { invariant } from "../../utils/invariant"
import { z } from "zod"
import { HolidayViewModel } from "../api/holiday"

const NewSingleHolidayDataSchema = SingleBaseHolidaySchema.omit({ id: true, createdAt: true, updatedAt: true })
const NewRangeHolidayDataSchema = RangeBaseHolidaySchema.omit({ id: true, createdAt: true, updatedAt: true })

export const mapSingleToRangeHoliday = (singleHolidayData: unknown, endDate?: Date): NewRangeHolidayData => {
  const parseResult = NewSingleHolidayDataSchema.safeParse(singleHolidayData)
  invariant(parseResult.success, "Invalid single holiday data")

  const guessedEndDate = addDays(parseResult.data.date, 1)
  return {
    ...parseResult.data,
    type: "Range",
    startDate: parseResult.data.date,
    endDate: endDate || guessedEndDate,
    date: null,
  }
}

export const mapRangeToSingleHoliday = (rangeHolidayData: unknown): NewSingleHolidayData => {
  const parseResult = NewRangeHolidayDataSchema.safeParse(rangeHolidayData)
  invariant(parseResult.success, "Invalid range holiday data")

  return {
    ...parseResult.data,
    type: "Single",
    date: parseResult.data.startDate,
    startDate: null,
    endDate: null,
  }
}

export const mapHolidayViewModelToFormData = (holiday: HolidayViewModel): HolidayFormData => {
  if (holiday.isSingleHoliday) {
    invariant(holiday.date, "Single holiday must have a date")
    return {
      name: holiday.name,
      type: "Single",
      date: holiday.date,
      startDate: null,
      endDate: null,
      recurring: holiday.recurring,
    }
  }

  invariant(holiday.startDate, "Range holiday must have a start date")
  invariant(holiday.endDate, "Range holiday must have an end date")
  return {
    name: holiday.name,
    type: "Range",
    date: null,
    startDate: holiday.startDate,
    endDate: holiday.endDate,
    recurring: holiday.recurring,
  }
}

type NewSingleHolidayData = z.infer<typeof NewSingleHolidayDataSchema>
type NewRangeHolidayData = z.infer<typeof NewRangeHolidayDataSchema>
export type HolidayFormData = NewSingleHolidayData | NewRangeHolidayData
