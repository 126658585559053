import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { ProjectViewModel } from "../api/project"
import { useProjectMutations } from "../hooks/use-project-mutations"
import { alpha, makeStyles, Theme } from "@material-ui/core"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"
import { useLocation } from "react-router-dom"
import { APP_MAX_WIDTH } from "../../constants"

const ProjectArchivedAlert = ({ project }: { project: ProjectViewModel }) => {
  const actions = useProjectMutations()
  const translations = useTranslations()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const location = useLocation()
  const isBasicRoute = location.pathname.includes("basic")
  const classes = useStyles()

  React.useLayoutEffect(
    function adjustWidthInProjectBasicRoute() {
      let container = containerRef.current

      if (isBasicRoute) {
        container?.style.setProperty("max-width", `${APP_MAX_WIDTH}px`)
        container?.style.setProperty("margin-left", "auto")
        container?.style.setProperty("margin-right", "auto")
      }

      return function resetWidth() {
        container?.style.removeProperty("max-width")
      }
    },
    [isBasicRoute]
  )

  if (!project.archived) return null

  return (
    <div ref={containerRef} className={classes.archivedMessageBar} id="project-archived-alert">
      <Typography variant="h6">{translations.archivedMessage}</Typography>
      {project.canDelete ? (
        <Button
          onClick={() => actions.unarchiveProject(project.id)}
          variant="contained"
          color="primary"
          data-test="unarchive-button"
        >
          {translations.unarchiveButtonLabel}
        </Button>
      ) : null}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    archivedMessageBar: {
      marginBottom: theme.spacing(),
      padding: theme.spacing(0.5, 1),
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      color: theme.palette.error.main,
      backgroundColor: alpha(theme.palette.error.main, 0.075),
      border: `1.5px solid ${alpha(theme.palette.error.main, 0.75)}`,
      borderRadius: theme.spacing(0.5),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: theme.spacing(),
        padding: theme.spacing(2),
      },
    },
  }
})

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("project")
  const translations = (t?.projectDetails || {}) as StringMap

  const { archivedMessage = defaults.archivedMessage, unarchiveButtonLabel = defaults.unarchiveButtonLabel } =
    translations

  return {
    archivedMessage,
    unarchiveButtonLabel,
  }
}

const defaultTranslations = {
  archivedMessage: "Project is archived",
  unarchiveButtonLabel: "Unarchive this project",
}

type Translations = typeof defaultTranslations
export default ProjectArchivedAlert
