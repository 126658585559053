import Avatar from "@material-ui/core/Avatar"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import CardMedia from "@material-ui/core/CardMedia"
import Divider from "@material-ui/core/Divider"
import List from "@material-ui/core/List"
import ListItem, { ListItemProps } from "@material-ui/core/ListItem"
import ListItemAvatar from "@material-ui/core/ListItemAvatar"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import Popper from "../Popper"

import { AlertTriangle, ArrowRight, AtSign, Briefcase, Check, Phone, Search } from "react-feather"
import { Button, CircularProgress, InputAdornment, makeStyles, Slide, TextField, Theme } from "@material-ui/core"
import { IContact } from "../../types/contact"
import { startCase, truncate, upperCase } from "lodash"
import { useI18n, useNetworkStatus } from "../../hooks"
import { useAuthUserMembership } from "../../memberships/hooks/use-auth-user-membership"
import { useState, useRef, MouseEvent, ReactElement } from "react"
import { COLOR_BLACK } from "../../constants"

const DefaultContactCard = ({
  contactListItems,
  defaultContact,
  actionable = true,
  translations = defaultTranslations,
  permissions = defaultPermissions,
}: DefaultContactCardProps) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [query, setQuery] = useState("")
  const buttonRef = useRef(null)
  const handleClick = (e: MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)
  const closeMenu = () => setAnchorEl(null)
  const { canChangeDefaultContact } = permissions
  const itemsToShow = contactListItems.filter((item) => item.primaryText.toLowerCase().includes(query.toLowerCase()))

  if (!defaultContact) {
    return (
      <Card variant="outlined" className={classes.card}>
        <CardContent>
          <Typography variant="body2" className={classes.title}>
            {translations.defaultContactCardHeader}
          </Typography>
          <Divider />
          <div className={classes.mediaContainer}>
            <CardMedia className={classes.media} component="img" image="/add-contact.svg" />
          </div>
        </CardContent>
        {actionable && canChangeDefaultContact && (
          <CardActions className={classes.cardActions}>
            <Button ref={buttonRef} onClick={handleClick} variant="outlined" fullWidth>
              {translations.changeDefaultContactButtonLabel}
            </Button>
            <Popper open={Boolean(anchorEl)} anchorEl={buttonRef.current} onClose={closeMenu}>
              <List className={classes.list}>
                <TextField
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Search"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search size={16} />
                      </InputAdornment>
                    ),
                  }}
                />
                {!itemsToShow.length && <Typography variant="h5">List is empty</Typography>}
                {itemsToShow.map((item) => (
                  <MenuItem key={item.value} {...item} />
                ))}
              </List>
            </Popper>
          </CardActions>
        )}
      </Card>
    )
  }

  const { firstname, lastname, email, phone, jobTitle } = defaultContact
  const initials = upperCase(`${firstname[0]}${lastname[0]}`)
  const fullname = startCase(`${firstname} ${lastname}`)
  const contactEmail = email || translations.noEmail
  const contactPhone = phone || translations.noPhoneNumber
  const contactJobTitle = jobTitle || translations.noJobTitle

  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent>
        <Typography variant="body2" className={classes.title}>
          {translations.defaultContactCardHeader}
        </Typography>
        <Divider />
        <List>
          <ListItem>
            <ListItemAvatar>
              <Avatar className={classes.avatar}>{initials}</Avatar>
            </ListItemAvatar>
            <Tooltip title={fullname} enterDelay={getTooltipDelay(fullname)}>
              <ListItemText primary={truncateTo20(fullname)} data-test={`default-contact-${fullname}`}></ListItemText>
            </Tooltip>
          </ListItem>
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              <AtSign size={20} />
            </ListItemIcon>
            <Tooltip title={contactEmail} enterDelay={getTooltipDelay(contactEmail)}>
              <ListItemText primary={truncateTo20(contactEmail)} />
            </Tooltip>
          </ListItem>
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              <Phone size={20} />
            </ListItemIcon>
            <ListItemText primary={contactPhone} />
          </ListItem>
          <ListItem>
            <ListItemIcon className={classes.listItemIcon}>
              <Briefcase size={20} />
            </ListItemIcon>
            <Tooltip title={contactJobTitle} enterDelay={getTooltipDelay(contactJobTitle)}>
              <ListItemText primary={truncateTo20(contactJobTitle)} />
            </Tooltip>
          </ListItem>
        </List>
      </CardContent>
      {actionable && canChangeDefaultContact && (
        <CardActions className={classes.cardActions}>
          <Button ref={buttonRef} onClick={handleClick} variant="outlined" fullWidth>
            {translations.changeDefaultContactButtonLabel}
          </Button>
          <Popper open={Boolean(anchorEl)} anchorEl={buttonRef.current} onClose={closeMenu}>
            <List className={classes.list}>
              <TextField
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Search"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={16} />
                    </InputAdornment>
                  ),
                }}
              />
              {!itemsToShow.length && <Typography variant="h5">List is empty</Typography>}
              {itemsToShow.map((item) => (
                <MenuItem key={item.value} {...item} />
              ))}
            </List>
          </Popper>
        </CardActions>
      )}
    </Card>
  )
}

const MenuItem = ({
  primaryText,
  secondaryText,
  onSelect,
  disabled,
  avatar,
  ...listItemProps
}: AsyncSelectListItem) => {
  const classes = useStyles()
  const [isHovering, setIsHovering] = useState(false)
  const { isFulfilled, isIdle, isPending, isRejected, setStatus } = useNetworkStatus()

  const handleMouseEnter = () => setIsHovering(true)
  const handleMouseLeave = () => setIsHovering(false)

  const handleClick = async (e: MouseEvent<HTMLLIElement>) => {
    setStatus("pending")
    setIsHovering(false)
    try {
      await onSelect()
      setStatus("fulfilled")
    } catch (error) {
      setStatus("rejected")
    }
  }

  return (
    <ListItem
      {...listItemProps}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disabled={isPending() || disabled || listItemProps.selected}
      divider
      button
      dense
      component="li"
    >
      {avatar && <ListItemAvatar className={classes.avatar}>{avatar}</ListItemAvatar>}
      <ListItemText primary={primaryText} secondary={isRejected() ? "failed" : secondaryText} />
      <ListItemIcon>
        {isHovering && isIdle() && (
          <Slide direction="left" in={isHovering && isIdle()} mountOnEnter unmountOnExit>
            <ArrowRight size={16} color={COLOR_BLACK} />
          </Slide>
        )}
        {isPending() && <CircularProgress size={16} color="primary" />}
        {isFulfilled() && <Check size={16} />}
        {isRejected() && <AlertTriangle size={16} />}
      </ListItemIcon>
    </ListItem>
  )
}

const DefaultContactCardWithPermissions = (props: DefaultContactCardProps) => {
  const { canUpdateAnySupplier } = useAuthUserMembership()
  const canChangeDefaultContact = canUpdateAnySupplier
  return <DefaultContactCard {...props} permissions={{ canChangeDefaultContact }} />
}

const DefaultContactCardWithI18n = (props: DefaultContactCardProps) => {
  const { translations = {} } = useI18n("translation")
  const {
    defaultContactCardHeader = defaultTranslations.defaultContactCardHeader,
    changeDefaultContactButtonLabel = defaultTranslations.changeDefaultContactButtonLabel,
    noEmail = defaultTranslations.noEmail,
    noPhoneNumber = defaultTranslations.noPhoneNumber,
    noJobTitle = defaultTranslations.noJobTitle,
  } = translations

  return (
    <DefaultContactCardWithPermissions
      {...props}
      translations={{
        defaultContactCardHeader,
        changeDefaultContactButtonLabel,
        noEmail,
        noPhoneNumber,
        noJobTitle,
      }}
    />
  )
}

const defaultPermissions: DefaultContactCardPermissions = {
  canChangeDefaultContact: true,
}

const defaultTranslations: DefaultContactCardTranslations = {
  defaultContactCardHeader: "Default contact",
  changeDefaultContactButtonLabel: "Change default contact",
  noEmail: "No email",
  noPhoneNumber: "No phone number",
  noJobTitle: "No job title",
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: theme.spacing(40),
    minWidth: theme.spacing(40),
    padding: theme.spacing(1),
    marginTop: 32,
  },
  title: {
    paddingBottom: theme.spacing(2),
    textTransform: "uppercase",
    letterSpacing: theme.typography.overline.letterSpacing,
    fontWeight: 500,
  },
  cardActions: {
    display: "flex",
    justifyContent: "center",
    paddingBottom: theme.spacing(2),
  },
  listItemIcon: {
    color: theme.palette.common.black,
    height: theme.spacing(2.5),
    width: theme.spacing(2.5),
  },
  mediaContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),
  },
  media: {
    width: theme.spacing(25),
    display: "block",
  },
  list: {
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    overflow: "hidden",
    boxShadow: theme.shadows["2"],
    "& li": {
      "&.Mui-disabled": {
        opacity: 1,
      },
      "& > .MuiListItemIcon-root": {
        minWidth: 24,
      },
      "&:last-child": {
        borderBottom: "1px solid transparent",
      },
    },
  },
  avatar: {
    fontSize: "0.875rem",
    backgroundColor: theme.palette.primary.main,
    textTransform: "uppercase",
  },
}))

function getTooltipDelay(text: string) {
  if (!text) return
  return text.length > 20 ? 50 : 5000
}

function truncateTo20(text: string) {
  if (!text) return
  return truncate(text, { length: 20 })
}

type DefaultContactCardProps = {
  defaultContact: null | IContact
  contactListItems: AsyncSelectListItem[]
  actionable?: boolean
  translations?: DefaultContactCardTranslations
  permissions?: DefaultContactCardPermissions
}

type DefaultContactCardPermissions = {
  canChangeDefaultContact: boolean
}

type DefaultContactCardTranslations = {
  defaultContactCardHeader: string
  changeDefaultContactButtonLabel: string
  noEmail: string
  noPhoneNumber: string
  noJobTitle: string
}

type AsyncSelectListItem = ListItemProps & {
  onSelect: () => Promise<unknown>
  value: string
  primaryText: string
  secondaryText?: string
  avatar?: ReactElement
}

export default DefaultContactCardWithI18n
