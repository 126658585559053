import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import NoContacts from "./NoContacts"
import { IContact } from "../../types/contact"
import { useI18n, useRouter } from "../../hooks"
import { makeStyles, Theme, useTheme } from "@material-ui/core"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { useIsSmallScreen } from "../../hooks/use-is-small-screen"
import { Link } from "react-router-dom"

const ContactsTable = ({ contacts, canAddContact }: ContactsTableProps) => {
  const { pathname, history } = useRouter()
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { createPathWithContext } = useUrlWithContext()
  const isSmallScreen = useIsSmallScreen()

  if (!contacts.length) return <NoContacts showAddButton={canAddContact} />

  if (isSmallScreen) {
    return (
      <div style={{ paddingLeft: 8, paddingRight: 8 }}>
        {contacts.map((contact) => (
          <ContactCard key={contact.id} contact={contact} />
        ))}
      </div>
    )
  }

  return (
    <TableContainer>
      <Table>
        <TableHead className={classes.tableHead}>
          <TableRow>
            <TableCell align="left">{translations.tableHeaderFirstname}</TableCell>
            <TableCell align="left">{translations.tableHeaderLastname}</TableCell>
            <TableCell align="left">{translations.tableHeaderEmail}</TableCell>
            <TableCell align="left">{translations.tableHeaderPhoneNumber}</TableCell>
            <TableCell align="left">{translations.tableHeaderJobTitle}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {contacts.map((contact) => {
            const contactURI = createPathWithContext(`${pathname}?tab=contact&contact=${contact.id}`)
            const redirectToContact = () => history.push(contactURI)
            return (
              <TableRow key={contact.id}>
                <TableCell
                  className={classes.tableCell}
                  onClick={redirectToContact}
                  data-test={`contacts-table-${contact.firstname} ${contact.lastname}`}
                >
                  <span>{contact.firstname || "-"}</span>
                </TableCell>
                <TableCell>{contact.lastname || "-"}</TableCell>
                <TableCell>{contact.email || "-"}</TableCell>
                <TableCell>{contact.phone || "-"}</TableCell>
                <TableCell>{contact.jobTitle || "-"}</TableCell>
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

const ContactCard = ({ contact }: { contact: IContact }) => {
  const translations = useTranslations(defaultTranslations)
  const { createPathWithContext } = useUrlWithContext()
  const { pathname } = useRouter()
  const theme = useTheme()

  const contactName = `${contact.firstname} ${contact.lastname}`
  const contactURI = createPathWithContext(`${pathname}?tab=contact&contact=${contact.id}`)

  return (
    <Paper variant="outlined" style={{ padding: 16, marginBottom: 16 }}>
      <Typography
        variant="h6"
        gutterBottom
        component={Link}
        to={contactURI}
        style={{ textDecoration: "none", color: theme.palette.primary.main }}
      >
        {contactName}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {translations.tableHeaderEmail}: {contact.email ? contact.email : translations.noEmail}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {translations.tableHeaderPhoneNumber}: {contact.phone ? contact.phone : translations.noPhoneNumber}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {translations.tableHeaderJobTitle}: {contact.jobTitle ? contact.jobTitle : translations.noJobTitle}
      </Typography>
    </Paper>
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t || {}) as { [k: string]: string }
  const { firstname, lastname, email, phoneNumber, jobTitle } = translations

  const tableHeaderFirstname = firstname || defaults.tableHeaderFirstname
  const tableHeaderLastname = lastname || defaults.tableHeaderLastname
  const tableHeaderEmail = email || defaults.tableHeaderEmail
  const tableHeaderPhoneNumber = phoneNumber || defaults.tableHeaderPhoneNumber
  const tableHeaderJobTitle = jobTitle || defaults.tableHeaderJobTitle
  const noEmail = translations.noEmail || defaults.noEmail
  const noPhoneNumber = translations.noPhoneNumber || defaults.noPhoneNumber
  const noJobTitle = translations.noJobTitle || defaults.noJobTitle

  return {
    tableHeaderFirstname,
    tableHeaderLastname,
    tableHeaderEmail,
    tableHeaderPhoneNumber,
    tableHeaderJobTitle,
    noEmail,
    noPhoneNumber,
    noJobTitle,
  }
}

const defaultTranslations = {
  tableHeaderFirstname: "Firstname",
  tableHeaderLastname: "Lastname",
  tableHeaderEmail: "Email",
  tableHeaderPhoneNumber: "Phone number",
  tableHeaderJobTitle: "Job title",
  noEmail: "No email",
  noPhoneNumber: "No phone number",
  noJobTitle: "No job title",
}

const useStyles = makeStyles((theme: Theme) => ({
  tableHead: {
    background: theme.palette.background.default,
    textTransform: "uppercase",
    "& th": {
      fontSize: 12,
      fontWeight: 500,
      color: theme.palette.text.secondary,
    },
  },
  tableCell: {
    color: theme.palette.primary.main,
    transition: theme.transitions.create("opacity"),
    "&:first-child": {
      "&:hover": {
        cursor: "pointer",
        opacity: 1,
      },
      "& > span:hover": {
        paddingBottom: theme.spacing(0.5),
        borderBottom: `1px dotted ${theme.palette.primary.main}`,
      },
    },
  },
}))

type ContactsTableProps = {
  contacts: IContact[]
  canAddContact: boolean
}

type Translations = typeof defaultTranslations

export default ContactsTable
