import React from "react"
import FormLabel from "@material-ui/core/FormLabel"
import FormikInput from "../components/FormikInput"
import ButtonLink from "../components/ButtonLink"
import ErrorFallback from "../components/ErrorFallback"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Turnstile from "../components/Turnstile"
import doublet from "../utils/doublet"
import { Form, Formik, FormikConfig } from "formik"
import { useI18n } from "../hooks"
import { ErrorBoundary } from "react-error-boundary"
import { Eye, EyeOff } from "react-feather"
import { useAppDispatch } from "../store"
import { LoginUser } from "../users/store/actions"
import { LoginData } from "../users/auth-api"
import { Alert } from "@material-ui/lab"
import { paths } from "../paths"
import { useTurnstile } from "../hooks/use-turnstile"
import { z } from "zod"
import { useZodSchemas } from "../hooks/use-zod-schemas"
import { createFormikValidate } from "../utils/formik"

const FIVE_SECONDS = 5_000

const LoginRoute = () => {
  const translations = useTranslations()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <Typography variant="h3" style={{ paddingBottom: 24 }}>
            {translations.login}
          </Typography>
        </Grid>
        <Grid item>
          <LoginForm />
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item>
              <ButtonLink data-test="signup-link" to={paths.signup()}>
                {translations.noAccount}
              </ButtonLink>
            </Grid>
            <Grid item>
              <ButtonLink data-test="forgot-password-link" to={paths.forgotPassword()}>
                {translations.forgotPassword}
              </ButtonLink>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

const LoginForm = () => {
  const { ZodEmail, ZodPassword } = useZodSchemas()
  const turnstile = useTurnstile()
  const translations = useTranslations()
  const dispatch = useAppDispatch()
  const [showPassword, setShowPassword] = React.useState(false)
  const [error, setError] = React.useState<string | null>(null)
  const LoginSchema = z.object({ email: ZodEmail, password: ZodPassword })

  const handleLogin = async (loginData: LoginData) => {
    if (!turnstile.isSolved) {
      setError(translations.verifyHuman)
      return
    }

    const [turnstileVerificationError, isTurnstileVerified] = await doublet(turnstile.verifyToken)
    if (turnstileVerificationError || !isTurnstileVerified) {
      setError(translations.turnstileTokenVerificationFailed)
      turnstile.props.ref.current?.reset()
      return
    }

    const [loginError, loginResult] = await doublet(() => dispatch(LoginUser(loginData)))
    if (loginError || loginResult.meta.requestStatus === "rejected") {
      // @ts-expect-error
      const errorMessage = loginResult?.error?.message || loginError?.message || translations.loginFailedMessage
      setError(errorMessage)
      turnstile.props.ref.current?.reset()
      return
    }
  }

  const formikProps: FormikConfig<{ email: string; password: string }> = {
    initialValues: { email: "", password: "" },
    initialErrors: { email: "invalid email", password: "invalid password" },
    onSubmit: handleLogin,
    validate: createFormikValidate(LoginSchema),
    validateOnMount: true,
  }

  React.useEffect(() => {
    if (turnstile.error) {
      setError(turnstile.error)
    }
  }, [turnstile.error])

  React.useEffect(() => {
    let timeoutId: NodeJS.Timeout | null = null
    if (error) timeoutId = setTimeout(() => setError(null), FIVE_SECONDS)

    return () => {
      if (timeoutId) clearTimeout(timeoutId)
    }
  }, [error])

  const passwordInputEndAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(e) => e.preventDefault()}
      >
        {showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, isValid }) => {
        const isSubmitDisabled = !isValid || isSubmitting || error !== null

        return (
          <Form>
            <Grid direction="column" spacing={2} container>
              <Grid item>
                <FormLabel htmlFor="email">{`${translations.email}:`}</FormLabel>
                <FormikInput
                  data-test="email-input"
                  id="email"
                  name="email"
                  placeholder={translations.emailPlaceholder}
                  variant="outlined"
                  disabled={isSubmitting}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <FormLabel htmlFor="password">{`${translations.password}:`}</FormLabel>
                <FormikInput
                  data-test="password-input"
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder={translations.passwordPlaceholder}
                  variant="outlined"
                  disabled={isSubmitting}
                  InputProps={{ endAdornment: passwordInputEndAdornment }}
                  fullWidth
                />
              </Grid>
              <Grid item>
                {error && (
                  <Alert severity="error" onClose={() => setError(null)}>
                    {error}
                  </Alert>
                )}
              </Grid>
              <Grid item>
                <Turnstile {...turnstile.props} />
              </Grid>
              <Grid item>
                <Button
                  data-test="submit-button"
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitDisabled}
                  startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                >
                  {isSubmitting ? translations.loggingIn : translations.login}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")

  const {
    email = defaults.email,
    emailPlaceholder = defaults.emailPlaceholder,
    password = defaults.password,
    passwordPlaceholder = defaults.passwordPlaceholder,
    noAccount = defaults.noAccount,
    forgotPassword = defaults.forgotPassword,
    error = defaults.error,
    loginFailedMessage = defaults.loginFailedMessage,
    login = defaults.login,
    loggingIn = defaults.loggingIn,
    verifyHuman = defaults.verifyHuman,
    turnstileTokenVerificationFailed = defaults.turnstileTokenVerificationFailed,
  } = translations

  return {
    email,
    emailPlaceholder,
    password,
    passwordPlaceholder,
    noAccount,
    forgotPassword,
    error,
    loginFailedMessage,
    login,
    loggingIn,
    verifyHuman,
    turnstileTokenVerificationFailed,
  }
}

const defaultTranslations = {
  email: "Email",
  emailPlaceholder: "Enter your email",
  password: "Password",
  passwordPlaceholder: "Enter your password",
  noAccount: `I don't have an account`,
  forgotPassword: `I forgot my password`,
  error: "Error",
  loginFailedMessage: "Failed to login!",
  login: "Login",
  loggingIn: "Logging in",
  verifyHuman: "Please verify you are a human",
  turnstileTokenVerificationFailed: "Human verification failed due to token verification error",
}
type Translations = typeof defaultTranslations

export default LoginRoute
