import IconButton from "@material-ui/core/IconButton"
import Tooltip from "@material-ui/core/Tooltip"
import React from "react"
import { ProjectRouteContext } from "../../../routes/projects/$projectId"
import { useTheme } from "@material-ui/core"
import { useTranslations } from "./use-translations"
import { Maximize2, Minimize2 } from "react-feather"
import { useLocation } from "react-router-dom"
import { isGanttRoute } from "./utils"

const FullscreenToggleButton = () => {
  const theme = useTheme()
  const translations = useTranslations()
  const projectRouteContext = React.useContext(ProjectRouteContext)
  const location = useLocation()

  const isFullScreen = projectRouteContext?.isFullScreen
  const tooltipTitle = translations.fullScreenAriaLabel
  const isGantt = isGanttRoute(location.pathname)

  if (!isGantt) return null

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        onClick={projectRouteContext?.toggleFullscreen}
        aria-label={tooltipTitle}
        style={{ color: theme.palette.text.primary }}
      >
        {isFullScreen ? <Minimize2 size={16} /> : <Maximize2 size={16} />}
      </IconButton>
    </Tooltip>
  )
}

export default FullscreenToggleButton
