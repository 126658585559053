import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import HolidayForm from "./HolidayForm"
import { BaseNewHolidayData } from "../api"
import { mapRangeToSingleHoliday, mapSingleToRangeHoliday, HolidayFormData } from "../utils/HolidayFormUtils"
import { Plus } from "react-feather"
import { makeStyles, Theme } from "@material-ui/core"
import { useTranslations } from "../hooks/use-translations"

const initialHolidayState: HolidayFormData = {
  name: "",
  type: "Single",
  date: new Date(),
  recurring: false,
  startDate: null,
  endDate: null,
}

const NewHolidayDialog = ({ onAddHoliday }: NewHolidayDialogProps) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [holiday, setHoliday] = React.useState<HolidayFormData>(initialHolidayState)
  const classes = useStyles()
  const translations = useTranslations()

  const addHoliday = () => {
    onAddHoliday(holiday)
    setAnchorEl(null)
    setHoliday(initialHolidayState)
  }

  const closeDialog = () => {
    setAnchorEl(null)
    setHoliday(initialHolidayState)
  }
  const onSaveName = (name: string) => setHoliday({ ...holiday, name })
  const onSaveRecurring = (recurring: boolean) => setHoliday({ ...holiday, recurring })

  const onSaveStartDate = (startDate: Date) => {
    if (holiday.type === "Single") {
      setHoliday({ ...holiday, date: startDate })
    } else {
      setHoliday({ ...holiday, startDate })
    }
  }

  const onSaveEndDate = (endDate: Date | null) => {
    // Handle updating of end date if range holiday
    if (holiday.type === "Range" && endDate) {
      setHoliday({ ...holiday, endDate })
    }

    // Handle auto mapping of single to range if end date is set
    if (holiday.type === "Single" && endDate) {
      const rangeHoliday = mapSingleToRangeHoliday(holiday, endDate)
      setHoliday(rangeHoliday)
    }

    // Handle auto mapping of range to single if end date is removed
    if (holiday.type === "Range" && !endDate) {
      const singleHoliday = mapRangeToSingleHoliday(holiday)
      setHoliday(singleHoliday)
    }
  }

  return (
    <>
      <Button
        size={"small"}
        variant="contained"
        color="primary"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<Plus size={16} />}
      >
        {translations.addHoliday}
      </Button>
      <Dialog open={anchorEl !== null} onClose={closeDialog} aria-labelledby="new-holiday-dialog">
        <DialogTitle id="new-holiday-dialog" className={classes.dialogTitle}>
          {translations.addANewHoliday}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <HolidayForm
            holiday={holiday}
            onSaveName={onSaveName}
            onSaveRecurring={onSaveRecurring}
            onSaveEndDate={onSaveEndDate}
            onSaveStartDate={onSaveStartDate}
            autoFocusTitle
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>{translations.cancel}</Button>
          <Button onClick={addHoliday} size="small" color="primary" variant="contained" disabled={!holiday.name}>
            {translations.addHoliday}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogTitle: { borderBottom: `1px solid ${theme.palette.divider}` },
  dialogContent: { padding: theme.spacing(0, 3) },
}))

export default NewHolidayDialog

type NewHolidayDialogProps = {
  onAddHoliday: (holiday: BaseNewHolidayData) => void
}
