export const PROJECT_TYPE = {
  NORMAL: "Normal",
  TEMPLATE: "Template",
}

export const PROJECT_STATE = {
  NOT_REPORTED: "Not reported",
  GREEN: "Green",
  RED: "Red",
  YELLOW: "Yellow",
} as const

export const PROJECT_STATE_ICON_NAME = {
  [PROJECT_STATE.NOT_REPORTED]: "",
  [PROJECT_STATE.GREEN]: "sentiment_satisfied",
  [PROJECT_STATE.RED]: "sentiment_dissatisfied",
  [PROJECT_STATE.YELLOW]: "sentiment_neutral",
}
