import React from "react"
import Menu from "@material-ui/core/Menu"
import Button from "@material-ui/core/Button"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import { makeStyles, Theme, useTheme } from "@material-ui/core"
import { ChevronDown, ChevronLeft, ChevronRight } from "react-feather"
import { useUrlWithContext } from "../../../hooks/use-url-with-context"
import { paths } from "../../../paths"
import { Link, useLocation } from "react-router-dom"
import { useTranslations } from "./use-translations"
import { TaskViewModel } from "../../api/task"
import TaskMenu from "./TaskMenu"
import FullPath from "../../../components/FullPath"

const useMenuState = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => setAnchorEl((prev) => (prev ? null : e.currentTarget))
  const closeMenu = () => setAnchorEl(null)

  return { anchorEl, toggleMenu, closeMenu }
}

const useTaskNavItems = ({ task }: { task: TaskViewModel }) => {
  const { createPathWithContext } = useUrlWithContext()
  const translations = useTranslations()
  const location = useLocation()

  const basicPath = paths.taskBasic(task.id)
  const subtasksPath = paths.taskSubtasks(task.id)
  const kanbanPath = paths.taskKanban(task.id)
  const calendarPath = paths.taskCalendar(task.id)
  const todosPath = paths.taskTodos(task.id)

  const basicRoute = createPathWithContext(basicPath)
  const subtasksRoute = createPathWithContext(subtasksPath)
  const kanbanRoute = createPathWithContext(kanbanPath)
  const calendarRoute = createPathWithContext(calendarPath)
  const todosRoute = `${createPathWithContext(todosPath)}&view=all`

  const taskNavItems = [
    {
      id: basicPath,
      label: translations.basicInfoTabLabel,
      to: basicRoute,
    },
    {
      id: subtasksPath,
      label: translations.tasksTabLabel,
      to: subtasksRoute,
    },
    {
      id: kanbanPath,
      label: translations.kanbanTabLabel,
      to: kanbanRoute,
    },
    {
      id: calendarPath,
      label: translations.calendarTabLabel,
      to: calendarRoute,
    },
    {
      id: todosPath,
      label: translations.todoListTabLabel,
      to: todosRoute,
    },
  ]

  const activeTab = taskNavItems.find((item) => location.pathname.includes(item.id)) || taskNavItems[0]
  const activeTabIndex = taskNavItems.indexOf(activeTab)
  const prevTab = taskNavItems[activeTabIndex - 1] || null
  const nextTab = taskNavItems[activeTabIndex + 1] || null

  return {
    taskNavItems,
    activeTab,
    prevTab,
    nextTab,
  }
}

const TaskNavbarMobile = ({ task }: { task: TaskViewModel }) => {
  const classes = useStyles()
  const theme = useTheme()
  const { anchorEl, toggleMenu, closeMenu } = useMenuState()
  const { taskNavItems, activeTab, prevTab, nextTab } = useTaskNavItems({ task })

  return (
    <div className={classes.container}>
      <div className={classes.titleRow}>
        <div style={{ marginBottom: 4, marginTop: -8, flexGrow: 1 }}>
          <FullPath
            ancestorTaskLinks={task.ancestorTaskLinks}
            projectLink={task.projectLink}
            taskLink={task.taskLink}
          />
          <Typography variant="h6">{task.title}</Typography>
        </div>
        <TaskMenu task={task} />
      </div>
      <div className={classes.navRow}>
        {prevTab ? (
          <Button
            startIcon={<ChevronLeft size={16} />}
            component={Link}
            to={prevTab.to}
            style={{ color: theme.palette.text.secondary }}
          >
            {prevTab.label}
          </Button>
        ) : null}
        <div style={{ flexGrow: 1, marginLeft: 8, marginRight: 8 }}>
          <Button
            onClick={toggleMenu}
            endIcon={<ChevronDown size={16} />}
            variant="outlined"
            style={{ background: theme.palette.background.paper }}
            fullWidth
          >
            {activeTab.label}
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={closeMenu}>
            {taskNavItems.map((item) => {
              return (
                <ListItem key={item.to} component={Link} to={item.to} button dense onClick={closeMenu}>
                  <ListItemText primary={item.label} />
                </ListItem>
              )
            })}
          </Menu>
        </div>
        {nextTab ? (
          <Button
            endIcon={<ChevronRight size={16} />}
            component={Link}
            to={nextTab.to}
            style={{ color: theme.palette.text.secondary }}
          >
            {nextTab.label}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    container: {
      padding: theme.spacing(1, 1, 0, 1),
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(0),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    titleRow: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexWrap: "nowrap",
      "& h6": {
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        overflow: "hidden",
        maxWidth: "calc(100% - 48px)",
      },
      "& button": {
        backgroundColor: theme.palette.divider,
        borderRadius: theme.shape.borderRadius,
        marginLeft: theme.spacing(1),
      },
    },
    navRow: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(2, 0, 1, 0),
      margin: theme.spacing(0, -1),
    },
    navButton: {
      border: `1px solid ${theme.palette.divider}`,
      display: "flex",
      padding: theme.spacing(1),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
    },
    navButtonLabel: {
      margin: theme.spacing(0, 1),
    },
  }
})

export default TaskNavbarMobile
