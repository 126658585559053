import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"

export const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("task")
  const translations = (t?.taskNavBar || {}) as StringMap

  const appNavBarTranslations = useI18n("translation").translations?.appNavBar || {}
  const {
    tasksLabel = defaults.tasksTabLabel,
    todosLabel = defaults.todoListTabLabel,
    notificationsLabel = defaults.notificationsLabel,
  } = appNavBarTranslations

  const {
    managePermissionsBtnLabel = defaults.managePermissionsBtnLabel,
    archiveTaskBtnLabel = defaults.archiveTaskBtnLabel,
    deleteTaskBtnLabel = defaults.deleteTaskBtnLabel,
    deleteTaskSecondaryText = defaults.deleteTaskSecondaryText,
    basicInfoTabLabel = defaults.basicInfoTabLabel,
    kanbanTabLabel = defaults.kanbanTabLabel,
    noStartDate = defaults.noStartDate,
    noEndDate = defaults.noEndDate,
    addStartDate = defaults.addStartDate,
    addEndDate = defaults.addEndDate,
    planTitle = defaults.planTitle,
    enableTimeComponentLabel = defaults.enableTimeComponentLabel,
    deleteTaskSuccessText = defaults.deleteTaskSuccessText,
    deleteTaskFailureText = defaults.deleteTaskFailureText,
    deleteTaskConfirmTitle = defaults.deleteTaskConfirmTitle,
    deleteTaskConfirmText = defaults.deleteTaskConfirmText,
    archiveTaskSuccessText = defaults.archiveTaskSuccessText,
    archiveTaskFailureText = defaults.archiveTaskFailureText,
    archiveTaskConfirmTitle = defaults.archiveTaskConfirmTitle,
    archiveTaskConfirmText = defaults.archiveTaskConfirmText,
    boardMenuAriaLabel = defaults.boardMenuAriaLabel,
    boardMenuSubHeader = defaults.boardMenuSubHeader,
    calendarTabLabel = defaults.calendarTabLabel,
    menuAriaLabel = defaults.menuAriaLabel,
    backButtonLabel = defaults.backButtonLabel,
  } = translations

  return {
    menuAriaLabel,
    managePermissionsBtnLabel,
    archiveTaskBtnLabel,
    deleteTaskBtnLabel,
    deleteTaskSecondaryText,
    basicInfoTabLabel,
    tasksTabLabel: tasksLabel,
    kanbanTabLabel,
    todoListTabLabel: todosLabel,
    calendarTabLabel,
    noStartDate,
    noEndDate,
    addStartDate,
    addEndDate,
    planTitle,
    enableTimeComponentLabel,
    deleteTaskSuccessText,
    deleteTaskFailureText,
    deleteTaskConfirmTitle,
    deleteTaskConfirmText,
    archiveTaskSuccessText,
    archiveTaskFailureText,
    archiveTaskConfirmTitle,
    archiveTaskConfirmText,
    boardMenuAriaLabel,
    boardMenuSubHeader,
    backButtonLabel,
    notificationsLabel,
  }
}

const defaultTranslations = {
  menuAriaLabel: "Open menu",
  managePermissionsBtnLabel: "Manage Permissions",
  archiveTaskBtnLabel: "Archive task",
  deleteTaskBtnLabel: "Delete task",
  deleteTaskSecondaryText: "Permanently removes the task from database.",
  basicInfoTabLabel: "Basic info",
  tasksTabLabel: "Tasks",
  kanbanTabLabel: "Kanban",
  todoListTabLabel: "To-dos",
  calendarTabLabel: "Calendar",
  noStartDate: "No start",
  noEndDate: "No end",
  addStartDate: "Add start",
  addEndDate: "Add end",
  planTitle: "Schedule:",
  enableTimeComponentLabel: "Enable scheduling in hours and minutes",
  deleteTaskSuccessText: "Task deleted, redirecting . . .",
  deleteTaskFailureText: "Failed to delete task",
  deleteTaskConfirmTitle: "Delete this task",
  deleteTaskConfirmText:
    "Continuing this action will delete all data linked with this task including all subtasks. After this it can not be restored",
  archiveTaskSuccessText: "Task archived",
  archiveTaskFailureText: "Failed to archive task",
  archiveTaskConfirmTitle: "Archive this task",
  archiveTaskConfirmText:
    "Archiving this task also archives all the resources linked with this task. However you can unarchive task later",
  boardMenuAriaLabel: "Open board menu",
  boardMenuSubHeader: "Board column's visibility",
  backButtonLabel: "Go to project",
  notificationsLabel: "Notifications",
}

type Translations = typeof defaultTranslations
