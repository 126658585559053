import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogActions from "@material-ui/core/DialogActions"
import TextField from "@material-ui/core/TextField"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import { Plus } from "react-feather"
import { useI18n } from "../../hooks"

const NewTaskDialog = ({ onAddTask }: NewTaskDialogProps) => {
  const translations = useTranslations()
  const [isOpen, setIsOpen] = React.useState(false)

  const [title, setTitle] = React.useState("")
  const [position, setPosition] = React.useState<"top" | "bottom">("bottom")

  const openDialog = () => setIsOpen(true)
  const closeDialog = () => setIsOpen(false)

  const handleSubmit = () => {
    if (title.trim()) {
      onAddTask(title, position)
      setTitle("")
      setPosition("bottom")
      closeDialog()
    }
  }

  const handleCancel = () => {
    closeDialog()
    setTitle("")
    setPosition("bottom")
  }

  return (
    <>
      <Button variant="outlined" size="small" endIcon={<Plus size={16} />} onClick={openDialog}>
        {translations.addTask}
      </Button>
      <Dialog open={isOpen} onClose={handleCancel}>
        <DialogTitle>{translations.addNewTask}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="title"
            label={translations.taskTitle}
            type="text"
            fullWidth
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <FormControl component="fieldset" style={{ marginTop: 24 }}>
            <FormLabel component="legend">{translations.position}</FormLabel>
            <RadioGroup value={position} onChange={(e) => setPosition(e.target.value as Position)}>
              <FormControlLabel value="top" control={<Radio />} label={translations.atTop} />
              <FormControlLabel value="bottom" control={<Radio />} label={translations.atBottom} />
            </RadioGroup>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel} color="primary">
            {translations.cancel}
          </Button>
          <Button onClick={handleSubmit} color="primary" disabled={!title.trim()}>
            {translations.add}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: treegridTranslations } = useI18n("treegrid")
  const { translations } = useI18n()

  const { addTask = defaults.addTask } = treegridTranslations

  const {
    addNewTask = defaults.addNewTask,
    taskTitle = defaults.taskTitle,
    position = defaults.position,
    atTop = defaults.atTop,
    atBottom = defaults.atBottom,
    cancel = defaults.cancel,
    add = defaults.add,
  } = translations

  return {
    addTask,
    addNewTask,
    taskTitle,
    position,
    atTop,
    atBottom,
    cancel,
    add,
  }
}

const defaultTranslations = {
  addTask: "Add Task",
  addNewTask: "Add New Task",
  taskTitle: "Task Title",
  position: "Position",
  atTop: "At the top of the list",
  atBottom: "At the bottom of the list",
  cancel: "Cancel",
  add: "Add",
}

type NewTaskDialogProps = {
  onAddTask: (title: string, position: Position) => void
}

type Position = "top" | "bottom"
type Translations = typeof defaultTranslations
export default NewTaskDialog
