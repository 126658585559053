import Typography from "@material-ui/core/Typography"
import Stack from "../../components/Stack"
import { NewTodoData } from "../api"
import { TodoViewModel } from "../api/todo"
import NewTodoDialog from "./NewTodoDialog"
import { useTheme } from "@material-ui/core"
import { useI18n } from "../../hooks"

const todosEmpty = "You don't have any to-dos here yet."

const TodoListEmpty = ({ canAddTodos, onAddTodo }: TodoListEmptyProps) => {
  const theme = useTheme()
  const { translations } = useI18n("translation")
  const todosEmptyMessage = translations.todosEmpty || todosEmpty

  return (
    <Stack
      alignItems="center"
      style={{
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Typography variant="body2" gutterBottom>
        {todosEmptyMessage}
      </Typography>
      {canAddTodos && <NewTodoDialog canAddTodos={canAddTodos} onAddTodo={onAddTodo} />}
    </Stack>
  )
}

type TodoListEmptyProps = {
  canAddTodos: boolean
  onAddTodo: (todoData: NewTodoData) => Promise<TodoViewModel | void>
}

export default TodoListEmpty
