import React from "react"
import TextField from "@material-ui/core/TextField"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Popper, { PopperProps } from "@material-ui/core/Popper"
import Grow from "@material-ui/core/Grow"
import Paper from "@material-ui/core/Paper"
import ClickAwayListener from "@material-ui/core/ClickAwayListener"
import InputLabel from "@material-ui/core/FormLabel"
import InputAdornment from "@material-ui/core/InputAdornment"
import { Theme, makeStyles } from "@material-ui/core"
import { COLOR_LIGHT } from "../../constants"
import { Search } from "react-feather"

const useMemberMenu = ({ anchor, options, selected, onClickAway }: MemberMenuProps) => {
  const [search, setSearch] = React.useState<string>("")

  const matchedOptions = options.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()))
  const filteredOptions = selected ? matchedOptions.filter(({ id }) => !selected.includes(id)) : matchedOptions

  React.useEffect(() => {
    if (!anchor) {
      setSearch("")
    }
  }, [anchor])

  return {
    options: filteredOptions,
    search,
    setSearch,
  }
}

const MemberMenu = (props: MemberMenuProps) => {
  const { isOpen, anchor, menuTitle, searchInputPlaceholder, onClickMenuItem, onClickAway } = props
  const { PopperProps = {} } = props
  const { options, search, setSearch } = useMemberMenu(props)
  const classes = useStyles()
  const searchMemberInputAdornment = <InputAdornment position="start">{<Search size={16} />}</InputAdornment>
  const open = isOpen || Boolean(anchor)

  return (
    <Popper
      open={open}
      anchorEl={anchor}
      placement="bottom-end"
      className={classes.popper}
      keepMounted={false}
      transition
      disablePortal
      {...PopperProps}
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps}>
          <Paper className={classes.paper} variant="outlined">
            <ClickAwayListener onClickAway={onClickAway}>
              <span>
                <InputLabel>{menuTitle}</InputLabel>
                <TextField
                  className={classes.textField}
                  placeholder={searchInputPlaceholder}
                  variant="outlined"
                  size="small"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  InputProps={{ startAdornment: searchMemberInputAdornment }}
                  fullWidth
                  autoFocus
                />

                <List>
                  {options.map(({ id, name }, i) => (
                    <ListItem
                      key={id}
                      divider={i !== options.length - 1}
                      onClick={() => onClickMenuItem({ id, name })}
                      button
                      dense
                      disableGutters
                    >
                      <ListItemText primary={name} />
                    </ListItem>
                  ))}
                </List>
              </span>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}

export default MemberMenu

const useStyles = makeStyles((theme: Theme) => ({
  helpButton: { fontSize: 10 },
  textField: { marginTop: 16, marginBottom: 8, fontSize: 12, background: COLOR_LIGHT },
  popper: { zIndex: 99, width: 360, marginTop: 4 },
  paper: { padding: 16, maxHeight: 400, overflowX: "hidden", overflowY: "auto" },
}))

type MemberMenuOption = { id: string; name: string }
type MemberMenuProps = {
  anchor: HTMLElement | null
  menuTitle: string
  searchInputPlaceholder: string
  options: MemberMenuOption[]
  selected?: string
  isOpen?: boolean
  shouldHideSelected?: boolean
  onClickAway: () => void
  onClickMenuItem: (option: MemberMenuOption) => void
  PopperProps?: Partial<PopperProps>
}
