import { PayloadAction } from "@reduxjs/toolkit"
import { OptionsState } from "./index"
import { IOptionResponse } from "../interfaces/options-response"
import { difference, keyBy, keys, omitBy, uniq, without } from "lodash"

export const setOptionsCase = (state: OptionsState, action: PayloadAction<IOptionResponse[]>) => {
  const options = action.payload
  const optionsMap = keyBy(options, "id")
  const optionIds = keys(optionsMap)
  state.byIds = { ...state.byIds, ...optionsMap }
  state.allIds = uniq([...state.allIds, ...optionIds])
}

export const deleteOrganisationCase = (state: OptionsState, action: PayloadAction<string>) => {
  const optionIdsToDelete: string[] = []
  for (const optionId of state.allIds) {
    const option = state.byIds[optionId]
    if (option.orgId === action.payload || option.id === action.payload) {
      optionIdsToDelete.push(optionId)
    }
  }
  state.byIds = omitBy(state.byIds, { orgId: action.payload })
  state.allIds = difference(state.allIds, optionIdsToDelete)
}

export const setOptionCase = (state: OptionsState, action: PayloadAction<IOptionResponse>) => {
  const option = action.payload
  state.byIds[option.id] = option
  if (!state.allIds.includes(option.id)) {
    state.allIds.push(option.id)
  }
}

export const removeOptionCase = (state: OptionsState, action: PayloadAction<string>) => {
  const optionId = action.payload
  state.byIds = omitBy(state.byIds, { id: optionId })
  state.allIds = without(state.allIds, optionId)
}
