import React from "react"
import ProjectList from "../treegrid/project-list"
import ProjectsViewHeader from "../projects/components/ProjectsViewHeader"
import CircularProgress from "@material-ui/core/CircularProgress"
import { Theme, makeStyles, useMediaQuery } from "@material-ui/core"
import { useAppContext } from "../hooks/use-app-context"
import { useFirstDayOfWeek } from "../hooks/use-first-day-of-week"
import { useRouter } from "../hooks/use-router"
import { useUserTimeZone } from "../hooks/use-user-time-zone"
import { useAllOptions } from "../options/hooks/use-all-options"
import { getOptionsForMainContext } from "../options/utils"
import { ProjectsViewOptions } from "../projects/components/ProjectsViewSelect"
import { ProjectColumnName } from "../treegrid/project-list/types"
import { useDateFormat } from "../users/hooks/use-date-format"
import { ProjectsQueryParams, useGetProjectsQuery } from "../projects/api"
import { getProjectQueryFromContext } from "../context-options/utils"
import { AppMainContextType, AppSubContextType } from "../types/app-context"
import { Status, StringMap } from "../types/common"
import { useI18n } from "../hooks/use-i18n"
import ErrorList from "../components/ErrorList"
import { useWeekendDays } from "../hooks/use-weekend-days"
import { PROJECT_TYPE } from "../projects/constants"
import TempalateList from "../treegrid/template-list"
import { useGetHolidaysQuery } from "../holidays/api"
import { useGetOrganisationByIdQuery } from "../organisations/api"
import ProjectCards from "../projects/components/ProjectCards"
import { useAuthUserMembership } from "../memberships/hooks/use-auth-user-membership"

const defaultColumnOrder: ProjectColumnName[] = [
  "open",
  "customers",
  "projectNumber",
  "title",
  "description",
  "managers",
  "participants",
  "workspaces",
  "suppliers",
  "plannedStartDate",
  "plannedEndDate",
  "actualStartDate",
  "actualEndDate",
  "actualBarStartDate",
  "actualBarEndDate",
  "status",
  "completionPercentage",
  "completionPercentageBasedOnTasks",
  "state",
  "statusDescription",
  "links",
  "rejectedDate",
  "ganttBarColor",
  "ganttGanttHtmlRight",
  "ganttRun",
]

const defaultVisibleColumnsByView: Record<ProjectsViewOptions, ProjectColumnName[]> = {
  currentAndFuture: [
    "open",
    "customers",
    "projectNumber",
    "title",
    "managers",
    "plannedStartDate",
    "plannedEndDate",
    "status",
    "completionPercentage",
    "state",
    "statusDescription",
  ],
  all: [
    "open",
    "customers",
    "projectNumber",
    "title",
    "managers",
    "plannedStartDate",
    "plannedEndDate",
    "status",
    "completionPercentage",
    "state",
    "statusDescription",
  ],
  completed: [
    "open",
    "customers",
    "projectNumber",
    "title",
    "managers",
    "actualStartDate",
    "actualEndDate",
    "state",
    "statusDescription",
  ],
  archived: [
    "open",
    "customers",
    "projectNumber",
    "title",
    "managers",
    "plannedStartDate",
    "plannedEndDate",
    "status",
    "state",
    "statusDescription",
  ],
  rejected: ["open", "customers", "projectNumber", "title", "managers", "rejectedDate", "state", "statusDescription"],
  templates: [
    "open",
    "customers",
    "projectNumber",
    "title",
    "managers",
    "plannedStartDate",
    "plannedEndDate",
    "status",
    "state",
    "statusDescription",
  ],
}

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectsRoute = () => {
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const translations = useTranslations()
  const { dateFormat, dateSeparator } = useDateFormat()
  const userTimeZone = useUserTimeZone()
  const firstDayOfWeek = useFirstDayOfWeek()
  const weekendDays = useWeekendDays()
  const router = useRouter()
  const {
    appContext: { mainContext, subContext },
  } = useAppContext()
  const context = subContext || mainContext
  const { view = "currentAndFuture" } = (router.query || {}) as { view?: ProjectsViewOptions }
  const query = makeProjectsQuery(context, view)
  const holidaysQuery = makeHolidaysQuery(mainContext)
  const { data: projects = [], isLoading, isError, status } = useGetProjectsQuery(query, queryOptions)
  const {
    data: holidays = [],
    isLoading: isLoadingHolidays,
    isError: isErrorHolidays,
  } = useGetHolidaysQuery(holidaysQuery, queryOptions)

  const organisationId = mainContext?.type === "org" ? mainContext.id : ""
  const skipFetchingOrganisation = !organisationId
  const {
    data: organisation,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation,
  } = useGetOrganisationByIdQuery(organisationId, { ...queryOptions, skip: skipFetchingOrganisation })

  const showArchivedTitle = view === "archived"
  const classes = useStyles()
  const { options } = useAllOptions()
  const [key, setKey] = React.useState(JSON.stringify(query))
  const authUserMembership = useAuthUserMembership()
  const canCreateProjects = Boolean(mainContext?.type === "user" || authUserMembership?.canCreateProjects)

  React.useEffect(() => {
    if (status === "fulfilled") {
      // Need to recreate the treegrid when the query is changed and the data is completed refetching (status = fulfilled)
      setKey(JSON.stringify(query))
    }
  }, [status, query])

  if (isLoading || isLoadingHolidays || isLoadingOrganisation) return <CircularProgress />
  if (!context || !mainContext) return <ErrorList errors={[translations.contextNotFoundError]} />
  if (isError) return <ErrorList errors={[translations.fetchErrorProjects]} />
  if (isErrorHolidays) return <ErrorList errors={[translations.fetchErrorHolidays]} />
  if (isErrorOrganisation) return <ErrorList errors={[translations.fetchErrorOrganisation]} />

  const isTemplatesView = view === "templates"
  const showTemplateList = !isSmallScreen && isTemplatesView
  const showProjectList = !isSmallScreen && !isTemplatesView
  const showProjectCards = isSmallScreen

  return (
    <div className={classes.viewContainer}>
      <ProjectsViewHeader
        canCreateProjects={canCreateProjects}
        titlePrefix={context.name}
        showArchivedTitle={showArchivedTitle}
      />
      {showProjectCards && <ProjectCards projects={projects} />}
      {showTemplateList && (
        <TempalateList
          projects={projects}
          canCreateProjects={canCreateProjects}
          timeZone={userTimeZone}
          dateFormat={dateFormat}
        />
      )}
      {showProjectList && (
        <ProjectList
          key={key}
          projects={projects}
          canCreateProjects={canCreateProjects}
          dateFormat={dateFormat}
          timeZone={userTimeZone}
          options={getOptionsForMainContext(options, mainContext)}
          gridInfo={`${context.name} - Projects`}
          dateSeparator={dateSeparator}
          firstDayOfWeek={organisation?.firstDayOfWeek || firstDayOfWeek}
          defaultColumnOrder={defaultColumnOrder}
          defaultVisibleColumns={defaultVisibleColumnsByView[view]}
          weekendDays={organisation?.weekendDays || weekendDays}
          holidays={holidays}
        />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  viewContainer: {
    marginTop: theme.spacing(0.75),
    "& > :first-child": {
      marginBottom: theme.spacing(1.125),
    },
  },
}))

const makeProjectsQuery = (
  context: AppMainContextType | AppSubContextType | null,
  view: ProjectsViewOptions
): ProjectsQueryParams => {
  const projectTypeQuery = { type: PROJECT_TYPE.NORMAL }
  if (view === "completed") {
    return {
      links: "true",
      archived: "false",
      status: [Status.COMPLETED],
      ...projectTypeQuery,
      ...getProjectQueryFromContext(context),
    }
  }

  if (view === "archived") {
    return {
      links: "true",
      archived: "true",
      ...projectTypeQuery,
      ...getProjectQueryFromContext(context),
    }
  }

  if (view === "rejected") {
    return {
      links: "true",
      archived: "false",
      status: [Status.REJECTED],
      ...projectTypeQuery,
      ...getProjectQueryFromContext(context),
    }
  }

  if (view === "currentAndFuture") {
    return {
      links: "true",
      archived: "false",
      status: [Status.NOT_STARTED, Status.IN_PROGRESS],
      ...projectTypeQuery,
      ...getProjectQueryFromContext(context),
    }
  }

  if (view === "templates") {
    return {
      type: PROJECT_TYPE.TEMPLATE,
      ...getProjectQueryFromContext(context),
    }
  }

  return { ...projectTypeQuery, ...getProjectQueryFromContext(context) }
}

const makeHolidaysQuery = (appMainContext: AppMainContextType | null) => {
  return appMainContext?.type === "org" ? { orgId: appMainContext.id } : { userId: appMainContext?.id as string }
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = t || ({} as StringMap)

  const {
    contextNotFoundError = defaults.contextNotFoundError,
    fetchErrorProjects = defaults.fetchErrorProjects,
    fetchErrorHolidays = defaults.fetchErrorHolidays,
    fetchErrorOrganisation = defaults.fetchErrorOrganisation,
    projectsEmpty = defaults.projectsEmpty,
  } = translations

  return {
    contextNotFoundError,
    fetchErrorProjects,
    fetchErrorHolidays,
    fetchErrorOrganisation,
    projectsEmpty,
    addProject: translations.projectsViewHeader?.addProject || defaults.addProject,
  }
}

const defaultTranslations = {
  contextNotFoundError: "Context not found",
  fetchErrorProjects: "Failed to fetch projects",
  fetchErrorHolidays: "Failed to fetch holidays",
  fetchErrorOrganisation: "Failed to fetch organisation",
  projectsEmpty: "You don't have any projects here yet",
  addProject: "Add project",
}
type Translations = typeof defaultTranslations

export default ProjectsRoute
