import * as roleAPI from "../../roles/role-api"
import { RoleNormalized } from "../interfaces/role-normalized"
import { useAppDispatch, useAppSelector } from "../../store"
import { getOrgRoles } from "../../roles/store/selectors"
import { FetchRoles, UpdateRoleAbilities } from "../../roles/store/actions"
import { unwrapResult } from "@reduxjs/toolkit"
import React from "react"
import { useAuthUserMembership } from "../../memberships/hooks/use-auth-user-membership"

export const useOrgRoles = (orgId: string) => {
  const dispatch = useAppDispatch()
  const roles = useAppSelector((state) => getOrgRoles(state, orgId))
  const { canUpdateAnyRole } = useAuthUserMembership()

  const updateRoleAbilities = async (roleId: string, updateData: roleAPI.RoleAbilityUpdateData) => {
    const thunkArg = { roleId, ...updateData }
    const result = await dispatch(UpdateRoleAbilities(thunkArg))
    unwrapResult(result)
  }

  const memberRole = getOrgMemberRole(roles)
  const limitedMemberRole = getLimitedMemberRole(roles)
  const projectManagerRole = getProjectManagerRole(roles)
  const projectParticipantRole = getProjectParticipantRole(roles)
  const taskManagerRole = getTaskManagerRole(roles)
  const taskParticipantRole = getTaskParticipantRole(roles)

  React.useEffect(() => {
    canUpdateAnyRole && dispatch(FetchRoles(orgId))
  }, [orgId, dispatch, canUpdateAnyRole])

  return {
    memberRole,
    limitedMemberRole,
    projectManagerRole,
    projectParticipantRole,
    taskManagerRole,
    taskParticipantRole,
    updateRoleAbilities,
  }
}

const isOrgMemberRole = (role: { name: string } | null) => {
  return role?.name === "org_member"
}
const isLimitedMemberRole = (role: { name: string } | null) => {
  return role?.name === "org_limited_member"
}
const isProjectManagerRole = (role: { name: string } | null) => {
  return role?.name === "project_manager"
}
const isProjectParticipantRole = (role: { name: string } | null) => {
  return role?.name === "project_participant"
}
const isTaskManagerRole = (role: { name: string } | null) => {
  return role?.name === "task_manager"
}
const isTaskParticipantRole = (role: { name: string } | null) => {
  return role?.name === "task_participant"
}
const getOrgMemberRole = (roles: RoleNormalized[]) => {
  return roles.find(isOrgMemberRole)
}
const getProjectManagerRole = (roles: RoleNormalized[]) => {
  return roles.find(isProjectManagerRole)
}
const getProjectParticipantRole = (roles: RoleNormalized[]) => {
  return roles.find(isProjectParticipantRole)
}
const getTaskManagerRole = (roles: RoleNormalized[]) => {
  return roles.find(isTaskManagerRole)
}
const getTaskParticipantRole = (roles: RoleNormalized[]) => {
  return roles.find(isTaskParticipantRole)
}
const getLimitedMemberRole = (roles: RoleNormalized[]) => {
  return roles.find(isLimitedMemberRole)
}
