import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"

import { makeStyles, Theme } from "@material-ui/core"
import { useI18n, useRouter } from "../../hooks"
import { StringMap } from "../../types/common"
import { NewTodoData } from "../api"
import { TodoViewModel } from "../api/todo"
import NewTodoDialog from "./NewTodoDialog"
import { useIsSmallScreen } from "../../hooks/use-is-small-screen"

const TodosViewHeaderMobile = (props: TodosViewHeaderProps) => {
  const onAddTodo = props.canAddTodos ? props.onAddTodo : undefined
  return (
    <>
      <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={8}>
          <TodosViewSelect />
        </Grid>
        {onAddTodo ? (
          <Grid item xs={4} container justifyContent="flex-end">
            <NewTodoDialog onAddTodo={onAddTodo} canAddTodos={!!props.canAddTodos} />
          </Grid>
        ) : null}
      </Grid>
    </>
  )
}

const TodosViewHeader = ({ title, canAddTodos, onAddTodo }: TodosViewHeaderProps) => {
  const translations = useTranslations()
  const isSmallScreen = useIsSmallScreen()

  if (isSmallScreen) return <TodosViewHeaderMobile title={title} onAddTodo={onAddTodo} canAddTodos={canAddTodos} />

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h6">
          {title && (
            <Box component="span" color="text.secondary">
              {`${title} - `}
            </Box>
          )}
          <Box component="span"> {translations.pageTitle} </Box>
        </Typography>
      </Grid>
      <Grid item>
        <TodosViewSelect />
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: theme.spacing(28),
    "& .MuiSelect-root": {
      fontSize: 14,
      padding: 6,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  todoDialogActions: {
    padding: theme.spacing(2),
  },
}))

const TodosViewSelect = () => {
  const classes = useStyles()
  const router = useRouter()
  const translations = useTranslations()

  const onChangeView = (view: TodosViewOptions) => {
    const resultUrl = new URL(window.location.origin)
    resultUrl.pathname = router.location.pathname
    resultUrl.search = router.location.search
    resultUrl.searchParams.set("view", view)
    router.push(resultUrl.pathname + resultUrl.search)
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="todos-view-select"
        id="todos-view-select"
        // @ts-expect-error
        value={router.query.view || "currentAndFuture"}
        onChange={(e) => onChangeView(e.target.value as TodosViewOptions)}
      >
        <MenuItem value="all">{translations.allMenuItemLabel}</MenuItem>
        <MenuItem value="currentAndFuture">{translations.currentAndFutureMenuItemLabel}</MenuItem>
        <MenuItem value="completed">{translations.completedMenuItemLabel}</MenuItem>
      </Select>
    </FormControl>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t?.todosViewHeader || {}) as StringMap

  const {
    pageTitle = defaults.pageTitle,
    allMenuItemLabel = defaults.allMenuItemLabel,
    currentAndFutureMenuItemLabel = defaults.currentAndFutureMenuItemLabel,
    completedMenuItemLabel = defaults.completedMenuItemLabel,
  } = translations

  return {
    pageTitle,
    allMenuItemLabel,
    currentAndFutureMenuItemLabel,
    completedMenuItemLabel,
  }
}

const defaultTranslations = {
  pageTitle: "To-dos",
  allMenuItemLabel: "All to-dos",
  currentAndFutureMenuItemLabel: "Current and future to-dos",
  completedMenuItemLabel: "Completed to-dos",
}

type Translations = typeof defaultTranslations

type TodosViewOptions = "all" | "currentAndFuture" | "completed"

type TodosViewHeaderProps = {
  title?: string
  canAddTodos?: boolean
  onAddTodo?: (newTodoData: NewTodoData) => Promise<TodoViewModel | void>
}

export default TodosViewHeader
