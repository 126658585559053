import { CalendarEventViewModel } from "../../../calendar-events/api/calendar-event"
import { TimeZoneType } from "../../../constants/timezones"
import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getSupplierOptions,
  getWorkspaceOptions,
} from "../../../options/utils"
import { DateTimeService } from "../../../services/date-time-service"
import { TreeGridTranslations } from "../../hooks/use-translations"
import { Option } from "../../types"
import { getColorHtmlForColor, makeEnumString, mapColorToTranslations } from "../../utils"
import { getGanttGanttHtmlRight, getTitleHtmlPrefix } from "../../utils/tree-grid"
import { CalendarEventActivityRow } from "../types"

export const makeCalendarEventActivityRow = ({
  calendarEvent,
  dateFormat,
  timeZone,
  options,
  translations,
}: MakeCalendarEventActivityRowProps): CalendarEventActivityRow => {
  const filterOptionByOrganisation = makeFilterOptionByOrganisation(calendarEvent)
  const { id, title, description, startDate, endDate, project, task, color, projectLink, taskLink, ancestorTaskLinks } =
    calendarEvent
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent: true })
  const dateTimeFormat = dateTimeService.getFormat()
  const activityNumber = task?.taskNumber || project?.projectNumber || ""
  const ganttGanttHtmlRight = getGanttGanttHtmlRight({
    title: title,
    startDate,
    endDate,
    dateTimeService,
    showTime: true,
  })
  const GanttGanttHtmlRight = "*ganttGanttHtmlRight*"
  const translatedColor = mapColorToTranslations(translations)[color]
  const ganttBarColor = getColorHtmlForColor({ color: color, translatedColor })
  const GanttGanttClass = color === "Default" ? "Blue" : color

  const customers = calendarEvent.customers.map((c) => c.id).join(";")
  const customerOptions = getCustomerOptions(options).filter(filterOptionByOrganisation)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const deactivatedCustomers = calendarEvent.customers.filter(({ id }) => !customerIds.includes(id))
  const deactivatedCustomersNames = deactivatedCustomers.map(({ name }) => name)
  const deactivatedCustomersIds = deactivatedCustomers.map(({ id }) => id)
  const customersEnum = makeEnumString([...customerNames, ...deactivatedCustomersNames])
  const customersEnumKeys = makeEnumString([...customerIds, ...deactivatedCustomersIds])

  const participants = calendarEvent.participants.map((r) => r.id).join(";")
  const participantOptions = getMembershipOptions(options).filter(filterOptionByOrganisation)
  const participantNames = participantOptions.map(getOptionName)
  const participantIds = participantOptions.map(getOptionId)
  const deactivatedParticipants = calendarEvent.participants.filter(({ id }) => !participantIds.includes(id))
  const deactivatedParticipantsNames = deactivatedParticipants.map(({ name }) => name)
  const deactivatedParticipantsIds = deactivatedParticipants.map(({ id }) => id)
  const participantEnum = makeEnumString([...participantNames, ...deactivatedParticipantsNames])
  const participantEnumKeys = makeEnumString([...participantIds, ...deactivatedParticipantsIds])

  const suppliers = calendarEvent.suppliers.map((s) => s.id).join(";")
  const supplierOptions = getSupplierOptions(options).filter(filterOptionByOrganisation)
  const supplierNames = supplierOptions.map(getOptionName)
  const supplierIds = supplierOptions.map(getOptionId)
  const deactivatedSuppliers = calendarEvent.suppliers.filter(({ id }) => !supplierIds.includes(id))
  const deactivatedSuppliersNames = deactivatedSuppliers.map(({ name }) => name)
  const deactivatedSuppliersIds = deactivatedSuppliers.map(({ id }) => id)
  const suppliersEnum = makeEnumString([...supplierNames, ...deactivatedSuppliersNames])
  const suppliersEnumKeys = makeEnumString([...supplierIds, ...deactivatedSuppliersIds])

  const workspaces = calendarEvent.workspaces.map((w) => w.id).join(";")
  const workspaceOptions = getWorkspaceOptions(options).filter(filterOptionByOrganisation)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const deactivatedWorkspaces = calendarEvent.workspaces.filter(({ id }) => !workspaceIds.includes(id))
  const deactivatedWorkspacesNames = deactivatedWorkspaces.map(({ name }) => name)
  const deactivatedWorkspacesIds = deactivatedWorkspaces.map(({ id }) => id)
  const workspacesEnum = makeEnumString([...workspaceNames, ...deactivatedWorkspacesNames])
  const workspacesEnumKeys = makeEnumString([...workspaceIds, ...deactivatedWorkspacesIds])

  const plannedStartDateCanEdit = calendarEvent.isRecurringEvent ? 0 : calendarEvent.canUpdate ? 1 : 0
  const plannedEndDateCanEdit = calendarEvent.isRecurringEvent ? 0 : calendarEvent.canUpdate ? 1 : 0

  return {
    id,
    type: "calendarEvent",
    title,
    titleHtmlPrefix: getTitleHtmlPrefix({ projectLink, taskLink, ancestorTaskLinks }),
    activityNumber,
    description,
    GanttGanttHtmlRight,
    ganttGanttHtmlRight,
    GanttGanttClass,
    ganttBarColor,
    ganttBarColorFilterValue: translatedColor,
    plannedStartDate: getPlannedStartDate(),
    plannedStartDateFormat: dateTimeFormat,
    plannedEndDate: getPlannedEndDate(),
    plannedEndDateFormat: dateTimeFormat,
    customers,
    participants,
    suppliers,
    workspaces,
    customersEnum,
    customersEnumKeys,
    participantsEnum: participantEnum,
    participantsEnumKeys: participantEnumKeys,
    suppliersEnum,
    suppliersEnumKeys,
    workspacesEnum,
    workspacesEnumKeys,
    links: null,
    completionPercentage: "",
    completionPercentageHtmlPostfix: "",
    completionPercentageBasedOnTasks: "",
    completionPercentageBasedOnTasksHtmlPostfix: "",
    activityNumberCanEdit: 0,
    statusCanEdit: 0,
    stateCanEdit: 0,
    statusDescriptionCanEdit: 0,
    responsibleCanEdit: 0,
    actualStartDateCanEdit: 0,
    titleCanEdit: calendarEvent.canUpdate ? 1 : 0,
    descriptionCanEdit: calendarEvent.canUpdate ? 1 : 0,
    ganttBarColorCanEdit: calendarEvent.canUpdate ? 1 : 0,
    plannedEndDateCanEdit,
    plannedStartDateCanEdit,
    customersCanEdit: calendarEvent.canUpdate && calendarEvent.organisation ? 1 : 0,
    participantsCanEdit: calendarEvent.canUpdate && calendarEvent.organisation ? 1 : 0,
    suppliersCanEdit: calendarEvent.canUpdate && calendarEvent.organisation ? 1 : 0,
    workspacesCanEdit: calendarEvent.canUpdate && calendarEvent.organisation ? 1 : 0,
    completionPercentageCanEdit: 0,
    completionPercentageBasedOnTasksCanEdit: 0,
    Height: 48,
    MaxHeight: 48,
  }

  function getPlannedStartDate(): number {
    return dateTimeService.removeTimezoneOffset(startDate, "UTC").getTime()
  }

  function getPlannedEndDate(): number {
    return dateTimeService.removeTimezoneOffset(endDate, "UTC").getTime()
  }
}

function makeFilterOptionByOrganisation(calendarEvent: CalendarEventViewModel) {
  return function (option: Option) {
    return option.orgId === calendarEvent.organisation?.id
  }
}

type MakeCalendarEventActivityRowProps = {
  calendarEvent: CalendarEventViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: Option[]
  translations: TreeGridTranslations
}
