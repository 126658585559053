import TimeZoneSelectWidget from "../../components/TimeZoneSelectWidget"
import DateFormatSelectWidget from "../../components/DateFormatSelectWidget"
import DateFormatSeparatorSelectWidget from "../../components/DateFormatSeparatorSelectWidget"
import WeekendDaysSelectWidget from "../../components/WeekendDaysSelectWidget"
import FirstDayOfWeekSelectWidget from "../../components/FirstDayOfWeekSelectWidget"

import {
  alpha,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core"
import { COLOR_WHITE } from "../../constants"
import { StringMap } from "../../types/common"
import { useI18n } from "../../hooks"
import { useConfirmDeleteAccount } from "../hooks/use-confirm-delete-account"
import { AlertTriangle, X } from "react-feather"
import { Alert } from "@material-ui/lab"
import { useAppContext } from "../../hooks/use-app-context"
import { useDispatch } from "react-redux"
import { ClearStorageAndAuthState } from "../store/actions"
import { useHistory } from "react-router-dom"
import { paths } from "../../paths"
import { useGetUserInfoQuery, UserInfoUpdateData } from "../api"
import { useUserMutations } from "../hooks/use-user-mutations"

const UserGeneralSettings = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const translations = useTranslations()
  const { data: user, isLoading } = useGetUserInfoQuery()
  const { updateUserInfo } = useUserMutations()
  const { appContext } = useAppContext()

  const {
    status,
    dialogOpen,
    openDialog,
    closeDialog,
    confirmAndCloseDialog,
    confirmationText,
    confirmPassword,
    setConfirmationText,
    setConfirmPassword,
    isDeleting,
    disableDeletion,
  } = useConfirmDeleteAccount({
    deleteErrorMessage: translations.deleteErrorMessage,
  })

  const shouldShowOrganisationTransferInfo = appContext.mainContextList?.length > 1
  const openSuccessDialog = status === "success"
  const closeSuccessDialog = () => {
    dispatch(ClearStorageAndAuthState())
    history.push(paths.login())
  }

  if (isLoading || !user) return <CircularProgress />

  const onUpdateUserInfo = (updateData: UserInfoUpdateData) => updateUserInfo(user.id, updateData)

  if (openSuccessDialog) {
    return (
      <Dialog open={openSuccessDialog}>
        <DialogTitle>{translations.deleteSuccessDialogTitle}</DialogTitle>
        <DialogContent>
          <Typography variant="subtitle2" className={classes.deleteSuccessDialogText}>
            {translations.deleteSuccessDialogText}
          </Typography>
          <Typography variant="subtitle2">{translations.deleteSuccessDialogGreetingText}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeSuccessDialog} color="primary">
            {translations.deleteSuccessDialogCloseButtonLabel}
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <Grid container direction="column" style={{ margin: "auto", maxWidth: 850 }}>
      <Grid item container direction="row" className={classes.daysSection}>
        <TimeZoneSelectWidget timeZone={user?.timeZone} onTimeZoneChange={onUpdateUserInfo} />
        <DateFormatSelectWidget
          dateFormat={user?.dateFormat}
          dateFormatSeparator={user?.dateFormatSeparator}
          onDateFormatChange={onUpdateUserInfo}
        />
        <DateFormatSeparatorSelectWidget
          dateFormatSeparator={user?.dateFormatSeparator}
          onDateFormatSeparatorChange={onUpdateUserInfo}
        />
        <WeekendDaysSelectWidget weekendDays={user?.weekendDays} onWeekendDaysChange={onUpdateUserInfo} />
        <FirstDayOfWeekSelectWidget firstDayOfWeek={user?.firstDayOfWeek} onFirstDayOfWeekChange={onUpdateUserInfo} />
      </Grid>

      <section className={classes.deleteAccountSection}>
        <Typography variant="h6" color="error">
          {translations.deleteAccountHeader}
        </Typography>
        {shouldShowOrganisationTransferInfo && <Alert severity="info">{translations.deleteAccountInfoText}</Alert>}

        <Grid container className={classes.deleteAccountButtonSection}>
          <Grid item alignContent="center">
            <Typography variant="body1">{translations.deleteAccountDescription}</Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="secondary"
              size="small"
              className={classes.deleteAccountButton}
              onClick={openDialog}
            >
              {translations.deleteAccountButtonLabel}
            </Button>
          </Grid>
        </Grid>

        <Dialog open={dialogOpen} onClose={closeDialog}>
          <DialogTitle id="confirm-organisation-deletion-dialog">
            {translations.deleteAccountConfirmationDialogTitle}
            <IconButton aria-label="close" className={classes.deleteAccountDialogCloseButton} onClick={closeDialog}>
              <X size={20} />
            </IconButton>
          </DialogTitle>
          <DialogContent className={classes.deleteAccountDialogContent}>
            <Alert className={classes.deleteAccountDialogAlert} severity="warning" icon={<AlertTriangle size={20} />}>
              {translations.deleteAccountConfirmationDialogAlertText}
            </Alert>
            <Typography>{translations.deleteAccountConfirmationDialogText}</Typography>
          </DialogContent>
          <Divider />
          <footer className={classes.deleteAccountDialogFooter}>
            <Typography variant="subtitle1" className={classes.userInfoSection}>
              {`${translations.deleteAccountDialogUserInfoText}: `}
              <br />
              {`${translations.usernameText}: `}
              <strong>{user?.fullname}</strong>
              {" , "}
              {`${translations.emailText}: `}
              <strong>{user?.email}</strong>
            </Typography>
            <Divider />
            <Typography variant="subtitle1">
              <strong>{`${translations.deleteAccountConfirmationPasswordLabel}`}</strong>
            </Typography>
            <TextField
              type="password"
              margin="dense"
              variant="outlined"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              fullWidth
            />
            <Typography variant="subtitle1">
              <strong>{`${translations.deleteAccountExtraConfirmationTextLabel}: 'delete my account'`}</strong>
            </Typography>
            <TextField
              margin="dense"
              variant="outlined"
              value={confirmationText}
              onChange={(e) => setConfirmationText(e.target.value)}
              fullWidth
            />
            <Button
              variant="contained"
              size="small"
              onClick={confirmAndCloseDialog}
              disabled={disableDeletion}
              startIcon={isDeleting ? <CircularProgress size={22.75} /> : null}
              fullWidth
            >
              {isDeleting ? "" : translations.deleteAccountButtonLabel}
            </Button>
          </footer>
        </Dialog>
      </section>
    </Grid>
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t.userSettingsView || {}) as StringMap
  const {
    pageTitle = defaults.pageTitle,
    deleteAccountHeader = defaults.deleteAccountHeader,
    deleteAccountDescription = defaults.deleteAccountDescription,
    deleteAccountInfoText = defaults.deleteAccountInfoText,
    deleteAccountButtonLabel = defaults.deleteAccountButtonLabel,
    deleteAccountConfirmationDialogTitle = defaults.deleteAccountConfirmationDialogTitle,
    deleteAccountConfirmationDialogAlertText = defaults.deleteAccountConfirmationDialogAlertText,
    deleteAccountConfirmationDialogText = defaults.deleteAccountConfirmationDialogText,
    deleteAccountExtraConfirmationTextLabel = defaults.deleteAccountExtraConfirmationTextLabel,
    deleteAccountConfirmationPasswordLabel = defaults.deleteAccountConfirmationPasswordLabel,
    deleteErrorMessage = defaults.deleteErrorMessage,
    deleteAccountDialogUserInfoText = defaults.deleteAccountDialogUserInfoText,
    usernameText = defaults.usernameText,
    emailText = defaults.emailText,
    deleteSuccessDialogTitle = defaults.deleteSuccessDialogTitle,
    deleteSuccessDialogText = defaults.deleteSuccessDialogText,
    deleteSuccessDialogGreetingText = defaults.deleteSuccessDialogGreetingText,
    deleteSuccessDialogCloseButtonLabel = defaults.deleteSuccessDialogCloseButtonLabel,
  } = translations

  return {
    pageTitle,
    deleteAccountHeader,
    deleteAccountDescription,
    deleteAccountInfoText,
    deleteAccountButtonLabel,
    deleteAccountConfirmationDialogTitle,
    deleteAccountConfirmationDialogAlertText,
    deleteAccountConfirmationDialogText,
    deleteAccountExtraConfirmationTextLabel,
    deleteAccountConfirmationPasswordLabel,
    deleteErrorMessage,
    deleteAccountDialogUserInfoText,
    usernameText,
    emailText,
    deleteSuccessDialogTitle,
    deleteSuccessDialogText,
    deleteSuccessDialogGreetingText,
    deleteSuccessDialogCloseButtonLabel,
  }
}

const defaultTranslations = {
  pageTitle: "General settings",
  deleteAccountHeader: "Delete my account",
  deleteAccountDescription: "You cannot undo this action. Please be certain.",
  deleteAccountInfoText:
    "Before you proceed, please make sure that you are not an organisation owner. If you are, you need to either transfer the ownership to another user or delete the organisation.",
  deleteAccountButtonLabel: "Delete account",
  deleteAccountConfirmationDialogTitle: "Are you sure?",
  deleteAccountConfirmationDialogAlertText: "Before proceeding, please  read the following text carefully.",
  deleteAccountConfirmationDialogText:
    "This action will result in the permanent deletion of the user, along with its projects, tasks, to-dos, calendar events, and all other associated data.",
  deleteAccountExtraConfirmationTextLabel: "Please enter this text in the box below",
  deleteAccountConfirmationPasswordLabel: "Please enter your password",
  deleteErrorMessage: "An error occurred while deleting the account!",

  deleteAccountDialogUserInfoText: "You are deleting the account associated with the following details",
  usernameText: "User name",
  emailText: "Email",

  deleteSuccessDialogTitle: "Account deletion successful!",
  deleteSuccessDialogText:
    "We have now permanently deleted your user account, along with its projects, tasks, to-dos, calendar events, and all other associated data.",
  deleteSuccessDialogGreetingText: "Thank you for using starbrix. We hope to see you again soon!",
  deleteSuccessDialogCloseButtonLabel: "Close",
}
type Translations = typeof defaultTranslations

const useStyles = makeStyles((theme: Theme) => ({
  pageHeader: {
    margin: theme.spacing(2, 0),
  },
  permissions: {
    paddingBottom: theme.spacing(10),
  },
  daysSection: {
    backgroundColor: COLOR_WHITE,
  },
  deleteAccountSection: {
    backgroundColor: COLOR_WHITE,
    padding: theme.spacing(4),
    margin: theme.spacing(4, 0),
  },
  deleteAccountButton: {
    marginLeft: theme.spacing(2),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.light,
    "&:hover": {
      backgroundColor: theme.palette.error.main,
    },
  },
  deleteAccountDialogContent: {
    marginBottom: theme.spacing(2),
  },
  deleteAccountButtonSection: {
    marginTop: theme.spacing(2),
  },
  deleteAccountDialogFooter: {
    padding: theme.spacing(2),
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(),
    },
    "& fieldset": {
      borderColor: theme.palette.common.black,
    },
    "& button": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  deleteAccountDialogAlert: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    background: alpha(theme.palette.warning.main, 0.4),
    "& .MuiAlert-icon": {
      fontSize: 16,
      color: theme.palette.common.black,
    },
  },
  deleteAccountDialogCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.secondary,
  },

  userInfoSection: {
    marginBottom: theme.spacing(2),
  },

  deleteSuccessDialogText: {
    marginBottom: theme.spacing(2),
  },
}))

export default UserGeneralSettings
