import { forEach } from "lodash"
import { HolidaysProcessor } from "../../holidays/utils/HolidaysProcessor"
import { addDays, format } from "date-fns"
import { treegridDateFormat } from "."

export class TreegridHolidaysProcessor extends HolidaysProcessor {
  public getHolidaysAsGanttBackground = (): string => {
    let GanttBackground = ""
    const singleHolidays = this.getSingleHolidays()
    forEach(singleHolidays, (holiday) => {
      const ganttExcludeStart = format(holiday.date, treegridDateFormat)
      const ganttExcludeEnd = format(addDays(holiday.date, 1), treegridDateFormat)
      const background = `#${ganttExcludeStart}~${ganttExcludeEnd}#1;`
      if (holiday.recurring) GanttBackground += `y${background}`
      else GanttBackground += background
    })

    const rangeHolidays = this.getRangeHolidays()
    forEach(rangeHolidays, (holiday) => {
      const ganttExcludeStart = format(holiday.startDate, treegridDateFormat)
      const ganttExcludeEnd = format(addDays(holiday.endDate, 1), treegridDateFormat)
      const background = `#${ganttExcludeStart}~${ganttExcludeEnd}#1;`
      if (holiday.recurring) GanttBackground += `y${background}`
      else GanttBackground += background
    })

    return GanttBackground
  }
}
