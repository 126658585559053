import HolidayList from "../../../../treegrid/holiday-list"
import HolidayCards from "../../../../holidays/components/HolidayCards"
import * as holidayApi from "../../../../holidays/api"
import { CircularProgress, makeStyles, Theme } from "@material-ui/core"
import { useGetHolidaysQuery } from "../../../../holidays/api"
import { useAppContext } from "../../../../hooks/use-app-context"
import { useDateFormat } from "../../../../users/hooks/use-date-format"
import { useUserTimeZone } from "../../../../hooks/use-user-time-zone"
import { useHolidayMutations } from "../../../../holidays/hooks/use-holiday-mutations"
import { useGetOrganisationByIdQuery } from "../../../../organisations/api"
import { useIsSmallScreen } from "../../../../hooks/use-is-small-screen"

const OrgHolidaysList = () => {
  const classes = useStyles()
  const orgId = useAppContext().appContext.mainContext?.id as string
  const { data: holidays = [], isLoading: isLoadingHolidays } = useGetHolidaysQuery(
    { orgId },
    { refetchOnMountOrArgChange: true }
  )
  const { data: organisation, isLoading: isLoadingOrg } = useGetOrganisationByIdQuery(orgId)
  const { dateFormat, dateSeparator } = useDateFormat()
  const userTimeZone = useUserTimeZone()
  const { createHoliday } = useHolidayMutations()
  const isSmallScreen = useIsSmallScreen()

  const isLoading = isLoadingHolidays || isLoadingOrg
  if (isLoading) return <CircularProgress />

  const canManageHolidays = organisation?.canUpdateDetails || false

  function addHoliday(newHoliday: holidayApi.BaseNewHolidayData) {
    return createHoliday({ ...newHoliday, orgId })
  }

  if (isSmallScreen) {
    return <HolidayCards holidays={holidays} onAddHoliday={addHoliday} canManageHolidays={canManageHolidays} />
  }

  return (
    <div className={classes.viewContainer}>
      <HolidayList
        holidays={holidays}
        dateFormat={dateFormat}
        timeZone={userTimeZone}
        firstDayOfWeek={organisation?.firstDayOfWeek || 1}
        dateSeparator={dateSeparator}
        onAddHoliday={addHoliday}
        canCreateHoliday={canManageHolidays}
      />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  viewContainer: {
    marginTop: theme.spacing(1),
    "& > :first-child": {
      marginBottom: theme.spacing(1.125),
    },
  },
}))

export default OrgHolidaysList
