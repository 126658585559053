import { cloneDeep } from "lodash"
import { TaskViewModel } from "../api/task"

const getTime = (date: string | undefined) => (date ? new Date(date).getTime() : Infinity)

export const sortTasksByPlan = (tasks: TaskViewModel[]): TaskViewModel[] => {
  const cloned = cloneDeep(tasks)
  return cloned.sort((a, b) => {
    const endTimeA = getTime(a.plannedEndDate)
    const endTimeB = getTime(b.plannedEndDate)

    if (endTimeA !== endTimeB) return endTimeA - endTimeB

    const startTimeA = getTime(a.plannedStartDate)
    const startTimeB = getTime(b.plannedStartDate)

    return startTimeA - startTimeB
  })
}
