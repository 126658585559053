import { createAsyncThunk } from "@reduxjs/toolkit"
import type { RootState } from "../../store"
import type { IContextOptionsAPI } from "../interfaces/context-options-api"
import * as ContextOptionsAPI from "../services/options-api"

export const makeFetchContextOptions = (contextOptionsAPI: IContextOptionsAPI) => {
  return createAsyncThunk("context-options/fetchContextOptions", async (_, { getState }) => {
    const { user } = (getState() as RootState).auth
    return await contextOptionsAPI.fetchContextOptions(user?.id || "")
  })
}

export const FetchContextOptions = makeFetchContextOptions(ContextOptionsAPI)
