import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import TodosViewHeader from "../todos/components/TodosViewHeader"
import TodoList from "../treegrid/todo-list/TodoList"
import { useAppContext } from "../hooks/use-app-context"
import { useAllOptions } from "../options/hooks/use-all-options"
import { useTodoMutations } from "../todos/hooks/use-todo-mutations"
import { useAuthUserMembership } from "../memberships/hooks/use-auth-user-membership"
import { todoApi, NewTodoData, TodoQueryParams } from "../todos/api"
import { useFirstDayOfWeek } from "../hooks/use-first-day-of-week"
import { useUserTimeZone } from "../hooks/use-user-time-zone"
import { useDateFormat } from "../users/hooks/use-date-format"
import { Theme, makeStyles, useMediaQuery } from "@material-ui/core"
import { getOptionsForMainContext } from "../options/utils"
import { getTodoQueryFromContext } from "../context-options/utils"
import { AppMainContextType, AppSubContextType } from "../types/app-context"
import { useI18n, useRouter } from "../hooks"
import { StringMap } from "../types/common"
import ErrorList from "../components/ErrorList"
import { useGetOrganisationByIdQuery } from "../organisations/api"
import TodoListMobile from "../todos/components/TodoListMobile"
import TodoListEmpty from "../todos/components/TodoListEmpty"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TodosRoute = () => {
  const translations = useTranslations()
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))

  const {
    appContext: { mainContext, subContext },
  } = useAppContext()
  const context = subContext || mainContext
  const router = useRouter()
  // @ts-expect-error - Ts is not aware of the query object
  const todosView = (router.query.view || "currentAndFuture") as TodosViewOptions
  const query = makeTodosQuery(context, todosView)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodo,
    status,
  } = todoApi.useGetTodosQuery(query, queryOptions)

  const organisationId = mainContext?.type === "org" ? mainContext.id : ""
  const skipFetchingOrganisation = !organisationId
  const {
    data: organisation,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation,
  } = useGetOrganisationByIdQuery(organisationId, { ...queryOptions, skip: skipFetchingOrganisation })

  const { options } = useAllOptions()
  const { createTodo } = useTodoMutations()
  const { canCreateTodos } = useAuthUserMembership()
  const classes = useStyles()
  const firstDayOfWeek = useFirstDayOfWeek(organisation?.id)
  const userTimeZone = useUserTimeZone()
  const { dateFormat, dateSeparator } = useDateFormat()
  const [key, setKey] = React.useState(JSON.stringify(query))

  React.useEffect(() => {
    if (status === "fulfilled") {
      // Need to recreate the treegrid when the query is changed and the data is completed refetching (status = fulfilled)
      setKey(JSON.stringify(query))
    }
  }, [status, query])

  const isLoading = isLoadingTodos || isLoadingOrganisation
  const isError = isErrorTodo || isErrorOrganisation
  const queryErrors = {} as QueryErrors
  if (isErrorTodo) queryErrors.todo = translations.fetchErrorTodos
  if (isErrorOrganisation) queryErrors.organisation = translations.fetchErrorOrganisation

  if (isLoading) return <CircularProgress />
  if (!context || !mainContext) return <ErrorList errors={[translations.contextNotFoundError]} />
  if (isError) return <ErrorList errors={queryErrors} />

  const canAddTodos = (mainContext.type === "user" || canCreateTodos) && todosView !== "completed"

  function onAddTodo(newTodo: NewTodoData) {
    const newTodoData = { ...newTodo }
    if (mainContext && mainContext.type === "org") {
      newTodoData.organisation = mainContext.id
    }
    if (context) {
      if (context.type === "customer") {
        newTodoData.customers = [context.id]
      } else if (context.type === "member") {
        newTodoData.responsible = [context.id]
      } else if (context.type === "workspace") {
        newTodoData.workspaces = [context.id]
      }
    }
    return createTodo(newTodoData)
  }

  return (
    <div className={classes.viewContainer}>
      <TodosViewHeader title={context.name} canAddTodos={canAddTodos} onAddTodo={onAddTodo} />
      {isSmallScreen ? (
        todos.length ? (
          <TodoListMobile todos={todos} />
        ) : (
          <TodoListEmpty canAddTodos={false} onAddTodo={onAddTodo} />
        )
      ) : (
        <TodoList
          key={key}
          todos={todos}
          gridInfo={`${context.name} - Todos`}
          dateFormat={dateFormat}
          dateSeparator={dateSeparator}
          firstDayOfWeek={firstDayOfWeek}
          timeZone={userTimeZone}
          options={getOptionsForMainContext(options, mainContext)}
          canCreateTodos={canAddTodos}
          onAddTodo={onAddTodo}
        />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  viewContainer: {
    marginTop: theme.spacing(0.75),
    "& > :first-child": {
      marginBottom: theme.spacing(1.125),
    },
  },
}))

const makeTodosQuery = (
  context: AppMainContextType | AppSubContextType | null,
  view: TodosViewOptions
): TodoQueryParams => {
  if (view === "completed") {
    return {
      completed: "true",
      ...getTodoQueryFromContext(context),
    }
  }

  if (view === "currentAndFuture") {
    return {
      completed: "false",
      ...getTodoQueryFromContext(context),
    }
  }

  return getTodoQueryFromContext(context)
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = t || ({} as StringMap)

  const {
    contextNotFoundError = defaults.contextNotFoundError,
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorOrganisation = defaults.fetchErrorOrganisation,
  } = translations

  return {
    contextNotFoundError,
    fetchErrorTodos,
    fetchErrorOrganisation,
  }
}

const defaultTranslations = {
  contextNotFoundError: "Context not found",
  fetchErrorTodos: "Failed to fetch to-dos",
  fetchErrorOrganisation: "Failed to fetch organisation",
}
type Translations = typeof defaultTranslations
type TodosViewOptions = "all" | "currentAndFuture" | "completed"
type QueryErrors = {
  todo?: string
  organisation?: string
}

export default TodosRoute
