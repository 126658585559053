import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import React from "react"
import CalendarForm from "./CalendarForm"
import TaskCardTitle from "../../tasks/components/TaskCardTitle"
import TodoCardTitle from "../../todos/components/TodoCardTitle"
import CalendarEventCardTitle from "./CalendarEventCardTitle"
import TaskDetails from "../../tasks/components/TaskDetails"
import TodoDetails from "../../todos/components/TodoDetails"
import CalendarEventDetails from "./CalendarEventDetails"
import FullPath from "../../components/FullPath"
import Button from "@material-ui/core/Button"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import Typography from "@material-ui/core/Typography"
import { makeStyles, Theme } from "@material-ui/core"
import { X } from "react-feather"
import { useLocation } from "react-router-dom"
import {
  CalendarEventData,
  CalendarEventUpdates,
  CalendarTaskData,
  CalendarTaskUpdates,
  CalendarTodoData,
  CalendarTodoUpdates,
  EventType,
  RecurringEventType,
} from "../utils"
import { DateTimeService } from "../../services/date-time-service"
import { useAuthUser } from "../../users/hooks/use-auth-user"
import { useDateFormat } from "../../users/hooks/use-date-format"
import { TaskViewModel } from "../../tasks/api/task"
import { CalendarEventViewModel } from "../api/calendar-event"
import { TodoViewModel } from "../../todos/api/todo"
import { useI18n } from "../../hooks"
import { useDateTranslations } from "../../hooks/use-date-translations"
import { HolidayViewModel } from "../../holidays/api/holiday"
import { HolidaysProcessor } from "../../holidays/utils/HolidaysProcessor"
import { useIsSmallScreen } from "../../hooks/use-is-small-screen"

const SCHEDULER_HEADER = {
  full: ["day", "week", "month", "minicalendar", "date", "prev", "today", "next"],
  compact: {
    rows: [{ cols: ["date"] }, { cols: ["day", "week", "minicalendar", "spacer", "prev", "today", "next"] }],
  },
}

const useEditRecurringEvent = () => {
  const [currentRecurringEvent, setCurrentRecurringEvent] = React.useState<RecurringEventType | null>(null)
  const [openEditRecurringEventConfirmation, setOpenEditRecurringEventConfirmation] = React.useState(false)

  const handleClickRecurringEvent = (event: RecurringEventType) => {
    setCurrentRecurringEvent(event)
    setOpenEditRecurringEventConfirmation(true)
  }

  const clearState = () => {
    setCurrentRecurringEvent(null)
    setOpenEditRecurringEventConfirmation(false)
  }

  return {
    openEditRecurringEventConfirmation,
    currentRecurringEvent,
    handleClickRecurringEvent,
    setOpenEditRecurringEventConfirmation,
    clearState,
  }
}

const Calendar = ({
  id = "__scheduler__v2",
  events,
  recurringEvents,
  entitiesById,
  canAddCalendarEvents,
  canAddTasks,
  canAddTodos,
  onAddCalendarEvent,
  onAddTask,
  onAddTodo,
  onUpdateCalendarEvent,
  onUpdateTask,
  onUpdateTodo,
  weekendDays = [],
  holidays = [],
  firstDayOfWeek = 1,
  dateTimeService,
  showCaption = false,
}: CalendarProps) => {
  const { user } = useAuthUser()
  const { dateFormat } = useDateFormat()
  const defaultDateTimeService = React.useMemo(() => {
    return (
      dateTimeService ||
      new DateTimeService({
        dateFormat,
        timeZone: user?.timeZone,
      })
    )
  }, [dateTimeService, dateFormat, user?.timeZone])

  const timeZonedNormalEvents = React.useMemo(() => {
    return events.map((event: EventType) => {
      const { start_date, end_date } = event
      const zonedStartDate = defaultDateTimeService.removeTimezoneOffset(start_date)
      const zonedEndDate = defaultDateTimeService.removeTimezoneOffset(end_date)
      return { ...event, start_date: zonedStartDate, end_date: zonedEndDate }
    })
  }, [defaultDateTimeService, events])
  const timeZonedRecurringEvents = React.useMemo(() => {
    return recurringEvents.map((event: RecurringEventType) => {
      const { start_date, end_date } = event
      const zonedStartDate = defaultDateTimeService.removeTimezoneOffset(start_date)
      const zonedEndDate = end_date
        ? defaultDateTimeService.removeTimezoneOffset(end_date)
        : defaultDateTimeService.getLargestPossibleDate()

      let zonedEventLength = event.event_length
      if (event.event_pid) {
        const eventLenthDate = new Date(zonedEventLength * 1000)
        const zonedEventLengthDate = defaultDateTimeService.removeTimezoneOffset(eventLenthDate)
        zonedEventLength = zonedEventLengthDate.getTime() / 1000
      }

      return { ...event, start_date: zonedStartDate, end_date: zonedEndDate, event_length: zonedEventLength }
    })
  }, [defaultDateTimeService, recurringEvents])
  const timeZonedEvents = React.useMemo(() => {
    return [...timeZonedNormalEvents, ...timeZonedRecurringEvents]
  }, [timeZonedNormalEvents, timeZonedRecurringEvents])

  const { currentRecurringEvent, handleClickRecurringEvent, openEditRecurringEventConfirmation, clearState } =
    useEditRecurringEvent()

  const classes = useStyles()
  const translations = useTranslations()
  const location = useLocation()
  const isSmallScreen = useIsSmallScreen()
  const calendarInstanceRef = React.useRef<SchedulerStatic | null>(null)
  const lightBoxRootElRef = React.useRef<HTMLDivElement | null>(null)
  const eventFormTitleInputRef = React.useRef<HTMLInputElement | null>(null)
  const [startDate, setStartDate] = React.useState<string | null>(null)
  const [endDate, setEndDate] = React.useState<string | null>(null)
  const [currentEventId, setCurrentEventId] = React.useState<string | null>(null)
  const [openNewOccurance, setOpenNewOccurance] = React.useState<Boolean>(false)
  const [newlyCreatedEventId, setNewlyCreatedEventId] = React.useState<string | null>(null)

  const currentEvent = timeZonedEvents.find((evt) => evt.id === currentEventId)
  const newEvent = timeZonedEvents.find((evt) => evt.id === newlyCreatedEventId)
  const onBeforeEventChanged = (event: EventType, _: any, isNew: boolean) => {
    const eventEndDate = defaultDateTimeService.addTimezoneOffset(event.end_date).toISOString()
    const eventStartDate = defaultDateTimeService.addTimezoneOffset(event.start_date).toISOString()
    if (isNew) {
      // sync startDate and endDate with dates of new event
      setStartDate(eventStartDate)
      setEndDate(eventEndDate)
      return true // returning falsy from here will cancel this event and cause undesired behaviour
    } else if (event.type === "task") {
      return onUpdateTask(event.id, {
        plannedEndDate: eventEndDate,
        plannedStartDate: eventStartDate,
      })
    } else if (event.type === "todo") {
      const dueDate = eventEndDate
      let enableTimeComponent = false
      if (dueDate && !defaultDateTimeService.hasMidnightTime(dueDate)) enableTimeComponent = true
      return onUpdateTodo(event.id, { dueDate, enableTimeComponent })
    } else if (event.type === "calendarEvent") {
      const [parentEventId, eventLength] = event.id.split("#")
      const shouldCreateNewOccurance = Boolean(eventLength)
      if (shouldCreateNewOccurance) {
        const eventLengthDate = new Date(parseInt(eventLength) * 1000)
        const timeZonedEventLengthDate = defaultDateTimeService.addTimezoneOffset(eventLengthDate)

        return onAddCalendarEvent({
          title: event.text,
          startDate: eventStartDate,
          endDate: eventEndDate,
          description: event.details || "",
          parentEventId,
          eventLength: timeZonedEventLengthDate.getTime(),
        })
      }

      return onUpdateCalendarEvent(event.id, {
        startDate: eventStartDate,
        endDate: eventEndDate,
      })
    }
    throw new Error("Unexpected error")
  }

  const onEventAdded = (id: string) => {
    calendarInstanceRef.current?.deleteEvent(id)
  }

  const showLightBox = (id: string) => {
    if (canAddTasks || canAddTodos || canAddCalendarEvents) {
      if (calendarInstanceRef.current && lightBoxRootElRef.current) {
        calendarInstanceRef.current.startLightbox(id, lightBoxRootElRef.current)
        eventFormTitleInputRef.current?.focus()
      }
    }
  }

  const closeForm = (save: boolean) => {
    calendarInstanceRef.current?.endLightbox(save, lightBoxRootElRef.current as HTMLElement)
    // reset state of start and end date
    setStartDate(null)
    setEndDate(null)
    setCurrentEventId(null)
    setNewlyCreatedEventId(null)
  }

  const handleBeforeEventChange = React.useCallback(onBeforeEventChanged, [
    defaultDateTimeService,
    onUpdateTask,
    onUpdateTodo,
    onUpdateCalendarEvent,
    onAddCalendarEvent,
  ])
  const handleShowLightBox = React.useCallback(showLightBox, [canAddTasks, canAddTodos, canAddCalendarEvents])
  const handleEventAdded = React.useCallback(onEventAdded, [])
  const handleClickEditOccurrence = () => {
    if (currentRecurringEvent) {
      const foundEvent = timeZonedRecurringEvents.find((event) => event.id === currentRecurringEvent.id)
      if (foundEvent) {
        setCurrentEventId(currentRecurringEvent.id)
        clearState()
        return
      }

      const parentEvent = timeZonedRecurringEvents.find((event) => event.id === currentRecurringEvent.event_pid)
      if (!parentEvent) return clearState()
      const endDate = new Date(
        currentRecurringEvent.start_date.getTime() + parentEvent.event_length * 1000
      ).toISOString()
      const newEvent: CalendarEventData = {
        title: currentRecurringEvent.text,
        startDate: currentRecurringEvent.start_date.toISOString(),
        endDate: endDate,
        description: parentEvent.details || "",
        parentEventId: currentRecurringEvent.event_pid,
      }
      onAddCalendarEvent(newEvent)
      setOpenNewOccurance(true)
    } else {
      clearState()
    }
  }

  React.useEffect(() => {
    const calendar = calendarInstanceRef.current
    if (!calendar) return

    // Render header based on screen size
    const header = isSmallScreen ? SCHEDULER_HEADER.compact : SCHEDULER_HEADER.full
    // @ts-expect-error
    calendar.config.header = header
    calendar.xy.nav_height = isSmallScreen ? 80 : 60
    calendar.setCurrentView(new Date(), isSmallScreen ? "day" : "week")

    // Header date based on screen size
    calendar.templates.week_scale_date = function (date) {
      const dayOfWeek = date.getDay() as DayOfWeek
      const day = date.getDate()
      const month = date.getMonth()

      const dayName = translations.abbreviatedDayNames[dayOfWeek]
      const monthName = translations.fullMonthNames[month]

      const holidaysProcessor = new HolidaysProcessor({ holidays, timeZone: defaultDateTimeService.getTimeZone() })
      const isHoliday = holidaysProcessor.isHoliday(date)
      const holidayName = holidaysProcessor.getHolidayName(date)
      const textToDisplay = `${dayName} ${day} ${isSmallScreen ? "" : monthName}`

      return isHoliday
        ? `<span data-tooltip="${holidayName}" class="custom-tooltip"><span class="overflow-hidden">${textToDisplay}<img src="/info.svg"></span></span>`
        : `<span class="overflow-hidden">${textToDisplay}</span>`
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSmallScreen])

  React.useLayoutEffect(() => {
    function eventTextTemplate(start: Date, end: Date, event: EventType) {
      return `<span class="dhx_calendar_title">${event.text}</span>`
    }
    if (!calendarInstanceRef.current) {
      const schedulerConfig = {
        container: id,
        config: {
          readonly: !canAddTasks && !canAddTodos && !canAddCalendarEvents,
          details_on_create: true,
          details_on_dblclick: true,
          header: SCHEDULER_HEADER.full,
          scroll_hour: new Date().getHours(),
          mark_now: true,
          now_date: new Date(),
        },
        data: { timeZonedEvents },
        templates: {
          event_text: eventTextTemplate,
        },
      }
      // Initialize the calendar instance
      calendarInstanceRef.current = Scheduler.getSchedulerInstance(
        //@ts-ignore
        schedulerConfig
      )
      const calendar = calendarInstanceRef.current
      /**
       * Calendar plugins
       */
      // @ts-ignore
      calendar.plugins({
        limit: true,
        minical: true,
        tooltip: true,
        recurring: true,
      })

      /** Calendar timeZonedEvents */
      calendar.attachEvent("onBeforeEventChanged", handleBeforeEventChange)
      calendar.attachEvent("onEventAdded", handleEventAdded)
      calendar.attachEvent("onClick", (id: string) => {
        const foundEvent = calendar.getEvent(id)
        const isRecurringEvent = foundEvent?.rec_type || foundEvent?.event_pid
        if (isRecurringEvent) return handleClickRecurringEvent(foundEvent)
        const [eventId] = id.split("#")
        setCurrentEventId(eventId)
        calendar.showLightbox(eventId)
      })
      calendar.attachEvent("onDblClick", (id: string) => {
        setCurrentEventId(id)
        calendar.showLightbox(id)
        calendar.showLightbox(id)
      })

      /** Calendar methods */
      calendar.showLightbox = handleShowLightBox

      /** Calendar template customization */
      calendar.templates.week_date_class = function (start, end, event) {
        const weekends = weekendDays || user?.weekendDays || []
        const dayOfWeek = start.getDay() as DayOfWeek
        const isWeekend = weekends.includes(dayOfWeek)
        if (isWeekend) return "weekend"

        const holidaysProcessor = new HolidaysProcessor({ holidays, timeZone: defaultDateTimeService.getTimeZone() })
        const isHoliday = holidaysProcessor.isHoliday(start)
        return isHoliday ? "weekend" : ""
      }

      calendar.templates.tooltip_text = function (start, end, event) {
        const isMultipleDays = start.getDate() !== end.getDate()
        const startHours = start.getHours() < 10 ? `0${start.getHours()}` : `${start.getHours()}`
        const startMinutes = start.getMinutes() < 10 ? `0${start.getMinutes()}` : `${start.getMinutes()}`
        const startTime = `${startHours}:${startMinutes}`
        const startTimeToShow = isMultipleDays ? `${defaultDateTimeService.format(start)} ${startTime}` : startTime

        const endHours = end.getHours() < 10 ? `0${end.getHours()}` : `${end.getHours()}`
        const endMinutes = end.getMinutes() < 10 ? `0${end.getMinutes()}` : `${end.getMinutes()}`
        const endTime = `${endHours}:${endMinutes}`
        const endTimeToShow = isMultipleDays ? `${defaultDateTimeService.format(end)} ${endTime}` : endTime
        const participants = event.participants.join(", ")
        const text = event.details || event.text
        return `<span class="dhx_calendar_caption">${text}</span><br><span class="dhx_calendar_caption">${startTimeToShow} - ${endTimeToShow}<span><br><span class="dhx_calendar_caption">${participants}</span>`
      }

      /** Calendar week_start customization */
      calendar.date.week_start = function (date) {
        let shift = date.getDay()
        if (shift === 0) {
          shift = 2
        } else {
          shift = shift - firstDayOfWeek
        }
        return this.date_part(this.add(date, -1 * shift, "day"))
      }
      calendarInstanceRef.current?.parse(timeZonedEvents)
    }
  }, [
    canAddTasks,
    canAddTodos,
    canAddCalendarEvents,
    timeZonedEvents,
    handleBeforeEventChange,
    handleShowLightBox,
    handleEventAdded,
    id,
    weekendDays,
    showCaption,
    defaultDateTimeService,
    user?.weekendDays,
    handleClickRecurringEvent,
    holidays,
    translations.abbreviatedDayNames,
    translations.fullMonthNames,
    firstDayOfWeek,
    isSmallScreen,
  ])
  React.useEffect(() => {
    return () => {
      // destroy the calendar instance
      if (calendarInstanceRef.current) {
        // @ts-ignore
        calendarInstanceRef.current.destructor()
        calendarInstanceRef.current = null
      }
    }
  }, [location.pathname])
  React.useEffect(() => {
    if (calendarInstanceRef.current) {
      const calendarTranslations = makeCalendarTranslations(translations)
      // @ts-ignore
      calendarInstanceRef.current.i18n.setLocale(calendarTranslations)
      // @ts-ignore
      calendarInstanceRef.current.render()
    }
  }, [translations])

  /**
   * This effect is responsible for updating the calendar
   * when any event is updated
   */
  React.useEffect(() => {
    if (calendarInstanceRef.current) {
      calendarInstanceRef.current.clearAll()
      calendarInstanceRef.current.parse(timeZonedEvents)
    }
  }, [timeZonedEvents])

  /**
   * This effect ends the light box
   * when an event gets deleted from calendar
   */
  React.useEffect(() => {
    if (currentEventId && !currentEvent) {
      setCurrentEventId(null)
      calendarInstanceRef.current?.endLightbox(false, lightBoxRootElRef.current as HTMLElement)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeZonedEvents, currentEventId])

  React.useEffect(() => {
    if (openNewOccurance) {
      if (!currentRecurringEvent) {
        setOpenNewOccurance(false)
        clearState()
        return
      }

      const foundOccurance = timeZonedRecurringEvents.find((event) => {
        const occuranceId = event.event_pid + "#" + event.event_length
        return occuranceId === currentRecurringEvent.id
      })

      if (foundOccurance) {
        setCurrentEventId(foundOccurance.id)
        setOpenNewOccurance(false)
        clearState()
      }
    }
  }, [clearState, currentRecurringEvent, openNewOccurance, timeZonedRecurringEvents])

  const onCreateTodo = async (todo: CalendarTodoData) => {
    const dueDate = todo.dueDate
    let enableTimeComponent = false
    if (dueDate && !defaultDateTimeService.hasMidnightTime(dueDate)) enableTimeComponent = true
    const newTodo = await onAddTodo({ ...todo, enableTimeComponent })
    if (newTodo) setNewlyCreatedEventId(newTodo.id)
  }
  const onCreateCalendarEvent = async (calendarEvent: CalendarEventData) => {
    const newCalendarEvent = await onAddCalendarEvent(calendarEvent)
    if (newCalendarEvent) setNewlyCreatedEventId(newCalendarEvent.id)
  }
  const onCreateTask = async (task: CalendarTaskData) => {
    const newTask = await onAddTask(task)
    if (newTask) setNewlyCreatedEventId(newTask.id)
  }

  function showMiniCalendar() {
    if (calendarInstanceRef.current?.isCalendarVisible()) {
      calendarInstanceRef.current?.destroyCalendar()
    } else {
      calendarInstanceRef.current?.renderCalendar({
        position: "dhx_minical_icon",
        date: calendarInstanceRef.current?.date,
        navigation: true,
        handler: function (date: Date) {
          calendarInstanceRef.current?.setCurrentView(date)
          calendarInstanceRef.current?.destroyCalendar()
        },
      })
    }
  }

  const shouldOpenCurrentEventDialog =
    currentEventId &&
    currentEvent &&
    (currentEvent.type === "task" || currentEvent.type === "todo" || currentEvent.type === "calendarEvent")
  const shouldOpenNewEventDialog =
    newlyCreatedEventId &&
    newEvent &&
    (newEvent.type === "task" || newEvent.type === "todo" || newEvent.type === "calendarEvent")

  return (
    <div data-test="calendar-container">
      <div style={{ height: 800 }}>
        <div id={id} className="dhx_cal_container" style={{ width: "100%", height: "100%" }}>
          <div className="dhx_cal_navline">
            <div className="dhx_cal_prev_button">&nbsp;</div>
            <div className="dhx_cal_next_button">&nbsp;</div>
            <div className="dhx_cal_today_button"></div>
            <div className="dhx_cal_date"></div>
            <div className="dhx_minical_icon" id="dhx_minical_icon" onClick={() => showMiniCalendar()}>
              &nbsp;
            </div>
            {/* @ts-ignore */}
            <div className="dhx_cal_tab" name="day_tab"></div>
            {/* @ts-ignore */}
            <div className="dhx_cal_tab" name="week_tab"></div>
            {/* @ts-ignore */}
            <div className="dhx_cal_tab" name="month_tab"></div>
          </div>
          <div className="dhx_cal_header"></div>
          <div className="dhx_cal_data"></div>
        </div>
      </div>

      <div ref={lightBoxRootElRef} className={classes.lightBoxRootElement}>
        {!currentEventId && (
          <CalendarForm
            canAddCalendarEvents={canAddCalendarEvents}
            canAddTasks={canAddTasks}
            canAddTodos={canAddTodos}
            onAddCalendarEvent={onCreateCalendarEvent}
            onAddAsTask={onCreateTask}
            onAddAsTodo={onCreateTodo}
            startDate={startDate}
            endDate={endDate}
            closeForm={closeForm}
            titleInputRef={eventFormTitleInputRef}
            firstDayOfWeek={firstDayOfWeek}
          />
        )}
        {shouldOpenCurrentEventDialog && (
          <Dialog
            open
            fullWidth
            maxWidth="md"
            PaperProps={{
              style: { padding: 12, minHeight: 600 },
            }}
            onClose={() => closeForm(false)}
          >
            <div className={classes.links}>
              {currentEvent.type === "task" && (
                <FullPath
                  projectLink={(entitiesById[currentEventId] as TaskViewModel)?.projectLink}
                  taskLink={(entitiesById[currentEventId] as TaskViewModel)?.taskLink}
                  ancestorTaskLinks={(entitiesById[currentEventId] as TaskViewModel)?.ancestorTaskLinks}
                  projectOrTaskNumber={(entitiesById[currentEventId] as TaskViewModel)?.shownTaskNumber}
                  onRedirect={() => closeForm(false)}
                />
              )}
              {(currentEvent.type === "todo" || currentEvent.type === "calendarEvent") && (
                <FullPath
                  projectLink={(entitiesById[currentEventId] as TodoViewModel | CalendarEventViewModel)?.projectLink}
                  taskLink={(entitiesById[currentEventId] as TodoViewModel | CalendarEventViewModel)?.taskLink}
                  ancestorTaskLinks={
                    (entitiesById[currentEventId] as TodoViewModel | CalendarEventViewModel)?.ancestorTaskLinks
                  }
                  projectOrTaskNumber={
                    (entitiesById[currentEventId] as TodoViewModel | CalendarEventViewModel)?.task?.taskNumber ||
                    (entitiesById[currentEventId] as TodoViewModel | CalendarEventViewModel)?.project?.projectNumber
                  }
                  showTaskLink={true}
                  onRedirect={() => closeForm(false)}
                />
              )}
            </div>
            <div className={classes.cardHeader}>
              <span className={classes.cardTitle}>
                {currentEvent.type === "task" && <TaskCardTitle task={entitiesById[currentEventId] as TaskViewModel} />}
                {currentEvent.type === "todo" && <TodoCardTitle todo={entitiesById[currentEventId] as TodoViewModel} />}
                {currentEvent.type === "calendarEvent" && (
                  <CalendarEventCardTitle calendarEvent={entitiesById[currentEventId] as CalendarEventViewModel} />
                )}
              </span>
              <IconButton className={classes.closeIcon} onClick={() => closeForm(false)}>
                <X size={24} color={"#616161"} />
              </IconButton>
            </div>
            <div style={{ padding: "16px" }}>
              {currentEvent.type === "task" && <TaskDetails taskId={currentEventId} />}
              {currentEvent.type === "todo" && <TodoDetails todo={entitiesById[currentEventId] as TodoViewModel} />}
              {currentEvent.type === "calendarEvent" && (
                <CalendarEventDetails
                  calendarEvent={entitiesById[currentEventId] as CalendarEventViewModel}
                  onDelete={() => {
                    closeForm(false)
                    clearState()
                    setCurrentEventId(null)
                  }}
                />
              )}
            </div>
          </Dialog>
        )}
        {shouldOpenNewEventDialog && (
          <Dialog
            open
            fullWidth
            maxWidth="md"
            PaperProps={{
              style: { padding: 12, minHeight: 600 },
            }}
            onClose={() => closeForm(false)}
          >
            <div className={classes.links}>
              {newEvent.type === "task" && (
                <FullPath
                  projectLink={(entitiesById[newlyCreatedEventId] as TaskViewModel)?.projectLink}
                  taskLink={(entitiesById[newlyCreatedEventId] as TaskViewModel)?.taskLink}
                  ancestorTaskLinks={(entitiesById[newlyCreatedEventId] as TaskViewModel)?.ancestorTaskLinks}
                  projectOrTaskNumber={(entitiesById[newlyCreatedEventId] as TaskViewModel)?.shownTaskNumber}
                  onRedirect={() => closeForm(false)}
                />
              )}
              {(newEvent.type === "todo" || newEvent.type === "calendarEvent") && (
                <FullPath
                  projectLink={
                    (entitiesById[newlyCreatedEventId] as TodoViewModel | CalendarEventViewModel)?.projectLink
                  }
                  taskLink={(entitiesById[newlyCreatedEventId] as TodoViewModel | CalendarEventViewModel)?.taskLink}
                  ancestorTaskLinks={
                    (entitiesById[newlyCreatedEventId] as TodoViewModel | CalendarEventViewModel)?.ancestorTaskLinks
                  }
                  projectOrTaskNumber={
                    (entitiesById[newlyCreatedEventId] as TodoViewModel | CalendarEventViewModel)?.task?.taskNumber ||
                    (entitiesById[newlyCreatedEventId] as TodoViewModel | CalendarEventViewModel)?.project
                      ?.projectNumber
                  }
                  showTaskLink={true}
                  onRedirect={() => closeForm(false)}
                />
              )}
            </div>
            <div className={classes.cardHeader}>
              <span className={classes.cardTitle}>
                {newEvent.type === "task" && (
                  <TaskCardTitle task={entitiesById[newlyCreatedEventId] as TaskViewModel} />
                )}
                {newEvent.type === "todo" && (
                  <TodoCardTitle todo={entitiesById[newlyCreatedEventId] as TodoViewModel} />
                )}
                {newEvent.type === "calendarEvent" && (
                  <CalendarEventCardTitle calendarEvent={entitiesById[newlyCreatedEventId] as CalendarEventViewModel} />
                )}
              </span>
              <IconButton className={classes.closeIcon} onClick={() => closeForm(false)}>
                <X size={24} color={"#616161"} />
              </IconButton>
            </div>
            <div style={{ padding: "16px" }}>
              {newEvent.type === "task" && <TaskDetails taskId={newlyCreatedEventId} />}
              {newEvent.type === "todo" && <TodoDetails todo={entitiesById[newlyCreatedEventId] as TodoViewModel} />}
              {newEvent.type === "calendarEvent" && (
                <CalendarEventDetails
                  calendarEvent={entitiesById[newlyCreatedEventId] as CalendarEventViewModel}
                  onDelete={() => {
                    closeForm(false)
                    clearState()
                    setCurrentEventId(null)
                  }}
                />
              )}
            </div>
          </Dialog>
        )}
        {openEditRecurringEventConfirmation && (
          <Dialog
            open
            fullWidth
            maxWidth="sm"
            PaperProps={{
              style: { padding: 12 },
            }}
          >
            <DialogContent style={{ textAlign: "center" }}>
              <Typography>{translations.editRecurringEventsDialogTitle}</Typography>
            </DialogContent>
            <DialogActions style={{ justifyContent: "center" }}>
              <Button
                variant="outlined"
                onClick={() => {
                  if (currentRecurringEvent) setCurrentEventId(currentRecurringEvent.event_pid)
                  clearState()
                }}
              >
                {translations.editSeries}
              </Button>
              <Button variant="outlined" onClick={handleClickEditOccurrence}>
                {translations.editOccurrence}
              </Button>
              <Button variant="outlined" onClick={() => clearState()}>
                {translations.cancel}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  lightBoxRootElement: {
    position: "absolute",
    top: "50% !important",
    left: "calc(50% + 24px) !important",
    transform: "translate(-50%, -50%)",
    zIndex: 10001,
    display: "none", // important to hide the light box initially
    background: theme.palette.common.white,
    transition: theme.transitions.create("height"),
    borderRadius: theme.spacing(0.5),
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-bewteen",
  },
  cardTitle: {
    flex: 1,
    padding: theme.spacing(1, 1.5),
  },
  closeIcon: {
    marginRight: 16,
  },
  links: {
    paddingLeft: theme.spacing(3),
  },
}))

type CalendarProps = {
  id?: string
  events: EventType[]
  recurringEvents: RecurringEventType[]
  entitiesById: Record<string, TaskViewModel | CalendarEventViewModel | TodoViewModel>
  canAddCalendarEvents: boolean
  canAddTasks: boolean
  canAddTodos: boolean
  onAddCalendarEvent: (calendarEvent: CalendarEventData) => Promise<void | CalendarEventViewModel>
  onAddTask: (task: CalendarTaskData) => Promise<void | TaskViewModel>
  onAddTodo: (task: CalendarTodoData) => Promise<void | TodoViewModel>
  onUpdateCalendarEvent: (eventId: string, updates: CalendarEventUpdates) => void
  onUpdateTask: (taskId: string, updates: CalendarTaskUpdates) => void
  onUpdateTodo: (todoId: string, updates: CalendarTodoUpdates) => void
  weekendDays?: DayOfWeek[]
  firstDayOfWeek?: DayOfWeek
  holidays?: HolidayViewModel[]
  dateTimeService?: DateTimeService
  showCaption?: boolean
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")
  const recurringEventTranslations = translations?.recurringEventWidget || {}
  const dateTranslations = useDateTranslations()

  const { today = defaults.today } = translations
  const { dayLabel, weekLabel, monthLabel, fullDayNames, abbreviatedDayNames, fullMonthNames, shortMonthsNames } =
    dateTranslations
  const cancel = translations?.confirmationDialog?.secondaryButtonLabel || defaults.cancel
  const {
    editRecurringEventsDialogTitle = defaults.editRecurringEventsDialogTitle,
    editSeries = defaults.editSeries,
    editOccurrence = defaults.editOccurrence,
  } = recurringEventTranslations

  return {
    today,
    dayLabel,
    weekLabel,
    monthLabel,
    fullDayNames,
    abbreviatedDayNames,
    fullMonthNames,
    shortMonthsNames,
    editRecurringEventsDialogTitle,
    editSeries,
    editOccurrence,
    cancel,
  }
}

const defaultTranslations = {
  today: "Today",
  dayLabel: "Day",
  weekLabel: "Week",
  monthLabel: "Month",
  fullDayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  abbreviatedDayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  fullMonthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  shortMonthsNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  editRecurringEventsDialogTitle: "Do you want to edit the whole set of repeated events?",
  editSeries: "Edit series",
  editOccurrence: "Edit occurrence",
  cancel: "Cancel",
}
type Translations = typeof defaultTranslations

const makeCalendarTranslations = (translations: Translations) => {
  const {
    today,
    dayLabel,
    weekLabel,
    monthLabel,
    fullDayNames,
    abbreviatedDayNames,
    fullMonthNames,
    shortMonthsNames,
  } = translations
  return {
    date: {
      month_full: fullMonthNames,
      month_short: shortMonthsNames,
      day_full: fullDayNames,
      day_short: abbreviatedDayNames,
    },
    labels: {
      dhx_cal_today_button: today,
      day_tab: dayLabel,
      week_tab: weekLabel,
      month_tab: monthLabel,
      day: dayLabel,
      week: weekLabel,
      month: monthLabel,
    },
  }
}

export default Calendar
