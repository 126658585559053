export const makeOrganisationApiRoutes = (orgId = ":orgId") => {
  const routes = {
    organisations: () => "/orgs",
    organisationItem: (id = orgId) => {
      return `${routes.organisations()}/${id || orgId}`
    },
    orgRoles: (id = orgId) => `${routes.organisationItem(id)}/roles`,
    orgRoleItem: (id = ":roleId") => `/roles/${id}`,
    orgRoleAbilities: (id = ":roleId") => `/roles/${id}/abilities`,
    orgRoleAbilitiesApplyToAll: (id = ":roleId") => {
      return `/roles/${id}/abilities/apply-to-all`
    },
    orgBillingSummary: (id = orgId) => `${routes.organisationItem(id)}/billing/summary`,
  }
  return routes
}
