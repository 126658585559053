import { filter } from "lodash"

import { useRouter } from "."
import { useAuthUser } from "../users/hooks/use-auth-user"
import { AppContextState, AppMainContextType, AppSubContextType } from "../types/app-context"
import { IContextOptionNormalized } from "../context-options/interfaces/context-options-normalized"
import { useContextOptions } from "../context-options/hooks/use-context-options"
import { useUrlWithContext } from "./use-url-with-context"
import { paths } from "../paths"

export const useAppContext = () => {
  const { location, push } = useRouter()
  const { user } = useAuthUser()
  const {
    contextOptions,
    customerContextOptions,
    supplierContextOptions,
    workspaceContextOptions,
    membershipContextOptions,
  } = useContextOptions()
  const { createPathWithGivenContext } = useUrlWithContext()

  const query = useRouter().query as { mainContext?: string; subContext?: string }
  const userHomeRoute = createPathWithGivenContext({ path: paths.home(), mainContextId: user?.id })

  const getMainContextFromURL = (): AppMainContextType | null => {
    const mainContext = query.mainContext
    if (mainContext) {
      const context = contextOptions.find((mc) => mc.id === mainContext)
      if (context) return makeMainContext(context)
    }
    push(userHomeRoute)
    return null
  }

  const getSubContextFromURL = (): AppSubContextType | null => {
    const mainContext = getMainContextFromURL()
    const subContext = query.subContext
    if (mainContext && subContext) {
      const context = contextOptions.find((sc) => sc.id === subContext)
      if (context) return makeSubcontext(context)
      onChangeSubContext(null)
    }
    return null
  }

  const mainContext = getMainContextFromURL()
  const subContext = getSubContextFromURL()
  const mainContextList = createMainContextList({
    contextOptions,
  })
  const subContextList: AppSubContextType[] = mainContext
    ? createSubContextList({
        mainContext,
        customerContextOptions,
        supplierContextOptions,
        workspaceContextOptions,
        membershipContextOptions,
      })
    : []
  const appContext: AppContextState = {
    mainContext,
    subContext,
    mainContextList,
    subContextList,
  }

  function onChangeMainContext(mainContext: AppMainContextType) {
    let subContext: AppSubContextType | undefined
    if (mainContext.type === "org") {
      const membershipContext = membershipContextOptions.find(
        ($membershipContextOption) =>
          $membershipContextOption.userId === user?.id &&
          $membershipContextOption.orgId === mainContext.id &&
          !$membershipContextOption.disabled
      )
      subContext = membershipContext ? makeSubcontext(membershipContext) : undefined
    }

    const context = subContext || mainContext
    const pathname = location.pathname
    const nextPath = createNextPath({ currentPath: pathname, context })
    const nextPathWithContext = createPathWithGivenContext({
      path: nextPath,
      mainContextId: mainContext.id,
      subContextId: subContext?.id,
    })
    push(nextPathWithContext)
  }

  function onChangeSubContext(subContext: AppSubContextType | null) {
    if (mainContext) {
      const context = subContext || mainContext
      const pathname = location.pathname
      const nextPath = createNextPath({ currentPath: pathname, context })
      const nextPathWithContext = createPathWithGivenContext({
        path: nextPath,
        mainContextId: mainContext?.id,
        subContextId: subContext?.id,
      })
      push(nextPathWithContext)
    } else {
      push(userHomeRoute)
    }
  }

  return {
    appContext,
    onChangeMainContext,
    onChangeSubContext,
  }
}

const createMainContextList = ({
  contextOptions,
}: {
  contextOptions: IContextOptionNormalized[]
}): AppMainContextType[] => {
  const mainContextOptions = contextOptions.filter(
    ($contextOption) => $contextOption.type === "organisation" || $contextOption.type === "user"
  )
  return mainContextOptions.map(makeMainContext)
}

const createSubContextList = ({
  mainContext,
  customerContextOptions,
  supplierContextOptions,
  workspaceContextOptions,
  membershipContextOptions,
}: {
  mainContext: AppMainContextType
  customerContextOptions: IContextOptionNormalized[]
  supplierContextOptions: IContextOptionNormalized[]
  workspaceContextOptions: IContextOptionNormalized[]
  membershipContextOptions: IContextOptionNormalized[]
}): AppSubContextType[] => {
  const orgId = mainContext.id
  const customerContexts = filter(customerContextOptions, {
    orgId,
  }).map(($contextOption) => makeSubcontext($contextOption))
  const supplierContexts = filter(supplierContextOptions, {
    orgId,
  }).map(($contextOption) => makeSubcontext($contextOption))
  const membershipContexts = filter(workspaceContextOptions, {
    orgId,
  }).map(($contextOption) => makeSubcontext($contextOption))
  const workspaceContexts = filter(membershipContextOptions, {
    orgId,
  }).map(($contextOption) => makeSubcontext($contextOption))

  return [...customerContexts, ...supplierContexts, ...membershipContexts, ...workspaceContexts]
}

export const makeSubcontext = (contextOption: IContextOptionNormalized): AppSubContextType => {
  const { id, name, orgId, type, homePage, disabled } = contextOption
  let subContextType: "customer" | "member" | "supplier" | "workspace" = "member"
  if (type === "customer") subContextType = "customer"
  if (type === "supplier") subContextType = "supplier"
  if (type === "workspace") subContextType = "workspace"

  return {
    id,
    name,
    parentContextId: orgId,
    disabled,
    type: subContextType,
    homePage,
  }
}

export const makeMainContext = (contextOption: IContextOptionNormalized): AppMainContextType => {
  const { id, name, type, homePage } = contextOption
  let mainContextType: "user" | "org" = "user"
  if (type === "organisation") mainContextType = "org"

  return {
    id,
    name,
    type: mainContextType,
    homePage,
  }
}

const commonRoutes = [
  "home",
  "projects",
  "tasks",
  "todos",
  "calendar",
  "kanban",
  "notifications",
  "projects/new",
  "project-templates/new",
]
export const validRouteSegmentsByContext = {
  user: [
    "home",
    "projects",
    "tasks",
    "todos",
    "calendar",
    "kanban",
    "notifications",
    "projects/new",
    "project-templates/new",
    "settings",
  ],
  org: [
    "projects",
    "tasks",
    "todos",
    "calendar",
    "notifications",
    "projects/new",
    "project-templates/new",
    "settings",
    "members",
    "customers",
    "suppliers",
    "workspaces",
  ],
  customer: [
    "home",
    "projects",
    "tasks",
    "todos",
    "calendar",
    "kanban",
    "notifications",
    "projects/new",
    "project-templates/new",
    "settings",
  ],
  supplier: [
    "home",
    "projects",
    "tasks",
    "calendar",
    "kanban",
    "notifications",
    "projects/new",
    "project-templates/new",
    "settings",
  ],
  workspace: [
    "home",
    "projects",
    "tasks",
    "todos",
    "calendar",
    "kanban",
    "notifications",
    "projects/new",
    "project-templates/new",
    "settings",
    "members",
  ],
  member: [
    "home",
    "projects",
    "tasks",
    "todos",
    "calendar",
    "kanban",
    "notifications",
    "projects/new",
    "project-templates/new",
    "settings",
  ],
}

const isCommonRoute = (path: string) => commonRoutes.some((route) => path.includes(route))
export function createNextPath(params: {
  currentPath: string
  context: AppMainContextType | AppSubContextType
}): string {
  const context = params.context
  const modifiedCurrentPath = `${params.currentPath}?` // This avoids matching projects route when we are on projects/* routes and other similar situations
  const validRouteForNextContext = validRouteSegmentsByContext[context.type]
  const fallbackSegment = context.type === "org" ? "projects" : "home"
  const nextPathSegment =
    validRouteForNextContext.find((segment) => modifiedCurrentPath.includes(`${segment}?`)) || fallbackSegment
  const nextPath = isCommonRoute(nextPathSegment) ? `/${nextPathSegment}` : `${context.homePage}/${nextPathSegment}`
  return nextPath
}
