import { AxiosRequestConfig } from "axios"
import api from "../services/api-service"
import { ISupplierResponse, ISupplierNumberMetadataResponse, IContactResponse } from "./interfaces/supplier-response"
import { SupplierStatusEnum } from "../types/supplier"

export const fetchSupplierById = (supplierId: string, config: AxiosRequestConfig = {}) => {
  return api.get<ISupplierResponse>(`/suppliers/${supplierId}`, config)
}

export const fetchSuppliers = (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.get<ISupplierResponse[]>(`/orgs/${orgId}/suppliers`, config)
}

export const createSupplier = (orgId: string, supplierData: CreateSupplierData, config: AxiosRequestConfig = {}) => {
  return api.post<ISupplierResponse>(`/orgs/${orgId}/suppliers`, supplierData, config)
}

export const updateSupplierInfo = (
  supplierId: string,
  updateData: SupplierInfoUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<ISupplierResponse>(`/suppliers/${supplierId}`, updateData, config)
}

export const updateSupplierStatus = (
  supplierId: string,
  updateData: SupplierStatusUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<ISupplierResponse>(`/suppliers/${supplierId}/status`, updateData, config)
}

export const getSupplierNumberMetadata = (orgId: string, supplierNumber: string, config: AxiosRequestConfig = {}) => {
  return api.get<ISupplierNumberMetadataResponse>(`/orgs/${orgId}/supplier-numbers/${supplierNumber}/metadata`, config)
}

export const addContact = (supplierId: string, contactData: AddContactData, config: AxiosRequestConfig = {}) => {
  return api.post<IContactResponse>(`/suppliers/${supplierId}/contacts`, contactData, config)
}

export const changeDefaultContact = (supplierId: string, contactId: string, config: AxiosRequestConfig = {}) => {
  return api.put<ISupplierResponse>(`/suppliers/${supplierId}/default-contact`, { contactId }, config)
}

export const updateContact = (
  supplierId: string,
  contactId: string,
  updateData: UpdateContactData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<IContactResponse>(`/suppliers/${supplierId}/contacts/${contactId}`, updateData, config)
}

export const deleteContact = (supplierId: string, contactId: string, config: AxiosRequestConfig = {}) => {
  return api.delete<IContactResponse>(`/suppliers/${supplierId}/contacts/${contactId}`, config)
}

export type CreateSupplierData = {
  name: string
  supplierNumber?: string
}

export type SupplierInfoUpdateData = {
  name?: string
  supplierNumber?: number
  businessId?: string
  vatRegistrationNumber?: string
  language?: string
}

export type SupplierStatusUpdateData = {
  status: SupplierStatusEnum
}

export type AddContactData = {
  firstname: string
  lastname: string
  phone?: string
  email?: string
  jobTitle?: string
}

export type UpdateContactData = {
  firstname?: string
  lastname?: string
  phone?: string
  email?: string
  jobTitle?: string
}
