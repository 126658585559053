import React from "react"
import Button from "@material-ui/core/Button"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { Link } from "react-router-dom"
import { makeStyles, alpha } from "@material-ui/core"
import { ISupplierNormalized } from "../../suppliers/interfaces/supplier-normalized"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { paths } from "../../paths"
import { Settings } from "react-feather"
import { useOrgSuppliers } from "../../suppliers/hooks/use-org-suppliers"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"

const OrgSupplierCards = ({ orgId }: { orgId: string }) => {
  const { suppliers, activeSuppliers, inactiveSuppliers } = useOrgSuppliers(orgId)
  const [filter, setFilter] = React.useState("active")
  const classes = useStyles()
  const translations = useTranslations()
  const filteredSuppliers =
    filter === "active" ? activeSuppliers : filter === "inactive" ? inactiveSuppliers : suppliers

  return (
    <div style={{ marginTop: 24 }}>
      <InputLabel className={classes.filterLabel}>{translations.filter}</InputLabel>
      <Select
        className={classes.filter}
        value={filter}
        onChange={(e) => setFilter(e.target.value as string)}
        variant="outlined"
      >
        <MenuItem value="all">
          <em>{translations.allSuppliersOption}</em>
        </MenuItem>
        <MenuItem value={"active"}>{translations.activeSuppliersOption}</MenuItem>
        <MenuItem value={"inactive"}>{translations.inactiveSuppliersOption}</MenuItem>
      </Select>
      {filteredSuppliers.map((supplier) => (
        <OrgSupplierCard key={supplier.id} supplier={supplier} />
      ))}
      {filteredSuppliers.length === 0 && (
        <div className={classes.emptyMessage}>
          <Typography variant="body2" color="textSecondary">
            {translations.noSuppliersText}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default OrgSupplierCards

const OrgSupplierCard = ({ supplier }: { supplier: ISupplierNormalized }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { createPathWithGivenContext } = useUrlWithContext()

  const supplierHomePath = createPathWithGivenContext({
    path: paths.home(),
    mainContextId: supplier.orgId,
    subContextId: supplier.id,
  })

  const supplierSettingsPath = createPathWithGivenContext({
    path: paths.supplierSettings(),
    mainContextId: supplier.orgId,
    subContextId: supplier.id,
  })

  return (
    <Paper variant="outlined" className={classes.card}>
      <Typography variant="subtitle1" component={Link} to={supplierHomePath} gutterBottom>
        {supplier.name}
      </Typography>

      {supplier.supplierNumber ? (
        <Typography variant="body2" component="p" color="textSecondary">
          {translations.supplierNumberHeader}: {supplier.supplierNumber}
        </Typography>
      ) : null}

      {supplier.defaultContact ? (
        <Typography variant="body2" component="p" color="textSecondary">
          {translations.supplierContactHeader}: {supplier.defaultContact.firstname} {supplier.defaultContact.lastname}
        </Typography>
      ) : null}

      <br />

      <Button
        color="primary"
        className={classes.settingsButton}
        component={Link}
        to={supplierSettingsPath}
        size="small"
        startIcon={<Settings size={14} />}
      >
        {translations.settings}
      </Button>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
      textDecoration: "none",
    },
    "& .text": {
      marginBottom: theme.spacing(0.5),
    },
  },
  settingsButton: {
    marginTop: theme.spacing(1),
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  filter: {
    minWidth: 120,
    width: "calc(100% - 24px)",
    marginLeft: theme.spacing(1.5),
  },
  filterLabel: {
    marginLeft: theme.spacing(2),
  },
  emptyMessage: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")
  const { translations: supplierTranslations } = useI18n("supplier")
  const suppliersPageTranslations = (supplierTranslations?.suppliersPage || {}) as StringMap

  const {
    allSuppliersOption = defaults.allSuppliersOption,
    activeSuppliersOption = defaults.activeSuppliersOption,
    inactiveSuppliersOption = defaults.inactiveSuppliersOption,
    supplierNumberHeader = defaults.supplierNumberHeader,
    supplierLanguageHeader = defaults.supplierLanguageHeader,
    supplierContactHeader = defaults.supplierContactHeader,
    noSuppliersText = defaults.noSuppliersText,
  } = suppliersPageTranslations

  return {
    allSuppliersOption,
    activeSuppliersOption,
    inactiveSuppliersOption,
    supplierNumberHeader,
    supplierLanguageHeader,
    supplierContactHeader,
    noSuppliersText,
    filter: translations.filter || defaults.filter,
    settings: translations.settings || defaults.settings,
  }
}

const defaultTranslations = {
  allSuppliersOption: "All suppliers",
  activeSuppliersOption: "Active suppliers",
  inactiveSuppliersOption: "Inactive suppliers",
  supplierNumberHeader: "Supplier number",
  supplierLanguageHeader: "Language",
  supplierContactHeader: "Contact",
  noSuppliersText: "No suppliers",
  filter: "Filter",
  settings: "Settings",
}

type Translations = typeof defaultTranslations
