import Typography from "@material-ui/core/Typography"
import { ProjectViewModel } from "../../api/project"
import { useProjectPlannedEnd, useProjectPlannedStart } from "../../hooks/use-project-plan"
import DatePicker from "../../../components/DatePicker"
import { makeStyles, Theme, useMediaQuery } from "@material-ui/core"
import clsx from "clsx"
import { useAuthUserLocale } from "../../../users/hooks/use-auth-user-locale"
import Tooltip from "@material-ui/core/Tooltip"
import { useLocation } from "react-router-dom"
import { isBasicRoute } from "./utils"

const ProjectPlan = ({ project }: { project: ProjectViewModel }) => {
  const translations = useTranslations()
  const classes = useStyles({} as StyleProps)
  const location = useLocation()

  const isBasicInfoRoute = isBasicRoute(location.pathname)
  const isMediumOrSmallScreen = useMediaQuery("(max-width:1480px)")
  const isSmallScreen = useMediaQuery("(max-width:1350px)")

  if (isSmallScreen || isBasicInfoRoute) return null

  return (
    <div className={classes.scheduleWrapper}>
      <span className="divider" />
      {isMediumOrSmallScreen ? null : (
        <Typography variant="body2" className="title">
          {translations.planTitle}
        </Typography>
      )}
      <PlannedStartDate project={project} />
      <PlannedEndDate project={project} />
    </div>
  )
}

const PlannedStartDate = ({ project }: { project: ProjectViewModel }) => {
  const { dateTimeFormat } = useAuthUserLocale()
  const translations = useTranslations()
  const {
    canUpdatePlannedStart,
    plannedStartDate,
    formattedPlannedStart,
    isPlannedStartPastDue,
    updatePlannedStart,
    isInheritedFromTasks,
  } = useProjectPlannedStart(project)

  const styleProps: StyleProps = {
    isEditable: canUpdatePlannedStart,
    hasDate: Boolean(plannedStartDate),
    isTimeEnabled: project.enableTimeComponent,
    isPastDue: isPlannedStartPastDue,
  }
  const classes = useStyles(styleProps)
  const tooltipTitle = isInheritedFromTasks ? translations.projectPlanToolTipLabel : ""

  if (!canUpdatePlannedStart) {
    return (
      <Tooltip title={tooltipTitle}>
        <Typography variant="body2" color={isPlannedStartPastDue ? "error" : "textSecondary"}>
          {formattedPlannedStart}
        </Typography>
      </Tooltip>
    )
  }

  const textFieldProps = {
    size: "small",
    className: clsx(classes.datePickerInput, classes.input),
  } as const

  return (
    <Tooltip title={tooltipTitle}>
      <DatePicker
        readOnly={!canUpdatePlannedStart}
        isClearable={canUpdatePlannedStart}
        selected={plannedStartDate}
        placeholderText={translations.addStartDate}
        textFieldProps={textFieldProps}
        onChange={updatePlannedStart}
        showTimeSelect={project.enableTimeComponent}
        dateFormat={dateTimeFormat}
        calendarStartDay={project.firstDayOfWeek}
      />
    </Tooltip>
  )
}

const PlannedEndDate = ({ project }: { project: ProjectViewModel }) => {
  const { dateTimeFormat } = useAuthUserLocale()
  const translations = useTranslations()
  const {
    canUpdatePlannedEnd,
    plannedEndDate,
    formattedPlannedEnd,
    isPlannedEndPastDue,
    updatePlannedEnd,
    isInheritedFromTasks,
  } = useProjectPlannedEnd(project)

  const styleProps: StyleProps = {
    isEditable: canUpdatePlannedEnd,
    hasDate: Boolean(plannedEndDate),
    isTimeEnabled: project.enableTimeComponent,
    isPastDue: isPlannedEndPastDue,
  }
  const classes = useStyles(styleProps)
  const tooltipTitle = isInheritedFromTasks ? translations.projectPlanToolTipLabel : ""

  if (!canUpdatePlannedEnd) {
    return (
      <Tooltip title={tooltipTitle}>
        <Typography variant="body2" color={isPlannedEndPastDue ? "error" : "textSecondary"}>
          {formattedPlannedEnd}
        </Typography>
      </Tooltip>
    )
  }

  const textFieldProps = {
    size: "small",
    className: clsx(classes.datePickerInput, classes.input),
  } as const

  return (
    <Tooltip title={tooltipTitle}>
      <DatePicker
        readOnly={!canUpdatePlannedEnd}
        isClearable={canUpdatePlannedEnd}
        selected={plannedEndDate}
        placeholderText={translations.addEndDate}
        textFieldProps={textFieldProps}
        onChange={updatePlannedEnd}
        showTimeSelect={project.enableTimeComponent}
        dateFormat={dateTimeFormat}
        calendarStartDay={project.firstDayOfWeek}
      />
    </Tooltip>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  scheduleWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 8,
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    "& .divider": {
      width: 20,
      height: 16,
      borderLeft: `1px solid ${theme.palette.grey[600]}`,
    },
    "& .react-datepicker__close-icon": {
      opacity: 0,
    },
    "&:hover .react-datepicker__close-icon": {
      opacity: 1,
    },
    "& .title": {
      marginRight: 8,
    },
  },
  input: {
    width: ({ isEditable, hasDate, isTimeEnabled }) => {
      if (!isEditable) return 64
      if (isEditable && !hasDate) return 96
      if (!isTimeEnabled) return 120
      return 160
    },
    "& .MuiInputBase-input": {
      color: (props: StyleProps) => (props.isPastDue ? theme.palette.error.main : ""),
      textAlign: ({ isEditable }: StyleProps) => {
        return isEditable ? "left" : "center"
      },
    },
  },
  datePickerInput: {
    "& fieldset": {
      border: 0,
    },
    "& .MuiOutlinedInput-adornedStart": {
      paddingLeft: 0,
    },
    "& .MuiInputBase-input": {
      paddingRight: 0,
      fontSize: 14,
    },
    "& .MuiInputAdornment-root": {
      "& svg": {
        width: 14,
        height: 14,
      },
    },
  },
}))

const useTranslations = () => {
  return {
    planTitle: "Schedule",
    addStartDate: "Add Start Date",
    addEndDate: "Add End Date",
    projectPlanToolTipLabel: "Plan is inherited from tasks",
  }
}

type StyleProps = {
  isPastDue: boolean
  isEditable: boolean
  hasDate: boolean
  isTimeEnabled: boolean
}

export default ProjectPlan
