import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"

import { makeStyles, Theme } from "@material-ui/core/styles"

const progressStyles = makeStyles((theme: Theme) => ({
  container: {
    textAlign: "center",
    padding: theme.spacing(8, 0),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    width: "100%",
  },
  progress: {
    margin: theme.spacing(1, 2),
  },
}))

const Progress = ({ label = "loading" }: { label?: string }) => {
  const classes = progressStyles()
  return (
    <div className={classes.container}>
      <CircularProgress color="primary" className={classes.progress} size={24} />
      <Typography variant="h5" component="h6" color="primary">
        {label}
      </Typography>
    </div>
  )
}

export default Progress
