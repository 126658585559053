import { useEffect, useState } from "react"
import { useI18n } from "../../hooks"
import BillingAddress from "./BillingAddress"
import BillingContactPerson from "./BillingContactPerson"
import InvoicingAddress from "./InvoicingAddress"

import { useAppContext } from "../../hooks/use-app-context"
import doublet from "../../utils/doublet"
import { useSnackbar } from "notistack"
import {
  BillingSettingsResponse,
  fetchBillingSettings,
  updateBillingSettings,
} from "../../organisations/services/organisation-api"
import { Checkbox, Theme, makeStyles } from "@material-ui/core"
import { Alert, AlertTitle } from "@material-ui/lab"

const useBillingInformation = () => {
  const { appContext } = useAppContext()
  const orgId = appContext.mainContext?.id as string
  const [billingInformation, setBillingInformation] = useState<BillingSettingsResponse | null>(null)
  const [error, setError] = useState<Error | null>(null)
  const { enqueueSnackbar } = useSnackbar()

  const onChange = async (value: { [key: string]: string | boolean }) => {
    const [error, data] = await doublet(updateBillingSettings, orgId, value)
    if (error) {
      enqueueSnackbar(error.message, { variant: "error" })
      if (typeof value.agreeToPolicy === "string") {
        throw error
      }
    }
    if (data) setBillingInformation(data)
  }

  useEffect(() => {
    const fetchData = async () => {
      const [error, data] = await doublet(fetchBillingSettings, orgId)
      if (error) {
        setError(error)
        return
      }
      if (data) setBillingInformation(data)
    }

    if (!billingInformation && !error) fetchData()
  })

  return { error, billingInformation, onChange }
}

const BillingInformation = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const { error, billingInformation, onChange } = useBillingInformation()

  if (error) return <div>{error.message}</div>
  if (!billingInformation) return <div>Loading...</div>

  return (
    <div data-test="section-container">
      <h3>{translations.billingAddressHeader}</h3>
      <BillingAddress billingAddress={billingInformation.billingAddress} onChange={onChange} />
      <h3>{translations.billingContactPersonHeader}</h3>
      <BillingContactPerson billingContactPerson={billingInformation.contactPerson} onChange={onChange} />
      <h3>{translations.invoicingAddressHeader}</h3>
      <InvoicingAddress invoicingAddress={billingInformation.invoicingAddress} onChange={onChange} />
      <Alert
        icon={
          <Checkbox
            name="agreeToPolicy"
            color="primary"
            style={{ marginLeft: -12 }}
            checked={billingInformation.agreeToPolicy}
            onChange={(e) => onChange({ agreeToPolicy: e.target.checked })}
            data-test="accept-agreement-checkbox"
          />
        }
        severity="info"
        className={classes.alert}
      >
        <AlertTitle>{translations.agreeToPolicyText}</AlertTitle>
      </Alert>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    marginTop: theme.spacing(2),

    "& .MuiAlert-message": {
      display: "flex",
      alignItems: "center",
      paddingTop: theme.spacing(2),
    },
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("org")
  const translations = (t.orgBillingPage || {}) as { [k: string]: string }

  const {
    billingAddressHeader = defaults.billingAddressHeader,
    billingContactPersonHeader = defaults.billingContactPersonHeader,
    invoicingAddressHeader = defaults.invoicingAddressHeader,
    agreeToPolicyText = defaults.agreeToPolicyText,
  } = translations

  return {
    billingAddressHeader,
    billingContactPersonHeader,
    invoicingAddressHeader,
    agreeToPolicyText,
  }
}

const defaultTranslations = {
  billingAddressHeader: "Your billing address",
  billingContactPersonHeader: "Billing contact person",
  invoicingAddressHeader: "Invoicing address",
  agreeToPolicyText:
    "I confirm that the billing information provided is accurate. This organization is prepared to receive invoices in accordance with the billing summary.",
}

type Translations = typeof defaultTranslations

export default BillingInformation
