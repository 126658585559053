import Box from "@material-ui/core/Box"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import MenuItem from "@material-ui/core/MenuItem"
import Radio from "@material-ui/core/Radio"
import Select from "@material-ui/core/Select"
import { useTranslations } from "../hooks/use-translations"
import { ReminderViewModel } from "../api/reminder"
import { useReminderMutations } from "../hooks/use-reminder-mutations"
import { makeStyles, Theme } from "@material-ui/core"
import { StatusOption } from "../../lib/status-options"
import { Status } from "../../types/common"

const defaultStatusOptions: StatusOption[] = [
  {
    value: Status.IN_PROGRESS,
    label: Status.IN_PROGRESS,
    isVisible: true,
  },
  {
    value: Status.COMPLETED,
    label: Status.COMPLETED,
    isVisible: true,
  },
]

const ReminderStatusInput = ({ reminder, statusOptions = defaultStatusOptions }: ReminderStatusInputProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { updateReminderTrigger } = useReminderMutations()
  const isHidden = reminder.isPersonal || reminder.isCalendarEventReminder || reminder.isTodoReminder

  const updateReminderTriggerStatus = (e: React.ChangeEvent<{ value: unknown }>) => {
    updateReminderTrigger(reminder.id, {
      triggerType: "statusChange",
      ...parseValue(e.target.value as string),
    })
  }

  const handleChangeTriggerType = () => {
    updateReminderTrigger(reminder.id, { triggerType: "statusChange", triggerStatus: Status.COMPLETED })
  }

  if (isHidden) return null

  const statusTranslationMap: Record<string, string> = {
    [Status.COMPLETED]: translations.completed,
    [Status.IN_PROGRESS]: translations.inProgress,
    [Status.NOT_STARTED]: translations.notStarted,
  }

  const label = translations.sendNotification + " " + translations.whenStatusChanges
  const selectedTriggerStatusValue = optionToValue({
    value: reminder.triggerStatus || Status.COMPLETED,
    subStatusId: reminder.triggerSubStatusId || undefined,
  })

  const menuItems = statusOptions.map((option) => {
    const value = optionToValue(option)
    const label = statusTranslationMap[option.label] || option.label
    return { value, label }
  })

  return (
    <Box className={classes.container}>
      <FormControlLabel
        value="statusChange"
        control={<Radio color="primary" checked={reminder.isStatusChangeReminder} onChange={handleChangeTriggerType} />}
        label={label}
        disabled={reminder.isReadOnly}
        className={classes.formControlLabel}
      />
      <Box className={classes.selectWrapper}>
        <FormControl size="small" variant="outlined" disabled={reminder.isReadOnly}>
          <Select value={selectedTriggerStatusValue} onChange={updateReminderTriggerStatus}>
            {menuItems.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  )
}

export default ReminderStatusInput

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(1, 0),
    borderBottom: `1px solid ${theme.palette.divider}`,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  formControlLabel: {
    marginLeft: 0,
    "& .MuiTypography-root": {
      marginLeft: 16,
    },
  },
  selectWrapper: {
    marginLeft: -8,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: 8,
      marginLeft: 54,
    },
  },
}))

type ReminderStatusInputProps = {
  reminder: ReminderViewModel
  statusOptions?: StatusOption[]
}

function optionToValue(option: Pick<StatusOption, "value" | "subStatusId">) {
  return `${option.value}${option.subStatusId ? `-${option.subStatusId}` : ""}`
}

function parseValue(value: string) {
  const [status, subStatusId = null] = value.split("-")
  return { triggerStatus: status as Status, triggerSubStatusId: subStatusId }
}
