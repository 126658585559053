import React from "react"
import * as todoApi from "../../todos/api"
import { TimeZoneType } from "../../constants/timezones"
import { useTranslations } from "../hooks/use-translations"
import { Option } from "../types"
import { makeLayout } from "./layout"
import { makeTreegridTodoRow, makeTreegridTodoRows } from "./rows"
import { TodoColumnName, TreeGridTodo } from "./types"
import {
  createTreegrid,
  destroyTreegrid,
  getSummaryTextWithRowCount,
  syncDataFromServerToGrid,
  updateGridData,
} from "../utils/tree-grid"
import { DateTimeService } from "../../services/date-time-service"
import { useRouter } from "../../hooks"
import { useAppContext } from "../../hooks/use-app-context"
import { useContextOptions } from "../../context-options/hooks/use-context-options"
import { TodoViewModel } from "../../todos/api/todo"
import { useTreeGridFilters } from "../hooks/use-treegrid-filters"
import { useAddTodoButton } from "../hooks/use-add-todo-button"
import { useAddedRows } from "../hooks/use-added-rows"
import { useTodoMutations } from "../../todos/hooks/use-todo-mutations"
import { useDeleteTodo } from "../../todos/hooks/use-delete-todo"
import { paths } from "../../paths"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { getColorFromColorHtml, mapTranslationsToColor } from "../utils"

const id = "__treegrid_todo_list__"

const useTodoList = (props: TodoListProps) => {
  const router = useRouter()
  const { createPathWithContext } = useUrlWithContext()
  // @ts-expect-error
  const todosView = (router.query.view || "currentAndFuture") as TodosViewOptions
  const isProjectTodoListRoute = checkIsProjectTodoListRoute(router.match.path)
  const isTaskTodoListRoute = checkIsTaskTodoListRoute(router.match.path)
  const params = router.match.params as { projectId: string; taskId: string }
  const viewInfo = {
    projectId: isProjectTodoListRoute ? params.projectId : null,
    taskId: isTaskTodoListRoute ? params.taskId : null,
  }
  const { initTodoUpdate } = useTodoMutations()
  const { deleteTodo, status: todoDeletionStatus, resetStatus: resetTodoDeletionStatus } = useDeleteTodo()
  const translations = useTranslations()
  translations.toolbarTodoListSummaryText = getSummaryTextWithRowCount(
    translations.toolbarTodoListSummaryText,
    props.todos.length
  )
  const { showFilters, toggleFilters } = useTreeGridFilters({ gridId: id })
  const { disableAddTodoButton, setDisableAddTodoButton, isAddTodoButtonVisible } = useAddTodoButton({
    gridId: id,
    canCreateTodos: props.canCreateTodos,
    translations,
  })
  const { isAddedRow, setAddedRows } = useAddedRows<TreeGridTodo>({ gridId: id })
  const layout = makeLayout({
    ...props,
    id,
    translations,
    isFilterRowVisible: showFilters,
    isAddTodoButtonVisible,
    defaultColumnOrder: props.defaultColumnOrder || todoListDefaultColumnOrder,
    defaultVisibleColumns: props.defaultVisibleColumns || todoListDefaultVisibleColumns,
  })
  const { appContext } = useAppContext()
  const { userMembershipContextOptions } = useContextOptions()

  React.useEffect(() => {
    const grid = window.Grids[id]
    if (grid) {
      if (todoDeletionStatus.startsWith("deleted")) {
        const todoId = todoDeletionStatus.split(":")[1]
        const updatedRow = { id: todoId, Deleted: 1 }
        const Changes = JSON.stringify({ Changes: [updatedRow] })
        grid.Source.Data.Data.Changes = Changes
        grid.AcceptChanges()
        resetTodoDeletionStatus()
      }
      if (todoDeletionStatus.startsWith("cancelled") || todoDeletionStatus.startsWith("error")) {
        const todoId = todoDeletionStatus.split(":")[1]
        const todoRow = grid.GetRowById(todoId)
        grid.DeleteRow(todoRow, 3) // 3 = unmark row as deleted
        resetTodoDeletionStatus()
      }
    }
  }, [todoDeletionStatus, deleteTodo, resetTodoDeletionStatus])

  // @ts-ignore
  window.Grids["OnFilterToggle"] = function () {
    toggleFilters()
  }

  // @ts-ignore
  window.Grids.OnSearchChange = function (grid: TGrid, value: string) {
    if (grid) {
      grid.SearchExpression = value
      grid.DoSearch("Filter")
    }
  }

  window.Grids.OnFilterFinish = function (grid: TGrid) {
    if (grid) {
      const rowCount = grid.GetShownRows().length
      const toolbarTodoListSummaryText = getSummaryTextWithRowCount(translations.toolbarTodoListSummaryText, rowCount)
      // @ts-expect-error
      grid.Toolbar.Summary = toolbarTodoListSummaryText
      grid.RefreshRow(grid.Toolbar)
    }
  }

  window.Grids.OnValueChanged = function (
    grid: TGrid,
    row: TRow & TreeGridTodo,
    column: TodoColumnName,
    newValue,
    oldValue
  ) {
    // NOTE: Returning early from this function will cause unexpected behavior in the treegrid
    const isEditAllowed = row.CanEdit === 1
    const hasValueChanged = newValue !== oldValue
    const shouldDispatchUpdateRequest = isEditAllowed && hasValueChanged
    if (shouldDispatchUpdateRequest) {
      let value = adaptValue({ column, value: newValue })

      if (column === "dueDate" && value) {
        const dateTimeService = new DateTimeService({
          dateFormat: props.dateFormat,
          timeZone: props.timeZone,
          enableTimeComponent: row.enableTimeComponent === 1,
        })
        const date = new Date(value)
        const adjustedDate = dateTimeService.addTimezoneOffset(date, "UTC")
        value = adjustedDate.toISOString()
      }

      if (column === "ganttBarColor") {
        value = mapTranslationsToColor(translations)[value]
      }

      initTodoUpdate(row.id, { field: column, value }).then((updatedTodo) => {
        if (!updatedTodo) return
        const todo = "todo" in updatedTodo ? updatedTodo.todo : updatedTodo
        if (updatedTodo && grid) {
          const currentContext = appContext.subContext || appContext.mainContext
          const userMembershipIds = userMembershipContextOptions.map((m) => m.id)
          const isVisibleInThisView = todo.checkIsVisibleInView(todosView)
          const isVisibleInThisContext = todo.checkIsVisibleInContext(currentContext, userMembershipIds)
          const isVisibleRegardlessOfContext = isTaskTodoListRoute || isProjectTodoListRoute
          const isVisible = isVisibleInThisView && (isVisibleInThisContext || isVisibleRegardlessOfContext)

          if (isVisible) {
            const todoRow = makeTreegridTodoRow({ todo, translations, ...props })
            syncDataFromServerToGrid(grid, [todoRow])
          } else {
            const row = grid.GetRowById(todo.id)
            if (row) grid.AnimateRow(row, "Delete", undefined, () => grid.RemoveRow(row))
          }
        }
      })
    }
    return newValue
  }

  window.Grids.OnRowDelete = function (grid: TGrid, row: TRow & TreeGridTodo) {
    deleteTodo(row.id)
  }

  // @ts-expect-error
  window.Grids.OnAddTodo = function (grid: TGrid) {
    const title = "Untitled"
    if (!disableAddTodoButton && grid) {
      setDisableAddTodoButton(true)
      props.onAddTodo({ title }).then((todo) => {
        if (todo) {
          const todoRow = makeTreegridTodoRow({ todo, translations, ...props })
          setAddedRows([{ ...todoRow, focus: true, scrollTo: true }])
          syncDataFromServerToGrid(grid, [{ ...todoRow, Added: 1 }])
          grid.SortRows()
          setDisableAddTodoButton(false)
        }
      })
    }
  }

  window.Grids.OnTip = function (grid: TGrid, row: TRow & TreeGridTodo, column: TodoColumnName) {
    if (column === "completed") {
      return row.completed ? translations.markTodoAsNotCompletedTip : translations.markTodoAsCompletedTip
    }
  }

  window.Grids.OnGetSortValue = function (grid: TGrid, row: TRow & TreeGridTodo, column: TodoColumnName, value) {
    if (column === "dueDate") {
      const shouldSortToTop = isAddedRow(row.id)
      const shouldSortToBottom = !shouldSortToTop && !value
      if (shouldSortToTop) return Number.MIN_SAFE_INTEGER
      if (shouldSortToBottom) return Number.MAX_SAFE_INTEGER
    }
    return value
  }

  // @ts-ignore
  window.Grids.onClickTitleLink = function (link) {
    const linkWithContext = createPathWithContext(link)
    router.history.push(linkWithContext)
  }

  return { layout, todosView, viewInfo }
}

const TodoList = (props: TodoListProps) => {
  const translations = useTranslations()
  const { layout, todosView, viewInfo } = useTodoList(props)

  React.useEffect(() => {
    const todosToShow = filterTodosByView(props.todos, todosView)
    const data = makeTreegridTodoRows({ ...props, todos: todosToShow, viewInfo, translations })
    createTreegrid({ id, layout, data })
    return () => destroyTreegrid(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  React.useEffect(() => {
    const todosToShow = filterTodosByView(props.todos, todosView)
    const data = makeTreegridTodoRows({ ...props, todos: todosToShow, viewInfo, translations })
    updateGridData({ id, data })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [todosView])

  return <div id={id} style={{ height: "calc(100vh - 125px)" }} data-test="todo-list-container"></div>
}

export default TodoList

function adaptValue({ column, value }: { column: string; value: any }) {
  let adaptedValue: any = value
  if (column === "customers" || column === "workspaces" || column === "responsible") {
    adaptedValue = value.split(";").filter(Boolean)
  }
  if (column === "dueDate") {
    if (!value) {
      adaptedValue = null
    } else {
      adaptedValue = new Date(value).toISOString()
    }
  }
  if (column === "completed") {
    if (value === 1) {
      adaptedValue = new Date().toISOString()
    } else {
      adaptedValue = null
    }
  }

  if (column === "enableTimeComponent") {
    adaptedValue = Boolean(value)
  }
  if (column === "ganttBarColor") {
    adaptedValue = getColorFromColorHtml(value)
  }
  return adaptedValue
}

function filterTodosByView(todos: TodoViewModel[], view: TodosViewOptions) {
  if (view === "all") return todos
  if (view === "completed") return todos.filter((t) => t.completed)
  return todos.filter((t) => !t.completed)
}

const todoListDefaultColumnOrder: TodoColumnName[] = [
  "completed",
  "title",
  "description",
  "dueDate",
  "enableTimeComponent",
  "customers",
  "workspaces",
  "responsible",
  "ganttBarColor",
]

const todoListDefaultVisibleColumns: TodoColumnName[] = [
  "completed",
  "title",
  "description",
  "dueDate",
  "enableTimeComponent",
  "customers",
  "workspaces",
  "responsible",
]

export type TodosViewOptions = "all" | "currentAndFuture" | "completed"

type TodoListProps = {
  defaultColumnOrder?: TodoColumnName[]
  defaultVisibleColumns?: TodoColumnName[]
  dateFormat: string
  dateSeparator: string
  firstDayOfWeek: DayOfWeek
  gridInfo: string
  timeZone: TimeZoneType
  todos: TodoViewModel[]
  options: Option[]
  canCreateTodos: boolean
  onAddTodo: (newTodo: todoApi.NewTodoData) => Promise<TodoViewModel | void>
}

const checkIsProjectTodoListRoute = (routeMatch: string) => routeMatch === paths.projectTodos()
const checkIsTaskTodoListRoute = (routeMatch: string) => routeMatch === paths.taskTodos()
