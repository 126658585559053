import { useAppSelector } from "../../store"
import { getContextOptions } from "../store/selectors"

export const useUserContextOption = () => {
  const contextOptions = useAppSelector(getContextOptions)
  const userContext = contextOptions.find(({ type }) => type === "user")

  return {
    userContext,
  }
}
