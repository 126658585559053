import { makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import { useI18n } from "../../hooks"
import { TaskViewModel } from "../api/task"
import { useTaskMutations } from "../hooks/use-task-mutations"
import { useState } from "react"

const TaskCompletionPercentageWidget = ({ task, boardId }: TaskCompletionPercentageWidgetProps) => {
  const translations = useTranslations()
  const classes = useStyles()
  const { updateTaskCompletionPercentage } = useTaskMutations()
  const canUpdate = task.canUpdateStatus && !task.hasActiveChildren

  const [taskCompletionPercentage, setTaskCompletionPercentage] = useState<number>(task.completionPercentage)
  const handleChangeCompletionPercentage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const parsedValue = value ? parseInt(value) : 0
    const modifiedValue = parsedValue > 100 ? 100 : parsedValue < 0 ? 0 : parsedValue
    setTaskCompletionPercentage(modifiedValue)
  }

  const saveUpdatedCompletionPercentage = () => {
    if (taskCompletionPercentage !== task.completionPercentage) {
      updateTaskCompletionPercentage(task.id, { completionPercentage: taskCompletionPercentage, boardId })
    }
  }

  return (
    <div>
      <Typography variant="body1" style={{ marginBottom: 4 }}>
        <strong>{translations.completionHeader}</strong>
      </Typography>
      {canUpdate ? (
        <>
          <Typography variant="body1" component={"span"}>
            {translations.manuallyUpdatedLabel}
            {": "}
          </Typography>
          <TextField
            size="small"
            type="number"
            variant="outlined"
            value={taskCompletionPercentage}
            onChange={handleChangeCompletionPercentage}
            onBlur={saveUpdatedCompletionPercentage}
            InputProps={{ inputProps: { min: 0, max: 100 } }}
            className={classes.input}
          />
          {"%"}
        </>
      ) : (
        <Typography variant="body1">{`${translations.basedOnTasksLabel}: ${taskCompletionPercentage}%`}</Typography>
      )}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    maxWidth: 56,
    margin: theme.spacing(0, 1),
    "& input": {
      height: 8,
      padding: theme.spacing(1.25, 0.5, 1.25, 1),
    },
  },
}))

const defaultTranslations = {
  completionHeader: "Completion",
  manuallyUpdatedLabel: "Manually updated",
  basedOnTasksLabel: "Based on tasks",
}
const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const { translations: treegridTranslations } = useI18n("treegrid")
  const completionHeader = treegridTranslations.completionColumnHeader || defaults.completionHeader
  const { manuallyUpdatedLabel = defaults.manuallyUpdatedLabel, basedOnTasksLabel = defaults.basedOnTasksLabel } =
    t || {}

  return {
    completionHeader,
    manuallyUpdatedLabel,
    basedOnTasksLabel,
  }
}

export default TaskCompletionPercentageWidget
type TaskCompletionPercentageWidgetProps = {
  task: TaskViewModel
  boardId?: string
}
type Translations = typeof defaultTranslations
