import React from "react"
import Chip from "@material-ui/core/Chip"

import { Menu as MenuIcon, User as UserIcon } from "react-feather"
import { makeStyles, Theme } from "@material-ui/core/styles"

const useChipStyles = makeStyles((theme: Theme) => ({
  root: {
    fontSize: 20,
    height: 28,
    paddingLeft: theme.spacing(0.5),
    color: theme.palette.common.black,
    backgroundColor: theme.palette.divider,
    "& .MuiChip-label": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: 10,
    },
    "& .MuiChip-icon": {
      color: theme.palette.common.black,
    },
  },
}))

interface UserMenuIconProps {
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void
}

const UserMenuIcon = React.forwardRef(({ onClick }: UserMenuIconProps, ref: any) => {
  const classes = useChipStyles()
  return (
    <Chip
      className={classes.root}
      onClick={onClick}
      icon={<UserIcon size={16} />}
      label={<MenuIcon size={16} />}
      ref={ref}
      data-test="user-menu"
    />
  )
})

export default UserMenuIcon
