import React from "react"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import { makeStyles, Theme } from "@material-ui/core"
import { COLOR_BLACK, COLOR_LIGHT, treeGridColors, treeGridColorsMap } from "../constants"
import { Check } from "react-feather"
import { TreeGridColors } from "../treegrid/types"
import { useI18n } from "../hooks"
import { useIsSmallScreen } from "../hooks/use-is-small-screen"

type ColorSelectProps = {
  selectedColor: TreeGridColors
  onColorChange: (color: TreeGridColors) => void
  disabled?: boolean
}

const ColorSelectionWidget = ({ selectedColor, onColorChange, disabled = false }: ColorSelectProps) => {
  const translations = useTranslations()
  const colorOptions = treeGridColors.filter((color) => color !== "Default")

  return (
    <div>
      <Typography component="h4" variant="body1">
        <strong>{translations.color}</strong>
      </Typography>
      <Grid container spacing={1} style={{ marginTop: "8px" }}>
        {colorOptions.map(($color) => {
          const label = `${$color[0]}${$color[1]}`
          const labelToShow = selectedColor === $color ? <Check /> : label
          const color = treeGridColorsMap[$color]
          const textColor = $color === "White" || $color === "Yellow" ? COLOR_BLACK : COLOR_LIGHT
          return (
            <Grid item key={color}>
              <Button
                variant="outlined"
                style={{ backgroundColor: color, color: selectedColor === $color ? textColor : color, padding: "0" }}
                onClick={() => {
                  if ($color === selectedColor) return
                  onColorChange($color)
                }}
                size="small"
                disabled={disabled}
              >
                {labelToShow}
              </Button>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

const ColorSelectMobile = ({ selectedColor, onColorChange, disabled = false }: ColorSelectProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const colorOptions = treeGridColors.filter((color) => color !== "Default")
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget)
    }
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleSelectColor = (color: TreeGridColors) => {
    onColorChange(color)
    handleClose()
  }

  const selectedColorValue = treeGridColorsMap[selectedColor]
  const checkColor = selectedColor === "White" || selectedColor === "Yellow" ? COLOR_BLACK : COLOR_LIGHT
  const selectorColorTranslatedName = translations[selectedColor as keyof Translations] || selectedColor

  return (
    <div>
      <Typography component="h4" variant="body1" gutterBottom>
        <strong>{translations.color}</strong>
      </Typography>
      <Button
        onClick={handleClick}
        variant="outlined"
        disabled={disabled}
        style={{ backgroundColor: `${selectedColorValue}2f`, borderColor: `${selectedColorValue}2f` }}
        className={classes.button}
        fullWidth
      >
        <span className={classes.iconWrapper} style={{ backgroundColor: selectedColorValue }}>
          <Check color={checkColor} size={24} />
        </span>
        <Typography variant="body1" style={{ marginLeft: 20, display: "inline-block", fontWeight: 500 }}>
          {selectorColorTranslatedName}
        </Typography>
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {colorOptions.map((color) => {
          const colorValue = treeGridColorsMap[color]
          const translatedColorName = translations[color as keyof Translations] || color

          return (
            <MenuItem key={color} onClick={() => handleSelectColor(color)} style={{ maxWidth: 300 }} divider>
              <Grid container alignItems="center" spacing={1}>
                <Grid item>
                  <div className={classes.colorSquare} style={{ backgroundColor: colorValue }} />
                </Grid>
                <Grid item style={{ flexGrow: 1 }}>
                  <Typography variant="body2">{translatedColorName}</Typography>
                </Grid>
              </Grid>
            </MenuItem>
          )
        })}
      </Menu>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    padding: 0,
    minHeight: 56,
    position: "relative",
  },
  iconWrapper: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 32,
    height: 32,
    top: 12,
    left: 12,
    bottom: 12,
    borderRadius: "50%",
  },
  colorSquare: { width: 24, height: 24, borderRadius: 4, marginRight: 8 },
}))

const useTranslations = (defaults: Translations = defaultTranslations) => {
  const { translations } = useI18n("translation")
  const { translations: treegridTranslations } = useI18n("treegrid")
  const { color = defaults.color } = translations

  const {
    Aqua = defaults.Aqua,
    Black = defaults.Black,
    Blue = defaults.Blue,
    Fuchsia = defaults.Fuchsia,
    Gray = defaults.Gray,
    Green = defaults.Green,
    Lime = defaults.Lime,
    Maroon = defaults.Maroon,
    Navy = defaults.Navy,
    Olive = defaults.Olive,
    Orange = defaults.Orange,
    Purple = defaults.Purple,
    Red = defaults.Red,
    Silver = defaults.Silver,
    Teal = defaults.Teal,
    White = defaults.White,
    Yellow = defaults.Yellow,
  } = treegridTranslations

  return {
    color,
    Aqua,
    Black,
    Blue,
    Fuchsia,
    Gray,
    Green,
    Lime,
    Maroon,
    Navy,
    Olive,
    Orange,
    Purple,
    Red,
    Silver,
    Teal,
    White,
    Yellow,
  }
}

const defaultTranslations = {
  color: "Color",
  Aqua: "Aqua",
  Black: "Black",
  Blue: "Blue",
  Fuchsia: "Fuchsia",
  Gray: "Gray",
  Green: "Green",
  Lime: "Lime",
  Maroon: "Maroon",
  Navy: "Navy",
  Olive: "Olive",
  Orange: "Orange",
  Purple: "Purple",
  Red: "Red",
  Silver: "Silver",
  Teal: "Teal",
  White: "White",
  Yellow: "Yellow",
}

type Translations = typeof defaultTranslations

export default function ColorSelection(props: ColorSelectProps) {
  const isSmallScreen = useIsSmallScreen()
  return isSmallScreen ? <ColorSelectMobile {...props} /> : <ColorSelectionWidget {...props} />
}
