import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { debounce } from "lodash"
import { useAppContext } from "../../../../hooks/use-app-context"
import { SystemStatusId } from "../../../../organisations/api/project-type-response"
import { useOrgMutations } from "../../../../organisations/hooks/use-organisation-mutations"
import { makeStyles, Theme } from "@material-ui/core"
import { useI18n } from "../../../../hooks"
import { SystemStatus, type StatusData } from "../../../../lib/system-status"
import { useGetOrganisationByIdQuery } from "../../../../organisations/api"
import SubStatuses from "../../../../components/SubStatuses"

const OrgTaskSettings = () => {
  const translations = useTranslations()
  const orgId = useAppContext().appContext.mainContext?.id as string
  const { data: organisation } = useGetOrganisationByIdQuery(orgId)

  if (!organisation) return null

  const taskStatuses = Object.values(organisation.taskStatuses)

  return (
    <div style={{ margin: "24px auto" }}>
      <Typography variant="h6" gutterBottom>
        {translations.taskStatuses}
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{ marginBottom: 16 }}>
        {translations.taskStatusDescription}
      </Typography>
      {taskStatuses.map((status) => (
        <TaskCustomStatus key={status.systemStatusId} orgId={orgId} status={status} />
      ))}
    </div>
  )
}

export default OrgTaskSettings

const TaskCustomStatus = ({ orgId, status }: { orgId: string; status: StatusData }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const mutations = useOrgMutations()
  const translatedStatusName = translations[SystemStatus.translationKeyByStatusId[status.systemStatusId]]
  const statusName = SystemStatus.isDefaultName(status) ? translatedStatusName : status.name

  const createDebouncedRenameTaskStatus = (id: SystemStatusId) => {
    return debounce((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = event.target.value
      mutations.renameTaskStatus(id, orgId, { name })
    }, 300)
  }

  const syncStatusNameInputWithBackend = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputField = event.target
    if (inputField.value !== status.name && !inputField.value) {
      inputField.value = translatedStatusName
    }
  }

  const addSubStatus = (name: string) => {
    mutations.addTaskSubStatus(status.systemStatusId, orgId, { name })
  }

  const updateSubStatus = (id: string, updateData: { name?: string; order?: number }) => {
    mutations.updateTaskSubStatus(id, status.systemStatusId, orgId, updateData)
  }

  const deleteSubStatus = (id: string) => {
    mutations.deleteTaskSubStatus(id, status.systemStatusId, orgId)
  }

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Typography variant="subtitle1" className={classes.statusName}>
            {translations[SystemStatus.translationKeyByStatusId[status.systemStatusId]]}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {translations.renameStatus}
            </Typography>
            <TextField
              type="text"
              defaultValue={statusName}
              onChange={createDebouncedRenameTaskStatus(status.systemStatusId)}
              onBlur={syncStatusNameInputWithBackend}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Box>
          <SubStatuses
            mainStatusId={status.systemStatusId}
            subStatuses={status.subStatuses}
            addSubStatus={addSubStatus}
            updateSubStatus={updateSubStatus}
            deleteSubStatus={deleteSubStatus}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

const defaultTranslations = {
  taskStatuses: "Task statuses",
  taskStatusDescription:
    "You can change the name of the statuses and add additional statuses from here. Additional statuses added here will be available in the task status dropdown.",
  renameStatus: "Rename status",
  notStarted: "Not started",
  inProgress: "In progress",
  completed: "Completed",
  rejected: "Rejected",
} as const

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")
  const { translations: projectTranslations } = useI18n("project")
  const statusMenuTranslations = projectTranslations.projectStatusMenu

  return {
    ...defaults,
    ...statusMenuTranslations,
    ...translations,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2, 1),
    marginBottom: theme.spacing(),
  },
  statusName: {
    fontWeight: 500,
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
}))

type Translations = typeof defaultTranslations
