import React from "react"
import ProjectBasic from "./basic"
import ProjectCalendar from "./calendar"
import ProjectGantt from "./gantt"
import ProjectKanban from "./kanban"
import ProjectTasks from "./tasks"
import ProjectTodos from "./todos"
import ProjectNavbar from "../../../projects/components/Navbar/ProjectNavbar"
import ErrorList from "../../../components/ErrorList"
import ProjectArchivedAlert from "../../../projects/components/ProjectArchivedAlert"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { CircularProgress, Grid } from "@material-ui/core"
import { useProject } from "../../../projects/hooks/use-project"
import { paths } from "../../../paths"
import { useFullscreen } from "ahooks"
import { APP_MAX_WIDTH, COLOR_LIGHT } from "../../../constants"
import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"
import { Alert } from "@material-ui/lab"
import { useIsSmallScreen } from "../../../hooks/use-is-small-screen"

export const ProjectRouteContext = React.createContext<ProjectRouteContextValue | undefined>(undefined)

const ProjectLayout = ({ match, location }: RouteComponentProps<RouteParams>) => {
  const isSmallScreen = useIsSmallScreen()
  const translations = useTranslations()
  const projectId = match.params.projectId
  const { project, isLoading, error } = useProject(projectId)
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [isBaselineBarShown, setIsBaselineBarShown] = React.useState(false)
  const [isActualBarShown, setIsActualBarShown] = React.useState(true)
  const [isFullScreen, { toggleFullscreen }] = useFullscreen(containerRef)

  const toggleActualBar = () => setIsActualBarShown((prev) => !prev)
  const toggleBaselineBar = () => setIsBaselineBarShown((prev) => !prev)

  if (isLoading) return <CircularProgress />
  if (error) return <ErrorList errors={[translations.fetchErrorProject]} />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />
  const showFullScreen = !location.pathname.includes("basic")

  return (
    <ProjectRouteContext.Provider
      value={{
        isActualBarShown,
        isBaselineBarShown,
        isFullScreen,
        toggleActualBar,
        toggleBaselineBar,
        toggleFullscreen,
      }}
    >
      <div ref={containerRef} style={{ background: COLOR_LIGHT }}>
        <ProjectNavbar project={project} isFullWidth={showFullScreen} />
        {project.isTemplate && showFullScreen && (
          <div style={{ margin: "10px 0px" }}>
            <Alert severity="info">{translations.projectTemplateInfo}</Alert>
          </div>
        )}
        {project.isTemplate && !showFullScreen && (
          <Grid container alignItems="center" direction="column">
            <Grid item style={{ width: APP_MAX_WIDTH, margin: "10px 0px" }}>
              <Alert severity="info">{translations.projectTemplateInfo}</Alert>
            </Grid>
          </Grid>
        )}
        <ProjectArchivedAlert project={project} />
        <Switch>
          <Route path={paths.projectBasic()} component={ProjectBasic} />
          <Route path={paths.projectCalendar()} component={ProjectCalendar} />
          {isSmallScreen ? null : <Route path={paths.projectGantt()} component={ProjectGantt} />}
          <Route path={paths.projectKanban()} component={ProjectKanban} />
          <Route path={paths.projectTasks()} component={ProjectTasks} />
          <Route path={paths.projectTodos()} component={ProjectTodos} />
        </Switch>
      </div>
    </ProjectRouteContext.Provider>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translations")
  const { translations: p } = useI18n("project")
  const translations = t || ({} as StringMap)
  const projectTranslations = p || ({} as StringMap)

  const projectNotFoundError = translations.projectNotFoundError || defaults.projectNotFoundError
  const fetchErrorProject = translations.fetchErrorProject || defaults.fetchErrorProject
  const projectTemplateInfo = projectTranslations.projectTemplateInfo || defaults.projectTemplateInfo

  return {
    projectNotFoundError,
    fetchErrorProject,
    projectTemplateInfo,
  }
}
const defaultTranslations = {
  projectNotFoundError: "Project not found",
  fetchErrorProject: "Failed to fetch project",
  projectTemplateInfo: "You are inside a project template",
}
type Translations = typeof defaultTranslations

export default ProjectLayout

type RouteParams = { projectId: string }
type ProjectRouteContextValue = {
  isActualBarShown: boolean
  toggleActualBar: () => void
  isBaselineBarShown: boolean
  toggleBaselineBar: () => void
  isFullScreen: boolean
  toggleFullscreen: () => void
}
