import React from "react"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import CircularProgress from "@material-ui/core/CircularProgress"
import FormLabel from "@material-ui/core/FormLabel"
import FormikInput from "../../../../components/FormikInput"
import FormControl from "@material-ui/core/FormControl"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from "@material-ui/core/ListItemText"
import Select from "@material-ui/core/Select"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import ErrorFallback from "../../../../components/ErrorFallback"
import { Alert, AlertTitle } from "@material-ui/lab"
import { Form, Formik, FormikHelpers } from "formik"
import { keyBy } from "lodash"
import { useHistory } from "react-router-dom"
import { ErrorBoundary, useErrorHandler } from "react-error-boundary"
import { makeStyles, Theme } from "@material-ui/core"
import { StringMap } from "../../../../types/common"
import { unwrapResult } from "@reduxjs/toolkit"
import { useAppContext } from "../../../../hooks/use-app-context"
import { useAppDispatch } from "../../../../store"
import { useI18n, useNetworkStatus } from "../../../../hooks"
import { paths } from "../../../../paths"
import { CreateWorkspace } from "../../../../workspaces/store/actions"
import { CreateWorkspaceData } from "../../../../workspaces/workspace-api"
import { useUrlWithContext } from "../../../../hooks/use-url-with-context"
import { useOrgOptions } from "../../../../options/hooks/use-org-options"

const NewWorkspaceRoute = () => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { appContext } = useAppContext()
  const { mainContext } = appContext

  if (mainContext?.type !== "org") {
    return <Typography variant="h6">{translations.selectOrganisationMessage}</Typography>
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Paper elevation={0} className={classes.container} data-test="new-workspace-card">
        <Typography variant="h5" className={classes.header}>
          {translations.pageHeader}
        </Typography>
        <WorkspaceForm orgId={mainContext.id} />
      </Paper>
    </ErrorBoundary>
  )
}

const WorkspaceForm = ({ orgId }: { orgId: string }) => {
  const dispatch = useAppDispatch()
  const translations = useTranslations()
  const history = useHistory()
  const handleError = useErrorHandler()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 3000 })
  const { createPathWithGivenContext } = useUrlWithContext()
  const [workspaceId, setWorkspaceId] = React.useState<string | null>(null)
  const { setStatus, isRejected, isIdle } = networkStatus
  const { membershipOptions } = useOrgOptions(orgId)
  const membershipMap = keyBy(membershipOptions, "id")

  const handleSubmit = async (values: WorkspaceFormValues, { setSubmitting }: FormikHelpers<WorkspaceFormValues>) => {
    setStatus("pending")
    const payload = {
      name: values.name,
      managers: values.managers,
      members: values.members,
    } as CreateWorkspaceData
    await dispatch(CreateWorkspace({ orgId, ...payload }))
      .then(unwrapResult)
      .then((workspace) => {
        setStatus("fulfilled")
        setSubmitting(false)
        setWorkspaceId(workspace?.id || null)
      })
      .catch((error: any) => handleError(error))
  }

  const formikProps = {
    initialValues: {
      name: "",
      managers: [],
      members: [],
    } as WorkspaceFormValues,
    onSubmit: handleSubmit,
  }

  React.useEffect(() => {
    if (workspaceId) {
      const workspaceWelcomePageURLWithContext = createPathWithGivenContext({
        path: paths.workspaceWelcome(),
        mainContextId: orgId,
        subContextId: workspaceId,
      })
      history.push(workspaceWelcomePageURLWithContext)
    }
  }, [createPathWithGivenContext, history, orgId, workspaceId])

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, handleChange }) => {
        const shouldDisableButton = Boolean(values.name === "" || errors.name || !isIdle())

        return (
          <Form>
            <FormLabel htmlFor="name">{translations.nameInputLabel}</FormLabel>
            <FormikInput
              id="name"
              name="name"
              variant="outlined"
              autoComplete="off"
              fullWidth
              inputProps={{ "data-test": "workspace-name-input" }}
            />
            <FormControl style={{ width: "100%", margin: "16px 0" }}>
              <InputLabel id="managers">{translations.managerInputLabel}</InputLabel>
              <Select
                labelId="managers"
                name="managers"
                value={values.managers}
                onChange={handleChange}
                input={<Input />}
                renderValue={(ids) => {
                  if (Array.isArray(ids)) {
                    return ids.map((id) => membershipMap[id]?.name).join(", ")
                  }
                }}
                multiple
                fullWidth
                data-test="managers-select-input"
              >
                {membershipOptions
                  .filter(({ id }) => !values.members.includes(id))
                  .map(({ id, name }) => (
                    <MenuItem key={name} value={id} data-test={`menu-item-${name}`}>
                      <Checkbox color="primary" checked={values.managers.indexOf(id) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            <FormControl style={{ width: "100%", margin: "16px 0" }}>
              <InputLabel id="members">{translations.memberInputLabel}</InputLabel>
              <Select
                labelId="members"
                name="members"
                value={values.members}
                onChange={handleChange}
                input={<Input />}
                renderValue={(ids) => {
                  if (Array.isArray(ids)) {
                    return ids.map((id) => membershipMap[id]?.name).join(", ")
                  }
                }}
                multiple
                fullWidth
                data-test="members-select-input"
              >
                {membershipOptions
                  .filter(({ id }) => !values.managers.includes(id))
                  .map(({ id, name }) => (
                    <MenuItem key={name} value={id} data-test={`menu-item-${name}`}>
                      <Checkbox color="primary" checked={values.members.indexOf(id) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>

            {isRejected() && (
              <Alert severity="error">
                <AlertTitle>{translations.errorTitleMessage}</AlertTitle>
                {translations.errorMessage}
              </Alert>
            )}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={shouldDisableButton}
              startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
              style={{ marginTop: 24 }}
              data-test="create-workspace-button"
            >
              {isSubmitting ? translations.createButtonLabel_inprogress : translations.createButtonLabel_ideal}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

type WorkspaceFormValues = {
  name: string
  managers: string[]
  members: string[]
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    border: `1px solid ${theme.palette.divider}`,
    display: "flex",
    flexDirection: "column",
    margin: theme.spacing(4, "auto"),
    padding: theme.spacing(4),
    maxWidth: 650,
  },
  header: { paddingBottom: theme.spacing(3) },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t?.newWorkspacePage || {}) as StringMap

  const {
    pageHeader = defaults.pageHeader,
    selectOrganisationMessage = defaults.selectOrganisationMessage,
    nameInputLabel = defaults.nameInputLabel,
    managerInputLabel = defaults.managerInputLabel,
    memberInputLabel = defaults.memberInputLabel,
    createButtonLabel_ideal = defaults.createButtonLabel_ideal,
    createButtonLabel_inprogress = defaults.createButtonLabel_inprogress,
    errorMessage = defaults.errorMessage,
    errorTitleMessage = defaults.errorTitleMessage,
    nameValidationMessage = defaults.nameValidationMessage,
  } = translations

  return {
    pageHeader,
    selectOrganisationMessage,
    nameInputLabel,
    managerInputLabel,
    memberInputLabel,
    createButtonLabel_ideal,
    createButtonLabel_inprogress,
    errorMessage,
    nameValidationMessage,
    errorTitleMessage,
  }
}

const defaultTranslations = {
  pageHeader: "Let's create a new workspace in your organisation ",
  selectOrganisationMessage: "Select organisation before creating workspace",
  nameInputLabel: "Workspace name",
  managerInputLabel: "Workspace manager",
  memberInputLabel: "Workspace member",
  createButtonLabel_inprogress: "Creating workspace",
  createButtonLabel_ideal: "Create workspace",
  errorMessage: "Failed to create new workspace",
  errorTitleMessage: "Error",
  nameValidationMessage: "Workspace name is required",
}

type Translations = typeof defaultTranslations

export default NewWorkspaceRoute
