import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import WorkspaceMembersView from "../../../workspaces/views/WorkspaceMembersView"
import { unwrapResult } from "@reduxjs/toolkit"
import { FetchOrgMemberships } from "../../../memberships/store"
import { FetchWorkspaceById } from "../../../workspaces/store/actions"
import { useAppDispatch } from "../../../store"
import { useAsync } from "../../../hooks/use-async"
import { useWorkspace } from "../../../workspaces/hooks/use-workspace"
import { useAppContext } from "../../../hooks/use-app-context"

const useLoader = ({ workspaceId }: { workspaceId: string }) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: "load_org_memberships_route" })
    if (workspaceId) {
      const workspace = unwrapResult(await dispatch(FetchWorkspaceById(workspaceId)))
      unwrapResult(await dispatch(FetchOrgMemberships(workspace.orgId)))
    }
  }, [dispatch, workspaceId])
  return useAsync<void, any>(loader)
}

const WorkspaceMembersRoute = () => {
  const { appContext } = useAppContext()
  const workspaceId = appContext.subContext?.id as string
  const { error, isSuccess } = useLoader({ workspaceId })
  const errorMessage = error?.message ? error.message : error
  const { workspace } = useWorkspace(workspaceId)

  if (errorMessage) return <p>{errorMessage} </p>
  if (isSuccess() && workspace) return <WorkspaceMembersView workspaceId={workspaceId} />

  return <CircularProgress />
}

export default WorkspaceMembersRoute
