import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"

export const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("project")
  const translations = (t?.projectNavBar || {}) as StringMap
  const { translations: commonTranslations = {} } = useI18n("translation")
  const appNavBarTranslations = commonTranslations.appNavBar || ({} as StringMap)

  const {
    tasksLabel = defaults.tasksTabLabel,
    todosLabel = defaults.todoListTabLabel,
    notificationsLabel = defaults.notificationsLabel,
  } = appNavBarTranslations

  const {
    saveBasePlanBtnLabel = defaults.saveBasePlanBtnLabel,
    basePlanSaveSuccessTitle = defaults.basePlanSaveSuccessTitle,
    basePlanSavedSuccessText = defaults.basePlanSavedSuccessText,
    basePlanSaveFailureTitle = defaults.basePlanSaveFailureTitle,
    basePlanSavedFailureText = defaults.basePlanSavedFailureText,
    saveBasePlanConfirmTitle = defaults.saveBasePlanConfirmTitle,
    saveBasePlanConfirmBtnLabel = defaults.saveBasePlanConfirmBtnLabel,
    saveBasePlanConfirmText = defaults.saveBasePlanConfirmText,
    hideBaselineBarText = defaults.hideBaselineBarText,
    showBaselineBarText = defaults.showBaselineBarText,
    hideActualBarText = defaults.hideActualBarText,
    showActualBarText = defaults.showActualBarText,
    baselineSavedText = defaults.baselineSavedText,
    savedByYou = defaults.savedByYou,
    fullScreenAriaLabel = defaults.fullScreenAriaLabel,
    menuAriaLabel = defaults.menuAriaLabel,
    projectSettingsButtonLabel = defaults.projectSettingsButtonLabel,
    archiveProjectBtnLabel = defaults.archiveProjectBtnLabel,
    deleteProjectBtnLabel = defaults.deleteProjectBtnLabel,
    deleteProjectSecondaryText = defaults.deleteProjectSecondaryText,
    basicInfoTabLabel = defaults.basicInfoTabLabel,
    ganttChartTabLabel = defaults.ganttChartTabLabel,
    kanbanTabLabel = defaults.kanbanTabLabel,
    noStartDate = defaults.noStartDate,
    noEndDate = defaults.noEndDate,
    addStartDate = defaults.addStartDate,
    addEndDate = defaults.addEndDate,
    planTitle = defaults.planTitle,
    enableTimeComponentLabel = defaults.enableTimeComponentLabel,
    deleteProjectdSuccessText = defaults.deleteProjectdSuccessText,
    deleteProjectdFailureText = defaults.deleteProjectdFailureText,
    deleteProjectConfirmTitle = defaults.deleteProjectConfirmTitle,
    deleteProjectConfirmText = defaults.deleteProjectConfirmText,
    archiveProjectdSuccessText = defaults.archiveProjectdSuccessText,
    archiveProjectdFailureText = defaults.archiveProjectdFailureText,
    archiveProjectConfirmTitle = defaults.archiveProjectConfirmTitle,
    archiveProjectConfirmText = defaults.archiveProjectConfirmText,
    boardMenuAriaLabel = defaults.boardMenuAriaLabel,
    boardMenuSubHeader = defaults.boardMenuSubHeader,
    calendarTabLabel = defaults.calendarTabLabel,
    projectPlanToolTipLabel = defaults.projectPlanToolTipLabel,
  } = translations

  return {
    saveBasePlanBtnLabel,
    basePlanSaveSuccessTitle,
    basePlanSavedSuccessText,
    basePlanSaveFailureTitle,
    basePlanSavedFailureText,
    saveBasePlanConfirmTitle,
    saveBasePlanConfirmBtnLabel,
    saveBasePlanConfirmText,
    hideBaselineBarText,
    showBaselineBarText,
    hideActualBarText,
    showActualBarText,
    baselineSavedText,
    savedByYou,
    fullScreenAriaLabel,
    menuAriaLabel,
    projectSettingsButtonLabel,
    archiveProjectBtnLabel,
    deleteProjectBtnLabel,
    deleteProjectSecondaryText,
    basicInfoTabLabel,
    tasksTabLabel: tasksLabel,
    ganttChartTabLabel,
    kanbanTabLabel,
    noStartDate,
    noEndDate,
    addStartDate,
    addEndDate,
    planTitle,
    enableTimeComponentLabel,
    deleteProjectdSuccessText,
    deleteProjectdFailureText,
    deleteProjectConfirmTitle,
    deleteProjectConfirmText,
    archiveProjectdSuccessText,
    archiveProjectdFailureText,
    archiveProjectConfirmTitle,
    archiveProjectConfirmText,
    boardMenuAriaLabel,
    boardMenuSubHeader,
    calendarTabLabel,
    projectPlanToolTipLabel,
    todoListTabLabel: todosLabel,
    notificationsLabel,
  }
}

const defaultTranslations = {
  saveBasePlanBtnLabel: "Save base plan",
  basePlanSaveSuccessTitle: "Success",
  basePlanSavedSuccessText: "Base plan saved",
  basePlanSaveFailureTitle: "Failed",
  basePlanSavedFailureText: "Failed to save base plan",
  saveBasePlanConfirmTitle: `This cannot be done twice`,
  saveBasePlanConfirmBtnLabel: `Save`,
  saveBasePlanConfirmText: "Once you save base plan it can not be reverted or changed.",
  hideBaselineBarText: "Hide baseline bar",
  showBaselineBarText: "Show baseline bar",
  hideActualBarText: "Hide actual bar",
  showActualBarText: "Show actual bar",
  baselineSavedText: "{{saved_by}} saved baseline plan on {{date}}.",
  savedByYou: "you",
  fullScreenAriaLabel: "Open in full screen",
  menuAriaLabel: "Open menu",
  projectSettingsButtonLabel: "Project settings",
  archiveProjectBtnLabel: "Archive project",
  deleteProjectBtnLabel: "Delete project",
  deleteProjectSecondaryText: "Permanently removes the project from database.",
  basicInfoTabLabel: "Basic info",
  tasksTabLabel: "Tasks",
  ganttChartTabLabel: "Gantt",
  kanbanTabLabel: "Kanban",
  todoListTabLabel: "To-dos",
  calendarTabLabel: "Calendar",
  noStartDate: "No start",
  noEndDate: "No end",
  addStartDate: "Add start",
  addEndDate: "Add end",
  planTitle: "Schedule:",
  enableTimeComponentLabel: "Enable scheduling in hours and minutes",
  deleteProjectdSuccessText: "Project deleted, redirecting . . .",
  deleteProjectdFailureText: "Failed to delete project",
  deleteProjectConfirmTitle: "Delete this project",
  deleteProjectConfirmText:
    "Continuing this action will delete all data linked with this project including all tasks. After this it can not be restored",
  archiveProjectdSuccessText: "Project archived",
  archiveProjectdFailureText: "Failed to archive project",
  archiveProjectConfirmTitle: "Archive this project",
  archiveProjectConfirmText:
    "Archiving this project also archives all the resources linked with this project. However you can unarchive project later",
  boardMenuAriaLabel: "Open board menu",
  boardMenuSubHeader: "Board column's visibility",
  projectPlanToolTipLabel: "Plan is inherited from tasks",
  notificationsLabel: "Notifications",
}

type Translations = typeof defaultTranslations
