import { TimeZoneType } from "../../constants/timezones"
import { NotificationSettingsData } from "../../notifications/notifications"
import { DateFormatOptionType, DateFormatSeparatorOptionType } from "../../organisations/constants"
import { AccountStatus } from "../../types/common"
import { Language } from "../../utils/languages"
import { UserResponse } from "./user-response"

export const makeUserViewModel = (userResponse: UserResponse): UserViewModel => {
  const {
    id,
    createdAt,
    updatedAt,
    firstname,
    lastname,
    fullname,
    initials,
    email,
    accountStatus,
    avatarURL,
    weekendDays,
    firstDayOfWeek,
    timeZone,
    dateFormatSeparator,
    dateFormat,
    notificationSettings,
  } = userResponse

  return {
    id,
    createdAt,
    updatedAt,
    firstname,
    lastname,
    fullname,
    initials,
    email,
    accountStatus,
    avatarURL,
    weekendDays,
    firstDayOfWeek,
    timeZone,
    dateFormatSeparator,
    dateFormat,
    notificationSettings,
    language: "en",
  }
}

export type UserViewModel = {
  id: string
  createdAt: string
  updatedAt: string
  firstname: string
  lastname: string
  fullname: string
  initials: string
  email: string
  accountStatus: AccountStatus
  avatarURL: string
  weekendDays: DayOfWeek[]
  firstDayOfWeek: DayOfWeek
  timeZone: TimeZoneType
  dateFormatSeparator: DateFormatSeparatorOptionType
  dateFormat: DateFormatOptionType
  language: Language
  notificationSettings: NotificationSettingsData
}
