import { createSelector } from "@reduxjs/toolkit"
import { RootState } from "../../store"
import { filter, values } from "lodash"

export const getOptions = createSelector(
  (state: RootState) => state.options.byIds,
  (_state: RootState, predicate: any) => predicate,
  (options, predicate) => {
    return filter(values(options), predicate)
  }
)
