import Typography from "@material-ui/core/Typography"
import { makeStyles, Theme } from "@material-ui/core"
import { TodoViewModel } from "../api/todo"
import FullPath from "../../components/FullPath"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import { ExternalLink, X } from "react-feather"
import { Calendar, Check, User } from "react-feather"
import { DateTimeService } from "../../services/date-time-service"
import { useDateFormat } from "../../users/hooks/use-date-format"
import clsx from "clsx"
import { useTodoMutations } from "../hooks/use-todo-mutations"
import { sortTodosByDueDateAndCompleted } from "../utils/sort"
import React from "react"
import TodoDetails from "./TodoDetails"
import TodoCardTitle from "./TodoCardTitle"

const TodoListMobile = ({ todos }: { todos: TodoViewModel[] }) => {
  const sortedTodos = sortTodosByDueDateAndCompleted(todos)
  const [openTodoId, setOpenTodoId] = React.useState<string | null>(null)
  const openTodo = todos.find((todo) => todo.id === openTodoId)
  const classes = useStyles()
  const onOpenTodo = (todoId: string) => setOpenTodoId(todoId)

  return (
    <>
      <ul style={{ marginTop: 12, padding: 0, listStyle: "none" }}>
        {sortedTodos.map((todo) => (
          <TodoListItem key={todo.id} todo={todo} onOpenTodo={onOpenTodo} />
        ))}
      </ul>
      {openTodo ? (
        <Dialog open={openTodoId !== null} PaperProps={{ className: classes.dialogPaper }}>
          <div className={classes.dialogHeader}>
            <div className={classes.dialogHeaderLeft}>
              <FullPath
                projectLink={openTodo.projectLink}
                taskLink={openTodo.taskLink}
                ancestorTaskLinks={openTodo.ancestorTaskLinks}
                projectOrTaskNumber={openTodo.task?.taskNumber || openTodo.project?.projectNumber || ""}
                showTaskLink
              />
            </div>
            <IconButton className={classes.closeButton} onClick={() => setOpenTodoId(null)}>
              <X size={24} />
            </IconButton>
          </div>
          <div className={classes.todoCardTitleContainer}>
            <TodoCardTitle todo={openTodo} />
          </div>
          <TodoDetails todo={openTodo} />
        </Dialog>
      ) : null}
    </>
  )
}

const CheckboxIconUnchecked = () => {
  const classes = useStyles()
  return (
    <span className={clsx(classes.checkboxIcon, "unchecked")}>
      <Check size={12} />
    </span>
  )
}

const CheckboxIconChecked = () => {
  const classes = useStyles()
  return (
    <span className={clsx(classes.checkboxIcon, "checked")}>
      <Check size={12} />
    </span>
  )
}

const TodoListItem = ({ todo, onOpenTodo }: { todo: TodoViewModel; onOpenTodo: (todo: string) => void }) => {
  const classes = useStyles()
  const { dateFormat } = useDateFormat()
  const dateTimeService = new DateTimeService({ dateFormat })
  const { updateStatus } = useTodoMutations()
  const formattedDueDate = todo.dueDate ? dateTimeService.format(todo.dueDate) : null
  const formattedResponsible = todo.responsible.length ? todo.responsible.map((r) => r.name).join(", ") : null
  const shouldShowInfo = Boolean(formattedDueDate || formattedResponsible)

  return (
    <li className={classes.listItem} key={todo.id}>
      <IconButton color="primary" className={classes.openIcon} onClick={() => onOpenTodo(todo.id)}>
        <ExternalLink size={16} />
      </IconButton>

      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={!!todo.completed}
            onChange={(e) => updateStatus(todo.id, { completed: e.target.checked })}
            icon={<CheckboxIconUnchecked />}
            checkedIcon={<CheckboxIconChecked />}
          />
        }
        label=""
      />
      <div>
        <FullPath
          projectLink={todo.projectLink}
          taskLink={todo.taskLink}
          ancestorTaskLinks={todo.ancestorTaskLinks}
          projectOrTaskNumber={todo.task?.taskNumber || todo.project?.projectNumber || ""}
          showTaskLink
        />
        <Typography variant="body1">{todo.title}</Typography>
        {shouldShowInfo && (
          <div className={classes.info}>
            {formattedResponsible ? (
              <div className={classes.managerInfo}>
                <User className={classes.icon} size={12} />
                <Typography variant="body2" color="textSecondary">
                  {formattedResponsible}
                </Typography>
              </div>
            ) : null}
            {formattedDueDate ? (
              <div className={classes.scheduleInfo}>
                <Calendar className={classes.icon} size={12} />
                <Typography variant="body2" color="textSecondary">
                  {formattedDueDate}
                </Typography>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </li>
  )
}
const useStyles = makeStyles((theme: Theme) => {
  return {
    listItem: {
      background: theme.palette.background.paper,
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(2),
      marginBottom: 4,
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: theme.shape.borderRadius,
      position: "relative",
    },
    openIcon: {
      position: "absolute",
      right: 8,
      top: 8,
    },
    info: {
      display: "flex",
      alignItems: "center",
      marginTop: 16,
      "& .MuiTypography-root": {
        fontSize: 12,
      },
    },
    managerInfo: {
      display: "flex",
      alignItems: "center",
      marginRight: 16,
    },
    scheduleInfo: {
      display: "flex",
      alignItems: "center",
    },
    icon: {
      marginRight: theme.spacing(1),
      color: theme.palette.text.secondary,
    },
    checkbox: {
      marginLeft: 0,
      marginRight: theme.spacing(3),
    },
    checkboxIcon: {
      height: 20,
      width: 20,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "50%",
      zIndex: 9,
      "&.unchecked": {
        border: `1px solid ${theme.palette.common.black}`,
        "& svg": {
          opacity: 0,
          transition: theme.transitions.create("opacity"),
        },
        "&:hover": {
          "& svg": {
            opacity: 1,
          },
        },
      },
      "&.checked": {
        border: `1px solid transparent`,
        backgroundColor: theme.palette.secondary.main,
        transition: theme.transitions.create("background"),
        "& svg": {
          color: theme.palette.common.white,
        },
      },
    },
    dialogPaper: {
      minWidth: 300,
      width: "100%",
      padding: theme.spacing(2),
    },
    dialogHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },
    dialogHeaderLeft: {
      flex: 1,
    },
    closeButton: {
      marginRight: -16,
      color: theme.palette.text.secondary,
    },
    todoCardTitleContainer: {
      marginLeft: -12,
      marginBottom: 16,
    },
  }
})

export default TodoListMobile
