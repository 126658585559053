import { createAsyncThunk } from "@reduxjs/toolkit"
import { IRoleAPI } from "../interfaces/role-api"
import * as RoleAPI from "../role-api"

const makeFetchRoles = (roleAPI: IRoleAPI) => {
  return createAsyncThunk("roles/fetchRoles", async (orgId: string) => {
    return await roleAPI.fetchOrgRoles(orgId)
  })
}

const makeUpdateRoleAbilities = (roleAPI: IRoleAPI) => {
  return createAsyncThunk(
    "roles/updateRoleAbilities",
    async ({ roleId, ...updateData }: UpdateRoleAbilitiesThunkArg) => {
      return await roleAPI.updateRoleAbilities(roleId, updateData)
    }
  )
}

const makeApplyRoleAbilitiesToAll = (roleAPI: IRoleAPI) => {
  return createAsyncThunk("roles/applyRoleAbilitiesToAll", async (roleId: string) => {
    return await roleAPI.applyRoleAbilitiesToAll(roleId)
  })
}

export const FetchRoles = makeFetchRoles(RoleAPI)
export const UpdateRoleAbilities = makeUpdateRoleAbilities(RoleAPI)
export const ApplyRoleAbilitiesToAll = makeApplyRoleAbilitiesToAll(RoleAPI)

type UpdateRoleAbilitiesThunkArg = {
  roleId: string
} & RoleAPI.RoleAbilityUpdateData
