import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { useSelector } from "react-redux"
import { REFRESH_TOKEN } from "../constants"
import { Storage } from "../utils/local-storage"
import { getAuthState } from "../users/store/selectors"
import { useAppDispatch } from "../store"
import { LogoutUser, ResetAuthState } from "../users/store/actions"

const useNotFoundStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: "100%",
      height: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
    svg: {
      width: "100%",
      hight: "100%",
      maxWidth: 450,
    },
  })
)
const refreshTokenStore = new Storage(REFRESH_TOKEN)

const UnknownError = () => {
  const classes = useNotFoundStyles()
  const dispatch = useAppDispatch()
  const { refreshToken } = useSelector(getAuthState)

  const handleAppCrash = () => {
    if (refreshToken) dispatch(LogoutUser())
    refreshTokenStore.setValue(null)
    dispatch(ResetAuthState())
  }

  return (
    <Grid container justifyContent="space-between" direction="row" spacing={4}>
      <Grid item container direction="column" md={6} justifyContent="space-evenly" spacing={2}>
        <Grid item>
          <Typography variant="h3" component="h3">
            Oops, Something went wrong?
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2" component="p" color="textSecondary">
            Please login again.
          </Typography>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={() => handleAppCrash()}>
            Back to login
          </Button>
        </Grid>
      </Grid>
      <Grid item md={6}>
        <div>
          <img className={classes.svg} src="/error.svg" alt="error illustration" />
        </div>
      </Grid>
    </Grid>
  )
}

export default UnknownError
