import { uniq, keyBy, keys } from "lodash"
import { PayloadAction } from "@reduxjs/toolkit"
import { OrganisationsState } from "."
import { normalizeOrg } from "../interfaces/org-normalized"
import { IOrgResponse } from "../interfaces/org-response"

export function setOrgCase(state: OrganisationsState, action: PayloadAction<IOrgResponse>) {
  const org = normalizeOrg(action.payload)
  const { id } = org
  state.byIds[id] = org
  state.allIds = uniq([...state.allIds, id])
}

export function setOrgsCase(state: OrganisationsState, action: PayloadAction<IOrgResponse[]>) {
  const orgs = action.payload.map(normalizeOrg)
  const incomingOrgs = keyBy(orgs, "id") || {}
  const updatedOrgs = {
    ...state.byIds,
    ...incomingOrgs,
  }
  state.byIds = updatedOrgs
  state.allIds = keys(updatedOrgs)
}

export function deleteOrgCase(state: OrganisationsState, action: PayloadAction<string>) {
  const orgId = action.payload
  delete state.byIds[orgId]
  state.allIds = state.allIds.filter((id) => id !== orgId)
}
