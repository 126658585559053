import React from "react"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import Typography from "@material-ui/core/Typography"
import MuiLink from "@material-ui/core/Link"
import TaskDetails from "../../tasks/components/TaskDetails"
import TaskCardTitle from "../../tasks/components/TaskCardTitle"
import TodoCardTitle from "../../todos/components/TodoCardTitle"
import TodoDetails from "../../todos/components/TodoDetails"
import IconButton from "@material-ui/core/IconButton"
import FullPath from "../../components/FullPath"
import BoardCardMenu from "./BoardCardMenu"
import { Draggable } from "react-beautiful-dnd"
import { makeStyles, Theme } from "@material-ui/core"
import { X } from "react-feather"
import { COLOR_BLACK } from "../../constants"
import { Link } from "react-router-dom"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { BoardPopulated, CardPopulated } from "../api/board"

const BoardCard = ({ board, card }: BoardCardProps) => {
  const classes = useStyles()
  const { createPathWithContext } = useUrlWithContext()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const openDialog = () => setDialogOpen(true)
  const closeDialog = () => setDialogOpen(false)
  const isTaskCard = card.type === "task"
  const isTodoCard = card.type === "todo"
  const isDraggable = isTaskCard ? card.task.canUpdateStatus : card.todo.canUpdate

  const projectTitle = getProjectTitleFromCard(card)
  const projectLinkWithoutContext = getProjectLinkFromCard(card)
  const projectLink =
    projectLinkWithoutContext && createPathWithContext(projectLinkWithoutContext, { withSubContext: true })
  const showProjectLinkOnCard = projectLink && !board.isProjectBoard && !board.isTaskBoard
  const cardTitle = isTaskCard ? card.task.title || card.task.shownTaskNumber : card.todo.title

  return (
    <>
      <Draggable draggableId={card.id} index={card.position} isDragDisabled={!isDraggable}>
        {(provided) => (
          <Card
            className={classes.root}
            {...provided.dragHandleProps}
            {...provided.draggableProps}
            ref={provided.innerRef}
            onClick={openDialog}
          >
            <BoardCardMenu board={board} card={card} />
            <CardContent>
              {showProjectLinkOnCard && (
                <MuiLink className={classes.projectLink} color="primary" component={Link} to={projectLink} gutterBottom>
                  {projectTitle}
                </MuiLink>
              )}
              <Typography variant="body2" component="h2" gutterBottom>
                {cardTitle}
              </Typography>
            </CardContent>
          </Card>
        )}
      </Draggable>
      <Dialog
        open={dialogOpen}
        onClose={closeDialog}
        PaperProps={{
          style: {
            width: "100%",
            maxWidth: 1040,
            padding: "16px 0px",
          },
        }}
        disablePortal
      >
        <div className={classes.cardHeader}>
          <div style={{ flex: 1 }}>
            {isTaskCard && (
              <FullPath
                projectLink={card.task.projectLink}
                taskLink={card.task.taskLink}
                ancestorTaskLinks={card.task.ancestorTaskLinks}
                projectOrTaskNumber={card.task.shownTaskNumber}
                onRedirect={closeDialog}
              />
            )}
            {isTodoCard && (
              <FullPath
                projectLink={card.todo.projectLink}
                taskLink={card.todo.taskLink}
                ancestorTaskLinks={card.todo.ancestorTaskLinks}
                projectOrTaskNumber={card.todo.task?.taskNumber || card.todo.project?.projectNumber || ""}
                onRedirect={closeDialog}
                showTaskLink={true}
              />
            )}
          </div>
          <IconButton className={classes.closeIcon} onClick={closeDialog}>
            <X size={18} color={COLOR_BLACK} />
          </IconButton>
        </div>
        <div className={classes.cardTitle}>
          {isTaskCard && <TaskCardTitle task={card.task} />}
          {isTodoCard && <TodoCardTitle todo={card.todo} />}
        </div>
        <DialogContent style={{ paddingBottom: "16px " }}>
          {isTaskCard && <TaskDetails taskId={card.task.id} boardId={board.id} />}
          {isTodoCard && <TodoDetails todo={card.todo} boardId={board.id} />}
        </DialogContent>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginBottom: theme.spacing(),
  },
  projectLink: {
    fontSize: 10,
    display: "inline-flex",
    alignItems: "center",
    maxWidth: "calc(100% - 32px)",
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 0, 0, 2.5),
  },
  cardTitle: {
    paddingLeft: theme.spacing(1.5),
  },
  closeIcon: {
    marginRight: 16,
  },
  chip: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
  },
  linkDivider: {
    color: theme.palette.info.main,
  },
}))

type BoardCardProps = { board: BoardPopulated; card: CardPopulated }
export default BoardCard

function getProjectTitleFromCard(card: CardPopulated) {
  if (card.type === "task") {
    const taskNumber = card.task.shownTaskNumber
    return `${taskNumber} ${card.task.projectLink.label}`
  } else return card.todo.project?.title
}

function getProjectLinkFromCard(card: CardPopulated) {
  if (card.type === "task") return card.task.projectLink.href + "/basic"
  const projectId = card.todo.project?.id
  if (projectId) return `/projects/${projectId}/basic`
}
