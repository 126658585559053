import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import ProjectsViewSelect from "./ProjectsViewSelect"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"
import { Theme, useMediaQuery } from "@material-ui/core"
import { Plus } from "react-feather"
import { Link } from "react-router-dom"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { paths } from "../../paths"

const ProjectsViewHeader = ({ titlePrefix, showArchivedTitle = false, canCreateProjects }: ProjectsViewHeaderProps) => {
  const translations = useTranslations()
  const isXsScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("xs"))
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"))
  const { createPathWithContext } = useUrlWithContext()
  const newProjectPath = createPathWithContext(paths.newProject())

  if (isSmallScreen) {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ maxWidth: "100%", flexWrap: "nowrap" }}
      >
        <Grid item style={{ flexGrow: 1, marginRight: 16 }}>
          <ProjectsViewSelect />
        </Grid>
        {canCreateProjects ? (
          <Grid item style={{ marginRight: 4 }}>
            <Tooltip title={translations.addProjectButtonLabel}>
              <Button
                size="small"
                color="primary"
                variant="contained"
                endIcon={<Plus size={16} />}
                component={Link}
                to={newProjectPath}
                fullWidth
              >
                {isXsScreen ? translations.addProjectButtonLabel.split(" ")[0] : translations.addProjectButtonLabel}
              </Button>
            </Tooltip>
          </Grid>
        ) : null}
      </Grid>
    )
  }

  return (
    <Grid container justifyContent="space-between" alignItems="center" spacing={4}>
      <Grid item>
        <Typography variant="h6">
          {titlePrefix && (
            <Box component="span" color="text.secondary">
              {`${titlePrefix} - `}
            </Box>
          )}
          {!showArchivedTitle && <Box component="span"> {translations.pageTitle} </Box>}
          {showArchivedTitle && (
            <Box component="span" style={{ color: "red" }}>
              {translations.archivedPageTitle}
            </Box>
          )}
        </Typography>
      </Grid>
      <Grid item>
        <ProjectsViewSelect />
      </Grid>
    </Grid>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t?.projectsViewHeader || {}) as StringMap

  const {
    pageTitle = defaults.pageTitle,
    showFiltersButtonLabel = defaults.showFiltersButtonLabel,
    addProjectButtonLabel = defaults.addProjectButtonLabel,
    archivedPageTitle = defaults.archivedPageTitle,
  } = translations

  return {
    pageTitle,
    showFiltersButtonLabel,
    addProjectButtonLabel,
    archivedPageTitle,
  }
}

const defaultTranslations = {
  pageTitle: "Projects",
  showFiltersButtonLabel: "Filters",
  addProjectButtonLabel: "Add project",
  archivedPageTitle: "Archived projects",
}

type Translations = typeof defaultTranslations

type ProjectsViewHeaderProps = {
  canCreateProjects: boolean
  titlePrefix?: string
  showArchivedTitle?: boolean
}

export default ProjectsViewHeader
