import { policies } from "./policies"
import { Policies } from "./policies"

export const buildMakeAllowedAbilities =
  ({ policies }: { policies: Policies }) =>
  (abilities: string[] = []): AllowedAbilities => {
    const _abilities: Set<string> = new Set()
    abilities.forEach((ability) => addAbility(ability))

    return {
      getAbilities,
      addAbility,
      removeAbility,
      isAllowed,
    }

    // Abilities getter
    function getAbilities() {
      return Array.from(_abilities)
    }

    /**
     * Adds an ability to the abilities list if it is not already there.
     * It then calls itself recursively for all the abilities that this ability enables.
     * @param ability An ability name to add to the abilities list
     */
    function addAbility(ability: string) {
      //  add it to the abilities list
      _abilities.add(ability)
      // Then add all the other abilities that this ability enables
      policies[ability]?.enables?.forEach((ability) => {
        if (!_abilities.has(ability)) addAbility(ability)
      })
    }

    /**
     * Removes an ability from the abilities list .
     * It then calls itself recursively for all the abilities that this ability disables.
     * @param ability An ability name to remove
     */

    function removeAbility(ability: string) {
      // First remove the ability from the abilities list
      _abilities.delete(ability)
      // Then remove all the other abilities that are disabled by removed ability
      policies[ability]?.disables?.forEach((ability) => {
        if (_abilities.has(ability)) removeAbility(ability)
      })
    }

    /**
     * Checks if an ability is in the abilities list or if it is enabled by one of the abilities in the abilities list
     * @param ability An ability name to check
     * @returns  true if the ability is in the abilities list or if it is enabled by one of the abilities in the abilities list
     */
    function isAllowed(ability: string) {
      return _abilities.has(ability)
    }
  }

// Everywhere we need to operate on a collection of abilities that will have allow effect, we should use this
// The methods addAbility and removeAbility take care of adding and removing all the abilities
// that are enabled or disabled by the ability that is being added or removed
export const makeAllowedAbilities = buildMakeAllowedAbilities({ policies })

type AllowedAbilities = {
  getAbilities: () => string[]
  addAbility: (ability: string) => void
  removeAbility: (ability: string) => void
  isAllowed: (ability: string) => boolean
}
