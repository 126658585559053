import { PayloadAction } from "@reduxjs/toolkit"
import { ContextOptionsState } from "."
import { IContextOptionResponse } from "../interfaces/context-options-response"
import { normalizeContextOption } from "../interfaces/context-options-normalized"
import { filter } from "lodash"

export const setContextOptionsCase = (state: ContextOptionsState, action: PayloadAction<IContextOptionResponse[]>) => {
  const contextOptions = action.payload
  contextOptions.forEach((contextOption) => {
    state.byIds[contextOption.id] = normalizeContextOption(contextOption)
    if (!state.allIds.includes(contextOption.id)) state.allIds.push(contextOption.id)
  })
}

export const setContextOptionCase = (state: ContextOptionsState, action: PayloadAction<IContextOptionResponse>) => {
  const contextOption = action.payload
  state.byIds[contextOption.id] = normalizeContextOption(contextOption)
  if (!state.allIds.includes(contextOption.id)) {
    state.allIds.push(contextOption.id)
  }
}

export const deleteContextOptionCase = (state: ContextOptionsState, action: PayloadAction<string>) => {
  delete state.byIds[action.payload]
  state.allIds = filter(state.allIds, action.payload)
}

export const resetContextOptionsCase = (state: ContextOptionsState) => {
  state.byIds = {}
  state.allIds = []
}
