import { isAfter } from "date-fns"
import { DateTimeService } from "../../services/date-time-service"
import { TodoViewModel } from "../api/todo"

export const isTodoDueDatePastDue = (props: {
  todo: Pick<TodoViewModel, "dueDate" | "enableTimeComponent" | "completed">
  dateTimeService: DateTimeService
}) => {
  const { todo, dateTimeService } = props
  if (!todo.dueDate) return false
  const compareToDate = todo.enableTimeComponent ? todo.dueDate : dateTimeService.endOfDay(new Date(todo.dueDate))
  const timezonedCompareToDate = dateTimeService.removeTimezoneOffset(compareToDate)
  const isPastDue = !todo.completed ? isAfter(new Date(), new Date(timezonedCompareToDate)) : false
  return isPastDue
}
