import { useConfirmDialog } from "../../components/ConfirmDialog"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"
import { useLazyGetTasksQuery } from "../api"
import { useTaskMutations } from "./use-task-mutations"

export const useUnarchiveTask = () => {
  const taskActions = useTaskMutations()
  const translations = useTranslations()
  const [getTasks] = useLazyGetTasksQuery()

  const onConfirm = ({ taskId, boardId }: { taskId: string; boardId?: string }) => {
    return taskActions.unarchiveTask(taskId, { unarchiveSubtasks: true, boardId })
  }

  const onCancel = ({ taskId, boardId }: { taskId: string; boardId?: string }) => {
    return taskActions.unarchiveTask(taskId, { unarchiveSubtasks: false, boardId })
  }

  const { confirm: confirmUnarchiveSubtasks } = useConfirmDialog<ConfirmUnarchiveSubtasksFn>({
    onConfirm,
    onCancel,
    primaryActionButtonLabel: translations.unarchiveSubTasksBtnLabel,
    secondaryActionButtonLabel: translations.unarchiveTaskOnlyBtnLabel,
    text: translations.unarchiveSubTasksText,
    title: translations.unarchiveSubTasksTitle,
  })

  const unarchiveTask = async ({ taskId, boardId }: { taskId: string; boardId?: string }) => {
    const descendants = await getTasks({ task: taskId }).unwrap()
    const hasArchivedDescendants = descendants.some((task) => task.archived)
    if (hasArchivedDescendants) {
      confirmUnarchiveSubtasks({ taskId, boardId })
    } else {
      taskActions.unarchiveTask(taskId, { unarchiveSubtasks: false })
    }
  }

  return {
    unarchiveTask,
  }
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("task")
  const translations = t || ({} as StringMap)

  const {
    unarchiveSubTasksBtnLabel = defaults.unarchiveSubTasksBtnLabel,
    unarchiveTaskOnlyBtnLabel = defaults.unarchiveTaskOnlyBtnLabel,
    unarchiveSubTasksText = defaults.unarchiveSubTasksText,
    unarchiveSubTasksTitle = defaults.unarchiveSubTasksTitle,
  } = translations

  return {
    unarchiveSubTasksBtnLabel,
    unarchiveTaskOnlyBtnLabel,
    unarchiveSubTasksText,
    unarchiveSubTasksTitle,
  }
}

const defaultTranslations = {
  unarchiveSubTasksBtnLabel: "Unarchive this task and subtasks",
  unarchiveTaskOnlyBtnLabel: "Unarchive this task only",
  unarchiveSubTasksText:
    "This task has other sub-tasks which are currently archived. Do you want to unarchive those subtasks too?",
  unarchiveSubTasksTitle: "Unarchive task and subtasks",
}

type ConfirmUnarchiveSubtasksFn = (arg: { taskId: string; boardId?: string }) => void
type Translations = typeof defaultTranslations
