import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"

import { useI18n } from "../hooks"
import { StringMap } from "../types/common"
import { timeZones, TimeZoneType } from "../constants/timezones"

const TimeZoneSelectWidget = ({ timeZone, onTimeZoneChange = () => {}, disabled }: TimeZoneSelectWidgetProps) => {
  const translations = useTranslations()
  const sortedTimezones = [...timeZones].sort()

  return (
    <Paper elevation={0}>
      <Box p={4}>
        <Grid container direction="column" style={{ width: "100%" }}>
          <Typography variant="subtitle1">{translations.timeZoneSelectTitle}</Typography>
          <Grid item>
            <Autocomplete
              id="tz-select"
              options={sortedTimezones}
              value={timeZone || sortedTimezones[0]}
              onChange={(_, timeZone) => {
                if (timeZone) onTimeZoneChange({ timeZone })
              }}
              renderInput={(params: any) => <TextField {...params} variant="outlined" style={{ minWidth: 240 }} />}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n("translation")
  const translations = (t.timeZoneSelectWidget || {}) as StringMap

  const { timeZoneSelectTitle = defaults.timeZoneSelectTitle } = translations

  return { timeZoneSelectTitle }
}

const defaultTranslations = {
  timeZoneSelectTitle: "Timezone",
}

type TimeZoneSelectWidgetProps = {
  onTimeZoneChange?: ({ timeZone }: { timeZone: TimeZoneType }) => void
  timeZone?: TimeZoneType
  disabled?: boolean
}

export default TimeZoneSelectWidget
