import React from "react"
import * as taskAPI from "../../tasks/api"
import { useDateFormat } from "../../users/hooks/use-date-format"
import { TreeGridTranslations, useTranslations } from "../hooks/use-translations"
import { useTreeGridFilters } from "../hooks/use-treegrid-filters"
import { useLanguage } from "../../i18n/use-language"
import { useHistory } from "react-router-dom"
import { TaskTreeGridRow } from "../types"
import {
  getColorFromColorHtml,
  getGanttUnitFromZoom,
  getLagUnitFromText,
  getStateFromStateIcon,
  makeEnumString,
  mapTranslationsToColor,
} from "../utils"
import { addDays, addMonths, subDays, subMonths } from "date-fns"
import { useFirstDayOfWeek } from "../../hooks/use-first-day-of-week"
import { useWeekendDays } from "../../hooks/use-weekend-days"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import { useTaskArchiveFeedback } from "../hooks/use-task-archive-feedback"
import { useAddTaskButton } from "../hooks/use-show-add-task-button"
import { getLatestCreatedTasks, createTreegrid, destroyTreegrid, syncDataFromServerToGrid } from "../utils/tree-grid"
import { useUserTimeZone } from "../../hooks/use-user-time-zone"
import { DateTimeService } from "../../services/date-time-service"
import { useUpdateTaskStatus } from "../../tasks/hooks/use-update-task-status"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { makeRootNode } from "./root-node"
import { makeZooms } from "./zooms"
import { makeLayout } from "./layout"
import { getMaxEndDate, getMinStartDate, syncAddedAndUpdatedTasks } from "./utils"
import { makeTaskTreegridRows } from "./rows"
import { makeTreeGridTask } from "./tree-grid-task"
import { TimeZoneType } from "../../constants/timezones"
import { useAddedRows } from "../hooks/use-added-rows"
import { getMembershipOptions } from "../../options/utils"
import { useTaskMutations } from "../../tasks/hooks/use-task-mutations"
import { TaskViewModel } from "../../tasks/api/task"
import { ProjectViewModel } from "../../projects/api/project"
import { makeShowContextMenu } from "./show-context-menu"
import { HolidayViewModel } from "../../holidays/api/holiday"
import { SystemStatus } from "../../lib/system-status"

export const useTaskTreeGrid = ({
  project,
  task,
  tasks,
  onAddTask,
  holidays = [],
  showActualBar = false,
  showBaselineBar = false,
  showGantt = false,
}: TaskTreeGridProps) => {
  const root = project || task
  const gridId = project ? "__project_task_treegrid__" : "__task_treegrid__"
  const id = gridId
  const canCreateRootTasks = Boolean(project?.canCreateProjectTasks || task?.canCreate)
  const isUserRoot = project ? project.isUserProject : task.isUserTask
  const { enableTimeComponent } = root
  const history = useHistory()
  const locale = useLanguage()
  const translations = useTranslations()
  const firstDayOfWeek = useFirstDayOfWeek()
  const weekendDays = useWeekendDays()
  const timeZone = useUserTimeZone()
  const { dateFormat, dateSeparator } = useDateFormat()
  const { showFilters, toggleFilters } = useTreeGridFilters({ gridId })
  const { setAddedRows } = useAddedRows({ gridId })
  const { showAddTaskButton, disableAddTaskButton, setDisableAddTaskButton } = useAddTaskButton({
    canCreateRootTasks,
    translations,
  })
  const [navigateToTaskId, setNavigateToTaskId] = React.useState<null | string>(null)
  const { onArchiveSuccess } = useTaskArchiveFeedback({ gridId, dateFormat, timeZone })
  const taskActions = useTaskMutations()
  const { updatedTasks, updateTaskStatus } = useUpdateTaskStatus()
  const { createPathWithContext } = useUrlWithContext()
  const { customerOptions, membershipOptions, supplierOptions, workspaceOptions } = useOrgOptions(root.maintainerId)
  const options = isUserRoot ? [] : [...customerOptions, ...membershipOptions, ...supplierOptions, ...workspaceOptions]
  const zoomRef = React.useRef<string | null>(null)
  const scrollToDateRef = React.useRef<Date | null>(null)

  const rootNode = makeRootNode({ root, dateFormat, timeZone })
  const { zoomList, defaultZoom } = makeZooms({ translations, enableTimeComponent })
  const rows = makeTaskTreegridRows({ rootNode, tasks, dateFormat, timeZone, translations })

  // TODO memoize
  const layout = makeLayout({
    id,
    root: rootNode,
    showActualBar,
    showBaselineBar,
    showGantt,
    showGanttToolbarActions: showGantt,
    showAddTaskButton,
    showFilters,
    translations,
    options,
    weekendDays: task?.weekendDays || project?.weekendDays || weekendDays,
    firstDayOfWeek: task?.firstDayOfWeek || project?.firstDayOfWeek || firstDayOfWeek,
    dateSeparator,
    locale,
    defaultZoom,
    zoomList,
    holidays,
  })

  const minStartDate = getMinStartDate(rootNode, tasks) // TODO memoize
  const maxEndDate = getMaxEndDate(rootNode, tasks) // TODO memoize
  const dateTimeService = React.useMemo(
    () => new DateTimeService({ dateFormat, timeZone, enableTimeComponent }),
    [dateFormat, timeZone, enableTimeComponent]
  )

  if (window.Grids) {
    window.Grids.OnRenderStart = function (grid) {
      const onAddTaskFromContextMenu = async (data: Omit<taskAPI.NewTaskData, "orgId" | "projectId">) => {
        const responseData = await onAddTask({ ...data, taskCount: 1 })
        if (responseData) {
          const addedTasks = responseData.created
          const updatedTasks = responseData.updated.tasks
          syncAddedAndUpdatedTasks({ grid, addedTasks, updatedTasks, dateFormat, timeZone, setAddedRows, translations })
        }
        return responseData
      }

      const onCopyTaskFromContextMenu = async (taskId: string, options: Omit<taskAPI.CopyTaskData, "taskId">) => {
        const copyResult = await taskActions.copyTask(taskId, options)
        if (!copyResult || !grid) return
        const addedTasks = getLatestCreatedTasks(copyResult.tasks)
        const updatedTasks = tasks.filter((task) => {
          return addedTasks.findIndex((addedTask) => addedTask.id === task.id) === -1
        })
        syncAddedAndUpdatedTasks({
          grid,
          addedTasks,
          updatedTasks,
          dateFormat,
          timeZone,
          setAddedRows,
          translations,
        })
        return { added: addedTasks, updated: updatedTasks }
      }

      const { showContextMenu } = makeShowContextMenu({
        rootNode,
        dateFormat,
        timeZone,
        onAddTask: onAddTaskFromContextMenu,
        onCopyTask: onCopyTaskFromContextMenu,
        translations,
      })

      // @ts-ignore
      grid["showContextMenu"] = showContextMenu
    }

    window.Grids.OnEndDrag = function (
      grid: any,
      origRow: TaskTreeGridRow & TRow,
      __: any,
      destRow: TaskTreeGridRow & TRow,
      type: number
    ) {
      const projectId = rootNode.projectId
      const taskId = origRow.id
      const fromOrder = origRow?.order
      const toOrder = destRow?.order
      const origRowParentTaskId = origRow.parentNode?.id || ""
      const destRowParentTaskId = destRow.parentNode?.id || ""
      const parentTask = grid.GetRowById(destRowParentTaskId)
      const isAllowedToDrag = parentTask ? parentTask?.canCreateTasks : canCreateRootTasks
      if (!isAllowedToDrag) return 0 // return 0 to cancel the drag

      // type === 1 means Task is being moved above destRow
      if (type === 1) moveTaskAbove()
      // type === 2 means Task is being moved as a child of destRow
      if (type === 2) moveTaskAsChild()
      // type === 3 means Task is being moved below destRow
      if (type === 3) moveTaskBelow()

      function moveTaskAsChild() {
        taskActions.updateOrder(taskId, { projectId, parentTaskId: destRow.id }).then((updateResult) => {
          if (!updateResult) return
          const updatedTasks = updateResult.tasks
          if (updatedTasks.length) updateRowsInGrid({ translations, grid, dateFormat, timeZone, updatedTasks })
        })
        return
      }

      function moveTaskAbove() {
        let order
        if (origRowParentTaskId === destRowParentTaskId) {
          order = fromOrder < toOrder ? toOrder - 1 : toOrder
        } else {
          order = toOrder
        }
        taskActions
          .updateOrder(taskId, { projectId, order, parentTaskId: destRowParentTaskId })
          .then((updateResult) => {
            if (!updateResult) return
            const updatedTasks = updateResult.tasks
            if (updatedTasks.length) updateRowsInGrid({ translations, grid, dateFormat, timeZone, updatedTasks })
          })
        return
      }

      function moveTaskBelow() {
        let order
        if (origRowParentTaskId === destRowParentTaskId) {
          order = fromOrder < toOrder ? toOrder : toOrder + 1
        } else {
          order = toOrder + 1
        }
        taskActions
          .updateOrder(taskId, { projectId, order, parentTaskId: destRowParentTaskId })
          .then((updateResult) => {
            if (!updateResult) return
            const updatedTasks = updateResult.tasks
            if (updatedTasks.length) updateRowsInGrid({ translations, grid, dateFormat, timeZone, updatedTasks })
          })
        return
      }
    }

    // @ts-ignore
    window.Grids.OnAddTask = function (grid, row, col, event: PointerEvent) {
      if (!grid) return
      const eventTargetId = (event.target as HTMLElement).getAttribute("id")
      const isAddTaskButtonClick = eventTargetId === "addTaskButtonClickArea"
      if (isAddTaskButtonClick) {
        const taskCountInputElement = document.querySelector('input[name="taskToAddCount"]') as HTMLInputElement
        const taskCount = taskCountInputElement ? Number(taskCountInputElement.value) : 1
        const title = "Untitled task"
        const parentTaskId = row.id === "Toolbar" ? undefined : row.parentNode?.id || undefined
        const order = row.id === "Toolbar" ? undefined : row.order + 1

        if (disableAddTaskButton) return
        setDisableAddTaskButton(true)

        onAddTask({ title, parentTaskId, order, taskCount }).then((responseData) => {
          setDisableAddTaskButton(false)
          if (responseData && grid) {
            const addedTasks = responseData.created
            const updatedTasks = responseData.updated.tasks
            syncAddedAndUpdatedTasks({
              grid,
              addedTasks,
              updatedTasks,
              dateFormat,
              timeZone,
              setAddedRows,
              translations,
            })
          }
        })
      }
    }

    // @ts-ignore
    window.Grids.OnKeyDownAddTaskCount = function (event: KeyboardEvent<HTMLInputElement>) {
      event.stopImmediatePropagation()
      if (event.key === "Enter") {
        const taskCountInputElement = event.target
        const taskCount = taskCountInputElement ? Number(taskCountInputElement.value) : 1
        const title = "Untitled task"
        const grid = window.Grids[id]

        onAddTask({ title, taskCount }).then((responseData) => {
          if (responseData && grid) {
            const addedTasks = responseData.created
            const updatedTasks = responseData.updated.tasks
            syncAddedAndUpdatedTasks({
              grid,
              addedTasks,
              updatedTasks,
              dateFormat,
              timeZone,
              setAddedRows,
              translations,
            })
          }
        })
      }
    }

    window.Grids.OnEndDragGantt = function (grid, row: TRow & TaskTreeGridRow, __, draggedObjectName, start, end) {
      if (
        row.GanttCanEdit === 0 &&
        draggedObjectName === "Main" // The plan bar has gantt object name Main, so its making sure the dragged object is really a plan bar
      ) {
        const startDate: Date = new Date(start)
        const endDate: Date = new Date(end)
        let plannedEndDate: string = dateTimeService.addTimezoneOffset(endDate, "UTC").toISOString()
        let plannedStartDate: string = dateTimeService.addTimezoneOffset(startDate, "UTC").toISOString()
        // If the time component is disabled and the planned end date given by the treegrid has a timestamp of 00:00:00,
        // then we need to subtract 1 millisecond from the planned end date to get the correct end of the day.
        // For example: when the user drags the plan bar to the end of the 18th day the treegrid gives the planned end date as 19th day 00:00:00.
        // But the user wants to schedule the task at the end of the 18th day, so we need to subtract 1 millisecond from the planned end date.
        if (!enableTimeComponent && dateTimeService.isMidnight(plannedEndDate)) {
          plannedEndDate = new Date(new Date(plannedEndDate).getTime() - 1).toISOString()
        }
        taskActions.updatePlan(row.id, { plannedEndDate, plannedStartDate }).then((updateResult) => {
          if (!updateResult) return
          const updatedTasks = updateResult.tasks
          if (!updatedTasks || !updatedTasks.length) return
          updateRowsInGrid({ translations, grid, dateFormat, timeZone, updatedTasks })
        })
      }
    }

    window.Grids.OnEndDragGanttDependency = function (_, deps) {
      const [newDependency] = deps
      const [ganttAncestorTaskId, toId, type = "fs", lagTime = 0] = newDependency
      const currentZoom = zoomRef.current as string
      const lagUnit = getGanttUnitFromZoom(zoomList, currentZoom)
      const dependencyData = {
        ganttAncestorTaskId,
        toId,
        type,
        lagTime,
        lagUnit,
      }
      taskActions.addDependency(toId, dependencyData).then((updateResult) => {
        if (!updateResult) return
        const updatedTasks = updateResult.tasks
        if (!updatedTasks || !updatedTasks.length) return
        updateRowsInGrid({ translations, grid: window.Grids[id], dateFormat, timeZone, updatedTasks })
      })
      return false
    }

    window.Grids.OnGanttMenuClick = function (grid, row, col, menuName, menuItem) {
      if (menuName === "Delete the dependency") {
        const dependency = (menuItem as any).Param?.[0]?.[0]
        const [fromId, toId, type] = dependency
        const dependencyData = { ganttAncestors: [{ taskId: fromId, type }] }
        taskActions.removeDependency(toId, dependencyData).then((updatedTasks) => {
          if (!updatedTasks || !updatedTasks.length) return
          updateRowsInGrid({ translations, grid: window.Grids[id], dateFormat, timeZone, updatedTasks })
        })
        return
      }
      if (menuName === translations.deleteMainBar) {
        const updateData = { plannedStartDate: "", plannedEndDate: "" }
        taskActions.updatePlan(row.id, updateData).then((updateResult) => {
          if (!updateResult) return
          const updatedTasks = updateResult.tasks
          if (!updatedTasks || !updatedTasks.length) return
          updateRowsInGrid({ translations, grid: window.Grids[id], dateFormat, timeZone, updatedTasks })
        })
        return
      }
    }

    window.Grids.OnValueChanged = function (grid, row: TRow & TaskTreeGridRow, column: string, newValue, oldValue) {
      // NOTE: Returning early from this function will cause unexpected behavior in the treegrid
      const isColumnEditAllowed = (row as any)[`${column}CanEdit`] === 1
      const hasValueChanged = newValue !== oldValue
      const shouldDispatchUpdateRequest = isColumnEditAllowed && hasValueChanged

      if (shouldDispatchUpdateRequest) {
        let value = adaptValue({ column, newValue, oldValue })

        if (isDateColumn(column) && value) {
          const date = new Date(value)
          const adjustedDate = dateTimeService.addTimezoneOffset(date, "UTC")
          value = adjustedDate.toISOString()
        }

        if (column === "ganttBarColor") {
          row.GanttGanttClass = value === "Default" ? "Blue" : value // changes the color of the gantt bar immediately
          value = mapTranslationsToColor(translations)[value]
        }

        if (column === "status") {
          const [mainStatusId, subStatusId] = newValue.toString().split("_")
          const status = SystemStatus.statusByIndex[parseInt(mainStatusId)]
          updateTaskStatus(row.id, { status, subStatusId: subStatusId ?? null })
        }

        if (column === "actualEndDate" || column === "actualStartDate") {
          updateTaskStatus(row.id, { [column]: value })
          return newValue
        }
        taskActions.initTaskUpdate(row.id, { field: column as any, value }).then((responseData) => {
          if (!responseData || !grid) return
          const updatedTasks = "tasks" in responseData ? responseData.tasks : [responseData]
          updateRowsInGrid({ translations, grid, dateFormat, timeZone, updatedTasks })
        })
      }
      return newValue
    }

    window.Grids.OnGanttChange = function (
      grid,
      row,
      col,
      item,
      value,
      value2,
      old = null,
      old2 = null,
      action = null
    ) {
      // Treegrid calls this function twice when the dependency lag is updated, once with the updated value and once with empty value.
      // We should only handle when the value is not empty.
      const shouldUpdateDependencyLag = item === "Dependency" && action === "Edit" && value
      if (shouldUpdateDependencyLag) {
        const [ganttAncestorTaskId, taskId, type, lag = ""] = value2
        const regex = /^(-?[\d\s]+)([a-zA-Z]+)?$/
        const [match, time, unit] = lag.match(regex)
        if (!ganttAncestorTaskId || !taskId || !type || !match) return
        const lagTime = Number(time.trim())
        const currentZoom = zoomRef.current as string
        const fallbackUnit = getGanttUnitFromZoom(zoomList, currentZoom)
        const lagUnit = unit ? getLagUnitFromText(unit) : fallbackUnit
        const dependencyData = { ganttAncestorTaskId, type, lagTime, lagUnit }
        taskActions.updateDependency(taskId, dependencyData).then((updateResult) => {
          if (!updateResult) return
          const updatedTasks = updateResult.tasks
          if (!updatedTasks || !updatedTasks.length) return
          updateRowsInGrid({ translations, grid: window.Grids[id], dateFormat, timeZone, updatedTasks })
        })
      }
    }

    window.Grids.OnRowDelete = function (grid, row, type) {
      handleRowDelete()
      async function handleRowDelete() {
        if (type !== 2) {
          const archiveResult = await taskActions.archiveTask({ taskId: row.id })
          if (!archiveResult) return
          const changedTasks = archiveResult.tasks
          if (changedTasks.length && grid) {
            onArchiveSuccess(row.id, translations.taskArchiveSuccessMessage)
            const archivedTasks = changedTasks.filter((task) => task.archived)
            const archivedTasksSortedByAncestorLength = archivedTasks.sort((a, b) => {
              return b.ancestors.length - a.ancestors.length
            })

            archivedTasksSortedByAncestorLength.forEach((task) => {
              const gridRow = grid.GetRowById(task.id)
              if (gridRow) {
                grid.HideRow(gridRow)
                grid.RemoveRow(gridRow)
              }
            })
            const updatedTasks = changedTasks.filter((task) => !task.archived)
            updateRowsInGrid({ translations, grid, dateFormat, timeZone, updatedTasks })
          }
        }
      }
    }

    window.Grids.OnGanttTip = function (_g, _r, _c, _tip, event, name) {
      if (name === "Dependency") {
        const { DependencyFrom, DependencyType, DependencyTo } = event
        const fromRowNumber = (_g?.Rows as any)[DependencyFrom]?.[1]
        const toRowNumber = (_g?.Rows as any)[DependencyTo]?.[1]
        return `${fromRowNumber} => ${toRowNumber} (${DependencyType})`
      } else {
        return _tip
      }
    }

    window.Grids.OnRenderFinish = function (grid) {
      if (grid) {
        const zoomToStart = minStartDate || new Date()
        const zoomToEnd = maxEndDate ? addDays(maxEndDate, 2) : addMonths(new Date(), 1)
        const timeZonedZoomToStart = dateTimeService.removeTimezoneOffset(zoomToStart, "UTC")
        const timeZonedZoomToEnd = dateTimeService.removeTimezoneOffset(zoomToEnd, "UTC")

        scrollToDateRef.current = timeZonedZoomToStart
        grid.ZoomTo(timeZonedZoomToStart, timeZonedZoomToEnd)
      }
    }

    window.Grids.OnZoom = function (_, zoom) {
      zoomRef.current = zoom
    }
    // @ts-ignore
    window.Grids["OnFilterToggle"] = function () {
      toggleFilters()
    }
    // @ts-ignore
    window.Grids.OnClickNext = function () {
      if (window.Grids[id]) {
        if (
          zoomRef.current === translations.zoomMenuMonthsWeeksAndDays ||
          zoomRef.current === translations.zoomMenuWeeksAndDays
        ) {
          scrollToDateRef.current = addDays(scrollToDateRef.current as Date, 7)
          window.Grids[id]?.ScrollToDate(scrollToDateRef.current as Date, "Left")
        } else if (zoomRef.current === translations.zoomMenuQuartersAndMonths) {
          scrollToDateRef.current = addMonths(scrollToDateRef.current as Date, 1)
          window.Grids[id]?.ScrollToDate(scrollToDateRef.current as Date, "Left")
        } else if (zoomRef.current === translations.zoomMenuYearsAndQuarters) {
          scrollToDateRef.current = addMonths(scrollToDateRef.current as Date, 3)
          window.Grids[id]?.ScrollToDate(scrollToDateRef.current as Date, "Left")
        }
      }
    }
    // @ts-ignore
    window.Grids.OnClickPrev = function () {
      if (window.Grids[id]) {
        if (
          zoomRef.current === translations.zoomMenuMonthsWeeksAndDays ||
          zoomRef.current === translations.zoomMenuWeeksAndDays
        ) {
          scrollToDateRef.current = subDays(scrollToDateRef.current as Date, 7)
          window.Grids[id]?.ScrollToDate(scrollToDateRef.current as Date, "Left")
        } else if (zoomRef.current === translations.zoomMenuQuartersAndMonths) {
          scrollToDateRef.current = subMonths(scrollToDateRef.current as Date, 1)
          window.Grids[id]?.ScrollToDate(scrollToDateRef.current as Date, "Left")
        } else if (zoomRef.current === translations.zoomMenuYearsAndQuarters) {
          scrollToDateRef.current = subMonths(scrollToDateRef.current as Date, 3)
          window.Grids[id]?.ScrollToDate(scrollToDateRef.current as Date, "Left")
        }
      }
    }
    // @ts-ignore
    window.Grids.OnClickToday = function () {
      if (window.Grids[id]) {
        window.Grids[id]?.ScrollToDate(new Date(), "Center")
      }
    }
    // @ts-ignore
    window.Grids.OnClick = function (grid, row, col) {
      if (col === "open") setNavigateToTaskId(row.id)
      if (col === "ZoomFit" && grid) {
        const zoomToStart = dateTimeService.removeTimezoneOffset(minStartDate || new Date(), "UTC")
        const zoomToEnd = dateTimeService.removeTimezoneOffset(
          maxEndDate ? maxEndDate : addMonths(new Date(), 1),
          "UTC"
        )
        grid.ZoomTo(zoomToStart, zoomToEnd)
        return true
      }
    }

    window.Grids.OnGanttPage = function (grid, col, size) {
      if (window.Grids[id]) {
        const [left] = size as unknown as number[]
        // @ts-ignore
        const leftDate = window.Grids[id].GetGanttDate(left)
        scrollToDateRef.current = new Date(leftDate)
      }
    }

    window.Grids.OnGetExportValue = function (grid, row, col, value, format) {
      const task = row as any
      if (col === "statusDescription") {
        const tipValue = (row as any).statusDescriptionTip?.replaceAll("&nbsp;", " ").replaceAll("&bull;", "")
        const tipFormatted = tipValue ? `(${tipValue})` : ""
        const statusDescription = task.statusDescription?.toString().replaceAll("\n", " ") || ""
        const statusDescriptionExportValue = `${statusDescription} ${tipFormatted}`
        return statusDescriptionExportValue
      }
    }

    window.Grids.OnGetEnum = function (grid, row, col, enumValue) {
      if (col === "participants") {
        // @ts-ignore
        const taskManagers = row.managers?.split(";").filter(Boolean) || []
        const membershipOptions = getMembershipOptions(options)
        const participantOptions = membershipOptions.reduce((options, current) => {
          if (!taskManagers.includes(current.id)) {
            options.push(current.name)
          }
          return options
        }, [] as string[])
        return makeEnumString(participantOptions)
      }
      return enumValue
    }

    window.Grids.OnGetEnumKeys = function (grid, row, col, enumValue) {
      if (col === "participants") {
        // @ts-ignore
        const taskManagers = row.managers?.split(";").filter(Boolean) || []
        const membershipOptions = getMembershipOptions(options)
        const participantOptions = membershipOptions.reduce((options, current) => {
          if (!taskManagers.includes(current.id)) {
            options.push(current.id)
          }
          return options
        }, [] as string[])
        return makeEnumString(participantOptions)
      }
      return enumValue
    }
  }

  React.useEffect(() => {
    // Create a new treegrid instance at the beginning
    // or if one of showActualBar, showBaselineBar, locale, showFilters or showGantt changes
    createTreegrid({ id, layout, data: rows })
    return () => destroyTreegrid(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, showActualBar, showBaselineBar, showGantt, root.enableTimeComponent, project?.customProjectNumber])

  React.useEffect(() => {
    const grid = window.Grids[id]
    updateRowsInGrid({ grid, dateFormat, timeZone, updatedTasks, translations })
  }, [id, updatedTasks, dateFormat, timeZone, translations])

  React.useEffect(() => {
    if (navigateToTaskId) {
      const path = createPathWithContext(`/tasks/${navigateToTaskId}/basic`, { withSubContext: true })
      history.push(path)
    }
    return () => {
      setNavigateToTaskId(null)
    }
  }, [createPathWithContext, history, navigateToTaskId])

  React.useEffect(() => {
    const grid = window.Grids[id]
    const isGridNotRendering = grid && grid?.Rendering === false
    if (showGantt && isGridNotRendering) {
      const ganttBaseDate = !root.enableTimeComponent
        ? rootNode.ganttBaseDate
          ? dateTimeService.startOfDay(new Date(rootNode.ganttBaseDate)).getTime()
          : undefined
        : rootNode.ganttBaseDate
      const timeZonedGanttBaseDate = ganttBaseDate
        ? dateTimeService.removeTimezoneOffset(new Date(ganttBaseDate), "UTC").getTime()
        : undefined

      const ganttFinishDate = !rootNode.enableTimeComponent
        ? rootNode.ganttFinishDate
          ? dateTimeService.endOfDay(new Date(rootNode.ganttFinishDate)).getTime()
          : undefined
        : rootNode.ganttFinishDate
      const timeZonedGanttFinishDate = ganttFinishDate
        ? dateTimeService.removeTimezoneOffset(new Date(ganttFinishDate), "UTC").getTime()
        : undefined

      grid.SetGanttBase(timeZonedGanttBaseDate)
      grid.SetGanttFinish(timeZonedGanttFinishDate)
    }
  }, [id, dateFormat, showGantt, rootNode, root.enableTimeComponent, dateTimeService])

  return {
    id,
    rootNode,
    translations,
  }
}

const TaskTreeGrid = ({ isFullScreen = true, ...taskTreeGridProps }: TaskTreeGridProps) => {
  const { id } = useTaskTreeGrid(taskTreeGridProps)
  return <div id={id} style={{ height: "calc(100vh - 124px)" }} data-test="task-list-container" />
}

export default TaskTreeGrid

function adaptValue({ column, newValue, oldValue }: { column: string; newValue: any; oldValue: any }) {
  let adaptedValue: any = newValue
  if (isDateColumn(column)) {
    if (newValue) adaptedValue = new Date(newValue).toISOString()
    else adaptedValue = ""
  }
  if (isSelectionColumn(column)) {
    const oldArray: string[] = oldValue.split(";").filter(Boolean)
    const newArray: string[] = newValue.split(";").filter(Boolean)
    const add = newArray.filter((id) => !oldArray.includes(id))
    const remove = oldArray.filter((id) => !newArray.includes(id))
    adaptedValue = { add, remove }
  }
  if (column === "state") {
    adaptedValue = getStateFromStateIcon(newValue)
  }
  if (column === "ganttBarColor") {
    adaptedValue = getColorFromColorHtml(newValue)
  }
  return adaptedValue
}

const isDateColumn = (columnName: string) => {
  return (
    columnName === "actualEndDate" ||
    columnName === "actualStartDate" ||
    columnName === "plannedEndDate" ||
    columnName === "plannedStartDate"
  )
}

const isSelectionColumn = (columnName: string) => {
  return (
    columnName === "participants" ||
    columnName === "managers" ||
    columnName === "suppliers" ||
    columnName === "workspaces"
  )
}

const updateRowsInGrid = ({ grid, dateFormat, timeZone, updatedTasks, translations }: UpdateRowsInGridFnArgs) => {
  if (!grid || !updatedTasks) return
  const taskRows = updatedTasks.map((task) => makeTreeGridTask({ task, dateFormat, timeZone, translations }))
  syncDataFromServerToGrid(grid, taskRows)
}

type UpdateRowsInGridFnArgs = {
  grid: TGrid
  dateFormat: string
  timeZone: TimeZoneType
  updatedTasks: TaskViewModel[]
  translations: TreeGridTranslations
}

type ProjectTaskListProps = {
  project: ProjectViewModel
  task?: undefined
}
type SubTaskListProps = {
  task: TaskViewModel
  project?: undefined
}
type TaskTreeGridProps = (ProjectTaskListProps | SubTaskListProps) & {
  tasks: TaskViewModel[]
  holidays?: HolidayViewModel[]
  onAddTask: (
    treegridNewTaskData: Omit<taskAPI.NewBulkTaskData, "projectId" | "orgId">
  ) => Promise<taskAPI.BulkCreateTasksReturnData | void>
  showGantt?: boolean
  showActualBar?: boolean
  showBaselineBar?: boolean
  isFullScreen?: boolean
}
