import { FormikErrors } from "formik"
import { z } from "zod"

export const createFormikErrors = <T extends Record<string, unknown>>(error: z.ZodError): FormikErrors<T> => {
  const formikErrors: FormikErrors<T> = {}

  error.errors.forEach((error) => {
    // @ts-expect-error - We know that path is an array and error.message is a string
    formikErrors[error.path.filter(Boolean).join(".")] = error.message
  })

  return formikErrors
}

export const createFormikValidate = <T extends Record<string, unknown>>(schema: z.ZodType<any, any>) => {
  return (values: T): FormikErrors<T> => {
    try {
      schema.parse(values)
      return {}
    } catch (error) {
      return createFormikErrors<T>(error as z.ZodError)
    }
  }
}
