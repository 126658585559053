import React from "react"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import TextField from "@material-ui/core/TextField"
import { Plus } from "react-feather"
import { makeStyles, Theme } from "@material-ui/core"
import { NewTodoData } from "../api"
import { TodoViewModel } from "../api/todo"
import DatePicker from "../../components/DatePicker"
import Stack from "../../components/Stack"
import { useI18n } from "../../hooks"

const NewTodoDialog = ({ onAddTodo, canAddTodos }: NewTodoDialogProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const [open, setOpen] = React.useState(false)
  const [newTodo, setNewTodo] = React.useState<TodoFormState>({ title: "", dueDate: null })
  const paperRef = React.useRef<HTMLDivElement>(null)

  const openDialog = () => setOpen(canAddTodos)
  const closeDialog = () => setOpen(false)

  const handleAddTodo = () => {
    const title = newTodo.title.trim()
    const dueDate = newTodo.dueDate ? newTodo.dueDate.toISOString() : null

    if (title && canAddTodos) {
      onAddTodo({ title, dueDate })
      closeDialog()
      setNewTodo({ title: "", dueDate: null })
    }
  }

  const handleCancel = () => {
    closeDialog()
    setNewTodo({ title: "", dueDate: null })
  }

  const handleCalendarOpen = () => {
    if (paperRef.current) {
      paperRef.current.style.height = "574px"
    }
  }

  const handleCalendarClose = () => {
    if (paperRef.current) {
      paperRef.current.style.height = "280px"
    }
  }

  if (!canAddTodos) return null

  return (
    <>
      <Button variant="contained" color="primary" size="small" endIcon={<Plus size={16} />} onClick={openDialog}>
        {translations.add}
      </Button>
      <Dialog
        open={open}
        PaperProps={{
          ref: paperRef,
          className: classes.dialogPaper,
        }}
        keepMounted={false}
      >
        <DialogTitle>
          {translations.add} {translations.todo.toLocaleLowerCase()}
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <TextField
              value={newTodo.title}
              label={translations.titlePlaceholder}
              variant="outlined"
              onChange={(e) => setNewTodo({ ...newTodo, title: e.target.value })}
              autoFocus
              fullWidth
            />
            <DatePicker
              selected={newTodo.dueDate}
              onChange={(dueDate) => setNewTodo({ ...newTodo, dueDate })}
              onCalendarOpen={handleCalendarOpen}
              onCalendarClose={handleCalendarClose}
            />
          </Stack>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={handleCancel} color="primary">
            {translations.cancel}
          </Button>
          <Button color="primary" variant="contained" onClick={handleAddTodo} endIcon={<Plus size={16} />}>
            {translations.add}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  dialogPaper: {
    width: "100%",
    height: 280,
    overflow: "hidden",
    transition: "height 300ms ease-in-out",
  },
  dialogActions: {
    padding: theme.spacing(2),
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")

  const titlePlaceholder = translations.boardColumn?.cardTitlePlaceholder || defaults.titlePlaceholder

  return {
    ...defaults,
    ...translations,
    titlePlaceholder,
  }
}

const defaultTranslations = {
  title: "Enter to-do title",
  add: "Add",
  cancel: "Cancel",
  todo: "To-do",
  titlePlaceholder: "Enter title here",
}

export default NewTodoDialog

type TodoFormState = { title: string; dueDate: Date | null }
type NewTodoDialogProps = {
  onAddTodo: (newTodoData: NewTodoData) => Promise<TodoViewModel | void>
  canAddTodos: boolean
}
type Translations = typeof defaultTranslations
