import { type ProjectTypeResponse, ProjectTypeResponseSchema } from "./project-type-response"

export const makeProjectTypeViewModel = (projectType: ProjectTypeResponse): ProjectTypeViewModel => {
  const parsedProjectType = ProjectTypeResponseSchema.parse(projectType)
  const shouldTranslateType =
    parsedProjectType.type.toLowerCase() === "default" && parsedProjectType.createdBy === "System"

  return {
    ...parsedProjectType,
    shouldTranslateType,
  }
}

export type ProjectTypeViewModel = ProjectTypeResponse & {
  shouldTranslateType: boolean
}
