import { api } from "../../api"
import { TimeZoneType } from "../../constants/timezones"
import { NotificationChannel, NotificationType } from "../../notifications/notifications"
import { DateFormatOptionType, DateFormatSeparatorOptionType } from "../../organisations/constants"
import { Language } from "../../utils/languages"
import { makeUserApiEndpoints } from "./endpoints"
import { UserViewModel, makeUserViewModel } from "./user"

const apiEndpoints = makeUserApiEndpoints()

export const userApi = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getUserInfo: builder.query<UserViewModel, void>({
        query: () => apiEndpoints.userInfo(),
        transformResponse: makeUserViewModel,
        providesTags: ["User"],
      }),
      updateUserInfo: builder.mutation<UserViewModel, { userId: string } & UserInfoUpdateData>({
        query: ({ userId, ...updateData }) => ({
          url: apiEndpoints.userItem(userId),
          method: "PUT",
          body: updateData,
        }),
        invalidatesTags: ["User"],
      }),
      updateNotificationSettings: builder.mutation<
        UserViewModel,
        { userId: string } & UserNotificationSettingsUpdateData
      >({
        query: ({ userId, ...updateData }) => ({
          url: apiEndpoints.userNotificationSettings(userId),
          method: "PUT",
          body: updateData,
        }),
        invalidatesTags: ["User"],
      }),
    }
  },
})

export const { useGetUserInfoQuery, useUpdateNotificationSettingsMutation, useUpdateUserInfoMutation } = userApi

export type UserNotificationSettingsUpdateData = {
  enable: NotificationUpdateAction[]
  disable: NotificationUpdateAction[]
}

export type UserInfoUpdateData = {
  firstname?: string
  lastname?: string
  email?: string
  weekendDays?: DayOfWeek[]
  firstDayOfWeek?: DayOfWeek
  timeZone?: TimeZoneType
  dateFormatSeparator?: DateFormatSeparatorOptionType
  dateFormat?: DateFormatOptionType
  language?: Language
}

type NotificationUpdateAction = { type: NotificationType; channel: NotificationChannel }
