import { cloneDeep } from "lodash"
import {
  SystemStatus,
  type StatusMap,
  type StatusData,
  type SubStatusData,
  type SystemStatusType,
} from "./system-status"

class StatusOptionsBuilder {
  private _statusOptions: StatusOption[] = []

  static sortSubStatuses(subStatuses: SubStatusData[]): SubStatusData[] {
    return cloneDeep(subStatuses).sort((a, b) => a.order - b.order)
  }

  addMainStatus(statusData: StatusData): this {
    this._statusOptions.push({
      label: statusData.name,
      value: SystemStatus.statusByIndex[Number(statusData.systemStatusId)],
      isVisible: true,
    })
    return this
  }

  addSubStatuses(subStatuses: SubStatusData[], status: SystemStatusType): this {
    const sortedSubStatuses = StatusOptionsBuilder.sortSubStatuses(subStatuses)
    const subStatusOptions = sortedSubStatuses.map((subStatus) => ({
      label: subStatus.name,
      value: status,
      subStatusId: subStatus.id,
      isVisible: subStatus.isVisibleInOptions,
    }))
    this._statusOptions.push(...subStatusOptions)
    return this
  }

  build(): StatusOption[] {
    return this._statusOptions
  }
}

export class StatusOptionsFactory {
  static create(statusMap: Partial<StatusMap> = {}): StatusOption[] {
    const optionBuilder = new StatusOptionsBuilder()

    SystemStatus.statusByIndex.forEach((status, index) => {
      const systemStatusId = index.toString() as keyof StatusMap
      const statusData = statusMap[systemStatusId] || SystemStatus.defaultStatusMap[systemStatusId]
      optionBuilder.addMainStatus(statusData).addSubStatuses(statusData.subStatuses, status)
    })

    return optionBuilder.build()
  }
}

export type StatusOption = {
  label: string
  value: SystemStatusType
  isVisible: boolean
  subStatusId?: string
}
