import React from "react"
import * as holidayApi from "../../holidays/api"
import { TimeZoneType } from "../../constants/timezones"
import { HolidayViewModel } from "../../holidays/api/holiday"
import { useTranslations } from "../hooks/use-translations"
import { makeTreegridLayout } from "./layout"
import { makeTreeGridHolidayRow, makeTreeGridHolidayRows } from "./rows"
import { createTreegrid, destroyTreegrid, syncDataFromServerToGrid } from "../utils/tree-grid"
import { HolidayColumnName, TreeGridHoliday } from "./types"
import { DateTimeService } from "../../services/date-time-service"
import { useHolidayMutations } from "../../holidays/hooks/use-holiday-mutations"

const id = "__treegrid_holiday_list__"

const useHolidayList = (props: HolidayListProps) => {
  const translations = useTranslations()
  const { intiHolidayUpdate, deleteHoliday } = useHolidayMutations()
  const layout = makeTreegridLayout({ id, translations, ...props })

  window.Grids.OnClick = function (grid, row, col) {
    if (col === "AddHoliday") {
      const dateTimeService = new DateTimeService({
        dateFormat: props.dateFormat,
        timeZone: props.timeZone,
        enableTimeComponent: false,
      })
      const date = dateTimeService.addTimezoneOffset(new Date())
      const newHoliday: holidayApi.BaseNewHolidayData = {
        name: "Holiday",
        date,
        type: "Single",
        recurring: false,
      }
      props.onAddHoliday(newHoliday).then((holiday) => {
        if (holiday) {
          const newHoliday = makeTreeGridHolidayRow({
            holiday,
            dateFormat: props.dateFormat,
            timeZone: props.timeZone,
            canCreateHoliday: props.canCreateHoliday,
          })
          syncDataFromServerToGrid(grid, [{ ...newHoliday, Added: 1 }])
        }
      })
    }
  }

  window.Grids.OnValueChanged = function (
    grid: TGrid,
    row: TRow & TreeGridHoliday,
    column: HolidayColumnName,
    newValue,
    oldValue
  ) {
    // NOTE: Returning early from this function will cause unexpected behavior in the treegrid
    const isColumnEditAllowed = (row as any)[`${column}CanEdit`] === 1
    const hasValueChanged = newValue !== oldValue
    const shouldDispatchUpdateRequest = isColumnEditAllowed && hasValueChanged
    if (shouldDispatchUpdateRequest) {
      let value = adaptValue({ column, newValue })

      if (isDateColumn(column) && value) {
        const dateTimeService = new DateTimeService({
          dateFormat: props.dateFormat,
          timeZone: props.timeZone,
          enableTimeComponent: false,
        })
        const date = dateTimeService.startOfDay(new Date(value))
        const adjustedDate = dateTimeService.addTimezoneOffset(date)
        value = adjustedDate.toISOString()
      }

      intiHolidayUpdate(row, { field: column as any, value }).then((responseData) => {
        if (!responseData || !grid) return
        const updatedHoliday = makeTreeGridHolidayRow({
          holiday: responseData,
          dateFormat: props.dateFormat,
          timeZone: props.timeZone,
          canCreateHoliday: props.canCreateHoliday,
        })
        syncDataFromServerToGrid(grid, [updatedHoliday])
      })
    }
    return newValue
  }

  window.Grids.OnRowDelete = function (grid: TGrid, row: TRow & TreeGridHoliday) {
    handleRowDelete()
    async function handleRowDelete() {
      const deleteResult = await deleteHoliday(row.id)
      if (!deleteResult) return
      if (grid) {
        const gridRow = grid.GetRowById(row.id)
        if (gridRow) {
          grid.HideRow(gridRow)
          grid.RemoveRow(gridRow)
        }
      }
    }
  }

  return { layout }
}

const HolidayList = (props: HolidayListProps) => {
  const { layout } = useHolidayList(props)

  React.useEffect(() => {
    const data = makeTreeGridHolidayRows({ ...props })
    createTreegrid({ id, data, layout })
    return () => destroyTreegrid(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <div id={id} style={{ height: "calc(100vh - 125px)" }}></div>
}

type HolidayListProps = {
  holidays: HolidayViewModel[]
  dateSeparator: string
  dateFormat: string
  timeZone: TimeZoneType
  firstDayOfWeek: DayOfWeek
  canCreateHoliday: boolean
  onAddHoliday: (newHoliday: holidayApi.BaseNewHolidayData) => Promise<HolidayViewModel | void>
}

export default HolidayList

function adaptValue({ column, newValue }: { column: string; newValue: string }) {
  let adaptedValue = newValue

  if (isDateColumn(column)) {
    if (newValue) adaptedValue = new Date(newValue).toISOString()
    else adaptedValue = ""
  }

  return adaptedValue
}

function isDateColumn(columnName: string) {
  return columnName === "startDate" || columnName === "endDate"
}
