import ResourceManager from "../../components/ResourceManager"
import { useCalendarEventMutations } from "../hooks/use-calendar-event-mutations"
import { ResourceType } from "../../components/ResourceManager/ResourceManager"
import { useI18n } from "../../hooks/use-i18n"
import { StringMap } from "../../types/common"
import { CalendarEventViewModel } from "../api/calendar-event"

const EventTaskWidget = ({ calendarEvent, taskOptions }: EventTaskWidgetProps) => {
  const { updateTask } = useCalendarEventMutations()
  const translations = useTranslations(defaultTranslations)
  const eventTask = calendarEvent.task ? [{ id: calendarEvent.task.id, name: calendarEvent.task.title }] : []

  const onAddTask = (task: string) => updateTask(calendarEvent.id, task)
  const onRemoveTask = () => updateTask(calendarEvent.id, null)

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={taskOptions}
      resources={eventTask}
      canAddResource={calendarEvent.canUpdate}
      canRemoveResource={calendarEvent.canUpdate}
      onAddResource={onAddTask}
      onRemoveResource={onRemoveTask}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection={false}
    />
  )
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n("calendarEvent")
  const translations = (t?.taskWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: "Task",
  emptyOptionsMessage: "No tasks to connect this event",
  emptyResourcesMessage: "This event is not connected to any task",
}

type EventTaskWidgetProps = {
  calendarEvent: CalendarEventViewModel
  taskOptions: ResourceType[]
}

export default EventTaskWidget
