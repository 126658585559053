import { makeStyles, Theme } from "@material-ui/core"
import InlineEditableText from "../../../components/InlineEditableText"
import TaskNavBreadCrumbs from "../TaskNavBreadCrumbs"
import { TaskViewModel } from "../../api/task"
import { useTaskMutations } from "../../hooks/use-task-mutations"
import TaskBoardColumnChooser from "./TaskBoardColumnChooser"
import TaskMenu from "./TaskMenu"
import TaskNavTabs from "./TaskNavTabs"

const TaskNavbar = ({ task, isFullWidth = false }: TaskNavbarProps) => {
  const classes = useStyles({ isFullWidth })
  const { updateInfo } = useTaskMutations()
  const titleTypographyProps = { variant: "subtitle1", style: { fontWeight: 500 } } as const

  return (
    <>
      <div className={classes.container}>
        <div className={classes.navbarLeftWrapper}>
          <TaskNavBreadCrumbs task={task} />
          <span className={classes.divider} />
          <InlineEditableText
            isEditable={task.canUpdateDetails}
            value={task.customTaskNumber || task.taskNumber}
            onSave={(customTaskNumber) => updateInfo(task.id, { customTaskNumber })}
            TypographyProps={titleTypographyProps}
          />
          <span className={classes.divider} />
          <InlineEditableText
            isEditable={task.canUpdateDetails}
            value={task.title}
            onSave={(title) => updateInfo(task.id, { title })}
            TypographyProps={titleTypographyProps}
          />
        </div>

        <div className={classes.navbarRightWrapper}>
          <TaskNavTabs task={task} />
          <div className={classes.iconButtons}>
            <TaskBoardColumnChooser task={task} />
            <TaskMenu task={task} />
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    margin: theme.spacing(-1.5, "auto", 0, "auto"),
    zIndex: 1,
    width: ({ isFullWidth }) => (isFullWidth ? "100%" : 1440),
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  navbarLeftWrapper: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  navbarRightWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
  },
  divider: {
    width: 1,
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    height: 18,
  },
  iconButtons: {
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(1),
    "& button": {
      background: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
  },
}))

type TaskNavbarProps = {
  task: TaskViewModel
  isFullWidth?: boolean
}

type StyleProps = {
  isFullWidth: boolean
}

export default TaskNavbar
