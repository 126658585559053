import ResourceManager from "../../components/ResourceManager"
import { StringMap } from "i18next"
import { useI18n } from "../../hooks"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import { useProjectMutations } from "../hooks/use-project-mutations"
import { ProjectViewModel } from "../api/project"

const ProjectWorkspacesWidget = ({ project }: { project: ProjectViewModel }) => {
  const translations = useTranslations(defaultTranslations)
  const { workspaceOptions } = useOrgOptions(project.maintainerId)
  const { addWorkspaces, removeWorkspaces } = useProjectMutations()

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={workspaceOptions}
      resources={project.workspaces}
      canAddResource={project.canUpdateWorkspaces}
      canRemoveResource={project.canUpdateWorkspaces}
      onAddResources={(ids) => addWorkspaces(project.id, ids)}
      onRemoveResource={(id) => removeWorkspaces(project.id, [id])}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("project")
  const translations = (t?.workspacesWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: "Workspaces",
  emptyOptionsMessage: "No workspaces in this organisation",
  emptyResourcesMessage: "Project is not connected to any workspace",
}

type Translations = typeof defaultTranslations

export default ProjectWorkspacesWidget
