import { useI18n } from "../../hooks"
import { useDateTranslations } from "../../hooks/use-date-translations"
import { StringMap } from "../../types/common"

export const useTranslations = (defaults = defaultTranslations): TreeGridTranslations => {
  const { translations } = useI18n("translation")
  const { translations: treegridTranslations } = useI18n("treegrid")
  const { translations: projectTranslations } = useI18n("project")
  const { translations: orgTranslations } = useI18n("org")
  const dateTranslations = useDateTranslations()
  const statusTranslations = projectTranslations.projectStatusMenu || {}

  const {
    okButtonLabel = defaults.okButtonLabel,
    hideAllButtonLabel = defaults.hideAllButtonLabel,
    allOnButtonLabel = defaults.allOnButtonLabel,
    color = defaults.color,
    links: linksColumnHeader = defaults.linksColumnHeader,
    show = defaults.showLabel,
  } = translations
  const showLabel = show
  const { cancelButtonLabel = defaults.cancelButtonLabel } = orgTranslations?.orgMembershipsPage || ({} as StringMap)

  const longDayNames = dateTranslations.fullDayNames.join(",") || defaults.longDayNames
  const shortDayNames = dateTranslations.abbreviatedDayNames.join(",") || defaults.shortDayNames
  const day2CharNames = dateTranslations.shortDayNames.join(",") || defaults.day2CharNames
  const day1CharNames = dateTranslations.narrowDayNames.join(",") || defaults.day1CharNames

  const longMonthNames = dateTranslations.fullMonthNames.join(",") || defaults.longMonthNames
  const longMonthNames2 = longMonthNames
  const shortMonthNames = dateTranslations.shortMonthsNames.join(",") || defaults.shortMonthNames
  const shortWeekLabel = dateTranslations.shortWeekLabel || defaults.shortWeekLabel

  const {
    rowNumberColumnHeader = defaults.rowNumberColumnHeader,
    taskNumberShortColumnHeader = defaults.taskNumberShortColumnHeader,
    projectNumberShortColumnHeader = defaults.projectNumberShortColumnHeader,
    titleColumnHeader = defaults.titleColumnHeader,
    descriptionColumnHeader = defaults.descriptionColumnHeader,
    dueDateColumnHeader = defaults.dueDateColumnHeader,
    customersColumnHeader = defaults.customersColumnHeader,
    workspacesColumnHeader = defaults.workspacesColumnHeader,
    responsibleColumnHeader = defaults.responsibleColumnHeader,
    completedColumnHeader = defaults.completedColumnHeader,
    toolbarSearchInputLabel = defaults.toolbarSearchInputLabel,
    toolbarSearchInputTip = defaults.toolbarSearchInputTip,
    toolbarPrintTip = defaults.toolbarPrintTip,
    toolbarExportTip = defaults.toolbarExportTip,
    toolbarExpandAllTip = defaults.toolbarExpandAllTip,
    toolbarCollapseAllTip = defaults.toolbarCollapseAllTip,
    toolbarColumnsTip = defaults.toolbarColumnsTip,
    toolbarFilterTip = defaults.toolbarFilterTip,
    toolbarTodoListSummaryText = defaults.toolbarTodoListSummaryText,
    toolbarActivitiesListSummaryText = defaults.toolbarActivitiesListSummaryText,
    toolbarProjectListSummaryText = defaults.toolbarProjectListSummaryText,
    toolbarTaskListSummaryText = defaults.toolbarTaskListSummaryText,
    pageHeaderRowLabel = defaults.pageHeaderRowLabel,
    headerRowLabel = defaults.headerRowLabel,
    statusFilterOffLabel = defaults.statusFilterOffLabel,
    managersFilterOffLabel = defaults.managersFilterOffLabel,
    participantsFilterOffLabel = defaults.participantsFilterOffLabel,
    suppliersFilterOffLabel = defaults.suppliersFilterOffLabel,
    workspacesFilterOffLabel = defaults.workspacesFilterOffLabel,
    stateFilterOffLabel = defaults.stateFilterOffLabel,
    customersFilterOffLabel = defaults.customersFilterOffLabel,
    responsibleFilterOffLabel = defaults.responsibleFilterOffLabel,
    addTodoButtonLabel = defaults.addTodoButtonLabel,
    showTimeColumnHeader = defaults.showTimeColumnHeader,
    markTodoAsCompletedTip = defaults.markTodoAsCompletedTip,
    markTodoAsNotCompletedTip = defaults.markTodoAsNotCompletedTip,
    plannedStartDateColumnHeader = defaults.plannedStartDateColumnHeader,
    plannedEndDateColumnHeader = defaults.plannedEndDateColumnHeader,
    actualStartDateColumnHeader = defaults.actualStartDateColumnHeader,
    actualEndDateColumnHeader = defaults.actualEndDateColumnHeader,
    statusColumnHeader = defaults.statusColumnHeader,
    statusDescriptionColumnHeader = defaults.statusDescriptionColumnHeader,
    stateColumnHeader = defaults.stateColumnHeader,
    participantsColumnHeader = defaults.participantsColumnHeader,
    suppliersColumnHeader = defaults.suppliersColumnHeader,
    notReported = defaults.notReported,
    managersColumnHeader = defaults.managersColumnHeader,
    rejectedDateColumnHeader = defaults.rejectedDateColumnHeader,
    createdAtColumnHeader = defaults.createdAtColumnHeader,
    creatorColumnHeader = defaults.creatorColumnHeader,
    templateTitleColumnHeader = defaults.templateTitleColumnHeader,
    completionBasedOnTasksColumnHeader = defaults.completionBasedOnTasksColumnHeader,
    completionColumnHeader = defaults.completionColumnHeader,
    activitiesListProjectGroupHeader = defaults.activitiesListProjectGroupHeader,
    activitiesListTaskTodoAndCalendarEventGroupHeader = defaults.activitiesListTaskTodoAndCalendarEventGroupHeader,
    addProjectButtonLabel = defaults.addProjectButtonLabel,
    zoomMenuHoursAndQuarters = defaults.zoomMenuHoursAndQuarters,
    zoomMenuDaysAndHours = defaults.zoomMenuDaysAndHours,
    zoomMenuWeeksAndHalfDays = defaults.zoomMenuWeeksAndHalfDays,
    zoomMenuWeeksAndDays = defaults.zoomMenuWeeksAndDays,
    zoomMenuMonthsAndWeeks = defaults.zoomMenuMonthsAndWeeks,
    zoomMenuMonthsWeeksAndDays = defaults.zoomMenuMonthsWeeksAndDays,
    zoomMenuQuartersAndMonths = defaults.zoomMenuQuartersAndMonths,
    zoomMenuHalvesAndMonths = defaults.zoomMenuHalvesAndMonths,
    zoomMenuYearsAndQuarters = defaults.zoomMenuYearsAndQuarters,
    openColumnHeader = defaults.openColumnHeader,
    taskNumberColumnHeader = defaults.taskNumberColumnHeader,
    durationColumnHeader = defaults.durationColumnHeader,
    daysLeftColumnHeader = defaults.daysLeftColumnHeader,
    basePlanStartDateColumnHeader = defaults.basePlanStartDateColumnHeader,
    basePlanEndDateColumnHeader = defaults.basePlanEndDateColumnHeader,
    ganttDependenciesColumnHeader = defaults.ganttDependenciesColumnHeader,
    toolbarZoomInTip = defaults.toolbarZoomInTip,
    toolbarZoomOutTip = defaults.toolbarZoomOutTip,
    toolbarZoomFitTip = defaults.toolbarZoomFitTip,
    toolbarPrevTip = defaults.toolbarPrevTip,
    toolbarNextTip = defaults.toolbarNextTip,
    toolbarAddTaskBtnLabel = defaults.toolbarAddTaskBtnLabel,
    archiveTask = defaults.archiveTask,
    deleteDependency = defaults.deleteDependency,
    changeDependencyLag = defaults.changeDependencyLag,
    deleteMainBar = defaults.deleteMainBar,
    taskArchiveSuccessMessage = defaults.taskArchiveSuccessMessage,
    taskArchiveErrorMessage = defaults.taskArchiveErrorMessage,
    rowHeaders = defaults.rowHeaders,
    pageHeader = defaults.pageHeader,
    addTask = defaults.addTask,
    addTaskAbove = defaults.addTaskAbove,
    addTaskBelow = defaults.addTaskBelow,
    addSubtask = defaults.addSubtask,
    copyTaskAbove = defaults.copyTaskAbove,
    copyTaskBelow = defaults.copyTaskBelow,
    copyTaskTreeAbove = defaults.copyTaskTreeAbove,
    copyTaskTreeBelow = defaults.copyTaskTreeBelow,
    contextMenuHelpModalTitle = defaults.contextMenuHelpModalTitle,
    contextMenuHelpModalAlertInfo = defaults.contextMenuHelpModalAlertInfo,
    contextMenuHelpModalCheckboxLabel = defaults.contextMenuHelpModalCheckboxLabel,
    noData = defaults.noData,
    dependenciesLabel = defaults.dependenciesLabel,
    ganttChartLabel = defaults.ganttChartLabel,
    chooseColumnsLabel = defaults.chooseColumnsLabel,
    chooseRowsLabel = defaults.chooseRowsLabel,
    chooseItemsLabel = defaults.chooseItemsLabel,
    rowHeadersLabel = defaults.rowHeadersLabel,
    chooseToolbarsLabel = defaults.chooseToolbarsLabel,
    printOptionsLabel = defaults.printOptionsLabel,
    printMaxRowsPerPageLabel = defaults.printMaxRowsPerPageLabel,
    printStartRowLabel = defaults.printStartRowLabel,
    printExpandedRowsLabel = defaults.printExpandedRowsLabel,
    printVisibleGantLabel = defaults.printVisibleGantLabel,
    printPageSizeLabel = defaults.printPageSizeLabel,
    printBrowserPrintInfoLabel = defaults.printBrowserPrintInfoLabel,
    printPageOrientationLabel = defaults.printPageOrientationLabel,
    printMarginWidthLabel = defaults.printMarginWidthLabel,
    printMarginHeightLabel = defaults.printMarginHeightLabel,
    printFitToPageLabel = defaults.printFitToPageLabel,
    printDPIRatioLabel = defaults.printDPIRatioLabel,
    printPageWidthLabel = defaults.printPageWidthLabel,
    printPageHeightLabel = defaults.printPageHeightLabel,
    printSizeInfo = defaults.printSizeInfo,
    landscapeLabel = defaults.landscapeLabel,
    portraitLabel = defaults.portraitLabel,
    widthLabel = defaults.widthLabel,
    heightLabel = defaults.heightLabel,
    singlePageLabel = defaults.singlePageLabel,
    noneLabel = defaults.noneLabel,
    exportOptionsLabel = defaults.exportOptionsLabel,
    exportFileNameLabel = defaults.exportFileNameLabel,
    exportFileFormatLabel = defaults.exportFileFormatLabel,
    exportAllRowsExpandedLabel = defaults.exportAllRowsExpandedLabel,
    exportTreeInExcelLabel = defaults.exportTreeInExcelLabel,
    exportIndentTreeInMainColumnLabel = defaults.exportIndentTreeInMainColumnLabel,
    exportFreezeHeadRowsLabel = defaults.exportFreezeHeadRowsLabel,
    exportFreezeLeftColumnsLabel = defaults.exportFreezeLeftColumnsLabel,
    exportEmptyCellsLabel = defaults.exportEmptyCellsLabel,
    scaleLabel = defaults.scaleLabel,
    filterOffLabel = defaults.filterOffLabel,
    filterEqualLabel = defaults.filterEqualLabel,
    filterNotEqualLabel = defaults.filterNotEqualLabel,
    filterLessThanLabel = defaults.filterLessThanLabel,
    filterLessThanOrEqualLabel = defaults.filterLessThanOrEqualLabel,
    filterGreaterThanLabel = defaults.filterGreaterThanLabel,
    filterGreaterThanOrEqualLabel = defaults.filterGreaterThanOrEqualLabel,
    filterBeginsWithLabel = defaults.filterBeginsWithLabel,
    filterDoesnotBeginWithLabel = defaults.filterDoesnotBeginWithLabel,
    filterEndsWithLabel = defaults.filterEndsWithLabel,
    filterDoesnotEndWithLabel = defaults.filterDoesnotEndWithLabel,
    filterContainsLabel = defaults.filterContainsLabel,
    filterDoesnotContainLabel = defaults.filterDoesnotContainLabel,
    Default = defaults.Default,
    Aqua = defaults.Aqua,
    Black = defaults.Black,
    Blue = defaults.Blue,
    Fuchsia = defaults.Fuchsia,
    Gray = defaults.Gray,
    Green = defaults.Green,
    Lime = defaults.Lime,
    Maroon = defaults.Maroon,
    Navy = defaults.Navy,
    Olive = defaults.Olive,
    Orange = defaults.Orange,
    Purple = defaults.Purple,
    Red = defaults.Red,
    Silver = defaults.Silver,
    Teal = defaults.Teal,
    White = defaults.White,
    Yellow = defaults.Yellow,
    noGrouping = defaults.noGrouping,
    groupByManagers = defaults.groupByManagers,
    groupByResponsibles = defaults.groupByResponsibles,
    groupByParticipants = defaults.groupByParticipants,
    groupByCustomers = defaults.groupByCustomers,
    groupBySuppliers = defaults.groupBySuppliers,
    groupByWorkspaces = defaults.groupByWorkspaces,
    deleteRow = defaults.deleteRow,
    clear = defaults.clear,
    nameColumnHeader = defaults.nameColumnHeader,
    fromColumnHeader = defaults.fromColumnHeader,
    toColumnHeader = defaults.toColumnHeader,
    isRecurringColumnHeader = defaults.isRecurringColumnHeader,
    addHolidayButtonLabel = defaultTranslations.addHolidayButtonLabel,
  } = treegridTranslations

  const {
    notStarted = defaults.notStarted,
    inProgress = defaults.inProgress,
    completed = defaults.completed,
    rejected = defaults.rejected,
  } = statusTranslations

  return {
    rowNumberColumnHeader,
    taskNumberShortColumnHeader,
    projectNumberShortColumnHeader,
    titleColumnHeader,
    descriptionColumnHeader,
    dueDateColumnHeader,
    customersColumnHeader,
    workspacesColumnHeader,
    responsibleColumnHeader,
    completedColumnHeader,
    toolbarSearchInputLabel,
    toolbarSearchInputTip,
    toolbarPrintTip,
    toolbarExportTip,
    toolbarExpandAllTip,
    toolbarCollapseAllTip,
    toolbarColumnsTip,
    toolbarFilterTip,
    toolbarTodoListSummaryText,
    toolbarActivitiesListSummaryText,
    toolbarProjectListSummaryText,
    toolbarTaskListSummaryText,
    pageHeaderRowLabel,
    headerRowLabel,
    statusFilterOffLabel,
    managersFilterOffLabel,
    participantsFilterOffLabel,
    suppliersFilterOffLabel,
    workspacesFilterOffLabel,
    stateFilterOffLabel,
    customersFilterOffLabel,
    responsibleFilterOffLabel,
    addTodoButtonLabel,
    showTimeColumnHeader,
    markTodoAsCompletedTip,
    markTodoAsNotCompletedTip,
    plannedStartDateColumnHeader,
    plannedEndDateColumnHeader,
    actualStartDateColumnHeader,
    actualEndDateColumnHeader,
    statusColumnHeader,
    statusDescriptionColumnHeader,
    stateColumnHeader,
    participantsColumnHeader,
    suppliersColumnHeader,
    notReported,
    managersColumnHeader,
    rejectedDateColumnHeader,
    createdAtColumnHeader,
    creatorColumnHeader,
    templateTitleColumnHeader,
    completionBasedOnTasksColumnHeader,
    completionColumnHeader,
    activitiesListProjectGroupHeader,
    activitiesListTaskTodoAndCalendarEventGroupHeader,
    addProjectButtonLabel,
    zoomMenuHoursAndQuarters,
    zoomMenuDaysAndHours,
    zoomMenuWeeksAndHalfDays,
    zoomMenuWeeksAndDays,
    zoomMenuMonthsAndWeeks,
    zoomMenuMonthsWeeksAndDays,
    zoomMenuQuartersAndMonths,
    zoomMenuHalvesAndMonths,
    zoomMenuYearsAndQuarters,
    openColumnHeader,
    taskNumberColumnHeader,
    durationColumnHeader,
    daysLeftColumnHeader,
    basePlanStartDateColumnHeader,
    basePlanEndDateColumnHeader,
    ganttDependenciesColumnHeader,
    toolbarZoomInTip,
    toolbarZoomOutTip,
    toolbarZoomFitTip,
    toolbarPrevTip,
    toolbarNextTip,
    toolbarAddTaskBtnLabel,
    archiveTask,
    deleteDependency,
    changeDependencyLag,
    deleteMainBar,
    taskArchiveSuccessMessage,
    taskArchiveErrorMessage,
    rowHeaders,
    pageHeader,
    addTask,
    addTaskAbove,
    addTaskBelow,
    addSubtask,
    copyTaskAbove,
    copyTaskBelow,
    copyTaskTreeAbove,
    copyTaskTreeBelow,
    contextMenuHelpModalTitle,
    contextMenuHelpModalAlertInfo,
    contextMenuHelpModalCheckboxLabel,
    notStarted,
    inProgress,
    completed,
    rejected,
    noData,
    dependenciesLabel,
    ganttChartLabel,
    chooseColumnsLabel,
    chooseRowsLabel,
    chooseItemsLabel,
    rowHeadersLabel,
    chooseToolbarsLabel,
    printOptionsLabel,
    printMaxRowsPerPageLabel,
    printStartRowLabel,
    printExpandedRowsLabel,
    printVisibleGantLabel,
    printPageSizeLabel,
    printBrowserPrintInfoLabel,
    printPageOrientationLabel,
    printMarginWidthLabel,
    printMarginHeightLabel,
    printFitToPageLabel,
    printDPIRatioLabel,
    printPageWidthLabel,
    printPageHeightLabel,
    printSizeInfo,
    landscapeLabel,
    portraitLabel,
    widthLabel,
    heightLabel,
    singlePageLabel,
    noneLabel,
    exportOptionsLabel,
    exportFileNameLabel,
    exportFileFormatLabel,
    exportAllRowsExpandedLabel,
    exportTreeInExcelLabel,
    exportIndentTreeInMainColumnLabel,
    exportFreezeHeadRowsLabel,
    exportFreezeLeftColumnsLabel,
    exportEmptyCellsLabel,
    scaleLabel,
    okButtonLabel,
    cancelButtonLabel,
    hideAllButtonLabel,
    allOnButtonLabel,
    longDayNames,
    shortDayNames,
    day2CharNames,
    day1CharNames,
    longMonthNames,
    longMonthNames2,
    shortMonthNames,
    shortWeekLabel,
    showLabel,
    filterOffLabel,
    filterEqualLabel,
    filterNotEqualLabel,
    filterLessThanLabel,
    filterLessThanOrEqualLabel,
    filterGreaterThanLabel,
    filterGreaterThanOrEqualLabel,
    filterBeginsWithLabel,
    filterDoesnotBeginWithLabel,
    filterEndsWithLabel,
    filterDoesnotEndWithLabel,
    filterContainsLabel,
    filterDoesnotContainLabel,
    color,
    Default,
    Aqua,
    Black,
    Blue,
    Fuchsia,
    Gray,
    Green,
    Lime,
    Maroon,
    Navy,
    Olive,
    Orange,
    Purple,
    Red,
    Silver,
    Teal,
    White,
    Yellow,
    noGrouping,
    groupByManagers,
    groupByResponsibles,
    groupByParticipants,
    groupByCustomers,
    groupBySuppliers,
    groupByWorkspaces,
    linksColumnHeader,
    deleteRow,
    clear,
    nameColumnHeader,
    fromColumnHeader,
    toColumnHeader,
    isRecurringColumnHeader,
    addHolidayButtonLabel,
  }
}

const defaultTranslations = {
  rowNumberColumnHeader: "No.",
  taskNumberShortColumnHeader: "TNo.",
  projectNumberShortColumnHeader: "PNo.",
  titleColumnHeader: "Title",
  descriptionColumnHeader: "Description",
  plannedStartDateColumnHeader: "Planned start",
  plannedEndDateColumnHeader: "Planned end",
  actualStartDateColumnHeader: "Actual start",
  actualEndDateColumnHeader: "Actual end",
  statusColumnHeader: "Status",
  statusDescriptionColumnHeader: "Status description",
  stateColumnHeader: "State",
  dueDateColumnHeader: "Due date",
  customersColumnHeader: "Customer",
  suppliersColumnHeader: "Suppliers",
  workspacesColumnHeader: "Workspaces",
  responsibleColumnHeader: "Responsible",
  managersColumnHeader: "Manager",
  participantsColumnHeader: "Participants",
  completedColumnHeader: "Done",
  rejectedDateColumnHeader: "Rejected",
  createdAtColumnHeader: "Created at",
  creatorColumnHeader: "Creator name",
  templateTitleColumnHeader: "Template name",
  toolbarSearchInputLabel: "Search",
  toolbarSearchInputTip: "Type to search",
  toolbarPrintTip: "Print",
  toolbarExportTip: "Export",
  toolbarExpandAllTip: "Expand all",
  toolbarCollapseAllTip: "Collapse all",
  toolbarColumnsTip: "Display column chooser",
  toolbarFilterTip: "Show or hide filter row",
  toolbarTodoListSummaryText: `<p>Total: <b> {{rowCount}} </b> rows`,
  toolbarActivitiesListSummaryText: `<p>Total: <b> {{rowCount}} </b> rows`,
  toolbarProjectListSummaryText: `<p>Total: <b> {{rowCount}} </b> rows`,
  toolbarTaskListSummaryText: `<p>Total: <b> {{rowCount}} </b> rows`,
  pageHeaderRowLabel: "Page header",
  headerRowLabel: "Row headers",
  statusFilterOffLabel: "All statuses",
  managersFilterOffLabel: "All managers",
  participantsFilterOffLabel: "All participants",
  suppliersFilterOffLabel: "All suppliers",
  workspacesFilterOffLabel: "All workspaces",
  stateFilterOffLabel: "All states",
  customersFilterOffLabel: "All customers",
  responsibleFilterOffLabel: "All responsible",
  addTodoButtonLabel: "Add to-do",
  showTimeColumnHeader: "Show time",
  markTodoAsCompletedTip: "Mark to-do as completed",
  markTodoAsNotCompletedTip: "Mark to-do as not completed",
  notReported: "Not reported",
  activitiesListProjectGroupHeader: "Projects",
  activitiesListTaskTodoAndCalendarEventGroupHeader: "Tasks, to-dos and calendar events",
  addProjectButtonLabel: "Add project",
  zoomMenuHoursAndQuarters: "Hours and Quarters",
  zoomMenuDaysAndHours: "Days and Hours",
  zoomMenuWeeksAndHalfDays: "Weeks and Half Days",
  zoomMenuWeeksAndDays: "Weeks and Days",
  zoomMenuMonthsAndWeeks: "Months and Weeks",
  zoomMenuMonthsWeeksAndDays: "Months, Weeks and Days",
  zoomMenuQuartersAndMonths: "Quarters and Months",
  zoomMenuHalvesAndMonths: "Halves and Months",
  zoomMenuYearsAndQuarters: "Years and Quarters",
  openColumnHeader: "",
  taskNumberColumnHeader: "Task number",
  durationColumnHeader: "Duration",
  daysLeftColumnHeader: "Days left",
  basePlanStartDateColumnHeader: "Base plan start",
  basePlanEndDateColumnHeader: "Base plan end",
  ganttDependenciesColumnHeader: "Gantt ancestors",
  toolbarZoomInTip: "Zoom in",
  toolbarZoomOutTip: "Zoom out",
  toolbarZoomFitTip: "Zoom to fit",
  toolbarPrevTip: "Scroll gantt backward",
  toolbarNextTip: "Scroll gantt forward",
  toolbarAddTaskBtnLabel: "Add task",
  archiveTask: "Archive task",
  deleteDependency: "Delete dependency",
  changeDependencyLag: "Change dependency's lag",
  deleteMainBar: "Delete main bar",
  taskArchiveSuccessMessage: "Task archived successfully",
  taskArchiveErrorMessage: "Failed to archive task",
  rowHeaders: "Row headers",
  pageHeader: "Page Header",
  addTask: "Add task",
  addTaskAbove: "Add task above",
  addTaskBelow: "Add task below",
  addSubtask: "Add subtask",
  copyTaskAbove: "Copy task above",
  copyTaskBelow: "Copy task below",
  copyTaskTreeAbove: "Copy task tree above",
  copyTaskTreeBelow: "Copy task tree below",
  contextMenuHelpModalTitle: "You can create tasks by right clicking on any row.",
  contextMenuHelpModalAlertInfo: "Watch the demo below to see how it works.",
  contextMenuHelpModalCheckboxLabel: "Don't show me this again",
  notStarted: "Not started",
  inProgress: "In progress",
  completed: "Completed",
  rejected: "Rejected",

  noData: "No data found",
  dependenciesLabel: "Dependencies",
  ganttChartLabel: "Gantt chart",
  chooseColumnsLabel: "Choose columns",
  chooseRowsLabel: "Choose rows",
  chooseItemsLabel: "Choose items",
  rowHeadersLabel: "Row headers",
  chooseToolbarsLabel: "Choose toolbars",
  printOptionsLabel: "Print options",
  printMaxRowsPerPageLabel: "Max rows per page",
  printStartRowLabel: "Start page always by root row",
  printExpandedRowsLabel: "Print all rows expanded",
  printVisibleGantLabel: "Print visible Gantt only",
  printPageSizeLabel: "Page size",
  printBrowserPrintInfoLabel: "Set the same values also in the browser print dialog!",
  printPageOrientationLabel: "Page orientation",
  printMarginWidthLabel: "Page margin width (mm)",
  printMarginHeightLabel: "Page margin height (mm)",
  printFitToPageLabel: "Fit to one page",
  printDPIRatioLabel: "DPI ratio",
  printPageWidthLabel: "Page width (px)",
  printPageHeightLabel: "Page height (px)",
  printSizeInfo: "Page size is 1 x 1 pages",
  landscapeLabel: "Landscape",
  portraitLabel: "Portrait",
  widthLabel: "Width",
  heightLabel: "Height",
  singlePageLabel: "Single page",
  noneLabel: "None",
  exportOptionsLabel: "Export options",
  exportFileNameLabel: "File name",
  exportFileFormatLabel: "File format",
  exportAllRowsExpandedLabel: "Export all rows expanded",
  exportTreeInExcelLabel: "Export tree in Excel outline",
  exportIndentTreeInMainColumnLabel: "Indent tree in main column",
  exportFreezeHeadRowsLabel: "Freeze (anchor) head rows",
  exportFreezeLeftColumnsLabel: "Freeze (anchor) left columns",
  exportEmptyCellsLabel: "Export also empty cells",
  scaleLabel: "Scale",
  okButtonLabel: "OK",
  cancelButtonLabel: "Cancel",
  hideAllButtonLabel: "Hide all",
  allOnButtonLabel: "All on",
  clear: "Clear",
  longDayNames: "Sunday,Monday,Tuesday,Wednesday,Thursday,Friday,Saturday",
  shortDayNames: "Sun,Mon,Tue,Wed,Thu,Fri,Sat",
  day2CharNames: "Su,Mo,Tu,We,Th,Fr,Sa",
  day1CharNames: "S,M,T,W,T,F,S",
  longMonthNames: "January,February,March,April,May,June,July,August,September,October,November,December",
  longMonthNames2: "January,February,March,April,May,June,July,August,September,October,November,December",
  shortMonthNames: "Jan,Feb,Mar,Apr,May,Jun,Jul,Aug,Sep,Oct,Nov,Dec",
  shortWeekLabel: "wk",
  showLabel: "Show",

  filterOffLabel: "Off",
  filterEqualLabel: "Equal",
  filterNotEqualLabel: "Not equal",
  filterLessThanLabel: "Less than",
  filterLessThanOrEqualLabel: "Less than or equal",
  filterGreaterThanLabel: "Greater than",
  filterGreaterThanOrEqualLabel: "Greater than or equal",
  filterBeginsWithLabel: "Begins with",
  filterDoesnotBeginWithLabel: "Does not begin with",
  filterEndsWithLabel: "Ends with",
  filterDoesnotEndWithLabel: "Does not end with",
  filterContainsLabel: "Contains",
  filterDoesnotContainLabel: "Does not contain",

  color: "Color",
  Default: "Default",
  Aqua: "Aqua",
  Black: "Black",
  Blue: "Blue",
  Fuchsia: "Fuchsia",
  Gray: "Gray",
  Green: "Green",
  Lime: "Lime",
  Maroon: "Maroon",
  Navy: "Navy",
  Olive: "Olive",
  Orange: "Orange",
  Purple: "Purple",
  Red: "Red",
  Silver: "Silver",
  Teal: "Teal",
  White: "White",
  Yellow: "Yellow",

  noGrouping: "No grouping",
  groupByManagers: "Group by Managers",
  groupByResponsibles: "Group by Responsibles",
  groupByParticipants: "Group by Participants",
  groupByCustomers: "Group by Customers",
  groupBySuppliers: "Group by Suppliers",
  groupByWorkspaces: "Group by Workspaces",
  linksColumnHeader: "Links",
  deleteRow: "Delete row",

  nameColumnHeader: "Name",
  fromColumnHeader: "From",
  toColumnHeader: "To",
  isRecurringColumnHeader: "Recurring",
  addHolidayButtonLabel: "Add holiday",

  completionColumnHeader: "Completion",
  completionBasedOnTasksColumnHeader: "Task-based completion",
}

export type TreeGridTranslations = typeof defaultTranslations
