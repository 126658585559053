import React from "react"
import Typography from "@material-ui/core/Typography"
import Stack from "../../../components/Stack"
import AppLanguageSwitch from "../../../components/LanguageSwitch"
import Logout from "../../../components/Logout"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import AcceptedInvitationCard from "../../../invitations/components/AcceptedInvitationCard"
import PendingInvitationCard from "../../../invitations/components/PendingInvitationCard"
import { useLocation, Link } from "react-router-dom"
import { paths } from "../../../paths"
import { useAppDispatch } from "../../../store"
import { FetchUser } from "../../../users/store/actions"
import { FetchUserInvitations } from "../../../invitations/store/actions"
import { Alert } from "@material-ui/lab"
import { useUserInvitations } from "../../../invitations/hooks/use-user-invitations"
import { CircularProgress, Theme, makeStyles } from "@material-ui/core"
import { IInvitationResponse } from "../../../invitations/interfaces/invitation-response"
import { useAuthUser } from "../../../users/hooks/use-auth-user"
import { ArrowRight } from "react-feather"
import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"
import { unwrapResult } from "@reduxjs/toolkit"
import { useUrlWithContext } from "../../../hooks/use-url-with-context"

const WelcomeRoute = () => {
  const classes = useStyles()
  const location = useLocation()
  const translations = useTranslations()
  const { createPathWithGivenContext } = useUrlWithContext()
  const { from } = (location.state || {}) as { from?: Location }
  const isFromAccountVerification = from?.pathname === paths.accountVerification()
  const { pendingInvitations } = useUserInvitations()
  const dispatch = useAppDispatch()
  const [status, setStatus] = React.useState<Status>("loading")
  const [error, setError] = React.useState<string | null>(null)
  const isLoading = status === "loading"
  const isRejected = status === "rejected"
  const [acceptedInvitation, setAcceptedInvitation] = React.useState<IInvitationResponse | null>(null)
  const hasInvitations = pendingInvitations.length > 0 || acceptedInvitation
  const { user } = useAuthUser()

  React.useEffect(() => {
    async function loader() {
      if (isFromAccountVerification) {
        try {
          unwrapResult(await dispatch(FetchUser()))
          unwrapResult(await dispatch(FetchUserInvitations()))
          setStatus("fulfilled")
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : "Unknown error"
          setStatus("rejected")
          setError(errorMessage)
        }
      }
    }
    loader()
  }, [isFromAccountVerification, dispatch])

  if (isRejected) return <p>{error}</p>
  if (isLoading && isFromAccountVerification) return <CircularProgress />

  return (
    <Stack className={classes.container} spacing={1}>
      <Stack direction="row" packed alignItems="center" style={{ marginBottom: 4 }}>
        <AppLanguageSwitch />
        <Logout className={classes.logout} />
      </Stack>
      <section>
        {isFromAccountVerification && (
          <Alert>
            <b>{translations.accountVerified}</b>
          </Alert>
        )}
        {hasInvitations && (
          <>
            <header className={classes.header}>
              <Typography variant="h5" gutterBottom>
                {user?.firstname}, {translations.invitationReceived}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {translations.invitationReceivedDescription}
              </Typography>
            </header>
            <ul className={classes.invitationList}>
              {acceptedInvitation && <AcceptedInvitationCard invitation={acceptedInvitation} />}
              {pendingInvitations.map((invitation) => (
                <PendingInvitationCard
                  key={invitation.id}
                  invitation={invitation}
                  setAcceptedInvitation={setAcceptedInvitation}
                />
              ))}
            </ul>
          </>
        )}
      </section>
      <section>
        {!hasInvitations && (
          <>
            <Paper variant="outlined" className={classes.card}>
              <Stack>
                <div>
                  <Typography variant="h6" gutterBottom>
                    {translations.getStarted}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {translations.getStartedDescription}
                  </Typography>
                </div>
                <Alert variant="standard" severity="info">
                  {translations.organisationCreateAlert}
                </Alert>
                <Button
                  component={Link}
                  color="primary"
                  to={paths.newOrganisation()}
                  endIcon={<ArrowRight size={16} />}
                  replace
                >
                  {translations.createOrganisation}
                </Button>
              </Stack>
            </Paper>
            <Paper variant="outlined" className={classes.card}>
              <Stack>
                <div>
                  <Typography variant="h6" gutterBottom>
                    {translations.notReady}
                  </Typography>
                  <Typography variant="body1" color="textSecondary">
                    {translations.notReadyDescription}
                  </Typography>
                </div>
                <Alert variant="standard" severity="info">
                  {translations.unlimitedProjectsFreeAlert}
                </Alert>
                <Button
                  component={Link}
                  color="primary"
                  to={createPathWithGivenContext({ path: paths.newProject(), mainContextId: user?.id })}
                  endIcon={<ArrowRight size={16} />}
                  replace
                >
                  {translations.createFirstProject}
                </Button>
              </Stack>
            </Paper>
          </>
        )}
      </section>
      <section style={{ marginTop: 20 }}>
        <Button
          component={Link}
          color="primary"
          to={createPathWithGivenContext({ path: paths.home(), mainContextId: user?.id })}
          endIcon={<ArrowRight size={16} />}
          replace
        >
          {translations.skipButtonLabel}
        </Button>
      </section>
    </Stack>
  )
}

export default WelcomeRoute

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 800,
    margin: "50px auto",
  },
  invitationList: {
    padding: 0,
  },
  header: {
    margin: theme.spacing(4, 0, 3, 0),
  },
  logout: {
    marginTop: theme.spacing(3),
    borderRadius: 4,
    border: `1px solid ${theme.palette.divider}`,
    background: theme.palette.common.white,
  },
  card: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t || {}) as StringMap

  return {
    createOrganisation: translate("createOrganisation"),
    createFirstProject: translate("createFirstProject"),
    skipButtonLabel: translate("skipButtonLabel"),
    getStarted: translate("getStarted"),
    getStartedDescription: translate("getStartedDescription"),
    notReady: translate("notReady"),
    notReadyDescription: translate("notReadyDescription"),
    invitationReceivedDescription: translate("invitationReceivedDescription"),
    invitationReceived: translate("invitationReceived"),
    accountVerified: translate("accountVerified"),
    organisationCreateAlert: translate("organisationCreateAlert"),
    unlimitedProjectsFreeAlert: translate("unlimitedProjectsFreeAlert"),
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

const defaultTranslations = {
  createOrganisation: "Create organisation",
  createFirstProject: "Create your first project",
  skipButtonLabel: "You can also skip this and go to your home page",
  getStarted: "Start here if you want to use starbrix to collaborate on projects with your team",
  getStartedDescription:
    "You can create projects, tasks, todos, and calendar events in the organisation and collaborate in these activities with your team.",
  notReady: "If you are not ready to create an organisation yet",
  notReadyDescription:
    "If you're not ready to create an organisation just yet, you always have the option to set one up later.",
  invitationReceivedDescription:
    "You have received invitation from an organisation and you will be able to collaborate on the projects, tasks and other activities of the organisation when you accept the invitation.",
  invitationReceived: "Your colleagues are waiting . . .",
  accountVerified: "Your account has been verified.",
  organisationCreateAlert: "You can create an organisation for free",
  unlimitedProjectsFreeAlert:
    "You can create unlimited personal projects, tasks and calendar events in starbrix for free.",
}

type Status = "loading" | "rejected" | "fulfilled"
type Translations = typeof defaultTranslations
