import * as organisationApi from "../api"
import { useSnackbar } from "notistack"
import { useUpdateOrgNotificationSettingsMutation } from "../api"
import { NotificationChannel, NotificationType } from "../../notifications/notifications"
import { SystemStatusId } from "../api/project-type-response"

export const useOrgMutations = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [updateNotificationSettingsMutation] = useUpdateOrgNotificationSettingsMutation()
  const [addProjectTypeMutation] = organisationApi.useAddProjectTypeMutation()
  const [updateProjectTypeMutation] = organisationApi.useUpdateProjectTypeMutation()
  const [deleteProjectTypeMutation] = organisationApi.useDeleteProjectTypeMutation()
  const [renameProjectStatusMutation] = organisationApi.useRenameProjectStatusMutation()
  const [addProjectSubStatusMutation] = organisationApi.useAddProjectSubStatusMutation()
  const [updateProjectSubStatusMutation] = organisationApi.useUpdateProjectSubStatusMutation()
  const [deleteProjectSubStatusMutation] = organisationApi.useDeleteProjectSubStatusMutation()
  const [renameTaskStatusMutation] = organisationApi.useRenameTaskStatusMutation()
  const [addTaskSubStatusMutation] = organisationApi.useAddTaskSubStatusMutation()
  const [updateTaskSubStatusMutation] = organisationApi.useUpdateTaskSubStatusMutation()
  const [deleteTaskSubStatusMutation] = organisationApi.useDeleteTaskSubStatusMutation()

  const handleError = (error: any) => {
    const errorMessage = "error" in error ? error.error : "message" in error ? error.message : "Unknown error"
    enqueueSnackbar(errorMessage, { variant: "error" })
  }

  const enableNotification = (orgId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [{ type, channel }], disable: [] }
    return updateNotificationSettings(orgId, updateData)
  }

  const disableNotification = (orgId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [], disable: [{ type, channel }] }
    return updateNotificationSettings(orgId, updateData)
  }

  const updateNotificationSettings = async (orgId: string, data: organisationApi.OrgNotificationSettingsUpdateData) => {
    return await updateNotificationSettingsMutation({ orgId, ...data })
      .unwrap()
      .catch(handleError)
  }

  const addProjectType = async (orgId: string, type: string) => {
    return await addProjectTypeMutation({ orgId, type }).unwrap().catch(handleError)
  }

  const updateProjectType = async (
    orgId: string,
    projectTypeId: string,
    updateData: organisationApi.ProjectTypeUpdateData
  ) => {
    return await updateProjectTypeMutation({ orgId, id: projectTypeId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const deleteProjectType = async (orgId: string, projectTypeId: string) => {
    return await deleteProjectTypeMutation({ orgId, id: projectTypeId }).unwrap().catch(handleError)
  }

  const renameProjectStatus = async (
    id: SystemStatusId,
    projectTypeId: string,
    orgId: string,
    updateData: organisationApi.ProjectCustomStatusUpdateData
  ) => {
    return await renameProjectStatusMutation({ orgId, id, projectTypeId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const addProjectSubStatus = async (
    projectStatusId: SystemStatusId,
    projectTypeId: string,
    orgId: string,
    subStatusData: organisationApi.NewSubStatusData
  ) => {
    return await addProjectSubStatusMutation({ projectStatusId, projectTypeId, orgId, ...subStatusData })
      .unwrap()
      .catch(handleError)
  }

  const updateProjectSubStatus = async (
    id: string,
    projectTypeId: string,
    projectStatusId: SystemStatusId,
    orgId: string,
    subStatusData: organisationApi.SubStatusUpdateData
  ) => {
    return await updateProjectSubStatusMutation({ id, orgId, projectTypeId, projectStatusId, ...subStatusData })
      .unwrap()
      .catch(handleError)
  }

  const deleteProjectSubStatus = async (
    id: string,
    projectTypeId: string,
    projectStatusId: SystemStatusId,
    orgId: string
  ) => {
    return await deleteProjectSubStatusMutation({ id, orgId, projectTypeId, projectStatusId })
      .unwrap()
      .catch(handleError)
  }

  const renameTaskStatus = async (
    id: SystemStatusId,
    orgId: string,
    updateData: organisationApi.TaskCustomStatusUpdateData
  ) => {
    return await renameTaskStatusMutation({ orgId, id, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const addTaskSubStatus = async (
    id: SystemStatusId,
    orgId: string,
    subStatusData: organisationApi.NewSubStatusData
  ) => {
    return await addTaskSubStatusMutation({ id, orgId, ...subStatusData })
      .unwrap()
      .catch(handleError)
  }

  const updateTaskSubStatus = async (
    id: string,
    statusId: SystemStatusId,
    orgId: string,
    subStatusData: organisationApi.SubStatusUpdateData
  ) => {
    return await updateTaskSubStatusMutation({ id, orgId, statusId, ...subStatusData })
      .unwrap()
      .catch(handleError)
  }

  const deleteTaskSubStatus = async (id: string, statusId: SystemStatusId, orgId: string) => {
    return await deleteTaskSubStatusMutation({ id, orgId, statusId }).unwrap().catch(handleError)
  }

  return {
    enableNotification,
    disableNotification,
    updateNotificationSettings,
    addProjectType,
    updateProjectType,
    deleteProjectType,
    renameProjectStatus,
    addProjectSubStatus,
    updateProjectSubStatus,
    deleteProjectSubStatus,
    renameTaskStatus,
    addTaskSubStatus,
    updateTaskSubStatus,
    deleteTaskSubStatus,
  }
}
