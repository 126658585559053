import ResourceManager from "../../components/ResourceManager"
import { StringMap } from "../../types/common"
import { useI18n } from "../../hooks"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import { TaskViewModel } from "../api/task"
import { useTaskMutations } from "../hooks/use-task-mutations"

const TaskWorkspacesWidget = ({ task, boardId }: { task: TaskViewModel; boardId?: string }) => {
  const translations = useTranslations(defaultTranslations)
  const { addWorkspaces, removeWorkspaces } = useTaskMutations()
  const { workspaceOptions } = useOrgOptions(task.maintainerId)

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={workspaceOptions}
      resources={task.workspaces}
      canAddResource={task.canUpdateWorkspaces}
      canRemoveResource={task.canUpdateWorkspaces}
      onAddResource={(id) => addWorkspaces(task.id, [id])}
      onRemoveResource={(id) => removeWorkspaces(task.id, { workspaceIds: [id], boardId })}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("task")
  const translations = (t?.workspacesWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: "Workspaces",
  emptyOptionsMessage: "No workspaces in this organisation",
  emptyResourcesMessage: "Task is not connected to any workspace",
}

type Translations = typeof defaultTranslations

export default TaskWorkspacesWidget
