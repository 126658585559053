import { useMembershipActions } from "../../memberships/hooks/use-membership-actions"
import { useAppDispatch, useAppSelector } from "../../store"
import { getMembershipPermissions } from "../store/selectors"
import { UpdateMembershipAbilities } from "../store/actions"
import { unwrapResult } from "@reduxjs/toolkit"

export const useMembershipPermission = (membershipId: string) => {
  const dispatch = useAppDispatch()
  const membershipActions = useMembershipActions()
  const permission = useAppSelector((s) => getMembershipPermissions(s, membershipId))

  const updateAssociations = (newAssociations: string[]) => {
    const associationsToAdd = newAssociations.filter((a) => !permission.associations.includes(a))
    const associationsToRemove = permission.associations.filter((a) => !newAssociations.includes(a))
    return membershipActions.updateAssociations(membershipId, { associationsToAdd, associationsToRemove })
  }

  const updateAbilitiesInResources = (newAbilitiesInResources: AbilitiesInResources) => {
    const prevAbilitiesInResources = permission.abilitiesInResources
    const addedAbilityInResource = getAddedAbilityInResource(prevAbilitiesInResources, newAbilitiesInResources)
    const removedAbilityInResource = getRemovedAbilityInResource(prevAbilitiesInResources, newAbilitiesInResources)

    if (addedAbilityInResource) {
      membershipActions.updateAbilitiesInResources(membershipId, {
        resourceId: addedAbilityInResource.resourceId,
        abilitiesToAdd: addedAbilityInResource.abilities,
      })
    }

    if (removedAbilityInResource) {
      membershipActions.updateAbilitiesInResources(membershipId, {
        resourceId: removedAbilityInResource.resourceId,
        abilitiesToRemove: removedAbilityInResource.abilities,
      })
    }
  }

  const updateAbilities = async ({ abilitiesToAdd = [], abilitiesToRemove = [], orgId }: UpdateAbilitiesParams) => {
    const result = await dispatch(UpdateMembershipAbilities({ membershipId, abilitiesToAdd, abilitiesToRemove, orgId }))
    return unwrapResult(result)
  }

  const copyPermissions = (copyPermissionsFrom: string) => {
    return membershipActions.copyPermissions(membershipId, copyPermissionsFrom)
  }

  return {
    permission,
    updateAssociations,
    updateAbilitiesInResources,
    updateAbilities,
    copyPermissions,
  }
}

function getAddedAbilityInResource(prevState: AbilitiesInResources, nextState: AbilitiesInResources) {
  return nextState.find((next) => !prevState.some((prev) => prev.resourceId === next.resourceId))
}

function getRemovedAbilityInResource(prevState: AbilitiesInResources, nextState: AbilitiesInResources) {
  return prevState.find((prev) => !nextState.some((next) => next.resourceId === prev.resourceId))
}

type AbilitiesInResources = Array<{ resourceId: string; abilities: string[] }>
type UpdateAbilitiesParams = { abilitiesToAdd?: string[]; abilitiesToRemove?: string[]; orgId: string }
