import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import DaysSelect from "./DaysSelect"

import { useI18n } from "../hooks"
import { StringMap } from "../types/common"

const WeekendDaysSelectWidget = ({
  weekendDays = [0, 6],
  onWeekendDaysChange = () => {},
  disabled = false,
}: WeekendDaysSelectWidgetProps) => {
  const translations = useTranslations()
  const onChange = (weekendDays: DayOfWeek[]) => {
    onWeekendDaysChange({ weekendDays })
  }

  return (
    <Paper elevation={0}>
      <Box p={4}>
        <Grid container direction="column">
          <Typography variant="subtitle1">{translations.weekendDaysSelectTitle}</Typography>
          <Grid item id="weekend-days-select">
            <DaysSelect value={weekendDays} onChange={onChange} disabled={disabled} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n("translation")
  const translations = (t.weekendDaysSelectWidget || {}) as StringMap

  const { weekendDaysSelectTitle = defaults.weekendDaysSelectTitle } = translations

  return { weekendDaysSelectTitle }
}

const defaultTranslations = {
  weekendDaysSelectTitle: "Weekend days",
}

type WeekendDaysSelectWidgetProps = {
  weekendDays?: DayOfWeek[]
  onWeekendDaysChange?: ({ weekendDays }: { weekendDays: DayOfWeek[] }) => void
  disabled?: boolean
}

export default WeekendDaysSelectWidget
