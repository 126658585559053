import * as actions from "./actions"
import { createSlice } from "@reduxjs/toolkit"
import { RoleNormalized } from "../../organisations/interfaces/role-normalized"
import { setRoleCase, setRolesCase } from "./case-reducers"

const initialState: RolesState = {
  byIds: {},
  allIds: [],
}

const rolesSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    SetRole: setRoleCase,
    SetRoles: setRolesCase,
  },
  extraReducers: (builder) => {
    builder.addCase(actions.FetchRoles.fulfilled, setRolesCase)
    builder.addCase(actions.UpdateRoleAbilities.fulfilled, setRoleCase)
  },
})

export type RolesState = {
  byIds: {
    [id: string]: RoleNormalized
  }
  allIds: string[]
}

export default rolesSlice.reducer
