import { chain } from "lodash"

export const adaptPermissions = (permissions: AdaptablePermission[]) => {
  const adaptedPermissions: AdaptedPermissions = []
  if (!permissions) return adaptedPermissions
  return chain(permissions)
    .flatMap((p) => p.abilitiesByResources)
    .reduce((adapted, permission) => {
      const existing = adapted.find((p) => p.resource === permission.resource)
      if (existing) {
        existing.abilities.push(permission.abilities)
      } else {
        adapted.push({
          resource: permission.resource,
          abilities: [permission.abilities],
        })
      }
      return adapted
    }, adaptedPermissions)
    .value()
}

type AdaptedPermissions = Array<{
  resource: string
  abilities: { [ability: string]: boolean }[]
}>

type AdaptablePermission = {
  abilitiesByResources: {
    resource: string
    abilities: { [ability: string]: boolean }
  }[]
}
