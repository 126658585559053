import { RootNode, TaskTreeGridRow, TreeGridTask } from "../types"
import { sortBy } from "lodash"
import { makeTreeGridTask } from "./tree-grid-task"
import { TimeZoneType } from "../../constants/timezones"
import { TaskViewModel } from "../../tasks/api/task"
import { TreeGridTranslations } from "../hooks/use-translations"

type MakeTaskTreeParams = {
  rootNode: RootNode
  tasks: TaskViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
  translations: TreeGridTranslations
}
export function makeTaskTreegridRows({ rootNode, tasks, dateFormat, timeZone, translations }: MakeTaskTreeParams) {
  const rowsById = new Map<string, TaskTreeGridRow>()
  const rootType = rootNode.isProjectNode ? "project" : "task"
  const treegridTasks = tasks.map((task) => makeTreeGridTask({ task, dateFormat, timeZone, translations }))
  // Start from the direct children of the root node
  const sortedRootChildren = getSortedRootChildren(rootNode.id, rootType, treegridTasks)
  // Make rows for the root children
  const rows = sortedRootChildren.map(makeTaskRow)
  return rows

  function makeTaskRow(task: TreeGridTask) {
    const sortedChildren = getSortedRootChildren(task.id, "task", treegridTasks)
    const isBranch = Boolean(sortedChildren.length)
    const isLeaf = !isBranch
    let GanttGanttClass = task.GanttGanttClass
    const ganttBarColor = task.ganttBarColor
    if (task.GanttGanttClass === "Default") {
      GanttGanttClass = isBranch ? "Aqua" : "Blue"
    }
    const row: TaskTreeGridRow = {
      ...task,
      isBranch,
      isLeaf,
      Def: isBranch ? "Branch" : "Leaf",
      Items: sortedChildren.map(makeTaskRow),
      GanttGanttClass,
      ganttBarColor,
    }
    rowsById.set(row.id, row)
    return row
  }
}

function getSortedRootChildren(rootId: string, rootType: "task" | "project", tasks: TreeGridTask[]) {
  const rootChildren = tasks.filter((task) => {
    if (rootType === "task") return task.parentTaskId === rootId
    return task.projectId === rootId && !task.parentTaskId
  })
  return sortBy(rootChildren, "order")
}
