import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { createStyles, makeStyles, Theme } from "@material-ui/core"
import { Link } from "react-router-dom"
import { paths } from "../paths"

const useNotFoundStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      padding: 160,
      textAlign: "center",
    },
    svg: {
      height: 160,
    },
  })
)

const NotFound = () => {
  const classes = useNotFoundStyles()
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      spacing={4}
      className={classes.container}
    >
      <Grid item>
        <div>
          <img className={classes.svg} src="/404.svg" alt="404" />
        </div>
      </Grid>
      <Grid item>
        <Typography variant="h3" component="h3">
          Oops, Are you lost?
        </Typography>
        <br />
        <Typography variant="body2" component="p" color="textSecondary">
          Nothing exists here. You can go back home by clicking button below.
        </Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" color="primary" component={Link} to={paths.login()}>
          Go back to home
        </Button>
      </Grid>
    </Grid>
  )
}

export default NotFound
