import ReminderList from "../../reminders/components/ReminderList"
import { Typography, Paper, CircularProgress } from "@material-ui/core"
import { BaseNewReminderData, useGetRemindersQuery } from "../../reminders/api"
import { useReminderMutations } from "../../reminders/hooks/use-reminder-mutations"
import { useGetProjectByIdQuery } from "../api"
import { ReminderViewModel } from "../../reminders/api/reminder"
import { guessReminderTime } from "../../reminders/utils/guess-reminder-time"
import { isDate } from "lodash"
import { useTranslation } from "react-i18next"
import { Status } from "../../types/common"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectReminders = ({ projectId }: { projectId: string }) => {
  const translations = useTranslations()
  const { data: reminders = [], isLoading: isLoadingReminders } = useGetRemindersQuery(
    { project: projectId },
    queryOptions
  )
  const { data: project, isLoading: isLoadingProject } = useGetProjectByIdQuery(projectId)
  const { createReminder } = useReminderMutations()

  const isLoading = isLoadingReminders || isLoadingProject
  const refStartDate = project?.plannedStartDate ? new Date(project.plannedStartDate) : null
  const refEndDate = project?.plannedEndDate ? new Date(project.plannedEndDate) : null
  const projectStatusOptions = project?.statusOptions || []
  const reminderTriggerStatusOptions = projectStatusOptions.filter((option) => option.value !== Status.REJECTED)

  const createNewReminder = async () => {
    const guessedReminderTime = guessReminderTime(refStartDate, refEndDate)
    const reminderData: Omit<BaseNewReminderData, "triggerType"> = {
      entityId: projectId,
      entityType: "project",
      recipients: {
        roles: ["projectManager"],
        emails: [],
      },
    }

    const reminderPromise = isDate(guessedReminderTime)
      ? createReminder({
          ...reminderData,
          reminderTime: guessedReminderTime.toISOString(),
          reminderTimeDetails: undefined,
          triggerType: "absoluteTime",
        })
      : createReminder({
          ...reminderData,
          reminderTime: undefined,
          reminderTimeDetails: guessedReminderTime,
          triggerType: "relativeTime",
        })

    return (await reminderPromise) as ReminderViewModel
  }

  return (
    <Paper style={{ padding: 20 }} elevation={0}>
      <Typography variant="h5" gutterBottom>
        {translations.projectCustomNotifications}
      </Typography>
      {isLoading && <CircularProgress />}
      {reminders && project && (
        <ReminderList
          reminders={reminders}
          refStartDate={refStartDate}
          refEndDate={refEndDate}
          onCreateReminder={createNewReminder}
          canCreateReminder={project.canManageProjectNotifications}
          statusOptions={reminderTriggerStatusOptions}
        />
      )}
    </Paper>
  )
}

const useTranslations = () => {
  const { t } = useTranslation()

  return {
    projectCustomNotifications: t("projectCustomNotifications", "Project custom notifications"),
  }
}

export default ProjectReminders
