import { api } from "../../api"
import { CalendarEventResponse } from "./calendar-event-response"
import { CalendarEventViewModel, makeCalendarEventViewModel } from "./calendar-event"
import { makeCalendarEventApiEndpoints } from "./endpoints"
import { TreeGridColors } from "../../treegrid/types"

const apiEndpoints = makeCalendarEventApiEndpoints()

export const calendarEventApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getCalendarEvents: builder.query<CalendarEventViewModel[], EventsQueryParams | void>({
      query: (params) => ({
        method: "GET",
        url: apiEndpoints.events(),
        params: params ? new URLSearchParams(params) : undefined,
      }),
      transformResponse: (response: { calendarEvents: CalendarEventResponse[] }) => {
        return response.calendarEvents.map(makeCalendarEventViewModel)
      },
      providesTags: (result = []) => [
        "CalendarEvent",
        ...result.map(({ id }) => ({ type: "CalendarEvent", id } as const)),
      ],
    }),
    createCalendarEvent: builder.mutation<CalendarEventViewModel, NewCalendarEventData>({
      query: (eventData) => ({
        method: "POST",
        url: apiEndpoints.events(),
        body: eventData,
      }),
      transformResponse: makeCalendarEventViewModel,
      invalidatesTags: ["CalendarEvent"],
    }),
    updateCalendarEventInfo: builder.mutation<
      CalendarEventViewModel,
      { eventId: string } & CalendarEventInfoUpdateData
    >({
      query: ({ eventId, ...updateData }) => ({
        method: "PUT",
        url: apiEndpoints.eventItem(eventId),
        body: updateData,
      }),
      transformResponse: makeCalendarEventViewModel,
      invalidatesTags: (result) => ["CalendarEvent", { type: "CalendarEvent", id: result?.id }],
    }),
    updateCalendarEventProject: builder.mutation<CalendarEventViewModel, { eventId: string; project: string | null }>({
      query: ({ eventId, project }) => ({
        method: "PATCH",
        url: apiEndpoints.projectRelation(eventId),
        body: { project },
      }),
      transformResponse: makeCalendarEventViewModel,
      invalidatesTags: (result) => ["CalendarEvent", { type: "CalendarEvent", id: result?.id }],
    }),
    updateCalendarEventTask: builder.mutation<CalendarEventViewModel, { eventId: string; task: string | null }>({
      query: ({ eventId, task }) => ({
        method: "PATCH",
        url: apiEndpoints.taskRelation(eventId),
        body: { task },
      }),
      transformResponse: makeCalendarEventViewModel,
      invalidatesTags: (result) => ["CalendarEvent", { type: "CalendarEvent", id: result?.id }],
    }),
    updateCalendarEventCustomers: builder.mutation<CalendarEventViewModel, { eventId: string; customers: string[] }>({
      query: ({ eventId, customers }) => ({
        method: "PATCH",
        url: apiEndpoints.customerRelations(eventId),
        body: { customers },
      }),
      transformResponse: makeCalendarEventViewModel,
      invalidatesTags: (result) => ["CalendarEvent", { type: "CalendarEvent", id: result?.id }],
    }),
    updateCalendarEventParticipants: builder.mutation<
      CalendarEventViewModel,
      { eventId: string; participants: string[] }
    >({
      query: ({ eventId, participants }) => ({
        method: "PATCH",
        url: apiEndpoints.participantRelations(eventId),
        body: { participants },
      }),
      transformResponse: makeCalendarEventViewModel,
      invalidatesTags: (result) => ["CalendarEvent", { type: "CalendarEvent", id: result?.id }],
    }),
    updateCalendarEventSuppliers: builder.mutation<CalendarEventViewModel, { eventId: string; suppliers: string[] }>({
      query: ({ eventId, suppliers }) => ({
        method: "PATCH",
        url: apiEndpoints.supplierRelations(eventId),
        body: { suppliers },
      }),
      transformResponse: makeCalendarEventViewModel,
      invalidatesTags: (result) => ["CalendarEvent", { type: "CalendarEvent", id: result?.id }],
    }),
    updateCalendarEventWorkspaces: builder.mutation<CalendarEventViewModel, { eventId: string; workspaces: string[] }>({
      query: ({ eventId, workspaces }) => ({
        method: "PATCH",
        url: apiEndpoints.workspaceRelations(eventId),
        body: { workspaces },
      }),
      transformResponse: makeCalendarEventViewModel,
      invalidatesTags: (result) => ["CalendarEvent", { type: "CalendarEvent", id: result?.id }],
    }),
    deleteEvent: builder.mutation<void, string>({
      query: (eventId) => ({
        method: "DELETE",
        url: apiEndpoints.eventItem(eventId),
      }),
      invalidatesTags: ["CalendarEvent"],
    }),
  }),
})

export const {
  useGetCalendarEventsQuery,
  useCreateCalendarEventMutation,
  useUpdateCalendarEventInfoMutation,
  useUpdateCalendarEventProjectMutation,
  useUpdateCalendarEventTaskMutation,
  useUpdateCalendarEventCustomersMutation,
  useUpdateCalendarEventParticipantsMutation,
  useUpdateCalendarEventSuppliersMutation,
  useUpdateCalendarEventWorkspacesMutation,
  useDeleteEventMutation,
} = calendarEventApi

type OrgEventsQueryParams = { organisation: string }
type CustomerEventsQueryParams = { customer: string }
type MemberEventsQueryParams = { participant: string }
type ProjectEventsQueryParams = { project: string }
type SupplierEventsQueryParams = { supplier: string }
type TaskEventsQueryParams = { task: string }
type WorkspaceEventsQueryParams = { workspace: string }

export type EventsQueryParams =
  | Record<string, never> // for {}
  | OrgEventsQueryParams
  | CustomerEventsQueryParams
  | MemberEventsQueryParams
  | ProjectEventsQueryParams
  | SupplierEventsQueryParams
  | TaskEventsQueryParams
  | WorkspaceEventsQueryParams

export type NewCalendarEventData = {
  title: string
  startDate: string
  endDate: string
  description?: string | null
  project?: string | null
  task?: string | null
  organisation?: string | null
  participants?: string[]
  customers?: string[]
  suppliers?: string[]
  workspaces?: string[]
  recurranceType?: string | null
  recurranceEndDate?: string | null
  eventLength?: number // should only be used to send the event length timestamp to the server for recurring instances
  parentEventId?: string | null
}

export type CalendarEventInfoUpdateData = {
  title?: string
  description?: string | null
  startDate?: string
  endDate?: string
  color?: TreeGridColors | null
  recurranceType?: string | null
  recurranceEndDate?: string | null
}
