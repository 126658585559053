import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import CardActions from "@material-ui/core/CardActions"
import Button from "@material-ui/core/Button"
import { HolidayViewModel } from "../api/holiday"
import { useHolidayMutations } from "../hooks/use-holiday-mutations"
import HolidayForm from "./HolidayForm"
import { mapHolidayViewModelToFormData } from "../utils/HolidayFormUtils"
import { useTheme } from "@material-ui/core"
import { useTranslations } from "../hooks/use-translations"

const HolidayCard = ({ holiday, canUpdate, canDelete }: HolidayCardProps) => {
  const theme = useTheme()
  const translations = useTranslations()
  const { updateHoliday, deleteHoliday } = useHolidayMutations()

  const onSaveName = (name: string) => updateHoliday(holiday.id, { name })
  const onSaveRecurring = (recurring: boolean) => updateHoliday(holiday.id, { recurring })
  const onSaveEndDate = (date: Date | null) => updateHoliday(holiday.id, { endDate: date })
  const onSaveStartDate = (date: Date) => {
    const update = holiday.isSingleHoliday ? { date } : { startDate: date }
    updateHoliday(holiday.id, update)
  }

  const deleteHolidayLabel = `${translations.deleteLabel} ${translations.holiday.toLocaleLowerCase()}`

  return (
    <Card variant="outlined">
      <CardContent>
        <HolidayForm
          holiday={mapHolidayViewModelToFormData(holiday)}
          onSaveName={onSaveName}
          onSaveEndDate={onSaveEndDate}
          onSaveStartDate={onSaveStartDate}
          onSaveRecurring={onSaveRecurring}
          isReadOnly={!canUpdate}
        />
      </CardContent>
      <CardActions>
        <Button
          style={{ color: theme.palette.error.main }}
          onClick={() => deleteHoliday(holiday.id)}
          disabled={!canDelete}
        >
          {deleteHolidayLabel}
        </Button>
      </CardActions>
    </Card>
  )
}

type HolidayCardProps = {
  holiday: HolidayViewModel
  canUpdate: boolean
  canDelete: boolean
}

export default HolidayCard
