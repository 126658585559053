import React from "react"
import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import ButtonGroup from "@material-ui/core/ButtonGroup"
import Button from "@material-ui/core/Button"
import Chip from "@material-ui/core/Chip"
import Menu from "@material-ui/core/Menu"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import Progress from "./Progress"
import { alpha, InputAdornment, List, ListItem, ListSubheader, makeStyles, TextField, Theme } from "@material-ui/core"
import { ChevronDown, Plus, Search, X } from "react-feather"
import { filter, truncate } from "lodash"
import { useAuthUserMembership } from "../memberships/hooks/use-auth-user-membership"
import { useI18n } from "../hooks"
import { StringMap } from "../types/common"
import { useAppContext } from "../hooks/use-app-context"
import { paths } from "../paths"
import { AppSubContextType } from "../types/app-context"
import { Link } from "react-router-dom"
import { useUrlWithContext } from "../hooks/use-url-with-context"

const AppSubContext = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const { canCreateMemberships, canCreateWorkspaces, canCreateCustomers, canCreateSuppliers } = useAuthUserMembership()
  const { appContext, onChangeSubContext } = useAppContext()
  const { createPathWithContext } = useUrlWithContext()
  const { mainContext, subContext, subContextList } = appContext
  const [search, setSearch] = React.useState("")
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLButtonElement | null>(null)
  const menuAnchorRef = React.useRef<HTMLButtonElement | null>(null)
  const toggleSubContextMenu = () => {
    setMenuAnchor(menuAnchor ? null : menuAnchorRef.current)
  }
  const closeMenu = () => setMenuAnchor(null)
  const matchedContexts = filter(subContextList, (ctx) => {
    return ctx.name.toLowerCase().includes(search.toLowerCase())
  })
  const activeSubContextList = subContextList.filter((item) => !item.disabled)
  const accordion = activeSubContextList.length > 12
  const customerList = filter(activeSubContextList, { type: "customer" })
  const memberList = filter(activeSubContextList, { type: "member" })
  const supplierList = filter(activeSubContextList, { type: "supplier" })
  const workspaceList = filter(activeSubContextList, { type: "workspace" })

  const onClickSubContext = (subContext: AppSubContextType): void => {
    onChangeSubContext(subContext)
    closeMenu()
  }

  const onClickSubContextButton = () => {
    if (subContext) onChangeSubContext(null)
    else toggleSubContextMenu()
  }

  const createCustomerHref = canCreateCustomers
    ? createPathWithContext(paths.orgNewCustomer(), { withSubContext: false })
    : undefined
  const customerListProps = {
    title: translations.customersTitle,
    list: customerList,
    onClickSubContext,
    addButtonLabel: canCreateCustomers ? translations.addCustomerBtnLabel : undefined,
    addButtonHref: createCustomerHref,
  }

  const createMembershipHref = canCreateMemberships
    ? createPathWithContext(paths.orgNewMember(), { withSubContext: false })
    : undefined
  const memberListProps = {
    title: translations.membersTitle,
    list: memberList,
    onClickSubContext,
    addButtonLabel: canCreateMemberships ? translations.addMemberBtnLabel : undefined,
    addButtonHref: createMembershipHref,
  }

  const createSupplierHref = canCreateSuppliers
    ? createPathWithContext(paths.orgNewSupplier(), { withSubContext: false })
    : undefined
  const supplierListProps = {
    title: translations.suppliersTitle,
    list: supplierList,
    onClickSubContext,
    addButtonLabel: canCreateSuppliers ? translations.addSupplierBtnLabel : undefined,
    addButtonHref: createSupplierHref,
  }

  const createWorkspaceHref = canCreateWorkspaces
    ? createPathWithContext(paths.orgNewWorkspace(), { withSubContext: false })
    : undefined
  const workspaceListProps = {
    title: translations.workspacesTitle,
    list: workspaceList,
    onClickSubContext,
    addButtonLabel: canCreateWorkspaces ? translations.addWorkspaceBtnLabel : undefined,
    addButtonHref: createWorkspaceHref,
  }

  return (
    <div className={classes.wrapper}>
      {mainContext?.type === "org" && (
        <Tooltip title={subContext?.name && subContext.name.length > 20 ? subContext.name : ""}>
          <ButtonGroup className={classes.buttonGroup} variant="text">
            <Button
              ref={menuAnchorRef}
              onClick={onClickSubContextButton}
              endIcon={subContext ? <X size={14} /> : <ChevronDown size={16} />}
              className={subContext?.disabled ? classes.disabledSubcontext : ""}
              data-test="selected-sub-context"
            >
              {subContext
                ? truncate(subContext.name, {
                    length: 20,
                  })
                : translations.selectSubContextBtnLabel}
            </Button>
            {subContext && (
              <Button onClick={toggleSubContextMenu} className={subContext?.disabled ? classes.disabledSubcontext : ""}>
                <ChevronDown size={16} />
              </Button>
            )}
          </ButtonGroup>
        </Tooltip>
      )}
      <Menu aria-label="Sub context menu" open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={closeMenu}>
        {!activeSubContextList.length && (
          <div className={classes.spinnerWrapper}>
            <Progress label="" />
          </div>
        )}
        {activeSubContextList.length !== 0 && (
          <div className={classes.panel}>
            {subContext && (
              <div style={{ padding: "0 12px" }}>
                <Button
                  size="small"
                  onClick={() => onChangeSubContext(null)}
                  endIcon={<X size={16} />}
                  variant="outlined"
                  className={subContext?.disabled ? classes.disabledSubcontext : ""}
                >
                  {subContext.name}
                </Button>
              </div>
            )}
            <SubContextSearchForm search={search} setSearch={setSearch} />
            {search && (
              <SearchResult search={search} matchedContexts={matchedContexts} onClickSubContext={onClickSubContext} />
            )}

            {accordion && (
              <>
                <WithAccordion title={memberListProps.title} chipLabel={memberList.length}>
                  <SubContextList {...memberListProps} showTitle={false} />
                </WithAccordion>
                <WithAccordion title={workspaceListProps.title} chipLabel={workspaceList.length}>
                  <SubContextList {...workspaceListProps} showTitle={false} />
                </WithAccordion>
                <WithAccordion title={customerListProps.title} chipLabel={customerList.length}>
                  <SubContextList {...customerListProps} showTitle={false} />
                </WithAccordion>
                <WithAccordion title={supplierListProps.title} chipLabel={supplierList.length}>
                  <SubContextList {...supplierListProps} showTitle={false} />
                </WithAccordion>
              </>
            )}

            {!accordion && (
              <div style={{ padding: "0 12px" }}>
                <SubContextList {...memberListProps} />
                <SubContextList {...workspaceListProps} />
                <SubContextList {...customerListProps} />
                <SubContextList {...supplierListProps} />
              </div>
            )}
          </div>
        )}
      </Menu>
    </div>
  )
}
const WithAccordion = ({ title, chipLabel, children }: React.PropsWithChildren<AccordionProps>) => {
  const classes = useStyles()

  return (
    <Accordion elevation={0} classes={{ root: classes.accordion }}>
      <AccordionSummary expandIcon={<ChevronDown height={24} width={24} />}>
        <Typography variant="overline" color="textSecondary">
          <strong>{title}</strong>
          <Chip color="default" label={chipLabel} size="small" className={classes.chip} />
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.fullWidth}>{children}</div>
      </AccordionDetails>
    </Accordion>
  )
}
const SearchResult = ({ search, matchedContexts, onClickSubContext }: SearchResultProps) => {
  const classes = useSearchResultStyles()
  const translations = useTranslations()

  const matchedCount = matchedContexts.length
  const hasMatch = Boolean(matchedContexts.length)
  const showing = translations.searchResultMessageFrontText
  const resultsFor = translations.searchResultMessageEndText
  const noMatchFoundMessage = translations.searchResultNotFoundMessage

  return (
    <div className={classes.root}>
      <div className={classes.meta}>
        {!hasMatch && (
          <Typography variant="body2" color="textSecondary">
            {`${noMatchFoundMessage}:`}
            <Typography variant="subtitle1" color="textPrimary" component="span" style={{ margin: "0 8px" }}>
              {search}
            </Typography>
          </Typography>
        )}
        {hasMatch && (
          <Typography variant="body1" color="textPrimary">
            {showing}
            <Chip color="primary" label={matchedCount} size="small" className={classes.chip} />
            {resultsFor}
            <Typography variant="subtitle1" component="span" style={{ margin: "0 8px" }}>
              {search}
            </Typography>
          </Typography>
        )}
      </div>
      <div>
        <SubContextList list={matchedContexts} onClickSubContext={onClickSubContext} isSearchResult />
      </div>
    </div>
  )
}
const SubContextList = ({
  list,
  onClickSubContext,
  addButtonLabel,
  addButtonHref,
  title,
  showTitle = true,
  isSearchResult = false,
}: SubContextListProps) => {
  const classes = useSubContextListStyles()
  return (
    <List component="ul">
      {showTitle && title && <ListSubheader className={classes.title}>{title}</ListSubheader>}
      {addButtonLabel && addButtonHref && (
        <Button
          to={addButtonHref}
          variant="outlined"
          component={Link}
          endIcon={<Plus height={16} />}
          className={classes.addButton}
          size="small"
          fullWidth
        >
          {addButtonLabel}
        </Button>
      )}
      {list.map((subContext, index) => {
        const { name, disabled = false } = subContext
        return (
          <ListItem
            divider={!(index === list.length - 1)}
            disabled={disabled}
            onClick={() => onClickSubContext(subContext)}
            button
            key={subContext.id}
            data-test={`sub-context-menu-item-${name}`}
          >
            <Typography variant="subtitle1" component="span">
              {name}
            </Typography>
          </ListItem>
        )
      })}
    </List>
  )
}
const SubContextSearchForm = ({ search, setSearch }: SubContextSearchFormProps) => {
  const textFielsClasses = useTextFieldStyles()
  const inputClasses = useInputStyles()
  const translations = useTranslations()

  return (
    <form onSubmit={(e) => e.preventDefault()}>
      <TextField
        name="search"
        id="search"
        classes={textFielsClasses}
        placeholder={translations.searchSubContextPlaceholder}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        InputProps={{
          classes: inputClasses,
          startAdornment: (
            <InputAdornment position="start">
              <Search height={16} />
            </InputAdornment>
          ),
        }}
        autoFocus
      />
    </form>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    maxWidth: 240,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  disabledSubcontext: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
  listItem: {
    "& .MuiListItemIcon-root": {
      minWidth: theme.spacing(5),
    },
    "&:last-child": {
      borderBottom: 0,
    },
    "& .MuiListItemText-primary.selected": {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  panel: {
    padding: 0,
    width: 360,
    maxHeight: 360,
    overflow: "auto",
  },
  text: {
    letterSpacing: 0.1,
    whiteSpace: "nowrap",
    fontWeight: 600,
    fontSize: theme.typography.overline.fontSize,
  },
  accordion: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  buttonGroup: {
    "& button": {
      paddingTop: 2,
      paddingBottom: 2,
      "&:first-child": {
        borderRightColor: alpha(theme.palette.common.white, 0.1),
        paddingRight: theme.spacing(2),
      },
    },
  },
  chip: { marginLeft: 16 },
  fullWidth: { width: "100%" },
  menuPaper: { padding: 0 },
  spinnerWrapper: { padding: theme.spacing(2, 15) },
}))
const useSearchResultStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  meta: {
    padding: theme.spacing(1.5, 0),
  },
  chip: { marginLeft: 12, marginRight: 12 },
}))
const useSubContextListStyles = makeStyles((theme: Theme) => ({
  title: {
    padding: 0,
    letterSpacing: theme.typography.overline.letterSpacing,
    fontWeight: 600,
    textTransform: "uppercase",
    background: theme.palette.common.white,
  },
  addButton: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
    transition: theme.transitions.create(["color", "background"]),
    "&:hover": {
      color: theme.palette.common.white,
      background: theme.palette.primary.main,
    },
  },
}))
const useTextFieldStyles = makeStyles((theme: Theme) => ({
  root: {
    width: "100%",
  },
}))
const useInputStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(1.5),
    "& input": {
      "&::placeholder": {
        fontSize: theme.typography.body2.fontSize,
      },
    },
  },
  focused: {
    "&:before": {
      borderTop: 0,
      borderLeft: 0,
      borderRight: 0,
    },
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")
  const subContextTranslations = (translations?.appContext || {}) as StringMap

  const {
    searchSubContextPlaceholder = defaults.searchSubContextPlaceholder,
    searchResultMessageFrontText = defaults.searchResultMessageFrontText,
    searchResultMessageEndText = defaults.searchResultMessageEndText,
    searchResultNotFoundMessage = defaults.searchResultNotFoundMessage,
  } = translations

  const {
    customersTitle = defaults.customersTitle,
    addCustomerBtnLabel = defaults.addCustomerBtnLabel,
    membersTitle = defaults.membersTitle,
    addMemberBtnLabel = defaults.addMemberBtnLabel,
    suppliersTitle = defaults.suppliersTitle,
    addSupplierBtnLabel = defaults.addSupplierBtnLabel,
    workspacesTitle = defaults.workspacesTitle,
    addWorkspaceBtnLabel = defaults.addWorkspaceBtnLabel,
    selectSubContextBtnLabel = defaults.selectSubContextBtnLabel,
  } = subContextTranslations

  return {
    customersTitle,
    addCustomerBtnLabel,
    membersTitle,
    addMemberBtnLabel,
    suppliersTitle,
    addSupplierBtnLabel,
    workspacesTitle,
    addWorkspaceBtnLabel,
    selectSubContextBtnLabel,
    searchSubContextPlaceholder,
    searchResultMessageEndText,
    searchResultMessageFrontText,
    searchResultNotFoundMessage,
  }
}
const defaultTranslations = {
  customersTitle: "Customers",
  addCustomerBtnLabel: "Add customer",
  membersTitle: "Members",
  addMemberBtnLabel: "Add member",
  suppliersTitle: "Suppliers",
  addSupplierBtnLabel: "Add supplier",
  workspacesTitle: "Workspaces",
  addWorkspaceBtnLabel: "Add workspace",
  selectSubContextBtnLabel: "Select sub context",
  searchSubContextPlaceholder: "Search for member, workspace or customer",
  searchResultMessageFrontText: "Showing",
  searchResultMessageEndText: "results for",
  searchResultNotFoundMessage: "No match was found for",
}

type Translations = typeof defaultTranslations
type AccordionProps = {
  title: string
  chipLabel: string | number
}
type SearchResultProps = {
  search: string
  matchedContexts: AppSubContextType[]
  onClickSubContext: (subContext: AppSubContextType) => void
}
type SubContextListProps = {
  list: AppSubContextType[]
  onClickSubContext: (subContext: AppSubContextType) => void
  showTitle?: boolean
  title?: string
  addButtonLabel?: string
  addButtonHref?: string
  isSearchResult?: boolean
}
type SubContextSearchFormProps = {
  search: string
  setSearch: (s: string) => void
}

export default AppSubContext
