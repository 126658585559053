import { PayloadAction } from "@reduxjs/toolkit"
import { IUserResponse } from "../interfaces/user-response"
import { AuthState } from "./index"
import { normalizeUser } from "../interfaces/user-normalized"

export const setAuthStateCase = (state: AuthState, action: PayloadAction<AuthState>) => {
  state.isAuthenticated = action.payload.isAuthenticated || state.isAuthenticated
  state.refreshToken = action.payload.refreshToken || state.refreshToken
  state.user = action.payload.user || state.user
  state.accessToken = action.payload.accessToken || state.accessToken
  state.tokenExpiresAt = action.payload.tokenExpiresAt || state.tokenExpiresAt
  state.authError = action.payload.authError || state.authError
  state.tokenRefreshStatus = action.payload.tokenRefreshStatus || state.tokenRefreshStatus
}

export const setRefreshTokenCase = (
  state: AuthState,
  action: PayloadAction<{
    refreshToken: string | null
    isAuthenticated: boolean
  }>
) => {
  state.isAuthenticated = action.payload.isAuthenticated
  state.refreshToken = action.payload.refreshToken
}

export const setUserCase = (state: AuthState, action: PayloadAction<IUserResponse>) => {
  state.user = normalizeUser(action.payload)
}

export const resetAuthStateCase = (state: AuthState) => {
  state.isAuthenticated = false
  state.refreshToken = null
  state.user = null
  state.accessToken = null
  state.tokenExpiresAt = null
  state.authError = null
  state.tokenRefreshStatus = "idle"
}

export const refreshingAccessTokenCase = (state: AuthState) => {
  state.tokenRefreshStatus = "pending"
  state.authError = null
}

export const accessTokenRefreshedCase = (state: AuthState, action: PayloadAction<AccessTokenData>) => {
  state.tokenRefreshStatus = action.payload.accessToken ? "fulfilled" : "rejected"
  state.accessToken = action.payload.accessToken
  state.tokenExpiresAt = action.payload.tokenExpiresAt
  state.authError = null
}

export const accessTokenRefreshFailedCase = (state: AuthState) => {
  state.tokenRefreshStatus = "rejected"
  state.authError = "Unauthorized"
}

type AccessTokenData = {
  accessToken: string
  tokenExpiresAt: number
}
