import type { IWorkspaceAPI } from "../interfaces/workspace-api"
import * as WorkspaceAPI from "../workspace-api"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { SetContextOption } from "../../context-options/store"
import { makeContextOptionFromWorkspace } from "../../context-options/utils"

const makeCreateWorkspace = (workspaceAPI: IWorkspaceAPI) => {
  return createAsyncThunk(
    "workspaces/createWorkspace",
    async ({ orgId, ...workspaceData }: CreateWorkspaceThunkArg, { dispatch }) => {
      const workspace = await workspaceAPI.createWorkspace(orgId, workspaceData)
      dispatch(SetContextOption(makeContextOptionFromWorkspace(workspace)))
      return workspace
    }
  )
}

const makeFetchWorkspaceById = (workspaceAPI: IWorkspaceAPI) => {
  return createAsyncThunk("workspaces/fetchWorkspaceById", async (workspaceId: string) => {
    return await workspaceAPI.fetchWorkspaceById(workspaceId)
  })
}

const makeFetchWorkspaces = (workspaceAPI: IWorkspaceAPI) => {
  return createAsyncThunk("workspaces/fetchWorkspaces", async (orgId: string) => {
    return await workspaceAPI.fetchWorkspaces(orgId)
  })
}

const makeUpdateInfo = (workspaceAPI: IWorkspaceAPI) => {
  return createAsyncThunk(
    "workspaces/updateInfo",
    async ({ workspaceId, ...updateData }: UpdateWorkspaceInfoThunkArg) => {
      return await workspaceAPI.updateWorkspaceInfo(workspaceId, updateData)
    }
  )
}

const makeAddManager = (workspaceAPI: IWorkspaceAPI) => {
  return createAsyncThunk("workspaces/addManager", async ({ workspaceId, managerId }: AddManagerThunkArg) => {
    return await workspaceAPI.addManager(workspaceId, managerId)
  })
}

const makeAddMember = (workspaceAPI: IWorkspaceAPI) => {
  return createAsyncThunk("workspaces/addMember", async ({ workspaceId, memberId }: AddMemberThunkArg) => {
    return await workspaceAPI.addMember(workspaceId, memberId)
  })
}

const makeRemoveManager = (workspaceAPI: IWorkspaceAPI) => {
  return createAsyncThunk("workspaces/removeManager", async ({ workspaceId, managerId }: RemoveManagerThunkArg) => {
    return await workspaceAPI.removeManager(workspaceId, managerId)
  })
}

const makeRemoveMember = (workspaceAPI: IWorkspaceAPI) => {
  return createAsyncThunk("workspaces/removeMember", async ({ workspaceId, memberId }: RemoveMemberThunkArg) => {
    return await workspaceAPI.removeMember(workspaceId, memberId)
  })
}

const makeUpdateWorkspaceStatus = (workspaceAPI: IWorkspaceAPI) => {
  return createAsyncThunk(
    "workspaces/updateStatus",
    async ({ workspaceId, ...updatedData }: UpdateWorkspaceStatusThunkArg) => {
      const result = await workspaceAPI.updateWorkspaceStatus(workspaceId, updatedData)
      return result
    }
  )
}

export const CreateWorkspace = makeCreateWorkspace(WorkspaceAPI)
export const FetchWorkspaceById = makeFetchWorkspaceById(WorkspaceAPI)
export const FetchWorkspaces = makeFetchWorkspaces(WorkspaceAPI)
export const UpdateWorkspaceInfo = makeUpdateInfo(WorkspaceAPI)
export const AddWorkspaceMember = makeAddMember(WorkspaceAPI)
export const AddWorkspaceManager = makeAddManager(WorkspaceAPI)
export const RemoveWorkspaceMember = makeRemoveMember(WorkspaceAPI)
export const RemoveWorkspaceManager = makeRemoveManager(WorkspaceAPI)
export const UpdateWorkspaceStatus = makeUpdateWorkspaceStatus(WorkspaceAPI)

type CreateWorkspaceThunkArg = {
  orgId: string
} & WorkspaceAPI.CreateWorkspaceData

type UpdateWorkspaceInfoThunkArg = {
  workspaceId: string
} & WorkspaceAPI.WorkspaceInfoUpdateData

type AddManagerThunkArg = {
  workspaceId: string
  managerId: string
}

type RemoveManagerThunkArg = {
  workspaceId: string
  managerId: string
}

type AddMemberThunkArg = {
  workspaceId: string
  memberId: string
}

type RemoveMemberThunkArg = {
  workspaceId: string
  memberId: string
}

type UpdateWorkspaceStatusThunkArg = {
  workspaceId: string
} & WorkspaceAPI.WorkspaceStatusUpdateData
