import React from "react"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import ActivitiesList from "../treegrid/activities-list"
import { Theme, makeStyles } from "@material-ui/core"
import { useUserTimeZone } from "../hooks/use-user-time-zone"
import { useFirstDayOfWeek } from "../hooks/use-first-day-of-week"
import { useDateFormat } from "../users/hooks/use-date-format"
import { useI18n } from "../hooks"
import { useAppContext } from "../hooks/use-app-context"
import {
  getCalendarEventQueryFromContext,
  getProjectQueryFromContext,
  getTaskQueryFromContext,
  getTodoQueryFromContext,
} from "../context-options/utils"
import { useGetCalendarEventsQuery } from "../calendar-events/api"
import { ProjectsQueryParams, useGetProjectsQuery } from "../projects/api"
import { TodoQueryParams, useGetTodosQuery } from "../todos/api"
import { TasksQueryParams, useGetTasksQuery } from "../tasks/api"
import { useAllOptions } from "../options/hooks/use-all-options"
import { getOptionsForMainContext } from "../options/utils"
import { Status, StringMap } from "../types/common"
import { AppMainContextType, AppSubContextType } from "../types/app-context"
import ErrorList from "../components/ErrorList"
import { useWeekendDays } from "../hooks/use-weekend-days"
import { PROJECT_TYPE } from "../projects/constants"
import { useGetHolidaysQuery } from "../holidays/api"
import { useGetOrganisationByIdQuery } from "../organisations/api"
import { ProjectViewModel } from "../projects/api/project"
import { TaskViewModel } from "../tasks/api/task"
import { TodoViewModel } from "../todos/api/todo"
import { CalendarEventViewModel } from "../calendar-events/api/calendar-event"
import { useIsSmallScreen } from "../hooks/use-is-small-screen"
import { sortProjectsByPlan } from "../projects/utils/sort"
import ProjectCard from "../projects/components/ProjectCard"
import FullPath from "../components/FullPath"
import ActivityCard from "../components/ActivityCard"
import { X } from "react-feather"
import TodoDetails from "../todos/components/TodoDetails"
import TaskDetails from "../tasks/components/TaskDetails"
import CalendarEventDetails from "../calendar-events/components/CalendarEventDetails"
import { useCalendarEventMutations } from "../calendar-events/hooks/use-calendar-event-mutations"
import TaskCardTitle from "../tasks/components/TaskCardTitle"
import TodoCardTitle from "../todos/components/TodoCardTitle"
import CalendarEventCardTitle from "../calendar-events/components/CalendarEventCardTitle"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const useLoader = () => {
  const translations = useTranslations()
  const queryErrors = {} as QueryErrors
  const {
    appContext: { mainContext, subContext },
  } = useAppContext()
  const context = subContext || mainContext
  const organisationId = mainContext?.type === "org" ? mainContext.id : ""
  const skipFetchingOrganisation = !organisationId

  const todoQuery = makeTodosQuery(context)
  const taskQuery = makeTasksQuery(context)
  const calendarEventQuery = getCalendarEventQueryFromContext(context)
  const projectQuery = makeProjectsQuery(context)
  const holidaysQuery = makeHolidaysQuery(mainContext)

  const {
    data: calendarEvents = [],
    isLoading: isLoadingCalendarEvents,
    isError: isErrorCalendarEvents,
    status: calendarEventStatus,
  } = useGetCalendarEventsQuery(calendarEventQuery, queryOptions)
  const {
    data: projects = [],
    isLoading: isLoadingProjects,
    isError: isErrorProjects,
    status: projectStatus,
  } = useGetProjectsQuery(projectQuery, queryOptions)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
    status: todoStatus,
  } = useGetTodosQuery(todoQuery, queryOptions)
  const {
    data: tasks = [],
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
    status: taskStatus,
  } = useGetTasksQuery(taskQuery, queryOptions)
  const {
    data: holidays = [],
    isLoading: isLoadingHolidays,
    isError: isErrorHolidays,
  } = useGetHolidaysQuery(holidaysQuery, queryOptions)
  const {
    data: organisation,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation,
  } = useGetOrganisationByIdQuery(organisationId, { ...queryOptions, skip: skipFetchingOrganisation })

  const isLoading =
    isLoadingCalendarEvents ||
    isLoadingProjects ||
    isLoadingTodos ||
    isLoadingTasks ||
    isLoadingHolidays ||
    isLoadingOrganisation
  const isError =
    isErrorCalendarEvents || isErrorProjects || isErrorTodos || isErrorTasks || isErrorHolidays || isErrorOrganisation

  if (isErrorProjects) queryErrors.project = translations.fetchErrorProjects
  if (isErrorTasks) queryErrors.task = translations.fetchErrorTasks
  if (isErrorTodos) queryErrors.todo = translations.fetchErrorTodos
  if (isErrorCalendarEvents) queryErrors.calendarEvent = translations.fetchErrorCalendarEvents
  if (isErrorHolidays) queryErrors.organisation = translations.fetchErrorHolidays
  if (isErrorOrganisation) queryErrors.organisation = translations.fetchErrorOrganisation

  const isFulfilled =
    calendarEventStatus === "fulfilled" &&
    projectStatus === "fulfilled" &&
    todoStatus === "fulfilled" &&
    taskStatus === "fulfilled"

  return {
    isLoading,
    isError,
    isFulfilled,
    context,
    mainContext,
    calendarEvents,
    projects,
    todos,
    tasks,
    holidays,
    organisation,
    queryErrors,
  }
}

const HomeView = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const userTimeZone = useUserTimeZone()
  const { dateFormat, dateSeparator } = useDateFormat()
  const isSmallScreen = useIsSmallScreen()

  const {
    isLoading,
    isError,
    isFulfilled,
    context,
    mainContext,
    calendarEvents,
    projects,
    todos,
    tasks,
    holidays,
    organisation,
    queryErrors,
  } = useLoader()
  const firstDayOfWeek = useFirstDayOfWeek(organisation?.id)
  const weekendDays = useWeekendDays(organisation?.id)
  const { options } = useAllOptions()
  const [key, setKey] = React.useState(context?.id)

  React.useEffect(() => {
    if (isFulfilled) {
      setKey(context?.id)
    }
  }, [isFulfilled, context?.id])

  if (isLoading) return <CircularProgress />
  if (!context || !mainContext) return <ErrorList errors={[translations.contextNotFoundError]} />
  if (isError) return <ErrorList errors={queryErrors} />

  if (isSmallScreen) {
    return <HomeViewMobile projects={projects} tasks={tasks} todos={todos} calendarEvents={calendarEvents} />
  }

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography variant="h6">
          <Box component="span" color="text.secondary">
            {`${context.name} - `}
          </Box>
          <Box component="span"> {translations.pageTitleLabel} </Box>
        </Typography>
      </div>
      <ActivitiesList
        key={key}
        calendarEvents={calendarEvents}
        projects={projects}
        todos={todos}
        tasks={tasks}
        dateFormat={dateFormat}
        dateSeparator={dateSeparator}
        firstDayOfWeek={firstDayOfWeek}
        gridInfo={`${context.name} - ${translations.pageTitleLabel}`}
        timeZone={userTimeZone}
        options={getOptionsForMainContext(options, mainContext)}
        weekendDays={weekendDays}
        holidays={holidays}
      />
    </>
  )
}

export default HomeView

const HomeViewMobile = ({ projects, tasks, todos, calendarEvents }: HomeViewMobileProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const [openActivityId, setOpenActivityId] = React.useState<string | null>(null)

  const activities = [
    ...tasks.map(adaptTaskToActivity),
    ...todos.map(adaptTodoToActivity),
    ...calendarEvents.map(adaptCalendarEventToActivity),
  ]
  const sortedActivities = sortActivities(activities)
  const sortedProjects = sortProjectsByPlan(projects)
  const openActivity = activities.find((activity) => activity.id === openActivityId)
  const type = openActivity?.type
  const openTask = type === "task" ? tasks.find((task) => task.id === openActivityId) : null
  const openTodo = type === "todo" ? todos.find((todo) => todo.id === openActivityId) : null
  const openCalendarEvent = type === "calendarEvent" ? calendarEvents.find(({ id }) => id === openActivityId) : null
  const { deleteEvent } = useCalendarEventMutations()
  const onDeleteEvent = () => openActivityId && deleteEvent(openActivityId)

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {translations.projects}
      </Typography>
      {sortedProjects.map((project) => {
        return (
          <div key={project.id} style={{ marginTop: 4, marginBottom: 4 }}>
            <ProjectCard key={project.id} project={project} />
          </div>
        )
      })}
      {sortedProjects.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          {translations.projectsEmpty}
        </Typography>
      ) : null}

      <Typography variant="h6" gutterBottom style={{ marginTop: 16 }}>
        {translations.tasks}, {translations.todos} & {translations.calendarEvents}
      </Typography>
      {sortedActivities.map((activity) => {
        return (
          <ActivityCard key={activity.id} activity={activity} onOpenActivity={() => setOpenActivityId(activity.id)} />
        )
      })}
      {sortedActivities.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          {translations.activitiesEmpty}
        </Typography>
      ) : null}

      {openActivity ? (
        <Dialog open={openActivity !== null} PaperProps={{ className: classes.dialogPaper }}>
          <IconButton className={classes.closeButton} onClick={() => setOpenActivityId(null)}>
            <X size={24} />
          </IconButton>
          <div className={classes.fullPathWrapper}>{openActivity.fullPath}</div>
          <div className={classes.titleContainer}>
            {openTask ? <TaskCardTitle task={openTask} /> : null}
            {openTodo ? <TodoCardTitle todo={openTodo} /> : null}
            {openCalendarEvent ? <CalendarEventCardTitle calendarEvent={openCalendarEvent} /> : null}
          </div>
          {openTask && <TaskDetails taskId={openTask.id} />}
          {openTodo && <TodoDetails todo={openTodo} />}
          {openCalendarEvent && <CalendarEventDetails calendarEvent={openCalendarEvent} onDelete={onDeleteEvent} />}
        </Dialog>
      ) : null}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: "flex",
    margin: theme.spacing(0.75, 0, 1.125, 0),
    "& > :first-child": {
      flexGrow: 1,
      marginRight: 32,
    },
  },
  dialogPaper: {
    minWidth: 300,
    width: "100%",
    padding: theme.spacing(2),
    overflowX: "hidden",
    position: "relative",
  },
  fullPathWrapper: {
    maxWidth: "calc(100% - 48px)",
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 1,
    color: theme.palette.text.primary,
  },
  titleContainer: {
    marginLeft: -12,
    marginBottom: 16,
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations = {} } = useI18n("translation")
  const { translations: mt } = useI18n("membership")

  const membershipTranslations = mt?.membershipHomeView || ({} as StringMap)

  const {
    contextNotFoundError = defaults.contextNotFoundError,
    fetchErrorProjects = defaults.fetchErrorProjects,
    fetchErrorTasks = defaults.fetchErrorTasks,
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorCalendarEvents = defaults.fetchErrorCalendarEvents,
    fetchErrorHolidays = defaults.fetchErrorHolidays,
    fetchErrorOrganisation = defaults.fetchErrorOrganisation,
    task = defaults.task,
    todo = defaults.todo,
    calendarEvent = defaults.calendarEvent,
    projectsEmpty = defaults.projectsEmpty,
    activitiesEmpty = defaults.activitiesEmpty,
  } = translations
  const { pageTitleLabel = defaults.pageTitleLabel, showFiltersButtonLabel = defaults.showFiltersButtonLabel } =
    membershipTranslations

  const calendarEvents = translations.calendarViewHeader?.calendarEventsLabel || defaults.calendarEvents
  const projects = translations.appNavBar?.projectsLabel || defaults.projects
  const tasks = translations.appNavBar?.tasksLabel || defaults.tasks
  const todos = translations.appNavBar?.todosLabel || defaults.todos

  return {
    pageTitleLabel,
    showFiltersButtonLabel,
    contextNotFoundError,
    fetchErrorCalendarEvents,
    fetchErrorProjects,
    fetchErrorTasks,
    fetchErrorTodos,
    fetchErrorHolidays,
    fetchErrorOrganisation,
    projects,
    task,
    tasks,
    todo,
    todos,
    calendarEvent,
    calendarEvents,
    projectsEmpty,
    activitiesEmpty,
  }
}

const defaultTranslations = {
  pageTitleLabel: "All current and future activities",
  showFiltersButtonLabel: "Filters",
  contextNotFoundError: "Context not found",
  fetchErrorCalendarEvents: "Failed to fetch calendar events",
  fetchErrorProjects: "Failed to fetch projects",
  fetchErrorTasks: "Failed to fetch tasks",
  fetchErrorTodos: "Failed to fetch todos",
  fetchErrorHolidays: "Failed to fetch holidays",
  fetchErrorOrganisation: "Failed to fetch organisation",
  projects: "Projects",
  task: "Task",
  tasks: "Tasks",
  todo: "To-do",
  todos: "To-dos",
  calendarEvent: "Calendar event",
  calendarEvents: "Calendar events",
  projectsEmpty: "You don't have any projects here yet",
  activitiesEmpty: "You don't have any activities here yet",
}

const makeProjectsQuery = (context: AppMainContextType | AppSubContextType | null): ProjectsQueryParams => {
  return {
    links: "true",
    type: PROJECT_TYPE.NORMAL,
    archived: "false",
    status: [Status.NOT_STARTED, Status.IN_PROGRESS],
    ...getProjectQueryFromContext(context),
  }
}

const makeTasksQuery = (context: AppMainContextType | AppSubContextType | null): TasksQueryParams => {
  return {
    links: "true",
    archived: "false",
    status: [Status.NOT_STARTED, Status.IN_PROGRESS],
    ...getTaskQueryFromContext(context),
  }
}

const makeTodosQuery = (context: AppMainContextType | AppSubContextType | null): TodoQueryParams => {
  return {
    completed: "false",
    ...getTodoQueryFromContext(context),
  }
}

const makeHolidaysQuery = (appMainContext: AppMainContextType | null) => {
  return appMainContext?.type === "org" ? { orgId: appMainContext.id } : { userId: appMainContext?.id as string }
}

function adaptTodoToActivity(todo: TodoViewModel): Activity {
  const fullPath = (
    <FullPath
      projectLink={todo.projectLink}
      taskLink={todo.taskLink}
      ancestorTaskLinks={todo.ancestorTaskLinks}
      projectOrTaskNumber={todo.task?.taskNumber || todo.project?.projectNumber || ""}
      showTaskLink
    />
  )

  return {
    id: todo.id,
    type: "todo",
    title: todo.title,
    startDate: null,
    endDate: todo.dueDate ? new Date(todo.dueDate) : null,
    people: todo.responsible.map(({ name }) => name),
    fullPath,
    enableTimeComponent: todo.enableTimeComponent,
    customers: todo.customers.map(({ name }) => name),
  }
}

function adaptCalendarEventToActivity(calendarEvent: CalendarEventViewModel): Activity {
  const fullPath = (
    <FullPath
      projectLink={calendarEvent.projectLink}
      taskLink={calendarEvent.taskLink}
      ancestorTaskLinks={calendarEvent.ancestorTaskLinks}
      projectOrTaskNumber={calendarEvent.task?.taskNumber || calendarEvent.project?.projectNumber || ""}
      showTaskLink
    />
  )

  return {
    id: calendarEvent.id,
    type: "calendarEvent",
    title: calendarEvent.title,
    startDate: new Date(calendarEvent.startDate),
    endDate: new Date(calendarEvent.endDate),
    people: calendarEvent.participants.map(({ name }) => name),
    fullPath,
    enableTimeComponent: true,
    customers: calendarEvent.customers.map(({ name }) => name),
  }
}

function adaptTaskToActivity(task: TaskViewModel): Activity {
  const fullPath = (
    <FullPath
      projectLink={task.projectLink}
      taskLink={task.taskLink}
      ancestorTaskLinks={task.ancestorTaskLinks}
      projectOrTaskNumber={task.shownTaskNumber}
    />
  )

  return {
    id: task.id,
    type: "task",
    title: task.title,
    startDate: task.plannedStartDate ? new Date(task.plannedStartDate) : null,
    endDate: task.plannedEndDate ? new Date(task.plannedEndDate) : null,
    people: task.managers.map(({ name }) => name),
    fullPath,
    enableTimeComponent: task.enableTimeComponent,
    customers: task.customers.map(({ name }) => name),
  }
}

const getTime = (date: Date | null) => (date ? date.getTime() : Infinity)

function sortActivities(activities: Activity[]): Activity[] {
  return activities.sort((a, b) => {
    const endTimeA = getTime(a.endDate)
    const endTimeB = getTime(b.endDate)

    if (endTimeA !== endTimeB) return endTimeA - endTimeB

    const startTimeA = getTime(a.startDate)
    const startTimeB = getTime(b.startDate)

    return startTimeA - startTimeB
  })
}

type Translations = typeof defaultTranslations
type QueryErrors = {
  calendarEvent?: string
  project?: string
  task?: string
  todo?: string
  organisation?: string
}

type HomeViewMobileProps = {
  projects: ProjectViewModel[]
  tasks: TaskViewModel[]
  todos: TodoViewModel[]
  calendarEvents: CalendarEventViewModel[]
}

export type Activity = {
  id: string
  type: "task" | "todo" | "calendarEvent"
  title: string
  startDate: Date | null
  endDate: Date | null
  people: string[]
  fullPath: JSX.Element
  enableTimeComponent: boolean
  customers: string[]
}
