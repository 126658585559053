import Alert from "@material-ui/lab/Alert"
import AlertTitle from "@material-ui/lab/AlertTitle"

import { AlertProps, AlertTitleProps } from "@material-ui/lab"

type FormErrorAlertProps = {
  title: string
  description: string
  AlertProps?: AlertProps
  AlertTitleProps?: AlertTitleProps
}
const FormErrorAlert = ({ title, description, AlertProps = {}, AlertTitleProps = {} }: FormErrorAlertProps) => {
  return (
    <Alert severity="error" {...AlertProps}>
      <AlertTitle {...AlertTitleProps}>{title}</AlertTitle>
      {description}
    </Alert>
  )
}

export default FormErrorAlert
