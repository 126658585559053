import Accordion from "@material-ui/core/Accordion"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import Typography from "@material-ui/core/Typography"
import RoleAbilitiesManager from "./RoleAbilitiesManager"
import { ChevronDown } from "react-feather"
import { camelCase } from "lodash"
import { useI18n } from "../../hooks"
import type { RoleNormalized } from "../interfaces/role-normalized"
import { useState } from "react"
import { COLOR_PRIMARY } from "../../constants"

const RoleManager = ({
  role,
  updateRoleAbilities,
  applyRoleToAll,
  showApplyRoleAbbilitiesToAllButton = true,
}: Props) => {
  const translations = useTranslations(defaultTranslations)
  const accordionTitle = translations[getTitleKeyFromRole(role)]
  const [expanded, setExpanded] = useState(false)
  const handleAccordionChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded)
  }

  return (
    <Accordion onChange={handleAccordionChange}>
      <AccordionSummary expandIcon={<ChevronDown size={24} style={{ color: expanded ? COLOR_PRIMARY : "" }} />}>
        <Typography
          variant={expanded ? "h6" : "subtitle1"}
          style={{ fontWeight: 500, color: expanded ? COLOR_PRIMARY : "" }}
        >
          {accordionTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ flexWrap: "wrap" }}>
        <RoleAbilitiesManager
          role={role}
          updateRoleAbilities={updateRoleAbilities}
          applyRoleAbilitiesToAll={applyRoleToAll}
          showApplyRoleAbbilitiesToAllButton={showApplyRoleAbbilitiesToAllButton}
        />
      </AccordionDetails>
    </Accordion>
  )
}

export default RoleManager

const defaultTranslations = {
  orgMembersDefaultPermissionsTitle: "Standard members",
  orgLimitedMembersDefaultPermissionsTitle: "Limited members",
  projectManagersDefaultPermissionsTitle: "Project managers",
  projectParticipantsDefaultPermissionsTitle: "Project participants",
  taskManagersDefaultPermissionsTitle: "Task managers",
  taskParticipantsDefaultPermissionsTitle: "Task participants",
}

const useTranslations = (defaults = defaultTranslations): typeof defaultTranslations => {
  const { translations: t } = useI18n("org")
  const translations = t.orgSettingsPage || {}

  const {
    orgMembersDefaultPermissionsTitle = defaults.orgMembersDefaultPermissionsTitle,
    orgLimitedMembersDefaultPermissionsTitle = defaults.orgLimitedMembersDefaultPermissionsTitle,
    projectManagersDefaultPermissionsTitle = defaults.projectManagersDefaultPermissionsTitle,
    projectParticipantsDefaultPermissionsTitle = defaults.projectParticipantsDefaultPermissionsTitle,
    taskManagersDefaultPermissionsTitle = defaults.taskManagersDefaultPermissionsTitle,
    taskParticipantsDefaultPermissionsTitle = defaults.taskParticipantsDefaultPermissionsTitle,
  } = translations

  return {
    orgMembersDefaultPermissionsTitle,
    orgLimitedMembersDefaultPermissionsTitle,
    projectManagersDefaultPermissionsTitle,
    projectParticipantsDefaultPermissionsTitle,
    taskManagersDefaultPermissionsTitle,
    taskParticipantsDefaultPermissionsTitle,
  }
}

type Props = {
  role: RoleNormalized
  showApplyRoleAbbilitiesToAllButton?: boolean
  updateRoleAbilities: (roleId: string, updateData: { abilitiesToAdd?: string[]; abilitiesToRemove?: string[] }) => void
  applyRoleToAll: (roleId: string) => void
}

const getTitleKeyFromRole = (role: { name: string }) => {
  return `${camelCase(role.name)}sDefaultPermissionsTitle` as keyof typeof defaultTranslations
}
