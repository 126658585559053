import api from "../../services/api-service"
import { AxiosRequestConfig } from "axios"
import { makeOptionsApiRoutes } from "../utils/make-api-routes"
import { IOptionResponse } from "../interfaces/options-response"

const optionsApiRoutes = makeOptionsApiRoutes()

export const fetchOptions = (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.get<IOptionResponse[]>(optionsApiRoutes.options(orgId), config)
}

export const fetchUserOptions = (config: AxiosRequestConfig = {}) => {
  return api.get<IOptionResponse[]>(optionsApiRoutes.userOptions(), config)
}
