import { useAppSelector } from "../../store"
import { getOrgMemberships } from "../store/selectors"

export const useOrgMemberships = (orgId: string) => {
  const memberships = useAppSelector((s) => getOrgMemberships(s, orgId))
  const activeMemberships = memberships.filter((m) => !m.isDeactivated)
  const inactiveMemberships = memberships.filter((m) => m.isDeactivated)

  return {
    memberships,
    activeMemberships,
    inactiveMemberships,
  }
}
