import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Typography from "@material-ui/core/Typography"
import Chip from "@material-ui/core/Chip"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles, Theme } from "@material-ui/core"
import { Briefcase, Calendar, ExternalLink, User } from "react-feather"
import { ProjectViewModel } from "../api/project"
import { useDateFormat } from "../../users/hooks/use-date-format"
import { DateTimeService } from "../../services/date-time-service"
import { Link } from "react-router-dom"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { paths } from "../../paths"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"

const ProjectCard = ({ project }: ProjectCardProps) => {
  const classes = useStyles({ color: project.ganttBarColor })
  const translations = useTranslations()
  const { dateFormat } = useDateFormat()
  const dateTimeService = new DateTimeService({ dateFormat })
  const { projectNumber, title, managers, plannedStartDate, plannedEndDate, customers } = project
  const { createPathWithContext } = useUrlWithContext()

  const formattedPlannedStart = plannedStartDate ? dateTimeService.format(plannedStartDate) : translations.noStartDate
  const formattedPlannedEnd = plannedEndDate ? dateTimeService.format(plannedEndDate) : translations.noEndDate
  const hasSchedule = Boolean(plannedStartDate || plannedEndDate)
  const schedule = hasSchedule ? `${formattedPlannedStart} - ${formattedPlannedEnd}` : translations.noSchedule
  const formattedManagers = managers.map(({ name }) => name).join(", ")
  const projectPath = createPathWithContext(paths.projectBasic(project.id))
  const formattedCustomers = customers.map(({ name }) => name).join(", ")
  const projectStatus = project.statusTranslationKey ? translations[project.statusTranslationKey] : project.customStatus

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <div className={classes.header}>
          <Chip label={projectStatus} size="small" variant="default" className={classes.statusChip} />
          <IconButton size="small" color="primary" component={Link} to={projectPath}>
            <ExternalLink size={16} />
          </IconButton>
        </div>
        <Typography variant="subtitle1" component="h2" gutterBottom style={{ marginBottom: 16 }}>
          {projectNumber}: {title}
        </Typography>

        {formattedManagers && (
          <div className={classes.infoRow}>
            <User className={classes.icon} size={16} />
            <Typography variant="body2">{formattedManagers}</Typography>
          </div>
        )}

        {formattedCustomers && (
          <div className={classes.infoRow}>
            <Briefcase className={classes.icon} size={16} />
            <Typography variant="body2">{formattedCustomers}</Typography>
          </div>
        )}

        <div className={classes.infoRow}>
          <Calendar className={classes.icon} size={16} />
          <Typography variant="body2">{schedule}</Typography>
        </div>
      </CardContent>
    </Card>
  )
}

export default ProjectCard

const useStyles = makeStyles<Theme, StyleProps>((theme) => ({
  card: {
    position: "relative",
    transition: "transform 0.2s ease-in-out",
    "& .MuiCardContent-root": {
      paddingBottom: 8,
    },
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  statusChip: {
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.grey[800],
    height: 16,
    fontSize: 12,
  },
  infoRow: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const { translations: projectTranslations } = useI18n("project")
  const translations = t || ({} as StringMap)
  const statusMenuTranslations = projectTranslations.projectStatusMenu || {}
  return { ...defaults, ...translations, ...statusMenuTranslations }
}

const defaultTranslations = {
  noStartDate: "No start date",
  noEndDate: "No end date",
  noSchedule: "No schedule yet",
  notStarted: "Not started",
  inProgress: "In progress",
  completed: "Completed",
  rejected: "Rejected",
}

type Translations = typeof defaultTranslations

type ProjectCardProps = {
  project: ProjectViewModel
}

type StyleProps = {
  color: string
}
