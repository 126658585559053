import { NewCalendarEventData } from "../../calendar-events/api"
import { useCalendarEventMutations } from "../../calendar-events/hooks/use-calendar-event-mutations"
import { useFlattenedPermissions } from "../../permissions/hooks/use-flattened-permissions"
import { useAppSelector } from "../../store"
import { NewTodoData } from "../../todos/api"
import { useTodoMutations } from "../../todos/hooks/use-todo-mutations"
import { getMembershipById } from "../store/selectors"

export const useMembership = (membershipId: string) => {
  const permissions = useFlattenedPermissions(membershipId) || {}
  const membership = useAppSelector((state) => getMembershipById(state, membershipId))
  const calendarEventActions = useCalendarEventMutations()
  const todoActions = useTodoMutations()
  const canRemoveInvitation = Boolean(permissions?.canDeleteAnyInvitation)
  const canAddCalendarEvents = Boolean(permissions?.canCreateCalendarEvents)
  const canAddTodos = Boolean(permissions?.canCreateTodos)

  const onAddTodo = (todo: NewTodoData) => {
    return todoActions.createTodo({
      title: todo.title,
      description: todo.description || null,
      dueDate: todo.dueDate || null,
      organisation: membership.orgId,
      responsible: [membershipId],
      enableTimeComponent: todo.enableTimeComponent,
    })
  }

  const onAddCalendarEvent = (event: NewCalendarEventData) => {
    return calendarEventActions.createCalendarEvent({
      title: event.title,
      startDate: event.startDate,
      endDate: event.endDate,
      description: event.description || null,
      organisation: membership.orgId,
      participants: [membershipId],
    })
  }

  return {
    membership,
    canAddTodos,
    canAddCalendarEvents,
    canRemoveInvitation,
    onAddTodo,
    onAddCalendarEvent,
    ...permissions,
  }
}
