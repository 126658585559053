import CircularProgress from "@material-ui/core/CircularProgress"
import TodoList from "../../../treegrid/todo-list/TodoList"
import ErrorList from "../../../components/ErrorList"
import { RouteComponentProps } from "react-router-dom"
import { useProject } from "../../../projects/hooks/use-project"
import { NewTodoData, useGetTodosQuery } from "../../../todos/api"
import { useDateFormat } from "../../../users/hooks/use-date-format"
import { useUserTimeZone } from "../../../hooks/use-user-time-zone"
import { useOrgOptions } from "../../../options/hooks/use-org-options"
import { useTodoMutations } from "../../../todos/hooks/use-todo-mutations"
import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"
import { useIsSmallScreen } from "../../../hooks/use-is-small-screen"
import { Grid, Typography, useTheme } from "@material-ui/core"
import NewTodoDialog from "../../../todos/components/NewTodoDialog"
import TodoListEmpty from "../../../todos/components/TodoListEmpty"
import TodoListMobile from "../../../todos/components/TodoListMobile"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectTodos = ({ match }: RouteComponentProps<RouteParams>) => {
  const isSmallScreen = useIsSmallScreen()
  const translations = useTranslations()
  const projectId = match.params.projectId
  const { project, isLoading: isLoadingProject, isError: isErrorProject } = useProject(projectId, queryOptions)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
  } = useGetTodosQuery({ project: projectId }, queryOptions)

  const { dateFormat, dateSeparator } = useDateFormat()
  const userTimeZone = useUserTimeZone()
  const { options } = useOrgOptions(project?.maintainerId || "")
  const todoActions = useTodoMutations()
  const theme = useTheme()

  if (isLoadingProject || isLoadingTodos) return <CircularProgress />
  if (isErrorProject) return <ErrorList errors={[translations.fetchErrorProject]} />
  if (isErrorTodos) return <ErrorList errors={[translations.fetchErrorTodos]} />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />

  const onAddTodo = (todoData: NewTodoData) => todoActions.createTodo({ ...todoData, project: projectId })
  const canAddTodos = project.canCreateProjectTodos && !project.archived

  if (isSmallScreen) {
    return (
      <div
        style={{
          marginTop: theme.spacing(2),
          borderTop: `1px solid ${theme.palette.divider}`,
          paddingTop: theme.spacing(2),
        }}
      >
        <Grid container style={{ marginBottom: theme.spacing(2) }}>
          <Grid item style={{ flexGrow: 1 }}>
            <Typography variant="h5">
              {translations.project} {translations.todos.toLocaleLowerCase()}
            </Typography>
          </Grid>
          {todos.length ? (
            <Grid item xs={4} container justifyContent="flex-end" style={{ marginRight: theme.spacing(0.5) }}>
              <NewTodoDialog canAddTodos={canAddTodos} onAddTodo={onAddTodo} />
            </Grid>
          ) : null}
        </Grid>
        {todos.length ? (
          <TodoListMobile todos={todos} />
        ) : (
          <TodoListEmpty canAddTodos={canAddTodos} onAddTodo={onAddTodo} />
        )}
      </div>
    )
  }

  return (
    <TodoList
      gridInfo={`${project?.title} - Todos`}
      dateFormat={dateFormat}
      dateSeparator={dateSeparator}
      firstDayOfWeek={project.firstDayOfWeek}
      timeZone={userTimeZone}
      options={options}
      todos={todos}
      canCreateTodos={project.canCreateProjectTodos}
      onAddTodo={onAddTodo}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n()
  const translations = t || ({} as StringMap)

  const {
    projectNotFoundError = defaults.projectNotFoundError,
    fetchErrorProject = defaults.fetchErrorProject,
    fetchErrorTodos = defaults.fetchErrorTodos,
    project = defaults.project,
  } = translations

  const todos = translations.appNavBar?.todosLabel || defaults.todos

  return {
    projectNotFoundError,
    fetchErrorProject,
    fetchErrorTodos,
    project,
    todos,
  }
}
const defaultTranslations = {
  projectNotFoundError: "Project not found",
  fetchErrorProject: "Failed to fetch project",
  fetchErrorTodos: "Failed to fetch to-dos",
  project: "Project",
  todos: "To-dos",
}
type Translations = typeof defaultTranslations
type RouteParams = { projectId: string }
export default ProjectTodos
