import { useI18n } from "../../hooks"

export const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n()
  const { translations: treegridTranslations } = useI18n("treegrid")

  const addHoliday = treegridTranslations.addHolidayButtonLabel || defaults.addHoliday
  const recurring = treegridTranslations.isRecurringColumnHeader || defaults.recurring

  return {
    ...defaults,
    ...translations,
    addHoliday,
    recurring,
  }
}

const defaultTranslations = {
  add: "Add",
  deleteLabel: "Delete",
  cancel: "Cancel",
  holidaysLabel: "Holidays",
  addHoliday: "Add holiday",
  recurring: "Recurring",
  addANewHoliday: "Add a new holiday",
  date: "Date",
  end: "End",
  start: "Start",
  holiday: "Holiday",
  holidayNamePlaceholder: "Add holiday name",
  holidayStartsFrom: "Holiday starts from",
  holidayEndsOn: "Holiday ends on",
  holidayEndDateHint: "Adding an end date will change this holiday to a range holiday",
  holidaysEmpty: "You have not created any holidays yet.",
}

type Translations = typeof defaultTranslations
