import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import ProjectSuppliersWidget from "./ProjectSuppliersWidget"
import ProjectWorkspacesWidget from "./ProjectWorkspacesWidget"
import ProjectManagersWidget from "./ProjectManagersWidget"
import ProjectParticipantsWidget from "./ProjectParticipantsWidget"
import ProjectCustomersWidget from "./ProjectCustomersWidget"
import InheritPlanWidget from "./InheritPlanWidget"
import ProjectStateSelect from "./ProjectStateSelect"
import React from "react"
import { makeStyles, Theme } from "@material-ui/core"
import { StringMap } from "i18next"
import { ArrowRight } from "react-feather"
import { useProject } from "../hooks/use-project"
import { useProjectMutations } from "../hooks/use-project-mutations"
import DescriptionEditor from "../../components/DescriptionEditor"
import { StatusDescriptionHistoryDialog } from "../../components/StatusDescriptionHistory"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import DateWidget from "../../components/DateWidget"
import { useI18n } from "../../hooks/use-i18n"
import ColorSelectionWidget from "../../components/ColorSelectionWidget"
import CreationMetaDataWidget from "../../components/CreationMetaDataWidget"
import LinkList from "../../links/components/LinkList"
import ProjectTypeDropdown from "./ProjectTypeDropdown"
import ProjectStatusWidget from "./ProjectStatusWidget"
import ProjectCompletionPercentageWidget from "./ProjectCompletionPercentageWidget"
import { useIsSmallScreen } from "../../hooks/use-is-small-screen"

export default function ProjectDetails({ projectId }: { projectId: string }) {
  const isSmallScreen = useIsSmallScreen()
  const { project } = useProject(projectId)
  if (!project) return null
  return isSmallScreen ? (
    <ProjectDetailsMobile projectId={projectId} />
  ) : (
    <ProjectDetailsDefault projectId={projectId} />
  )
}

const useProjectDetails = (projectId: string) => {
  const [isHistoryOpen, setHistoryOpen] = React.useState(false)
  const openHistory = () => setHistoryOpen(true)
  const closeHistory = () => setHistoryOpen(false)
  const {
    project,
    currentStatusDescription,
    sortedStatusDescriptionHistory,
    plannedStartDateIsPastDue,
    plannedEndDateIsPastDue,
    formattedPlannedEnd,
    formattedPlannedStart,
    formattedActualStart,
    formattedActualEnd,
  } = useProject(projectId)
  const { customerOptions, supplierOptions, workspaceOptions } = useOrgOptions(project?.maintainerId || "")

  if (!project) return null

  const hasCustomerOptions = customerOptions.length > 0
  const hasSupplierOptions = supplierOptions.length > 0
  const hasWorkspaceOptions = workspaceOptions.length > 0
  const showCustomerSection = project.isOrgProject && hasCustomerOptions
  const showSupplierSection = project.isOrgProject && hasSupplierOptions
  const showWorkspaceSection = project.isOrgProject && hasWorkspaceOptions

  return {
    project,
    currentStatusDescription,
    sortedStatusDescriptionHistory,
    plannedStartDateIsPastDue,
    plannedEndDateIsPastDue,
    formattedPlannedEnd,
    formattedPlannedStart,
    formattedActualStart,
    formattedActualEnd,
    openHistory,
    closeHistory,
    isHistoryOpen,
    showCustomerSection,
    showSupplierSection,
    showWorkspaceSection,
  }
}

const ProjectDetailsMobile = ({ projectId }: ProjectDetailsProps) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const actions = useProjectMutations()
  const projectDetails = useProjectDetails(projectId)

  if (!projectDetails) return null
  const {
    project,
    currentStatusDescription,
    sortedStatusDescriptionHistory,
    plannedStartDateIsPastDue,
    plannedEndDateIsPastDue,
    formattedPlannedEnd,
    formattedPlannedStart,
    formattedActualStart,
    formattedActualEnd,
    openHistory,
    closeHistory,
    isHistoryOpen,
    showCustomerSection,
    showSupplierSection,
    showWorkspaceSection,
  } = projectDetails
  const { plannedStartDate, plannedEndDate, actualStartDate, actualEndDate, enableTimeComponent } = project

  return (
    <>
      <section style={{ marginTop: -16 }}>
        <DescriptionEditor
          description={project.description}
          onSaveDescription={(description) => actions.updateInfo(projectId, { description })}
          TextAreaAutosizeProps={{
            placeholder: project.canUpdateDetails
              ? translations.descriptionPlaceholder
              : translations.readOnlyDescriptionPlaceholder,
            disabled: !project.canUpdateDetails,
          }}
        />
      </section>

      <section style={{ marginBottom: 16 }}>
        <DateWidget
          calendarStartDay={project.firstDayOfWeek}
          formattedDate={formattedPlannedStart}
          isoDate={plannedStartDate || null}
          onDateChange={(startDate) =>
            actions.updatePlan(project.id, { plannedStartDate: startDate?.toISOString() || "" })
          }
          isReadOnly={!project.canUpdatePlan}
          label={translations.planStartWidgetLabel}
          emptyDateMessage={translations.planStartEmptyMessage}
          clearButtonHint={translations.planStartClearHint}
          isClearable
          showTimeSelect={enableTimeComponent}
          isPastDue={plannedStartDateIsPastDue}
        />
        {project.canUpdatePlan && (
          <InheritPlanWidget
            label={translations.inheritPlanStartLabel}
            onChange={() =>
              actions.updatePlan(project.id, { lockPlannedStartDate: !Boolean(project.lockPlannedStartDate) })
            }
            checked={!Boolean(project.lockPlannedStartDate)}
            isReadOnly={!project.canUpdatePlan}
          />
        )}
      </section>

      <section style={{ marginBottom: 16 }}>
        <DateWidget
          calendarStartDay={project.firstDayOfWeek}
          formattedDate={formattedPlannedEnd}
          isoDate={plannedEndDate || null}
          onDateChange={(endDate) => actions.updatePlan(project.id, { plannedEndDate: endDate?.toISOString() || "" })}
          isReadOnly={!project.canUpdatePlan}
          label={translations.planEndWidgetLabel}
          emptyDateMessage={translations.planEndEmptyMessage}
          clearButtonHint={translations.planEndClearHint}
          showTimeSelect={enableTimeComponent}
          isPastDue={plannedEndDateIsPastDue}
          isClearable
        />
        {project.canUpdatePlan && (
          <InheritPlanWidget
            label={translations.inheritPlanEndLabel}
            onChange={() =>
              actions.updatePlan(project.id, { lockPlannedEndDate: !Boolean(project.lockPlannedEndDate) })
            }
            checked={!Boolean(project.lockPlannedEndDate)}
            isReadOnly={!project.canUpdatePlan}
          />
        )}
      </section>

      <section style={{ marginBottom: 16 }}>
        <ProjectStatusWidget label={translations.statusWidgetLabel} project={project} />
      </section>

      <section style={{ marginBottom: 16 }}>
        <ProjectCompletionPercentageWidget project={project} />
      </section>

      <section style={{ marginBottom: 16 }}>
        <DateWidget
          calendarStartDay={project.firstDayOfWeek}
          formattedDate={formattedActualStart}
          isoDate={actualStartDate || null}
          onDateChange={(startDate) =>
            actions.updateStatus(project.id, { actualStartDate: startDate?.toISOString() || "" })
          }
          isReadOnly={!project.canUpdateStatus}
          label={translations.actualStartWidgetLabel}
          emptyDateMessage={translations.actualStartEmptyMessage}
          clearButtonHint={translations.actualStartClearHint}
          isClearable
          showTimeSelect={enableTimeComponent}
        />
      </section>

      <section style={{ marginBottom: 16 }}>
        <DateWidget
          calendarStartDay={project.firstDayOfWeek}
          formattedDate={formattedActualEnd}
          isoDate={actualEndDate || null}
          onDateChange={(endDate) => actions.updateStatus(project.id, { actualEndDate: endDate?.toISOString() || "" })}
          isReadOnly={!project.canUpdateStatus}
          label={translations.actualEndWidgetLabel}
          emptyDateMessage={translations.actualEndEmptyMessage}
          clearButtonHint={translations.actualEndClearHint}
          showTimeSelect={enableTimeComponent}
          isClearable
        />
      </section>

      <section style={{ marginBottom: 16 }}>
        <Typography variant="body1" component="h4" style={{ marginBottom: 8 }}>
          <strong>{translations.projectStateLabel}</strong>
        </Typography>
        <ProjectStateSelect
          state={project.state}
          onStateChange={(state) => actions.updateStatusDescription(project.id, { state })}
          disabled={!project.canUpdateStatus}
        />
      </section>

      <section style={{ marginBottom: 16 }}>
        <Typography variant="body1" component="h4" style={{ marginBottom: 8 }}>
          <strong>{translations.statusDescriptionLabel}</strong>
        </Typography>
        <DescriptionEditor
          description={project.statusDescription || ""}
          onSaveDescription={(statusDescription) => actions.updateStatusDescription(project.id, { statusDescription })}
          TextAreaAutosizeProps={{
            placeholder: project.canUpdateStatus
              ? translations.statusDescriptionPlaceholder
              : translations.readOnlyStatusDescriptionPlaceholder,
            disabled: !project.canUpdateStatus,
          }}
        />
        {currentStatusDescription && (
          <>
            <Typography variant="caption" style={{ marginTop: -8 }}>
              {currentStatusDescription.updatedBy}
              &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
              {currentStatusDescription.updatedAt}
              &nbsp;&nbsp;&nbsp;•
              <Button
                color="primary"
                size="small"
                className={classes.viewHistoryButton}
                onClick={openHistory}
                endIcon={<ArrowRight size={12} />}
              >
                {translations.viewHistoryButtonLabel}
              </Button>
            </Typography>
            <StatusDescriptionHistoryDialog
              isOpen={isHistoryOpen}
              onClose={closeHistory}
              entries={sortedStatusDescriptionHistory || []}
              title={translations.statusDescriptionHistoryDialogTitle}
            />
          </>
        )}
      </section>

      {project.isOrgProject ? (
        <>
          {showCustomerSection ? (
            <section style={{ marginBottom: 16 }}>
              <ProjectCustomersWidget project={project} />
            </section>
          ) : null}

          {showSupplierSection ? (
            <section style={{ marginBottom: 16 }}>
              <ProjectSuppliersWidget project={project} />
            </section>
          ) : null}

          {showWorkspaceSection ? (
            <section style={{ marginBottom: 16 }}>
              <ProjectWorkspacesWidget project={project} />
            </section>
          ) : null}

          <section style={{ marginBottom: 16 }}>
            <ProjectManagersWidget project={project} />
          </section>
          <section style={{ marginBottom: 16 }}>
            <ProjectParticipantsWidget project={project} />
          </section>
          <section style={{ marginBottom: 16 }}>
            <ColorSelectionWidget
              selectedColor={project.ganttBarColor}
              onColorChange={(color) => actions.updateInfo(project.id, { ganttBarColor: color })}
              disabled={!project.canUpdateDetails}
            />
          </section>
          <section style={{ marginBottom: 16 }}>
            <LinkList
              entityId={project.id}
              entityType="project"
              canAddLink={project.canUpdateDetails}
              canUpdateLinks={project.canUpdateDetails}
              canDeleteLinks={project.canUpdateDetails}
            />
          </section>
          <section style={{ marginBottom: 16 }}>
            <ProjectTypeDropdown project={project} isReadOnly={!project.canUpdateStatus} />
          </section>
        </>
      ) : null}

      <section style={{ marginBottom: 16 }}>
        <CreationMetaDataWidget creatorName={project.creatorName} creationDate={project.createdAt} />
      </section>
    </>
  )
}

const ProjectDetailsDefault = ({ projectId }: ProjectDetailsProps) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const actions = useProjectMutations()
  const projectDetails = useProjectDetails(projectId)
  if (!projectDetails) return null
  const {
    project,
    currentStatusDescription,
    sortedStatusDescriptionHistory,
    plannedStartDateIsPastDue,
    plannedEndDateIsPastDue,
    formattedPlannedEnd,
    formattedPlannedStart,
    formattedActualStart,
    formattedActualEnd,
    openHistory,
    closeHistory,
    isHistoryOpen,
    showCustomerSection,
    showSupplierSection,
    showWorkspaceSection,
  } = projectDetails

  const { plannedStartDate, plannedEndDate, actualStartDate, actualEndDate, enableTimeComponent } = project

  return (
    <div data-test="project-details-card">
      <Grid container spacing={4} className={classes.container} wrap="nowrap">
        {project.archived && <div className={classes.readOnlyOverlay}></div>}
        <Grid item className={classes.main}>
          <DescriptionEditor
            description={project.description}
            onSaveDescription={(description) => actions.updateInfo(project.id, { description })}
            TextAreaAutosizeProps={{
              placeholder: project.canUpdateDetails
                ? translations.descriptionPlaceholder
                : translations.readOnlyDescriptionPlaceholder,
              disabled: !project.canUpdateDetails,
            }}
          />
          <div style={{ marginBottom: 24, marginTop: 24 }}>
            <Typography variant="body1" component="h4">
              <strong>{translations.projectStateLabel}</strong>
            </Typography>
            <ProjectStateSelect
              state={project.state}
              onStateChange={(state) => actions.updateStatusDescription(project.id, { state })}
              disabled={!project.canUpdateStatus}
            />
          </div>
          <div style={{ marginBottom: 24 }}>
            <Typography variant="body1" component="h4" style={{ marginBottom: 8 }}>
              <strong>{translations.statusDescriptionLabel}</strong>
            </Typography>
            <DescriptionEditor
              description={project.statusDescription || ""}
              onSaveDescription={(statusDescription) =>
                actions.updateStatusDescription(project.id, { statusDescription })
              }
              TextAreaAutosizeProps={{
                placeholder: project.canUpdateStatus
                  ? translations.statusDescriptionPlaceholder
                  : translations.readOnlyStatusDescriptionPlaceholder,
                disabled: !project.canUpdateStatus,
              }}
            />
            {currentStatusDescription && (
              <>
                <Typography variant="caption" style={{ marginTop: -8 }}>
                  {currentStatusDescription.updatedBy}
                  &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
                  {currentStatusDescription.updatedAt}
                  &nbsp;&nbsp;&nbsp;•
                  <Button
                    color="primary"
                    size="small"
                    className={classes.viewHistoryButton}
                    onClick={openHistory}
                    endIcon={<ArrowRight size={12} />}
                  >
                    {translations.viewHistoryButtonLabel}
                  </Button>
                </Typography>
                <StatusDescriptionHistoryDialog
                  isOpen={isHistoryOpen}
                  onClose={closeHistory}
                  entries={sortedStatusDescriptionHistory || []}
                  title={translations.statusDescriptionHistoryDialogTitle}
                />
              </>
            )}
          </div>

          {project.isOrgProject && (
            <>
              {(showCustomerSection || showSupplierSection || showWorkspaceSection) && (
                <>
                  <Grid container spacing={3}>
                    {showCustomerSection && (
                      <Grid item style={{ flex: 1 }} data-test="project-customer-widget">
                        <ProjectCustomersWidget project={project} />
                      </Grid>
                    )}
                    {showSupplierSection && (
                      <Grid item style={{ flex: 1 }} data-test="project-supplier-widget">
                        <ProjectSuppliersWidget project={project} />
                      </Grid>
                    )}
                    {showWorkspaceSection && (
                      <Grid item style={{ flex: 1 }} data-test="project-workspace-widget">
                        <ProjectWorkspacesWidget project={project} />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}

              <Grid container spacing={3}>
                <Grid item style={{ flex: 1 }} data-test="project-manager-widget">
                  <ProjectManagersWidget project={project} />
                </Grid>
                <Grid item style={{ flex: 1 }} data-test="project-participant-widget">
                  <ProjectParticipantsWidget project={project} />
                </Grid>
              </Grid>
            </>
          )}

          <section style={{ margin: "24px 0px 24px 0px" }}>
            <ColorSelectionWidget
              selectedColor={project.ganttBarColor}
              onColorChange={(color) => actions.updateInfo(project.id, { ganttBarColor: color })}
              disabled={!project.canUpdateDetails}
            />
          </section>

          <Grid container spacing={3} style={{ margin: "24px 0px 24px 0px" }}>
            <Grid item style={{ flex: 1 }} data-test="link-list">
              <LinkList
                entityId={project.id}
                entityType="project"
                canAddLink={project.canUpdateDetails}
                canUpdateLinks={project.canUpdateDetails}
                canDeleteLinks={project.canUpdateDetails}
              />
            </Grid>
            <Grid item style={{ flex: 1 }}></Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.sidebar}>
          <section className={classes.sidebarWidget}>
            <DateWidget
              calendarStartDay={project.firstDayOfWeek}
              formattedDate={formattedPlannedStart}
              isoDate={plannedStartDate || null}
              onDateChange={(startDate) =>
                actions.updatePlan(project.id, { plannedStartDate: startDate?.toISOString() || "" })
              }
              isReadOnly={!project.canUpdatePlan}
              label={translations.planStartWidgetLabel}
              emptyDateMessage={translations.planStartEmptyMessage}
              clearButtonHint={translations.planStartClearHint}
              isClearable
              showTimeSelect={enableTimeComponent}
              isPastDue={plannedStartDateIsPastDue}
            />
            {project.canUpdatePlan && (
              <InheritPlanWidget
                label={translations.inheritPlanStartLabel}
                onChange={() =>
                  actions.updatePlan(project.id, { lockPlannedStartDate: !Boolean(project.lockPlannedStartDate) })
                }
                checked={!Boolean(project.lockPlannedStartDate)}
                isReadOnly={!project.canUpdatePlan}
              />
            )}
          </section>
          <Divider />

          <section className={classes.sidebarWidget}>
            <DateWidget
              calendarStartDay={project.firstDayOfWeek}
              formattedDate={formattedPlannedEnd}
              isoDate={plannedEndDate || null}
              onDateChange={(endDate) =>
                actions.updatePlan(project.id, { plannedEndDate: endDate?.toISOString() || "" })
              }
              isReadOnly={!project.canUpdatePlan}
              label={translations.planEndWidgetLabel}
              emptyDateMessage={translations.planEndEmptyMessage}
              clearButtonHint={translations.planEndClearHint}
              showTimeSelect={enableTimeComponent}
              isPastDue={plannedEndDateIsPastDue}
              isClearable
            />
            {project.canUpdatePlan && (
              <InheritPlanWidget
                label={translations.inheritPlanEndLabel}
                onChange={() =>
                  actions.updatePlan(project.id, { lockPlannedEndDate: !Boolean(project.lockPlannedEndDate) })
                }
                checked={!Boolean(project.lockPlannedEndDate)}
                isReadOnly={!project.canUpdatePlan}
              />
            )}
          </section>
          <Divider />

          <section className={classes.sidebarWidget}>
            <ProjectStatusWidget label={translations.statusWidgetLabel} project={project} />
          </section>
          <Divider />

          <section className={classes.sidebarWidget}>
            <ProjectCompletionPercentageWidget project={project} />
          </section>
          <Divider />

          {project.isOrgProject ? (
            <>
              <ProjectTypeDropdown project={project} isReadOnly={!project.canUpdateStatus} />
              <Divider />
            </>
          ) : null}

          <section className={classes.sidebarWidget}>
            <DateWidget
              calendarStartDay={project.firstDayOfWeek}
              formattedDate={formattedActualStart}
              isoDate={actualStartDate || null}
              onDateChange={(startDate) =>
                actions.updateStatus(project.id, { actualStartDate: startDate?.toISOString() || "" })
              }
              isReadOnly={!project.canUpdateStatus}
              label={translations.actualStartWidgetLabel}
              emptyDateMessage={translations.actualStartEmptyMessage}
              clearButtonHint={translations.actualStartClearHint}
              isClearable
              showTimeSelect={enableTimeComponent}
            />
          </section>
          <Divider />

          <section className={classes.sidebarWidget}>
            <DateWidget
              calendarStartDay={project.firstDayOfWeek}
              formattedDate={formattedActualEnd}
              isoDate={actualEndDate || null}
              onDateChange={(endDate) =>
                actions.updateStatus(project.id, { actualEndDate: endDate?.toISOString() || "" })
              }
              isReadOnly={!project.canUpdateStatus}
              label={translations.actualEndWidgetLabel}
              emptyDateMessage={translations.actualEndEmptyMessage}
              clearButtonHint={translations.actualEndClearHint}
              showTimeSelect={enableTimeComponent}
              isClearable
            />
          </section>
          <Divider />

          {project.canDelete && !project.archived && (
            <Button
              onClick={() => actions.archiveProject(project.id)}
              variant="outlined"
              style={{ marginBottom: 8, marginTop: 26 }}
              fullWidth
            >
              {translations.archiveButtonLabel}
            </Button>
          )}

          <section className={classes.sidebarWidget}>
            <CreationMetaDataWidget creatorName={project.creatorName} creationDate={project.createdAt} />
          </section>
        </Grid>
      </Grid>
    </div>
  )
}

const useTranslations = (defaults: ProjectDetailsTranslations): ProjectDetailsTranslations => {
  const { translations: t } = useI18n("project")
  const { translations: treegridTranslations } = useI18n("treegrid")
  const translations = (t?.projectDetails || {}) as StringMap

  const { statusColumnHeader = defaults.statusWidgetLabel, stateColumnHeader = defaults.projectStateLabel } =
    treegridTranslations
  const statusWidgetLabel = statusColumnHeader
  const projectStateLabel = stateColumnHeader

  const {
    archivedMessage = defaults.archivedMessage,
    unarchiveButtonLabel = defaults.unarchiveButtonLabel,
    resourcesSectionHeader = defaults.resourcesSectionHeader,
    relationsSectionHeader = defaults.relationsSectionHeader,
    planStartWidgetLabel = defaults.planStartWidgetLabel,
    planStartEmptyMessage = defaults.planStartEmptyMessage,
    planStartClearHint = defaults.planStartClearHint,
    planEndWidgetLabel = defaults.planEndWidgetLabel,
    planEndEmptyMessage = defaults.planEndEmptyMessage,
    planEndClearHint = defaults.planEndClearHint,
    actualStartWidgetLabel = defaults.actualStartWidgetLabel,
    actualStartEmptyMessage = defaults.actualStartEmptyMessage,
    actualStartClearHint = defaults.actualStartClearHint,
    actualEndWidgetLabel = defaults.actualEndWidgetLabel,
    actualEndEmptyMessage = defaults.actualEndEmptyMessage,
    actualEndClearHint = defaults.actualEndClearHint,
    actionsSectionHeader = defaults.actionsSectionHeader,
    archiveButtonLabel = defaults.archiveButtonLabel,
    inheritPlanStartLabel = defaults.inheritPlanStartLabel,
    inheritPlanEndLabel = defaults.inheritPlanEndLabel,
    descriptionPlaceholder = defaults.descriptionPlaceholder,
    statusDescriptionPlaceholder = defaults.statusDescriptionPlaceholder,
    readOnlyDescriptionPlaceholder = defaults.readOnlyDescriptionPlaceholder,
    readOnlyStatusDescriptionPlaceholder = defaults.readOnlyStatusDescriptionPlaceholder,
    viewHistoryButtonLabel = defaults.viewHistoryButtonLabel,
    statusDescriptionLabel = defaults.statusDescriptionLabel,
    statusDescriptionHistoryDialogTitle = defaults.statusDescriptionHistoryDialogTitle,
    localeSectionHeader = defaults.localeSectionHeader,
  } = translations

  return {
    archivedMessage,
    unarchiveButtonLabel,
    resourcesSectionHeader,
    relationsSectionHeader,
    planStartWidgetLabel,
    planStartEmptyMessage,
    planStartClearHint,
    planEndWidgetLabel,
    planEndEmptyMessage,
    planEndClearHint,
    actualStartWidgetLabel,
    actualStartEmptyMessage,
    actualStartClearHint,
    actualEndWidgetLabel,
    actualEndEmptyMessage,
    actualEndClearHint,
    statusWidgetLabel,
    actionsSectionHeader,
    archiveButtonLabel,
    inheritPlanStartLabel,
    inheritPlanEndLabel,
    descriptionPlaceholder,
    statusDescriptionPlaceholder,
    readOnlyDescriptionPlaceholder,
    readOnlyStatusDescriptionPlaceholder,
    viewHistoryButtonLabel,
    statusDescriptionLabel,
    statusDescriptionHistoryDialogTitle,
    projectStateLabel,
    localeSectionHeader,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: { position: "relative" },
  readOnlyOverlay: {
    backgroundColor: `${theme.palette.common.black}07`,
    position: "absolute",
    top: 8,
    bottom: 0,
    left: 0,
    right: 0,
    margin: theme.spacing(-1),
    zIndex: 99,
    cursor: "not-allowed",
  },

  sidebar: { width: 300 },
  main: { flexGrow: 1 },
  sidebarWidget: {
    padding: theme.spacing(1.5, 0),
    "&:first-child": {
      marginTop: -theme.spacing(2.5),
    },
  },
  viewHistoryButton: {
    marginLeft: 4,
    fontSize: 12,
    fontWeight: 400,
    "& .MuiButton-endIcon": {
      marginLeft: 4,
      marginTop: 2,
    },
  },
}))

const defaultTranslations = {
  archivedMessage: "Project is archived",
  unarchiveButtonLabel: "Unarchive this project",
  resourcesSectionHeader: "Project resources",
  relationsSectionHeader: "Project relations",
  localeSectionHeader: "Locale",
  planStartWidgetLabel: "Planned start date",
  planStartEmptyMessage: "Planned start date is not given",
  planStartClearHint: "Clear planned start date",
  planEndWidgetLabel: "Planned end date",
  planEndEmptyMessage: "Planned end date is not given",
  planEndClearHint: "Clear planned end date",
  actualStartWidgetLabel: "Actual start date",
  actualStartEmptyMessage: "Actual start date is not given",
  actualStartClearHint: "Clear actual start date",
  actualEndWidgetLabel: "Actual end date",
  actualEndEmptyMessage: "Actual end date is not given",
  actualEndClearHint: "Clear actual end date",
  statusWidgetLabel: "Status",
  actionsSectionHeader: "Project actions",
  archiveButtonLabel: "Archive this project",
  inheritPlanStartLabel: "Inherit start date from tasks",
  inheritPlanEndLabel: "Inherit end date from tasks",
  descriptionPlaceholder: "Add a description",
  statusDescriptionPlaceholder: "Add a short status description",
  readOnlyDescriptionPlaceholder: "This project has no description",
  readOnlyStatusDescriptionPlaceholder: "This project has no status description",
  viewHistoryButtonLabel: "View history",
  statusDescriptionLabel: "Status description",
  statusDescriptionHistoryDialogTitle: "Status description history",
  projectStateLabel: "State",
}

type ProjectDetailsProps = { projectId: string }
type ProjectDetailsTranslations = typeof defaultTranslations
