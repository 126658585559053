import { z } from "zod"
import { TimeZoneType } from "../../constants/timezones"
import { DateFormatOptionType, DateFormatSeparatorOptionType } from "../constants"
import { SuspensionStatus } from "../interfaces/org-response"
import { NotificationSettingsData } from "../../notifications/notifications"

const SystemStatusIdSchema = z.enum(["0", "1", "2", "3"])

const SubStatusSchema = z.object({
  id: z.string(),
  name: z.string(),
  order: z.number(),
  isVisibleInOptions: z.boolean(),
})

const CustomStatusSchema = z.object({
  name: z.string(),
  systemStatusId: SystemStatusIdSchema,
  subStatuses: z.array(SubStatusSchema).optional().default([]),
})

export const CustomStatusMapSchema = z.object({
  "0": CustomStatusSchema,
  "1": CustomStatusSchema,
  "2": CustomStatusSchema,
  "3": CustomStatusSchema,
})

export type CheckBillingSettingsResponse = {
  billingSettingsIsFilled: boolean
  canInviteUserWithoutBillingInfo: boolean
}

export type OrganisationResponse = {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  email: string | null
  ownerId: string
  ownerEmail: string
  ownerFullname: string
  weekendDays: DayOfWeek[]
  firstDayOfWeek: DayOfWeek
  timeZone: TimeZoneType
  dateFormatSeparator: DateFormatSeparatorOptionType
  dateFormat: DateFormatOptionType
  suspensionStatus: SuspensionStatus
  canReadDetails: boolean
  canReadPermissions: boolean
  canUpdateDetails: boolean
  canUpdatePermissions: boolean
  canDelete: boolean
  notificationSettings: NotificationSettingsData
  taskStatuses: CustomStatusMap
}

export type SystemStatusId = z.infer<typeof SystemStatusIdSchema>
export type SubStatus = z.infer<typeof SubStatusSchema>
export type CustomStatus = z.infer<typeof CustomStatusSchema>
export type CustomStatusMap = z.infer<typeof CustomStatusMapSchema>
