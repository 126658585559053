import { unwrapResult } from "@reduxjs/toolkit"
import { useAuthUserMembership } from "../../memberships/hooks/use-auth-user-membership"
import { useAppDispatch, useAppSelector } from "../../store"
import { CustomerStatusEnum } from "../../types/customer"
import { AddCustomerContact, UpdateCustomerStatus } from "../store/actions"
import { getCustomerById } from "../store/selectors"
import { useTodoMutations } from "../../todos/hooks/use-todo-mutations"
import { useCalendarEventMutations } from "../../calendar-events/hooks/use-calendar-event-mutations"
import { NewCalendarEventData } from "../../calendar-events/api"
import { NewTodoData } from "../../todos/api"

export const useCustomer = (customerId: string) => {
  const dispatch = useAppDispatch()
  const customer = useAppSelector((s) => getCustomerById(s, customerId))
  const { canCreateCalendarEvents, canCreateTodos } = useAuthUserMembership()
  const calendarEventActions = useCalendarEventMutations()
  const todoActions = useTodoMutations()

  const onChangeStatus = () => {
    const status = customer.isActive ? CustomerStatusEnum.inActive : CustomerStatusEnum.active
    return dispatch(
      UpdateCustomerStatus({
        customerId: customer.id,
        status: status,
      })
    )
  }

  const onAddContact = async (contact: {
    firstname: string
    lastname: string
    phone?: string
    email?: string
    jobTitle?: string
  }) => {
    unwrapResult(await dispatch(AddCustomerContact({ customerId, ...contact })))
  }

  const onAddCalendarEvent = (event: NewCalendarEventData) => {
    return calendarEventActions.createCalendarEvent({
      ...event,
      description: event.description || null,
      organisation: customer.orgId,
      customers: [customer.id],
    })
  }

  const onAddTodo = (todoData: NewTodoData) => {
    return todoActions.createTodo({
      ...todoData,
      description: todoData.description || null,
      dueDate: todoData.dueDate || null,
      organisation: customer.orgId,
      customers: [customer.id],
    })
  }

  return {
    customer,
    customerName: customer?.fullname,
    canAddCalendarEvents: canCreateCalendarEvents,
    canAddTodos: canCreateTodos,
    onAddCalendarEvent,
    onAddTodo,
    onChangeStatus,
    onAddContact,
  }
}
