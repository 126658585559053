import ConfirmDialog from "./ConfirmDialog"

import { PropsWithChildren, useState } from "react"
import { ConfirmDialogContext } from "./ConfirmDialogContext"
import { Dialog, OpenDialogParams, State } from "./types"
import { useI18n } from "../../hooks"

const ConfirmDialogProvider = (props: PropsWithChildren<{}>) => {
  const translations = useTranslations()
  const [state, setState] = useState<State>({
    dialog: null,
    contextValue: {
      openDialog,
    },
  })

  function closeDialog() {
    setState((state) => {
      const newState = {
        ...state,
        dialog: null,
      }
      return newState
    })
  }

  function openDialog(
    {
      onConfirm,
      onCancel = () => {},
      primaryActionButtonLabel = translations.primaryActionButtonLabel,
      secondaryActionButtonLabel = translations.secondaryActionButtonLabel,
      text = translations.text,
      title = translations.title,
    }: OpenDialogParams,
    ...args: [...any[]]
  ) {
    const handleCancel = async () => {
      closeDialog()
      await onCancel(...args)
    }

    const handleConfirm = async () => {
      closeDialog()
      await onConfirm(...args)
    }

    return setState((state) => {
      const dialog: Dialog = {
        handleCancel,
        handleClose: closeDialog,
        handleConfirm,
        open: true,
        primaryActionButtonLabel,
        secondaryActionButtonLabel,
        title,
        text,
      }

      return {
        ...state,
        dialog,
      }
    })
  }

  return (
    <ConfirmDialogContext.Provider value={state.contextValue}>
      {props.children}
      {state.dialog && <ConfirmDialog dialog={state.dialog} />}
    </ConfirmDialogContext.Provider>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations) => {
  const { translations: t } = useI18n("translation")
  const translations = (t.confirmationDialog || {}) as { [k: string]: string }

  const {
    primaryActionButtonLabel = defaults.primaryActionButtonLabel,
    secondaryActionButtonLabel = defaults.secondaryActionButtonLabel,
    title = defaults.title,
    text = defaults.text,
  } = translations

  return {
    primaryActionButtonLabel,
    secondaryActionButtonLabel,
    title,
    text,
  }
}

const defaultTranslations = {
  primaryActionButtonLabel: "Confirm",
  secondaryActionButtonLabel: "Cancel",
  title: "Confirm",
  text: "Are you sure you want to perform this action?",
}

type Translations = typeof defaultTranslations

export default ConfirmDialogProvider
