import CircularProgress from "@material-ui/core/CircularProgress"
import ErrorList from "../../../components/ErrorList"
import TaskListMobile from "../../../tasks/components/TaskListMobile"
import TaskTreeGrid from "../../../treegrid/task-tree-grid/TaskTreeGrid"
import { RouteComponentProps } from "react-router-dom"
import { useTask } from "../../../tasks/hooks/use-task"
import { useGetTasksQuery } from "../../../tasks/api"
import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"
import { useIsSmallScreen } from "../../../hooks/use-is-small-screen"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TaskSubtasksRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const isSmallScreen = useIsSmallScreen()
  const translations = useTranslations()
  const { taskId } = match.params
  const { task, bulkAddTasks, isLoading: isLoadingTask, isError: isErrorTask } = useTask(taskId, queryOptions)
  const tasksQuery = { task: taskId, archived: "false", links: "true" } as const
  const {
    data: subtasks = [],
    isLoading: isLoadingSubtasks,
    isError: isErrorSubtasks,
  } = useGetTasksQuery(tasksQuery, queryOptions)

  if (isLoadingTask || isLoadingSubtasks) return <CircularProgress />
  if (isErrorTask) return <ErrorList errors={[translations.fetchErrorTask]} />
  if (isErrorSubtasks) return <ErrorList errors={[translations.fetchErrorTasks]} />
  if (!task) return <ErrorList errors={[translations.taskNotFoundError]} />

  const descendants = subtasks.filter((subtask) => subtask.ancestors.includes(task.id))

  return isSmallScreen ? (
    <TaskListMobile rootTaskId={taskId} tasks={descendants} canAddTasks={task.canCreate} onAddTask={bulkAddTasks} />
  ) : (
    <TaskTreeGrid task={task} tasks={subtasks} onAddTask={bulkAddTasks} />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = t || ({} as StringMap)

  const {
    fetchErrorTask = defaults.fetchErrorTask,
    fetchErrorTasks = defaults.fetchErrorTasks,
    taskNotFoundError = defaults.taskNotFoundError,
  } = translations

  return {
    fetchErrorTask,
    fetchErrorTasks,
    taskNotFoundError,
  }
}

const defaultTranslations = {
  fetchErrorTask: "Failed to fetch task",
  fetchErrorTasks: "Failed to fetch tasks",
  taskNotFoundError: "Task not found",
}
type Translations = typeof defaultTranslations
type RouteParams = { taskId: string }
export default TaskSubtasksRoute
