import {
  getCustomerOptions,
  getMembershipOptions,
  getOptionId,
  getOptionName,
  getWorkspaceOptions,
} from "../../options/utils"
import { TreeGridTranslations } from "../hooks/use-translations"
import { Option, TreeGridColumn } from "../types"
import { makeColorEnum, makeEnumString } from "../utils"
import { TodoColumnName } from "./types"

export const makeColumns = (props: MakeColumnProps): TreeGridColumn[] => {
  const { defaultVisibleColumns, defaultColumnOrder, options, translations } = props

  const customerOptions = getCustomerOptions(options)
  const customerNames = customerOptions.map(getOptionName)
  const customerIds = customerOptions.map(getOptionId)
  const customersEnum = makeEnumString(customerNames)
  const customersEnumKeys = makeEnumString(customerIds)

  const workspaceOptions = getWorkspaceOptions(options)
  const workspaceNames = workspaceOptions.map(getOptionName)
  const workspaceIds = workspaceOptions.map(getOptionId)
  const workspacesEnum = makeEnumString(workspaceNames)
  const workspacesEnumKeys = makeEnumString(workspaceIds)

  const membershipOptions = getMembershipOptions(options)
  const membershipNames = membershipOptions.map(getOptionName)
  const membershipIds = membershipOptions.map(getOptionId)
  const responsibleEnum = makeEnumString(membershipNames)
  const responsibleEnumKeys = makeEnumString(membershipIds)

  const ganttBarColorEnum = makeColorEnum(translations)

  const hasCustomerOptions = customerOptions.length > 0
  const hasMembershipOptions = membershipOptions.length > 0
  const hasWorkspaceOptions = workspaceOptions.length > 0
  const isCustomerColumnVisible = hasCustomerOptions && defaultVisibleColumns.includes("customers")
  const isResponsibleColumnVisible = hasMembershipOptions && defaultVisibleColumns.includes("responsible")
  const isWorkspaceColumnVisible = hasWorkspaceOptions && defaultVisibleColumns.includes("workspaces")

  const createdAtColumn = {
    Name: "createdAt",
    Type: "Date",
    Visible: 0,
    CanHide: 0,
    CanExport: 0,
    CanPrint: 0,
  }

  const completedColumn = {
    Name: "completed",
    Type: "Bool",
    Width: 80,
    header: translations.completedColumnHeader,
    Visible: defaultVisibleColumns.includes("completed"),
  }

  const titleColumn = {
    Name: "title",
    MinWidth: 160,
    RelWidth: 1,
    CaseSensitive: 0,
    header: translations.titleColumnHeader,
    Visible: defaultVisibleColumns.includes("title"),
  }

  const descriptionColumn = {
    Name: "description",
    Visible: defaultVisibleColumns.includes("description"),
    CaseSensitive: 0,
    Type: "Lines",
    MinWidth: 200,
    RelWidth: 1,
    AcceptEnters: 1,
    header: translations.descriptionColumnHeader,
    CanSort: 0,
  }

  const dueDateColumn = {
    Name: "dueDate",
    Type: "Date",
    Width: 160,
    header: translations.dueDateColumnHeader,
    Visible: defaultVisibleColumns.includes("dueDate"),
  }

  const customersColumn = {
    Name: "customers",
    Type: "Enum",
    Value: 0,
    Range: true,
    header: translations.customersColumnHeader,
    CanHide: hasCustomerOptions ? 1 : 0,
    Visible: isCustomerColumnVisible,
    Enum: customersEnum,
    EnumKeys: customersEnumKeys,
  }

  const workspacesColumn = {
    Name: "workspaces",
    Type: "Enum",
    Value: 0,
    Range: true,
    header: translations.workspacesColumnHeader,
    CanHide: hasWorkspaceOptions ? 1 : 0,
    Visible: isWorkspaceColumnVisible,
    Enum: workspacesEnum,
    EnumKeys: workspacesEnumKeys,
  }

  const responsibleColumn = {
    Name: "responsible",
    Type: "Enum",
    Value: 0,
    Range: true,
    header: translations.responsibleColumnHeader,
    CanHide: hasMembershipOptions ? 1 : 0,
    Visible: isResponsibleColumnVisible,
    Enum: responsibleEnum,
    EnumKeys: responsibleEnumKeys,
  }

  const enableTimeComponentColumn = {
    Name: "enableTimeComponent",
    Type: "Bool",
    Width: 44,
    header: "🕒",
    CanSort: 0,
    Visible: defaultVisibleColumns.includes("enableTimeComponent"),
  }

  const ganttBarColorColumn = {
    Name: "ganttBarColor",
    Type: "Enum",
    CanFilter: 1,
    Enum: ganttBarColorEnum,
    MinWidth: 120,
    Visible: defaultVisibleColumns.includes("ganttBarColor"),
    header: translations.color,
  }

  const columnsByName: Record<TodoColumnName, TreeGridColumn> = {
    title: titleColumn,
    description: descriptionColumn,
    dueDate: dueDateColumn,
    customers: customersColumn,
    workspaces: workspacesColumn,
    responsible: responsibleColumn,
    completed: completedColumn,
    enableTimeComponent: enableTimeComponentColumn,
    ganttBarColor: ganttBarColorColumn,
  }

  return defaultColumnOrder
    .map((name) => columnsByName[name])
    .concat({ Name: "Panel", CanHide: 0 })
    .concat(createdAtColumn)
}

type MakeColumnProps = {
  defaultVisibleColumns: TodoColumnName[]
  defaultColumnOrder: TodoColumnName[]
  options: Option[]
  translations: TreeGridTranslations
}
