import { StringMap } from "i18next"
import { useI18n } from "../../hooks"

export const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const { translations: projectTranslations } = useI18n("project")
  const columnTranslations = (t?.boardColumn || {}) as StringMap
  const cardTranslations = (t?.boardCard || {}) as StringMap
  const columnChooserTranslations = (t?.boardColumnChooser || {}) as StringMap
  const statusMenuTranslations = projectTranslations?.projectStatusMenu || {}

  const {
    addCardButtonLabel = defaults.addCardButtonLabel,
    addTaskButtonLabel = defaults.addTaskButtonLabel,
    addTodoButtonLabel = defaults.addTodoButtonLabel,
    addTaskButtonTip = defaults.addTaskButtonTip,
    addTodoButtonTip = defaults.addTodoButtonTip,
    cardTitlePlaceholder = defaults.cardTitlePlaceholder,
    inputQueueLabel = defaults.inputQueueLabel,
    backlogLabel = defaults.backlogLabel,
    planningLabel = defaults.planningLabel,
    implementationLabel = defaults.implementationLabel,
    reviewLabel = defaults.reviewLabel,
    testingLabel = defaults.testingLabel,
    deploymentLabel = defaults.deploymentLabel,
  } = columnTranslations

  const notStartedLabel = statusMenuTranslations.notStarted || defaults.notStartedLabel
  const completedLabel = statusMenuTranslations.completed || defaults.completedLabel
  const openKanbanBtnLabel = cardTranslations.openKanbanBtnLabel || defaults.openKanbanBtnLabel
  const maximizeBtnTip = cardTranslations.maximizeBtnTip || defaults.maximizeBtnTip

  const {
    headerText = defaults.headerText,
    buttonLabel = defaults.buttonLabel,
    buttonTip = defaults.buttonTip,
  } = columnChooserTranslations

  const {
    moveToLeft = defaults.moveToLeft,
    moveToRight = defaults.moveToRight,
    moveToColumn = defaults.moveToColumn,
    cardWillMoveTo = defaults.cardWillMoveTo,
  } = t

  return {
    addCardButtonLabel,
    addTaskButtonLabel,
    addTodoButtonLabel,
    addTaskButtonTip,
    addTodoButtonTip,
    cardTitlePlaceholder,
    inputQueueLabel,
    backlogLabel,
    notStartedLabel,
    planningLabel,
    implementationLabel,
    reviewLabel,
    testingLabel,
    deploymentLabel,
    completedLabel,
    openKanbanBtnLabel,
    maximizeBtnTip,
    headerText,
    buttonLabel,
    buttonTip,
    moveToLeft,
    moveToRight,
    moveToColumn,
    cardWillMoveTo,
  }
}

const defaultTranslations = {
  addCardButtonLabel: "Add a new card",
  addTaskButtonLabel: "Add as task",
  addTodoButtonLabel: "Add as todo",
  addTaskButtonTip: "Adding a task will add a new task which will be visible in gantt chart and task list views",
  addTodoButtonTip:
    "Adding a todo will add a new todo which will be visible only in todo list view and it will not affect gantt chart",
  cardTitlePlaceholder: "Enter title here",
  inputQueueLabel: "Input queue",
  backlogLabel: "Backlog",
  notStartedLabel: "Not started",
  planningLabel: "Planning",
  implementationLabel: "Implementation",
  reviewLabel: "Review",
  testingLabel: "Testing",
  deploymentLabel: "Deployment",
  completedLabel: "Completed",
  openKanbanBtnLabel: "Open task kanban",
  maximizeBtnTip: "Open task in full screen",
  headerText: "Board column's visibility",
  buttonLabel: "Choose columns",
  buttonTip: "Open board's column chooser menu",
  moveToLeft: "Move to left",
  moveToRight: "Move to right",
  moveToColumn: "Move to column",
  cardWillMoveTo: "Card will move to",
}

type Translations = typeof defaultTranslations
