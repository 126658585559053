import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Box from "@material-ui/core/Box"
import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import ReminderList from "../../reminders/components/ReminderList"
import { isDate } from "lodash"
import { BaseNewReminderData, useGetRemindersQuery } from "../../reminders/api"
import { useReminderMutations } from "../../reminders/hooks/use-reminder-mutations"
import { guessReminderTime } from "../../reminders/utils/guess-reminder-time"
import { TodoViewModel } from "../api/todo"
import { ReminderViewModel } from "../../reminders/api/reminder"
import { makeStyles, Theme } from "@material-ui/core"
import { ChevronDown } from "react-feather"
import { useI18n } from "../../hooks"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TodoReminders = ({ todo }: TodoRemindersProps) => {
  const refStartDate = todo.dueDate ? new Date(todo.dueDate) : null
  const refEndDate = null
  const { createReminder } = useReminderMutations()
  const classes = useStyles()
  const { data: reminders = [], isLoading } = useGetRemindersQuery({ todo: todo.id }, queryOptions)
  const hasReminders = reminders.length > 0
  const { t } = useI18n("translation")

  const createNewTodoReminder = async () => {
    const guessedReminderTime = guessReminderTime(refStartDate, refEndDate)
    const reminderData: Omit<BaseNewReminderData, "triggerType"> = {
      entityId: todo.id,
      entityType: "todo",
      recipients: {
        roles: ["todoResponsible"],
        emails: [],
      },
    }

    const reminderPromise = isDate(guessedReminderTime)
      ? createReminder({
          ...reminderData,
          reminderTime: guessedReminderTime.toISOString(),
          reminderTimeDetails: undefined,
          triggerType: "absoluteTime",
        })
      : createReminder({
          ...reminderData,
          reminderTime: undefined,
          reminderTimeDetails: guessedReminderTime,
          triggerType: "relativeTime",
        })

    return (await reminderPromise) as ReminderViewModel
  }

  return (
    <Box className={classes.container}>
      <Accordion className={classes.accordion}>
        <AccordionSummary expandIcon={<ChevronDown color="black" />}>
          <Typography variant="body1">
            <strong>{t("notifications", "Notifications")}</strong>
            {hasReminders && (
              <Typography variant="subtitle1" component="span" className={classes.notificationCount}>
                {reminders.length > 9 ? "9+" : reminders.length}
              </Typography>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          {isLoading && <CircularProgress />}
          {!isLoading && (
            <ReminderList
              reminders={reminders}
              refStartDate={refStartDate}
              refEndDate={refEndDate}
              onCreateReminder={createNewTodoReminder}
              canCreateReminder={todo.canUpdate}
            />
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default TodoReminders

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  accordion: {
    boxShadow: "none",
    borderTop: `1px solid ${theme.palette.divider}`,
    borderBottom: `1px solid ${theme.palette.divider}`,
    "&.Mui-expanded": {
      borderBottom: 0,
    },
    "& .MuiAccordionSummary-root": {
      padding: 0,
      fontWeight: "bold",
    },
    "& .MuiAccordionDetails-root": {
      padding: 0,
    },
  },
  notificationCount: {
    marginLeft: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `1px 8px`,
    borderRadius: theme.spacing(2),
    fontWeight: 700,
    letterSpacing: 0.5,
    fontSize: "0.75rem",
  },
}))

type TodoRemindersProps = {
  todo: TodoViewModel
}
