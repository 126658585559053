import Typography from "@material-ui/core/Typography"
import { useTheme } from "@material-ui/core"
import { useI18n } from "../../hooks"
import React, { PropsWithChildren } from "react"
import NewTaskDialog from "./NewTaskDialog"

const tasksEmpty = "You don't have any tasks here yet."

const TaskEmptyMessage = ({ children }: PropsWithChildren<{}>) => {
  const theme = useTheme()
  const { translations } = useI18n("translation")
  const tasksEmptyMessage = translations.tasksEmpty || tasksEmpty

  const newTaskDialog = React.Children.toArray(children).find(
    (child) => React.isValidElement(child) && child.type === NewTaskDialog
  ) as React.ReactElement | undefined

  return (
    <div
      style={{
        marginTop: theme.spacing(3),
        padding: theme.spacing(3, 1),
        border: `1px dashed ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        textAlign: "center",
      }}
    >
      <Typography variant="subtitle1">{tasksEmptyMessage}</Typography>
      <br />
      {newTaskDialog || null}
    </div>
  )
}

export default TaskEmptyMessage
