import HolidayCard from "./HolidayCard"
import Typography from "@material-ui/core/Typography"
import NewHolidayDialog from "./NewHolidayDialog"
import { HolidayViewModel } from "../api/holiday"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { BaseNewHolidayData } from "../api"
import { useTranslations } from "../hooks/use-translations"

const HolidayCards = ({ holidays, onAddHoliday, canManageHolidays }: HolidayCardsProps) => {
  const classes = useStyles()
  const translations = useTranslations()

  if (holidays.length === 0) {
    return <HolidaysEmptyMessage onAddHoliday={onAddHoliday} canManageHolidays={canManageHolidays} />
  }

  return (
    <>
      <div className={classes.header}>
        <Typography variant="h6">{translations.holidaysLabel}</Typography>
        {canManageHolidays ? <NewHolidayDialog onAddHoliday={onAddHoliday} /> : null}
      </div>
      {holidays.map((holiday) => (
        <div className={classes.cardWrapper} key={holiday.id}>
          <HolidayCard key={holiday.id} holiday={holiday} canUpdate={canManageHolidays} canDelete={canManageHolidays} />
        </div>
      ))}
    </>
  )
}

const HolidaysEmptyMessage = ({
  onAddHoliday,
  canManageHolidays,
}: Pick<HolidayCardsProps, "onAddHoliday" | "canManageHolidays">) => {
  const classes = useStyles()
  const translations = useTranslations()

  return (
    <div className={classes.emptyMessage}>
      <Typography variant="body1">{translations.holidaysEmpty}</Typography>
      {canManageHolidays ? <NewHolidayDialog onAddHoliday={onAddHoliday} /> : null}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  emptyMessage: {
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    textAlign: "center",
    padding: theme.spacing(4),
    marginTop: theme.spacing(2),
    "& .MuiTypography-root": {
      marginBottom: theme.spacing(2),
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(0.5),
    margin: theme.spacing(1, 0),
  },
  cardWrapper: {
    marginBottom: theme.spacing(0.5),
  },
}))

export default HolidayCards

type HolidayCardsProps = {
  holidays: HolidayViewModel[]
  onAddHoliday: (holiday: BaseNewHolidayData) => void
  canManageHolidays: boolean
}
