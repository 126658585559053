import { MembershipViewModel } from "../memberships/api/membership"
import { InvitationStatus } from "../memberships/interfaces/membership-response"
import { MembershipRoleEnum } from "../types/memberships"
import { Translations } from "./hooks/use-translations"

export const getInvitationStatusText = (status: InvitationStatus, translations: Translations) => {
  if (status === "not_invited") return translations.notInvitedInvitationStatus
  if (status === "accepted") return translations.acceptedInvitationStatus
  if (status === "pending") return translations.pendingInvitationStatus
  if (status === "rejected") return translations.rejectedInvitationStatus
}

export const sortMembershipsByInvitationStatus = (memberships: MembershipViewModel[]) => {
  return memberships.sort((a, b) => {
    if (a.invitationStatus === "accepted") return -1
    if (b.invitationStatus === "accepted") return 1
    if (a.invitationStatus === "pending") return -1
    if (b.invitationStatus === "pending") return 1
    if (a.invitationStatus === "rejected") return -1
    if (b.invitationStatus === "rejected") return 1
    if (a.invitationStatus === "not_invited") return -1
    if (b.invitationStatus === "not_invited") return 1
    return 0
  })
}

export const getRoleTranslationKey = (role: MembershipRoleEnum) => {
  if (role === MembershipRoleEnum.orgOwner) return "ownerRoleText"
  if (role === MembershipRoleEnum.orgLimitedMember) return "limitedMemberRoleText"
  return "memberRoleText"
}
