import { useLocation } from "react-router-dom"
import { isKanbanRoute } from "./utils"
import BoardColumnChooser from "../../../boards/components/BoardColumnChooser"
import { ProjectViewModel } from "../../api/project"

const ProjectBoardColumnChooser = ({ project }: { project: ProjectViewModel }) => {
  const location = useLocation()
  const isKanban = isKanbanRoute(location.pathname)

  if (!isKanban) return null
  return <BoardColumnChooser boardId={project.id} boardType="project" buttonVariant="iconButton" />
}

export default ProjectBoardColumnChooser
