import { RouteComponentProps } from "react-router-dom"
import { useProject } from "../../../projects/hooks/use-project"
import { useGetTasksQuery } from "../../../tasks/api"
import CircularProgress from "@material-ui/core/CircularProgress"
import TaskTreeGrid from "../../../treegrid/task-tree-grid/TaskTreeGrid"
import React from "react"
import { ProjectRouteContext } from "./index"
import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"
import ErrorList from "../../../components/ErrorList"
import { useAppContext } from "../../../hooks/use-app-context"
import { useGetHolidaysQuery } from "../../../holidays/api"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectGanttRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const projectId = match.params.projectId
  const {
    project,
    bulkAddTasks,
    isLoading: isLoadingProject,
    isError: isErrorProject,
  } = useProject(projectId, queryOptions)
  const tasksQuery = { project: projectId, archived: "false" } as const
  const {
    data: tasks = [],
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetTasksQuery(tasksQuery, queryOptions)
  const context = React.useContext(ProjectRouteContext)
  const { appContext } = useAppContext()
  const appMainContext = appContext.mainContext
  const holidaysQuery =
    appMainContext?.type === "org" ? { orgId: appMainContext.id } : { userId: appMainContext?.id as string }
  const {
    data: holidays = [],
    isLoading: isLoadingHolidays,
    isError: isErrorHolidays,
  } = useGetHolidaysQuery(holidaysQuery, queryOptions)

  if (isLoadingProject || isLoadingTasks || isLoadingHolidays) return <CircularProgress />
  if (isErrorProject) return <ErrorList errors={[translations.fetchErrorProject]} />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />
  if (isErrorTasks) return <ErrorList errors={[translations.fetchErrorTasks]} />
  if (isErrorHolidays) return <ErrorList errors={[translations.fetchErrorHolidays]} />

  return (
    <TaskTreeGrid
      project={project}
      tasks={tasks}
      holidays={project.enableHolidays ? holidays : []}
      onAddTask={bulkAddTasks}
      showActualBar={context?.isActualBarShown}
      showBaselineBar={context?.isBaselineBarShown}
      isFullScreen={context?.isFullScreen}
      showGantt
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translations")
  const translations = t || ({} as StringMap)

  const {
    projectNotFoundError = defaults.projectNotFoundError,
    fetchErrorProject = defaults.fetchErrorProject,
    fetchErrorTasks = defaults.fetchErrorTasks,
    fetchErrorHolidays = defaults.fetchErrorHolidays,
  } = translations

  return {
    projectNotFoundError,
    fetchErrorProject,
    fetchErrorTasks,
    fetchErrorHolidays,
  }
}
const defaultTranslations = {
  projectNotFoundError: "Project not found",
  fetchErrorProject: "Failed to fetch project",
  fetchErrorTasks: "Failed to fetch tasks",
  fetchErrorHolidays: "Failed to fetch holidays",
}
type Translations = typeof defaultTranslations
type RouteParams = { projectId: string }
export default ProjectGanttRoute
