import * as actions from "./actions"
import { createSlice } from "@reduxjs/toolkit"
import {
  deleteContactCase,
  setContactCase,
  setCustomerCase,
  setCustomersCase,
  deleteOrganisationCase,
} from "./case-reducers"
import { ICustomerNormalized } from "../interfaces/customer-normalized"
import * as orgActions from "../../organisations/store/actions"

const initialState: CustomersState = {
  byIds: {},
  allIds: [],
}

const customersSlice = createSlice({
  name: "customers",
  initialState,
  reducers: {
    SetCustomer: setCustomerCase,
    SetCustomers: setCustomersCase,
  },
  extraReducers: ({ addCase }) => {
    addCase(actions.FetchCustomers.fulfilled, setCustomersCase)
    addCase(actions.FetchCustomerById.fulfilled, setCustomerCase)
    addCase(actions.AddCustomerContact.fulfilled, setContactCase)
    addCase(actions.ChangeCustomerDefaultContact.fulfilled, setCustomerCase)
    addCase(actions.CreateCustomer.fulfilled, setCustomerCase)
    addCase(actions.UpdateCustomerContact.fulfilled, setContactCase)
    addCase(actions.UpdateCustomerInfo.fulfilled, setCustomerCase)
    addCase(actions.UpdateCustomerStatus.fulfilled, setCustomerCase)
    addCase(actions.DeleteCustomerContact.fulfilled, deleteContactCase)
    addCase(orgActions.DeleteOrganisation.fulfilled, deleteOrganisationCase)
  },
})

export const { SetCustomer, SetCustomers } = customersSlice.actions
export default customersSlice.reducer
export * from "./actions"

export type CustomersState = {
  byIds: { [key: string]: ICustomerNormalized }
  allIds: string[]
}
