import {
  Button,
  makeStyles,
  Theme,
  Typography,
  Checkbox,
  FormControl,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  Divider,
  Paper,
} from "@material-ui/core"
import { ArrowLeft, ChevronDown, MinusSquare } from "react-feather"
import { useI18n } from "../../hooks"
import { TaskPermissionRecord } from "../api/task-permission-response"
import { ChangeEvent, useState } from "react"
import { concat, sortBy, without } from "lodash"
import MembersTable from "../../components/MembersTable"
import { COLOR_PRIMARY } from "../../constants"
import AbilitiesTable from "../../components/AbilitiesTable"
import { TaskViewModel } from "../api/task"
import { MembershipViewModel } from "../../memberships/api/membership"
import { useTaskMutations } from "../hooks/use-task-mutations"
import { useIsSmallScreen } from "../../hooks/use-is-small-screen"

const useTaskPermissionsManagerView = ({
  task,
  taskPermissions: permissions,
  memberships,
}: {
  task: TaskViewModel
  taskPermissions: TaskPermissionRecord[]
  memberships: MembershipViewModel[]
}) => {
  const taskActions = useTaskMutations()
  const [membersFilter, setMembersFilter] = useState<MembersFilterType>("all")
  const onChangeMembersFilter = (e: ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as MembersFilterType
    setMembersFilter(val)
    setSelectedMembers([])
  }
  const taskCreatorMembership = memberships.find((membership) => membership.userId === task.creatorId)
  const taskResourceIds = [...task.managers, ...task.participants].map(({ id }) => id)
  if (taskCreatorMembership) {
    taskResourceIds.push(taskCreatorMembership.userId)
  }
  const activeMemberRows = memberships
    .filter(($m) => !$m.isDeactivated)
    .map((membership) => ({
      id: membership.id,
      userId: membership.userId,
      initials: membership.userInitials,
      fullname: membership.fullname,
      email: membership.userEmail,
      disabled: membership.isOwner,
    }))
  const memberRowsToShow = sortBy(
    activeMemberRows.filter((row) => {
      const isResource = taskResourceIds.includes(row.id) || taskResourceIds.includes(row.userId)

      if (membersFilter === "resources") return isResource
      if (membersFilter === "nonResources") return !isResource
      return true
    }),
    "disabled"
  )
  const editableMembers = memberships.filter(
    (membership) => !membership.isOwner && memberRowsToShow.some((r) => r.id === membership.id)
  )
  const [selectedMembers, setSelectedMembers] = useState<string[]>([])
  const selectedMembersNames = memberships.filter((m) => selectedMembers.includes(m.id)).map((m) => m.fullname)
  const selectedCount = selectedMembers.length
  const editableMembersCount = editableMembers.length
  const selectAllChecked = !!selectedCount && selectedCount === editableMembersCount
  const selectAllIndeterminate = !!selectedCount && selectedCount !== editableMembersCount
  const selectAllDisabled = !editableMembersCount

  const onRowSelect = (id: string) => {
    if (selectedMembers.includes(id)) {
      setSelectedMembers(without(selectedMembers, id))
    } else {
      setSelectedMembers(concat(selectedMembers, id))
    }
  }
  const onSelectAll = () => {
    if (selectedCount === editableMembersCount) {
      setSelectedMembers([])
    } else {
      setSelectedMembers(editableMembers.map((membership) => membership.id))
    }
  }
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const openPermissionsDialog = () => {
    setDialogOpen(true)
  }
  const closePermissionsDialog = () => {
    setDialogOpen(false)
  }
  const onClickManagePermissionsButton = (id?: string) => {
    if (id) setSelectedMembers([id])
    openPermissionsDialog()
  }
  const groupedAbilities: any = groupAbilities(
    permissions.filter(($permission) => selectedMembers.includes($permission.membershipId))
  )

  const onUpdateAbilities = async ({
    abilitiesToAdd,
    abilitiesToRemove,
  }: {
    abilitiesToAdd?: string[]
    abilitiesToRemove?: string[]
  }) => {
    await taskActions.updatePermissions(task.id, {
      abilitiesToAllow: abilitiesToAdd,
      abilitiesToRestrict: abilitiesToRemove,
      membershipIds: selectedMembers,
    })
  }

  return {
    task,
    groupedAbilities,
    memberRowsToShow,
    membersFilter,
    selectedMembers,
    selectedMembersNames,
    selectAllChecked,
    selectAllDisabled,
    selectAllIndeterminate,
    dialogOpen,
    onRowSelect,
    onSelectAll,
    openPermissionsDialog,
    closePermissionsDialog,
    onChangeMembersFilter,
    onClickManagePermissionsButton,
    onUpdateAbilities,
  }
}

const TaskPermissionsManagerView = ({
  task,
  memberships,
  taskPermissions,
  navigateToTask,
}: TaskPermissionManagerViewProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const isSmallScreen = useIsSmallScreen()
  const {
    groupedAbilities,
    memberRowsToShow,
    membersFilter,
    selectedMembers,
    selectedMembersNames,
    selectAllChecked,
    selectAllDisabled,
    selectAllIndeterminate,
    dialogOpen,
    onRowSelect,
    onSelectAll,
    openPermissionsDialog,
    closePermissionsDialog,
    onChangeMembersFilter,
    onClickManagePermissionsButton,
    onUpdateAbilities,
  } = useTaskPermissionsManagerView({
    task,
    memberships,
    taskPermissions,
  })

  return (
    <div className={classes.container}>
      <div className={classes.pageHeader} data-test="page-header">
        <Button onClick={navigateToTask} startIcon={<ArrowLeft size={16} />}>
          {`${translations.backButtonLabel}: ${task?.title}`}
        </Button>
        <div className="headerRow">
          <Typography component="header" variant="h6">
            {translations.header}
          </Typography>
        </div>
        <Typography className="subheader">{translations.subheader}</Typography>
      </div>
      {task.isOrgTask && (
        <>
          {isSmallScreen ? (
            <MemberCards members={memberRowsToShow} onClickManagePermissions={onClickManagePermissionsButton} />
          ) : (
            <>
              {" "}
              <div className={classes.toolbar}>
                <div className="left">
                  <Checkbox
                    checked={selectAllChecked}
                    color="primary"
                    indeterminate={selectAllIndeterminate}
                    indeterminateIcon={<MinusSquare color={COLOR_PRIMARY} />}
                    className="checkbox"
                    onChange={onSelectAll}
                    disabled={selectAllDisabled}
                  />
                  {!selectedMembers.length && (
                    <Typography variant="subtitle2" component="span">
                      {translations.selectAllCheckboxLabel}
                    </Typography>
                  )}
                  {!!selectedMembers.length && (
                    <Button color="primary" variant="contained" size="small" onClick={openPermissionsDialog}>
                      {translations.managePermissionsBtnLabel}
                    </Button>
                  )}
                </div>
                <div className="right">
                  <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                      value={membersFilter}
                      onChange={onChangeMembersFilter}
                      IconComponent={ChevronDown}
                      autoWidth
                    >
                      <MenuItem value="all">{translations.allMembersLabel}</MenuItem>
                      <MenuItem value="resources">{translations.resourcesLabel}</MenuItem>
                      <MenuItem value="nonResources">{translations.nonResourcesLabel}</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <MembersTable
                memberRows={memberRowsToShow}
                selectedMemberships={selectedMembers}
                onManagePermissionsClick={onClickManagePermissionsButton}
                onRowSelect={onRowSelect}
              />
            </>
          )}

          <Dialog open={dialogOpen} onClose={closePermissionsDialog} maxWidth="md">
            <div className={classes.dialogContentWrapper}>
              <DialogTitle>
                {translations.dialogTitle.replace("{{members}}", selectedMembersNames.join(", "))}
              </DialogTitle>
              <Divider />
              <AbilitiesTable groupedAbilities={groupedAbilities} onUpdateAbilities={onUpdateAbilities} />
            </div>
          </Dialog>
        </>
      )}
    </div>
  )
}

const MemberCards = ({
  members,
  onClickManagePermissions,
}: {
  members: MemberRow[]
  onClickManagePermissions: (id: string) => void
}) => {
  const translations = useTranslations()
  return (
    <>
      {members.map((member) => (
        <Paper key={member.id} style={{ padding: 16, marginBottom: 8 }} variant="outlined">
          <Typography variant="h6">{member.fullname}</Typography>
          <Typography variant="body2" color="textSecondary">
            {member.email}
          </Typography>
          <Button
            style={{ marginLeft: -8, marginTop: 16 }}
            color="primary"
            onClick={() => onClickManagePermissions(member.id)}
          >
            {translations.managePermissionsBtnLabel}
          </Button>
        </Paper>
      ))}
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: "auto",
    maxWidth: 1040,
  },
  pageHeader: {
    padding: theme.spacing(2),
    "& .headerRow": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: theme.spacing(1, 0),
    },
    "& .subheader": {
      textAlign: "justify",
    },
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "white",
    padding: theme.spacing(1.5),
    borderTopLeftRadius: theme.spacing(),
    borderTopRightRadius: theme.spacing(),
    border: `1px solid ${theme.palette.divider}`,
    borderBottomColor: "transparent",
    "& .checkbox": {
      marginRight: 20,
    },
  },
  formControl: {
    "& .MuiOutlinedInput-root": {
      background: theme.palette.grey[200],
    },
    "& .MuiSelect-icon": {
      width: 20,
      height: 20,
      color: theme.palette.common.black,
      top: "calc(50% - 8px)",
    },
  },
  dialogContentWrapper: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(80),
    },
  },
}))

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n("task")
  const translations = (t.permissionsPage || {}) as {
    [k: string]: string
  }

  const {
    header = defaults.header,
    backButtonLabel = defaults.backButtonLabel,
    subheader = defaults.subheader,
    selectAllCheckboxLabel = defaults.selectAllCheckboxLabel,
    managePermissionsBtnLabel = defaults.managePermissionsBtnLabel,
    allMembersLabel = defaults.allMembersLabel,
    resourcesLabel = defaults.resourcesLabel,
    nonResourcesLabel = defaults.nonResourcesLabel,
    dialogTitle = defaults.dialogTitle,
  } = translations

  return {
    header,
    backButtonLabel,
    subheader,
    selectAllCheckboxLabel,
    managePermissionsBtnLabel,
    allMembersLabel,
    resourcesLabel,
    nonResourcesLabel,
    dialogTitle,
  }
}

const defaultTranslations = {
  header: `Manage Permissions`,
  backButtonLabel: "Back to task",
  subheader: `You can manage permissions for task resources from here.
    Also you can give permission to to perform certain actions 
    to other members of the organisation who is not a resource in this task.`,
  selectAllCheckboxLabel: "Select all",
  managePermissionsBtnLabel: "Manage permissions",
  allMembersLabel: "All members",
  resourcesLabel: "Project resources",
  nonResourcesLabel: "Not project resources",
  dialogTitle: "Managing permissions of {{members}}",
}

const groupAbilities = (
  permissions: TaskPermissionRecord[]
): Array<{ resource: string; abilities: { [key: string]: boolean }[] }> => {
  return [
    {
      resource: "task",
      abilities: permissions.map(($permission) => ({
        ReadTask: $permission.canRead,
        CreateSubTasks: $permission.canCreate,
        DeleteTask: $permission.canDelete,
        UpdateTaskDetails: $permission.canUpdateDetails,
        UpdateTaskStatus: $permission.canUpdateStatus,
        UpdateTaskManagers: $permission.canUpdateManagers,
        UpdateTaskParticipants: $permission.canUpdateParticipants,
        UpdateTaskWorkspaces: $permission.canUpdateWorkspaces,
        UpdateTaskSuppliers: $permission.canUpdateSuppliers,
      })),
    },
    {
      resource: "calendarEvent",
      abilities: permissions.map(($permission) => ({
        CreateTaskCalendarEvents: $permission.canCreateTaskCalendarEvents,
      })),
    },
    {
      resource: "todo",
      abilities: permissions.map(($permission) => ({
        CreateTaskTodos: $permission.canCreateTaskTodos,
      })),
    },
  ]
}

type TaskPermissionManagerViewProps = {
  task: TaskViewModel
  taskPermissions: TaskPermissionRecord[]
  memberships: MembershipViewModel[]
  navigateToTask: () => void
}

type MembersFilterType = "all" | "resources" | "nonResources"
export default TaskPermissionsManagerView

type MemberRow = {
  id: string
  userId: string
  initials: string
  fullname: string
  email: string
  disabled?: boolean
}
