import React from "react"
import {
  Button,
  FormControl,
  Select,
  TableContainer,
  TableHead,
  TableCell,
  Typography,
  makeStyles,
  Theme,
  MenuItem,
  Paper,
  Table,
  TableRow,
  Checkbox,
  TableBody,
  Grid,
  Toolbar as MuiToolbar,
  Link as MuiLink,
  alpha,
} from "@material-ui/core"
import { StringMap } from "i18next"
import { uniq, without } from "lodash"
import { Link } from "react-router-dom"
import { useConfirmDialog } from "../../components/ConfirmDialog"
import { useI18n } from "../../hooks"
import { paths } from "../../paths"
import { SupplierStatusEnum } from "../../types/supplier"
import { useOrgSuppliers } from "../../suppliers/hooks/use-org-suppliers"
import { useSnackbar } from "notistack"
import doublet from "../../utils/doublet"
import { useUrlWithContext } from "../../hooks/use-url-with-context"

const useOrgSuppliersTable = (orgId: string) => {
  /** Suppliers related code */
  const { suppliers, activeSuppliers, inactiveSuppliers, canUpdateAnySupplier, updateSupplierStatus } =
    useOrgSuppliers(orgId)
  const [selectedRows, setSelectedRows] = React.useState<string[]>([])
  const [actionState, setActionState] = React.useState<ActionState>("idle")
  const [filter, setFilter] = React.useState<SupplierFilter>("active")
  const { enqueueSnackbar } = useSnackbar()
  const translations = useTranslations(defaultTranslations)

  const selectedRowCount = selectedRows.length
  const isBusy = actionState === "busy"
  const showActiveSuppliers = filter === "active"
  const showInactiveSuppliers = filter === "inactive"
  const showAllSuppliers = filter === "all"
  const formattedActivateBtnLabel = translations.activateBtnLabel.replace("{{count}}", `${selectedRowCount}`)
  const formattedDeactivateBtnLabel = translations.deactivateBtnLabel.replace("{{count}}", `${selectedRowCount}`)
  const activateOrDeactivateBtnLabel = showActiveSuppliers ? formattedDeactivateBtnLabel : formattedActivateBtnLabel
  const suppliersToShow = showActiveSuppliers ? activeSuppliers : showInactiveSuppliers ? inactiveSuppliers : suppliers
  const idsOfSuppliersToShow = suppliersToShow.map(({ id }) => id)
  const enableRowSelection = canUpdateAnySupplier && Boolean(suppliersToShow.length) && !showAllSuppliers
  const disableActivateOrDeactivateBtn = !selectedRowCount || isBusy

  const onConfirmActivateSuppliers = async () => {
    setActionState("busy")
    for (let supplierId of selectedRows) {
      const updateData = { status: SupplierStatusEnum.active }
      const [error] = await doublet(updateSupplierStatus, supplierId, updateData)
      if (error) {
        const defaultErrorMessage = "Failed to activate supplier"
        const message = error.message || error || defaultErrorMessage
        enqueueSnackbar(message, { variant: "error" })
      } else {
        const message = `${selectedRowCount} suppliers activated`
        enqueueSnackbar(message, { variant: "success" })
      }
    }
    setSelectedRows([])
    setActionState("idle")
  }

  const onConfirmDeactivateSuppliers = async () => {
    setActionState("busy")
    for (let supplierId of selectedRows) {
      const updateData = { status: SupplierStatusEnum.inactive }
      const [error] = await doublet(updateSupplierStatus, supplierId, updateData)
      if (error) {
        const defaultErrorMessage = "Failed to deactivate supplier"
        const message = error.message || error || defaultErrorMessage
        enqueueSnackbar(message, { variant: "error" })
      } else {
        const message = `${selectedRowCount} suppliers deactivated`
        enqueueSnackbar(message, { variant: "success" })
      }
    }
    setSelectedRows([])
    setActionState("idle")
  }

  const { confirm: confirmActivateSuppliers } = useConfirmDialog({
    onConfirm: onConfirmActivateSuppliers,
    primaryActionButtonLabel: formattedActivateBtnLabel,
    title: translations.activateDialogTitle,
    text: translations.activateDialogText.replace("{{count}}", `${selectedRowCount}`),
  })

  const { confirm: confirmDeactivateSuppliers } = useConfirmDialog({
    onConfirm: onConfirmDeactivateSuppliers,
    primaryActionButtonLabel: formattedDeactivateBtnLabel,
    title: translations.deactivateDialogTitle,
    text: translations.deactivateDialogText.replace("{{count}}", `${selectedRowCount}`),
  })

  const onChangeFilter = (filter: SupplierFilter) => {
    setSelectedRows([])
    setFilter(filter)
  }

  const onClickActivateOrDeactivateBtn = showActiveSuppliers ? confirmDeactivateSuppliers : confirmActivateSuppliers

  /****** */

  return {
    selectedRows,
    setSelectedRows,
    selectedRowCount,
    filter,
    onChangeFilter,
    isBusy,
    activateOrDeactivateBtnLabel,
    idsOfSuppliersToShow,
    suppliersToShow,
    onClickActivateOrDeactivateBtn,
    enableRowSelection,
    disableActivateOrDeactivateBtn,
  }
}

const OrgSuppliersTable = ({ orgId }: { orgId: string }) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { createPathWithGivenContext } = useUrlWithContext()

  const {
    selectedRows,
    setSelectedRows,
    filter,
    onChangeFilter,
    activateOrDeactivateBtnLabel,
    idsOfSuppliersToShow,
    suppliersToShow,
    onClickActivateOrDeactivateBtn,
    enableRowSelection,
    disableActivateOrDeactivateBtn,
  } = useOrgSuppliersTable(orgId)

  return (
    <div className={classes.tableRoot}>
      {/** Toolbar  */}
      <MuiToolbar variant="dense" disableGutters>
        <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item>
            {enableRowSelection && (
              <Button size="small" onClick={onClickActivateOrDeactivateBtn} disabled={disableActivateOrDeactivateBtn}>
                {activateOrDeactivateBtnLabel}
              </Button>
            )}
          </Grid>
          <Grid item>
            <FormControl>
              <Select
                variant="outlined"
                value={filter}
                onChange={(e) => onChangeFilter(e.target.value as SupplierFilter)}
              >
                <MenuItem value={"all"}>{translations.allSuppliersOption}</MenuItem>
                <MenuItem value={"active"}>{translations.activeSuppliersOption}</MenuItem>
                <MenuItem value={"inactive"}>{translations.inactiveSuppliersOption}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MuiToolbar>
      {/** Table  */}
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="suppliers table">
          <TableHead>
            <TableRow>
              {enableRowSelection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRows([...idsOfSuppliersToShow])
                      } else {
                        setSelectedRows([])
                      }
                    }}
                    checked={Boolean(selectedRows.length && selectedRows.length === idsOfSuppliersToShow.length)}
                    indeterminate={Boolean(selectedRows.length && selectedRows.length !== idsOfSuppliersToShow.length)}
                  />
                </TableCell>
              )}
              <TableCell padding="checkbox">{translations.supplierNameHeader}</TableCell>
              <TableCell>{translations.supplierNumberHeader}</TableCell>
              <TableCell>{translations.supplierLanguageHeader}</TableCell>
              <TableCell>{translations.supplierContactHeader}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {suppliersToShow.map(($supplier) => (
              <TableRow key={$supplier.id}>
                {enableRowSelection && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={selectedRows.includes($supplier.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRows((prev) => uniq([...prev, $supplier.id]))
                        } else {
                          setSelectedRows((prev) => without(prev, $supplier.id))
                        }
                      }}
                    />
                  </TableCell>
                )}
                <TableCell padding="checkbox">
                  {$supplier.homePage ? (
                    <MuiLink
                      component={Link}
                      to={createPathWithGivenContext({
                        path: paths.home(),
                        mainContextId: orgId,
                        subContextId: $supplier.id,
                      })}
                    >
                      {$supplier.name}
                    </MuiLink>
                  ) : (
                    $supplier.name
                  )}
                </TableCell>
                <TableCell>{$supplier.supplierNumber}</TableCell>
                <TableCell>{$supplier.language}</TableCell>
                <TableCell>
                  {$supplier.defaultContact && $supplier.homePage ? (
                    <MuiLink
                      component={Link}
                      to={createPathWithGivenContext({
                        path: `${$supplier.homePage}/settings?tab=contact&contact=${$supplier.defaultContact.id}`,
                        mainContextId: orgId,
                        subContextId: $supplier.id,
                      })}
                    >
                      {`${$supplier.defaultContact.firstname} ${$supplier.defaultContact.lastname}`}
                    </MuiLink>
                  ) : $supplier.defaultContact ? (
                    `${$supplier.defaultContact.firstname} ${$supplier.defaultContact.lastname}`
                  ) : (
                    "-"
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!idsOfSuppliersToShow.length && (
          <Typography className="noData" color="textSecondary" variant="subtitle1">
            {translations.noSuppliersText}
          </Typography>
        )}
      </TableContainer>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(0, 2),
    maxWidth: 1440,
    margin: "auto",
  },
  header: {
    padding: theme.spacing(2, 0),
  },
  tableRoot: {
    maxWidth: 1440,
    borderRadius: 7,
    padding: theme.spacing(1, 0),
    width: "100%",
    background: theme.palette.common.white,
    "& .MuiToolbar-root": {
      padding: theme.spacing(0, 2),
      "& .MuiButton-root": {
        marginRight: theme.spacing(),
        transition: theme.transitions.create("background"),
        background: alpha(theme.palette.primary.main, 0.05),
        "&:hover": {
          background: alpha(theme.palette.primary.main, 0.1),
        },
      },
      "& .MuiSelect-outlined": {
        padding: "6px 9px",
        paddingRight: 32,
        fontSize: 14,
      },
    },
    "& .MuiTableContainer-root": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      "& .MuiTableRow-root th": {
        textTransform: "uppercase",
        fontSize: 12,
        color: theme.palette.text.secondary,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      "& .MuiTableCell-paddingCheckbox": {
        padding: theme.spacing(0.5),
        width: "auto",
      },
      "& .noData": {
        textAlign: "center",
        padding: 20,
      },
    },
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("supplier")
  const translations = (t?.suppliersPage || {}) as StringMap

  const {
    deactivateBtnLabel = defaults.deactivateBtnLabel,
    deactivateDialogTitle = defaults.deactivateDialogTitle,
    deactivateDialogText = defaults.deactivateDialogText,
    activateBtnLabel = defaults.activateBtnLabel,
    activateDialogTitle = defaults.activateDialogTitle,
    activateDialogText = defaults.activateDialogText,
    allSuppliersOption = defaults.allSuppliersOption,
    activeSuppliersOption = defaults.activeSuppliersOption,
    inactiveSuppliersOption = defaults.inactiveSuppliersOption,
    supplierNameHeader = defaults.supplierNameHeader,
    supplierNumberHeader = defaults.supplierNumberHeader,
    supplierLanguageHeader = defaults.supplierLanguageHeader,
    supplierContactHeader = defaults.supplierContactHeader,
    noSuppliersText = defaults.noSuppliersText,
  } = translations

  return {
    deactivateBtnLabel,
    deactivateDialogTitle,
    deactivateDialogText,
    activateBtnLabel,
    activateDialogTitle,
    activateDialogText,
    allSuppliersOption,
    activeSuppliersOption,
    inactiveSuppliersOption,
    supplierNameHeader,
    supplierNumberHeader,
    supplierLanguageHeader,
    supplierContactHeader,
    noSuppliersText,
  }
}

const defaultTranslations = {
  deactivateBtnLabel: "Deactivate selected suppliers",
  deactivateDialogTitle: "Deactivate suppliers",
  deactivateDialogText: "Are you sure you want to deactivate {{count}} suppliers?",
  activateBtnLabel: "Activate selected suppliers",
  activateDialogTitle: "Activate suppliers",
  activateDialogText: "Are you sure you want to activate {{count}} suppliers?",
  allSuppliersOption: "All suppliers",
  activeSuppliersOption: "Active suppliers",
  inactiveSuppliersOption: "Inactive suppliers",
  supplierNameHeader: "Supplier name",
  supplierNumberHeader: "Supplier number",
  supplierLanguageHeader: "Language",
  supplierContactHeader: "Contact",
  noSuppliersText: "No suppliers",
}

type SupplierFilter = "all" | "active" | "inactive"
type ActionState = "idle" | "busy"
type Translations = typeof defaultTranslations

export default OrgSuppliersTable
