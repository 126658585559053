import React from "react"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Select from "@material-ui/core/Select"
import { ArrowLeft, ArrowRight, ExternalLink, MoreVertical } from "react-feather"
import { Board } from "../entities/board"
import { useOnMoveCard } from "../hooks/use-on-move-card"
import { BoardPopulated, CardPopulated, getBoardDataFromPopulatedBoard } from "../api/board"
import { makeStyles, Theme } from "@material-ui/core"
import { Link } from "react-router-dom"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { paths } from "../../paths"
import { useTranslations } from "../hooks/use-translations"

const BoardCardMenu = ({ board, card }: BoardCardProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const [menuAncohorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null)
  const { onMoveCard } = useOnMoveCard()
  const { createPathWithContext } = useUrlWithContext()

  const boardInstance = new Board(getBoardDataFromPopulatedBoard(board))
  const visibleColumns = boardInstance.getVisibleColumns()
  const currentColumnIndex = boardInstance.getCardById(card.id)?.column as number
  const currentColumnId = boardInstance.getColumnByPosition(currentColumnIndex)?.id

  const nextVisibleColumn = boardInstance.getNextVisibleColumn(currentColumnIndex)
  const prevVisibleColumn = boardInstance.getPrevVisibleColumn(currentColumnIndex)
  const isMoveToLeftDisabled = prevVisibleColumn === null
  const isMoveToRightDisabled = nextVisibleColumn === null
  const taskKanbanPath = createPathWithContext(paths.taskKanban(card.id))

  const onMoveLeft = () => {
    if (!currentColumnId || !prevVisibleColumn) return

    onMoveCard({
      board: board,
      position: prevVisibleColumn.getNextCardPosition(),
      boardId: board.id,
      cardId: card.id,
      columnId: currentColumnId,
      destColumnId: prevVisibleColumn.id,
    })
  }

  const onMoveRight = () => {
    if (!currentColumnId || !nextVisibleColumn) return

    onMoveCard({
      board: board,
      position: nextVisibleColumn.getNextCardPosition(),
      boardId: board.id,
      cardId: card.id,
      columnId: currentColumnId,
      destColumnId: nextVisibleColumn.id,
    })
  }

  return (
    <div
      className={classes.container}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <IconButton className={classes.menuIcon} onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
        <MoreVertical size={16} />
      </IconButton>
      <Menu
        anchorEl={menuAncohorEl}
        open={Boolean(menuAncohorEl)}
        onClose={() => setMenuAnchorEl(null)}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {card.type === "task" ? (
          <MenuItem divider button className={classes.openKanbanButton} component={Link} to={taskKanbanPath}>
            <ListItemIcon>
              <ExternalLink size={20} />
            </ListItemIcon>
            <ListItemText primary={translations.openKanbanBtnLabel} />
          </MenuItem>
        ) : null}
        <MenuItem
          disabled={isMoveToRightDisabled}
          onClick={() => {
            onMoveRight()
            setMenuAnchorEl(null)
          }}
        >
          <ListItemIcon>
            <ArrowRight className={classes.arrowIcon} />
          </ListItemIcon>
          <ListItemText
            primary={translations.moveToRight}
            secondary={nextVisibleColumn ? `${translations.cardWillMoveTo} ${nextVisibleColumn.getName()}` : undefined}
          />
        </MenuItem>
        <MenuItem
          disabled={isMoveToLeftDisabled}
          onClick={() => {
            onMoveLeft()
            setMenuAnchorEl(null)
          }}
        >
          <ListItemIcon>
            <ArrowLeft className={classes.arrowIcon} />
          </ListItemIcon>
          <ListItemText
            primary={translations.moveToLeft}
            secondary={prevVisibleColumn ? `${translations.cardWillMoveTo} ${prevVisibleColumn.getName()}` : undefined}
          />
        </MenuItem>
        {visibleColumns.length > 1 && currentColumnId && (
          <MenuItem button={false}>
            <ListItemIcon>{null}</ListItemIcon>
            <ListItemText
              primary={translations.moveToColumn}
              secondary={
                <Select
                  className={classes.select}
                  value={currentColumnId}
                  variant="outlined"
                  fullWidth
                  onChange={(e) => {
                    const destColumnId = e.target.value as string
                    const destColumn = boardInstance.getColumnById(destColumnId)
                    if (!destColumn || !destColumn.isVisible) return

                    onMoveCard({
                      board: board,
                      position: destColumn.getNextCardPosition(),
                      boardId: board.id,
                      cardId: card.id,
                      columnId: currentColumnId,
                      destColumnId,
                    })

                    setMenuAnchorEl(null)
                  }}
                >
                  {visibleColumns.map((col) => (
                    <MenuItem key={col.id} value={col.id}>
                      {col.getName()}
                    </MenuItem>
                  ))}
                </Select>
              }
            />
          </MenuItem>
        )}
      </Menu>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: "relative",
  },
  openKanbanButton: {
    color: theme.palette.primary.main,
    "& .MuiListItemIcon-root": {
      color: theme.palette.primary.main,
    },
  },
  arrowIcon: {
    color: theme.palette.common.black,
    width: 20,
    height: 20,
  },
  menuIcon: {
    position: "absolute",
    right: 0,
    top: 8,
    zIndex: 9,
  },
  select: {
    marginTop: theme.spacing(1),
    "& .MuiSelect-root": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
}))

export default BoardCardMenu

type BoardCardProps = {
  board: BoardPopulated
  card: CardPopulated
}
