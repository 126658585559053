import { Box, Checkbox, FormControlLabel, FormGroup, makeStyles, Paper } from "@material-ui/core"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"

const EnableHolidaysWidget = ({
  enableHolidays = false,
  isOrgProject = false,
  onEnableHolidayChange = () => {},
  disabled = false,
}: EnableHolidaysWidgetProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const label = isOrgProject ? translations.enableOrgHolidayText : translations.enableUserHolidaysText

  return (
    <Paper elevation={0}>
      <Box p={4}>
        <FormGroup onChange={() => onEnableHolidayChange({ enableHolidays: !enableHolidays })}>
          <FormControlLabel
            control={<Checkbox checked={enableHolidays} className={classes.checkFilledIcon} />}
            label={label}
            disabled={disabled}
          />
        </FormGroup>
      </Box>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  checkFilledIcon: {
    "& .MuiSvgIcon-root": {
      fontSize: 28,
    },
    "&.Mui-checked": {
      color: theme.palette.primary.main,
      stroke: theme.palette.common.white,
      borderRadius: 3,
    },
  },
}))

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n("translation")
  const translations = (t || {}) as StringMap

  const {
    enableUserHolidaysText = defaults.enableUserHolidaysText,
    enableOrgHolidayText = defaults.enableOrgHolidayText,
  } = translations

  return { enableUserHolidaysText, enableOrgHolidayText }
}

const defaultTranslations = {
  enableUserHolidaysText: "Use holidays from your personal settings",
  enableOrgHolidayText: "Use holidays from this organization settings",
}

type EnableHolidaysWidgetProps = {
  enableHolidays?: boolean
  isOrgProject?: boolean
  onEnableHolidayChange?: ({ enableHolidays }: { enableHolidays: boolean }) => void
  disabled?: boolean
}

export default EnableHolidaysWidget
