import React from "react"
import FormikInput from "../components/FormikInput"
import ErrorFallback from "../components/ErrorFallback"
import ButtonLink from "../components/ButtonLink"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import InputLabel from "@material-ui/core/FormLabel"
import Stack from "../components/Stack"
import { Form, Formik, FormikHelpers } from "formik"
import { useErrorHandler } from "react-error-boundary"
import { useI18n, useNetworkStatus, useRouter } from "../hooks"
import { ErrorBoundary } from "react-error-boundary"
import { Eye, EyeOff } from "react-feather"
import { useAppDispatch } from "../store"
import { ResetPassword } from "../users/store/actions"
import { Alert, AlertTitle } from "@material-ui/lab"
import { paths } from "../paths"
import { useZodSchemas } from "../hooks/use-zod-schemas"
import { z } from "zod"
import { createFormikValidate } from "../utils/formik"
import { useTurnstile } from "../hooks/use-turnstile"
import Turnstile from "../components/Turnstile"
import { COLOR_ERROR } from "../constants"

const ResetPasswordRoute = () => {
  const translations = useTranslations()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Stack spacing={3}>
        <Typography variant="h4">{translations.passwordResetPageHeader}</Typography>
        <ResetPasswordForm />
      </Stack>
    </ErrorBoundary>
  )
}
const ResetPasswordForm = () => {
  const turnstile = useTurnstile()
  const translations = useTranslations()
  const handleError = useErrorHandler()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 15000 })
  const [showPassword, setShowPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)
  const { token } = router.query as { token: string }
  const { ResetPasswordFormSchema } = useSchemas()

  const { setStatus, isRejected, isIdle, isPending, isFulfilled } = networkStatus

  const handleSubmit = async (
    { password }: ResetPasswordFormData,
    { setSubmitting, resetForm }: FormikHelpers<ResetPasswordFormData>
  ) => {
    const isTurnstileVerified = await turnstile.verifyToken()
    if (!isTurnstileVerified) return

    try {
      setStatus("pending")
      const actionResult = await dispatch(ResetPassword({ password, token }))
      const success = actionResult.meta.requestStatus === "fulfilled"
      if (!success) return setStatus("rejected")
      if (success) setStatus("fulfilled")
      setSubmitting(false)
      resetForm()
    } catch (error: any) {
      handleError(error)
    }
  }

  const formikProps = {
    initialValues: { password: "", confirmPassword: "" },
    validate: createFormikValidate(ResetPasswordFormSchema),
    onSubmit: handleSubmit,
  }

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors }) => {
        const shouldDisableInput = isPending()
        const shouldDisableButton = Boolean(
          values.password === "" ||
            values.confirmPassword === "" ||
            errors.password ||
            errors.confirmPassword ||
            !isIdle() ||
            !turnstile.isSolved
        )

        let alertMessage
        let title
        let severity: "success" | "error" = "success"

        if (isRejected()) {
          alertMessage = translations.resetPasswordFailedMessage
          title = translations.error
          severity = "error"
        }

        if (isFulfilled()) {
          alertMessage = translations.resetPasswordSuccessMessage
          title = translations.success
          severity = "success"
        }

        return (
          <Form>
            <Stack>
              <div>
                <InputLabel htmlFor="password"> {`${translations.password}:`} </InputLabel>
                <FormikInput
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder={translations.newPasswordPlaceholder}
                  variant="outlined"
                  disabled={shouldDisableInput}
                  InputProps={{
                    endAdornment: (
                      <PasswordInputEndAdornment
                        showPassword={showPassword}
                        toggleShowPassword={() => setShowPassword((v) => !v)}
                      />
                    ),
                  }}
                  fullWidth
                />
              </div>
              <div>
                <InputLabel htmlFor="confirmPassword"> {`${translations.confirmPassword}:`} </InputLabel>
                <FormikInput
                  id="confirmPassword"
                  name="confirmPassword"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder={translations.confirmPasswordPlaceholder}
                  variant="outlined"
                  disabled={shouldDisableInput}
                  InputProps={{
                    endAdornment: (
                      <PasswordInputEndAdornment
                        showPassword={showConfirmPassword}
                        toggleShowPassword={() => setShowConfirmPassword((v) => !v)}
                      />
                    ),
                  }}
                  fullWidth
                />
              </div>
              <div>
                {alertMessage && (
                  <Alert severity={severity}>
                    <AlertTitle>{title}</AlertTitle>
                    {alertMessage}
                  </Alert>
                )}
              </div>
              <div>
                <p style={{ color: COLOR_ERROR }}>{turnstile.error}</p>
                <Turnstile {...turnstile.props} />
              </div>
              <Stack direction="row" packed>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={shouldDisableButton}
                  startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                >
                  {isSubmitting ? translations.resettingPassword : translations.resetPassword}
                </Button>
                <ButtonLink to={paths.login()}>{translations.backToLogin}</ButtonLink>
              </Stack>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

const PasswordInputEndAdornment = ({ showPassword, toggleShowPassword }: PasswordInputEndAdornmentProps) => {
  return (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={toggleShowPassword}
        onMouseDown={(e) => e.preventDefault()}
      >
        {showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
      </IconButton>
    </InputAdornment>
  )
}

const useSchemas = () => {
  const translations = useTranslations()
  const { ZodPassword } = useZodSchemas()

  const passwordMismatchError = translations.passwordMismatchError

  const ResetPasswordFormSchema = z
    .object({ password: ZodPassword, confirmPassword: ZodPassword })
    .refine(({ password, confirmPassword }) => password === confirmPassword, { message: passwordMismatchError })

  return { ResetPasswordFormSchema }
}

type ResetPasswordFormData = {
  password: string
  confirmPassword: string
}

type PasswordInputEndAdornmentProps = {
  showPassword: boolean
  toggleShowPassword: () => void
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")

  const {
    passwordResetPageHeader = defaults.passwordResetPageHeader,
    password = defaults.password,
    newPasswordPlaceholder = defaults.newPasswordPlaceholder,
    confirmPassword = defaults.confirmPassword,
    confirmPasswordPlaceholder = defaults.confirmPasswordPlaceholder,
    resetPasswordFailedMessage = defaults.resetPasswordFailedMessage,
    error = defaults.error,
    resetPasswordSuccessMessage = defaults.resetPasswordSuccessMessage,
    success = defaults.success,
    resettingPassword = defaults.resettingPassword,
    resetPassword = defaults.resetPassword,
    backToLogin = defaults.backToLogin,
    passwordMismatchError = defaults.passwordMismatchError,
  } = t

  return {
    passwordResetPageHeader,
    password,
    newPasswordPlaceholder,
    confirmPassword,
    confirmPasswordPlaceholder,
    resetPasswordFailedMessage,
    error,
    resetPasswordSuccessMessage,
    success,
    resettingPassword,
    resetPassword,
    backToLogin,
    passwordMismatchError,
  }
}

const defaultTranslations = {
  passwordResetPageHeader: "You can now create new password to access your StarBrix account",
  password: "Password",
  newPasswordPlaceholder: "Enter a new password",
  confirmPassword: "Confirm password",
  confirmPasswordPlaceholder: "Enter the new password again",
  resetPasswordFailedMessage: "Failed to reset you password!",
  error: "Error",
  resetPasswordSuccessMessage: "You can now use your new password to login to your account.",
  success: "Success",
  resettingPassword: "Resetting password",
  resetPassword: "Reset password",
  backToLogin: "Back to login",
  passwordMismatchError: "Passwords do not match",
}
type Translations = typeof defaultTranslations

export default ResetPasswordRoute
