/* eslint-disable no-console */
import React from "react"

import { useSnackbar } from "notistack"
import { useAuthUser } from "./use-auth-user"

export const useConfirmDeleteAccount = ({ deleteErrorMessage }: Params) => {
  const { enqueueSnackbar } = useSnackbar()
  const { user, onDeleteAccount } = useAuthUser()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [status, setStatus] = React.useState<Status>("idle")
  const [confirmationText, setConfirmationText] = React.useState("")
  const [confirmPassword, setConfirmPassword] = React.useState("")
  const email = user?.email || ""

  const isIdle = status === "idle"
  const isDeleting = status === "pending"
  const disableDeletion = !isIdle || isDeleting || confirmationText !== "delete my account" || !confirmPassword
  const closeDialog = () => {
    setDialogOpen(false)
    setConfirmationText("")
    setConfirmPassword("")
  }
  const openDialog = () => setDialogOpen(true)

  const confirmAndCloseDialog = async () => {
    console.log("Deleting user account")
    setStatus("pending")
    setConfirmationText("")
    setConfirmPassword("")

    try {
      const deleted = await onDeleteAccount({ confirmPassword })
      console.log("User account deleted", deleted)
      setStatus("success")
      setDialogOpen(false)
    } catch (error: any) {
      console.log(error)
      enqueueSnackbar(error?.message, { variant: "error" })
      setStatus("error")
    }
  }

  React.useEffect(() => {
    if (status === "error") {
      setStatus("idle")
    }
  }, [status, enqueueSnackbar, deleteErrorMessage])

  return {
    dialogOpen,
    setDialogOpen,
    status,
    confirmationText,
    email,
    confirmPassword,
    setConfirmationText,
    setConfirmPassword,
    isIdle,
    isDeleting,
    disableDeletion,
    closeDialog,
    openDialog,
    confirmAndCloseDialog,
  }
}

type Params = {
  deleteErrorMessage: string
}

type Status = "idle" | "success" | "pending" | "error"
