import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import InlineEditableText from "../../components/InlineEditableText"
import DatePicker from "../../components/DatePicker"
import Typography from "@material-ui/core/Typography"
import { alpha, makeStyles, Theme } from "@material-ui/core/styles"
import { addDays } from "date-fns"
import { HolidayFormData } from "../utils/HolidayFormUtils"
import { useTranslations } from "../hooks/use-translations"

const HolidayForm = ({
  holiday,
  onSaveEndDate,
  onSaveName,
  onSaveStartDate,
  onSaveRecurring,
  autoFocusTitle = false,
  isReadOnly = false,
}: HolidayFormProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const isSingleHoliday = holiday.type === "Single"
  const startDate = isSingleHoliday ? holiday.date : holiday.startDate
  const guessedEndDate = addDays(holiday.startDate || new Date(), 1)

  const startDateLabel = isSingleHoliday
    ? `${translations.holiday} ${translations.date.toLocaleLowerCase()}`
    : `${translations.holidayStartsFrom}`
  const endDateLabel = translations.holidayEndsOn
  const addEndDateButtonLabel = `${
    translations.add
  } ${translations.end.toLocaleLowerCase()} ${translations.date.toLocaleLowerCase()}`
  const endDateHint = translations.holidayEndDateHint
  const recurringCheckboxLabel = `${translations.recurring} ${translations.holiday.toLocaleLowerCase()}`

  return (
    <>
      <div className={classes.titleWrapper}>
        <InlineEditableText
          value={holiday.name}
          onSave={onSaveName}
          TypographyProps={{ variant: "h6" }}
          placeholder={translations.holidayNamePlaceholder}
          autoFocus={autoFocusTitle}
          isEditable={!isReadOnly}
        />
      </div>

      <div className={classes.startDateWrapper}>
        <Typography variant="body2" gutterBottom>
          {startDateLabel}
        </Typography>
        <DatePicker selected={startDate} onChange={onSaveStartDate} isClearable={false} readOnly={isReadOnly} />
      </div>

      {isSingleHoliday ? (
        <div className={classes.endDateNote}>
          <Button
            className={classes.addEndDateButton}
            color="primary"
            onClick={() => onSaveEndDate(guessedEndDate)}
            disabled={isReadOnly}
          >
            {addEndDateButtonLabel}
          </Button>
          <Typography variant="caption" color="textSecondary">
            {endDateHint}
          </Typography>
        </div>
      ) : (
        <div className={classes.endDateWrapper}>
          <Typography variant="body2" gutterBottom>
            {endDateLabel}
          </Typography>
          <DatePicker selected={holiday.endDate} onChange={onSaveEndDate} disabled={isReadOnly} />
        </div>
      )}

      <FormControlLabel
        control={
          <Checkbox checked={holiday.recurring} onChange={(e) => onSaveRecurring(e.target.checked)} color="primary" />
        }
        label={recurringCheckboxLabel}
        disabled={isReadOnly}
      />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  titleWrapper: { margin: theme.spacing(0, -1.5) },
  startDateWrapper: { margin: theme.spacing(1, 0) },
  endDateWrapper: { margin: theme.spacing(2, 0, 1, 0) },
  recurringWrapper: { margin: theme.spacing(1, 0) },
  addEndDateButton: { marginLeft: -8, textDecoration: "underline" },
  endDateNote: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    background: alpha(theme.palette.info.main, 0.1),
  },
}))

export default HolidayForm

type HolidayFormProps = {
  holiday: HolidayFormData
  onSaveName: (name: string) => unknown
  onSaveStartDate: (startDate: Date) => unknown
  onSaveEndDate: (endDate: Date | null) => unknown
  onSaveRecurring: (recurring: boolean) => unknown
  autoFocusTitle?: boolean
  isReadOnly?: boolean
}
