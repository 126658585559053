import { MembershipRoleEnum, MembershipStatusEnum } from "../../types/memberships"
import { isBeforeDays } from "../../utils"
import { IMembershipResponse as MembershipResponse, MembershipItemPermissions } from "../interfaces/membership-response"

export type MembershipViewModel = MembershipResponse &
  MembershipItemPermissions & {
    isDeactivated: boolean
    isOwner: boolean
    isStandardMember: boolean
    isLimitedMember: boolean
    fullname: string
    userInitials: string
    hasPendingInvitation: boolean
    hasAcceptedInvitation: boolean
    hasRejectedInvitation: boolean
    isNotInvited: boolean
    isDeactivatedBefore30Days: boolean
    reactivationDate: string | null
  }

export const makeMembershipViewModel = (membership: MembershipResponse): MembershipViewModel => {
  const { firstname, lastname } = membership
  const userInitials = `${firstname[0]}${lastname ? lastname[0] : firstname[1]}`.toUpperCase()
  const isDeactivatedBefore30Days = Boolean(
    membership.deactivationDate && isBeforeDays(new Date(membership.deactivationDate), 30)
  )

  return {
    ...membership,
    isDeactivated: membership.status === MembershipStatusEnum.IN_ACTIVE,
    isOwner: membership.role === MembershipRoleEnum.orgOwner,
    isStandardMember: membership.role === MembershipRoleEnum.orgMember,
    isLimitedMember: membership.role === MembershipRoleEnum.orgLimitedMember,
    fullname: `${firstname} ${lastname}`.trim(),
    userInitials,
    hasAcceptedInvitation: membership.invitationStatus === "accepted",
    hasPendingInvitation: membership.invitationStatus === "pending",
    hasRejectedInvitation: membership.invitationStatus === "rejected",
    isNotInvited: membership.invitationStatus === "not_invited",
    isDeactivatedBefore30Days,
    reactivationDate: getReactivationDate(membership),
  }
}

function getReactivationDate(membership: MembershipResponse): string | null {
  if (!membership.deactivationDate) return null
  const deactivationDate = new Date(membership.deactivationDate)
  const reactivationDate = new Date(deactivationDate)
  reactivationDate.setDate(reactivationDate.getDate() + 30)
  return reactivationDate.toISOString()
}
