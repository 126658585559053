import { alpha, makeStyles } from "@material-ui/core/styles"
import MuiAppBar from "@material-ui/core/AppBar"
import Badge from "@material-ui/core/Badge"
import Collapse from "@material-ui/core/Collapse"
import Drawer from "@material-ui/core/Drawer"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Toolbar from "@material-ui/core/Toolbar"
import Tooltip from "@material-ui/core/Tooltip"
import AppMainContext from "../AppMainContext"
import AppSubContext from "../AppSubContext"
import InvitationsMailIcon from "../Navbar/DesktopMenu/InvitationsMailIcon"
import UserMenu from "../Navbar/DesktopMenu/UserMenu"
import {
  ChevronLeft,
  ChevronDown,
  ChevronUp,
  Home,
  Folder,
  Clipboard,
  Columns,
  Calendar,
  CheckCircle,
  Settings,
  Mail,
  Users,
  Menu,
  CreditCard,
} from "react-feather"
import React from "react"
import clsx from "clsx"
import { useAppContext } from "../../hooks/use-app-context"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { useMediaQuery } from "@material-ui/core"
import { useI18n, useLocalStorage, useRouter } from "../../hooks"
import { StringMap } from "../../types/common"
import { Link } from "react-router-dom"
import { useUserInvitations } from "../../invitations/hooks/use-user-invitations"
import Logout from "../Logout"
import AppLanguageSwitch from "../LanguageSwitch"
import { COLOR_BLACK } from "../../constants"
import { useAuthUserMembership } from "../../memberships/hooks/use-auth-user-membership"
import { paths } from "../../paths"

const drawerWidth = 200

const initialNavState: NavState = {
  isDrawerOpen: window.innerWidth > 800,
  isResourceNavOpen: false,
  drawerOpenReason: null,
}

const navStateLocalStorageKey = "starbrix_app_nav_state"

const AppLayout = ({ children }: React.PropsWithChildren<{}>) => {
  const authUserMembership = useAuthUserMembership()
  const classes = useStyles()
  const [{ isDrawerOpen, isResourceNavOpen }, setNavState] = useLocalStorage<NavState>(
    navStateLocalStorageKey,
    initialNavState
  )

  const openDrawer = (reason: string) => {
    setNavState((state) => ({ ...state, isDrawerOpen: true, drawerOpenReason: reason }))
  }
  const closeDrawer = () => {
    setNavState({ isResourceNavOpen: false, isDrawerOpen: false, drawerOpenReason: null })
  }

  const toggleResourceNav = () => {
    setNavState((state) => {
      const nextIsResourceOpen = !state.isResourceNavOpen
      const nextIsDrawerOpen = nextIsResourceOpen || state.isDrawerOpen
      return { ...state, isResourceNavOpen: nextIsResourceOpen, isDrawerOpen: nextIsDrawerOpen }
    })
  }
  const { appContext } = useAppContext()
  const { mainContext, subContext } = appContext
  const { createPathWithContext } = useUrlWithContext()
  const translations = useTranslations()
  const isMobileScreen = useMediaQuery(() => "(max-width:500px)")
  const homePage = subContext ? subContext?.homePage : mainContext?.homePage
  const router = useRouter()
  const { pendingInvitations } = useUserInvitations()
  if (!mainContext) return null
  const shouldShowBillingLink = mainContext?.type === "org" && authUserMembership?.canCreateMemberships

  const billingPath = createPathWithContext(paths.orgBilling())
  const projectsPath = createPathWithContext(`projects`)
  const settingsPath = createPathWithContext(`${homePage}/settings`)
  const membersPath = createPathWithContext(`${homePage}/members`)
  const notificationsPath = createPathWithContext(`/invitations`)
  const customersPath = createPathWithContext(`${homePage}/customers`)
  const suppliersPath = createPathWithContext(`${homePage}/suppliers`)
  const workspacesPath = createPathWithContext(`${homePage}/workspaces`)
  const todosPath = createPathWithContext(`/todos`)
  const kanbanPath = createPathWithContext(`/kanban`)
  const calendarPath = createPathWithContext(`/calendar`)
  const tasksPath = createPathWithContext(`/tasks`)
  const homePath = createPathWithContext(`/home`)
  const isActive = (path: string) => {
    const url = new URL(path, window.location.origin)
    return router.location.pathname === url.pathname
  }

  const billing = { label: translations.billingLabel, path: billingPath, icon: <CreditCard /> }
  const home = { label: translations.homeLabel, path: homePath, icon: <Home /> }
  const notification = { label: translations.notificationsLabel, path: notificationsPath, icon: <Mail /> }
  const settings = { label: translations.settingsLabel, path: settingsPath, icon: <Settings /> }
  const projects = { label: translations.projectsLabel, path: projectsPath, icon: <Folder /> }
  const todos = { label: translations.todosLabel, path: todosPath, icon: <CheckCircle /> }
  const kanban = { label: translations.kanbanLabel, path: kanbanPath, icon: <Columns /> }
  const tasks = { label: translations.tasksLabel, path: tasksPath, icon: <Clipboard /> }
  const calendar = { label: translations.calendarLabel, path: calendarPath, icon: <Calendar /> }
  const members = { label: translations.membersLabel, path: membersPath, icon: <Users /> }
  const customers = { label: translations.customersLabel, path: customersPath }
  const suppliers = { label: translations.suppliersLabel, path: suppliersPath }
  const workspaces = { label: translations.workspacesLabel, path: workspacesPath }

  const navItems: NavItem[] = [home, projects, tasks, todos, calendar, kanban]
  const resourceNavigations: NavItem[] = []

  if (isMobileScreen) navItems.push(notification)
  if (mainContext?.type === "user") navItems.push(settings)
  if (mainContext?.type === "org" && !subContext) {
    const homeTabIndex = navItems.findIndex((item) => item.path.includes("home"))
    navItems.splice(homeTabIndex, 1)
    const kanbanTabIndex = navItems.findIndex((item) => item.path.includes("kanban"))
    navItems.splice(kanbanTabIndex, 1)

    resourceNavigations.push(members)
    resourceNavigations.push(customers)
    resourceNavigations.push(suppliers)
    resourceNavigations.push(workspaces)
  }
  if (subContext?.type === "member") {
    navItems.push(settings)
  }
  if (subContext?.type === "customer") {
    navItems.push(settings)
  }
  if (subContext?.type === "supplier") {
    const todoTabIndex = navItems.findIndex((item) => item.path.includes("todos"))
    navItems.splice(todoTabIndex, 1)
    navItems.push(settings)
  }
  if (subContext?.type === "workspace") {
    navItems.push(members)
    navItems.push(settings)
  }

  return (
    <div className={classes.container}>
      <MuiAppBar position="fixed" elevation={0} className={classes.appBar}>
        <Toolbar variant="dense" disableGutters>
          <div className={classes.logo}>
            <img src="/starbrix.png" alt="Starbrix logo" />
          </div>
          <div className={classes.appContext}>
            <AppMainContext />
            <AppSubContext />
          </div>
          {!isMobileScreen && <InvitationsMailIcon />}
          {!isMobileScreen && <UserMenu />}
        </Toolbar>
      </MuiAppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: isDrawerOpen,
          [classes.drawerClose]: !isDrawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: isDrawerOpen,
            [classes.drawerClose]: !isDrawerOpen,
          }),
        }}
      >
        <div className={classes.replacementDiv}></div>
        <div className={classes.drawerHeader}>
          <Tooltip title={isDrawerOpen ? translations.closeSidebarTip : translations.openSidebarTip} placement="right">
            <IconButton
              onClick={() => {
                isDrawerOpen ? closeDrawer() : openDrawer("onClickMenu")
              }}
            >
              {isDrawerOpen ? <ChevronLeft color={COLOR_BLACK} /> : <Menu color={COLOR_BLACK} />}
            </IconButton>
          </Tooltip>
        </div>
        <Divider />
        <div>
          <List disablePadding>
            {navItems.map(({ label, path, icon }, index) => {
              return (
                <Tooltip key={path} title={isDrawerOpen ? "" : label} placement="right">
                  <ListItem
                    component={Link}
                    to={path}
                    button
                    selected={isActive(path)}
                    className={classes.navItem}
                    divider={index !== navItems.length - 1}
                    data-test={`${label.toLowerCase()}-nav-link`}
                    onClick={() => isMobileScreen && closeDrawer()}
                  >
                    <ListItemIcon>
                      <Badge
                        overlap="rectangular"
                        badgeContent={
                          label === translations.notificationsLabel && !isDrawerOpen
                            ? pendingInvitations.length
                            : undefined
                        }
                        color="secondary"
                      >
                        {icon}
                      </Badge>
                    </ListItemIcon>
                    <Tooltip title={label.length > 12 ? label : ""}>
                      <ListItemText primary={label} />
                    </Tooltip>
                    {label === translations.notificationsLabel && (
                      <Badge
                        badgeContent={pendingInvitations.length}
                        color="secondary"
                        className={"notificationBadge"}
                      />
                    )}
                  </ListItem>
                </Tooltip>
              )
            })}
            {resourceNavigations.length > 0 && (
              <>
                <Divider />
                <Tooltip title={isDrawerOpen ? "" : translations.resourcesLabel} placement="right">
                  <ListItem
                    button
                    onClick={toggleResourceNav}
                    className={classes.navItem}
                    data-test={`resources-reveal-button`}
                  >
                    <ListItemIcon>
                      <Users />
                    </ListItemIcon>
                    <ListItemText primary={translations.resourcesLabel} />
                    <IconButton size="small" className={"chevronIcon"}>
                      {isResourceNavOpen ? <ChevronUp /> : <ChevronDown />}
                    </IconButton>
                  </ListItem>
                </Tooltip>
                <Collapse in={isResourceNavOpen} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {resourceNavigations.map(({ label, path }, index) => {
                      return (
                        <ListItem
                          key={path}
                          component={Link}
                          to={path}
                          button
                          selected={isActive(path)}
                          className={clsx(classes.navItem, classes.nested)}
                          divider={index !== resourceNavigations.length - 1}
                          data-test={`${label.toLowerCase()}-nav-link`}
                          onClick={() => isMobileScreen && closeDrawer()}
                        >
                          <ListItemText primary={label} />
                        </ListItem>
                      )
                    })}
                  </List>
                </Collapse>
                <Divider />
                <Tooltip title={isDrawerOpen ? "" : settings.label} placement="right">
                  <ListItem
                    key={settings.path}
                    component={Link}
                    to={settings.path}
                    selected={isActive(settings.path)}
                    className={classes.navItem}
                    button
                    data-test={`${settings.label.toLowerCase()}-nav-link`}
                    onClick={() => isMobileScreen && closeDrawer()}
                  >
                    <ListItemIcon>{settings.icon}</ListItemIcon>
                    <ListItemText primary={settings.label} />
                  </ListItem>
                </Tooltip>
                {shouldShowBillingLink && (
                  <Tooltip title={isDrawerOpen ? "" : billing.label} placement="right">
                    <ListItem
                      key={billing.path}
                      component={Link}
                      to={billing.path}
                      selected={isActive(billing.path)}
                      className={classes.navItem}
                      button
                      data-test={`${billing.label.toLowerCase()}-nav-link`}
                      onClick={() => isMobileScreen && closeDrawer()}
                    >
                      <ListItemIcon>{billing.icon}</ListItemIcon>
                      <ListItemText primary={billing.label} />
                    </ListItem>
                  </Tooltip>
                )}
              </>
            )}
          </List>
          {isMobileScreen && (
            <div style={{ marginTop: 24 }}>
              <Divider />
              {isDrawerOpen && (
                <div style={{ marginLeft: 12 }}>
                  <AppLanguageSwitch />
                </div>
              )}
              <Tooltip title={isDrawerOpen ? "" : "Logout"} placement="right">
                <span>
                  <Logout />
                </span>
              </Tooltip>
              <Divider />
            </div>
          )}
        </div>
      </Drawer>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: isDrawerOpen,
        })}
      >
        <div className={classes.replacementDiv}></div>
        {children}
      </main>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  container: { display: "flex" },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    "& .MuiToolbar-root": {
      padding: theme.spacing(0, 1),
    },
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth + 49,
    width: "calc(100vw - 49px)",
    overflow: "auto",
    padding: theme.spacing(1.5, 2),
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(1),
    },
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: 49,
  },
  logo: {
    height: 32,
    padding: theme.spacing(0.2, 0, 0.875, 0),
    marginRight: theme.spacing(2),
    "& img": {
      display: "block",
      height: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  appContext: {
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    "&  *": {
      color: theme.palette.common.white,
    },
    "& > div ": {
      backgroundColor: alpha(theme.palette.common.white, 0.1),
      borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(1),
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    maxHeight: "48px !important",
    minHeight: "48px !important",
    color: theme.palette.common.white,
    marginTop: theme.spacing(),
  },
  navItem: {
    "& .MuiListItemText-root": {
      marginLeft: -theme.spacing(1),
      "& .MuiTypography-root": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: 14,
      },
    },
    "& .chevronIcon": {
      marginRight: -theme.spacing(1.5),
    },
    "& .MuiListItemIcon-root svg, & .chevronIcon svg": {
      color: theme.palette.common.black,
      width: 20,
      height: 20,
    },
    "&.Mui-selected": {
      background: alpha(theme.palette.primary.main, 0.1),
      "& .MuiTypography-root": {
        fontWeight: 600,
        color: theme.palette.primary.main,
      },
      "& .MuiListItemIcon-root svg": {
        color: theme.palette.primary.main,
      },
    },
  },
  nested: {
    marginLeft: theme.spacing(7),
  },
  replacementDiv: {
    height: 48,
    width: "100%",
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t?.appNavBar || {}) as StringMap

  return {
    billingLabel: translate("billingLabel"),
    homeLabel: translate("homeLabel"),
    notificationsLabel: translate("notificationsLabel"),
    settingsLabel: translate("settingsLabel"),
    membersLabel: translate("membersLabel"),
    projectsLabel: translate("projectsLabel"),
    customersLabel: translate("customersLabel"),
    suppliersLabel: translate("suppliersLabel"),
    workspacesLabel: translate("workspacesLabel"),
    todosLabel: translate("todosLabel"),
    kanbanLabel: translate("kanbanLabel"),
    calendarLabel: translate("calendarLabel"),
    tasksLabel: translate("tasksLabel"),
    resourcesLabel: translate("resourcesLabel"),
    openSidebarTip: translate("openSidebarTip"),
    closeSidebarTip: translate("closeSidebarTip"),
  }

  function translate(key: keyof Translations): string {
    return translations[key] || defaults[key]
  }
}

const defaultTranslations = {
  billingLabel: "Billing",
  homeLabel: "Home",
  notificationsLabel: "Invites",
  settingsLabel: "Settings",
  membersLabel: "Members",
  projectsLabel: "Projects",
  customersLabel: "Customers",
  suppliersLabel: "Suppliers",
  workspacesLabel: "Workspaces",
  todosLabel: "Todos",
  kanbanLabel: "Kanban",
  calendarLabel: "Calendar",
  tasksLabel: "Tasks",
  resourcesLabel: "Resources",
  openSidebarTip: "Open sidebar",
  closeSidebarTip: "Close sidebar",
}

export default AppLayout

type Translations = typeof defaultTranslations
type NavItem = { label: string; path: string; icon?: React.ReactNode }
type NavState = {
  isDrawerOpen: boolean
  isResourceNavOpen: boolean
  drawerOpenReason: string | null
}
