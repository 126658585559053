import { getSupplierNumberMetadata } from "../supplier-api"

export const makeValidateSupplierNumber: MakeValidateSupplierNumber = ({
  orgId,
  currentSupplierNumber = "",
  translations,
}) => {
  return async ({ supplierNumber }) => {
    let message = translations.somethingWentWrongError
    if (!supplierNumber || supplierNumber === currentSupplierNumber.toString()) {
      return {}
    }

    try {
      const data = await getSupplierNumberMetadata(orgId, supplierNumber.toString())
      if (data.error) {
        message = translations.invalidSupplierNumberError

        const error = data.error.toLowerCase()
        const isDuplicate = error.includes("duplicate")
        const isNotNumber = error.includes("integer")

        if (isDuplicate) message = translations.duplicateSupplierNumberError
        if (isNotNumber) message = translations.numberOnlySupplierNumberError
        return { supplierNumber: message }
      }
      return {}
    } catch (error: any) {
      return { supplierNumber: message }
    }
  }
}
type MakeValidateSupplierNumber = (params: {
  orgId: string
  currentSupplierNumber?: string
  translations: {
    invalidSupplierNumberError: string
    duplicateSupplierNumberError: string
    numberOnlySupplierNumberError: string
    somethingWentWrongError: string
  }
}) => (params: { supplierNumber: string }) => Promise<{ supplierNumber?: string }>
