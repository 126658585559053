import { useTheme } from "@material-ui/core"
import { Check, Eye, EyeOff, Square } from "react-feather"

export const isGanttRoute = (pathname: string) => pathname.includes("gantt")
export const isBasicRoute = (pathname: string) => pathname.includes("basic")
export const isTasksRoute = (pathname: string) => pathname.includes("tasks")
export const isKanbanRoute = (pathname: string) => pathname.includes("kanban")
export const isTodoListRoute = (pathname: string) => pathname.includes("todos")
export const isCalendarRoute = (pathname: string) => pathname.includes("calendar")

export const getActiveTabIndex = (pathname: string) => {
  if (isBasicRoute(pathname)) return 0
  if (isGanttRoute(pathname)) return 1
  if (isTasksRoute(pathname)) return 2
  if (isTodoListRoute(pathname)) return 3
  if (isCalendarRoute(pathname)) return 4
  if (isKanbanRoute(pathname)) return 5
  throw new Error("Invalid pathname")
}

export const EyeIcon = ({ isShown, size = 20 }: { isShown: boolean; size?: number }) => {
  if (isShown) return <EyeOff size={size} />
  else return <Eye size={size} />
}

export const CheckIcon = ({ isChecked }: { isChecked: boolean }) => {
  const theme = useTheme()
  const style = { background: theme.palette.primary.main, stroke: theme.palette.common.white, borderRadius: 3 }

  if (isChecked) {
    return <Check style={style} size={16} />
  } else {
    return <Square size={18} />
  }
}
