import { createSelector } from "@reduxjs/toolkit"
import { filter, values } from "lodash"
import { RootState } from "../../store"

export const getCustomerById = (state: RootState, id: string) => {
  return state.customers.byIds[id]
}

export const getOrgCustomers = createSelector(
  (state: RootState) => state.customers.byIds,
  (state: RootState, orgId: string) => orgId,
  (customers, orgId) => {
    return filter(values(customers), { orgId })
  }
)

export const getAllCustomers = createSelector(
  (state: RootState) => state.customers.byIds,
  (customers) => values(customers)
)
