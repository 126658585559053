import { HolidayResponse, HolidayResponseSchema, RangeHolidayResponse, SingleHolidayResponse } from "./holiday-response"

export const makeHolidayViewModel = (holiday: HolidayResponse): HolidayViewModel => {
  try {
    const parsedHoliday = HolidayResponseSchema.parse(holiday)
    const isSingleHoliday = parsedHoliday.type === "Single"
    const isRangeHoliday = parsedHoliday.type === "Range"
    return { ...parsedHoliday, isSingleHoliday, isRangeHoliday }
  } catch (error) {
    throw new Error(`Failed to parse holiday: ${error}`)
  }
}

export type HolidayViewModel = HolidayResponse & {
  isSingleHoliday: boolean
  isRangeHoliday: boolean
}
export type SingleHolidayViewModel = SingleHolidayResponse & {
  isSingleHoliday: true
  isRangeHoliday: false
}
export type RangeHolidayViewModel = RangeHolidayResponse & {
  isSingleHoliday: false
  isRangeHoliday: true
}
