import { AppDispatch, RootState } from "../../store"
import { IAccessTokenResponse } from "../interfaces/auth-response"
import * as AuthAPI from "../auth-api"
import type { IAuthAPI } from "../interfaces/auth-api"
import { createAsyncThunk } from "@reduxjs/toolkit"

let accessTokenRefreshTimeout: NodeJS.Timeout | null = null

const makeAutoRefreshAccessToken = (authAPI: IAuthAPI) => {
  return createAsyncThunk(
    "auth/refreshAccessToken",
    async (_, { getState, dispatch }) => {
      const state = getState() as RootState
      if (!window.navigator.onLine) {
        return { accessToken: null, tokenExpiresAt: null }
      }
      const responseData = await authAPI.fetchAccessToken(state.auth.refreshToken as string)
      const { accessToken, tokenExpiresAt } = adaptAccessTokenResponse(responseData)
      const nextRefreshInMs = getNextRefreshInMs(tokenExpiresAt)
      scheduleNextRefresh(dispatch as AppDispatch, nextRefreshInMs)
      return { accessToken, tokenExpiresAt }
    },
    {
      condition: (_, { getState }) => {
        const state = getState() as RootState
        return state.auth.tokenRefreshStatus !== "pending"
      },
    }
  )
}

export const scheduleNextRefresh = (dispatch: AppDispatch, nextRefresh = 0): NodeJS.Timeout => {
  if (nextRefresh < 0) throw new Error("Token is already expired")
  // Clear the previous timeout
  if (accessTokenRefreshTimeout) clearTimeout(accessTokenRefreshTimeout)
  // Set the new timeout
  accessTokenRefreshTimeout = setTimeout(() => {
    dispatch(AutoRefreshAccessToken())
  }, nextRefresh)

  return accessTokenRefreshTimeout
}

export const AutoRefreshAccessToken = makeAutoRefreshAccessToken(AuthAPI)

const adaptAccessTokenResponse = (responseData: IAccessTokenResponse): AccessTokenData => {
  // expires is how long token is valid in seconds, tokenExpiresAt is the timestamp when the token will expire
  const now = Date.now()
  const tokenExpiresAt = now + responseData.expires * 1000
  return { accessToken: responseData.access_token, tokenExpiresAt }
}

const getNextRefreshInMs = (tokenExpiresAt: number): number => {
  const now = Date.now()
  const nextRefreshInMs = tokenExpiresAt - now - 30000
  return nextRefreshInMs
}

type AccessTokenData = {
  accessToken: string
  tokenExpiresAt: number
}
