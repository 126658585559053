import React from "react"
import Button from "@material-ui/core/Button"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { Link } from "react-router-dom"
import { makeStyles, alpha } from "@material-ui/core"
import { IWorkspaceNormalized } from "../../workspaces/interfaces/workspace-normalized"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { paths } from "../../paths"
import { Settings } from "react-feather"
import { useOrgWorkspaces } from "../../workspaces/hooks/use-org-workspaces"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"

const OrgWorkspaceCards = ({ orgId }: { orgId: string }) => {
  const { workspaces, activeWorkspaces, inactiveWorkspaces } = useOrgWorkspaces(orgId)
  const [filter, setFilter] = React.useState("active")
  const classes = useStyles()
  const translations = useTranslations()
  const filteredWorkspaces =
    filter === "active" ? activeWorkspaces : filter === "inactive" ? inactiveWorkspaces : workspaces

  return (
    <div style={{ marginTop: 24 }}>
      <InputLabel className={classes.filterLabel}>{translations.filter}</InputLabel>
      <Select
        className={classes.filter}
        value={filter}
        onChange={(e) => setFilter(e.target.value as string)}
        variant="outlined"
      >
        <MenuItem value="all">
          <em>{translations.allWorkspacesOption}</em>
        </MenuItem>
        <MenuItem value={"active"}>{translations.activeWorkspacesOption}</MenuItem>
        <MenuItem value={"inactive"}>{translations.inactiveWorkspacesOption}</MenuItem>
      </Select>
      {filteredWorkspaces.map((workspace) => (
        <OrgWorkspaceCard key={workspace.id} workspace={workspace} />
      ))}
      {filteredWorkspaces.length === 0 && (
        <div className={classes.emptyMessage}>
          <Typography variant="body2" color="textSecondary">
            {translations.noWorkspacesText}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default OrgWorkspaceCards

const OrgWorkspaceCard = ({ workspace }: { workspace: IWorkspaceNormalized }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { createPathWithGivenContext } = useUrlWithContext()

  const workspaceHomePath = createPathWithGivenContext({
    path: paths.home(),
    mainContextId: workspace.orgId,
    subContextId: workspace.id,
  })

  const workspaceSettingsPath = createPathWithGivenContext({
    path: paths.workspaceSettings(),
    mainContextId: workspace.orgId,
    subContextId: workspace.id,
  })

  const formattedWorkspaceResponsible = workspace.managers.map((manager) => manager.name).join(", ")
  const formattedWorkspaceMembers = workspace.members.map((member) => member.name).join(", ")

  return (
    <Paper variant="outlined" className={classes.card}>
      <Typography variant="subtitle1" component={Link} to={workspaceHomePath} gutterBottom>
        {workspace.name}
      </Typography>

      {workspace.managers.length ? (
        <Typography variant="body2" component="p" color="textSecondary">
          {translations.workspaceManagerHeader}: {formattedWorkspaceResponsible}
        </Typography>
      ) : null}

      <Typography variant="body2" component="p" color="textSecondary">
        {translations.workspaceMemberHeader}: {formattedWorkspaceMembers || 0}
      </Typography>

      <br />

      <Button
        color="primary"
        className={classes.settingsButton}
        component={Link}
        to={workspaceSettingsPath}
        size="small"
        startIcon={<Settings size={14} />}
      >
        {translations.settings}
      </Button>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
      textDecoration: "none",
    },
    "& .text": {
      marginBottom: theme.spacing(0.5),
    },
  },
  settingsButton: {
    marginTop: theme.spacing(1),
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  filter: {
    minWidth: 120,
    width: "calc(100% - 24px)",
    marginLeft: theme.spacing(1.5),
  },
  filterLabel: {
    marginLeft: theme.spacing(2),
  },
  emptyMessage: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n()
  const { translations: workspaceTranslations } = useI18n("workspace")
  const workspacesPageTranslations = (workspaceTranslations?.workspacesPage || {}) as StringMap
  const membersPageTranslations = (workspaceTranslations?.membersPage || {}) as StringMap

  const {
    allWorkspacesOption = defaults.allWorkspacesOption,
    activeWorkspacesOption = defaults.activeWorkspacesOption,
    inactiveWorkspacesOption = defaults.inactiveWorkspacesOption,
    workspaceMemberHeader = defaults.workspaceMemberHeader,
    noWorkspacesText = defaults.noWorkspacesText,
    workspaces = defaults.workspaces,
  } = workspacesPageTranslations

  const workspaceManagerHeader = membersPageTranslations.managerHeaderText || defaults.workspaceManagerHeader

  return {
    allWorkspacesOption,
    activeWorkspacesOption,
    inactiveWorkspacesOption,
    workspaceManagerHeader,
    workspaceMemberHeader,
    noWorkspacesText,
    workspaces,
    filter: translations.filter || defaults.filter,
    settings: translations.settings || defaults.settings,
  }
}

const defaultTranslations = {
  allWorkspacesOption: "All workspaces",
  activeWorkspacesOption: "Active workspaces",
  inactiveWorkspacesOption: "Inactive workspaces",
  workspaceMemberHeader: "Members",
  workspaceManagerHeader: "Responsible",
  noWorkspacesText: "No workspaces",
  workspaces: "Workspaces",
  settings: "Settings",
  filter: "Filter",
}

type Translations = typeof defaultTranslations
