import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"

import { makeStyles, Theme } from "@material-ui/core"
import { useI18n, useRouter } from "../../hooks"
import { StringMap } from "../../types/common"

const ProjectsViewSelect = () => {
  const router = useRouter()
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const query = router.query as { view?: ProjectsViewOptions }

  const onChangeView = (view: ProjectsViewOptions) => {
    const resultUrl = new URL(window.location.origin)
    resultUrl.pathname = router.location.pathname
    resultUrl.search = router.location.search
    resultUrl.searchParams.set("view", view)
    router.push(resultUrl.pathname + resultUrl.search)
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <Select
        labelId="project-view-select"
        id="project-view-select"
        value={query.view || "currentAndFuture"}
        onChange={(e) => onChangeView(e.target.value as ProjectsViewOptions)}
      >
        <MenuItem value="all">{translations.allMenuItemLabel}</MenuItem>
        <MenuItem value="currentAndFuture">{translations.currentAndFutureMenuItemLabel}</MenuItem>
        <MenuItem value="completed">{translations.completedMenuItemLabel}</MenuItem>
        <MenuItem value="rejected">{translations.rejectedMenuItemLabel}</MenuItem>
        <MenuItem value="archived">{translations.archivedMenuItemLabel}</MenuItem>
        <MenuItem value="templates">{translations.templatesMenuItemLabel}</MenuItem>
      </Select>
    </FormControl>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    minWidth: theme.spacing(28),
    "& .MuiSelect-root": {
      fontSize: 14,
      padding: 6,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("project")
  const translations = (t?.projectsViewSelect || {}) as StringMap

  const {
    allMenuItemLabel = defaults.allMenuItemLabel,
    currentAndFutureMenuItemLabel = defaults.currentAndFutureMenuItemLabel,
    completedMenuItemLabel = defaults.completedMenuItemLabel,
    rejectedMenuItemLabel = defaults.rejectedMenuItemLabel,
    archivedMenuItemLabel = defaults.archivedMenuItemLabel,
    templatesMenuItemLabel = defaults.templatesMenuItemLabel,
  } = translations

  return {
    allMenuItemLabel,
    currentAndFutureMenuItemLabel,
    completedMenuItemLabel,
    rejectedMenuItemLabel,
    archivedMenuItemLabel,
    templatesMenuItemLabel,
  }
}

const defaultTranslations = {
  allMenuItemLabel: "All projects",
  currentAndFutureMenuItemLabel: "Current and future projects",
  completedMenuItemLabel: "Completed projects",
  rejectedMenuItemLabel: "Rejected projects",
  archivedMenuItemLabel: "Archived projects",
  templatesMenuItemLabel: "All templates",
}

type Translations = typeof defaultTranslations
export type ProjectsViewOptions = "all" | "currentAndFuture" | "completed" | "rejected" | "archived" | "templates"
export default ProjectsViewSelect
