import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import { FallbackProps } from "react-error-boundary"

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    maxWidth: 550,
    margin: theme.spacing(4, 0, 6, 0),
    padding: theme.spacing(2),
  },
}))

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  let errorMessage = ""
  if (error instanceof Error) errorMessage = error.message
  if (typeof error === "string") errorMessage = error

  const classes = useStyles()
  return (
    <Card className={classes.root} variant="outlined">
      <CardContent>
        <Typography variant="subtitle2" component="p">
          Oops!
        </Typography>
        <Typography variant="h5" component="h5" color="error">
          {errorMessage ? errorMessage : "Something went wrong"}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={resetErrorBoundary} variant="contained" color="primary">
          OK
        </Button>
      </CardActions>
    </Card>
  )
}

export default ErrorFallback
