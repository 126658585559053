import React from "react"
import { TreeGridTranslations } from "./use-translations"
import { useRouter } from "../../hooks"

export const useAddTodoButton = ({ gridId, canCreateTodos, translations }: Props) => {
  const router = useRouter()
  // @ts-expect-error
  const isCompletedTodosView = router.query.view === "completed"
  const isAddTodoButtonVisible = canCreateTodos && !isCompletedTodosView
  const [disableAddTodoButton, setDisableAddTodoButton] = React.useState(false)
  const { addTodoButtonLabel } = translations
  const grid = window.Grids[gridId]

  // This effect is used to disable the add task button when disableShowAddTodoButton is true
  React.useEffect(() => {
    const toolbarRow = grid?.Toolbar
    if (!grid || !toolbarRow) return

    // @ts-expect-error
    toolbarRow.AddTodo = disableAddTodoButton
      ? `<button disabled="true">${addTodoButtonLabel}<span class="icon"> + </span></button>`
      : `<button>${addTodoButtonLabel}<span class="icon"> + </span></button>`

    grid.RefreshRow(toolbarRow)
  }, [disableAddTodoButton, addTodoButtonLabel, gridId, grid])

  React.useEffect(() => {
    const toolbarRow = grid?.Toolbar
    if (!grid || !toolbarRow) return

    if (isAddTodoButtonVisible) {
      // @ts-expect-error
      toolbarRow.AddTodo = `<button>${addTodoButtonLabel}<span class="icon"> + </span></button>`
    } else {
      // @ts-expect-error
      toolbarRow.AddTodo = 0
    }

    grid.RefreshRow(toolbarRow)
  }, [isAddTodoButtonVisible, grid, addTodoButtonLabel])

  return {
    disableAddTodoButton,
    setDisableAddTodoButton,
    isAddTodoButtonVisible,
  }
}

type Props = {
  gridId: string
  canCreateTodos: boolean
  translations: TreeGridTranslations
}
