import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

import { useRouter } from "../../hooks"
import { makeStyles, Theme } from "@material-ui/core"
import { ArrowLeft } from "react-feather"

const ContactNotFound = () => {
  const classes = useStyles()
  const router = useRouter()
  return (
    <Box p={2}>
      <Card variant="outlined">
        <Box py={6} display="flex" alignItems="space-around" flexDirection="column">
          <Box display="flex" justifyContent="space-around">
            <CardMedia className={classes.img} component="img" image="/not-found.svg" alt="no contact" />
          </Box>
          <Box my={2} display="flex" justifyContent="center">
            <Typography variant="subtitle1" color="textSecondary">
              Contact not found
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => router.push(`${router.pathname}`)}
              startIcon={<ArrowLeft size={16} />}
            >
              Go back to supplier info
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    display: "block",
    maxWidth: 240,
  },
}))

export default ContactNotFound
