import ErrorFallback from "../../../components/ErrorFallback"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"

import { makeStyles, Theme } from "@material-ui/core"
import { Typography } from "@material-ui/core"
import { ErrorBoundary } from "react-error-boundary"
import { Link } from "react-router-dom"
import { paths } from "../../../paths"
import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"
import { useUrlWithContext } from "../../../hooks/use-url-with-context"

const WorkspaceWelcomeRoute = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const { createPathWithContext } = useUrlWithContext()

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={2}
        className={classes.container}
        container
        data-test="workspace-welcome-card"
      >
        <Grid item>
          <Typography variant="h4">{translations.welcomeMessageTitle}</Typography>
          <Typography variant="subtitle1" color="textSecondary">
            {translations.welcomeMessageSubtitle}
          </Typography>
        </Grid>
        <Grid item container justifyContent="center" spacing={2} className="actions" data-test="create-project-button">
          <Grid item>
            <Button variant="contained" color="primary" component={Link} to={createPathWithContext(paths.newProject())}>
              {translations.createWorkspaceProjectButtonLabel}
            </Button>
          </Grid>
          <Grid item>
            <Button component={Link} to={createPathWithContext(paths.workspaceMembers())} data-test="add-member-button">
              {translations.addWorkspaceMemberButtonLabel}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("workspace")
  const translations = (t?.workspaceWelcomePage || {}) as StringMap

  const {
    welcomeMessageTitle = defaults.welcomeMessageTitle,
    welcomeMessageSubtitle = defaults.welcomeMessageSubtitle,
    createWorkspaceProjectButtonLabel = defaults.createWorkspaceProjectButtonLabel,
    addWorkspaceMemberButtonLabel = defaults.addWorkspaceMemberButtonLabel,
  } = translations

  return {
    welcomeMessageTitle,
    welcomeMessageSubtitle,
    createWorkspaceProjectButtonLabel,
    addWorkspaceMemberButtonLabel,
  }
}

const defaultTranslations = {
  welcomeMessageTitle: "Workspace has been created.",
  welcomeMessageSubtitle: "Now you can create projects or start adding members in the workspace.",
  createWorkspaceProjectButtonLabel: "Create workspace project",
  addWorkspaceMemberButtonLabel: "Add workspace member",
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.common.white,
    margin: "auto",
    maxWidth: 740,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    marginTop: theme.spacing(8),
    minHeight: theme.spacing(40),
    textAlign: "center",
    "& h4": {
      marginBottom: 12,
    },
    "& .actions": {
      marginTop: 18,
    },
  },
}))

type Translations = typeof defaultTranslations
export default WorkspaceWelcomeRoute
