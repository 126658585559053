import React from "react"
import TextareaAutosize, { TextareaAutosizeProps } from "@material-ui/core/TextareaAutosize"
import { makeStyles, Theme } from "@material-ui/core"

const DescriptionEditor = ({ description, onSaveDescription, TextAreaAutosizeProps = {} }: DescriptionEditorProps) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(description)
  const onTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value)
  }
  const onTextareaBlur = () => {
    const formattedValue = value.trim()
    if (formattedValue === description) return
    onSaveDescription(formattedValue)
  }

  const placeholder = TextAreaAutosizeProps.placeholder
    ? TextAreaAutosizeProps.placeholder
    : TextAreaAutosizeProps.disabled
    ? "Description is empty"
    : "Add a description"

  return (
    <div className={classes.descEditorContainer}>
      <span className={classes.descTextareaWrapper}>
        <TextareaAutosize
          {...TextAreaAutosizeProps}
          value={value}
          placeholder={placeholder}
          onBlur={onTextareaBlur}
          onChange={onTextareaChange}
          className={classes.descTextarea}
        />
      </span>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  descEditorContainer: {
    display: "flex",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 7,
    padding: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  descTextareaWrapper: { width: "calc(100% - 32px)" },
  descTextarea: {
    border: 0,
    outline: 0,
    width: "100%",
    resize: "none",
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.body1.letterSpacing,
  },
}))

type DescriptionEditorProps = {
  description: string
  onSaveDescription: (desc: string) => unknown
  TextAreaAutosizeProps?: TextareaAutosizeProps
}

export default DescriptionEditor
