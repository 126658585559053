import React from "react"
import TextField, { TextFieldProps } from "@material-ui/core/TextField"
import InputAdornment from "@material-ui/core/InputAdornment"
import ReactDatePicker, { ReactDatePickerProps, registerLocale, setDefaultLocale } from "react-datepicker"
import { Calendar } from "react-feather"
import { getDefaultDateFormat } from "../utils"
import { makeStyles, Theme } from "@material-ui/core"
import clsx from "clsx"
import { useDateTranslations } from "../hooks/use-date-translations"
import { enUS } from "date-fns/locale"

export interface DatePickerProps extends ReactDatePickerProps {
  calendarStartDay?: DayOfWeek
  textFieldProps?: TextFieldProps
}

const DatePicker = React.forwardRef<ReactDatePicker, DatePickerProps>(({ textFieldProps, ...datePickerProps }, ref) => {
  const translations = useDateTranslations()
  const dateFormat = getDefaultDateFormat()

  let customLocale: Locale = enUS
  if (enUS.localize) {
    customLocale = {
      ...enUS,
      localize: {
        ...enUS.localize,
        day: (n: number): string => translations.shortDayNames[n],
        month: (n: number): string => translations.shortMonthsNames[n],
      },
    }
  }
  setDefaultLocale("en")
  registerLocale("en", customLocale)

  return (
    <ReactDatePicker
      ref={ref}
      openToDate={datePickerProps?.selected || new Date()}
      dateFormat={dateFormat}
      placeholderText={dateFormat}
      dropdownMode="select"
      customInput={<DatePickerInput {...textFieldProps} />}
      calendarStartDay={1}
      isClearable
      showYearDropdown
      showMonthDropdown
      showWeekNumbers
      timeFormat="HH:mm"
      timeIntervals={15}
      {...datePickerProps}
    />
  )
})

export const DatePickerInput = React.forwardRef<HTMLDivElement, DatePickerInputProps>(
  (props: DatePickerInputProps, ref) => {
    const classes = useStyles()
    return (
      <TextField
        ref={ref}
        variant="outlined"
        {...props}
        className={clsx(classes.input, props?.className)}
        disabled={Boolean(props?.readOnly)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Calendar size={20} />
            </InputAdornment>
          ),
        }}
      />
    )
  }
)

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    width: "100%",
  },
}))

type DatePickerInputProps = TextFieldProps & { readOnly?: boolean }

export default DatePicker
