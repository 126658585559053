import doublet from "../../utils/doublet"
import { useSnackbar } from "notistack"
import { useAppDispatch } from "../../store"
import { ApplyRoleAbilitiesToAll } from "../../roles/store/actions"
import { unwrapResult } from "@reduxjs/toolkit"
import { useConfirmDialog } from "../../components/ConfirmDialog"
import { useI18n } from "../../hooks"

// keep track of the snackbar id so we can close it when  we need to
let snackbarId: null | number | string = null

// This hook encapsulates the logic required to call the API for applying role abilities to all
// and handles displaying appropriate feedback using a snackbar notification
export const useApplyRoleAbilitiesToAll = () => {
  const dispatch = useAppDispatch()
  const translations = useTranslations()
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const { confirm } = useConfirmDialog({
    onConfirm: applyRoleAbilitiesToAll,
    primaryActionButtonLabel: translations.applyToAllButtonLabel,
    title: translations.applyToAllDialogTitle,
    text: translations.applyToAllDialogText,
  })

  async function applyRoleAbilitiesToAll(roleId: string) {
    if (snackbarId) closeSnackbar(snackbarId)
    const dispatchResult = await dispatch(ApplyRoleAbilitiesToAll(roleId))
    let [error, message] = await doublet(unwrapResult, dispatchResult)
    const applyToAllDefaultErrorMessage = translations.applyToAllDefaultErrorMessage
    const variant = error ? "error" : "success"
    if (error) message = error?.message || applyToAllDefaultErrorMessage
    snackbarId = enqueueSnackbar(message, { variant })
  }

  return {
    applyRoleAbilitiesToAll: confirm,
  }
}

const defaultTranslations = {
  applyToAllDialogTitle: "Change permissions of existing members",
  applyToAllDialogText:
    "You are about to modify the permission settings for existing members in your organization. This action can have significant implications on how your projects are managed and who has access to critical information.\nPlease, proceed with caution.",
  applyToAllButtonLabel: "Apply to all",
  applyToAllDefaultErrorMessage: "Error applying role abilities to all",
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("org")
  const translations = t.orgSettingsPage || {}

  const {
    applyToAllDialogTitle = defaults.applyToAllDialogTitle,
    applyToAllDialogText = defaults.applyToAllDialogText,
    applyToAllButtonLabel = defaults.applyToAllButtonLabel,
    applyToAllDefaultErrorMessage = defaults.applyToAllDefaultErrorMessage,
  } = translations

  return {
    applyToAllDialogTitle,
    applyToAllDialogText,
    applyToAllButtonLabel,
    applyToAllDefaultErrorMessage,
  }
}

type Translations = typeof defaultTranslations
