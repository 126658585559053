import { makeStyles, Theme } from "@material-ui/core"
import InlineEditableText from "../../components/InlineEditableText"
import { useProjectMutations } from "../hooks/use-project-mutations"
import { ProjectViewModel } from "../api/project"

const ProjectCardTitle = ({ project }: { project: ProjectViewModel }) => {
  const classes = useStyles()
  const { updateInfo } = useProjectMutations()

  return (
    <InlineEditableText
      value={project.title}
      onSave={(title) => updateInfo(project.id, { title })}
      TypographyProps={{ variant: "h5", className: classes.projectTitle }}
      isEditable={Boolean(project?.canUpdateDetails)}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  projectTitle: {
    "& span, & textarea": {
      maxWidth: "none",
    },
  },
}))

export default ProjectCardTitle
