import { z } from "zod"
import { Status } from "../../types/common"

const ReminderTriggerTypeSchema = z.enum(["absoluteTime", "relativeTime", "statusChange"])
export const ReminderTriggerStatusSchema = z
  .enum(["completed", "inProgress", Status.NOT_STARTED, Status.IN_PROGRESS, Status.COMPLETED, Status.REJECTED])
  .transform((data) => {
    if (data === "inProgress") return Status.IN_PROGRESS
    if (data === "completed") return Status.COMPLETED
    return data
  })
const ReminderRecipientRoleSchema = z.enum([
  "projectManager",
  "projectParticipants",
  "taskManager",
  "taskParticipants",
  "managerOfParentTask",
  "managersOfDependentTasks",
  "calendarEventParticipants",
  "todoResponsible",
])
const ReminderEntityTypeSchema = z.enum(["task", "calendarEvent", "project", "todo"])
export const ReminderTimeDetailUnitSchema = z.enum(["minutes", "hours", "days", "weeks"])
export const ReminderTimeDetailReferenceSchema = z.enum(["plannedStart", "plannedEnd"])
const ReminderTimeDetailsSchema = z.object({
  value: z.number(),
  unit: ReminderTimeDetailUnitSchema,
  reference: ReminderTimeDetailReferenceSchema,
  refDate: z.coerce.date(),
  isAfter: z.boolean().optional(),
})
const ReminderRecipientsSchema = z.object({
  roles: z.array(ReminderRecipientRoleSchema),
  emails: z.array(z.string().email()),
})

const BaseReminderResponseSchema = z.object({
  id: z.string(),
  creatorUserId: z.string(),
  creatorFullname: z.string(),
  updatedAt: z.coerce.date(),
  createdAt: z.coerce.date(),
  sentAt: z.coerce.date().optional().nullable().default(null),
  sentTo: z.array(z.string().email()),
  note: z.string().optional().nullable().default(null),
  entityType: ReminderEntityTypeSchema,
  entityId: z.string(),
  recipients: ReminderRecipientsSchema,
  isPersonal: z.boolean(),
  isExpired: z.boolean(),
  isRecurrenceReminder: z.boolean(),
})

const AbsoluteReminderSchema = BaseReminderResponseSchema.extend({
  reminderTime: z.coerce.date(),
  reminderTimeDetails: z.null().optional().default(null),
  triggerType: z.literal("absoluteTime"),
  triggerStatus: z.null().optional().default(null),
  triggerSubStatusId: z.null().optional().default(null),
  triggerStatusName: z.null().optional().default(null),
})

const RelativeReminderSchema = BaseReminderResponseSchema.extend({
  reminderTimeDetails: ReminderTimeDetailsSchema,
  reminderTime: z.coerce.date(),
  triggerType: z.literal("relativeTime"),
  triggerStatus: z.null().optional().default(null),
  triggerSubStatusId: z.null().optional().default(null),
  triggerStatusName: z.null().optional().default(null),
})

const StatusChangeReminderSchema = BaseReminderResponseSchema.extend({
  triggerType: z.literal("statusChange"),
  reminderTime: z.null().optional().default(null),
  reminderTimeDetails: z.null().optional().default(null),
  triggerStatus: ReminderTriggerStatusSchema,
  triggerSubStatusId: z.string().optional().nullable().default(null),
  triggerStatusName: z.string().optional().nullable().default(null),
})

export const ReminderResponseSchema = z.discriminatedUnion("triggerType", [
  AbsoluteReminderSchema,
  RelativeReminderSchema,
  StatusChangeReminderSchema,
])

export const validateReminderResponse = (reminderData: ReminderResponse): ReminderResponse => {
  const parseResult = ReminderResponseSchema.safeParse(reminderData)
  if (parseResult.success) return parseResult.data
  throw new Error(parseResult.error.errors.map((e) => e.message).join("\n"))
}

export type ReminderRecipientRole = z.infer<typeof ReminderRecipientRoleSchema>
export type ReminderEntityType = z.infer<typeof ReminderEntityTypeSchema>
export type ReminderTimeDetailUnit = z.infer<typeof ReminderTimeDetailUnitSchema>
export type ReminderTimeDetailReference = z.infer<typeof ReminderTimeDetailReferenceSchema>
export type ReminderTimeDetails = z.infer<typeof ReminderTimeDetailsSchema>
export type ReminderResponse = z.infer<typeof ReminderResponseSchema>
export type ReminderTriggerType = z.infer<typeof ReminderTriggerTypeSchema>
export type ReminderTriggerStatus = z.infer<typeof ReminderTriggerStatusSchema>
