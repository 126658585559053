import React from "react"
import ErrorFallback from "../components/ErrorFallback"
import FormLabel from "@material-ui/core/FormLabel"
import FormikInput from "../components/FormikInput"
import ButtonLink from "../components/ButtonLink"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import IconButton from "@material-ui/core/IconButton"
import Stack from "../components/Stack"
import Checkbox from "@material-ui/core/Checkbox"
import { ErrorBoundary } from "react-error-boundary"
import { Form, Formik, FormikHelpers } from "formik"
import { useErrorHandler } from "react-error-boundary"
import { useI18n, useNetworkStatus } from "../hooks"
import { Eye, EyeOff } from "react-feather"
import { DateTimeService } from "../services/date-time-service"
import { useAppDispatch } from "../store"
import { SignupUser } from "../users/store/actions"
import { NewUserData } from "../users/user-api"
import { Alert, AlertTitle } from "@material-ui/lab"
import { paths } from "../paths"
import { Redirect } from "react-router-dom"
import { z } from "zod"
import { createFormikValidate } from "../utils/formik"
import { useZodSchemas } from "../hooks/use-zod-schemas"
import { useLanguage } from "../i18n/use-language"
import { useTurnstile } from "../hooks/use-turnstile"
import { COLOR_ERROR } from "../constants"
import Turnstile from "../components/Turnstile"

const SignupRoute = () => {
  const translations = useTranslations(defaultTranslations)

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography color="textSecondary" component="p" variant="body2" style={{ marginBottom: 12 }}>
            {translations.subHeader}
          </Typography>
          <Typography component="h4" variant="h4">
            {translations.header}
          </Typography>
        </Grid>
        <Grid item>
          <SignupForm />
        </Grid>
      </Grid>
    </ErrorBoundary>
  )
}

const SignupForm = () => {
  const turnstile = useTurnstile()
  const language = useLanguage()
  const translations = useTranslations(defaultTranslations)
  const dispatch = useAppDispatch()
  const handleError = useErrorHandler()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 5000 })
  const { setStatus, isRejected, isIdle, isPending, isFulfilled } = networkStatus
  const [showPassword, setShowPassword] = React.useState(false)
  const dateTimeService = new DateTimeService()
  const { NewUserDataSchema } = useSchemas()

  if (isFulfilled()) return <Redirect to={paths.accountVerification()} />

  const handleSignup = async (values: SignupFormData, { setSubmitting, resetForm }: FormikHelpers<SignupFormData>) => {
    const isTurnstileVerified = await turnstile.verifyToken()
    if (!isTurnstileVerified) return

    const timeZone = dateTimeService.getOsTimeZone()
    const { order: dateFormat, separator: dateFormatSeparator } = getStarbrixDateFormat()

    try {
      setStatus("pending")
      const payload = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        password: values.password,
        avatarURL: "",
        timeZone,
        dateFormat,
        dateFormatSeparator,
        language,
      }
      const actionResult = await dispatch(SignupUser(payload))
      const requestStatus = actionResult.meta.requestStatus
      resetForm()
      setStatus(requestStatus)
      setSubmitting(false)
      if (requestStatus === "fulfilled") {
        // @ts-expect-error - this is google's code
        gtag_report_conversion()
      }
    } catch (error: any) {
      handleError(error)
    }
  }

  const formikProps = {
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      tosAccepted: false,
    },
    validate: createFormikValidate(NewUserDataSchema),
    onSubmit: handleSignup,
  }

  const passwordInputEndAdornment = (
    <InputAdornment position="end">
      <IconButton
        aria-label="toggle password visibility"
        onClick={() => setShowPassword(!showPassword)}
        onMouseDown={(e) => e.preventDefault()}
      >
        {showPassword ? <Eye size={16} /> : <EyeOff size={16} />}
      </IconButton>
    </InputAdornment>
  )

  return (
    <Formik {...formikProps}>
      {({ values, isSubmitting, errors, handleChange }) => {
        const shouldDisableInput = isPending()
        const shouldDisableButton = Boolean(
          values.firstname === "" ||
            values.lastname === "" ||
            values.email === "" ||
            values.password === "" ||
            errors.firstname ||
            errors.lastname ||
            errors.email ||
            errors.password ||
            errors.tosAccepted ||
            !isIdle() ||
            !turnstile.isSolved
        )

        let errorMessage
        let title = translations.error
        if (isRejected()) errorMessage = translations.errorMessage

        return (
          <Form>
            <Stack>
              <div>
                <FormLabel htmlFor="firstname">{`${translations.firstname}:`}</FormLabel>
                <FormikInput
                  data-test="firstname-input"
                  id="firstname"
                  name="firstname"
                  forgot-password
                  variant="outlined"
                  disabled={shouldDisableInput}
                  fullWidth
                />
              </div>
              <div>
                <FormLabel htmlFor="lastname">{`${translations.lastname}:`}</FormLabel>
                <FormikInput
                  data-test="lastname-input"
                  id="lastname"
                  name="lastname"
                  variant="outlined"
                  forgot-password
                  disabled={shouldDisableInput}
                  fullWidth
                />
              </div>
              <div>
                <FormLabel htmlFor="email">{`${translations.email}:`}</FormLabel>
                <FormikInput
                  data-test="email-input"
                  id="email"
                  name="email"
                  variant="outlined"
                  disabled={shouldDisableInput}
                  fullWidth
                />
              </div>
              <div>
                <FormLabel htmlFor="password">{`${translations.password}:`}</FormLabel>
                <FormikInput
                  data-test="password-input"
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter a password"
                  variant="outlined"
                  disabled={shouldDisableInput}
                  InputProps={{ endAdornment: passwordInputEndAdornment }}
                  fullWidth
                />
              </div>
              <Stack direction="row" packed spacing={0}>
                <Checkbox
                  name="tosAccepted"
                  color="primary"
                  inputProps={{ "aria-label": "Agree to terms of service and privacy policy" }}
                  style={{ marginLeft: -12 }}
                  checked={values.tosAccepted}
                  onChange={(e) => handleChange(e)}
                  data-test="tos-accepted-checkbox"
                />
                <p>
                  {translations.readAndAgree}{" "}
                  <a href="https://www.starbrix.app/legal/terms-of-service" target="_blank" rel="noreferrer">
                    {translations.termsAndConditions}
                  </a>{" "}
                  &{" "}
                  <a href="https://www.starbrix.app/legal/privacy-policy" target="_blank" rel="noreferrer">
                    {translations.privacyPolicy}
                  </a>
                </p>
              </Stack>
              <div>
                {errorMessage && (
                  <Alert severity="error">
                    <AlertTitle>{title}</AlertTitle>
                    {errorMessage}
                  </Alert>
                )}
              </div>
              <div>
                <div>
                  <p style={{ color: COLOR_ERROR }}>{turnstile.error}</p>
                  <Turnstile {...turnstile.props} />
                </div>
                <Stack direction="row" packed>
                  <Button
                    data-test="submit-button"
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={shouldDisableButton}
                    startIcon={isSubmitting ? <CircularProgress size={16} /> : null}
                  >
                    {isSubmitting ? translations.signingUp : translations.signup}
                  </Button>
                  <ButtonLink data-test="login-link" to={paths.login()}>
                    {translations.haveAccount}
                  </ButtonLink>
                </Stack>
              </div>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

const useSchemas = () => {
  const { ZodEmail, ZodPassword } = useZodSchemas()
  const translations = useTranslations()

  const NewUserDataSchema = z.object({
    firstname: z
      .string({ message: translations.firstnameRequiredError })
      .min(2, translations.firstnameLengthErrorMin)
      .max(128, translations.firstnameLengthErrorMax),
    lastname: z
      .string({ message: translations.lastnameRequiredError })
      .min(2, translations.lastnameLengthErrorMin)
      .max(128, translations.lastnameLengthErrorMax),
    email: ZodEmail,
    password: ZodPassword,
    tosAccepted: z.boolean().refine((value) => value === true, { message: translations.tosAcceptedError }),
  })

  return { NewUserDataSchema }
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const { translations: tSignup } = useI18n("signup")

  const { subHeader = defaults.subHeader, header = defaults.header } = tSignup

  const {
    firstname = defaults.firstname,
    lastname = defaults.lastname,
    email = defaults.email,
    password = defaults.password,
    readAndAgree = defaults.readAndAgree,
    termsAndConditions = defaults.termsAndConditions,
    privacyPolicy = defaults.privacyPolicy,
    error = defaults.error,
    errorMessage = defaults.errorMessage,
    haveAccount = defaults.haveAccount,
    signup = defaults.signup,
    signingUp = defaults.signingUp,
    firstnameRequiredError = defaults.firstnameRequiredError,
    firstnameLengthErrorMin = defaults.firstnameLengthErrorMin,
    firstnameLengthErrorMax = defaults.firstnameLengthErrorMax,
    lastnameRequiredError = defaults.lastnameRequiredError,
    lastnameLengthErrorMin = defaults.lastnameLengthErrorMin,
    lastnameLengthErrorMax = defaults.lastnameLengthErrorMax,
    tosAcceptedError = defaults.tosAcceptedError,
  } = t

  return {
    subHeader,
    header,
    firstname,
    lastname,
    email,
    password,
    readAndAgree,
    termsAndConditions,
    privacyPolicy,
    error,
    errorMessage,
    haveAccount,
    signup,
    signingUp,
    firstnameRequiredError,
    firstnameLengthErrorMin,
    firstnameLengthErrorMax,
    lastnameRequiredError,
    lastnameLengthErrorMin,
    lastnameLengthErrorMax,
    tosAcceptedError,
  }
}

const defaultTranslations = {
  subHeader: "Create an account to get started",
  header: "Sign up",
  firstname: "Firstname",
  lastname: "Lastname",
  email: "Email",
  password: "Password",
  readAndAgree: "I have read and agree to the",
  termsAndConditions: "Terms of services",
  privacyPolicy: "Privacy policy",
  error: "Error",
  errorMessage: "Failed to create an account!",
  haveAccount: "I already have an account",
  signup: "Sign up",
  signingUp: "Signing up",
  firstnameRequiredError: "Firstname is required",
  firstnameLengthErrorMin: "Firstname must be at least 2 characters long",
  firstnameLengthErrorMax: "Firstname must be at most 128 characters long",
  lastnameRequiredError: "Lastname is required",
  lastnameLengthErrorMin: "Lastname must be at least 2 characters long",
  lastnameLengthErrorMax: "Lastname must be at most 128 characters long",
  tosAcceptedError: "You must agree to the terms of service and privacy policy.",
}
type Translations = typeof defaultTranslations

export default SignupRoute

// In starbrix database we are saving the date format in two fields
// One saves the order of day-month-year and the other saves the separator
function getStarbrixDateFormat() {
  const preferredDateFormat = new DateTimeService().getDateFormat()

  // There are only 3 possible orders of day, month and year
  // d-m-y, m-d-y, y-m-d
  const order = preferredDateFormat.toLowerCase().startsWith("d")
    ? "DD.MM.YYYY"
    : preferredDateFormat.toLowerCase().startsWith("m")
    ? "MM.DD.YYYY"
    : "YYYY.MM.DD"

  // There are 3 possible separators for the date format
  // ., /, -
  const separator = preferredDateFormat.includes(".") // if it includes . then it is .
    ? "."
    : preferredDateFormat.includes("/") // if it includes / then it is /
    ? "/"
    : "-"

  return { order, separator }
}

type SignupFormData = Omit<NewUserData, "language"> & { tosAccepted: boolean }
