import { useSelector } from "react-redux"
import { useAppContext } from "../../hooks/use-app-context"
import { RootState } from "../../store"
import { getAuthState } from "../../users/store/selectors"
import { getMemberships } from "../store/selectors"
import { useMembership } from "./use-membership"

export const useAuthUserMembership = (orgId?: string) => {
  const { appContext } = useAppContext()
  const auth = useSelector(getAuthState)
  const userId = auth.user?.id as string
  const membershipOrgId = orgId || (appContext.mainContext?.id as string)
  const [membership] = useSelector((state: RootState) => {
    return getMemberships(state, { userId, orgId: membershipOrgId, isDeactivated: false })
  })
  const membershipId = membership?.id || ""
  return useMembership(membershipId)
}
