import * as projectApi from "../api"
import { useSnackbar } from "notistack"
import { Status } from "../../types/common"
import { NotificationChannel, NotificationType } from "../../notifications/notifications"

export const useProjectMutations = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [createUserProjectMutation] = projectApi.useCreateUserProjectMutation()
  const [createOrgProjectMutation] = projectApi.useCreateOrgProjectMutation()
  const [updateInfoMutation] = projectApi.useUpdateProjectInfoMutation()
  const [updatePlanMutation] = projectApi.useUpdateProjectPlanMutation()
  const [updateProjectNumberMutation] = projectApi.useUpdateProjectNumberMutation()
  const [updateStatusMutation] = projectApi.useUpdateProjectStatusMutation()
  const [updateStatusDescriptionMutation] = projectApi.useUpdateProjectStatusDescriptionMutation()
  const [addManagersMutation] = projectApi.useAddProjectManagersMutation()
  const [removeManagersMutation] = projectApi.useRemoveProjectManagersMutation()
  const [addParticipantsMutation] = projectApi.useAddProjectParticipantsMutation()
  const [removeParticipantsMutation] = projectApi.useRemoveProjectParticipantsMutation()
  const [addCustomersMutation] = projectApi.useAddProjectCustomersMutation()
  const [removeCustomersMutation] = projectApi.useRemoveProjectCustomersMutation()
  const [addSuppliersMutation] = projectApi.useAddProjectSuppliersMutation()
  const [removeSuppliersMutation] = projectApi.useRemoveProjectSuppliersMutation()
  const [addWorkspacesMutation] = projectApi.useAddProjectWorkspacesMutation()
  const [removeWorkspacesMutation] = projectApi.useRemoveProjectWorkspacesMutation()
  const [saveBasePlanMutation] = projectApi.useSaveBasePlanMutation()
  const [updateTimeComponentMutation] = projectApi.useUpdateTimeComponentMutation()
  const [archiveProjectMutation] = projectApi.useArchiveProjectMutation()
  const [unarchiveProjectMutation] = projectApi.useUnarchiveProjectMutation()
  const [deleteProjectMutation] = projectApi.useDeleteProjectMutation()
  const [updateProjectPermissions] = projectApi.useUpdateProjectPermissionsMutation()
  const [updateProjectNotificationSettingsMutation] = projectApi.useUpdateProjectNotificationSettingsMutation()
  const [createProjectFromTemplateMutation] = projectApi.useCreateProjectFromTemplateMutation()
  const [updateProjectCompletionPercentageMutation] = projectApi.useUpdateProjectCompletionPercentageMutation()

  const handleError = (error: any) => {
    const errorMessage = "error" in error ? error.error : "message" in error ? error.message : "Unknown error"
    enqueueSnackbar(errorMessage, { variant: "error" })
  }

  const createUserProject = async (userId: string, newProjectData: projectApi.NewProjectData) => {
    return await createUserProjectMutation({ userId, ...newProjectData })
      .unwrap()
      .catch(handleError)
  }

  const createOrgProject = async (orgId: string, newProjectData: projectApi.NewProjectData) => {
    return await createOrgProjectMutation({ orgId, ...newProjectData })
      .unwrap()
      .catch(handleError)
  }

  const updateInfo = async (projectId: string, updateData: projectApi.ProjectInfoUpdateData) => {
    return await updateInfoMutation({ projectId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updatePlan = async (projectId: string, updateData: projectApi.ProjectPlanUpdateData) => {
    return await updatePlanMutation({ projectId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateProjectNumber = async (projectId: string, customProjectNumber: string) => {
    return await updateProjectNumberMutation({ projectId, customProjectNumber }).unwrap().catch(handleError)
  }

  const updateStatus = async (projectId: string, status: projectApi.ProjectStatusUpdateData) => {
    return await updateStatusMutation({ projectId, ...status })
      .unwrap()
      .catch(handleError)
  }

  const updateStatusDescription = async (projectId: string, updateData: projectApi.StatusDescriptionUpdateData) => {
    return await updateStatusDescriptionMutation({ projectId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const addManagers = async (projectId: string, managerIds: string[]) => {
    return await addManagersMutation({ projectId, managerIds }).unwrap().catch(handleError)
  }

  const removeManagers = async (projectId: string, managerIds: string[]) => {
    return await removeManagersMutation({ projectId, managerIds }).unwrap().catch(handleError)
  }

  const addParticipants = async (projectId: string, participantIds: string[]) => {
    return await addParticipantsMutation({ projectId, participantIds }).unwrap().catch(handleError)
  }

  const removeParticipants = async (projectId: string, participantIds: string[]) => {
    return await removeParticipantsMutation({ projectId, participantIds }).unwrap().catch(handleError)
  }

  const addCustomers = async (projectId: string, customerIds: string[]) => {
    return await addCustomersMutation({ projectId, customerIds }).unwrap().catch(handleError)
  }

  const removeCustomers = async (projectId: string, customerIds: string[]) => {
    return await removeCustomersMutation({ projectId, customerIds }).unwrap().catch(handleError)
  }

  const addSuppliers = async (projectId: string, supplierIds: string[]) => {
    return await addSuppliersMutation({ projectId, supplierIds }).unwrap().catch(handleError)
  }

  const removeSuppliers = async (projectId: string, supplierIds: string[]) => {
    return await removeSuppliersMutation({ projectId, supplierIds }).unwrap().catch(handleError)
  }

  const addWorkspaces = async (projectId: string, workspaceIds: string[]) => {
    return await addWorkspacesMutation({ projectId, workspaceIds }).unwrap().catch(handleError)
  }

  const removeWorkspaces = async (projectId: string, workspaceIds: string[]) => {
    return await removeWorkspacesMutation({ projectId, workspaceIds }).unwrap().catch(handleError)
  }

  const saveBasePlan = async (projectId: string) => {
    return await saveBasePlanMutation(projectId).unwrap().catch(handleError)
  }

  const updateTimeComponent = async (projectId: string, updateData: projectApi.ProjectTimeComponentUpdateData) => {
    return await updateTimeComponentMutation({ projectId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updatePermissions = async (projectId: string, updateData: projectApi.ProjectPermissionUpdateData) => {
    return await updateProjectPermissions({ projectId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const archiveProject = async (projectId: string) => {
    return await archiveProjectMutation(projectId).unwrap().catch(handleError)
  }

  const unarchiveProject = async (projectId: string) => {
    return await unarchiveProjectMutation(projectId).unwrap().catch(handleError)
  }

  const deleteProject = async (projectId: string) => {
    return await deleteProjectMutation(projectId).unwrap().catch(handleError)
  }

  const createProjectFromTemplate = async (newProjectData: projectApi.CreateProjectFromTemplateData) => {
    return await createProjectFromTemplateMutation(newProjectData).unwrap().catch(handleError)
  }

  const updateProjectCompletionPercentage = async (projectId: string, completionPercentage: number) => {
    return await updateProjectCompletionPercentageMutation({ projectId, completionPercentage })
      .unwrap()
      .catch(handleError)
  }

  const updateCustomers = async (projectId: string, { add, remove }: ResourceUpdateData) => {
    let updatedProject = null
    const shouldAdd = add.length > 0
    const shouldRemove = remove.length > 0
    const shouldDelay = shouldAdd && shouldRemove
    if (shouldAdd) updatedProject = await addCustomers(projectId, add)
    if (shouldDelay) await delay(50)
    if (shouldRemove) updatedProject = await removeCustomers(projectId, remove)
    return updatedProject
  }

  const updateManagers = async (projectId: string, { add, remove }: ResourceUpdateData) => {
    let updatedProject = null
    const shouldAdd = add.length > 0
    const shouldRemove = remove.length > 0
    const shouldDelay = shouldAdd && shouldRemove

    if (shouldAdd) updatedProject = await addManagers(projectId, add)
    if (shouldDelay) await delay(50)
    if (shouldRemove) updatedProject = await removeManagers(projectId, remove)
    return updatedProject
  }

  const updateParticipants = async (projectId: string, { add, remove }: ResourceUpdateData) => {
    let updatedProject = null
    const shouldAdd = add.length > 0
    const shouldRemove = remove.length > 0
    const shouldDelay = shouldAdd && shouldRemove

    if (shouldAdd) updatedProject = await addParticipants(projectId, add)
    if (shouldDelay) await delay(50)
    if (shouldRemove) updatedProject = await removeParticipants(projectId, remove)
    return updatedProject
  }

  const updateSuppliers = async (projectId: string, { add, remove }: ResourceUpdateData) => {
    let updatedProject = null
    const shouldAdd = add.length > 0
    const shouldRemove = remove.length > 0
    const shouldDelay = shouldAdd && shouldRemove

    if (shouldAdd) updatedProject = await addSuppliers(projectId, add)
    if (shouldDelay) await delay(50)
    if (shouldRemove) updatedProject = await removeSuppliers(projectId, remove)
    return updatedProject
  }

  const updateWorkspaces = async (projectId: string, { add, remove }: ResourceUpdateData) => {
    let updatedProject = null
    const shouldAdd = add.length > 0
    const shouldRemove = remove.length > 0
    const shouldDelay = shouldAdd && shouldRemove

    if (shouldAdd) updatedProject = await addWorkspaces(projectId, add)
    if (shouldDelay) await delay(50)
    if (shouldRemove) updatedProject = await removeWorkspaces(projectId, remove)
    return updatedProject
  }

  const updateProjectNotificationSettings = async (
    projectId: string,
    data: projectApi.ProjectNotificationSettingsUpdateData
  ) => {
    return await updateProjectNotificationSettingsMutation({ projectId, ...data })
      .unwrap()
      .catch(handleError)
  }

  const enableNotification = (projectId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [{ type, channel }], disable: [] }
    return updateProjectNotificationSettings(projectId, updateData)
  }

  const disableNotification = (orgId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [], disable: [{ type, channel }] }
    return updateProjectNotificationSettings(orgId, updateData)
  }

  const initProjectUpdate = async (projectId: string, updateData: InitProjectUpdateData) => {
    switch (updateData.field) {
      case "title":
      case "description":
      case "ganttBarColor":
        return await updateInfo(projectId, { [updateData.field]: updateData.value })

      case "projectNumber":
        return await updateProjectNumber(projectId, updateData.value)
      case "state":
      case "statusDescription":
        return await updateStatusDescription(projectId, { [updateData.field]: updateData.value })
      case "plannedStartDate":
      case "plannedEndDate":
        return await updatePlan(projectId, { [updateData.field]: updateData.value })
      case "customers":
        return await updateCustomers(projectId, updateData.value)
      case "managers":
        return updateManagers(projectId, updateData.value)
      case "participants":
        return updateParticipants(projectId, updateData.value)
      case "suppliers":
        return await updateSuppliers(projectId, updateData.value)
      case "workspaces":
        return await updateWorkspaces(projectId, updateData.value)
      case "completionPercentage":
        return await updateProjectCompletionPercentage(projectId, parseInt(updateData.value))
      default:
        return null
    }
  }

  return {
    createOrgProject,
    createUserProject,
    updateInfo,
    updatePlan,
    updateProjectNumber,
    updateStatus,
    updateStatusDescription,
    updatePermissions,
    addManagers,
    removeManagers,
    addParticipants,
    removeParticipants,
    addCustomers,
    removeCustomers,
    addSuppliers,
    removeSuppliers,
    addWorkspaces,
    removeWorkspaces,
    saveBasePlan,
    updateTimeComponent,
    archiveProject,
    unarchiveProject,
    deleteProject,
    initProjectUpdate,
    updateProjectNotificationSettings,
    enableNotification,
    disableNotification,
    createProjectFromTemplate,
    updateProjectCompletionPercentage,
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

type ResourceUpdateData = {
  add: string[]
  remove: string[]
}

type InitProjectUpdateData =
  | {
      field:
        | "title"
        | "description"
        | "ganttBarColor"
        | "projectNumber"
        | "state"
        | "statusDescription"
        | "actualStartDate"
        | "actualEndDate"
        | "plannedStartDate"
        | "plannedEndDate"
        | "completionPercentage"
      value: string
    }
  | {
      field: "status"
      value: Status
    }
  | {
      field: "customers" | "managers" | "participants" | "suppliers" | "workspaces"
      value: ResourceUpdateData
    }
