import { z } from "zod"
import { CustomStatusMapSchema } from "./organisation-response"
import type { CustomStatus, CustomStatusMap, SystemStatusId as SystemStatusIdType } from "./organisation-response"

export const ProjectTypeResponseSchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  type: z.string(),
  createdBy: z.string(),
  isVisibleInOptions: z.boolean(),
  isDefault: z.boolean(),
  projectCustomStatuses: CustomStatusMapSchema,
})

export type ProjectTypeResponse = z.infer<typeof ProjectTypeResponseSchema>
export type SystemStatusId = SystemStatusIdType
export type ProjectCustomStatus = CustomStatus
export type ProjectCustomStatusMap = CustomStatusMap
