import AppBackdrop from "./AppBackdrop"
import React from "react"
import { useSelector } from "react-redux"
import { Route, Redirect, RouteProps, useLocation } from "react-router-dom"
import { getAuthState } from "../users/store/selectors"
import { useAppDispatch } from "../store"
import { FetchUser, AutoRefreshAccessToken } from "../users/store/actions"
import { Alert } from "@material-ui/lab"
import { unwrapResult } from "@reduxjs/toolkit"
import { FetchContextOptions } from "../context-options/store/actions"
import { FetchUserOptions } from "../options/store/actions"
import { FetchUserMemberships } from "../memberships/store"
import { FetchMembershipPermissions } from "../permissions/store/actions"
import { FetchUserInvitations } from "../invitations/store/actions"
import { IUserNormalized } from "../users/interfaces/user-normalized"
import { paths } from "../paths"
import { IMembershipResponse } from "../memberships/interfaces/membership-response"
import { useTranslation } from "react-i18next"

const AuthRequiredRoute = ({ component: Component, ...rest }: AuthRequiredRouteProps) => {
  const { i18n } = useTranslation()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const { user, isAuthenticated, authError, accessToken, tokenRefreshStatus } = useSelector(getAuthState)
  const [status, setStatus] = React.useState<LoadingStatus>("idle")
  const isRejected = status === "rejected"
  const showError = isRejected || authError
  const errorMessage = authError || "An error occurred while fetching user data"

  React.useEffect(() => {
    async function autoRefreshAccessToken() {
      if (isAuthenticated && tokenRefreshStatus === "idle") {
        await dispatch(AutoRefreshAccessToken())
      }
    }
    autoRefreshAccessToken()
  }, [dispatch, isAuthenticated, tokenRefreshStatus])

  React.useEffect(() => {
    async function fetchUser() {
      if (!isAuthenticated || user || tokenRefreshStatus !== "fulfilled") return
      const fetchUserResult = await dispatch(FetchUser())
      setStatus(fetchUserResult.meta.requestStatus)
    }
    fetchUser()
  }, [dispatch, isAuthenticated, user, tokenRefreshStatus])

  React.useEffect(() => {
    if (user && user.language !== i18n.language) {
      i18n.changeLanguage(user.language)
    }
  }, [user, i18n])

  React.useEffect(() => {
    if (accessToken) {
      dispatch(FetchUserOptions())
    }
  }, [location.pathname, accessToken, dispatch])

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) return <Redirect to={{ pathname: paths.login(), state: { from: props.location } }} />
        if (user) return <InitialDataLoader {...props} component={Component} user={user} />
        if (showError) return <ErrorComponent message={errorMessage} />
        return <AppBackdrop timeout={60000} />
      }}
    />
  )
}

const InitialDataLoader = ({ component: Component, user, ...rest }: InitialDataLoaderProps) => {
  const dispatch = useAppDispatch()
  const [status, setStatus] = React.useState<LoadingStatus>("loading")
  const isLoading = status === "loading"
  const isRejected = status === "rejected"

  React.useEffect(() => {
    if (user.isActive) {
      loader()
    } else {
      setStatus("fulfilled")
    }

    async function loader() {
      try {
        const resolvedActions = await Promise.all([
          dispatch(FetchUserMemberships()),
          dispatch(FetchContextOptions()),
          dispatch(FetchUserOptions()),
          dispatch(FetchUserInvitations()),
        ])
        const unwrappedResults = resolvedActions.map(unwrapResult)
        const userMemberships = unwrappedResults[0] as IMembershipResponse[]
        const userMembershipPermissionPromises = userMemberships.map((membership) => {
          return dispatch(FetchMembershipPermissions(membership.id))
        })
        const dispatchResults = await Promise.all(userMembershipPermissionPromises)
        dispatchResults.forEach((result) => unwrapResult(result))
        setStatus("fulfilled")
      } catch (error) {
        setStatus("rejected")
      }
    }
  }, [dispatch, user])

  if (isLoading) return <AppBackdrop />
  if (isRejected) return <ErrorComponent message="An error occurred while loading initial data" />
  return <Component {...rest} />
}

type AuthRequiredRouteProps = RouteProps & {
  component: React.ComponentType<any>
}

type InitialDataLoaderProps = {
  component: React.ComponentType<any>
  user: IUserNormalized
}

type LoadingStatus = "loading" | "fulfilled" | "rejected" | "idle"

const ErrorComponent = ({ message }: { message: string }) => {
  return (
    <div style={{ maxWidth: 550, padding: 20, margin: "80px auto" }}>
      <Alert severity="error">An error occurred: {message}</Alert>
    </div>
  )
}

export default AuthRequiredRoute
