export const isBasicRoute = (pathname: string) => pathname.includes("basic")
export const isSubtasksRoute = (pathname: string) => pathname.includes("subtasks")
export const isKanbanRoute = (pathname: string) => pathname.includes("kanban")
export const isTodoListRoute = (pathname: string) => pathname.includes("todos")
export const isCalendarRoute = (pathname: string) => pathname.includes("calendar")

export const getActiveTabIndex = (pathname: string) => {
  if (isBasicRoute(pathname)) return 0
  if (isSubtasksRoute(pathname)) return 1
  if (isKanbanRoute(pathname)) return 2
  if (isCalendarRoute(pathname)) return 3
  if (isTodoListRoute(pathname)) return 4
  throw new Error("Invalid pathname")
}
