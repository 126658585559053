import React, { useState } from "react"
import MuiCheckbox, { CheckboxProps } from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { MoreVertical } from "react-feather"

import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"
import { Check, Square } from "react-feather"
import { makeStyles, Theme } from "@material-ui/core"
import { COLOR_BLACK } from "../../constants"

const CalendarViewHeader = ({
  titlePrefix,
  showEvents,
  showTasks,
  showTodos,
  toggleCalendarEvents,
  toggleTasks,
  toggleTodos,
}: CalendarViewHeaderProps) => {
  const translations = useTranslations(defaultTranslations)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const pageTitle = titlePrefix ? `${titlePrefix} - ${translations.pageTitle}` : translations.pageTitle

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <Typography variant="h6">{pageTitle}</Typography>
      </Grid>
      <Grid item>
        <IconButton onClick={(e) => setAnchorEl(e.currentTarget)} color="inherit">
          <MoreVertical size={20} />
        </IconButton>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
          <MenuItem style={{ paddingRight: 0 }}>
            <FormControlLabel
              label={translations.calendarEventsLabel}
              control={<Checkbox onChange={toggleCalendarEvents} checked={showEvents} />}
              style={{ minWidth: "100%" }}
            />
          </MenuItem>
          <MenuItem style={{ paddingRight: 0 }}>
            <FormControlLabel
              label={translations.tasksLabel}
              style={{ minWidth: "100%" }}
              control={<Checkbox onChange={toggleTasks} checked={showTasks} />}
            />
          </MenuItem>
          <MenuItem style={{ paddingRight: 0 }}>
            <FormControlLabel
              label={translations.todosLabel}
              style={{ minWidth: "100%" }}
              control={<Checkbox onChange={toggleTodos} checked={showTodos} />}
            />
          </MenuItem>
        </Menu>
      </Grid>
    </Grid>
  )
}

const Checkbox = (props: CheckboxProps) => {
  const classes = useStyles()
  return (
    <MuiCheckbox
      checkedIcon={<Check className={classes.checkIconChecked} size={18} />}
      icon={<Square size={18} color={COLOR_BLACK} />}
      {...props}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  checkIconChecked: {
    background: theme.palette.primary.main,
    stroke: theme.palette.common.white,
    borderRadius: 3,
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t?.calendarViewHeader || {}) as StringMap

  const {
    pageTitle = defaults.pageTitle,
    tasksLabel = defaults.tasksLabel,
    calendarEventsLabel = defaults.calendarEventsLabel,
    todosLabel = defaults.todosLabel,
  } = translations

  return {
    pageTitle,
    tasksLabel,
    calendarEventsLabel,
    todosLabel,
  }
}

const defaultTranslations = {
  pageTitle: "Calendar",
  tasksLabel: "Tasks",
  calendarEventsLabel: "Calendar events",
  todosLabel: "Todos",
}

type Translations = typeof defaultTranslations

type CalendarViewHeaderProps = {
  titlePrefix?: string
  showEvents: boolean
  showTasks: boolean
  showTodos: boolean
  toggleCalendarEvents: () => void
  toggleTasks: () => void
  toggleTodos: () => void
}

export default CalendarViewHeader
