export enum AccountStatus {
  WAITING_VERIFICATION = "Waiting Verification",
  ACTIVE = "Active",
  DEACTIVATED = "Deactivated",
}

export enum Priority {
  LOW = "Low",
  MEDIUM = "Medium",
  HIGH = "High",
  URGENT = "Urgent",
}

export enum StatusGeometricShape {
  CIRCLE = "Circle",
  TRIANGLE = "Triangle",
  SQUARE = "Square",
  DIAMOND = "Diamond",
}

export enum Status {
  NOT_STARTED = "Not Started",
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
  REJECTED = "Rejected",
}

export enum GroupBy {
  NO_GROUPING = "No Grouping",
  MANAGERS = "Group by Managers",
  PARTICIPANTS = "Group by Participants",
  CUSTOMERS = "Group by Customers",
  SUPPLIERS = "Group by Suppliers",
  WORKSPACES = "Group by Workspaces",
}

export enum NetworkStatusEnum {
  IDLE = "idle",
  PENDING = "pending",
  FULFILLED = "fulfilled",
  REJECTED = "rejected",
}

export enum DateFormat {
  DD_MM_YYYY = "dd.mm.yyyy",
}

export enum MaintainerTypeEnum {
  ORG = "Organisation",
  USER = "User",
}

export interface UserAvatar {
  id: string
  fullname: string
  email: string
  avatarURL: string
  initials: string
}

export interface CustomStatusMap {
  label: string
  hasStartDate: boolean
  hasEndDate: boolean
  shape: StatusGeometricShape
  color: string
}

export interface IComment {
  id?: string
  text: string
  commenter: string // for saving full name of commenter
  commentDate: Date
  commenterAvatarURL: string
  replies: IComment[]
}

export interface ApiErrorResponse {
  success: false
  httpStatus: 400 | 401 | 404 | 500
  message: string
  error?: string
}

export interface ApiSuccessResponse<T> {
  success: true
  httpStatus: 200
  message: string
  data: T
}

export type ApiResponse<T> = ApiErrorResponse | ApiSuccessResponse<T>

export type NetworkState = "idle" | "pending" | "fulfilled" | "rejected"

export type NetworkStatus =
  | NetworkStatusEnum.IDLE
  | NetworkStatusEnum.PENDING
  | NetworkStatusEnum.FULFILLED
  | NetworkStatusEnum.REJECTED

export type Maintainer = "org" | "user"

export type StringMap = { [k: string]: string }
export type GanttDependencyType = "fs" | "ss" | "sf" | "ss"
export type LinkType = {
  label: string
  href: string
  disabled: boolean
}
