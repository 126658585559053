import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import ReminderList from "../../../reminders/components/ReminderList"
import { BaseNewReminderData, useGetRemindersQuery } from "../../../reminders/api"
import { useGetTaskByIdQuery } from "../../../tasks/api"
import { guessReminderTime } from "../../../reminders/utils/guess-reminder-time"
import { isDate } from "lodash"
import { useReminderMutations } from "../../../reminders/hooks/use-reminder-mutations"
import { ReminderViewModel } from "../../../reminders/api/reminder"
import { useI18n, useRouter } from "../../../hooks"
import { ArrowLeft } from "react-feather"
import { paths } from "../../../paths"
import { Status } from "../../../types/common"
import { makeStyles, Theme } from "@material-ui/core"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TaskNotificationsRoute = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const router = useRouter()
  const { taskId } = router.query as { taskId: string }
  const { data: reminders = [], isLoading: isLoadingReminders } = useGetRemindersQuery({ task: taskId }, queryOptions)
  const { data: task, isLoading: isLoadingTask } = useGetTaskByIdQuery(taskId)
  const { createReminder } = useReminderMutations()

  const isLoading = isLoadingReminders || isLoadingTask
  const refStartDate = task?.plannedStartDate ? new Date(task.plannedStartDate) : null
  const refEndDate = task?.plannedEndDate ? new Date(task.plannedEndDate) : null
  const taskStatusOptions = task?.statusOptions || []
  const reminderTriggerStatusOptions = taskStatusOptions.filter((option) => option.value !== Status.REJECTED)

  const createNewReminder = async () => {
    const guessedReminderTime = guessReminderTime(refStartDate, refEndDate)
    const reminderData: Omit<BaseNewReminderData, "triggerType"> = {
      entityId: taskId,
      entityType: "task",
      recipients: {
        roles: ["taskManager"],
        emails: [],
      },
    }

    const reminderPromise = isDate(guessedReminderTime)
      ? createReminder({
          ...reminderData,
          reminderTime: guessedReminderTime.toISOString(),
          reminderTimeDetails: undefined,
          triggerType: "absoluteTime",
        })
      : createReminder({
          ...reminderData,
          reminderTime: undefined,
          reminderTimeDetails: guessedReminderTime,
          triggerType: "relativeTime",
        })

    return (await reminderPromise) as ReminderViewModel
  }

  const navigateToTask = () => {
    const taskBasic = `${paths.taskBasic(taskId)}${router.location.search}`
    router.history.push(taskBasic)
  }

  return (
    <Box className={classes.container}>
      <Button className={classes.backButton} onClick={navigateToTask} startIcon={<ArrowLeft size={16} />}>
        {`${translations.backTo}: ${task?.title}`}
      </Button>
      <Paper elevation={0}>
        <Typography variant="h5" gutterBottom>
          {translations.taskCustomNotifications}
        </Typography>
        {isLoading && <CircularProgress />}
        {!isLoading && task && (
          <ReminderList
            reminders={reminders}
            refStartDate={refStartDate}
            refEndDate={refEndDate}
            onCreateReminder={createNewReminder}
            canCreateReminder={task.canManageTaskNotifications}
            statusOptions={reminderTriggerStatusOptions}
          />
        )}
      </Paper>
    </Box>
  )
}

const defaultTranslations = {
  taskCustomNotifications: "Task custom notifications",
  backTo: "Back to",
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { t } = useI18n("translation")

  return {
    taskCustomNotifications: t("taskCustomNotifications", defaults.taskCustomNotifications),
    backTo: t("backTo", defaults.backTo),
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    margin: theme.spacing(8, "auto"),
    maxWidth: 800,
    "& > .MuiPaper-root": {
      padding: theme.spacing(2),
    },
  },
  backButton: {
    marginBottom: theme.spacing(2),
  },
}))

export default TaskNotificationsRoute

type Translations = typeof defaultTranslations
