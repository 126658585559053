import { TaskColumnName } from "./types"
import { Option } from "../types"
import { makeColumns } from "./columns"
import { TreeGridTranslations } from "../hooks/use-translations"
import { TimeZoneType } from "../../constants/timezones"
import { makeColorKeysEnum, makeGroupByEnum, mapGroupByToColumns, mapGroupByToTranslations } from "../utils"
import { GroupBy } from "../../types/common"
import { HolidayViewModel } from "../../holidays/api/holiday"

export const makeTreegridLayout = (props: CreateTreegridLayoutProps) => {
  const Cfg = makeCfg(props)
  const Cols = makeColumns(props)
  const Head = makeHead(props)
  const Header = makeHeader({ columns: Cols.columns, ...props })
  const Lang = makeLang(props)
  const Toolbar = makeToolbar(props)
  const Actions = makeActions()
  const Solid = [{ id: "NoData", Html: props.translations.noData }]

  return {
    Cfg,
    LeftCols: Cols.columns,
    RightCols: Cols.rightColumns,
    Head,
    Header,
    Lang,
    Toolbar,
    Actions,
    Solid,
    Def: makeDef({ showGantt: props.showGantt }),
  }
}

const makeCfg = ({ id, groupBy }: { id: string; groupBy: GroupBy }) => {
  /** @link link for documentation: https://www.treegrid.com/Doc/SearchAttr.html */
  return {
    id,
    SuppressCfg: process.env.NODE_ENV === "development" ? 1 : 0,
    CfgId: id,
    Style: "white", // Base treegrid style
    GanttStyle: "Material", // Base Gantt style
    Selecting: 0, // disables selecting of rows
    Dragging: 0, // disables dragging of rows
    ScrollLeft: 0, // sets initial horizontal scroll of middle (variable) columns, in pixels.
    LeftScrollLeft: 0, // sets initial horizontal scroll of left columns, in pixels.
    RightScrollLeft: 0, // sets initial horizontal scroll of right columns, in pixels.
    LeftCanResize: 4,
    RightCanResize: 4,
    SectionShrinkOrder: ["Right,Mid,Left"], // shrinks right first if width is not enough to render all sections
    PrintVisible: 1,
    PrintExpanded: 1,
    PrintPageOrientation: 1,
    PrintPaddingHeight: 20,
    PDFFitPage: 1,
    Code: process.env.REACT_APP_TREEGRID_CODE,
    Sort: "plannedEndDate, plannedStartDate",
    SearchAction: "Filter",
    Group: mapGroupByToColumns(groupBy),
    GroupMain: "title",
    WordWrap: 0,
    SaveAttrs: "Toolbar,SelectGroupBy",
  }
}

const makeDef = (props: { showGantt: boolean }) => {
  return [
    {
      Name: "Run",
      Def: "Group",
      Calculated: "1",
      RUNFormula: props.showGantt ? "ganttrunsum()" : "",
      Expanded: "1",
      Height: "48",
      titleClass: "group-header",
      Spanned: "1",
      titleSpan: "17",
      openSpan: "3",
    },
  ]
}

const makeHead = ({ dateFormat, gridInfo, showFilters, translations }: MakeHeadProps) => {
  const headerRow = {
    id: "headerRow",
    Name: translations.pageHeaderRowLabel,
    Space: -1,
    Visible: 0,
    PanelVisible: 0,
    CanExport: 0,
    CanPrint: 2,
    Cells: `Logo,GridInfo,PrintDate`,
    LogoRelWidth: 1,
    LogoAligh: "Left",
    LogoVAligh: "middle",
    LogoType: "Html",
    Logo: `<img style="height: 40px;" src="/favicon.png"  alt="company logo"/>`,
    PrintDate: new Date(),
    PrintDateType: "Date",
    PrintDateFormat: dateFormat,
    PrintDateCanEdit: 0,
    GridInfo: `<span class="largeText">${gridInfo}<span>`,
    GridInfoType: "Html",
    GridInfoRelWidth: 2,
  }
  const filtersRow = {
    CanExport: 0,
    CanPrint: 0,
    id: "filterRow",
    Kind: "Filter",
    ganttBarColorRange: 1,
    ganttBarColorFilterEnumKeys: 1,
    ganttBarColorEnumKeys: makeColorKeysEnum(translations),
    statusFilterOff: translations.statusFilterOffLabel,
    managersFilterOff: translations.responsibleFilterOffLabel,
    participantsFilterOff: translations.participantsFilterOffLabel,
    suppliersFilterOff: translations.suppliersFilterOffLabel,
    workspacesFilterOff: translations.workspacesFilterOffLabel,
    stateFilterOff: translations.stateFilterOffLabel,
    customersFilterOff: translations.customersFilterOffLabel,
    Visible: showFilters ? 1 : 0,
  }

  return [headerRow, filtersRow]
}

const makeLang = ({ firstDayOfWeek, dateSeparator, translations }: MakeLangProps) => {
  return {
    Format: {
      FirstWeekDay: firstDayOfWeek,
      GMT: 1,
      DateSeparator: dateSeparator,
      LongDayNames: translations.longDayNames,
      ShortDayNames: translations.shortDayNames,
      Day2CharNames: translations.day2CharNames,
      Day1CharNames: translations.day1CharNames,
      LongMonthNames: translations.longMonthNames,
      LongMonthNames2: translations.longMonthNames,
      ShortMonthNames: translations.shortMonthNames,
    },
    MenuColumns: {
      Caption: translations.chooseItemsLabel,
      ColsCaption: translations.chooseColumnsLabel,
      SpaceCaption: translations.chooseToolbarsLabel,
      RowsCaption: translations.chooseRowsLabel,
    },
    MenuButtons: {
      Ok: translations.okButtonLabel,
      Cancel: translations.cancelButtonLabel,
      HideAll: translations.hideAllButtonLabel,
      All: translations.allOnButtonLabel,
      Clear: translations.clear,
    },
    MenuPrint: {
      Caption: translations.printOptionsLabel,
      PrintOptions: translations.printOptionsLabel,
      PrintRows: translations.printMaxRowsPerPageLabel,
      PrintPageRoot: translations.printStartRowLabel,
      PrintPageSize: translations.printPageSizeLabel,
      PrintPageOptions: translations.printBrowserPrintInfoLabel,
      PrintPageOrientation: translations.printPageOrientationLabel,
      PrintPageOrientations: `${translations.portraitLabel},${translations.landscapeLabel}`,
      PrintMarginWidth: translations.printMarginWidthLabel,
      PrintMarginHeight: translations.printMarginHeightLabel,
      PDFFitPage: translations.printFitToPageLabel,
      PDFFitPages: `${translations.noneLabel},${translations.widthLabel},${translations.heightLabel},${translations.singlePageLabel}`,
      PrintDPI: translations.printDPIRatioLabel,
      PrintWidth: translations.printPageWidthLabel,
      PrintHeight: translations.printPageHeightLabel,
      PrintResults: translations.printSizeInfo,
    },
    MenuExport: {
      Caption: translations.exportOptionsLabel,
      ExportOptions: translations.exportOptionsLabel,
      ExportName: translations.exportFileNameLabel,
      ExportFormat: translations.exportFileFormatLabel,
      ExportExpanded: translations.exportAllRowsExpandedLabel,
      ExportOutline: translations.exportTreeInExcelLabel,
      ExportIndent: translations.exportIndentTreeInMainColumnLabel,
      ExportFixedRows: translations.exportFreezeHeadRowsLabel,
      ExportFixedCols: translations.exportFreezeLeftColumnsLabel,
      ExportEmpty: translations.exportEmptyCellsLabel,
    },
    MenuFilter: {
      F0: translations.filterOffLabel,
      F1: translations.filterEqualLabel,
      F2: translations.filterNotEqualLabel,
      F3: translations.filterLessThanLabel,
      F4: translations.filterLessThanOrEqualLabel,
      F5: translations.filterGreaterThanLabel,
      F6: translations.filterGreaterThanOrEqualLabel,
      F7: translations.filterBeginsWithLabel,
      F8: translations.filterDoesnotBeginWithLabel,
      F9: translations.filterEndsWithLabel,
      F10: translations.filterDoesnotEndWithLabel,
      F11: translations.filterContainsLabel,
      F12: translations.filterDoesnotContainLabel,
    },
  }
}

const makeToolbar = ({
  showFilters,
  translations,
  showGanttToolbarActions,
  groupBy,
  showGroupBy,
}: MakeToolbarProps) => {
  /**
   * @link https://www.treegrid.com/Doc/GlobalSettings.htm#DefaultToolbar
   */
  let toolbar: any = {
    AddChild: 0,
    Cfg: 0,
    Contrasts: 0,
    Debug: 0,
    DefSort: 0,
    GanttStyles: 0,
    Help: 0,
    Indent: 0,
    Lock: 0,
    Outdent: 0,
    Reload: 0,
    Sizes: 0,
    Space: 0,
    Styles: 0,
    WinScroll: 0,
    Correct: 0,
    Add: 0,
    Link: 0,

    Cells10Search: "Search,Summary",
    SearchLabel: translations.toolbarSearchInputLabel,
    SearchTip: translations.toolbarSearchInputTip,
    SearchType: "Text",
    SearchCanEdit: 1,
    SearchWidth: 200,
    SearchOnClick: "Focus AND StartEdit",
    SearchOnChange: "window.Grids.OnSearchChange(Grid, Value)",

    SummaryType: "Html",
    SummaryCanEdit: 0,
    Summary: translations.toolbarTaskListSummaryText,

    Cells60Filter: "Filter",
    FilterType: "Button",
    FilterButtonClass: showFilters ? "Filter_Button_Active" : "Filter_Button",
    FilterOnClick: "window.Grids.OnFilterToggle()",

    // To turn off the GanttZoom icons on Cells 50
    Cells50GanttZoom: "",

    // A custom empty cell to push the GanttZoom to the right
    Cells80Right: "Spacer",
    SpacerHtml: "<span></span>",
    SpacerRelWidth: "1",

    Cells99GanttZoom: showGanttToolbarActions ? "ZoomIn,ZoomOut,ZoomFit,Zoom,Prev,Today,Next" : "",
    ZoomType: "SelectGanttZoom",
    ZoomHtmlPrefix: `${translations.showLabel} <b>`,
    ZoomHtmlPostfix: "</b>",
    ZoomWidth: "184",

    PrintTip: translations.toolbarPrintTip,
    ExportTip: translations.toolbarExportTip,
    ExpandAllTip: translations.toolbarExpandAllTip,
    CollapseAllTip: translations.toolbarCollapseAllTip,
    ColumnsTip: translations.toolbarColumnsTip,
    FilterTip: translations.toolbarFilterTip,
    ScalesLabel: translations.scaleLabel,
  }

  if (showGroupBy) {
    toolbar = {
      ...toolbar,
      Cells80Right: "SelectGroupBy",
      SelectGroupByType: "Enum",
      SelectGroupByEnum: makeGroupByEnum({ withResponsibles: true, translations }),
      SelectGroupBy: mapGroupByToTranslations({ withResponsibles: true, translations })[groupBy],
      SelectGroupByWidth: 200,
      SelectGroupByOnChange: "window.Grids.OnSelectGroupByChange(Grid, Value)",
      Cells90Right: "Spacer",
    }
  }

  return toolbar
}

const makeHeader = ({ columns, translations }: MakeHeaderProps) => {
  const Header: Record<string, string | number> = {
    Name: translations.headerRowLabel,
    plannedStartDateClass: "text-right",
    plannedEndDateClass: "text-right",
    actualStartDateClass: "text-right",
    actualEndDateClass: "text-right",
    statusClass: "text-center",
    ganttBarColorClass: "text-center",
    Wrap: 1,
  }
  columns.forEach((col) => {
    if (col.Name) {
      Header[col.Name] = col.header as string
    }
  })
  return Header
}

const makeActions = () => {
  return {
    OnIns: "", // This is for resetting the default behavior of treegrid, which is to AddRow on insert key press
    OnDel: "", // This is for resetting the default behavior of treegrid, which is to DeleteRow on delete key press
  }
}

type MakeHeadProps = {
  showFilters: boolean
  dateFormat: string
  gridInfo: string
  translations: TreeGridTranslations
}

type MakeLangProps = {
  firstDayOfWeek: DayOfWeek
  dateSeparator: string
  translations: TreeGridTranslations
}

type MakeToolbarProps = {
  showFilters: boolean
  showGanttToolbarActions: boolean
  translations: TreeGridTranslations
  groupBy: GroupBy
  showGroupBy: boolean
}

type MakeHeaderProps = {
  columns: { header?: string; Name?: string }[]
  translations: TreeGridTranslations
}

type CreateTreegridLayoutProps = {
  id: string
  dateFormat: string
  dateSeparator: string
  translations: TreeGridTranslations
  defaultColumnOrder: TaskColumnName[]
  defaultVisibleColumns: TaskColumnName[]
  showFilters: boolean
  gridInfo: string
  options: Option[]
  firstDayOfWeek: DayOfWeek
  showGantt: boolean
  showGanttToolbarActions: boolean
  timeZone: TimeZoneType
  weekendDays: DayOfWeek[]
  groupBy: GroupBy
  showGroupBy: boolean
  holidays: HolidayViewModel[]
}
