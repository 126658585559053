import { addDays } from "date-fns"
import { TimeZoneType } from "../../constants/timezones"
import { DateTimeService } from "../../services/date-time-service"
import { HolidayViewModel, RangeHolidayViewModel, SingleHolidayViewModel } from "../api/holiday"

export class HolidaysProcessor {
  private holidays: HolidayViewModel[]
  private dateTimeService: DateTimeService

  constructor({ holidays, timeZone }: { holidays: HolidayViewModel[]; timeZone: TimeZoneType | undefined }) {
    this.dateTimeService = new DateTimeService({ timeZone })
    this.holidays = holidays
  }

  public getSingleHolidays(): SingleHolidayViewModel[] {
    const singleHolidays = this.holidays.filter((h) => h.isSingleHoliday) as [SingleHolidayViewModel]
    return singleHolidays.map((h) => ({
      ...h,
      date: this.dateTimeService.removeTimezoneOffset(h.date),
      recurring: h.recurring,
    }))
  }

  public getRangeHolidays(): RangeHolidayViewModel[] {
    const rangeHolidays = this.holidays.filter((h) => h.isRangeHoliday) as [RangeHolidayViewModel]
    return rangeHolidays.map((h) => ({
      ...h,
      startDate: this.dateTimeService.removeTimezoneOffset(h.startDate),
      endDate: this.dateTimeService.removeTimezoneOffset(h.endDate),
      recurring: h.recurring,
    }))
  }

  private getMatchingSingleHoliday(date: Date): SingleHolidayViewModel | undefined {
    const month = date.getMonth()
    const day = date.getDate()
    const year = date.getFullYear()

    const singleHolidays = this.getSingleHolidays()
    const foundHoliday = singleHolidays.find((h) => {
      const holidayYear = h.date.getFullYear()
      const holidayMonth = h.date.getMonth()
      const holidayDay = h.date.getDate()
      if (h.recurring) return month === holidayMonth && day === holidayDay
      return month === holidayMonth && day === holidayDay && year === holidayYear
    })
    return foundHoliday
  }

  private getMatchingRangeHoliday(date: Date): RangeHolidayViewModel | undefined {
    const month = date.getMonth()
    const day = date.getDate()
    const year = date.getFullYear()

    const rangeHolidays = this.getRangeHolidays()
    const foundInHolidayRange = rangeHolidays.find((h) => {
      const splittedDates = this.splitRangeHolidayToSingleHolidays(h)
      const foundDate = splittedDates.find((d) => {
        const holidayYear = d.getFullYear()
        const holidayMonth = d.getMonth()
        const holidayDay = d.getDate()
        if (h.recurring) return month === holidayMonth && day === holidayDay
        return month === holidayMonth && day === holidayDay && year === holidayYear
      })
      return Boolean(foundDate)
    })
    return foundInHolidayRange
  }

  private splitRangeHolidayToSingleHolidays(holiday: { startDate: Date; endDate: Date }): Date[] {
    const startDate = holiday.startDate
    const endDate = holiday.endDate
    const singleHolidays: Date[] = []

    const numberOfDays = Math.floor((endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24))
    for (let i = 0; i <= numberOfDays; i++) {
      const date = new Date(startDate)
      const nextDate = addDays(date, i)
      singleHolidays.push(nextDate)
    }

    return singleHolidays
  }

  public isHoliday(date: Date): boolean {
    const isSingleHoliday = Boolean(this.getMatchingSingleHoliday(date))
    const isRangeHoliday = Boolean(this.getMatchingRangeHoliday(date))
    return isSingleHoliday || isRangeHoliday
  }

  public getHolidayName(date: Date): string {
    let holidayName = ""
    const singleHoliday = this.getMatchingSingleHoliday(date)
    if (singleHoliday) holidayName = singleHoliday.name
    const rangeHoliday = this.getMatchingRangeHoliday(date)
    if (rangeHoliday) {
      if (holidayName) holidayName += ", "
      holidayName += `${rangeHoliday.name}`
    }
    return holidayName
  }
}
