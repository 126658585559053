import { i18n, TFunction } from "i18next"
import { useTranslation } from "react-i18next"

interface Translations {
  [translationKey: string]: any
}
interface ReturnType {
  i18n: i18n
  t: TFunction
  translations: Translations
}

const useI18n = (ns = "translation"): ReturnType => {
  const { t, i18n } = useTranslation(ns)
  const i18nStoreData = i18n.store.data
  const languageData = i18nStoreData[i18n.language] || i18nStoreData.en
  const translations = (languageData?.[ns] || {}) as Translations

  return {
    i18n,
    t,
    translations,
  }
}

export { useI18n }
