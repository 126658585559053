import { isAfter } from "date-fns"
import { DateTimeService } from "../../services/date-time-service"
import { TaskViewModel } from "../api/task"

export const isTaskPlannedStartDatePastDue = (props: { task: TaskViewModel; dateTimeService: DateTimeService }) => {
  const { task, dateTimeService } = props
  if (!task || !task.plannedStartDate) return false
  const compareToDate = task.enableTimeComponent
    ? task.plannedStartDate
    : dateTimeService.endOfDay(new Date(task.plannedStartDate))
  const timezonedCompareToDate = dateTimeService.removeTimezoneOffset(compareToDate)
  const isPastDue = task.isNotStarted ? isAfter(new Date(), new Date(timezonedCompareToDate)) : false
  return isPastDue
}

export const isTaskPlannedEndDatePastDue = (props: { task: TaskViewModel; dateTimeService: DateTimeService }) => {
  const { task, dateTimeService } = props
  if (!task || !task.plannedEndDate) return false
  const compareToDate = task.enableTimeComponent
    ? task.plannedEndDate
    : dateTimeService.endOfDay(new Date(task.plannedEndDate))
  const timezonedCompareToDate = dateTimeService.removeTimezoneOffset(compareToDate)
  const isPastDue = !task.isCompleted ? isAfter(new Date(), new Date(timezonedCompareToDate)) : false
  return isPastDue
}
