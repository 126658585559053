import * as actions from "../store/actions"
import * as orgActions from "../../organisations/store/actions"
import { createSlice } from "@reduxjs/toolkit"
import { IMembershipNormalized } from "../interfaces/membership-normalized"
import { setMembershipCase, setMembershipsCase, deleteOrganisationCase } from "./case-reducers"

const initialState: MembershipsState = {
  byIds: {},
  allIds: [],
}

const membershipsSlice = createSlice({
  name: "memberships",
  initialState,
  reducers: {
    SetMembership: setMembershipCase,
    SetMemberships: setMembershipsCase,
  },
  extraReducers: (builder) => {
    builder.addCase(actions.FetchUserMemberships.fulfilled, setMembershipsCase)
    builder.addCase(actions.FetchOrgMemberships.fulfilled, setMembershipsCase)
    builder.addCase(actions.FetchMembershipById.fulfilled, setMembershipCase)
    builder.addCase(actions.TransferOwnership.fulfilled, setMembershipsCase)
    builder.addCase(orgActions.DeleteOrganisation.fulfilled, deleteOrganisationCase)
    builder.addCase(actions.AddMembership.fulfilled, setMembershipCase)
    builder.addCase(actions.UpdateMembershipInfo.fulfilled, setMembershipCase)
    builder.addCase(actions.SendInvitation.fulfilled, setMembershipCase)
    builder.addCase(actions.CancelInvitation.fulfilled, setMembershipCase)
    builder.addCase(actions.ActivateMemberships.fulfilled, setMembershipsCase)
    builder.addCase(actions.DeactivateMemberships.fulfilled, setMembershipsCase)
  },
})

export const { SetMembership, SetMemberships } = membershipsSlice.actions
export default membershipsSlice.reducer
export type MembershipsState = {
  byIds: { [id: string]: IMembershipNormalized }
  allIds: string[]
}
export * from "./actions"
