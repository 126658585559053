import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { ArrowRight, Check } from "react-feather"
import { Theme, alpha, makeStyles } from "@material-ui/core"
import { useContextOptions } from "../../context-options/hooks/use-context-options"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { useHistory } from "react-router-dom"
import { IInvitationResponse } from "../interfaces/invitation-response"
import { paths } from "../../paths"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"

const AcceptedInvitationCard = ({ invitation }: { invitation: IInvitationResponse }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { userMembershipContextOptions } = useContextOptions()
  const { createPathWithGivenContext } = useUrlWithContext()
  const history = useHistory()

  const onClickGoToOrg = () => {
    const subContext = userMembershipContextOptions?.find((option) => option.orgId === invitation.orgId)
    const path = createPathWithGivenContext({
      path: paths.home(),
      mainContextId: invitation.orgId,
      subContextId: subContext?.id,
    })
    history.push(path)
  }

  return (
    <Paper className={classes.paper}>
      <Grid spacing={4} container>
        <Grid className={classes.left} item>
          <Avatar className={classes.avatar}>
            <Check />
          </Avatar>
        </Grid>
        <Grid item>
          <div className={classes.message}>
            <Typography variant="h5" component="p">
              {translations.welcomeMessageTitle}
            </Typography>
            <Typography variant="body1" color="textPrimary" component="p" data-test="welcome-message">
              {translations.welcomeMessageDescription}
              <strong>{` ${invitation?.orgName}`}</strong>
            </Typography>
          </div>
          <div>
            <Button variant="text" color="primary" size="small" onClick={onClickGoToOrg}>
              <strong>{`${translations.goToButtonText} ${invitation?.orgName}`}</strong>
              <ArrowRight size={16} />
            </Button>
          </div>
        </Grid>
      </Grid>
    </Paper>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    marginBottom: theme.spacing(2),
  },
  avatar: {
    marginLeft: theme.spacing(2),
    background: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
    width: theme.spacing(6),
    height: theme.spacing(6),
    border: `1px solid ${alpha(theme.palette.primary.main, 0.5)}`,
  },
  paper: {
    boxShadow: "none",
    borderRadius: theme.spacing(),
    marginBottom: theme.spacing(),
    border: `1px solid ${theme.palette.divider}`,
    "& > div": {
      padding: theme.spacing(4, 2),
    },
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t?.invitationsPage || {}) as StringMap

  return {
    welcomeMessageTitle: translate("welcomeMessageTitle"),
    welcomeMessageDescription: translate("welcomeMessageDescription"),
    goToButtonText: translate("goToButtonText"),
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

const defaultTranslations = {
  welcomeMessageTitle: "Congratulations",
  welcomeMessageDescription: "You are now member of this organisation",
  goToButtonText: "Go to",
}

type Translations = typeof defaultTranslations

export default AcceptedInvitationCard
