import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import DaysSelect from "./DaysSelect"

import { useI18n } from "../hooks"
import { StringMap } from "../types/common"

const FirstDayOfWeekSelectWidget = ({
  firstDayOfWeek = 1,
  onFirstDayOfWeekChange = () => {},
  disabled = false,
}: FirstDayOfWeekSelectWidgetProps) => {
  const translations = useTranslations()
  const onChange = (day: DayOfWeek) => {
    onFirstDayOfWeekChange({ firstDayOfWeek: day })
  }

  return (
    <Paper elevation={0}>
      <Box p={4}>
        <Grid container direction="column">
          <Typography variant="subtitle1">{translations.firstDayOfWeekSelectTitle}</Typography>
          <Grid item id="first-day-of-week-select">
            <DaysSelect value={firstDayOfWeek} onChange={onChange} disabled={disabled} />
          </Grid>
        </Grid>
      </Box>
    </Paper>
  )
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n("translation")
  const translations = (t.firstDayOfWeekSelectWidget || {}) as StringMap
  const { firstDayOfWeekSelectTitle = defaults.firstDayOfWeekSelectTitle } = translations

  return { firstDayOfWeekSelectTitle }
}

const defaultTranslations = {
  firstDayOfWeekSelectTitle: "First day of week",
}

type FirstDayOfWeekSelectWidgetProps = {
  onFirstDayOfWeekChange?: ({ firstDayOfWeek }: { firstDayOfWeek: Day }) => void
  firstDayOfWeek?: Day
  disabled?: boolean
}

export default FirstDayOfWeekSelectWidget
