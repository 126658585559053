import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"

export const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: orgTranslations } = useI18n("org")
  const { translations } = useI18n()
  const orgMembershipsPageTranslations = (orgTranslations?.orgMembershipsPage || {}) as StringMap

  return {
    nameHeaderText: translate("nameHeaderText"),
    emailHeaderText: translate("emailHeaderText"),
    roleHeaderText: translate("roleHeaderText"),
    avatarHeaderText: translate("avatarHeaderText"),
    actionsHeaderText: translate("actionsHeaderText"),
    invitationStatusHeaderText: translate("invitationStatusHeaderText"),
    noMembershipsText: translate("noMembershipsText"),
    ownerRoleText: translate("ownerRoleText"),
    memberRoleText: translate("memberRoleText"),
    limitedMemberRoleText: translate("limitedMemberRoleText"),
    noAccessText: translate("noAccessText"),
    managePermissionsButtonText: translate("managePermissionsButtonText"),
    cancelButtonLabel: translate("cancelButtonLabel"),
    deactivateBtnLabel: translate("deactivateBtnLabel"),
    deactivateDialogTitle: translate("deactivateDialogTitle"),
    deactivateDialogText: translate("deactivateDialogText"),
    activateBtnLabel: translate("activateBtnLabel"),
    activateDialogTitle: translate("activateDialogTitle"),
    activateDialogText: translate("activateDialogText"),
    activeMembershipsOption: translate("activeMembershipsOption"),
    inactiveMembershipsOption: translate("inactiveMembershipsOption"),
    acceptedInvitationStatus: translate("acceptedInvitationStatus"),
    pendingInvitationStatus: translate("pendingInvitationStatus"),
    rejectedInvitationStatus: translate("rejectedInvitationStatus"),
    notInvitedInvitationStatus: translate("notInvitedInvitationStatus"),
    updatePermissionDialogHeaderText: translate("updatePermissionDialogHeaderText"),
    reactivationInfo: translate("reactivationInfo"),
    deactivatedOnHeaderText: translate("deactivatedOnHeaderText"),
    settings: translations.settings || defaults.settings,
    filter: translations.filter || defaults.filter,
    all: translations.all || defaults.all,
    noData: translations.noData || defaults.noData,
  }

  function translate(key: keyof Translations) {
    return orgMembershipsPageTranslations[key] || defaults[key]
  }
}

const defaultTranslations = {
  nameHeaderText: "Name",
  emailHeaderText: "Email",
  roleHeaderText: "Role",
  avatarHeaderText: "Avatar",
  actionsHeaderText: "Actions",
  invitationStatusHeaderText: "Invitation status",
  noMembershipsText: "There are no members in this organisation",
  ownerRoleText: "Owner",
  memberRoleText: "Standard member",
  limitedMemberRoleText: "Limited member",
  deactivatedOnHeaderText: "Deactivated",
  noAccessText: "No access",
  managePermissionsButtonText: "Manage permissions for selected members",
  cancelButtonLabel: "Cancel",
  deactivateBtnLabel: "Deactivate selected memberships",
  deactivateDialogTitle: "Deactivate memberships",
  deactivateDialogText:
    "By deactivating the selected members, their access to the content of this organization will be revoked. Please confirm if you want to proceed with deactivating the selected members",
  activateBtnLabel: "Activate selected memberships",
  activateDialogTitle: "Activate memberships",
  activateDialogText:
    "By activating the selected members, they will be given the access to the content of this organization. Please confirm if you want to proceed with activating the selected members",
  activeMembershipsOption: "Active memberships",
  inactiveMembershipsOption: "Inactive memberships",
  acceptedInvitationStatus: "Accepted",
  pendingInvitationStatus: "Pending",
  rejectedInvitationStatus: "Rejected",
  notInvitedInvitationStatus: "Not invited",
  updatePermissionDialogHeaderText: "Updating permissions of the selected members",
  reactivationInfo:
    "Member can not be re-activated before 30 days of deactivation. This member can be re-activated again on",
  settings: "Settings",
  filter: "Filter",
  all: "All",
  noData: "No data",
}

export type Translations = typeof defaultTranslations
