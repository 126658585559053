import { lowerCase, startCase } from "lodash"

const languages = [
  {
    countryName: "United States",
    alpha2Code: "US",
    iso639Code: "en",
    languageNameShort: "EN",
    languageName: "English",
    languageNameNative: "English",
  },
  {
    countryName: "Bulgaria",
    alpha2Code: "BG",
    iso639Code: "bg",
    languageNameShort: "BG",
    languageName: "Bulgarian",
    languageNameNative: "български език",
  },
  {
    countryName: "Czech Republic",
    alpha2Code: "CZ",
    iso639Code: "cs",
    languageNameShort: "CS",
    languageName: "Czech",
    languageNameNative: "čeština",
  },
  {
    countryName: "Germany",
    alpha2Code: "DE",
    iso639Code: "de",
    languageNameShort: "DE",
    languageName: "German",
    languageNameNative: "Deutsch",
  },
  {
    countryName: "Denmark",
    alpha2Code: "DK",
    iso639Code: "da",
    languageNameShort: "DA",
    languageName: "Danish",
    languageNameNative: "dansk",
  },
  {
    countryName: "Spain",
    alpha2Code: "ES",
    iso639Code: "es",
    languageNameShort: "ES",
    languageName: "Spanish",
    languageNameNative: "Español",
  },
  {
    countryName: "Estonia",
    alpha2Code: "EE",
    iso639Code: "et",
    languageNameShort: "ET",
    languageName: "Estonian",
    languageNameNative: "eesti",
  },
  {
    countryName: "Finland",
    alpha2Code: "FI",
    iso639Code: "fi",
    languageNameShort: "FI",
    languageName: "Finnish",
    languageNameNative: "suomi",
  },
  {
    countryName: "France",
    alpha2Code: "FR",
    iso639Code: "fr",
    languageNameShort: "FR",
    languageName: "French",
    languageNameNative: "français",
  },
  {
    countryName: "Greece",
    alpha2Code: "GR",
    iso639Code: "el",
    languageNameShort: "EL",
    languageName: "Greek (modern)",
    languageNameNative: "ελληνικά",
  },
  {
    countryName: "Croatia",
    alpha2Code: "HR",
    iso639Code: "hr",
    languageNameShort: "HR",
    languageName: "Croatian",
    languageNameNative: "hrvatski jezik",
  },
  {
    countryName: "Hungary",
    alpha2Code: "HU",
    iso639Code: "hu",
    languageNameShort: "HU",
    languageName: "Hungarian",
    languageNameNative: "magyar",
  },
  {
    countryName: "Iceland",
    alpha2Code: "IS",
    iso639Code: "is",
    languageNameShort: "IS",
    languageName: "Icelandic",
    languageNameNative: "Íslenska",
  },
  {
    countryName: "Italy",
    alpha2Code: "IT",
    iso639Code: "it",
    languageNameShort: "IT",
    languageName: "Italian",
    languageNameNative: "Italiano",
  },
  {
    countryName: "Lithuania",
    alpha2Code: "LT",
    iso639Code: "lt",
    languageNameShort: "LT",
    languageName: "Lithuanian",
    languageNameNative: "lietuvių kalba",
  },
  {
    countryName: "Latvia",
    alpha2Code: "LV",
    iso639Code: "lv",
    languageNameShort: "LV",
    languageName: "Latvian",
    languageNameNative: "latviešu valoda",
  },
  {
    countryName: "Netherlands",
    alpha2Code: "NL",
    iso639Code: "nl",
    languageNameShort: "NL",
    languageName: "Dutch",
    languageNameNative: "Nederlands",
  },
  {
    countryName: "Norway",
    alpha2Code: "NO",
    iso639Code: "nb",
    languageNameShort: "NB",
    languageName: "Norwegian Bokmål",
    languageNameNative: "Norsk bokmål",
  },
  {
    countryName: "Poland",
    alpha2Code: "PL",
    iso639Code: "pl",
    languageNameShort: "PL",
    languageName: "Polish",
    languageNameNative: "język polski",
  },
  {
    countryName: "Portugal",
    alpha2Code: "PT",
    iso639Code: "pt",
    languageNameShort: "PT",
    languageName: "Portuguese",
    languageNameNative: "Português",
  },
  {
    countryName: "Romania",
    alpha2Code: "RO",
    iso639Code: "ro",
    languageNameShort: "RO",
    languageName: "Romanian",
    languageNameNative: "Română",
  },
  {
    countryName: "Russia",
    alpha2Code: "RU",
    iso639Code: "ru",
    languageNameShort: "RU",
    languageName: "Russian",
    languageNameNative: "Русский",
  },
  {
    countryName: "Serbia",
    alpha2Code: "RS",
    iso639Code: "sr",
    languageNameShort: "SR",
    languageName: "Serbian",
    languageNameNative: "српски језик",
  },
  {
    countryName: "Slovakia",
    alpha2Code: "SK",
    iso639Code: "sk",
    languageNameShort: "SK",
    languageName: "Slovak",
    languageNameNative: "slovenčina",
  },
  {
    countryName: "Sweden",
    alpha2Code: "SE",
    iso639Code: "sv",
    languageNameShort: "SV",
    languageName: "Swedish",
    languageNameNative: "svenska",
  },
  {
    countryName: "Turkey",
    alpha2Code: "TR",
    iso639Code: "tr",
    languageNameShort: "TR",
    languageName: "Turkish",
    languageNameNative: "Türkçe",
  },
  {
    countryName: "Ukraine",
    alpha2Code: "UA",
    iso639Code: "uk",
    languageNameShort: "UK",
    languageName: "Ukrainian",
    languageNameNative: "Українська",
  },
] as const

export const languageList = languages.map((lang) => ({
  value: lang.languageNameShort.toLowerCase(),
  label: startCase(lowerCase(lang.languageNameNative)),
}))

export const languageMap: { [k: string]: string } = {}
languageList.forEach((lang) => {
  languageMap[lang.value] = lang.label
})

export type Language =
  | "en"
  | "bg"
  | "cs"
  | "de"
  | "da"
  | "es"
  | "et"
  | "fi"
  | "fr"
  | "el"
  | "hr"
  | "hu"
  | "is"
  | "it"
  | "lt"
  | "lv"
  | "nl"
  | "nb"
  | "pl"
  | "pt"
  | "ro"
  | "ru"
  | "sr"
  | "sk"
  | "sv"
  | "tr"
  | "uk"
