import React from "react"
import BoardColumnSlider from "./BoardColumnSlider"
import BoardColumnChooser from "./BoardColumnChooser"
import BoardColumn from "./BoardColumn"
import { DragDropContext, DropResult } from "react-beautiful-dnd"
import { BoardColumnNameUpdateData, MoveCardData, NewCardData } from "../api"
import { BoardPopulated } from "../api/board"
import { useIsSmallScreen } from "../../hooks/use-is-small-screen"
import { getTranslatedDefaultColumnNames } from "../utils"
import { useTranslations } from "../hooks/use-translations"

export default function Board(props: BoardProps) {
  const isSmallScreen = useIsSmallScreen()

  if (isSmallScreen) {
    return <BoardMobile {...props} />
  }

  return <BoardDefault {...props} />
}

const BoardMobile = ({
  board,
  canAddTasks,
  canAddTodos,
  onAddTask,
  onAddTodo,
  onMoveCard,
  onSaveColumnName,
}: BoardProps) => {
  const translations = useTranslations()
  const maxVisibleColumns = board.columns.orderedVisibleColumnIds.length
  const [visibleColumnIndex, setVisibleColumnIndex] = React.useState(0)

  const handleDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    onMoveCard({
      board,
      boardId: board?.id,
      cardId: draggableId,
      columnId: source.droppableId,
      destColumnId: destination.droppableId,
      position: destination.index,
    })
  }

  if (maxVisibleColumns === 0) {
    return <BoardColumnChooser boardId={board.id} boardType={board.type} buttonVariant="button" />
  }

  const showNextColumn = () =>
    setVisibleColumnIndex((prev) => {
      const desiredNext = prev + 1
      return desiredNext >= maxVisibleColumns ? prev : desiredNext
    })

  const showPrevColumn = () =>
    setVisibleColumnIndex((prev) => {
      const desiredPrev = prev - 1
      return desiredPrev < 0 ? prev : desiredPrev
    })

  const showSelectedColumn = (index: number) => {
    setVisibleColumnIndex(index >= maxVisibleColumns || index < 0 ? 0 : index)
  }

  const selectedColumn = board.columns.byId[board.columns.orderedVisibleColumnIds[visibleColumnIndex]]
  const translatedDefaultColumn = getTranslatedDefaultColumnNames({
    columnName: selectedColumn.defaultName,
    translations,
  })

  selectedColumn.name = selectedColumn.name || translatedDefaultColumn

  return (
    <>
      <BoardColumnSlider
        board={board}
        showNextColumn={showNextColumn}
        showPrevColumn={showPrevColumn}
        showSelectedColumn={showSelectedColumn}
        selectedColumn={selectedColumn}
      />
      <DragDropContext onDragEnd={handleDragEnd}>
        <div
          style={{ display: "flex", alignItems: "flex-start", flexWrap: "nowrap", flexGrow: 1 }}
          data-test="board-container"
        >
          <BoardColumn
            board={board}
            column={selectedColumn}
            canAddTasks={canAddTasks}
            canAddTodos={canAddTodos}
            key={selectedColumn.id}
            onAddTask={onAddTask}
            onAddTodo={onAddTodo}
            onSaveColumnName={onSaveColumnName}
          />
        </div>
      </DragDropContext>
    </>
  )
}

const BoardDefault = ({
  board,
  canAddTasks,
  canAddTodos,
  onAddTask,
  onAddTodo,
  onMoveCard,
  onSaveColumnName,
}: BoardProps) => {
  const handleDragEnd = ({ source, destination, draggableId }: DropResult) => {
    if (!destination) return
    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return
    }

    onMoveCard({
      board,
      boardId: board?.id,
      cardId: draggableId,
      columnId: source.droppableId,
      destColumnId: destination.droppableId,
      position: destination.index,
    })
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <div
        style={{ display: "flex", alignItems: "flex-start", flexWrap: "nowrap", flexGrow: 1 }}
        data-test="board-container"
      >
        {board.columns.orderedVisibleColumnIds.map((columnId) => {
          const column = board.columns.byId[columnId]
          return (
            <BoardColumn
              board={board}
              column={column}
              canAddTasks={canAddTasks}
              canAddTodos={canAddTodos}
              key={column.id}
              onAddTask={onAddTask}
              onAddTodo={onAddTodo}
              onSaveColumnName={onSaveColumnName}
            />
          )
        })}
      </div>
    </DragDropContext>
  )
}

type BoardProps = {
  board: BoardPopulated
  canAddTasks: boolean
  canAddTodos: boolean
  onAddTask: (cardData: NewCardData) => unknown
  onAddTodo: (cardData: NewCardData) => unknown
  onMoveCard: (moveCardData: MoveCardData & { board: BoardPopulated }) => unknown
  onSaveColumnName: (columnData: BoardColumnNameUpdateData) => unknown
}
