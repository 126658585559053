import { createSlice } from "@reduxjs/toolkit"
import { IContextOptionNormalized } from "../interfaces/context-options-normalized"
import * as actions from "./actions"
import * as orgActions from "../../organisations/store/actions"
import {
  deleteContextOptionCase,
  resetContextOptionsCase,
  setContextOptionCase,
  setContextOptionsCase,
} from "./case-reducer"

const initialState: ContextOptionsState = {
  byIds: {},
  allIds: [],
}

export const contextOptionsSlice = createSlice({
  name: "context-options",
  initialState,
  reducers: {
    SetContextOption: setContextOptionCase,
    DeleteContextOption: deleteContextOptionCase,
    ResetContextOptions: resetContextOptionsCase,
  },
  extraReducers: (builder) => {
    builder.addCase(actions.FetchContextOptions.fulfilled, setContextOptionsCase)
    builder.addCase(orgActions.DeleteOrganisation.fulfilled, deleteContextOptionCase)
  },
})

export const { SetContextOption, DeleteContextOption, ResetContextOptions } = contextOptionsSlice.actions
export interface ContextOptionsState {
  byIds: { [id: string]: IContextOptionNormalized }
  allIds: string[]
}
export default contextOptionsSlice.reducer
