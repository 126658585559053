import React from "react"
import Checkbox from "@material-ui/core/Checkbox"
import Divider from "@material-ui/core/Divider"
import IconButton from "@material-ui/core/IconButton"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"
import { ProjectViewModel } from "../api/project"
import { useGetProjectTypesQuery } from "../../organisations/api"
import { COLOR_LIGHT } from "../../constants"
import { makeStyles, Theme } from "@material-ui/core"
import { ChevronDown } from "react-feather"
import { COLOR_BLACK } from "../../constants"
import { useProjectMutations } from "../hooks/use-project-mutations"
import { useI18n } from "../../hooks"

const ProjectTypeDropdown = ({ project, isReadOnly }: { project: ProjectViewModel; isReadOnly: boolean }) => {
  const { projectType: label, ...translations } = useTranslations()
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null)
  const openMenu = (e: React.MouseEvent<HTMLDivElement>) => setAnchorEl(e.currentTarget)
  const closeMenu = () => setAnchorEl(null)
  const { updateStatus } = useProjectMutations()

  const { data: projectTypeOptions = [] } = useGetProjectTypesQuery(project.maintainerId, {
    skip: project.isUserProject,
    refetchOnMountOrArgChange: true,
  })

  const projectType =
    projectTypeOptions.find(({ id }) => id === project.projectTypeId || "")?.type || translations.notSet
  const visibleOptions = projectTypeOptions.filter((option) => option.isVisibleInOptions)

  if (project.isUserProject) return null
  if (projectTypeOptions.length === 0) return null

  if (isReadOnly || visibleOptions.length === 1) {
    return (
      <>
        <Typography variant="body1" style={{ marginBottom: 8, marginTop: 16 }}>
          <strong>{label}</strong>
        </Typography>
        <Typography variant="body1" style={{ marginBottom: 16 }}>
          {projectType}
        </Typography>
      </>
    )
  }

  const removeProjectType = () => updateStatus(project.id, { projectTypeId: null })
  const setProjectType = (id: string) => updateStatus(project.id, { projectTypeId: id })

  const menuItems = projectTypeOptions
    .filter((option) => option.isVisibleInOptions)
    .map(({ id, type }) => ({
      label: type,
      value: id,
      onClick: () => {
        if (id === project.projectTypeId) return removeProjectType()
        else setProjectType(id)
      },
    }))

  return (
    <>
      <Typography variant="body1" style={{ marginBottom: 4, marginTop: 16 }}>
        <strong>{label}</strong>
      </Typography>
      <div className={classes.button} role="button" onClick={openMenu}>
        <Typography variant="body1">{projectType}</Typography>
        <IconButton className="arrowDownIcon">
          <ChevronDown size={16} color={COLOR_BLACK} />
        </IconButton>
      </div>
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{ style: { width: 280 } }}
        disablePortal
        keepMounted
      >
        {menuItems.map((menuItem) => {
          return (
            <MenuItem onClick={menuItem.onClick} key={menuItem.label}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  color="primary"
                  checked={project.projectTypeId === menuItem.value}
                  tabIndex={-1}
                  disableRipple
                />
              </ListItemIcon>
              <ListItemText
                primary={menuItem.label}
                primaryTypographyProps={{
                  style: { whiteSpace: "normal", wordWrap: "break-word" },
                }}
              />
            </MenuItem>
          )
        })}
        {projectTypeOptions.length === 1 && (
          <>
            <Divider />
            <MenuItem key={"tip"}>
              <ListItemText
                primary={translations.createProjectTypeTip}
                primaryTypographyProps={{
                  variant: "body2",
                  color: "textSecondary",
                  style: { whiteSpace: "normal", wordWrap: "break-word" },
                }}
              />
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 12,
    height: theme.spacing(5),
    margin: theme.spacing(0, -1, 1, -1),
    paddingLeft: theme.spacing(),
    borderRadius: theme.spacing(),
    cursor: "pointer",
    transition: theme.transitions.create("background"),
    "&:hover": {
      background: COLOR_LIGHT,
      "& .arrowDownIcon": {
        opacity: 1,
      },
    },
    "& .arrowDownIcon": {
      opacity: 0,
      transition: theme.transitions.create("opacity"),
    },
  },
}))

const defaultTranslations = {
  projectType: "Project type",
  notSet: "Not set",
  createProjectTypeTip: "To create a new project type, go to the organization settings.",
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations = {} } = useI18n("translation")

  return {
    projectType: translations.projectType || defaults.projectType,
    notSet: translations.notSet || defaults.notSet,
    createProjectTypeTip: translations.createProjectTypeTip || defaults.createProjectTypeTip,
  }
}

export default ProjectTypeDropdown
type Translations = typeof defaultTranslations
