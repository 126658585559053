import { chain, keyBy, map, sortBy } from "lodash"
import { BoardResponse } from "./board-response"
import { Board as BoardClass } from "../entities/board"
import { TodoViewModel } from "../../todos/api/todo"
import { TaskViewModel } from "../../tasks/api/task"

export const makeBoardViewModel = (boardData: BoardResponse): BoardViewModel => {
  const orderedColumns = sortBy(boardData.columns, "position")
  const allIds = map(orderedColumns, "id")
  const orderedVisibleColumnIds = chain(orderedColumns)
    .filter((col) => col.isVisible)
    .map("id")
    .value()
  const columnsById = chain(orderedColumns)
    .map((col) => {
      return {
        ...col,
        cards: {
          byId: keyBy(col.cards, "id"),
          allIds: map(col.cards, "id"),
        },
      }
    })
    .keyBy("id")
    .value()

  return {
    id: boardData.id,
    type: boardData.type,
    isProjectBoard: boardData.type === "project",
    isTaskBoard: boardData.type === "task",
    isUserBoard: boardData.type === "user",
    isMemberBoard: boardData.type === "member",
    isCustomerBoard: boardData.type === "customer",
    isSupplierBoard: boardData.type === "supplier",
    isWorkspaceBoard: boardData.type === "workspace",
    columns: {
      allIds,
      byId: columnsById,
      orderedVisibleColumnIds,
    },
  }
}

export const makePopulatedBoard = ({
  board,
  tasks,
  todos,
}: {
  board: BoardViewModel
  tasks: TaskViewModel[]
  todos: TodoViewModel[]
}): BoardPopulated => {
  const tasksById = keyBy(tasks, "id")
  const todosById = keyBy(todos, "id")
  const { columns, ...rest } = board
  const populatedColumns: ColumnPopulated[] = columns.allIds.map((columnId) => {
    const column = columns.byId[columnId]
    const populatedCards: CardPopulated[] = column.cards.allIds
      .map((cardId) => {
        const card = column.cards.byId[cardId]
        if (card.type === "task") {
          return {
            id: cardId,
            type: "task" as const,
            position: card.position,
            task: tasksById[cardId],
          }
        } else {
          return {
            id: cardId,
            type: "todo" as const,
            position: card.position,
            todo: todosById[cardId],
          }
        }
      })
      .filter((card) => {
        return (card.type === "task" && card.task) || (card.type === "todo" && card.todo)
      })
    const populatedCardMap = keyBy(populatedCards, "id")
    return {
      ...column,
      cards: {
        byId: populatedCardMap,
        allIds: column.cards.allIds,
      },
    }
  })

  return {
    ...rest,
    columns: {
      allIds: columns.allIds,
      byId: keyBy(populatedColumns, "id"),
      orderedVisibleColumnIds: columns.orderedVisibleColumnIds,
    },
  }
}

export const getBoardDataFromPopulatedBoard = (populatedBoard: BoardPopulated): BoardData => {
  const columns = map(populatedBoard.columns.allIds, (columnId) => {
    const column = populatedBoard.columns.byId[columnId]
    const cards = map(column.cards.allIds, (cardId) => {
      const card = column.cards.byId[cardId]
      return {
        id: cardId,
        type: card.type,
        position: card.position,
      }
    })
    return {
      id: columnId,
      board: populatedBoard.id,
      cards,
      name: column.name,
      defaultName: column.defaultName,
      position: column.position,
      isVisible: column.isVisible,
    }
  })
  return {
    id: populatedBoard.id,
    columns,
    type: populatedBoard.type,
  }
}

export type BoardCardType = "todo" | "task"
export type BoardType = (typeof BoardClass.allBoardTypes)[number]
export type BoardCardData = { id: string; type: BoardCardType; position: number }

export type BoardColumnData = {
  id: string
  board: string
  cards: BoardCardData[]
  name: string | null
  defaultName: string
  position: number
  isVisible: boolean
}

type BoardColumn = Omit<BoardColumnData, "cards"> & {
  cards: {
    byId: { [k: string]: BoardCardData }
    allIds: string[]
  }
}

export type BoardViewModel = {
  id: string
  type: BoardType
  isProjectBoard: boolean
  isTaskBoard: boolean
  isUserBoard: boolean
  isMemberBoard: boolean
  isCustomerBoard: boolean
  isSupplierBoard: boolean
  isWorkspaceBoard: boolean
  columns: {
    byId: { [k: string]: BoardColumn }
    orderedVisibleColumnIds: string[]
    allIds: string[]
  }
}

export type TaskCardPopulated = {
  type: "task"
  id: string
  position: number
  task: TaskViewModel
}

export type TodoCardPopulated = {
  type: "todo"
  id: string
  position: number
  todo: TodoViewModel
}

export type CardPopulated = TaskCardPopulated | TodoCardPopulated

export type ColumnPopulated = Omit<BoardColumnData, "cards"> & {
  cards: {
    byId: { [id: string]: CardPopulated }
    allIds: string[]
  }
}

export type BoardPopulated = BoardViewModel & {
  id: string
  type: BoardType
  columns: {
    allIds: string[]
    byId: { [id: string]: ColumnPopulated }
    orderedVisibleColumnIds: string[]
  }
}

export type BoardData = {
  id: string
  columns: BoardColumnData[]
  type: BoardType
}
