import CircularProgress from "@material-ui/core/CircularProgress"
import Typography from "@material-ui/core/Typography"
import NotificationSettingItem from "../../components/NotificationSettingItem"
import { Theme, makeStyles } from "@material-ui/core"
import { useI18n } from "../../hooks"
import { NotificationChannel, NotificationType } from "../../notifications/notifications"
import { useGetProjectByIdQuery } from "../api"
import { useProjectMutations } from "../hooks/use-project-mutations"

const ProjectNotificationSettings = ({ projectId }: { projectId: string }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { data: project, isLoading } = useGetProjectByIdQuery(projectId)
  const { enableNotification, disableNotification } = useProjectMutations()

  if (!project || isLoading) return <CircularProgress />
  const { notificationSettings } = project

  const onEnableNotification = (type: NotificationType, channel: NotificationChannel) => {
    enableNotification(project.id, type, channel)
  }
  const onDisableNotification = (type: NotificationType, channel: NotificationChannel) => {
    disableNotification(project.id, type, channel)
  }

  const isProjectOverdueEmailNotificationEnabled = notificationSettings.ProjectOverdue?.includes("email")
  const isProjectNotStartedEmailNotificationEnabled = notificationSettings.ProjectNotStarted?.includes("email")
  const isAssignedToProjectEmailNotificationEnabled = notificationSettings.AssignedToProject?.includes("email")
  const isTaskOverdueEmailNotificationEnabled = notificationSettings.TaskOverdue?.includes("email")
  const isTaskNotStartedEmailNotificationEnabled = notificationSettings.TaskNotStarted?.includes("email")
  const isAssignedToTaskEmailNotificationEnabled = notificationSettings.AssignedToTask?.includes("email")
  const isTodoOverdueEmailNotificationEnabled = notificationSettings.TodoOverdue?.includes("email")
  const isAssignedToTodoEmailNotificationEnabled = notificationSettings.AssignedToTodo?.includes("email")

  return (
    <div className={classes.container}>
      <Typography variant="h6" component="h5" className={classes.header}>
        {translations.emailNotificationSettings}
      </Typography>

      <section className={classes.section}>
        <Typography variant="subtitle1" component="h6" className="sectionHeader">
          {translations.projectNotifications}
        </Typography>
        <NotificationSettingItem
          label={translations.projectOverdueLabel}
          description={translations.projectOverdueDescription}
          enabled={isProjectOverdueEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("ProjectOverdue", "email")}
          onDisableNotification={() => onDisableNotification("ProjectOverdue", "email")}
        />
        <NotificationSettingItem
          label={translations.projectNotStartedLabel}
          description={translations.projectNotStartedDescription}
          enabled={isProjectNotStartedEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("ProjectNotStarted", "email")}
          onDisableNotification={() => onDisableNotification("ProjectNotStarted", "email")}
        />
        <NotificationSettingItem
          label={translations.assignedToProjectLabel}
          description={translations.assignedToProjectDescription}
          enabled={isAssignedToProjectEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("AssignedToProject", "email")}
          onDisableNotification={() => onDisableNotification("AssignedToProject", "email")}
        />
      </section>
      <section className={classes.section}>
        <Typography variant="subtitle1" component="h6" className="sectionHeader">
          {translations.taskNotifications}
        </Typography>
        <NotificationSettingItem
          label={translations.taskOverdueLabel}
          description={translations.taskOverdueDescription}
          enabled={isTaskOverdueEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("TaskOverdue", "email")}
          onDisableNotification={() => onDisableNotification("TaskOverdue", "email")}
        />
        <NotificationSettingItem
          label={translations.taskNotStartedLabel}
          description={translations.taskNotStartedDescription}
          enabled={isTaskNotStartedEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("TaskNotStarted", "email")}
          onDisableNotification={() => onDisableNotification("TaskNotStarted", "email")}
        />
        <NotificationSettingItem
          label={translations.assignedToTaskLabel}
          description={translations.assignedToTaskDescription}
          enabled={isAssignedToTaskEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("AssignedToTask", "email")}
          onDisableNotification={() => onDisableNotification("AssignedToTask", "email")}
        />
      </section>
      <section className={classes.section}>
        <Typography variant="subtitle1" component="h6" className="sectionHeader">
          {translations.todoNotifications}
        </Typography>
        <NotificationSettingItem
          label={translations.todoOverdueLabel}
          description={translations.todoOverdueDescription}
          enabled={isTodoOverdueEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("TodoOverdue", "email")}
          onDisableNotification={() => onDisableNotification("TodoOverdue", "email")}
        />
        <NotificationSettingItem
          label={translations.assignedToTodoLabel}
          description={translations.assignedToTodoDescription}
          enabled={isAssignedToTodoEmailNotificationEnabled}
          onEnableNotification={() => onEnableNotification("AssignedToTodo", "email")}
          onDisableNotification={() => onDisableNotification("AssignedToTodo", "email")}
        />
      </section>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.background.paper,
    padding: theme.spacing(3),
  },
  header: {
    marginBottom: theme.spacing(2),
  },
  section: {
    margin: theme.spacing(4, 0),
    padding: theme.spacing(2),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    "& .sectionHeader": {
      fontWeight: theme.typography.fontWeightBold,
      marginBottom: theme.spacing(2),
      fontSize: 18,
    },
    "&:first-child": {
      marginTop: theme.spacing(4),
    },
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations = {} } = useI18n("translation")
  const { translations: orgTranslations = {} } = useI18n("org")

  return {
    emailNotificationSettings: translate("emailNotificationSettings"),
    projectNotifications: translate("projectNotifications"),
    taskNotifications: translate("taskNotifications"),
    todoNotifications: translate("todoNotifications"),
    projectOverdueLabel: translate("projectOverdueLabel"),
    projectNotStartedLabel: translate("projectNotStartedLabel"),
    assignedToProjectLabel: translate("assignedToProjectLabel"),
    taskOverdueLabel: translate("taskOverdueLabel"),
    taskNotStartedLabel: translate("taskNotStartedLabel"),
    assignedToTaskLabel: translate("assignedToTaskLabel"),
    todoOverdueLabel: translate("todoOverdueLabel"),
    assignedToTodoLabel: translate("assignedToTodoLabel"),
    projectOverdueDescription: translate("projectOverdueDescription", "org"),
    projectNotStartedDescription: translate("projectNotStartedDescription", "org"),
    assignedToProjectDescription: translate("assignedToProjectDescription", "org"),
    taskOverdueDescription: translate("taskOverdueDescription", "org"),
    taskNotStartedDescription: translate("taskNotStartedDescription", "org"),
    assignedToTaskDescription: translate("assignedToTaskDescription", "org"),
    todoOverdueDescription: translate("todoOverdueDescription", "org"),
    assignedToTodoDescription: translate("assignedToTodoDescription", "org"),
  }

  function translate(key: keyof Translations, namespace: "translation" | "org" = "translation"): string {
    if (namespace === "translation") return translations[key] || defaults[key]
    else return orgTranslations[key] || defaults[key]
  }
}

const defaultTranslations = {
  emailNotificationSettings: "Email notification settings",
  projectNotifications: "Project notifications",
  taskNotifications: "Task notifications",
  todoNotifications: "To-do notifications",
  projectOverdueLabel: "Enable project overdue notifications",
  projectNotStartedLabel: "Enable project not started notifications",
  assignedToProjectLabel: "Enable added to project notifications",
  taskOverdueLabel: "Enable task overdue notifications",
  taskNotStartedLabel: "Enable task not started notifications",
  assignedToTaskLabel: "Enable added to task notifications",
  todoOverdueLabel: "Enable to-do overdue notifications",
  assignedToTodoLabel: "Enable added to to-do notifications",
  projectOverdueDescription: "Project's managers will receive an email when the project is not completed  on time.",
  projectNotStartedDescription:
    "Project's managers will receive an email when the project has not been started on time.",
  assignedToProjectDescription: "Users will receive an email when they are added as a manager to the project.",
  taskOverdueDescription: "Task's managers will receive an email when the task is not completed on time.",
  taskNotStartedDescription: "Task's managers will receive an email when the task has not been started on time.",
  assignedToTaskDescription: "Users will receive an email when they are added as a manager to the task.",
  todoOverdueDescription: "To-do's responsible person will receive an email when the to-do is not completed on time.",
  assignedToTodoDescription: "Users will receive an email when they are added as a responsible to a to-do.",
}

type Translations = typeof defaultTranslations

export default ProjectNotificationSettings
