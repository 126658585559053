import { BoardCardData, BoardCardType } from "../api/board"

export class BoardCard {
  readonly id: string
  readonly type: BoardCardType
  position: number

  constructor({ id, type, position = 0 }: Optional<BoardCardData, "position">) {
    this.id = id
    this.type = type
    this.position = position
  }

  toObject(): BoardCardData {
    return { ...this }
  }
}
