import React from "react"
import DatePicker from "../DatePicker"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import Tooltip from "@material-ui/core/Tooltip"
import { makeStyles, Theme } from "@material-ui/core"
import { Calendar, Plus, X } from "react-feather"
import { COLOR_BLACK, COLOR_LIGHT } from "../../constants"
import { ReactDatePickerProps } from "react-datepicker"
import { useOnClickOutside } from "../../hooks/use-on-click-outside"

const DatePickerInput = React.forwardRef<HTMLDivElement, DatePickerInputProps>((props, ref) => {
  const clickAreaRef = React.useRef<null | HTMLSpanElement>(null)
  const calendarOpenAttrKey = "data-calendar-open"
  const classes = useStyles()
  const clickAwayHandler = React.useCallback(() => {
    const isCalendarOpen = clickAreaRef.current?.getAttribute(calendarOpenAttrKey) === "open"
    if (isCalendarOpen) {
      clickAreaRef.current?.removeAttribute(calendarOpenAttrKey)
    }
  }, [])
  //
  useOnClickOutside(clickAreaRef, clickAwayHandler)

  const onClickClear = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    props.onClear?.()
  }

  const onClickClickArea = (e: React.MouseEvent<HTMLElement>) => {
    const isCalendarOpen = e.currentTarget.getAttribute(calendarOpenAttrKey) === "open"
    if (isCalendarOpen) {
      e.currentTarget.removeAttribute(calendarOpenAttrKey)
      e.stopPropagation()
    } else {
      e.currentTarget.setAttribute(calendarOpenAttrKey, "open")
    }
  }

  const onClickPlus = (e: React.MouseEvent<HTMLElement>) => {
    clickAreaRef.current?.setAttribute(calendarOpenAttrKey, "open")
  }
  return (
    <div className={classes.button} ref={ref} onClick={props.onClick} role="button" tabIndex={0}>
      <span ref={clickAreaRef} className={classes.clickArea} onClick={onClickClickArea} />
      {!props.formattedDate && (
        <>
          <Typography variant="body1">
            <strong>{props.label}</strong>
          </Typography>
          <IconButton onClick={onClickPlus}>
            <Plus size={16} color={COLOR_BLACK} />
          </IconButton>
        </>
      )}
      {props.formattedDate && (
        <>
          <span className="date">
            <Calendar size={18} color={COLOR_BLACK} />
            <Typography variant="body1" component="span">
              {props.label}
            </Typography>
          </span>
          {props.isClearable && (
            <Tooltip title={props.clearButtonHint}>
              <IconButton className="clearIcon" onClick={onClickClear}>
                <X size={16} color={COLOR_BLACK} />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </div>
  )
})

const CustomDatePicker = ({
  label,
  isClearable,
  formattedDate,
  clearButtonHint,
  calendarStartDay = 1,
  ...datePickerProps
}: DatePickerProps) => {
  const datePickerInputProps = isClearable
    ? ({
        isClearable,
        formattedDate,
        label,
        clearButtonHint,
        onClear: () => datePickerProps.onChange(null as any),
      } as DatePickerInputProps)
    : ({
        isClearable,
        formattedDate,
        label,
      } as DatePickerInputProps)

  return (
    <DatePicker
      {...datePickerProps}
      calendarStartDay={calendarStartDay as DayOfWeek}
      isClearable={false} // disable react date picker's clear button
      customInput={<DatePickerInput {...datePickerInputProps} />}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: theme.spacing(5),
    margin: theme.spacing(0, -1),
    paddingLeft: theme.spacing(),
    borderRadius: theme.spacing(),
    cursor: "pointer",
    transition: theme.transitions.create("background"),
    "&:hover, &:focus": {
      background: COLOR_LIGHT,
      "& .clearIcon": { opacity: 1 },
    },
    "& .date": { display: "inline-flex", alignItems: "center", gap: 12 },
    "& .clearIcon": {
      opacity: 0,
      transition: theme.transitions.create("opacity"),
    },
  },
  clickArea: {
    background: "black",
    position: "absolute",
    inset: 0,
    right: 40,
    opacity: 0,
  },
}))

type ClearableDatePickerProps = {
  isClearable: true
  onChange: (date: Date | null) => void | Promise<void>
  label: string
  formattedDate: string | null
  clearButtonHint: string
}

type UnclearableDatePickerProps = {
  isClearable: false
  onChange: (date: Date) => void
  label: string
  formattedDate: string
}

type ClearableDatePickerInputProps = {
  isClearable: true
  label: string
  clearButtonHint: string
  onClear: (d?: null) => void
  formattedDate: string | null
  onClick?: (args: any) => void
}

type UnclearableDatePickerInputProps = {
  isClearable: false
  label: string
  formattedDate: string | null
  onClick?: (args: any) => void
}

export type DatePickerProps = ReactDatePickerProps & XOR<ClearableDatePickerProps, UnclearableDatePickerProps>

type DatePickerInputProps = XOR<ClearableDatePickerInputProps, UnclearableDatePickerInputProps>

export default CustomDatePicker
