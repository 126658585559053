import { AxiosRequestConfig } from "axios"
import api from "../services/api-service"
import { IAccessTokenResponse, IAuthResponse } from "./interfaces/auth-response"
import { IUserResponse } from "./interfaces/user-response"

export const makeAuthApiRoutes = () => {
  const routes = {
    login: () => "/auth/login",
    token: () => `/auth/token`,
  }
  return routes
}
const authApiRoutes = makeAuthApiRoutes()

export const login = (loginData: LoginData, config: AxiosRequestConfig = {}) => {
  return api.post<AuthResponseData>(authApiRoutes.login(), loginData, config)
}

export const fetchAccessToken = (refreshToken: string, config: AxiosRequestConfig = {}) => {
  return api.post<IAccessTokenResponse>(authApiRoutes.token(), { refresh_token: refreshToken }, config)
}

export type LoginData = {
  email: string
  password: string
}

type AuthResponseData = {
  user: IUserResponse
  auth: IAuthResponse
}
