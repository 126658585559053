import { filter } from "lodash"
import { useAppSelector } from "../../store"
import { InvitationStatus } from "../constants"
import { getUserInvitations } from "../store/selectors"

export const useUserInvitations = () => {
  const invitations = useAppSelector(getUserInvitations)

  const acceptedInvitations = filter(invitations, {
    status: InvitationStatus.ACCEPTED,
  })
  const pendingInvitations = filter(invitations, {
    status: InvitationStatus.PENDING,
  })
  const rejectedInvitations = filter(invitations, {
    status: InvitationStatus.REJECTED,
  })

  return {
    invitations,
    acceptedInvitations,
    pendingInvitations,
    rejectedInvitations,
  }
}
