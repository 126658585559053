import { IContact } from "./contact"

enum CustomerTypeEnum {
  person = "person",
  organisation = "organisation",
}

enum CustomerStatusEnum {
  active = "active",
  inActive = "inactive",
}

interface ICustomer {
  id: string
  createdAt: string
  updatedAt: string
  customerNumber: number
  language: string
  orgId: string
  status: CustomerStatusEnum
  type: CustomerTypeEnum
  defaultContact: IContact | null
}

interface IFormattedCustomer {
  id: string
  orgId: string
  customerName: string
  customerNumber: number
  language: string
  defaultContact: IContact | null
  status: CustomerStatusEnum
  type: CustomerTypeEnum
}

interface IPersonCustomerRequiredData {
  firstname: string
  lastname: string
}

interface IOrgCustomerRequiredData {
  name: string
}

interface IPersonCustomer extends ICustomer, IPersonCustomerRequiredData {
  phone: string
  email: string
  personalId: string
  type: CustomerTypeEnum.person
}

interface IOrgCustomer extends ICustomer, IOrgCustomerRequiredData {
  businessId: string
  vatRegistrationNumber: string
  contacts: { [id: string]: IContact }
  defaultContact: IContact | null
  type: CustomerTypeEnum.organisation
}

interface OrgCustomerPayload {
  type: CustomerTypeEnum.organisation
  name?: string
  customerNumber?: number
  businessId?: string
  vatRegistrationNumber?: string
  language?: string
  status?: CustomerStatusEnum
}

interface PersonCustomerPayload {
  type: CustomerTypeEnum.person
  firstname?: string
  lastname?: string
  customerNumber?: number
  personalId?: string
  phone?: string
  email?: string
  language?: string
  status?: CustomerStatusEnum
}

interface CustomerNumberMetadata {
  customerNumber: number
  error: string
  unique: boolean
  valid: boolean
}

export { CustomerTypeEnum, CustomerStatusEnum }
export type {
  CustomerNumberMetadata,
  IPersonCustomer,
  IOrgCustomer,
  IFormattedCustomer,
  OrgCustomerPayload,
  PersonCustomerPayload,
}
