import { CalendarEventViewModel } from "../api/calendar-event"
import { useCalendarEventMutations } from "../hooks/use-calendar-event-mutations"
import ResourceManager, { ResourceType } from "../../components/ResourceManager/ResourceManager"
import { useI18n } from "../../hooks/use-i18n"
import { StringMap } from "../../types/common"

const EventProjectWidget = ({ calendarEvent, projectOptions }: Props) => {
  const { updateProject } = useCalendarEventMutations()
  const translations = useTranslations(defaultTranslations)
  const eventProject = calendarEvent.project
    ? [{ id: calendarEvent.project.id, name: calendarEvent.project.title }]
    : []
  const onAddProject = (project: string) => updateProject(calendarEvent.id, project)
  const onRemoveProject = () => updateProject(calendarEvent.id, null)

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={projectOptions}
      resources={eventProject}
      canAddResource={calendarEvent.canUpdate}
      canRemoveResource={calendarEvent.canUpdate}
      onAddResource={onAddProject}
      onRemoveResource={onRemoveProject}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection={false}
    />
  )
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n("calendarEvent")
  const translations = (t?.projectWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: "Project",
  emptyOptionsMessage: "No projects to connect this event",
  emptyResourcesMessage: "This event is not connected to any project",
}

type Props = {
  calendarEvent: CalendarEventViewModel
  projectOptions: ResourceType[]
}
export default EventProjectWidget
