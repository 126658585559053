import { useLocation } from "react-router-dom"
import { isKanbanRoute } from "./utils"
import BoardColumnChooser from "../../../boards/components/BoardColumnChooser"
import { TaskViewModel } from "../../api/task"

const TaskBoardColumnChooser = ({ task }: { task: TaskViewModel }) => {
  const location = useLocation()

  if (!isKanbanRoute(location.pathname)) return null
  return <BoardColumnChooser boardId={task.id} boardType="task" buttonVariant="iconButton" />
}

export default TaskBoardColumnChooser
