export const makeOrganisationApiEndpoints = (orgId = ":orgId") => {
  const endpoints = {
    organisations: () => "/orgs",
    organisationItem: (id = orgId) => {
      return `${endpoints.organisations()}/${id || orgId}`
    },
    orgRoles: (id = orgId) => `${endpoints.organisationItem(id)}/roles`,
    orgRoleItem: (id = ":roleId") => `/roles/${id}`,
    orgRoleAbilities: (id = ":roleId") => `/roles/${id}/abilities`,
    orgRoleAbilitiesApplyToAll: (id = ":roleId") => {
      return `/roles/${id}/abilities/apply-to-all`
    },
    orgBillingSummary: (id = orgId) => `${endpoints.organisationItem(id)}/billing/summary`,
    orgBillingSettingsCheck: (id = orgId) => `${endpoints.organisationItem(id)}/billing/check`,
    orgNotificationSettings: (id = orgId) => `${endpoints.organisationItem(id)}/notification-settings`,
    projectTypes: (id = orgId) => `${endpoints.organisationItem(id)}/project-types`,
    projectTypeItem: (id = ":projectTypeId", _orgId = orgId) => {
      return `${endpoints.projectTypes(_orgId)}/${id}`
    },
    projectStatusItem: (id = ":projectStatusId", projectTypeId = ":projectTypeId", _orgId = orgId) => {
      return `${endpoints.projectTypeItem(projectTypeId, _orgId)}/project-statuses/${id}`
    },
    taskStatusItem: (id = ":statusId", _orgId = orgId) => {
      return `${endpoints.organisationItem(_orgId)}/task-statuses/${id}`
    },
    projectSubStatuses: (id = ":projectStatusId", projectTypeId = ":projectTypeId", _orgId = orgId) => {
      return `${endpoints.projectStatusItem(id, projectTypeId, _orgId)}/sub-statuses`
    },
    projectSubStatusItem: (
      id = ":subStatusId",
      projectStatusId = ":projectStatusId",
      projectTypeId = ":projectTypeId",
      _orgId = orgId
    ) => {
      return `${endpoints.projectSubStatuses(projectStatusId, projectTypeId, _orgId)}/${id}`
    },
    taskSubStatuses(id = ":statusId", _orgId = orgId) {
      return `${endpoints.taskStatusItem(id, _orgId)}/sub-statuses`
    },
    taskSubStatusItem: (id = ":subStatusId", statusId = ":statusId", _orgId = orgId) => {
      return `${endpoints.taskSubStatuses(statusId, _orgId)}/${id}`
    },
  }
  return endpoints
}
