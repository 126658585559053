import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Checkbox from "@material-ui/core/Checkbox"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Paper from "@material-ui/core/Paper"
import IconButton from "@material-ui/core/IconButton"
import TextField from "@material-ui/core/TextField"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import { ArrowLeft, Trash, Eye, EyeOff, Edit } from "react-feather"
import { useAppContext } from "../../../../hooks/use-app-context"
import { useGetProjectTypesQuery } from "../../../../organisations/api"
import { makeStyles } from "@material-ui/core"
import { Link, Route, Switch, useHistory, useParams } from "react-router-dom"
import { useUrlWithContext } from "../../../../hooks/use-url-with-context"
import { useOrgMutations } from "../../../../organisations/hooks/use-organisation-mutations"
import Stack from "../../../../components/Stack"
import { COLOR_BLACK, COLOR_DIVIDER, COLOR_ERROR } from "../../../../constants"
import InlineEditableText from "../../../../components/InlineEditableText"
import { paths } from "../../../../paths"
import { useConfirmDialog } from "../../../../components/ConfirmDialog"
import { ProjectTypeViewModel } from "../../../../organisations/api/project-type"
import { useI18n } from "../../../../hooks"
import ProjectCustomStatuses from "../../../../organisations/components/ProjectCustomStatuses"

const OrgProjectSettingsLayout = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const { createPathWithContext } = useUrlWithContext()
  const newProjectTypePath = createPathWithContext(paths.orgNewProjectType())

  return (
    <>
      <Box className={classes.layoutHeader}>
        <Box>
          <Typography variant="h6" style={{ fontWeight: 500 }} gutterBottom>
            {translations.projectTypes}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {translations.projectTypesDescription}
          </Typography>
        </Box>
        <Button variant="contained" color="primary" component={Link} to={newProjectTypePath} size="small">
          {translations.addProjectType}
        </Button>
      </Box>

      <Switch>
        <Route path={paths.orgNewProjectType()} component={ProjectTypeForm} />
        <Route path={paths.orgProjectType()} component={ProjectTypeSettings} />
        <Route path={paths.orgProjectSettings()} component={ProjectTypeList} />
      </Switch>
    </>
  )
}

export default OrgProjectSettingsLayout

const queryOptions = { refetchOnMountOrArgChange: true }

const ProjectTypeList = () => {
  const orgId = useAppContext().appContext.mainContext?.id as string
  const classes = useStyles()
  const translations = useTranslations()
  const { data: projectTypes = [], isLoading } = useGetProjectTypesQuery(orgId, queryOptions)

  if (isLoading) return <CircularProgress />
  const lastItemIndex = projectTypes.length - 1

  if (!projectTypes.length) {
    return (
      <>
        <Box sx={{ mt: 2, p: 4, textAlign: "center", border: `1px dashed ${COLOR_DIVIDER}`, borderRadius: 4 }}>
          <Typography variant="body1">{translations.projectTypesEmpty}</Typography>
        </Box>
      </>
    )
  }

  return (
    <List component={Paper} className={classes.list}>
      {projectTypes.map((projectType, index) => (
        <ProjectTypeListItem
          key={projectType.id}
          projectType={projectType}
          isDividerVisible={index !== lastItemIndex}
        />
      ))}
    </List>
  )
}

const ProjectTypeForm = () => {
  const translations = useTranslations()
  const orgId = useAppContext().appContext.mainContext?.id as string
  const [type, setType] = React.useState("")
  const { addProjectType } = useOrgMutations()
  const history = useHistory()
  const { createPathWithContext } = useUrlWithContext()

  const orgProjectSettingsPath = createPathWithContext(paths.orgProjectSettings())

  const handleAddProjectType = () => {
    addProjectType(orgId, type)
    setType("")
    history.push(orgProjectSettingsPath)
  }

  return (
    <Paper variant="outlined" style={{ padding: "40px 24px" }}>
      <Button size="small" component={Link} to={orgProjectSettingsPath} startIcon={<ArrowLeft size={16} />}>
        {translations.backToProjectTypes}
      </Button>
      <Typography variant="h6" style={{ margin: "28px auto" }}>
        {translations.addNewProjectType}
      </Typography>
      <Stack>
        <TextField
          label={translations.projectType}
          placeholder={translations.projectTypePlaceholder}
          type="text"
          value={type}
          onChange={(e) => setType(e.target.value)}
          onKeyDown={(e) => e.key === "Enter" && handleAddProjectType()}
          variant="outlined"
          autoFocus
          fullWidth
        />
        <Button onClick={handleAddProjectType} color="primary" variant="contained">
          {translations.add}
        </Button>
      </Stack>
    </Paper>
  )
}

const ProjectTypeListItem = ({
  projectType,
  isDividerVisible,
}: {
  isDividerVisible: boolean
  projectType: ProjectTypeViewModel
}) => {
  const classes = useStyles()
  const { updateProjectType, deleteProjectType } = useOrgMutations()
  const orgId = useAppContext().appContext.mainContext?.id as string
  const { createPathWithContext } = useUrlWithContext()
  const createProjectTypePath = (id: string) => createPathWithContext(paths.orgProjectType(id))
  const translations = useTranslations()

  const toggleIsVisibleInOptions = () => {
    updateProjectType(orgId, projectType.id, { isVisibleInOptions: !projectType.isVisibleInOptions })
  }

  const onDeleteProjectType = () => {
    if (!projectType.id) return
    deleteProjectType(orgId, projectType.id)
  }

  const { confirm: confirmDeleteProjectType } = useConfirmDialog({
    title: translations.deleteProjectType,
    text: `${translations.deleteProjectTypeConfirmation} ${projectType.type}?`,
    primaryActionButtonLabel: translations.deleteLabel,
    onConfirm: onDeleteProjectType,
  })

  const eyeIconToolTip = projectType.isVisibleInOptions ? translations.hideInOptions : translations.showInOptions
  const deleteIconToolTip = `${translations.deleteLabel} ${projectType.type}`
  const projectTypeName = projectType.shouldTranslateType ? translations.default : projectType.type

  return (
    <ListItem
      component={Link}
      to={createProjectTypePath(projectType.id)}
      button
      key={projectType.id}
      divider={isDividerVisible}
    >
      <ListItemText
        primary={projectTypeName}
        secondary={
          projectType.isDefault
            ? translations.defaultProjectTypeInfo
            : !projectType.isVisibleInOptions
            ? translations.hiddenProjectTypeInfo
            : undefined
        }
      />
      <ListItemSecondaryAction style={{ display: "flex" }}>
        <Box className={classes.quickActions}>
          <Tooltip title={eyeIconToolTip}>
            <IconButton edge="end" onClick={toggleIsVisibleInOptions} style={{ marginRight: 0 }}>
              {projectType.isVisibleInOptions ? (
                <Eye size={20} color={COLOR_BLACK} />
              ) : (
                <EyeOff size={20} color={COLOR_BLACK} />
              )}
            </IconButton>
          </Tooltip>
          {!projectType.isDefault && (
            <Tooltip title={deleteIconToolTip}>
              <IconButton edge="end" onClick={confirmDeleteProjectType}>
                <Trash size={20} color={COLOR_BLACK} />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        <IconButton component={Link} to={createProjectTypePath(projectType.id)}>
          <Edit size={20} color={COLOR_BLACK} />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const ProjectTypeSettings = () => {
  const orgId = useAppContext().appContext.mainContext?.id as string
  const translations = useTranslations()
  const { projectTypeId } = useParams<{ projectTypeId: string }>()
  const { data: projectTypes = [], isLoading } = useGetProjectTypesQuery(orgId)
  const { updateProjectType, deleteProjectType } = useOrgMutations()
  const projectType = projectTypes.find((type) => type.id === projectTypeId)
  const { createPathWithContext } = useUrlWithContext()
  const history = useHistory()
  const orgProjectSettingsPath = createPathWithContext(paths.orgProjectSettings())

  const onChangeIsVisibleInOptions = (isVisibleInOptions: boolean) => {
    if (!projectType) return
    updateProjectType(orgId, projectTypeId, { isVisibleInOptions })
  }

  const onChangeIsDefault = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!projectType) return
    updateProjectType(orgId, projectTypeId, { isDefault: event.target.checked })
  }

  const onDeleteProjectType = () => {
    if (!projectTypeId) return
    deleteProjectType(orgId, projectTypeId)
    history.push(orgProjectSettingsPath)
  }

  const { confirm: confirmDeleteProjectType } = useConfirmDialog({
    title: translations.deleteProjectType,
    text: `${translations.deleteProjectTypeConfirmation} ${projectType?.type}?`,
    primaryActionButtonLabel: translations.deleteLabel,
    onConfirm: onDeleteProjectType,
  })

  if (!projectType || isLoading) return <CircularProgress />
  const projectTypeName = projectType.shouldTranslateType ? translations.default : projectType.type

  return (
    <Paper style={{ padding: "40px 24px" }} variant="outlined">
      <Button size="small" component={Link} to={orgProjectSettingsPath} startIcon={<ArrowLeft size={16} />}>
        {translations.backToProjectTypes}
      </Button>
      <Box style={{ marginTop: "12px", marginLeft: "-16px" }}>
        <InlineEditableText
          value={projectTypeName}
          onSave={(newType) => updateProjectType(orgId, projectTypeId, { type: newType })}
          TypographyProps={{ variant: "h6" }}
          isMultiline={false}
        />
      </Box>
      <Typography variant="body2" style={{ marginBottom: "24px" }} color="textSecondary">
        {`${translations.createdBy} ${projectType.createdBy}`}
      </Typography>
      <Stack>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={projectType.isVisibleInOptions}
              onChange={(e) => onChangeIsVisibleInOptions(e.target.checked)}
            />
          }
          label={translations.showInOptionsLabel}
        />
        <FormControlLabel
          control={<Checkbox color="primary" checked={projectType.isDefault} onChange={onChangeIsDefault} />}
          label={translations.useAsDefaultLabel}
        />

        <ProjectCustomStatuses projectType={projectType} />

        {!projectType.isDefault && (
          <Button
            color="primary"
            style={{ marginTop: "24px", color: COLOR_ERROR, borderColor: COLOR_ERROR }}
            variant="outlined"
            onClick={confirmDeleteProjectType}
          >
            {translations.deleteLabel} {projectType.type}
          </Button>
        )}
      </Stack>
    </Paper>
  )
}

const defaultTranslations = {
  projectTypesEmpty: "You have not added any project types yet.",
  addProjectType: "Add project type",
  showInOptions: "Show in project type options",
  hideInOptions: "Hide in project type options",
  deleteProjectType: "Delete project type",
  projectTypes: "Project Types",
  projectTypesDescription: "Click on the edit icon of any project type to rename it or add status options.",
  addNewProjectType: "Add a new project type",
  projectType: "Project Type",
  createdBy: "Created by",
  backToProjectTypes: "Back to project types",
  projectTypePlaceholder: "Enter project type e.g. Customer project",
  add: "Add",
  deleteLabel: "Delete",
  deleteProjectTypeConfirmation: "Are you sure you want to delete the project type",
  defaultProjectTypeInfo: "This is the default project type",
  hiddenProjectTypeInfo: "This project type is hidden in project type options",
  showInOptionsLabel: "Show this type in project type options",
  useAsDefaultLabel: "Use this type as default project type",
  default: "Default",
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")

  return {
    ...defaults,
    ...translations,
  }
}

const useStyles = makeStyles((theme) => ({
  layoutHeader: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down("sm")]: {
      "& .MuiButton-root": {
        marginTop: theme.spacing(2),
      },
    },
  },
  list: {
    "&.MuiPaper-root": {
      boxShadow: "none",
      border: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1.5),
      "& svg": {
        color: theme.palette.text.secondary,
      },
    },
    "& .MuiListItemText-primary": {
      fontWeight: 500,
    },
    "& .MuiListItem-container:hover $quickActions": {
      opacity: 1,
    },
  },
  quickActions: {
    display: "flex",
    opacity: 0,
    transition: theme.transitions.create(["opacity"]),
    "& button": {
      margin: 0,
    },
  },
}))

type Translations = typeof defaultTranslations
