import DateWidget from "../../components/DateWidget"
import {
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Theme,
  Typography,
  makeStyles,
} from "@material-ui/core"
import React, { useState } from "react"
import { useDateFormat } from "../../users/hooks/use-date-format"
import { useUserTimeZone } from "../../hooks/use-user-time-zone"
import { DateTimeService } from "../../services/date-time-service"
import { addMonths } from "date-fns"
import { Calendar } from "react-feather"
import { useI18n } from "../../hooks"
import { useDateTranslations } from "../../hooks/use-date-translations"
import { Alert } from "@material-ui/lab"

export const splitRecurranceType = (
  recurranceType: string
): {
  type: RecurranceType | null
  count: number | null
  weekDay: number | null
  dayCount: number | null
  days: Day[]
  extra: string | null
} => {
  if (recurranceType === "none") {
    return {
      type: null,
      count: null,
      weekDay: null,
      dayCount: null,
      days: [],
      extra: null,
    }
  }

  const [type, count, weekDay, dayCount, daysAndExtra] = recurranceType.split("_")
  const [days, extra] = daysAndExtra?.split("#")
  const validDays = (days
    ?.split(",")
    .filter((day) => Boolean(day))
    .map(Number)
    .filter((day) => day === 0 || Boolean(day))
    .sort() || []) as Day[]
  let validWeekDay: number | null = Number.parseInt(weekDay)
  if (Number.isNaN(validWeekDay)) validWeekDay = null

  return {
    type: (type as RecurranceType) || null,
    count: Number(count) || null,
    weekDay: validWeekDay,
    dayCount: Number(dayCount) || null,
    days: validDays,
    extra: extra || null,
  }
}
const getRecurranceType = (recurranceType: string | null): RecurranceType => {
  if (!recurranceType) return "day"
  return recurranceType.split("_")[0] as RecurranceType
}
const getDailyRecurranceData = (recurranceType: string | null): DailyRecurranceData => {
  let dailyRecurranceData: DailyRecurranceData = {
    count: 1,
  }
  if (recurranceType) {
    const { type, count } = splitRecurranceType(recurranceType)
    if (type === "day") {
      dailyRecurranceData = {
        count: count || 1,
      }
    }
  }
  return dailyRecurranceData
}
const getWeelkyRecurranceData = (recurranceType: string | null, startDate: string | null): WeeklyRecurranceData => {
  let weeklyRecurranceData: WeeklyRecurranceData = {
    days: startDate ? [new Date(startDate).getDay() as Day] : [],
    count: 1,
  }
  if (recurranceType) {
    const { type, count, days } = splitRecurranceType(recurranceType)
    if (type === "week") {
      weeklyRecurranceData = {
        days,
        count: count || 1,
      }
    }
  }

  return weeklyRecurranceData
}
const getMonthlyRecurranceData = (recurranceType: string | null, startDate: string | null): MonthlyRecurranceData => {
  let monthlyRecurranceData: MonthlyRecurranceData = {
    type: "date",
    dayCount: 1,
    monthCount: 1,
    weekDay: 1,
  }
  if (recurranceType) {
    const { type, count, weekDay, dayCount } = splitRecurranceType(recurranceType)
    if (type === "month") {
      monthlyRecurranceData.monthCount = count || 1
      if (weekDay || weekDay === 0) {
        monthlyRecurranceData.type = "weekday"
        monthlyRecurranceData.weekDay = weekDay
        monthlyRecurranceData.dayCount = dayCount || 1
      } else {
        monthlyRecurranceData.type = "date"
        monthlyRecurranceData.dayCount = startDate ? new Date(startDate).getDate() : 1
      }
    }
  }
  return monthlyRecurranceData
}
const getYearlyRecurranceData = (recurranceType: string | null, startDate: string | null): YearlyRecurranceData => {
  let yearlyRecurranceData: YearlyRecurranceData = {
    type: "date",
    dayCount: 1,
    weekDay: 1,
    month: 0,
  }
  if (recurranceType) {
    const { type, weekDay, dayCount } = splitRecurranceType(recurranceType)
    if (type === "year") {
      yearlyRecurranceData.dayCount = dayCount || 1
      yearlyRecurranceData.month = startDate ? new Date(startDate).getMonth() : 0

      if (weekDay || weekDay === 0) {
        yearlyRecurranceData.type = "weekday"
        yearlyRecurranceData.weekDay = weekDay
        yearlyRecurranceData.dayCount = dayCount || 1
      } else {
        yearlyRecurranceData.type = "date"
        yearlyRecurranceData.dayCount = startDate ? new Date(startDate).getDate() : 1
      }
    }
  }
  return yearlyRecurranceData
}
const getEndRecurranceData = (recurranceType: string | null, recurranceEndDate: string | null): EndRecurranceData => {
  let endRecurranceData: EndRecurranceData = {
    type: "never",
    count: 1,
    date: new Date(8.64e15),
  }
  if (recurranceType) {
    const { extra } = splitRecurranceType(recurranceType)
    if (extra) {
      const count = Number(extra)
      const isTypeAfter = Boolean(count)
      if (isTypeAfter) {
        endRecurranceData.type = "after"
        endRecurranceData.count = count
      }
    } else if (!extra && recurranceEndDate) {
      endRecurranceData.type = "on"
      endRecurranceData.date = new Date(recurranceEndDate)
    }
  }
  return endRecurranceData
}

export const useEventRecurringWidget = ({
  startDate,
  endDate,
  recurranceType = null,
  recurranceEndDate = null,
}: UseEventRecurringWidgetProps) => {
  const [update, setUpdate] = useState(false)
  const [type, setType] = useState<RecurranceType>(getRecurranceType(recurranceType))
  const [dailyRecurranceData, setDailyRecurranceData] = useState<DailyRecurranceData>(
    getDailyRecurranceData(recurranceType)
  )
  const [weeklyRecurranceData, setWeeklyRecurranceData] = useState<WeeklyRecurranceData>(
    getWeelkyRecurranceData(recurranceType, startDate)
  )
  const [monthlyRecurranceData, setMonthlyRecurranceData] = useState<MonthlyRecurranceData>(
    getMonthlyRecurranceData(recurranceType, startDate)
  )
  const [yearlyRecurranceData, setYearlyRecurranceData] = useState<YearlyRecurranceData>(
    getYearlyRecurranceData(recurranceType, startDate)
  )
  const [endRecurranceData, setEndRecurranceData] = useState<EndRecurranceData>(
    getEndRecurranceData(recurranceType, recurranceEndDate)
  )

  const onChangeType = (type: RecurranceType) => {
    setType(type)
    setUpdate(true)
  }
  const onChangeDailyRecurranceData = (data: DailyRecurranceData) => {
    setDailyRecurranceData(data)
    setUpdate(true)
  }
  const onChangeWeeklyRecurranceData = (data: WeeklyRecurranceData) => {
    setWeeklyRecurranceData(data)
    setUpdate(true)
  }
  const onChangeMonthlyRecurranceData = (data: MonthlyRecurranceData) => {
    setMonthlyRecurranceData(data)
    setUpdate(true)
  }
  const onChangeYearlyRecurranceData = (data: YearlyRecurranceData) => {
    setYearlyRecurranceData(data)
    setUpdate(true)
  }
  const onChangeEndRecurranceData = (data: EndRecurranceData) => {
    setEndRecurranceData(data)
    setUpdate(true)
  }

  const getReccuranceType = (): string => {
    let recType = ""

    if (type === "day") {
      recType += `day_${dailyRecurranceData.count}___`
    }

    if (type === "week") {
      recType += `week_${weeklyRecurranceData.count}___`
      recType += weeklyRecurranceData.days.join(",")
    }

    if (type === "month") {
      if (monthlyRecurranceData.type === "date") {
        recType += `month_${monthlyRecurranceData.monthCount}___`
      } else {
        recType += `month_${monthlyRecurranceData.monthCount}_${monthlyRecurranceData.weekDay}_${monthlyRecurranceData.dayCount}_`
      }
    }

    if (type === "year") {
      if (yearlyRecurranceData.type === "date") {
        recType += `year_1___`
      } else {
        recType += `year_1_${yearlyRecurranceData.weekDay}_${yearlyRecurranceData.dayCount}_`
      }
    }

    if (endRecurranceData.type === "never") recType += "#no"
    if (endRecurranceData.type === "after") recType += `#${endRecurranceData.count}`
    return recType
  }
  const getReccuranceEndDate = (): string | null => {
    if (endRecurranceData.type === "on") return endRecurranceData.date.toISOString()
    return null
  }
  const getStartDate = (): string | null => {
    if (!startDate) return null
    if (type === "month" && monthlyRecurranceData.type === "date") {
      const newStartDate = new Date(startDate)
      newStartDate.setDate(monthlyRecurranceData.dayCount)
      return newStartDate.toISOString()
    }
    if (type === "year") {
      const newStartDate = new Date(startDate)
      if (yearlyRecurranceData.type === "date") {
        newStartDate.setDate(yearlyRecurranceData.dayCount)
        newStartDate.setMonth(yearlyRecurranceData.month)
        return newStartDate.toISOString()
      }
      if (yearlyRecurranceData.type === "weekday") {
        newStartDate.setMonth(yearlyRecurranceData.month)
        return newStartDate.toISOString()
      }
    }
    return startDate
  }
  const getEndDate = (): string | null => {
    if (!startDate || !endDate) return null
    const eventDuration = (new Date(endDate).getTime() - new Date(startDate).getTime()) / 1000
    const newStartDate = getStartDate()
    if (!newStartDate) return null
    const newEndDate = new Date(new Date(newStartDate).getTime() + eventDuration * 1000)
    return newEndDate.toISOString()
  }

  return {
    type,
    dailyRecurranceData,
    weeklyRecurranceData,
    monthlyRecurranceData,
    yearlyRecurranceData,
    endRecurranceData,
    update,
    onChangeType,
    onChangeDailyRecurranceData,
    onChangeWeeklyRecurranceData,
    onChangeMonthlyRecurranceData,
    onChangeYearlyRecurranceData,
    onChangeEndRecurranceData,
    getReccuranceType,
    getReccuranceEndDate,
    getStartDate,
    getEndDate,
    setUpdate,
  }
}

const EventRecurringWidget = ({
  startDate,
  endDate,
  isRecurringEvent,
  recurranceType,
  recurranceEndDate,
  onChange,
  disabled = false,
  firstDayOfWeek,
}: EventRecurringWidgetProps) => {
  const translations = useTranslations()
  const classes = useStyles()
  const { dateFormat } = useDateFormat()
  const timeZone = useUserTimeZone()
  const dateTimeService = new DateTimeService({
    dateFormat,
    timeZone,
  })
  const format = dateTimeService.format.bind(dateTimeService)

  const {
    type,
    dailyRecurranceData,
    weeklyRecurranceData,
    monthlyRecurranceData,
    yearlyRecurranceData,
    endRecurranceData,
    update,
    onChangeType,
    onChangeDailyRecurranceData,
    onChangeWeeklyRecurranceData,
    onChangeMonthlyRecurranceData,
    onChangeYearlyRecurranceData,
    onChangeEndRecurranceData,
    getReccuranceType,
    getReccuranceEndDate,
    getStartDate,
    getEndDate,
    setUpdate,
  } = useEventRecurringWidget({
    startDate,
    endDate,
    recurranceType,
    recurranceEndDate,
  })

  React.useEffect(() => {
    if (update) {
      onChange({
        recurranceType: getReccuranceType(),
        recurranceEndDate: getReccuranceEndDate(),
        startDate: getStartDate(),
        endDate: getEndDate(),
      })
      setUpdate(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update])

  const handleChangeType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as RecurranceType
    onChangeType(value)
  }

  const handleChangeDailyRecurranceCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value)
    if (value < 1) value = 1
    onChangeDailyRecurranceData({ ...dailyRecurranceData, count: value })
  }

  const handleChangeWeeklyRecurranceDays = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number.parseInt(event.target.value) as Day
    const days = weeklyRecurranceData.days.includes(value)
      ? weeklyRecurranceData.days.filter((day) => day !== value)
      : [...weeklyRecurranceData.days, value]
    onChangeWeeklyRecurranceData({ ...weeklyRecurranceData, days })
  }
  const handleChangeWeeklyRecurranceCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value)
    if (value < 1) value = 1
    onChangeWeeklyRecurranceData({ ...weeklyRecurranceData, count: value })
  }

  const handleChangeMonthlyRecurranceType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as MonthlyRecurranceData["type"]
    const data = { ...monthlyRecurranceData, type: value, dayCount: 1, monthCount: 1 }
    onChangeMonthlyRecurranceData(data)
  }
  const handleChangeMonthlyRecurranceDayCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value)
    if (monthlyRecurranceData.type === "date") {
      value = value < 1 ? 1 : value > 31 ? 31 : value
    } else {
      value = value < 1 ? 1 : value > 5 ? 5 : value
    }
    onChangeMonthlyRecurranceData({ ...monthlyRecurranceData, dayCount: value })
  }
  const handleChangeMonthlyRecurranceMonthCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value)
    if (value < 1) value = 1
    if (value > 12) value = 12
    onChangeMonthlyRecurranceData({ ...monthlyRecurranceData, monthCount: value })
  }
  const handleChangeMonthlyRecurranceWeekDay = (event: React.ChangeEvent<{ value?: unknown }>) => {
    const value = Number(event.target.value)
    onChangeMonthlyRecurranceData({ ...monthlyRecurranceData, weekDay: value })
  }

  const handleChangeYearlyRecurranceType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as YearlyRecurranceData["type"]
    onChangeYearlyRecurranceData({ ...yearlyRecurranceData, type: value, dayCount: 1, month: 0 })
  }
  const handleChangeYearlyRecurranceDayCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value)
    if (yearlyRecurranceData.type === "date") {
      value = value < 1 ? 1 : value > 31 ? 31 : value
    } else {
      value = value < 1 ? 1 : value > 5 ? 5 : value
    }
    onChangeYearlyRecurranceData({ ...yearlyRecurranceData, dayCount: value })
  }
  const handleChangeYearlyRecurranceWeekDay = (event: React.ChangeEvent<{ value?: unknown }>) => {
    const value = Number(event.target.value)
    onChangeYearlyRecurranceData({ ...yearlyRecurranceData, weekDay: value })
  }
  const handleChangeYearlyRecurranceMonth = (event: React.ChangeEvent<{ value?: unknown }>) => {
    const value = Number(event.target.value)
    onChangeYearlyRecurranceData({ ...yearlyRecurranceData, month: value })
  }

  const handleChangeRecurranceEndType = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value as EndRecurranceData["type"]
    const isTypeOn = value === "on"
    const date = isTypeOn ? addMonths(new Date(), 1) : endRecurranceData.date
    onChangeEndRecurranceData({ ...endRecurranceData, type: value, date })
  }
  const handleChangeRecurranceEndCount = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(event.target.value)
    if (value < 1) value = 1
    onChangeEndRecurranceData({ ...endRecurranceData, count: value })
  }
  const handleChangeRecurranceEndDate = (date: Date) => {
    const value = dateTimeService.endOfDay(new Date(date))
    onChangeEndRecurranceData({ ...endRecurranceData, date: value })
  }

  const weekDays: { [key: string]: Day } = {
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
    Sunday: 0,
  }

  const months: { [key: string]: number } = {
    January: 0,
    February: 1,
    March: 2,
    April: 3,
    May: 4,
    June: 5,
    July: 6,
    August: 7,
    September: 8,
    October: 9,
    November: 10,
    December: 11,
  }

  return (
    <>
      <div className={classes.repeatEventHeader}>
        <Typography component="h4" variant="body1" role="button">
          <strong>{translations.repeatEventSectionHeader}</strong>
        </Typography>
        <Button
          onClick={() => {
            const data = {
              recurranceType: isRecurringEvent ? null : getReccuranceType(),
              recurranceEndDate: isRecurringEvent ? null : getReccuranceEndDate(),
              startDate: isRecurringEvent ? null : getStartDate(),
              endDate: isRecurringEvent ? null : getEndDate(),
            }
            onChange(data)
          }}
          className={classes.repeatButton}
          variant="outlined"
          size="small"
          disabled={disabled}
        >
          {isRecurringEvent && !disabled ? translations.disable : translations.enable}
        </Button>
      </div>
      {isRecurringEvent && !disabled && (
        <div className={classes.container}>
          <FormControl component="fieldset">
            <RadioGroup value={type} onChange={handleChangeType}>
              <FormControlLabel value="day" control={<Radio />} label={translations.daily} />
              <FormControlLabel value="week" control={<Radio />} label={translations.weekly} />
              <FormControlLabel value="month" control={<Radio />} label={translations.monthly} />
              <FormControlLabel value="year" control={<Radio />} label={translations.yearly} />
            </RadioGroup>
          </FormControl>

          <Divider />
          <div className={classes.midSection}>
            {type === "day" && (
              <>
                {translations.every}
                <TextField
                  size="small"
                  type="number"
                  variant="outlined"
                  value={dailyRecurranceData.count}
                  onChange={handleChangeDailyRecurranceCount}
                  InputProps={{ inputProps: { min: 1 } }}
                  className={classes.countInput}
                />
                {translations.dayLabel.toLowerCase()}
              </>
            )}

            {type === "week" && (
              <FormControl component="fieldset">
                <div>
                  <label>{translations.every}</label>
                  <TextField
                    size="small"
                    type="number"
                    variant="outlined"
                    value={weeklyRecurranceData.count}
                    onChange={handleChangeWeeklyRecurranceCount}
                    InputProps={{ inputProps: { min: 1 } }}
                    className={classes.countInput}
                  />
                  <label>{`${translations.weekLabel.toLowerCase()} ${translations.followingDays}`}:</label>
                </div>
                <section>
                  <div className={classes.daysContainer}>
                    {Object.keys(weekDays).map((day) => (
                      <div key={day}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={weeklyRecurranceData.days.includes(weekDays[day])}
                              onChange={handleChangeWeeklyRecurranceDays}
                              value={weekDays[day]}
                            />
                          }
                          label={translations.fullDayNames[weekDays[day]]}
                        />
                      </div>
                    ))}
                  </div>
                  {weeklyRecurranceData.days.length === 0 && (
                    <Alert severity="error">{translations.selectDaysError}</Alert>
                  )}
                </section>
              </FormControl>
            )}

            {type === "month" && (
              <FormControl component="fieldset">
                <RadioGroup value={monthlyRecurranceData.type} onChange={handleChangeMonthlyRecurranceType}>
                  <FormControlLabel
                    value="date"
                    control={<Radio />}
                    label={
                      <>
                        {translations.repeatOnThe}
                        <TextField
                          size="small"
                          type="number"
                          variant="outlined"
                          value={monthlyRecurranceData.type === "date" ? monthlyRecurranceData.dayCount : 1}
                          onChange={handleChangeMonthlyRecurranceDayCount}
                          InputProps={{ inputProps: { min: 1, max: 31 } }}
                          className={classes.countInput}
                          disabled={monthlyRecurranceData.type === "weekday"}
                        />
                        {`${translations.dayLabel.toLowerCase()} ${translations.every.toLowerCase()}`}
                        <TextField
                          size="small"
                          type="number"
                          variant="outlined"
                          value={monthlyRecurranceData.type === "date" ? monthlyRecurranceData.monthCount : 1}
                          onChange={handleChangeMonthlyRecurranceMonthCount}
                          InputProps={{ inputProps: { min: 1, max: 12 } }}
                          className={classes.countInput}
                          disabled={monthlyRecurranceData.type === "weekday"}
                        />
                        {translations.monthLabel.toLowerCase()}
                      </>
                    }
                  />
                  <FormControlLabel
                    value="weekday"
                    control={<Radio />}
                    label={
                      <>
                        {translations.on}
                        <TextField
                          size="small"
                          type="number"
                          variant="outlined"
                          value={monthlyRecurranceData.type === "weekday" ? monthlyRecurranceData.dayCount : 1}
                          onChange={handleChangeMonthlyRecurranceDayCount}
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          className={classes.countInput}
                          disabled={monthlyRecurranceData.type === "date"}
                        />
                        <select
                          value={monthlyRecurranceData.weekDay}
                          onChange={handleChangeMonthlyRecurranceWeekDay}
                          className={classes.select}
                          aria-label="Without label"
                          disabled={monthlyRecurranceData.type === "date"}
                        >
                          {Object.keys(weekDays).map((day) => (
                            <option key={day} value={weekDays[day]}>
                              {translations.fullDayNames[weekDays[day]]}
                            </option>
                          ))}
                        </select>
                        {translations.every.toLowerCase()}
                        <TextField
                          size="small"
                          type="number"
                          variant="outlined"
                          value={monthlyRecurranceData.type === "weekday" ? monthlyRecurranceData.monthCount : 1}
                          onChange={handleChangeMonthlyRecurranceMonthCount}
                          InputProps={{ inputProps: { min: 1, max: 12 } }}
                          className={classes.countInput}
                          disabled={monthlyRecurranceData.type === "date"}
                        />
                        {translations.monthLabel.toLowerCase()}
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
            )}

            {type === "year" && (
              <FormControl component="fieldset">
                <RadioGroup value={yearlyRecurranceData.type} onChange={handleChangeYearlyRecurranceType}>
                  <FormControlLabel
                    value="date"
                    control={<Radio />}
                    label={
                      <>
                        {translations.every}
                        <TextField
                          size="small"
                          type="number"
                          variant="outlined"
                          value={yearlyRecurranceData.type === "date" ? yearlyRecurranceData.dayCount : 1}
                          onChange={handleChangeYearlyRecurranceDayCount}
                          InputProps={{ inputProps: { min: 1, max: 31 } }}
                          className={classes.countInput}
                          disabled={yearlyRecurranceData.type === "weekday"}
                        />
                        {translations.dayIn.toLowerCase()}
                        <select
                          value={yearlyRecurranceData.type === "date" ? yearlyRecurranceData.month : 0}
                          onChange={handleChangeYearlyRecurranceMonth}
                          className={classes.select}
                          aria-label="Without label"
                          disabled={yearlyRecurranceData.type === "weekday"}
                        >
                          {Object.keys(months).map((month) => (
                            <option key={month} value={months[month]}>
                              {translations.fullMonthNames[months[month]]}
                            </option>
                          ))}
                        </select>
                      </>
                    }
                  />
                  <FormControlLabel
                    value="weekday"
                    control={<Radio />}
                    label={
                      <>
                        {translations.every}
                        <TextField
                          size="small"
                          type="number"
                          variant="outlined"
                          value={yearlyRecurranceData.type === "weekday" ? yearlyRecurranceData.dayCount : 1}
                          onChange={handleChangeYearlyRecurranceDayCount}
                          InputProps={{ inputProps: { min: 1, max: 5 } }}
                          className={classes.countInput}
                          disabled={yearlyRecurranceData.type === "date"}
                        />
                        <select
                          value={yearlyRecurranceData.weekDay}
                          onChange={handleChangeYearlyRecurranceWeekDay}
                          className={classes.select}
                          aria-label="Without label"
                          disabled={yearlyRecurranceData.type === "date"}
                        >
                          {Object.keys(weekDays).map((day) => (
                            <option key={day} value={weekDays[day]}>
                              {translations.fullDayNames[weekDays[day]]}
                            </option>
                          ))}
                        </select>
                        {translations.inLabel.toLowerCase()}
                        <select
                          value={yearlyRecurranceData.type === "weekday" ? yearlyRecurranceData.month : 0}
                          onChange={handleChangeYearlyRecurranceMonth}
                          className={classes.select}
                          aria-label="Without label"
                          disabled={yearlyRecurranceData.type === "date"}
                        >
                          {Object.keys(months).map((month) => (
                            <option key={month} value={months[month]}>
                              {translations.fullMonthNames[months[month]]}
                            </option>
                          ))}
                        </select>
                      </>
                    }
                  />
                </RadioGroup>
              </FormControl>
            )}
          </div>

          <Divider />
          <div className={classes.endSection}>
            <FormControl component="fieldset">
              <RadioGroup value={endRecurranceData.type} onChange={handleChangeRecurranceEndType}>
                <FormControlLabel value="never" control={<Radio />} label="No end date" />
                <FormControlLabel
                  value="after"
                  control={<Radio />}
                  label={
                    <>
                      {translations.after}
                      <TextField
                        size="small"
                        type="number"
                        variant="outlined"
                        value={endRecurranceData.count}
                        onChange={handleChangeRecurranceEndCount}
                        InputProps={{ inputProps: { min: 1 } }}
                        className={classes.countInput}
                        disabled={endRecurranceData.type !== "after"}
                      />
                      {translations.occurrences}
                    </>
                  }
                />
                <FormControlLabel
                  value="on"
                  control={<Radio />}
                  label={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <label style={{ marginRight: "8px" }}>End by</label>
                      {endRecurranceData.type === "on" && (
                        <DateWidget
                          formattedDate={format(endRecurranceData.date)}
                          isoDate={endRecurranceData.date.toISOString()}
                          onDateChange={handleChangeRecurranceEndDate}
                          label=""
                          isClearable={false}
                          showTimeSelect={false}
                          isReadOnly={endRecurranceData.type !== "on"}
                          {...(firstDayOfWeek && { firstDayOfWeek })}
                        />
                      )}
                      {endRecurranceData.type !== "on" && <Calendar size={18} />}
                    </div>
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      )}
    </>
  )
}
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(0, 2),
  },
  countInput: {
    maxWidth: 56,
    margin: theme.spacing(0, 1),
    "& input": {
      height: 8,
      padding: theme.spacing(1.25, 0.5, 1.25, 1),
    },
  },
  select: {
    height: 28,
    margin: theme.spacing(0, 1),
    border: `1px solid ${theme.palette.grey[400]}`,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,

    "&:hover": {
      outline: "none",
      border: `1px solid ${theme.palette.primary.main}`,
      cursor: "pointer",
    },

    "&:focus": {
      outline: "none",
      border: `1px solid ${theme.palette.grey[400]}`,
    },
  },
  midSection: {
    padding: theme.spacing(2, 0),
  },
  daysContainer: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr", // Creates three columns
    padding: theme.spacing(1, 0),
  },
  endSection: {
    padding: theme.spacing(2, 0),
  },
  repeatButton: { minWidth: 132 },
  repeatEventHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))

type RecurranceType = "day" | "week" | "month" | "year"
type DailyRecurranceData = {
  count: number
}
type WeeklyRecurranceData = {
  days: Day[]
  count: number
}
type MonthlyRecurranceData = {
  type: "date" | "weekday"
  dayCount: number
  monthCount: number
  weekDay: number
}
type YearlyRecurranceData = {
  type: "date" | "weekday"
  dayCount: number
  weekDay: number
  month: number
}
type EndRecurranceData = {
  type: "never" | "after" | "on"
  count: number
  date: Date
}
type UseEventRecurringWidgetProps = {
  startDate: string | null
  endDate: string | null
  recurranceType?: string | null
  recurranceEndDate?: string | null
}
type EventRecurringWidgetProps = {
  startDate: string | null
  endDate: string | null
  isRecurringEvent: boolean
  disabled?: boolean
  recurranceType?: string | null
  recurranceEndDate?: string | null
  firstDayOfWeek?: DayOfWeek
  onChange: (params: {
    startDate: string | null
    endDate: string | null
    recurranceType: string | null
    recurranceEndDate: string | null
  }) => void
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")
  const dateTranslations = useDateTranslations()
  const recurringEventTranslations = translations?.recurringEventWidget || {}

  const {
    daily = defaults.daily,
    weekly = defaults.weekly,
    monthly = defaults.monthly,
    yearly = defaults.yearly,
  } = translations

  const { dayLabel, weekLabel, monthLabel, fullDayNames, fullMonthNames } = dateTranslations

  const {
    repeatEventSectionHeader = defaults.repeatEventSectionHeader,
    disable = defaults.disable,
    enable = defaults.enable,
    every = defaults.every,
    repeat = defaults.repeat,
    on = defaults.on,
    inLabel = defaults.inLabel,
    repeatOnThe = defaults.repeatOnThe,
    followingDays = defaults.followingDays,
    noEndDate = defaults.noEndDate,
    after = defaults.after,
    occurrences = defaults.occurrences,
    endBy = defaults.endBy,
    dayIn = defaults.dayIn,
    selectDaysError = defaults.selectDaysError,
  } = recurringEventTranslations

  return {
    repeatEventSectionHeader,
    disable,
    enable,
    daily,
    weekly,
    monthly,
    yearly,
    dayLabel,
    weekLabel,
    monthLabel,
    fullDayNames,
    fullMonthNames,
    repeat,
    every,
    on,
    inLabel,
    dayIn,
    repeatOnThe,
    followingDays,
    noEndDate,
    after,
    occurrences,
    endBy,
    selectDaysError,
  }
}

const defaultTranslations = {
  repeatEventSectionHeader: "Repeat Event",
  disable: "Disable",
  enable: "Enable",
  daily: "Daily",
  weekly: "Weekly",
  monthly: "Monthly",
  yearly: "Yearly",
  every: "Every",
  dayLabel: "day",
  weekLabel: "week",
  monthLabel: "month",
  fullDayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  fullMonthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  repeat: "Repeat",
  on: "On",
  dayIn: "day in",
  inLabel: "in",
  repeatOnThe: "Repeat on the",
  followingDays: "on the following days",
  noEndDate: "No end date",
  after: "After",
  occurrences: "occurrences",
  endBy: "End by",
  selectDaysError: "Please select at least one day",
}
type Translations = typeof defaultTranslations

export default EventRecurringWidget
