import DefaultContactCard from "../../components/DefaultContactCard"

import { Avatar } from "@material-ui/core"
import { startCase, values } from "lodash"
import { useDispatch } from "react-redux"
import { ChangeCustomerDefaultContact } from "../store/actions"
import type { IOrgCustomerNormalized } from "../interfaces/customer-normalized"

const OrganisationCustomerDefaultContact = ({ customer }: OrganisationCustomerDefaultContactProps) => {
  const dispatch = useDispatch()
  if (!customer) return null
  const contactList = values(customer.contacts)

  /**
   *  "contactSelectItems",
   *  list items to be passed to async select component,
   *  which renders these items as a list,
   *  from which one item can be selected as default contact
   */
  const contactListItems = contactList.map((contact) => {
    const initials = contact.firstname[0] + contact.lastname[0]
    const fullname = `${contact.firstname} ${contact.lastname}`
    const isDefaultContact = customer.defaultContact?.id === contact.id

    const onSelect = () => {
      return dispatch(
        ChangeCustomerDefaultContact({
          customerId: contact.customerId as string,
          contactId: contact.id,
        })
      ) as unknown as Promise<unknown>
    }

    const avatar = <Avatar>{initials.toUpperCase()}</Avatar>

    return {
      value: contact.id,
      primaryText: startCase(fullname),
      secondaryText: isDefaultContact ? "Default contact" : "",
      onSelect,
      selected: isDefaultContact,
      avatar,
    }
  })

  return (
    <DefaultContactCard
      defaultContact={customer.defaultContact}
      contactListItems={contactListItems}
      actionable={customer.isActive}
    />
  )
}

type OrganisationCustomerDefaultContactProps = {
  customer: IOrgCustomerNormalized
}

export default OrganisationCustomerDefaultContact
