import React from "react"
import { Box, IconButton, makeStyles, TextField, Theme } from "@material-ui/core"
import { useZodSchemas } from "../../hooks/use-zod-schemas"
import { Trash } from "react-feather"

const RecipientEmail = React.forwardRef<HTMLInputElement, RecipientEmailProps>(
  ({ email, onSaveRecipientEmail, onRemoveRecipientEmail, isInputDisabled, autoFocus }, ref) => {
    const classes = useStyles()
    const { ZodEmail } = useZodSchemas()
    const [emailError, setEmailError] = React.useState("")
    const [isEditing, setIsEditing] = React.useState(false)

    const saveRecipientEmail = (email: string) => {
      if (email === "") return onSaveRecipientEmail(email)

      const parseResult = ZodEmail.safeParse(email)
      if (parseResult.success) {
        onSaveRecipientEmail(email)
        setEmailError("")
      } else {
        return setEmailError(parseResult.error.errors[0].message)
      }
    }

    const handleBlur = (email: string) => {
      saveRecipientEmail(email)
      setIsEditing(false)
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        saveRecipientEmail(e.currentTarget.value)
      }
    }

    return (
      <Box key={email} className={classes.emailInputRow}>
        <TextField
          ref={ref}
          size="small"
          variant="outlined"
          defaultValue={email}
          onFocus={() => setIsEditing(true)}
          onBlur={(e) => handleBlur(e.target.value)}
          InputProps={{ onKeyDown: handleKeyDown }}
          error={Boolean(emailError)}
          helperText={emailError}
          autoFocus={autoFocus}
          disabled={isInputDisabled}
          fullWidth
        />
        {email && !isEditing && !isInputDisabled ? (
          <IconButton onClick={() => onRemoveRecipientEmail(email)} color="inherit">
            <Trash size={20} />
          </IconButton>
        ) : null}
      </Box>
    )
  }
)

const useStyles = makeStyles((theme: Theme) => ({
  emailInputRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: 8,
    "& > .MuiTextField-root": {
      minWidth: 300,
      maxWidth: 300,
      marginRight: 8,
    },
  },
}))

export default RecipientEmail

type RecipientEmailProps = {
  email: string
  onRemoveRecipientEmail: (email: string) => void
  onSaveRecipientEmail: (email: string) => void
  isInputDisabled?: boolean
  autoFocus?: boolean
}
