import { chain, groupBy } from "lodash"
import { invariant } from "../../utils/invariant"
import { COLOR_GRAY } from "../../constants"
import { LinkType } from "../../types/common"
import { DateTimeService } from "../../services/date-time-service"

// This function is a hacky solution to highlight the row in the grid when a task is added.
// Don't use this function for any other purpose.
export const forceChangeBackgroundColorOfRow = ({
  row,
  color,
  leftColumns = true,
  midColumns = true,
}: ChangeBackgroundColorOfRowParams) => {
  // @ts-ignore
  const leftCols: HTMLElement[] = row?.r0?.children || []
  // @ts-ignore
  const middleCols: HTMLElement[] = row?.r1?.children || []
  const columns: HTMLElement[] = []

  if (leftColumns) columns.push(...leftCols)
  if (midColumns) columns.push(...middleCols)

  for (const td of columns) {
    td.style.backgroundColor = color
  }
}

export const toggleClassInRow = ({ grid, row, className }: ToggleClassOfRowParams) => {
  if (!grid || !row) return
  row.Class = typeof row.Class === "string" ? row.Class : ""
  row.Class = row.Class.includes(className) ? row.Class.replace(className, "") : `${row.Class} ${className}`
  grid.RefreshRow(row)
}

export const getLatestCreatedTasks = <T extends { id: string; createdAt: string }>(tasks: T[]): T[] => {
  const groupedByCreatedAt = groupBy(tasks, "createdAt")
  const latestCreatedAt = chain(groupedByCreatedAt)
    .keys()
    .map((createdAt) => new Date(createdAt).getTime())
    .max()
    .value()
  return groupedByCreatedAt[new Date(latestCreatedAt).toISOString()]
}

export const getHighestLevelTask = (tasks: TaskLike[]) => {
  return tasks.reduce((highestLevelTask, currentTask) => {
    if (currentTask.ancestors.length < highestLevelTask.ancestors.length) {
      return currentTask
    } else {
      return highestLevelTask
    }
  })
}

export const createTreegrid = ({ id, layout, data }: { id: string; layout: any; data: any }) => {
  invariant(id, "id is required to create a new treegrid")
  invariant(layout, "layout is required to create a new treegrid")
  invariant(data, "data is required to create a new treegrid")
  const layoutScript = `${id}Layout`

  if (!window.Grids[id]) {
    // @ts-ignore
    window[layoutScript] = layout

    // eslint-disable-next-line no-console
    console.log("Creating treegrid", id)
    window.TreeGrid(
      {
        Layout: { Script: layoutScript },
        Data: { Data: { Body: [data] } },
        Debug:
          process.env.NODE_ENV === "development"
            ? "Problem,Error" /**Problem,Error,ioerror,io,cookie,page,event,check */
            : "",
      },
      id
    )
  }
}

export const destroyTreegrid = (id: string) => {
  invariant(id, "id is required to destroy a treegrid")
  // eslint-disable-next-line no-console
  console.log("Destroying treegrid", id)
  window.Grids[id]?.Dispose()
  // @ts-expect-error
  window.Grids.length = 0
}

export const updateGridData = ({ id, data }: { id: string; data: any }) => {
  invariant(id, "id is required to update grid data")
  invariant(data, "data is required to update grid data")
  if (window.Grids[id]) {
    // @ts-ignore
    window.Grids[id].Source.Data.Data.Body = [data]
    window.Grids[id]?.ReloadBody()
  }
}

export const getSummaryTextWithRowCount = (summaryText: string, rowCount: number): string => {
  return summaryText.replace("{{rowCount}}", `${rowCount}`).replace(/\d+/g, `${rowCount}`)
}

export const syncDataFromServerToGrid = (grid: TGrid, data: any[]) => {
  if (!grid || !data.length) return
  const changes = JSON.stringify({ Changes: data })
  grid.AddDataFromServer(changes)
  grid.AcceptChanges()
}

export const getTitleHtmlPrefix = (props: {
  projectLink: LinkType | null
  taskLink: LinkType | null
  ancestorTaskLinks: LinkType[]
}): string => {
  const { projectLink, taskLink, ancestorTaskLinks } = props

  let html = ""
  const spanStyles = `color: ${COLOR_GRAY} !important; cursor: pointer;font-size: 0.625rem; font-weight: 400; overflow:hidden`

  if (projectLink) {
    if (projectLink.disabled) {
      html += `<span style='${spanStyles}' >${projectLink.label}  </span>`
    } else {
      html += `<span onclick='window.Grids.onClickTitleLink("${projectLink.href}/basic"); event.stopPropagation();' style='${spanStyles}' onmouseover="this.style.textDecoration='underline';" onmouseout="this.style.textDecoration='none';">${projectLink.label}  /  </span>`
    }
  }

  if (ancestorTaskLinks.length > 0) {
    ancestorTaskLinks.forEach((link) => {
      if (link.disabled) {
        html += `<span style='${spanStyles}'>${link.label} / </span>`
      } else {
        html += `<span onclick='window.Grids.onClickTitleLink("${link.href}/basic"); event.stopPropagation();' style='${spanStyles}'  onmouseover="this.style.textDecoration='underline';" onmouseout="this.style.textDecoration='none';">${link.label}  /  </span>`
      }
    })
  }

  if (taskLink) {
    if (taskLink.disabled) {
      html += `<span style='${spanStyles}'>${taskLink.label}</span>`
    } else {
      html += `<span onclick='window.Grids.onClickTitleLink("${taskLink.href}/basic"); event.stopPropagation();' style='${spanStyles}' onmouseover="this.style.textDecoration='underline';" onmouseout="this.style.textDecoration='none';">${taskLink.label}</span>`
    }
  }

  return `<div>${html}</div>`
}

export function getGanttGanttHtmlRight(props: {
  startDate: string | null
  endDate: string | null
  title: string
  dateTimeService: DateTimeService
  showTime?: boolean
}): string {
  const { startDate, endDate, title, dateTimeService, showTime = false } = props
  let text = `${title}`
  let schedule = ""
  const hasTitleAndSchedule = Boolean(text) && (startDate || endDate)
  if (hasTitleAndSchedule) text += ` :`
  if (startDate) {
    const date = new Date(startDate)
    const plannedEndDateYear = endDate ? new Date(endDate).getFullYear() : null
    const plannedStartDateYear = date.getFullYear()
    const sameDayPlan = endDate ? new Date(endDate).getTime() - date.getTime() < 24 * 60 * 60 * 1000 : false
    const showYear = plannedEndDateYear !== plannedStartDateYear || sameDayPlan
    const formattedDate = showYear
      ? dateTimeService.format(date, dateTimeService.getDateFormat())
      : dateTimeService.formatWithoutYear(date, dateTimeService.getDateFormat())
    schedule += ` ${formattedDate}`
    if (showTime) {
      const time = dateTimeService.getDoubleDigitTime(date)
      schedule += ` ${time}`
    }
  }
  if (endDate) {
    const date = new Date(endDate)
    const formattedDate = dateTimeService.format(date, dateTimeService.getDateFormat())
    const sameDayPlan = startDate ? date.getTime() - new Date(startDate).getTime() < 24 * 60 * 60 * 1000 : false
    schedule += sameDayPlan ? "" : ` - ${formattedDate}`
    if (showTime) {
      const time = dateTimeService.getDoubleDigitTime(date)
      schedule += sameDayPlan ? ` - ${time}` : ` ${time}`
    }
  }
  if (schedule) text += ` ${schedule}`

  return text
}

type ChangeBackgroundColorOfRowParams = {
  row: TRow
  color: string
  leftColumns?: boolean
  midColumns?: boolean
}

type ToggleClassOfRowParams = {
  grid: TGrid
  row: TRow
  className: string
}

type TaskLike = {
  id: string
  ancestors: string[]
  createdAt: string
}
