import React, { useState } from "react"
import InputAdornment from "@material-ui/core/InputAdornment"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Popper from "@material-ui/core/Popper"
import Paper from "@material-ui/core/Paper"
import Button from "@material-ui/core/Button"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import TextField from "@material-ui/core/TextField"
import Fade from "@material-ui/core/Fade"
import { makeStyles, Theme } from "@material-ui/core"
import { Check, Plus, Search, X } from "react-feather"
import { IWorkspaceNormalized } from "../interfaces/workspace-normalized"
import { useI18n } from "../../hooks"

const AddMembershipDialog = ({ workspace, memberships, handleAddMembership }: AddMembershipDialogProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [filter, setFilter] = useState("")

  if (!workspace.canUpdate) return null

  const filteredMemberships = memberships.filter((member) => {
    return member.fullname.toLowerCase().includes(filter.toLowerCase())
  })

  const toggleMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    anchorEl ? setAnchorEl(null) : setAnchorEl(e.currentTarget)
  }

  return (
    <>
      <Button
        size="small"
        variant="outlined"
        onClick={toggleMenu}
        endIcon={anchorEl ? <X size={16} /> : <Plus size={16} />}
      >
        {anchorEl ? translations.close : translations.add}
      </Button>
      <Popper anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)}>
        <Fade in={anchorEl !== null}>
          <Paper className={classes.paper} elevation={2}>
            <ListItem>
              <TextField
                placeholder={translations.searchMember}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search size={16} />
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                size="small"
                fullWidth
              />
            </ListItem>

            {filteredMemberships.map((member, index) => {
              return (
                <ListItem
                  key={member.membershipId}
                  onClick={() => handleAddMembership(member.membershipId)}
                  divider={index < filteredMemberships.length - 1}
                  dense
                  button
                >
                  <ListItemIcon>{member.isSelected ? <Check size={20} className={classes.icon} /> : null}</ListItemIcon>
                  <ListItemText primary={member.fullname} />
                </ListItem>
              )
            })}

            {filteredMemberships.length === 0 ? <NoMatchFoundMessage /> : null}
          </Paper>
        </Fade>
      </Popper>
    </>
  )
}

const NoMatchFoundMessage = () => {
  const translations = useTranslations()
  return (
    <ListItem>
      <ListItemIcon></ListItemIcon>
      <ListItemText primary={translations.noMatchFound} />
    </ListItem>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    maxHeight: 460,
    overflowY: "auto",
    marginTop: 4,
  },
  icon: {
    color: theme.palette.text.primary,
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n()

  const {
    add = defaults.add,
    close = defaults.close,
    noMatchFound = defaults.noMatchFound,
    searchMember = defaults.searchMember,
  } = translations

  return {
    add,
    close,
    noMatchFound,
    searchMember,
  }
}

const defaultTranslations = {
  add: "Add",
  close: "Close",
  noMatchFound: "No matches found",
  searchMember: "Search members...",
}

type IMembership = {
  membershipId: string
  fullname: string
  isSelected: boolean
}

type AddMembershipDialogProps = {
  workspace: IWorkspaceNormalized
  memberships: IMembership[]
  handleAddMembership: (membershipId: string) => Promise<void>
}

type Translations = typeof defaultTranslations
export default AddMembershipDialog
