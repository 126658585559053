import { useAppSelector } from "../../store"
import { getOptions } from "../store/selectors"
import { getCustomerOptions, getMembershipOptions, getSupplierOptions, getWorkspaceOptions } from "../utils"

export const useOrgOptions = (orgId: string) => {
  const options = useAppSelector((s) => getOptions(s, { orgId }))
  const customerOptions = getCustomerOptions(options)
  const supplierOptions = getSupplierOptions(options)
  const workspaceOptions = getWorkspaceOptions(options)
  const membershipOptions = getMembershipOptions(options)

  return {
    options,
    customerOptions,
    supplierOptions,
    workspaceOptions,
    membershipOptions,
  }
}
