import React from "react"
import Menu from "@material-ui/core/Menu"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import { Link, useHistory } from "react-router-dom"
import { TaskViewModel } from "../../api/task"
import { useTaskMutations } from "../../hooks/use-task-mutations"
import { useConfirmDialog } from "../../../components/ConfirmDialog"
import { useTranslations } from "./use-translations"
import { useUrlWithContext } from "../../../hooks/use-url-with-context"
import { paths } from "../../../paths"
import { Bell, MoreVertical, Sliders, Trash } from "react-feather"
import { makeStyles, Theme, useTheme } from "@material-ui/core"

const useMenuState = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const closeMenu = () => setAnchorEl(null)

  return { anchorEl, isOpen: Boolean(anchorEl), toggleMenu, closeMenu }
}

const useTaskMenuItems = ({ task }: { task: TaskViewModel }) => {
  const actions = useTaskMutations()
  const history = useHistory()
  const translations = useTranslations()
  const { createPathWithContext } = useUrlWithContext()
  const theme = useTheme()

  const tasksPath = createPathWithContext(paths.projectTasks(task.projectId))
  const taskPermissionsPath = createPathWithContext(paths.taskPermissions(task.id))
  const taskNotificationsPath = createPathWithContext(paths.taskNotifications(task.id))
  const isNotificationsButtonVisible = task.canRead
  const isPermissionsButtonVisible = task.canUpdatePermissions
  const isDeleteButtonVisible = task.canDelete

  const deleteTaskAndRedirect = async () => {
    actions.deleteTask(task.id).then(() => history.push(tasksPath))
  }

  const { confirm: confirmDeleteTask } = useConfirmDialog({
    onConfirm: deleteTaskAndRedirect,
    text: translations.deleteTaskConfirmText,
    title: translations.deleteTaskConfirmTitle,
    primaryActionButtonLabel: translations.deleteTaskBtnLabel,
  })

  return [
    {
      id: "notificationsPageLink",
      isVisible: isNotificationsButtonVisible,
      primaryText: translations.notificationsLabel,
      to: taskNotificationsPath,
      icon: <Bell size={18} color={theme.palette.text.primary} />,
    },
    {
      id: "permissionsPageLink",
      isVisible: isPermissionsButtonVisible,
      primaryText: translations.managePermissionsBtnLabel,
      to: taskPermissionsPath,
      icon: <Sliders size={18} color={theme.palette.text.primary} />,
    },
    {
      id: "deleteTaskButton",
      isVisible: isDeleteButtonVisible,
      primaryText: translations.deleteTaskBtnLabel,
      secondaryText: translations.deleteTaskSecondaryText,
      onClick: confirmDeleteTask,
      icon: <Trash size={18} color={theme.palette.text.primary} />,
    },
  ]
}

const TaskMenu = ({ task }: { task: TaskViewModel }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const taskMenuItems = useTaskMenuItems({ task })
  const { anchorEl, isOpen, toggleMenu, closeMenu } = useMenuState()

  const visibleMenuItems = taskMenuItems.filter((item) => item.isVisible !== false)

  return (
    <>
      <Tooltip title={translations.menuAriaLabel}>
        <IconButton
          aria-label={translations.menuAriaLabel}
          size="small"
          onClick={toggleMenu}
          data-test="task-three-dotted-menu"
        >
          <MoreVertical size={16} className={classes.menuIcon} />
        </IconButton>
      </Tooltip>
      <Menu
        id="task-three-dotted-menu"
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        variant="menu"
        PaperProps={{ className: classes.paper }}
        disablePortal
        keepMounted
      >
        {visibleMenuItems.map((menuItem) => {
          return (
            <ListItem
              key={menuItem.id}
              className={classes.listItem}
              component={menuItem.to ? Link : "li"}
              to={menuItem.to}
              onClick={menuItem.onClick}
              button
            >
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              <ListItemText primary={menuItem.primaryText} secondary={menuItem.secondaryText} />
            </ListItem>
          )
        })}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  menuIcon: { color: theme.palette.text.primary },
  paper: { maxWidth: 320 },
  listItem: { "& .MuiListItemIcon-root": { color: theme.palette.text.primary } },
}))

export default TaskMenu
