import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { useTask } from "../../../tasks/hooks/use-task"
import CircularProgress from "@material-ui/core/CircularProgress"
import TaskBasicRoute from "./basic"
import TaskCalendarRoute from "./calendar"
import TaskKanbanRoute from "./kanban"
import TaskSubtasksRoute from "./subtasks"
import TaskTodosRoute from "./todos"
import { paths } from "../../../paths"
import TaskNavbar from "../../../tasks/components/Navbar/TaskNavbar"
import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"
import ErrorList from "../../../components/ErrorList"

const TaskLayout = ({ match, location }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const { taskId } = match.params
  const { task, isLoading, isError } = useTask(taskId)

  if (isLoading) return <CircularProgress />
  if (isError) return <ErrorList errors={[translations.fetchErrorTask]} />
  if (!task) return <ErrorList errors={[translations.taskNotFoundError]} />

  return (
    <>
      <TaskNavbar task={task} isFullWidth={!location.pathname.includes("basic")} />
      <Switch>
        <Route path={paths.taskBasic()} component={TaskBasicRoute} />
        <Route path={paths.taskCalendar()} component={TaskCalendarRoute} />
        <Route path={paths.taskKanban()} component={TaskKanbanRoute} />
        <Route path={paths.taskSubtasks()} component={TaskSubtasksRoute} />
        <Route path={paths.taskTodos()} component={TaskTodosRoute} />
      </Switch>
    </>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translations")
  const translations = t || ({} as StringMap)

  const { taskNotFoundError = defaults.taskNotFoundError, fetchErrorTask = defaults.fetchErrorTask } = translations

  return {
    taskNotFoundError,
    fetchErrorTask,
  }
}
const defaultTranslations = {
  taskNotFoundError: "Task not found",
  fetchErrorTask: "Failed to fetch task",
}
type Translations = typeof defaultTranslations
type RouteParams = { taskId: string }
export default TaskLayout
