import { addDays, startOfDay, subDays } from "date-fns"
import { LinkType } from "../../tasks/api/task-response"
import { TreeGridColors } from "../../treegrid/types"
import { treeGridColorsMap } from "../../constants"

export const adaptTask = (task: TaskLike): EventType => {
  if (!task.plannedEndDate && !task.plannedStartDate) {
    throw new Error("Either start date or end date is required")
  }
  let start_date = null
  let end_date = null

  if (!task.plannedStartDate) {
    if (!task.enableTimeComponent) {
      end_date = startOfDay(addDays(new Date(task.plannedEndDate), 1))
      start_date = subDays(end_date, 1)
    } else {
      end_date = new Date(task.plannedEndDate)
      start_date = end_date
    }
  } else if (!task.plannedEndDate) {
    if (!task.enableTimeComponent) {
      start_date = startOfDay(new Date(task.plannedStartDate))
      end_date = addDays(start_date, 1)
    } else {
      start_date = new Date(task.plannedStartDate)
      end_date = start_date
    }
  } else {
    start_date = new Date(task.plannedStartDate)
    end_date = new Date(task.plannedEndDate)

    if (end_date.getTime() - start_date.getTime() === 86399999) {
      end_date = startOfDay(addDays(end_date, 1))
    }
  }

  const text = `${task.shownTaskNumber}: ${task.title}`
  const participants = task.managers.map((manager) => manager.name)
  let entirePath = `${task.shownTaskNumber}: ${task.projectLink.label}`
  const ancestorPath = task.ancestorTaskLinks.map((ancestor) => ancestor.label).join(" / ")
  if (task.ancestorTaskLinks.length) entirePath = entirePath.concat(` / ${ancestorPath}`)
  entirePath = entirePath.concat(` / ${task.title}`)

  return {
    id: task.id,
    type: "task",
    start_date,
    end_date,
    text: text,
    details: entirePath,
    participants,
    color: treeGridColorsMap[task.ganttBarColor],
  }
}

export const adaptTodo = (todo: TodoLike): EventType => {
  if (!todo.dueDate) throw new Error("Due date is required ")
  const startDate = todo.enableTimeComponent ? new Date(todo.dueDate) : startOfDay(new Date(todo.dueDate))
  const endDate = todo.enableTimeComponent ? startDate : addDays(startDate, 1)
  const participants = todo.responsible.map((responsible) => responsible.name)
  const text = todo.title

  let entirePath = `${text}`
  if (todo.task || todo.project) {
    const shownNumber = todo.task?.taskNumber || todo.project?.projectNumber || ""
    entirePath = `${shownNumber}: ${todo.projectLink?.label}`
    const ancestorPath = todo.ancestorTaskLinks.map((ancestor) => ancestor.label).join(" / ")
    if (todo.ancestorTaskLinks.length) entirePath = entirePath.concat(` / ${ancestorPath}`)
    if (todo.task) entirePath = entirePath.concat(` / ${todo.taskLink?.label}`)
    entirePath = entirePath.concat(` / ${text}`)
  }

  return {
    id: todo.id,
    type: "todo",
    start_date: startDate,
    end_date: endDate,
    text,
    participants,
    details: entirePath,
    color: treeGridColorsMap[todo.ganttBarColor],
  }
}

export const adaptCalendarEvent = (event: CalendarEventLike): EventType => {
  if (!event.startDate || !event.endDate) {
    throw new Error("start date and end date is required")
  }
  const participants = event.participants.map((participant) => participant.name)
  const text = event.title

  let entirePath = `${text}`
  if (event.task || event.project) {
    const shownNumber = event.task?.taskNumber || event.project?.projectNumber || ""
    entirePath = `${shownNumber}: ${event.projectLink?.label}`
    const ancestorPath = event.ancestorTaskLinks.map((ancestor) => ancestor.label).join(" / ")
    if (event.ancestorTaskLinks.length) entirePath = entirePath.concat(` / ${ancestorPath}`)
    if (event.task) entirePath = entirePath.concat(` / ${event.taskLink?.label}`)
    entirePath = entirePath.concat(` / ${text}`)
  }

  return {
    id: event.id,
    text,
    start_date: new Date(event.startDate),
    end_date: new Date(event.endDate),
    type: "calendarEvent",
    details: entirePath,
    participants,
    color: treeGridColorsMap[event.color],
  }
}

export const adaptRecurringEvent = (event: RecurringEventLike): RecurringEventType => {
  const adaptedCalendarEvent = adaptCalendarEvent(event)
  const isRecurringOccurance = Boolean(event.parentEventId)
  const endDate = isRecurringOccurance ? event.endDate : event.recurranceEndDate
  const isDeleted = event.recurranceType === "none"
  const adaptedRecurringEvent: RecurringEventType = {
    ...adaptedCalendarEvent,
    rec_type: event.recurranceType || "",
    event_length: event.eventLength,
    event_pid: event.parentEventId || "",
    end_date: endDate ? new Date(endDate) : null,
    isDeleted,
  }

  return adaptedRecurringEvent
}

export type EventTypeType = "calendarEvent" | "task" | "todo"
export type CardTypeType = EventTypeType | "newEvent"
export type CalendarTaskData = {
  title: string
  description: string
  plannedStartDate: string
  plannedEndDate: string
}

export type CalendarTaskUpdates = {
  plannedStartDate: string
  plannedEndDate: string
}

export type CalendarTodoData = {
  title: string
  description: string
  dueDate: string | null
  enableTimeComponent?: boolean
}

export type CalendarEventData = {
  title: string
  description: string
  startDate: string
  endDate: string
  recurranceType?: string | null
  recurranceEndDate?: string | null
  parentEventId?: string | null
  eventLength?: number // should only be used to send the event length timestamp to the server for recurring instances
}

export type CalendarTodoUpdates = {
  dueDate: string
  enableTimeComponent: boolean
}

export type EventType = {
  type: EventTypeType
  id: string
  start_date: Date
  end_date: Date
  text: string
  details?: string
  participants: string[]
  color: string
}

export type RecurringEventType = {
  type: EventTypeType
  id: string
  start_date: Date
  end_date: Date | null
  text: string
  details?: string
  participants: string[]
  color: string
  rec_type: string
  event_length: number
  event_pid: string
  isDeleted: boolean
}

type TodoLike = {
  id: string
  title: string
  dueDate: string | null
  enableTimeComponent: boolean
  responsible: { id: string; name: string }[]
  project: { id: string; title: string; projectNumber: string } | null
  task: { title: string; id: string; taskNumber: string } | null
  projectLink: LinkType | null
  taskLink: LinkType | null
  ancestorTaskLinks: LinkType[]
  ganttBarColor: TreeGridColors
}

type TaskLike = {
  id: string
  shownTaskNumber: string
  taskNumber: string
  title: string
  plannedStartDate: string
  plannedEndDate: string
  enableTimeComponent: boolean
  projectLink: { label: string }
  ancestorTaskLinks: LinkType[]
  managers: { id: string; name: string }[]
  ganttBarColor: TreeGridColors
}

type CalendarEventLike = {
  id: string
  title: string
  startDate: string
  endDate: string
  project: { id: string; title: string; projectNumber: string } | null
  participants: { id: string; name: string }[]
  task: { title: string; id: string; taskNumber: string } | null
  projectLink: LinkType | null
  taskLink: LinkType | null
  ancestorTaskLinks: LinkType[]
  color: TreeGridColors
}

type RecurringEventLike = {
  id: string
  parentEventId: string | null
  title: string
  startDate: string
  endDate: string
  project: { id: string; title: string; projectNumber: string } | null
  task: { title: string; id: string; taskNumber: string } | null
  participants: { id: string; name: string }[]
  projectLink: LinkType | null
  taskLink: LinkType | null
  ancestorTaskLinks: LinkType[]
  color: TreeGridColors
  recurranceType: string | null
  recurranceEndDate: string | null
  eventLength: number
}

export type CalendarEventUpdates = {
  startDate: string
  endDate: string
}
