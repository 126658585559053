import { EventsQueryParams } from "../../calendar-events/api"
import {
  ICustomerNormalized,
  IOrgCustomerNormalized,
  IPersonCustomerNormalized,
} from "../../customers/interfaces/customer-normalized"
import { ICustomerResponse } from "../../customers/interfaces/customer-response"
import { ProjectsQueryParams } from "../../projects/api"
import { ISupplierNormalized } from "../../suppliers/interfaces/supplier-normalized"
import { ISupplierResponse } from "../../suppliers/interfaces/supplier-response"
import { TasksQueryParams } from "../../tasks/api"
import { TodoQueryParams } from "../../todos/api"
import { AppMainContextType, AppSubContextType } from "../../types/app-context"
import { CustomerStatusEnum, CustomerTypeEnum, IOrgCustomer, IPersonCustomer } from "../../types/customer"
import { MembershipStatusEnum } from "../../types/memberships"
import { SupplierStatusEnum } from "../../types/supplier"
import { WorkspaceStatus } from "../../workspaces/constants"
import { IWorkspaceNormalized } from "../../workspaces/interfaces/workspace-normalized"
import { IWorkspaceResponse } from "../../workspaces/interfaces/workspace-response"
import { IContextOptionResponse } from "../interfaces/context-options-response"

export const makeContextOptionFromMembership = (membership: MembershipLike): IContextOptionResponse => {
  const { orgId, userId, id, status, firstname, lastname } = membership
  const fullname = `${firstname} ${lastname}`
  return {
    id,
    userId,
    orgId,
    name: fullname,
    type: "membership",
    homePage: `/memberships`,
    disabled: status === MembershipStatusEnum.IN_ACTIVE,
  }
}

export const makeContextOptionFromWorkspace = (
  workspace: IWorkspaceNormalized | IWorkspaceResponse
): IContextOptionResponse => {
  const { orgId, name, id, status } = workspace
  return {
    id,
    orgId,
    name,
    type: "workspace",
    homePage: `/workspaces`,
    disabled: status === WorkspaceStatus.INACTIVE,
  }
}

export const makeContextOptionFromSupplier = (
  supplier: ISupplierNormalized | ISupplierResponse
): IContextOptionResponse => {
  const { orgId, name, id, status } = supplier
  return {
    id,
    orgId,
    name,
    type: "supplier",
    homePage: `/suppliers`,
    disabled: status === SupplierStatusEnum.inactive,
  }
}

export const makeContextOptionFromCustomer = (
  customer: ICustomerNormalized | ICustomerResponse
): IContextOptionResponse => {
  const { id, orgId, status, type } = customer
  const disabled = status === CustomerStatusEnum.inActive
  const homePage = `/customers`
  if (type === CustomerTypeEnum.person) {
    const personCustomer = customer as IPersonCustomer | IPersonCustomerNormalized
    const name = `${personCustomer.firstname} ${personCustomer.lastname}`
    const customerContextOption: IContextOptionResponse = {
      id,
      name,
      type: "customer",
      orgId,
      homePage,
      disabled,
    }
    return customerContextOption
  }

  const orgCustomer = customer as IOrgCustomer | IOrgCustomerNormalized
  return {
    id,
    name: orgCustomer.name,
    type: "customer",
    orgId,
    homePage,
    disabled,
  }
}

export const getTodoQueryFromContext = (context: AppMainContextType | AppSubContextType | null): TodoQueryParams => {
  if (!context) return {}
  if (context.type === "customer") return { customer: context.id }
  if (context.type === "member") return { responsible: context.id }
  if (context.type === "workspace") return { workspace: context.id }
  if (context.type === "org") return { organisation: context.id }
  return {}
}

export const getTaskQueryFromContext = (context: AppMainContextType | AppSubContextType | null): TasksQueryParams => {
  if (!context) return {}
  if (context.type === "customer") return { customer: context.id }
  if (context.type === "member") return { member: context.id }
  if (context.type === "workspace") return { workspace: context.id }
  if (context.type === "org") return { organisation: context.id }
  if (context.type === "supplier") return { supplier: context.id }
  return {}
}

export const getCalendarEventQueryFromContext = (
  context: AppMainContextType | AppSubContextType | null
): EventsQueryParams | void => {
  if (!context) return
  if (context.type === "customer") return { customer: context.id }
  if (context.type === "member") return { participant: context.id }
  if (context.type === "workspace") return { workspace: context.id }
  if (context.type === "org") return { organisation: context.id }
  if (context.type === "supplier") return { supplier: context.id }
}

export const getBoardQueryFromContext = (context: AppMainContextType | AppSubContextType | null) => {
  if (!context) throw new Error("Context not found")
  if (
    context.type === "customer" ||
    context.type === "member" ||
    context.type === "workspace" ||
    context.type === "supplier" ||
    context.type === "user"
  ) {
    return { id: context.id, type: context.type } as const
  }
  throw new Error("Invalid context type")
}

export const getProjectQueryFromContext = (
  context: AppMainContextType | AppSubContextType | null
): ProjectsQueryParams => {
  if (!context) return {}
  if (context.type === "customer") return { customer: context.id }
  if (context.type === "member") return { member: context.id }
  if (context.type === "supplier") return { supplier: context.id }
  if (context.type === "workspace") return { workspace: context.id }
  if (context.type === "org") return { organisation: context.id }
  return {}
}

export const getHolidaysQueryFromContext = (appMainContext: AppMainContextType | null) => {
  return appMainContext?.type === "org" ? { orgId: appMainContext.id } : { userId: appMainContext?.id as string }
}

type MembershipLike = {
  id: string
  userId: string
  orgId: string
  fullname?: string
  firstname: string
  lastname: string
  status: MembershipStatusEnum
}
