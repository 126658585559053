import Container from "@material-ui/core/Container"
import MembershipForm from "../../../../memberships/components/MembershipForm"
import { Theme, makeStyles } from "@material-ui/core"
import { useAppContext } from "../../../../hooks/use-app-context"

const NewV2 = () => {
  const classes = useStyles()
  const { appContext } = useAppContext()
  const orgId = appContext.mainContext?.id as string

  return (
    <Container className={classes.container} data-test="new-member-card">
      <MembershipForm orgId={orgId} />
    </Container>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 650,
    margin: "auto",
    marginTop: 50,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    background: theme.palette.common.white,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
}))

export default NewV2
