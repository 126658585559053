import store, { AppStore } from "./store"
import { Storage } from "./utils/local-storage"
import { REFRESH_TOKEN } from "./constants"
import { SetRefreshToken } from "./users/store"
import { scheduleNextRefresh } from "./users/store/access-token"

const refreshTokenStore = new Storage(REFRESH_TOKEN)

const syncRefreshTokenInStateWithLocalStorage = ({ dispatch, getState }: AppStore): void => {
  const refreshTokenInState = getState().auth.refreshToken
  const refreshTokenInStorage = refreshTokenStore.getValue()
  const needsSync = refreshTokenInState !== refreshTokenInStorage
  if (needsSync) {
    const payload = { refreshToken: refreshTokenInStorage, isAuthenticated: refreshTokenInStorage != null }
    dispatch(SetRefreshToken(payload))
    if (!refreshTokenInStorage) window.location.reload()
  }
}

const handleTokenStorage = (e: StorageEvent) => syncRefreshTokenInStateWithLocalStorage(store)
const handleUserOnline = () => scheduleNextRefresh(store.dispatch)

export function registerGlobalEvents() {
  /** for syncing refresh token across tabs */
  window.addEventListener("storage", handleTokenStorage)
  window.addEventListener("online", handleUserOnline)
}

export function unregisterGlobalEvents() {
  /** for syncing refresh token across tabs */
  window.removeEventListener("storage", handleTokenStorage)
}
