import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import Tooltip from "@material-ui/core/Tooltip"
import { useTranslations } from "./use-translations"
import { makeStyles, Theme } from "@material-ui/core"
import { Link, useLocation } from "react-router-dom"
import { useUrlWithContext } from "../../../hooks/use-url-with-context"
import { paths } from "../../../paths"
import { TaskViewModel } from "../../api/task"
import { getActiveTabIndex } from "./utils"
import { useIsSmallScreen } from "../../../hooks/use-is-small-screen"

const TaskNavTabs = ({ task }: { task: TaskViewModel }) => {
  const translations = useTranslations()
  const classes = useStyles()
  const { createPathWithContext } = useUrlWithContext()
  const location = useLocation()
  const isSmallScreen = useIsSmallScreen()

  if (isSmallScreen) return null

  const tabValue = getActiveTabIndex(location.pathname)
  const basicRoute = createPathWithContext(paths.taskBasic(task.id))
  const subtasksRoute = createPathWithContext(paths.taskSubtasks(task.id))
  const kanbanRoute = createPathWithContext(paths.taskKanban(task.id))
  const calendarRoute = createPathWithContext(paths.taskCalendar(task.id))
  const todosRoute = `${createPathWithContext(paths.taskTodos(task.id))}&view=all`

  return (
    <Tabs className={classes.tabs} value={tabValue} indicatorColor="primary" textColor="primary">
      <Tooltip title={translations.basicInfoTabLabel}>
        <Tab component={Link} to={basicRoute} className={classes.tab} label={translations.basicInfoTabLabel} />
      </Tooltip>
      <Tooltip title={translations.tasksTabLabel}>
        <Tab component={Link} to={subtasksRoute} className={classes.tab} label={translations.tasksTabLabel} />
      </Tooltip>
      <Tooltip title={translations.kanbanTabLabel}>
        <Tab component={Link} to={kanbanRoute} className={classes.tab} label={translations.kanbanTabLabel} />
      </Tooltip>
      <Tooltip title={translations.calendarTabLabel}>
        <Tab component={Link} to={calendarRoute} className={classes.tab} label={translations.calendarTabLabel} />
      </Tooltip>
      <Tooltip title={translations.todoListTabLabel}>
        <Tab component={Link} to={todosRoute} className={classes.tab} label={translations.todoListTabLabel} />
      </Tooltip>
    </Tabs>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  tab: {
    maxWidth: 160,
    minWidth: 96,
    minHeight: 32,
    fontSize: 13,
    padding: `2px 12px`,
    "& .MuiTab-wrapper": {
      display: "block",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
    },
  },
  tabs: {
    marginTop: theme.spacing(2),
    "& .MuiTabs-indicator": {
      top: 36,
    },
  },
}))

export default TaskNavTabs
