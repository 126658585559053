import { useSnackbar } from "notistack"
import { useI18n } from "../../hooks"
import * as reminderApi from "../api"

export const useLinkMutations = () => {
  const translations = useTranslations()
  const { enqueueSnackbar } = useSnackbar()

  const handleError = (error: any) => {
    const errorMessage = "error" in error ? error.error : "message" in error ? error.message : "Unknown error"
    enqueueSnackbar(errorMessage, { variant: "error" })
  }

  const [createLinkMutation] = reminderApi.useCreateLinkMutation()
  const [updateLinkMutation] = reminderApi.useUpdateLinkMutation()
  const [deleteLinkMutation] = reminderApi.useDeleteLinkMutation()

  const createLink = (newLinkData: reminderApi.NewLinkData) => {
    return createLinkMutation(newLinkData)
      .unwrap()
      .then(() => true)
      .catch(() => handleError({ message: translations.linkCreationFailed }))
  }

  const updateLink = (linkId: string, linkUpdateData: reminderApi.LinkUpdateData) => {
    return updateLinkMutation({ linkId, ...linkUpdateData })
      .unwrap()
      .catch(handleError)
  }

  const deleteLink = (linkId: string) => {
    return deleteLinkMutation({ linkId })
      .unwrap()
      .then(() => {
        enqueueSnackbar(translations.linkDeletionSuccessful, { variant: "success" })
      })
      .catch(() => handleError({ message: translations.linkDeletionFailed }))
  }

  return { createLink, updateLink, deleteLink }
}

const defaultTranslations = {
  linkCreationFailed: "Failed to add a new link",
  linkDeletionSuccessful: "Link deleted successfully",
  linkDeletionFailed: "Failed to delete link",
}

const useTranslations = (defaults = defaultTranslations) => {
  const { t } = useI18n("translation")

  return {
    linkCreationFailed: t("linkCreationFailed", defaults.linkCreationFailed),
    linkDeletionSuccessful: t("linkDeletionSuccessful", defaults.linkDeletionSuccessful),
    linkDeletionFailed: t("linkDeletionFailed", defaults.linkDeletionFailed),
  }
}
