import { TimeZoneType } from "../../constants/timezones"
import { NotificationSettingsData } from "../../notifications/notifications"
import { DateFormatOptionType, DateFormatSeparatorOptionType } from "../constants"
import { IOrgResponse, SuspensionStatus } from "./org-response"

export type IOrgNormalized = {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  email: string | null
  ownerId: string
  ownerEmail: string
  ownerFullname: string
  weekendDays: DayOfWeek[]
  firstDayOfWeek: DayOfWeek
  timeZone: TimeZoneType
  dateFormatSeparator: DateFormatSeparatorOptionType
  dateFormat: DateFormatOptionType
  suspensionStatus: SuspensionStatus
  canReadDetails: boolean
  canReadPermissions: boolean
  canUpdateDetails: boolean
  canUpdatePermissions: boolean
  canDelete: boolean
  notificationSettings: NotificationSettingsData
}

export const normalizeOrg = (org: IOrgResponse): IOrgNormalized => {
  return { ...org }
}
