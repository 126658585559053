import ResourceManager from "../../components/ResourceManager"
import { StringMap } from "../../types/common"
import { useI18n } from "../../hooks"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import { TaskViewModel } from "../api/task"
import { useTaskMutations } from "../hooks/use-task-mutations"

const TaskParticipantsWidget = ({ task, boardId }: { task: TaskViewModel; boardId?: string }) => {
  const translations = useTranslations(defaultTranslations)
  const { membershipOptions } = useOrgOptions(task.maintainerId)
  const { addParticipants, removeParticipants } = useTaskMutations()
  const isNotManager = ({ id }: { id: string }) => task.managers.findIndex((m) => m.id === id) < 0

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={membershipOptions.filter(isNotManager)}
      resources={task.participants}
      onAddResources={(ids) => addParticipants(task.id, ids)}
      onRemoveResource={(id) => removeParticipants(task.id, { participantIds: [id], boardId })}
      canAddResource={task.canUpdateParticipants}
      canRemoveResource={task.canUpdateParticipants}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("task")
  const translations = (t?.participantsWidget || {}) as StringMap
  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: "Participants",
  emptyOptionsMessage: "No members in this organisation",
  emptyResourcesMessage: "No participant in this task",
}

type Translations = typeof defaultTranslations

export default TaskParticipantsWidget
