import { CheckBillingSettingsResponse, OrganisationResponse } from "./organisation-response"

export const makeCheckBillingSettingsViewModel = (
  checkBillingSettings: CheckBillingSettingsResponse
): CheckBillingSettingsViewModel => {
  const { billingSettingsIsFilled, canInviteUserWithoutBillingInfo } = checkBillingSettings
  const shouldFillBillingSettings = !billingSettingsIsFilled && !canInviteUserWithoutBillingInfo
  return {
    ...checkBillingSettings,
    shouldFillBillingSettings,
  }
}
export type CheckBillingSettingsViewModel = CheckBillingSettingsResponse & {
  shouldFillBillingSettings: boolean
}

export type OrganisationViewModel = OrganisationResponse
