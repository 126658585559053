import Grid from "@material-ui/core/Grid"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import TextField from "@material-ui/core/TextField"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import Divider from "@material-ui/core/Divider"
import RoleManager from "./RoleManager"
import TimeZoneSelectWidget from "../../components/TimeZoneSelectWidget"
import WeekendDaysSelectWidget from "../../components/WeekendDaysSelectWidget"
import FirstDayOfWeekSelectWidget from "../../components/FirstDayOfWeekSelectWidget"
import { useApplyRoleAbilitiesToAll } from "../hooks/use-apply-role-abilities-to-all"
import { useOrgRoles } from "../hooks/use-org-roles"
import { useI18n } from "../../hooks"
import { useAuthUserMembership } from "../../memberships/hooks/use-auth-user-membership"
import { Paper, Theme, makeStyles, alpha } from "@material-ui/core"
import { useEffect, useState } from "react"
import { COLOR_DIVIDER, COLOR_GRAY, COLOR_LIGHT } from "../../constants"
import MultiSearchSelect, { MultiSearchSelectOption } from "../../components/MultiSearchSelect"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import { useDispatch } from "react-redux"
import { useConfirmDialog } from "../../components/ConfirmDialog"
import { TransferOwnership } from "../../memberships/store"
import { ReactComponent as TransferOwnershipIcon } from "../../assets/icons/transfer-ownership.svg"
import { useConfirmDeleteOrganisation } from "../hooks/use-confirm-delete-organisation"
import { AlertTriangle, X } from "react-feather"
import { useOrganisation } from "../hooks/use-organisation"
import { useAppContext } from "../../hooks/use-app-context"
import Stack from "../../components/Stack"

const OrgGeneralSettings = () => {
  const mainContext = useAppContext().appContext.mainContext
  const orgId = mainContext?.id as string
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const dispatch = useDispatch()
  const { applyRoleAbilitiesToAll } = useApplyRoleAbilitiesToAll()
  const { organisation, updateOrgInfo } = useOrganisation(orgId)
  const {
    memberRole,
    limitedMemberRole,
    projectManagerRole,
    projectParticipantRole,
    taskManagerRole,
    taskParticipantRole,
    updateRoleAbilities,
  } = useOrgRoles(orgId)
  const { membership, canUpdateAnyRole, canUpdateAnyMembershipPermissions } = useAuthUserMembership()
  const [openTransferOwnershipDialog, setOpenTransferOwnershipDialog] = useState(false)
  const { membershipOptions } = useOrgOptions(orgId)
  const options = membershipOptions.filter((option) => option.id !== membership?.id && option.type !== "noAccessMember")

  const [newOwner, setNewOwner] = useState<MultiSearchSelectOption | null>(null)
  const [confirmTransfer, setConfirmTransfer] = useState(false)
  const { confirm } = useConfirmDialog({
    title: translations.confirmationDialogTitle,
    text: translations.confirmationDialogText,
    onConfirm: () => setConfirmTransfer(true),
    onCancel: () => setConfirmTransfer(false),
    primaryActionButtonLabel: translations.confirmationDialogConfirmLabel,
    secondaryActionButtonLabel: translations.confirmationDialogCancelLabel,
  })

  const [openTransferOwnershipSuccessDialog, setOpenTransferOwnershipSuccessDialog] = useState(false)
  const [transferOwnershipErrorMessage, setTransferOwnershipErrorMessage] = useState("")

  const {
    dialogOpen,
    openDialog,
    closeDialog,
    confirmAndCloseDialog,
    confirmationText,
    setConfirmationText,
    isDeleting,
    disableDeletion,
  } = useConfirmDeleteOrganisation({
    orgId,
    deleteErrorMessage: translations.deleteErrorMessage,
    deleteSuccessMessage: translations.deleteSuccessMessage,
  })

  const handleConfirmationTextKeyUp = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !disableDeletion) {
      confirmAndCloseDialog()
    }
  }

  useEffect(() => {
    const transferOwnership = async () => {
      if (confirmTransfer && newOwner) {
        try {
          const response: any = await dispatch(TransferOwnership(newOwner.id))
          if (response.error) {
            setOpenTransferOwnershipSuccessDialog(false)
            setTransferOwnershipErrorMessage(response.error?.message)
            setNewOwner(null)
            setConfirmTransfer(false)
          } else {
            setOpenTransferOwnershipSuccessDialog(true)
            setTransferOwnershipErrorMessage("")
            setNewOwner(null)
            setConfirmTransfer(false)
          }
        } catch (error: any) {
          setOpenTransferOwnershipSuccessDialog(false)
          setTransferOwnershipErrorMessage(translations.transferOwnershipErrorDialogText)
          setNewOwner(null)
          setConfirmTransfer(false)
        }
      }
    }

    transferOwnership()
  }, [confirmTransfer, dispatch, newOwner, translations.transferOwnershipErrorDialogText])

  if (organisation) {
    return (
      <div style={{ margin: "auto", padding: "24px 0", maxWidth: 1440 }}>
        <Typography variant="h6" style={{ marginBottom: 16 }} data-test="page-header">
          {translations.generalSettingsTitle}
        </Typography>
        <Stack direction="row" spacing={1} alignItems="stretch" className={classes.stack}>
          <WeekendDaysSelectWidget
            weekendDays={organisation.weekendDays || [6, 0]}
            onWeekendDaysChange={updateOrgInfo}
            disabled={!organisation.canUpdateDetails}
          />
          <FirstDayOfWeekSelectWidget
            firstDayOfWeek={organisation.firstDayOfWeek}
            onFirstDayOfWeekChange={updateOrgInfo}
            disabled={!organisation.canUpdateDetails}
          />
          <TimeZoneSelectWidget
            timeZone={organisation.timeZone}
            onTimeZoneChange={updateOrgInfo}
            disabled={!organisation.canUpdateDetails}
          />
        </Stack>
        <section>
          {membership?.isOwner && (
            <>
              <Typography variant="h6" style={{ margin: "20px 0" }}>
                {translations.orgOwnershipTitle}
              </Typography>
              <Paper elevation={0} style={{ padding: "20px 20px", border: `1px solid ${COLOR_DIVIDER}` }}>
                <Alert severity="info">{translations.transferOwnershipDescription}</Alert>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ margin: "20px 0px" }}
                  onClick={() => setOpenTransferOwnershipDialog(true)}
                >
                  {translations.transferOwnershipButtonLabel}
                </Button>
                <Dialog
                  maxWidth="sm"
                  open={openTransferOwnershipDialog}
                  onClose={() => setOpenTransferOwnershipDialog(false)}
                  aria-labelledby="Add members to workspace"
                  className={classes.dialog}
                >
                  <Grid direction="column" spacing={3} className={classes.root} container>
                    <Grid item>
                      <div className={classes.image}>
                        {/* this is a svg image but it is shown using img tag. To change the color of the svg, change the color of the img tag.*/}
                        <TransferOwnershipIcon fill={COLOR_GRAY} width="64" height="64" />
                      </div>
                    </Grid>
                    <Grid className={classes.primaryText} item>
                      <Typography>{translations.transferOwnershipDialogDescription}</Typography>
                    </Grid>
                    <Grid className={classes.primaryText} item>
                      <Typography>
                        <strong>{translations.transferOwnershipDialogText}</strong>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <MultiSearchSelect
                        options={options}
                        selectedOptions={[]}
                        onChange={(selectedOptions) => {
                          setNewOwner(selectedOptions[0])
                          setOpenTransferOwnershipDialog(false)
                          confirm()
                        }}
                        disabled={false}
                      />
                    </Grid>
                  </Grid>
                </Dialog>
              </Paper>
            </>
          )}
          {/* Success Dialog */}
          <Dialog
            open={openTransferOwnershipSuccessDialog}
            onClose={() => setOpenTransferOwnershipSuccessDialog(false)}
            maxWidth="sm"
          >
            <DialogTitle>{translations.transferOwnershipSuccessDialogTitle}</DialogTitle>
            <DialogContent>
              <Alert severity="success">
                <strong>{translations.transferOwnershipSuccessDialogText}</strong>
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenTransferOwnershipSuccessDialog(false)} color="primary" variant="contained">
                {translations.okButtonLabel}
              </Button>
            </DialogActions>
          </Dialog>

          {/* Error Dialog */}
          <Dialog
            open={Boolean(transferOwnershipErrorMessage)}
            onClose={() => setTransferOwnershipErrorMessage("")}
            maxWidth="sm"
          >
            <DialogTitle>{translations.transferOwnershipErrorDialogTitle}</DialogTitle>
            <DialogContent>
              <Alert severity="error">
                <strong>{transferOwnershipErrorMessage}</strong>
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setTransferOwnershipErrorMessage("")} color="primary" variant="contained">
                {translations.okButtonLabel}
              </Button>
            </DialogActions>
          </Dialog>
        </section>
        {canUpdateAnyRole && (
          <>
            <Typography variant="h6" style={{ margin: "20px 0" }}>
              {translations.permissionsSettingsTitle}
            </Typography>
            <section>
              {memberRole && (
                <RoleManager
                  role={memberRole}
                  updateRoleAbilities={updateRoleAbilities}
                  applyRoleToAll={applyRoleAbilitiesToAll}
                  showApplyRoleAbbilitiesToAllButton={canUpdateAnyMembershipPermissions}
                />
              )}
              {limitedMemberRole && (
                <RoleManager
                  role={limitedMemberRole}
                  updateRoleAbilities={updateRoleAbilities}
                  applyRoleToAll={applyRoleAbilitiesToAll}
                  showApplyRoleAbbilitiesToAllButton={canUpdateAnyMembershipPermissions}
                />
              )}
              {projectManagerRole && (
                <RoleManager
                  role={projectManagerRole}
                  updateRoleAbilities={updateRoleAbilities}
                  applyRoleToAll={applyRoleAbilitiesToAll}
                  showApplyRoleAbbilitiesToAllButton={canUpdateAnyMembershipPermissions}
                />
              )}
              {projectParticipantRole && (
                <RoleManager
                  role={projectParticipantRole}
                  updateRoleAbilities={updateRoleAbilities}
                  applyRoleToAll={applyRoleAbilitiesToAll}
                  showApplyRoleAbbilitiesToAllButton={canUpdateAnyMembershipPermissions}
                />
              )}
              {taskManagerRole && (
                <RoleManager
                  role={taskManagerRole}
                  updateRoleAbilities={updateRoleAbilities}
                  applyRoleToAll={applyRoleAbilitiesToAll}
                  showApplyRoleAbbilitiesToAllButton={canUpdateAnyMembershipPermissions}
                />
              )}
              {taskParticipantRole && (
                <RoleManager
                  role={taskParticipantRole}
                  updateRoleAbilities={updateRoleAbilities}
                  applyRoleToAll={applyRoleAbilitiesToAll}
                  showApplyRoleAbbilitiesToAllButton={canUpdateAnyMembershipPermissions}
                />
              )}
            </section>
          </>
        )}
        {membership?.isOwner && (
          <section className={classes.deleteOrganisationSection}>
            <Stack direction="row" spacing={2}>
              <div>
                <Typography variant="h6">{translations.deleteOrgSectionTitle}</Typography>
                <Typography variant="body2" style={{ marginTop: 4 }} color="textSecondary">
                  {translations.deleteOrgSectionDescription}
                </Typography>
              </div>
              <div>
                <Button variant="outlined" onClick={openDialog} className={classes.deleteOrganisationButton}>
                  {translations.deleteOrgButtonLabel}
                </Button>
              </div>
            </Stack>
            <Dialog open={dialogOpen} onClose={closeDialog} aria-labelledby="confirm-organisation-deletion-dialog">
              <DialogTitle id="confirm-organisation-deletion-dialog">
                {translations.deleteOrgConfirmationDialogTitle}
                <IconButton
                  aria-label="close"
                  className={classes.deleteOrganisationDialogCloseButton}
                  onClick={closeDialog}
                >
                  <X size={20} />
                </IconButton>
              </DialogTitle>
              <DialogContent className={classes.deleteOrganisationDialogContent}>
                <Alert
                  className={classes.deleteOrganisationDialogAlert}
                  severity="warning"
                  icon={<AlertTriangle size={20} />}
                >
                  {translations.deleteOrgConfirmationDialogAlertText}
                </Alert>
                <Typography>{translations.deleteOrgConfirmationDialogText}</Typography>
              </DialogContent>
              <Divider />
              <footer className={classes.deleteOrganisationDialogFooter}>
                <Typography variant="subtitle1">
                  <strong>{translations.deleteOrgConfirmationDialogFooterText}</strong>
                </Typography>
                <TextField
                  margin="dense"
                  variant="outlined"
                  value={confirmationText}
                  onChange={(e) => setConfirmationText(e.target.value)}
                  onKeyUp={handleConfirmationTextKeyUp}
                  fullWidth
                />
                <Button
                  variant="contained"
                  size="small"
                  onClick={confirmAndCloseDialog}
                  disabled={disableDeletion}
                  startIcon={isDeleting ? <CircularProgress size={22.75} /> : null}
                  fullWidth
                >
                  {isDeleting ? "" : translations.deleteOrgButtonLabel}
                </Button>
              </footer>
            </Dialog>
          </section>
        )}
      </div>
    )
  }
  return <p>error</p>
}

const defaultTranslations = {
  generalSettingsTitle: "General settings",
  permissionsSettingsTitle: "Default Permissions",
  orgOwnershipTitle: "Organisation ownership",
  transferOwnershipDescription: `We recommend using a shared account for an organization owner. Please click the button below to transfer the ownership to another member of this organisation.`,
  transferOwnershipButtonLabel: "Transfer ownership",
  transferOwnershipDialogDescription:
    "After transfering the ownership, you will be a standard member of this organisation. However, this action will not have any impact on your current permissions.",
  transferOwnershipDialogText: "If you want to continue, please select a new owner below:",
  confirmationDialogTitle: "Confirm transfer ownership",
  confirmationDialogText: "Are you sure you want to transfer ownership?",
  confirmationDialogCancelLabel: "Cancel",
  confirmationDialogConfirmLabel: "Confirm",
  transferOwnershipSuccessDialogTitle: "Success",
  transferOwnershipSuccessDialogText: "Ownership transferred successfully!",
  transferOwnershipErrorDialogTitle: "Error",
  transferOwnershipErrorDialogText: "Something went wrong",
  okButtonLabel: "OK",
  /** Delete organisation section */
  deleteOrgSectionTitle: "Delete this organisation",
  deleteOrgSectionDescription:
    "This will delete this organisation and everything related it. Once deleted it can not be restored.",
  deleteOrgButtonLabel: "Delete organisation",
  deleteOrgConfirmationDialogTitle: "Are you sure?",
  deleteOrgConfirmationDialogAlertText: "Before proceeding, please  read the following text carefully.",
  deleteOrgConfirmationDialogText:
    "This action will result in the permanent deletion of the organisation, along with its projects, tasks, to-dos, calendar events, and all other associated data.",
  deleteOrgConfirmationDialogFooterText:
    "To confirm deletion of this organisation, please enter the name of your organisation in the box below.",
  deleteSuccessMessage: "Organisation has been deleted successfully.",
  deleteErrorMessage: "An error occurred while deleting the organisation!",
}

const useTranslations = (defaults = defaultTranslations): typeof defaultTranslations => {
  const { translations: t } = useI18n("org")
  const translations = t.orgSettingsPage || {}

  const translate = (key: keyof typeof defaultTranslations) => {
    return translations[key] || defaults[key]
  }

  return {
    generalSettingsTitle: translate("generalSettingsTitle"),
    permissionsSettingsTitle: translate("permissionsSettingsTitle"),
    orgOwnershipTitle: translate("orgOwnershipTitle"),
    transferOwnershipDescription: translate("transferOwnershipDescription"),
    transferOwnershipButtonLabel: translate("transferOwnershipButtonLabel"),
    transferOwnershipDialogDescription: translate("transferOwnershipDialogDescription"),
    transferOwnershipDialogText: translate("transferOwnershipDialogText"),
    confirmationDialogTitle: translate("confirmationDialogTitle"),
    confirmationDialogText: translate("confirmationDialogText"),
    confirmationDialogCancelLabel: translate("confirmationDialogCancelLabel"),
    confirmationDialogConfirmLabel: translate("confirmationDialogConfirmLabel"),
    transferOwnershipSuccessDialogTitle: translate("transferOwnershipSuccessDialogTitle"),
    transferOwnershipSuccessDialogText: translate("transferOwnershipSuccessDialogText"),
    transferOwnershipErrorDialogTitle: translate("transferOwnershipErrorDialogTitle"),
    transferOwnershipErrorDialogText: translate("transferOwnershipErrorDialogText"),
    okButtonLabel: translate("okButtonLabel"),
    deleteOrgSectionTitle: translate("deleteOrgSectionTitle"),
    deleteOrgSectionDescription: translate("deleteOrgSectionDescription"),
    deleteOrgButtonLabel: translate("deleteOrgButtonLabel"),
    deleteOrgConfirmationDialogTitle: translate("deleteOrgConfirmationDialogTitle"),
    deleteOrgConfirmationDialogAlertText: translate("deleteOrgConfirmationDialogAlertText"),
    deleteOrgConfirmationDialogText: translate("deleteOrgConfirmationDialogText"),
    deleteOrgConfirmationDialogFooterText: translate("deleteOrgConfirmationDialogFooterText"),
    deleteSuccessMessage: translate("deleteSuccessMessage"),
    deleteErrorMessage: translate("deleteErrorMessage"),
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    minHeight: theme.spacing(30),
    overflowY: "hidden",
  },
  primaryText: {
    textAlign: "center",
  },
  dialog: {
    "& .MuiDialog-paper": {
      width: "100%",
      padding: theme.spacing(6),
      backgroundColor: COLOR_LIGHT,
    },
  },
  image: {
    padding: theme.spacing(5),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      width: "15%",
      height: "15%",
      filter: "hue-rotate(220deg)",
    },
  },
  popper: {
    width: "100%",
    maxHeight: theme.spacing(30),
    overflow: "auto",
    zIndex: theme.zIndex.modal,
  },
  deleteOrganisationSection: {
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2),
    border: `1px solid ${alpha(theme.palette.error.main, 0.25)}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.error.main, 0.05),
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  deleteOrganisationButton: {
    borderColor: theme.palette.error.main,
    color: theme.palette.error.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.error.main, 0.1),
    },
  },
  deleteOrganisationDialogContent: {
    marginBottom: theme.spacing(2),
  },
  deleteOrganisationDialogFooter: {
    padding: theme.spacing(2),
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(),
    },
    "& fieldset": {
      borderColor: theme.palette.common.black,
    },
    "& button": {
      color: theme.palette.common.white,
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.main,
      },
    },
  },
  deleteOrganisationDialogAlert: {
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2),
    background: alpha(theme.palette.warning.main, 0.4),
    "& .MuiAlert-icon": {
      fontSize: 16,
      color: theme.palette.common.black,
    },
  },
  deleteOrganisationDialogCloseButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  stack: {
    "& .MuiPaper-root": {
      border: `1px solid ${COLOR_DIVIDER}`,
    },
  },
}))

export default OrgGeneralSettings
