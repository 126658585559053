import { IContact } from "./contact"

export enum SupplierStatusEnum {
  active = "active",
  inactive = "inactive",
}

export interface ISupplier {
  id: string
  createdAt: string
  updatedAt: string
  name: string
  supplierNumber: string
  language: string
  orgId: string
  status: SupplierStatusEnum
  contacts: IContact[]
  businessId: string
  vatRegistrationNumber: string
  defaultContact: IContact
}

export interface ICreateSupplierPayload {
  name: string
  supplierNumber?: string
  orgId: string
}

export interface ISupplierContactPaylod {
  firstname: string
  lastname: string
  phone?: string
  email?: string
  jobTitle?: string
}

export interface SupplierNumberMetadata {
  customerNumber: number
  error: string
  unique: boolean
  valid: boolean
}
