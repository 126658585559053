import AutoSaveInput from "../../components/AutoSaveInput"
import AutoSaveSelect from "../../components/AutoSaveSelect"

import { Grid, makeStyles, Paper, Theme } from "@material-ui/core"
import { useDispatch } from "react-redux"
import { CustomerTypeEnum } from "../../types/customer"
import { useI18n } from "../../hooks"
import { languageList } from "../../utils/languages"
import { UpdateCustomerInfo } from "../store/actions"
import { IPersonCustomerNormalized } from "../interfaces/customer-normalized"
import { makeValidateCustomerNumber } from "../utils/validate-customer-number"
import { useAppContext } from "../../hooks/use-app-context"
import { StringMap } from "i18next"

const PersonBasicInfoForm = ({ customer, canUpdateBasicInfo }: PersonBasicInfoFormProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const translations = useTranslations(defaultTranslations)
  const { appContext } = useAppContext()
  const orgId = appContext.mainContext?.id as string
  const validateCustomerNumber = makeValidateCustomerNumber({ orgId, translations })

  const makeOnSave =
    (field: keyof IPersonCustomerNormalized) => async (values: { [k in keyof IPersonCustomerNormalized]: string }) => {
      const action: any = await dispatch(
        UpdateCustomerInfo({
          customerId: customer.id,
          [field]: values[field],
          type: CustomerTypeEnum.person,
        })
      )
      // Need to reject explicitly because even api responds with other codes than 200,
      // the request is resolved not rejected
      if (action?.meta?.requestStatus === "rejected") {
        return Promise.reject(action.error)
      }
    }

  const disabled = !customer.isActive || !canUpdateBasicInfo

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container spacing={2}>
        <Grid item className={classes.item}>
          <AutoSaveInput
            name="firstname"
            label={translations.firstnameLabel}
            onSave={makeOnSave("firstname")}
            initialValue={customer.firstname}
            disabled={disabled}
            required
            inputProps={{ "data-test": "customer-firstname-input" }}
          />
        </Grid>
        <Grid item className={classes.item}>
          <AutoSaveInput
            name="lastname"
            label={translations.lastnameLabel}
            onSave={makeOnSave("lastname")}
            initialValue={customer.lastname}
            disabled={disabled}
            required
            inputProps={{ "data-test": "customer-lastname-input" }}
          />
        </Grid>
      </Grid>

      <AutoSaveInput
        name="customerNumber"
        label={translations.customerNumberLabel}
        initialValue={`${customer.customerNumber}` || ""}
        onSave={makeOnSave("customerNumber")}
        disabled={disabled}
        FormikConfig={{
          validate: validateCustomerNumber,
          validateOnBlur: false,
        }}
        inputProps={{ "data-test": "customer-number-input" }}
      />
      <AutoSaveInput
        name="personalId"
        label={translations.personalIdLabel}
        onSave={makeOnSave("personalId")}
        initialValue={customer.personalId || ""}
        disabled={disabled}
        inputProps={{ "data-test": "personal-id-input" }}
      />
      <AutoSaveInput
        name="phone"
        label={translations.phoneLabel}
        onSave={makeOnSave("phone")}
        initialValue={customer.phone || ""}
        disabled={disabled}
        inputProps={{ "data-test": "phone-input" }}
      />
      <AutoSaveInput
        label={translations.emailLabel}
        name="email"
        initialValue={`${customer.email}` || ""}
        value={customer.email}
        onSave={makeOnSave("email")}
        disabled={disabled}
        inputProps={{ "data-test": "email-input" }}
      />
      <AutoSaveSelect
        name="language"
        label={translations.languageLabel}
        initialValue={customer.language}
        onSave={makeOnSave("language")}
        options={languageList}
        disabled={disabled}
        data-test="language-select-input"
      />
    </Paper>
  )
}

const defaultTranslations = {
  firstnameLabel: "Firstname",
  lastnameLabel: "Lastname",
  customerNumberLabel: "Customer number",
  personalIdLabel: "Personal ID",
  phoneLabel: "Phone number",
  emailLabel: "Email",
  languageLabel: "Language",
  invalidCustomerNumberError: "Customer number is not valid",
  duplicateCustomerNumberError: "Customer number already exists",
  numberOnlyCustomerNumberError: "Customer number can contain numbers only",
  somethingWentWrongError: "Something went wrong",
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const { translations: customerTranslations } = useI18n("customer")
  const customerForm = (customerTranslations.customerForm || {}) as StringMap
  const basicInfoForm = (customerTranslations.basicInfoForm || {}) as StringMap
  const translations = t || ({} as StringMap)

  const somethingWentWrongError = translations.appFallbackErrorMessage || defaults.somethingWentWrongError
  const {
    firstnameLabel = defaults.firstnameLabel,
    lastnameLabel = defaults.lastnameLabel,
    customerNumberLabel = defaults.customerNumberLabel,
    personalIdLabel = defaults.personalIdLabel,
    phoneLabel = defaults.phoneLabel,
    emailLabel = defaults.emailLabel,
    languageLabel = defaults.languageLabel,
  } = basicInfoForm
  const {
    invalidCustomerNumberError = defaults.invalidCustomerNumberError,
    duplicateCustomerNumberError = defaults.duplicateCustomerNumberError,
    numberOnlyCustomerNumberError = defaults.numberOnlyCustomerNumberError,
  } = customerForm

  return {
    firstnameLabel,
    lastnameLabel,
    customerNumberLabel,
    personalIdLabel,
    phoneLabel,
    emailLabel,
    languageLabel,
    invalidCustomerNumberError,
    duplicateCustomerNumberError,
    numberOnlyCustomerNumberError,
    somethingWentWrongError,
  }
}

type PersonBasicInfoFormProps = {
  customer: IPersonCustomerNormalized
  canUpdateBasicInfo: boolean
}
type Translations = typeof defaultTranslations
const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    "& > form, & > div": {
      padding: theme.spacing(1, 2),
    },
  },
  item: {
    flexGrow: 1,
  },
}))

export default PersonBasicInfoForm
