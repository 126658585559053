import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Grid from "@material-ui/core/Grid"
import FormikInput from "../../components/FormikInput"

import { Formik, Form } from "formik"
import { makeStyles, Theme } from "@material-ui/core"
import { ReactElement } from "react"
import { useDispatch } from "react-redux"
import { AlertTriangle, Check } from "react-feather"
import { useNetworkStatus } from "../../hooks/use-network-status"
import { useI18n } from "../../hooks"
import { UpdateWorkspaceInfo } from "../store/actions"
import { IWorkspaceResponse } from "../interfaces/workspace-response"
import { StringMap } from "../../types/common"

interface FormProps {
  workspace: IWorkspaceResponse
  disabled?: boolean
}

const WorkspaceNameEditor = ({ workspace, disabled = false }: FormProps) => {
  const translations = useTranslations()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { isIdle, isFulfilled, isPending, isRejected, setStatus } = useNetworkStatus({
    resetDelayInMS: 1000,
  })
  const handleSubmit = async ({ workspaceName }: { workspaceName: string }) => {
    setStatus("pending")
    const httpPayload = { name: workspaceName }
    const thunkPayload = { workspaceId: workspace.id, ...httpPayload }
    const actionResult: any = await dispatch(UpdateWorkspaceInfo(thunkPayload))
    setStatus(actionResult.meta.requestStatus || "rejected")
  }

  const formikProps = {
    initialValues: {
      workspaceName: workspace.name,
    },
    onSubmit: handleSubmit,
  }

  /** Button icon jsx */
  const PendingIcon = <CircularProgress size={16} color="secondary" />
  const FulfilledIcon = <Check width={16} height={16} data-test="success-icon" />
  const RejectedIcon = <AlertTriangle width={16} height={16} />

  return (
    <Formik {...formikProps} enableReinitialize>
      {({ values, isSubmitting, isValidating, dirty }) => {
        const shouldDisableInput = isSubmitting || !isIdle() || disabled
        const shouldDisableButton = isSubmitting || isValidating || !isIdle() || disabled || !dirty

        let buttonStartIcon: ReactElement | null = null
        if (isPending()) buttonStartIcon = PendingIcon
        if (isFulfilled()) buttonStartIcon = FulfilledIcon
        if (isRejected()) buttonStartIcon = RejectedIcon

        let buttonLabel = translations.renameButtonLabel_ideal
        if (isPending()) buttonLabel = translations.renameButtonLabel_pending
        if (isFulfilled()) buttonLabel = translations.renameButtonLabel_fulfilled
        if (isRejected()) buttonLabel = translations.renameButtonLabel_rejected

        return (
          <Form>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={8}>
                <FormikInput
                  id="workspaceName"
                  name="workspaceName"
                  variant="outlined"
                  margin="dense"
                  className={classes.inputField}
                  value={values.workspaceName}
                  disabled={shouldDisableInput}
                  inputProps={{ "data-test": "workspace-name-input" }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  variant="outlined"
                  type="submit"
                  disabled={shouldDisableButton}
                  startIcon={buttonStartIcon}
                  data-test="submit-button"
                >
                  {buttonLabel}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  inputField: {
    width: "100%",
    margin: 0,
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("workspace")
  const translations = (t?.workspaceSettingsPage || {}) as StringMap

  const {
    renameButtonLabel_ideal = defaults.renameButtonLabel_ideal,
    renameButtonLabel_pending = defaults.renameButtonLabel_pending,
    renameButtonLabel_fulfilled = defaults.renameButtonLabel_fulfilled,
    renameButtonLabel_rejected = defaults.renameButtonLabel_rejected,
  } = translations

  return {
    renameButtonLabel_ideal,
    renameButtonLabel_pending,
    renameButtonLabel_fulfilled,
    renameButtonLabel_rejected,
  }
}

const defaultTranslations = {
  renameButtonLabel_ideal: "Rename",
  renameButtonLabel_pending: "Renaming ...",
  renameButtonLabel_fulfilled: "Renamed",
  renameButtonLabel_rejected: "Failed to rename",
}

type Translations = typeof defaultTranslations
export default WorkspaceNameEditor
