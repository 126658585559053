import { api } from "../../api"
import { makeUrlSearchParams } from "../../utils/url"
import { makeHolidayApiEndpoints } from "./endpoints"
import { HolidayViewModel, makeHolidayViewModel } from "./holiday"
import { HolidayResponse } from "./holiday-response"

const apiEndpoints = makeHolidayApiEndpoints()

export const holidayApi = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getHolidays: builder.query<HolidayViewModel[], GetHolidaysQuery>({
        query: (params) => {
          return {
            method: "GET",
            url: apiEndpoints.holidays(),
            params: params ? makeUrlSearchParams(params) : undefined,
          }
        },
        transformResponse: (response: HolidayResponse[]) => response.map(makeHolidayViewModel),
        providesTags: ["Holiday"],
      }),
      getHoliday: builder.query<HolidayViewModel, { holidayId: string }>({
        query: ({ holidayId }) => {
          return {
            method: "GET",
            url: apiEndpoints.holidayItem(holidayId),
          }
        },
        transformResponse: (response: HolidayResponse) => makeHolidayViewModel(response),
        providesTags: ["Holiday"],
      }),
      createHoliday: builder.mutation<HolidayViewModel, NewHolidayData>({
        query: (holidayData) => {
          return {
            method: "POST",
            url: apiEndpoints.holidays(),
            body: holidayData,
          }
        },
        invalidatesTags: ["Holiday"],
      }),
      updateHoliday: builder.mutation<HolidayViewModel, { holidayId: string } & UpdateHolidayData>({
        query: ({ holidayId, ...updateData }) => {
          return {
            method: "PUT",
            url: apiEndpoints.holidayItem(holidayId),
            body: updateData,
          }
        },
        invalidatesTags: ["Holiday"],
      }),
      deleteHoliday: builder.mutation<void, { holidayId: string }>({
        query: ({ holidayId }) => {
          return {
            method: "DELETE",
            url: apiEndpoints.holidayItem(holidayId),
          }
        },
        invalidatesTags: ["Holiday"],
      }),
    }
  },
})

export const { useGetHolidaysQuery, useCreateHolidayMutation, useUpdateHolidayMutation, useDeleteHolidayMutation } =
  holidayApi

type BaseSingleNewHolidayData = {
  name: string
  type: "Single"
  date: Date
  recurring: boolean
}
type BaseRangeNewHolidayData = {
  name: string
  type: "Range"
  startDate: Date
  endDate: Date
  recurring: boolean
}
export type BaseNewHolidayData = BaseSingleNewHolidayData | BaseRangeNewHolidayData

type UserNewHolidayData = BaseNewHolidayData & { userId: string }
type OrgNewHolidayData = BaseNewHolidayData & { orgId: string }
export type NewHolidayData = UserNewHolidayData | OrgNewHolidayData

export type UpdateHolidayData = {
  name?: string
  date?: Date
  recurring?: boolean
  startDate?: Date
  endDate?: Date | null
}

type GetHolidaysQuery = { project: string } | { userId: string } | { orgId: string }
