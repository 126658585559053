import ProjectNavbarDefault from "./ProjectNavbarDefault"
import ProjectNavbarMobile from "./ProjectNavbarMobile"
import { useIsSmallScreen } from "../../../hooks/use-is-small-screen"
import { ProjectViewModel } from "../../api/project"

const ProjectNavbar = (props: ProjectNavbarProps) => {
  const isSmallScreen = useIsSmallScreen()
  return isSmallScreen ? <ProjectNavbarMobile {...props} /> : <ProjectNavbarDefault {...props} />
}

type ProjectNavbarProps = {
  project: ProjectViewModel
  isFullWidth?: boolean
}

export default ProjectNavbar
