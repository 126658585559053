import React from "react"
import AppLayout from "./AppLayout"
import { Redirect } from "react-router-dom"
import { useAppSelector } from "../store"
import { getAuthState } from "../users/store/selectors"
import { paths } from "../paths"
import { useRouter } from "../hooks"
import { useAppContext } from "../hooks/use-app-context"
import { useUrlWithContext } from "../hooks/use-url-with-context"

export const withActiveRouteLayout = (Component: React.ComponentType<any>) => {
  const WithLayout = (props: any = {}) => {
    const { user } = useAppSelector(getAuthState)

    if (!user) return null
    if (user.isWaitingVerification) return <Redirect to={paths.accountVerification()} />
    if (user.isDeactivated) return <Redirect to={paths.deactivated()} />

    return (
      <GoToValidUrl userId={user.id}>
        <AppLayout>
          <Component {...props} />
        </AppLayout>
      </GoToValidUrl>
    )
  }
  return WithLayout
}

const GoToValidUrl = ({ userId, children }: React.PropsWithChildren<{ userId: string }>) => {
  const { appContext } = useAppContext()
  const { location, match } = useRouter()
  const { createPathWithGivenContext } = useUrlWithContext()
  const { mainContext, subContext } = appContext

  const userHomeWithContext = createPathWithGivenContext({
    path: paths.home(),
    mainContextId: userId,
  })
  if (!mainContext) return <Redirect to={userHomeWithContext} />

  if (mainContext.type === "org") {
    const isProjectRoute = match.path === "/projects/:projectId"
    const isTaskRoute = match.path === "/tasks/:taskId"

    if (!subContext && !isProjectRoute && !isTaskRoute) {
      const orgProjectsWithContext = createPathWithGivenContext({
        path: paths.projects(),
        mainContextId: mainContext.id,
      })
      const isKanbanRoute = location.pathname.includes("/kanban")
      const isHomeRoute = location.pathname.includes("/home")
      if (isKanbanRoute || isHomeRoute) return <Redirect to={orgProjectsWithContext} />
    }

    if (subContext && subContext.type === "supplier") {
      const supplierHomeWithContext = createPathWithGivenContext({
        path: paths.home(),
        mainContextId: mainContext.id,
        subContextId: subContext.id,
      })
      const isTodoRoute = location.pathname.includes("/todos")
      const shouldRedirect = !isProjectRoute && !isTaskRoute && isTodoRoute
      if (shouldRedirect) return <Redirect to={supplierHomeWithContext} />
    }
  }

  return <>{children}</>
}
