import { TimeZoneType } from "../../constants/timezones"
import { HolidayViewModel } from "../../holidays/api/holiday"
import { DateTimeService } from "../../services/date-time-service"
import { TreeGridHoliday, TreeGridHolidayCellPermissions } from "./types"

export const makeTreeGridHolidayRows = ({ holidays, ...rest }: MakeRowsProps): TreeGridHoliday[] => {
  return holidays.map((holiday) => makeTreeGridHolidayRow({ holiday, ...rest }))
}

export const makeTreeGridHolidayRow = (props: MakeHolidayRowProps): TreeGridHoliday => {
  const { holiday, dateFormat, timeZone, canCreateHoliday } = props
  const { id, name, date, startDate, endDate, type, recurring } = holiday

  const dateTimeService = new DateTimeService({ dateFormat, timeZone })
  const dateTimeFormat = dateTimeService.getFormat()
  const timezonedDate = date ? dateTimeService.removeTimezoneOffset(date, "UTC").getTime() : ""
  const timezonedStartDate = startDate ? dateTimeService.removeTimezoneOffset(startDate, "UTC").getTime() : ""
  const timezonedEndDate = endDate ? dateTimeService.removeTimezoneOffset(endDate, "UTC").getTime() : ""
  const cellPermissions = getTreeGridHolidayCellPermissions()

  return {
    id,
    name,
    startDate: timezonedDate || timezonedStartDate,
    endDate: timezonedEndDate,
    startDateFormat: dateTimeFormat,
    endDateFormat: dateTimeFormat,
    type,
    isRecurring: recurring ? 1 : 0,
    Height: 48,
    MaxHeight: 48,
    ...cellPermissions,
  }

  function getTreeGridHolidayCellPermissions(): TreeGridHolidayCellPermissions {
    return {
      nameCanEdit: canCreateHoliday ? 1 : 0,
      startDateCanEdit: canCreateHoliday ? 1 : 0,
      endDateCanEdit: canCreateHoliday ? 1 : 0,
      isRecurringCanEdit: canCreateHoliday ? 1 : 0,
    }
  }
}

type MakeHolidayRowProps = {
  holiday: HolidayViewModel
  dateFormat: string
  timeZone: TimeZoneType
  canCreateHoliday: boolean
}

type MakeRowsProps = {
  holidays: HolidayViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
  canCreateHoliday: boolean
}
