import { TimeZoneType } from "../../constants/timezones"
import { ProjectViewModel } from "../../projects/api/project"
import { DateTimeService } from "../../services/date-time-service"
import { TreeGridTemplate, TreeGridTemplateCellPermissions } from "./types"

export const makeTreeGridTemplateRows = ({ projects, ...rest }: MakeRowsProps): TreeGridTemplate[] => {
  return projects.map((project) => makeTreeGridTemplateRow({ project, ...rest }))
}

export const makeTreeGridTemplateRow = (props: MakeTemplateRowProps): TreeGridTemplate => {
  const { project, dateFormat, timeZone } = props
  const { id, title, creatorName } = project

  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent: true })
  const dateTimeFormat = dateTimeService.getFormat()
  const projectNumber = project.customProjectNumber || project.projectNumber
  const createdAt = dateTimeService.removeTimezoneOffset(project.createdAt, "UTC").getTime()
  const cellPermissions = getTreeGridTemplateCellPermissions()

  return {
    id,
    open: "/external-link-icon.svg",
    projectNumber,
    title: title,
    creatorName,
    createdAt,
    createdAtFormat: dateTimeFormat,
    Height: 48,
    MaxHeight: 48,
    ...cellPermissions,
  }
}

function getTreeGridTemplateCellPermissions(): TreeGridTemplateCellPermissions {
  return {
    projectNumberCanEdit: 0,
    titleCanEdit: 0,
    createdAtCanEdit: 0,
    creatorNameCanEdit: 0,
  }
}

type MakeTemplateRowProps = {
  project: ProjectViewModel
  dateFormat: string
  timeZone: TimeZoneType
}

type MakeRowsProps = {
  projects: ProjectViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
}
