import { TreeGridColors } from "../../treegrid/types"
import { CalendarEventResponse } from "./calendar-event-response"

export const makeCalendarEventViewModel = (calendarEvent: CalendarEventResponse): CalendarEventViewModel => {
  let viewModel = {
    ...calendarEvent,
    color: calendarEvent.color ?? "Default",
    isOrgEvent: calendarEvent.organisation !== null,
    isUserEvent: calendarEvent.organisation === null,
    isRecurringEvent: Boolean(calendarEvent.recurranceType) || Boolean(calendarEvent.parentEventId),
    isDeletedRecurringEvent: calendarEvent.recurranceType === "none",
    eventLength: calendarEvent.eventLength / 1000,
  }
  if (calendarEvent.nextOccurrence) {
    viewModel = {
      ...viewModel,
      nextOccurrence: {
        ...calendarEvent.nextOccurrence,
        eventLength: calendarEvent.nextOccurrence.eventLength / 1000,
      },
    }
  }
  return viewModel
}

export type CalendarEventViewModel = CalendarEventResponse & {
  color: TreeGridColors
  isRecurringEvent: boolean
  isDeletedRecurringEvent: boolean
  isUserEvent: boolean
  isOrgEvent: boolean
}
