import { useAppSelector } from "../../store"
import { MembershipPermissions } from "../interfaces/membership-permissions"
import { getMembershipPermissions } from "../store/selectors"
import { flattenGroupedMembershipPermission } from "../utils/flatten-membership-permission"

// In store we save grouped permissions in shape { id: membershipId, abilitiesByResources: [{ resource: id, abilities: ['Ability'] }] }
// But in the UI we need flattened permissions in shape { canCreateTodos: true, canCreateCalendarEvents: false  ... }
// This hook selects the permission of given membershipId and returns flattened permissions
export const useFlattenedPermissions = (membershipId: string) => {
  const groupedMembershipPermissions = useAppSelector((s) => getMembershipPermissions(s, membershipId))
  return groupedMembershipPermissions
    ? flattenGroupedMembershipPermission(groupedMembershipPermissions)
    : ({} as MembershipPermissions)
}
