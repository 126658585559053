import { createTheme, responsiveFontSizes } from "@material-ui/core/styles"

import * as constants from "./constants"

/** Some local style related constants to avoid magic string */
const INPUT_PADDING = "16px"
/** */

/** Font weight enum */
enum FontWeight {
  EXTRA_LIGHT = 200,
  LIGHT = 300,
  REGULAR = 400,
  MEDIUM = 500,
  SEMI_BOLD = 600,
  BOLD = 700,
  EXTRA_BOLD = 800,
  BLACK = 900,
}
/** */

let theme = createTheme({
  palette: {
    common: {
      black: constants.COLOR_BLACK,
      white: constants.COLOR_WHITE,
    },
    background: {
      paper: constants.COLOR_WHITE,
      default: constants.COLOR_LIGHT,
    },
    primary: {
      contrastText: constants.COLOR_WHITE,
      main: constants.COLOR_PRIMARY,
    },
    secondary: {
      main: constants.COLOR_SECONDARY,
      contrastText: constants.COLOR_WHITE,
    },
    success: {
      main: constants.COLOR_SECONDARY,
      contrastText: constants.COLOR_WHITE,
    },
    info: {
      main: constants.COLOR_INFO,
      contrastText: constants.COLOR_WHITE,
    },
    error: {
      main: constants.COLOR_ERROR,
      contrastText: constants.COLOR_WHITE,
    },
    warning: {
      main: constants.COLOR_WARNING,
      contrastText: constants.COLOR_BLACK,
    },
    text: {
      primary: constants.COLOR_BLACK,
    },
    divider: constants.COLOR_DIVIDER,
  },
  typography: {
    fontFamily: constants.SANS_FONT_FAMILY,
    h1: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.LIGHT,
      fontSize: "5.875rem",
      letterSpacing: "-1.5px",
    },
    h2: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.LIGHT,
      fontSize: "3.6875rem",
      letterSpacing: "-0.5px",
    },
    h3: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.REGULAR,
      fontSize: "2.9375rem",
      letterSpacing: "0",
    },
    h4: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.REGULAR,
      fontSize: "2.0625rem",
      letterSpacing: "0.25px",
    },
    h5: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.REGULAR,
      fontSize: "1.5rem",
      letterSpacing: "0",
    },
    h6: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.MEDIUM,
      fontSize: "1.25rem",
      letterSpacing: "0.15px",
    },
    subtitle1: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.REGULAR,
      fontSize: "1rem",
      letterSpacing: "0.15px",
    },
    subtitle2: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.MEDIUM,
      fontSize: "0.875rem",
      letterSpacing: "0.1px",
    },
    body1: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.REGULAR,
      fontSize: "1rem",
      letterSpacing: "0.5px",
    },
    body2: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.REGULAR,
      fontSize: "0.875rem",
      letterSpacing: "0.25px",
    },
    button: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: 500,
      fontSize: "0.875rem",
      letterSpacing: "1.25px",
      textTransform: "none",
    },
    caption: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.REGULAR,
      fontSize: "0.75rem",
      letterSpacing: "0.4px",
    },
    overline: {
      fontFamily: constants.SANS_FONT_FAMILY,
      fontWeight: FontWeight.REGULAR,
      fontSize: "0.625rem",
      letterSpacing: "1.5px",
    },
  },
})
/** Customized styles */

// Custom button sytle
const MuiButton = {
  contained: {
    // removes elevation of contained button
    boxShadow: "none",
  },
}

// Custom input styles
const MuiOutlinedInput = {
  root: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: constants.COLOR_INFO,
    },
    "&.Mui-focused.Mui-error .MuiOutlinedInput-notchedOutline": {
      borderColor: "red",
    },
  },
  input: {
    padding: INPUT_PADDING,
  },
}

const MuiFilledInput = {
  root: {
    backgroundColor: constants.COLOR_WHITE,
    borderRadius: 4,
    "&:hover": {
      backgroundColor: constants.COLOR_DIVIDER,
    },
    "&.Mui-focused": {
      backgroundColor: constants.COLOR_LIGHT,
    },
    "&.Mui-error": {
      "&:hover:before": {
        border: `1px solid ${constants.COLOR_ERROR}`,
        borderBottom: `1px solid ${constants.COLOR_ERROR}`,
        transition: "border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
      "&:before": {
        border: `1px solid ${constants.COLOR_ERROR}`,
        borderBottom: `1px solid ${constants.COLOR_ERROR}`,
        transition: "border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },
    },
  },
  focused: {
    "&:before": {
      border: `2px solid ${constants.COLOR_INFO}`,
      borderBottom: `2px solid ${constants.COLOR_INFO}`,
      transition: "border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
  },
  underline: {
    "&:hover:before": {
      border: `2px solid ${constants.COLOR_INFO}`,
      borderBottom: `2px solid ${constants.COLOR_INFO}`,
      transition: "border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "&:before": {
      height: "100%",
      border: `1px solid ${constants.COLOR_LOW_CONTRAST_NEUTRAL}`,
      borderBottom: `1px solid ${constants.COLOR_LOW_CONTRAST_NEUTRAL}`,
      borderRadius: 4,
      transition: "border-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    },
    "&:after": {
      borderBottom: "none",
    },
  },
  input: {},
}

const MuiFab = {
  root: {
    boxShadow: "none",
  },
}

const MuiFormLabel = {
  root: {
    display: "inline-block",
    marginBottom: theme.spacing(),
    fontWeight: FontWeight.MEDIUM,
    fontSize: 14,
    color: theme.palette.common.black,
  },
}

theme.overrides = {
  MuiButton,
  MuiFab,
  MuiOutlinedInput,
  MuiFilledInput,
  MuiFormLabel,
}

theme.palette.text.secondary = theme.palette.grey["700"]

theme = responsiveFontSizes(theme)

export default theme
