import { makeStyles, Theme } from "@material-ui/core"
import InlineEditableText from "../../components/InlineEditableText"
import { useI18n } from "../../hooks/use-i18n"
import { StringMap } from "../../types/common"
import { TodoViewModel } from "../api/todo"
import { useTodoMutations } from "../hooks/use-todo-mutations"

const TodoCardTitle = ({ todo }: { todo: TodoViewModel }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { updateInfo } = useTodoMutations()

  return (
    <InlineEditableText
      placeholder={translations.todoTitlePlaceholder}
      value={todo.title}
      onSave={(title) => updateInfo(todo.id, { title })}
      TypographyProps={{ variant: "h5", className: classes.todoTitle }}
      isEditable={todo.canUpdate}
    />
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  todoTitle: {
    "& span, & textarea": {
      maxWidth: "none",
      textWrap: "inherit",
    },
  },
}))

export default TodoCardTitle

const defaultTranslations = {
  todoTitlePlaceholder: "Enter title here",
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n("todo")
  const translations = (t?.todoDetails || {}) as StringMap

  const { todoTitlePlaceholder = defaults.todoTitlePlaceholder } = translations
  return { todoTitlePlaceholder }
}
