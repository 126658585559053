import { CalendarEventViewModel } from "../api/calendar-event"
import { useCalendarEventMutations } from "../hooks/use-calendar-event-mutations"
import ResourceManager from "../../components/ResourceManager"
import { useI18n } from "../../hooks/use-i18n"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import { StringMap } from "../../types/common"

const EventParticipantsWidget = ({ calendarEvent }: { calendarEvent: CalendarEventViewModel }) => {
  const { updateParticipants } = useCalendarEventMutations()
  const translations = useTranslations(defaultTranslations)
  const { membershipOptions } = useOrgOptions(calendarEvent.organisation?.id || "")

  const onAddParticipant = (participantIds: string[]) => {
    const participants = calendarEvent.participants.map(($p) => $p.id).concat(participantIds)
    updateParticipants(calendarEvent.id, participants)
  }

  const onRemoveParticipant = (participant: string) => {
    const participants = calendarEvent.participants.filter(($p) => $p.id !== participant).map(($p) => $p.id)
    updateParticipants(calendarEvent.id, participants)
  }

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={membershipOptions}
      resources={calendarEvent.participants}
      canAddResource={calendarEvent.canUpdate}
      canRemoveResource={calendarEvent.canUpdate}
      onAddResources={onAddParticipant}
      onRemoveResource={onRemoveParticipant}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (
  defaults: EventParticipantsWidgetTranslations = defaultTranslations
): EventParticipantsWidgetTranslations => {
  const { translations: t } = useI18n("calendarEvent")
  const translations = (t?.participantsWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: "Participants",
  emptyOptionsMessage: "No members in this organisation",
  emptyResourcesMessage: "This event does not have participants",
}

type EventParticipantsWidgetTranslations = typeof defaultTranslations

export default EventParticipantsWidget
