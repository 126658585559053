import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Chip from "@material-ui/core/Chip"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles } from "@material-ui/core"
import { Briefcase, Calendar, ExternalLink, User } from "react-feather"
import { useDateFormat } from "../users/hooks/use-date-format"
import { DateTimeService } from "../services/date-time-service"
import { useI18n } from "../hooks"
import { StringMap } from "i18next"
import { Activity } from "../routes/home"

const ActivityCard = ({ activity, onOpenActivity }: { activity: Activity; onOpenActivity: () => void }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { dateFormat } = useDateFormat()
  const { title, people, startDate, endDate, type, enableTimeComponent, customers } = activity
  const dateTimeService = new DateTimeService({ dateFormat, enableTimeComponent })

  const formattedStart = startDate ? dateTimeService.format(startDate) : translations.noStartDate
  const formattedEnd = endDate ? dateTimeService.format(endDate) : translations.noEndDate
  const hasSchedule = Boolean(startDate || endDate)
  const schedule = hasSchedule
    ? type === "todo"
      ? formattedEnd
      : `${formattedStart} - ${formattedEnd}`
    : translations.noSchedule
  const formattedPeople = people.join(", ")
  const formattedCustomers = customers.join(", ")

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <div className={classes.header}>
          <Chip
            label={translations[type]}
            size="small"
            variant="default"
            color="primary"
            className={classes.statusChip}
          />
          <IconButton size="small" color="primary" onClick={onOpenActivity}>
            <ExternalLink size={16} />
          </IconButton>
        </div>
        {activity.fullPath ? activity.fullPath : null}
        <Typography variant="subtitle1" component="h2" style={{ marginTop: 4, marginBottom: 16 }} gutterBottom>
          {title}
        </Typography>

        {formattedPeople && (
          <div className={classes.infoRow}>
            <User className={classes.icon} size={16} />
            <Typography variant="body2">{formattedPeople}</Typography>
          </div>
        )}

        {formattedCustomers && (
          <div className={classes.infoRow}>
            <Briefcase className={classes.icon} size={16} />
            <Typography variant="body2">{formattedCustomers}</Typography>
          </div>
        )}

        <div className={classes.infoRow}>
          <Calendar className={classes.icon} size={16} />
          <Typography variant="body2">{schedule}</Typography>
        </div>
      </CardContent>
    </Card>
  )
}

export default ActivityCard

const useStyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    transition: "transform 0.2s ease-in-out",
    marginTop: 4,
    marginBottom: 4,
    "& .MuiCardContent-root": {
      paddingBottom: 8,
    },
  },
  header: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  statusChip: {
    height: 16,
    fontSize: 12,
  },
  infoRow: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = t || ({} as StringMap)
  return { ...defaults, ...translations }
}

const defaultTranslations = {
  task: "Task",
  todo: "To-do",
  calendarEvent: "Calendar event",
  noStartDate: "No start date",
  noEndDate: "No end date",
  noSchedule: "No schedule yet",
}

type Translations = typeof defaultTranslations
