import { Grid, makeStyles, Theme } from "@material-ui/core"
import { RouteComponentProps } from "react-router-dom"
import { useTask } from "../../../tasks/hooks/use-task"
import { APP_MAX_WIDTH } from "../../../constants/constants"
import TaskCardTitle from "../../../tasks/components/TaskCardTitle"
import TaskDetails from "../../../tasks/components/TaskDetails"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const TaskBasicRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const classes = useStyles()
  const { task } = useTask(match.params.taskId, queryOptions)

  if (!task) return null

  return (
    <Grid container alignItems="center" direction="column">
      <Grid item container alignItems="center" direction="column" spacing={2} className={classes.detailsContainer}>
        <Grid item className={classes.taskTitleGrid}>
          <TaskCardTitle task={task} />
        </Grid>
        <Grid item className={classes.taskDetailsGrid}>
          <TaskDetails taskId={task.id} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    maxWidth: APP_MAX_WIDTH,
  },

  taskTitleGrid: { width: "100%" },
  taskDetailsGrid: { width: "100%" },
}))

type RouteParams = { taskId: string }
export default TaskBasicRoute
