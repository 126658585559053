import { CustomStatusHandler } from "../../lib/custom-status-handler"
import { StatusOption } from "../../lib/status-options"
import { LinkViewModel, makeLinkViewModel } from "../../links/api/link"
import { TreeGridColors } from "../../treegrid/types"
import { MaintainerTypeEnum, Status } from "../../types/common"
import { PROJECT_TYPE } from "../constants"
import { ProjectResponse } from "./project-response"

const statusTranslationKeys: Record<Status, StatusTranslationKey> = {
  [Status.COMPLETED]: "completed",
  [Status.IN_PROGRESS]: "inProgress",
  [Status.NOT_STARTED]: "notStarted",
  [Status.REJECTED]: "rejected",
}

function getStatusTranslationKey(status: string): StatusTranslationKey | undefined {
  return statusTranslationKeys[status as Status]
}

export const makeProjectViewModel = (project: ProjectResponse): ProjectViewModel => {
  const projectLinks = (project.links || []).map(makeLinkViewModel)
  const statusHandler = new CustomStatusHandler(project)
  const projectStatus = statusHandler.getSelectedStatus()
  const shownProjectNumber = project.customProjectNumber || project.projectNumber
  const fullTitle = `${shownProjectNumber}: ${project.title}`

  return {
    ...project,
    fullTitle,
    shownProjectNumber,
    ganttBarColor: project.ganttBarColor || "Default",
    isManager: (id) => project.managers.map(({ id }) => id).includes(id),
    isParticipant: (id) => project.participants.map(({ id }) => id).includes(id),
    isCustomer: (id) => project.customers.map(({ id }) => id).includes(id),
    isSupplier: (id) => project.suppliers.map(({ id }) => id).includes(id),
    isMaintainer: (id) => project.maintainerId === id,
    isTemplate: project.type === PROJECT_TYPE.TEMPLATE,
    isNormalProject: project.type === PROJECT_TYPE.NORMAL,
    isUserProject: project.maintainerType === MaintainerTypeEnum.USER,
    isOrgProject: project.maintainerType === MaintainerTypeEnum.ORG,
    isCompleted: project.status === Status.COMPLETED,
    isNotStarted: project.status === Status.NOT_STARTED,
    isInProgress: project.status === Status.IN_PROGRESS,
    isRejected: project.status === Status.REJECTED,
    links: projectLinks,
    statusIndex: statusHandler.getStatusIndex(),
    customStatus: projectStatus,
    statusOptions: statusHandler.getVisibleOptions(),
    completionPercentage: project.completionPercentage || 0,
    statusTranslationKey: getStatusTranslationKey(projectStatus),
  }
}

export type ProjectViewModel = Omit<ProjectResponse, "links"> & {
  fullTitle: string
  shownProjectNumber: string
  ganttBarColor: TreeGridColors
  isUserProject: boolean
  isOrgProject: boolean
  isManager: (membershipId: string) => boolean
  isParticipant: (membershipId: string) => boolean
  isCustomer: (customerId: string) => boolean
  isSupplier: (supplierId: string) => boolean
  isMaintainer: (maintainerId: string) => boolean
  isTemplate: boolean
  isNormalProject: boolean
  isInProgress: boolean
  isCompleted: boolean
  isRejected: boolean
  isNotStarted: boolean
  links: LinkViewModel[]
  statusIndex: 0 | 1 | 2 | 3
  customStatus: string
  statusOptions: StatusOption[]
  statusTranslationKey?: StatusTranslationKey
}

type StatusTranslationKey = "completed" | "inProgress" | "notStarted" | "rejected"
