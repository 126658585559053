import * as taskApi from "../../tasks/api"
import { cloneDeep, filter } from "lodash"
import { RootNode, TaskTreeGridRow } from "../types"
import { TreeGridTranslations } from "../hooks/use-translations"
import { TimeZoneType } from "../../constants/timezones"
import { TaskViewModel } from "../../tasks/api/task"

export function makeShowContextMenu({ rootNode, onAddTask, onCopyTask, translations }: UseContextMenuProps) {
  const handleAddTask = async ({ position, row, grid }: AddTaskContextType) => {
    const parentTaskId = position === "child" ? row.id : row.parentNode.id || ""
    const order = position === "above" ? row.order : position === "below" ? row.order + 1 : undefined
    await onAddTask({ title: "Untitled task", parentTaskId, order })
  }

  const handleCopyTask = async ({ row, position = "below", copyTree = false, grid }: CopyTaskProps) => {
    if (!grid || !row) return
    const order = position === "above" ? row.order : row.order + 1
    await onCopyTask(row.id, { copyTree, order })
  }

  // Context menu items are the menu items that appear when you right click on any row on treegrid
  const contextMenuItems: ContextMenuItem[] = [
    {
      itemId: "AddTaskBelow",
      Name: translations.addTaskBelow,
      OnClick: ({ Row, Grid }: { Row: TRow & TaskTreeGridRow; Grid: TGrid }) => {
        return handleAddTask({ position: "below", grid: Grid, row: Row })
      },
    },
    {
      itemId: "AddTaskAbove",
      Name: translations.addTaskAbove,
      OnClick: ({ Row, Grid }: { Row: TRow & TaskTreeGridRow; Grid: TGrid }) => {
        return handleAddTask({ position: "above", grid: Grid, row: Row })
      },
    },
    {
      itemId: "AddChildTask",
      Name: translations.addSubtask,
      OnClick: ({ Row, Grid }: { Row: TRow & TaskTreeGridRow; Grid: TGrid }) => {
        return handleAddTask({ position: "child", grid: Grid, row: Row })
      },
    },
    {
      itemId: "CopyTaskBelow",
      Name: translations.copyTaskBelow,
      OnClick: ({ Row, Grid }: { Row: TRow & TaskTreeGridRow; Grid: TGrid }) => {
        return handleCopyTask({ row: Row, grid: Grid })
      },
    },
    {
      itemId: "CopyTaskAbove",
      Name: translations.copyTaskAbove,
      OnClick: ({ Row, Grid }: { Row: TRow & TaskTreeGridRow; Grid: TGrid }) => {
        return handleCopyTask({ row: Row, position: "above", grid: Grid })
      },
    },
    {
      itemId: "CopyTaskTreeBelow",
      Name: translations.copyTaskTreeBelow,
      OnClick: ({ Row, Grid }: { Row: TRow & TaskTreeGridRow; Grid: TGrid }) => {
        return handleCopyTask({ row: Row, copyTree: true, grid: Grid })
      },
    },
    {
      itemId: "CopyTaskTreeAbove",
      Name: translations.copyTaskTreeAbove,
      OnClick: ({ Row, Grid }: { Row: TRow & TaskTreeGridRow; Grid: TGrid }) => {
        return handleCopyTask({ row: Row, grid: Grid, position: "above", copyTree: true })
      },
    },
  ]

  function showContextMenu(
    row: TRow & TaskTreeGridRow & { parentNode: null | TaskTreeGridRow },
    col: TCol,
    Grid: TGrid
  ) {
    let Items = cloneDeep(contextMenuItems)
    const hasChildren = Boolean(row.firstChild)

    // First depending on the row type, remove the menu items that are not relevant
    // If the context menu is being shown for a row that does not have children, remove the menu items copying the task tree
    if (!hasChildren) {
      Items = removeMenuItems(Items, "CopyTaskTreeAbove", "CopyTaskTreeBelow")
    }

    // Then depending on the permissions of the user, remove the menu items that should not be shown to this user
    const canCreateSiblings = row.Level > 0 ? row.parentNode.canCreateTasks : rootNode.canCreateTasks // if true user is allowed to create/copy tasks above and below the current row
    const canCreateSubtasks = Boolean(row?.canCreateTasks) // if true user is allowed to create/copy tasks as children of the current row

    // If user does not have permission to create siblings, then
    // then remove the menu items that either create or copy tasks as siblings
    if (!canCreateSiblings) {
      Items = removeMenuItems(
        Items,
        "AddTaskAbove",
        "AddTaskBelow",
        "CopyTaskAbove",
        "CopyTaskBelow",
        "CopyTaskTreeAbove",
        "CopyTaskTreeBelow"
      )
    }

    // If the user does not have permission to create subtasks, then
    // remove the menu item that creates tasks as children
    if (!canCreateSubtasks) {
      Items = removeMenuItems(Items, "AddChildTask")
    }

    // @ts-ignore
    Grid.ShowMenu(row, col, { Items })
    return 1
  }

  return {
    contextMenuItems,
    showContextMenu,
  }
}

function removeMenuItems(items: ContextMenuItem[], ...args: string[]) {
  return filter(items, ({ itemId }) => !args.includes(itemId))
}

type CopyTaskProps = {
  grid: TGrid
  row: TaskTreeGridRow
  copyTree?: boolean
  position?: "above" | "below"
}

type AddTaskContextType = {
  position: "above" | "below" | "child"
  row: TRow & TaskTreeGridRow
  grid: TGrid
}

type UseContextMenuProps = {
  rootNode: RootNode
  dateFormat: string
  timeZone: TimeZoneType
  onAddTask: (task: TreeGridNewTaskData) => Promise<taskApi.BulkCreateTasksReturnData | void>
  onCopyTask: (
    task: string,
    opts: CopyTaskOptions
  ) => Promise<{ added: TaskViewModel[]; updated: TaskViewModel[] } | void>
  translations: TreeGridTranslations
}

type TreeGridNewTaskData = {
  title: string
  parentTaskId?: string
  order?: number
}

type CopyTaskOptions = {
  copyTree: boolean
  order: number
}

type ContextMenuItem = {
  itemId: string
  Name: string
  OnClick: (args: any) => void
}
