import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"

import { makeStyles, Theme } from "@material-ui/core"
import { useI18n, useRouter } from "../../hooks"
import { useUrlWithContext } from "../../hooks/use-url-with-context"

const NoContacts = ({ showAddButton }: NoContactsProps) => {
  const classes = useStyles()
  const router = useRouter()
  const { createPathWithContext } = useUrlWithContext()
  const translations = useTranslations(defaultTranslations)

  return (
    <Box p={2}>
      <Card variant="outlined">
        <Box py={6} display="flex" alignItems="space-around" flexDirection="column">
          <Box display="flex" justifyContent="space-around">
            <CardMedia className={classes.img} component="img" image="/no-contact.svg" alt="no contact" />
          </Box>
          <Box my={2} display="flex" justifyContent="center">
            <Typography variant="subtitle1" color="textSecondary">
              {translations.contactListEmptyMessage}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center">
            {showAddButton && (
              <Button
                variant="outlined"
                onClick={() => router.push(createPathWithContext(`${router.pathname}?tab=add-contact`))}
              >
                {translations.addContactLabel}
              </Button>
            )}
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")
  const { contactListEmptyMessage = defaults.contactListEmptyMessage, addContactLabel = defaults.addContactLabel } =
    translations

  return {
    contactListEmptyMessage,
    addContactLabel,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    display: "block",
    maxWidth: 176,
  },
}))

const defaultTranslations = {
  contactListEmptyMessage: "Contact list is empty",
  addContactLabel: "Add contact",
}

type NoContactsProps = {
  showAddButton: boolean
}

type Translations = typeof defaultTranslations

export default NoContacts
