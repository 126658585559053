import React, { useState } from "react"
import AbilitiesTable from "../../components/AbilitiesTable"
import doublet from "../../utils/doublet"
import Avatar from "@material-ui/core/Avatar"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import Typography from "@material-ui/core/Typography"
import MuiToolbar from "@material-ui/core/Toolbar"
import MuiLink from "@material-ui/core/Link"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Tooltip from "@material-ui/core/Tooltip"
import { Dialog } from "@material-ui/core"
import { makeStyles, alpha, Theme } from "@material-ui/core"
import { uniq, without } from "lodash"
import { useMembership } from "../../memberships/hooks/use-membership"
import { useOrgMemberships } from "../../memberships/hooks/use-org-memberships"
import { useConfirmDialog } from "../../components/ConfirmDialog"
import { useAuthUserMembership } from "../../memberships/hooks/use-auth-user-membership"
import { paths } from "../../paths"
import { Link } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../store"
import { ActivateMemberships, DeactivateMemberships } from "../../memberships/store"
import { getManyMembershipPermissions } from "../../permissions/store/selectors"
import { FetchManyMembershipPermissions } from "../../permissions/store/actions"
import { adaptPermissions } from "../../components/AbilitiesTable/utils"
import { useMembershipActions } from "../../memberships/hooks/use-membership-actions"
import { useSnackbar } from "notistack"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { Info } from "react-feather"
import { useDateFormat } from "../../users/hooks/use-date-format"
import { format } from "date-fns"
import { useTranslations } from "../hooks/use-translations"
import { getInvitationStatusText, getRoleTranslationKey, sortMembershipsByInvitationStatus } from "../utils"

const useOrgMembershipsTable = (orgId: string) => {
  const dispatch = useAppDispatch()
  const translations = useTranslations()
  const membershipActions = useMembershipActions()
  const { enqueueSnackbar } = useSnackbar()
  const [openPermissionsDialog, setOpenPermissionsDialog] = useState<boolean>(false)
  const [filter, setFilter] = useState<MembershipFilter>("active")
  const [actionState, setActionState] = useState<ActionState>("idle")
  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const { activeMemberships, inactiveMemberships } = useOrgMemberships(orgId)
  const filteredMemberships = filter === "active" ? activeMemberships : inactiveMemberships
  const sortedMemberships = sortMembershipsByInvitationStatus(filteredMemberships)
  const membershipIds = sortedMemberships.map((membership) => membership.id)
  const selectedMemberships = React.useMemo(
    () => activeMemberships.filter((m) => selectedRows.includes(m.id)),
    [selectedRows, activeMemberships]
  )
  const permissions = useAppSelector((s) => getManyMembershipPermissions(s, selectedRows))
  const { canUpdateAnyMembershipPermissions, canUpdateAnyMembershipStatus } = useAuthUserMembership()

  const showManagePermissionsButton = filter === "active"
  const selectedRowCount = selectedRows.length

  const onFilterChange = (filter: MembershipFilter) => {
    setSelectedRows([])
    setFilter(filter)
  }

  const { confirm: confirmDeactivateMemberships } = useConfirmDialog({
    onConfirm: async () => {
      setActionState("busy")
      await dispatch(DeactivateMemberships({ orgId, membershipIds: selectedRows }))
      setSelectedRows([])
      setActionState("idle")
    },
    primaryActionButtonLabel: translations.deactivateBtnLabel.replace("{{count}}", `${selectedRowCount}`),
    title: translations.deactivateDialogTitle,
    text: translations.deactivateDialogText.replace("{{count}}", `${selectedRowCount}`),
  })

  const { confirm: confirmActivateMemberships } = useConfirmDialog({
    onConfirm: async () => {
      setActionState("busy")
      await dispatch(ActivateMemberships({ orgId, membershipIds: selectedRows }))
      setSelectedRows([])
      setActionState("idle")
    },
    primaryActionButtonLabel: translations.activateBtnLabel.replace("{{count}}", `${selectedRowCount}`),
    title: translations.activateDialogTitle,
    text: translations.activateDialogText.replace("{{count}}", `${selectedRowCount}`),
  })

  const updateAbilities = async ({ abilitiesToAdd = [], abilitiesToRemove = [] }: UpdateAbilitiesArg) => {
    const [error] = await doublet(membershipActions.updateAbilties, {
      orgId,
      membershipId: selectedRows,
      abilitiesToAdd,
      abilitiesToRemove,
    })
    if (error) enqueueSnackbar(error.message, { variant: "error" })
  }

  React.useEffect(() => {
    if (openPermissionsDialog) {
      dispatch(FetchManyMembershipPermissions({ organisationId: orgId, membershipIds: selectedRows }))
    }
  }, [dispatch, orgId, selectedRows, openPermissionsDialog])

  return {
    permissions,
    canUpdateAnyMembershipPermissions,
    canUpdateAnyMembershipStatus,
    filter,
    setFilter,
    selectedRows,
    setSelectedRows,
    actionState,
    setActionState,
    openPermissionsDialog,
    setOpenPermissionsDialog,
    selectedMemberships,
    showManagePermissionsButton,
    onFilterChange,
    confirmActivateMemberships,
    confirmDeactivateMemberships,
    selectedRowCount,
    membershipIds,
    translations,
    updateAbilities,
  }
}

const OrgMembershipsTable = ({ orgId }: { orgId: string }) => {
  const classes = useStyles({})
  const {
    permissions,
    canUpdateAnyMembershipPermissions,
    canUpdateAnyMembershipStatus,
    filter,
    selectedRows,
    setSelectedRows,
    actionState,
    openPermissionsDialog,
    setOpenPermissionsDialog,
    showManagePermissionsButton,
    onFilterChange,
    confirmActivateMemberships,
    confirmDeactivateMemberships,
    selectedRowCount,
    membershipIds,
    translations,
    updateAbilities,
  } = useOrgMembershipsTable(orgId)

  return (
    <div className={classes.tableRoot}>
      <MuiToolbar variant="dense" disableGutters>
        <Grid container justifyContent={"space-between"}>
          {
            <Grid item>
              {showManagePermissionsButton && (
                <Button
                  size="small"
                  onClick={() => setOpenPermissionsDialog(true)}
                  color="primary"
                  disabled={selectedRowCount === 0 || !canUpdateAnyMembershipPermissions}
                >
                  {translations.managePermissionsButtonText}
                </Button>
              )}
              {canUpdateAnyMembershipStatus && (
                <Button
                  size="small"
                  onClick={async () => {
                    filter === "active" ? confirmDeactivateMemberships() : confirmActivateMemberships()
                  }}
                  disabled={selectedRowCount === 0 || actionState === "busy"}
                >
                  {filter === "active"
                    ? translations.deactivateBtnLabel.replace("{{count}}", `${selectedRowCount}`)
                    : translations.activateBtnLabel.replace("{{count}}", `${selectedRowCount}`)}
                </Button>
              )}
            </Grid>
          }
          <Grid item>
            <FormControl>
              <Select
                variant="outlined"
                value={filter}
                onChange={(e) => onFilterChange(e.target.value as MembershipFilter)}
              >
                <MenuItem value={"active"}>{translations.activeMembershipsOption}</MenuItem>
                <MenuItem value={"inactive"}>{translations.inactiveMembershipsOption}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MuiToolbar>
      <Dialog open={openPermissionsDialog} onClose={() => setOpenPermissionsDialog(false)} maxWidth="md">
        <div style={{ width: "100%", height: "100%" }}>
          <Typography variant="h6" gutterBottom style={{ padding: "20px 0 16px 16px" }}>
            {translations.updatePermissionDialogHeaderText}
          </Typography>
          {openPermissionsDialog && (
            <AbilitiesTable groupedAbilities={adaptPermissions(permissions)} onUpdateAbilities={updateAbilities} />
          )}
        </div>
      </Dialog>
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="customers table">
          <TableHead>
            <TableRow>
              {(canUpdateAnyMembershipPermissions || canUpdateAnyMembershipStatus) && (
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRows([...membershipIds])
                      } else {
                        setSelectedRows([])
                      }
                    }}
                    checked={Boolean(selectedRows.length && selectedRows.length === membershipIds.length)}
                    indeterminate={Boolean(selectedRows.length && selectedRows.length !== membershipIds.length)}
                  />
                </TableCell>
              )}
              <TableCell padding="checkbox">{translations.avatarHeaderText}</TableCell>
              <TableCell padding="checkbox">{translations.nameHeaderText}</TableCell>
              <TableCell padding="checkbox">{translations.emailHeaderText}</TableCell>
              <TableCell padding="checkbox">{translations.roleHeaderText}</TableCell>
              <TableCell padding="checkbox">{translations.invitationStatusHeaderText}</TableCell>
              {filter === "inactive" && (
                <TableCell padding="checkbox">{translations.deactivatedOnHeaderText}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {membershipIds.map((membershipId) => (
              <MembershipRow
                key={membershipId}
                membershipId={membershipId}
                setSelectedRows={setSelectedRows}
                selected={selectedRows.includes(membershipId)}
                isSelectable={canUpdateAnyMembershipPermissions || canUpdateAnyMembershipStatus}
              />
            ))}
          </TableBody>
        </Table>
        {!membershipIds.length && (
          <Typography className="noData" color="textSecondary" variant="subtitle1">
            {translations.noMembershipsText}
          </Typography>
        )}
      </TableContainer>
    </div>
  )
}

const MembershipRow = ({ membershipId, selected, setSelectedRows, isSelectable }: MembershipRowProps) => {
  const translations = useTranslations()
  const { membership } = useMembership(membershipId)
  const { createPathWithGivenContext } = useUrlWithContext()
  const classes = useStyles({ isConnectedToUser: membership.isConnectedToUser })
  const { fullname, userEmail, role, userInitials, invitationStatus } = membership
  const membershipLink = createPathWithGivenContext({
    path: paths.home(),
    mainContextId: membership.orgId,
    subContextId: membershipId,
  })
  const { dateFormat } = useDateFormat()

  const formattedReactivationDate = membership.reactivationDate
    ? format(new Date(membership.reactivationDate), dateFormat)
    : null
  const reactivationInfo =
    membership.isDeactivated && !membership.isDeactivatedBefore30Days
      ? `${translations.reactivationInfo} ${formattedReactivationDate}`
      : null

  return (
    <TableRow className={classes.row} data-test={`member-row-${membership.userEmail}`}>
      {isSelectable && (
        <TableCell padding="checkbox">
          <Checkbox
            size="small"
            color="primary"
            checked={selected}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedRows((prev) => uniq([...prev, membershipId]))
              } else {
                setSelectedRows((prev) => without(prev, membershipId))
              }
            }}
          />
        </TableCell>
      )}
      <TableCell padding="checkbox">
        <Avatar className={classes.listItemAvatar}>{userInitials}</Avatar>
      </TableCell>
      <TableCell>
        <MuiLink component={Link} to={membershipLink}>
          {fullname}
        </MuiLink>
      </TableCell>
      <TableCell padding="checkbox">{userEmail}</TableCell>
      <TableCell padding="checkbox" data-test="member-role">
        {membership.isConnectedToUser ? translations[getRoleTranslationKey(role)] : translations.noAccessText}
      </TableCell>
      <TableCell padding="checkbox" data-test="member-invitation-status">
        {getInvitationStatusText(invitationStatus, translations)}
      </TableCell>

      {membership.isDeactivated && (
        <>
          <TableCell padding="checkbox">{format(new Date(membership.deactivationDate), dateFormat)}</TableCell>
          <TableCell padding="checkbox">
            {!membership.isDeactivatedBefore30Days && (
              <Tooltip title={reactivationInfo || ""}>
                <Info size={20} style={{ marginRight: 8 }} />
              </Tooltip>
            )}
          </TableCell>
        </>
      )}
    </TableRow>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  listItemAvatar: {
    background: theme.palette.error.main,
    fontWeight: theme.typography.subtitle1.fontWeight,
  },
  header: {
    padding: theme.spacing(2, 0),
  },
  tableRoot: {
    maxWidth: 1440,
    borderRadius: 7,
    padding: theme.spacing(1, 0),
    width: "100%",
    background: theme.palette.common.white,
    "& .MuiToolbar-root": {
      padding: theme.spacing(0, 2),
      "& .MuiButton-root": {
        marginRight: theme.spacing(),
        transition: theme.transitions.create("background"),
        background: alpha(theme.palette.primary.main, 0.05),
        "&:hover": {
          background: alpha(theme.palette.primary.main, 0.1),
        },
      },
      "& .MuiSelect-outlined": {
        padding: "6px 9px",
        paddingRight: 32,
        fontSize: 14,
      },
    },
    "& .MuiTableContainer-root": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      "& .MuiTableRow-root th": {
        textTransform: "uppercase",
        fontSize: 12,
        color: theme.palette.text.secondary,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      "& .MuiTableCell-paddingCheckbox": {
        padding: theme.spacing(0.5),
        width: "auto",
      },
      "& .noData": {
        textAlign: "center",
        padding: 20,
      },
    },
  },
  row: {
    backgroundColor: (props: { isConnectedToUser?: boolean }) => {
      return props.isConnectedToUser === true ? "rgba(0, 128, 0, 0.1)" : "inherit"
    },
  },
}))

type MembershipRowProps = {
  membershipId: string
  selected: boolean
  setSelectedRows: React.Dispatch<React.SetStateAction<string[]>>
  isSelectable: boolean
}
type MembershipFilter = "active" | "inactive"
type ActionState = "idle" | "busy"
export default OrgMembershipsTable

type UpdateAbilitiesArg = {
  abilitiesToAdd?: string[]
  abilitiesToRemove?: string[]
}
