import { AxiosRequestConfig } from "axios"
import api from "../services/api-service"
import { WorkspaceStatusType } from "./constants"
import { IWorkspaceResponse } from "./interfaces/workspace-response"

export const fetchWorkspaceById = (workspaceId: string, config: AxiosRequestConfig = {}) => {
  return api.get<IWorkspaceResponse>(`/workspaces/${workspaceId}`, config)
}

export const fetchWorkspaces = (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.get<IWorkspaceResponse[]>(`/orgs/${orgId}/workspaces`, config)
}

export const createWorkspace = (orgId: string, workspaceData: CreateWorkspaceData, config: AxiosRequestConfig = {}) => {
  return api.post<IWorkspaceResponse>(`/orgs/${orgId}/workspaces`, workspaceData, config)
}

export const updateWorkspaceInfo = (
  workspaceId: string,
  updateData: WorkspaceInfoUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<IWorkspaceResponse>(`/workspaces/${workspaceId}`, updateData, config)
}

export const addManager = (workspaceId: string, managerId: string, config: AxiosRequestConfig = {}) => {
  return api.post<IWorkspaceResponse>(`/workspaces/${workspaceId}/managers`, { managerId }, config)
}

export const removeManager = (workspaceId: string, managerId: string, config: AxiosRequestConfig = {}) => {
  return api.delete<IWorkspaceResponse>(`/workspaces/${workspaceId}/managers/${managerId}`, config)
}

export const addMember = (workspaceId: string, memberId: string, config: AxiosRequestConfig = {}) => {
  return api.post<IWorkspaceResponse>(`/workspaces/${workspaceId}/members`, { memberId }, config)
}

export const removeMember = (workspaceId: string, memberId: string, config: AxiosRequestConfig = {}) => {
  return api.delete<IWorkspaceResponse>(`/workspaces/${workspaceId}/members/${memberId}`, config)
}

export const updateWorkspaceStatus = (
  customerId: string,
  updateData: WorkspaceStatusUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<IWorkspaceResponse>(`/workspaces/${customerId}/status`, updateData, config)
}

export type CreateWorkspaceData = {
  name: string
  managers?: string[]
  members?: string[]
}
export type WorkspaceInfoUpdateData = {
  name: string
}
export type WorkspaceStatusUpdateData = {
  status: WorkspaceStatusType
}
