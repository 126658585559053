import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import IconButton from "@material-ui/core/IconButton"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import Typography from "@material-ui/core/Typography"
import LinkForm from "./LinkForm"
import { LinkViewModel } from "../api/link"
import { useGetLinksQuery } from "../api"
import { Trash, Edit } from "react-feather"
import { COLOR_DIVIDER } from "../../constants"
import { useLinkMutations } from "../hooks/use-link-mutations"
import { useConfirmDialog } from "../../components/ConfirmDialog"
import { useI18n } from "../../hooks"

const newLinkId = "new-link"
const initialLinkData = { title: "", url: "https://" } as LinkViewModel
const queryOptions = { refetchOnMountOrArgChange: true }

const LinkList = (props: LinkListProps) => {
  const translations = useTranslations()
  const { entityId, entityType, canAddLink, canUpdateLinks, canDeleteLinks } = props
  const { data: links = [], isLoading } = useGetLinksQuery({ entityId, entityType }, queryOptions)
  const { createLink, updateLink, deleteLink } = useLinkMutations()
  const [openLinkId, setOpenLinkId] = React.useState<string | null>(null)
  const { confirm } = useConfirmDialog({
    onConfirm: deleteLink,
    primaryActionButtonLabel: translations.delete,
    title: translations.deleteLink,
    text: translations.deleteLinkText,
  })

  if (isLoading) return <CircularProgress />

  const linkCount = links.length
  const defaultLinkData = { ...initialLinkData, entityId, entityType }
  const dialogLink = links.find((link) => link.id === openLinkId) || defaultLinkData
  const isEditing = Boolean(openLinkId) && openLinkId !== newLinkId

  const createLinkAndCloseDialog = ({ title, url }: { title: string; url: string }) => {
    createLink({ title, url, entityId, entityType }).then((created) => created && setOpenLinkId(null))
  }

  const addLinkButton = (
    <Button size="small" variant="outlined" color="primary" onClick={() => setOpenLinkId(newLinkId)}>
      {translations.addNewLink}
    </Button>
  )

  const linksEmptyMessage = (
    <Typography style={{ textAlign: "center", marginBottom: 12 }} component="p">
      {translations.linksEmptyMessage}
    </Typography>
  )

  return (
    <>
      <Typography variant="subtitle1" gutterBottom>
        <strong>{translations.links}</strong>
      </Typography>
      <List
        dense
        style={{ maxWidth: "560px", border: linkCount ? `1px solid ${COLOR_DIVIDER}` : "none", borderRadius: 4 }}
      >
        {links.map((link, index) => {
          return (
            <ListItem key={link.id} divider={index !== linkCount - 1}>
              <div style={{ left: "12px" }} onClick={(e) => e.stopPropagation()}>
                {canUpdateLinks ? (
                  <Tooltip title={translations.editLink}>
                    <IconButton color="inherit" onClick={() => setOpenLinkId(link.id)} size="small">
                      <Edit size={18} />
                    </IconButton>
                  </Tooltip>
                ) : null}
                {canDeleteLinks ? (
                  <Tooltip title={translations.deleteLink}>
                    <IconButton color="inherit" size="small" onClick={() => confirm(link.id)} style={{ marginLeft: 4 }}>
                      <Trash size={18} />
                    </IconButton>
                  </Tooltip>
                ) : null}
              </div>
              <a
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                style={{ position: "relative", left: 16, color: "inherit", textDecoration: "none" }}
              >
                <ListItemText primary={link.title} primaryTypographyProps={{ color: "primary" }} />
              </a>
            </ListItem>
          )
        })}
      </List>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: linkCount ? undefined : "center",
          py: 2,
          borderRadius: 4,
          border: linkCount ? "none" : `1px dashed ${COLOR_DIVIDER}`,
        }}
      >
        {linkCount ? null : linksEmptyMessage}
        {canAddLink ? <Box>{addLinkButton}</Box> : null}
      </Box>
      <Dialog
        open={Boolean(openLinkId)}
        onClose={() => setOpenLinkId(null)}
        aria-labelledby={isEditing ? "edit-link-dialog" : "add-link-dialog"}
        fullWidth
      >
        <DialogTitle id={isEditing ? "edit-link-dialog" : "add-link-dialog"}>
          {isEditing ? translations.editLink : translations.addNewLink}
        </DialogTitle>
        <DialogContent>
          <LinkForm
            link={dialogLink}
            onAddLink={createLinkAndCloseDialog}
            onUpdate={updateLink}
            isEditing={isEditing}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LinkList

const defaultTranslations = {
  delete: "Delete",
  deleteLink: "Delete link",
  deleteLinkText: "Are you sure you want to delete this link?",
  linksEmptyMessage: "There are no links here yet.",
  editLink: "Edit link",
  addNewLink: "Add a new link",
  addLink: "Add link",
  links: "Links",
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const {
    deleteLink = defaults.deleteLink,
    deleteLinkText = defaults.deleteLinkText,
    linksEmptyMessage = defaults.linksEmptyMessage,
    editLink = defaults.editLink,
    addNewLink = defaults.addNewLink,
    addLink = defaults.addLink,
    links = defaults.links,
  } = t || {}

  return {
    delete: t.deleteLabel || defaults.delete,
    deleteLink,
    deleteLinkText,
    linksEmptyMessage,
    editLink,
    addNewLink,
    addLink,
    links,
  }
}

type Translations = typeof defaultTranslations
type LinkListProps = {
  entityId: string
  entityType: "project" | "task"
  canAddLink: boolean
  canUpdateLinks: boolean
  canDeleteLinks: boolean
}
