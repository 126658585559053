import React from "react"
import { useLocalStorage } from "../../hooks"

const filterKey = `is_filter_row_visible`

export const useTreeGridFilters = ({ gridId }: { gridId: string }) => {
  const [showFilters, setShowFilters] = useLocalStorage(gridId + filterKey, false)
  const toggleFilters = React.useCallback(() => setShowFilters(!showFilters), [showFilters, setShowFilters])
  const grid = window.Grids[gridId] as any
  const toolbarRow = grid?.Toolbar as any
  const filterRow = grid?.GetRowById("filterRow")

  // This effect applies the active class to the filter button if the filters are shown
  // And refreshes the toolbar row so the changes are reflected in the grid
  React.useEffect(() => {
    if (!grid || !toolbarRow) return
    toolbarRow.FilterButtonClass = showFilters ? "Filter_Button_Active" : "Filter_Button"
    grid.RefreshRow(toolbarRow)
  }, [showFilters, grid, toolbarRow])

  // This effect Shows/Hides the filter row in the grid depending on the showFilters state
  React.useEffect(() => {
    if (!grid || !filterRow) return
    if (showFilters) grid.ShowRow(filterRow)
    else grid.HideRow(filterRow)
  }, [showFilters, grid, filterRow])

  return {
    showFilters,
    toggleFilters,
  }
}
