import * as actions from "./actions"
import { createSlice } from "@reduxjs/toolkit"
import { IUserNormalized } from "../interfaces/user-normalized"
import {
  accessTokenRefreshedCase,
  accessTokenRefreshFailedCase,
  refreshingAccessTokenCase,
  resetAuthStateCase,
  setAuthStateCase,
  setRefreshTokenCase,
  setUserCase,
} from "./case-reducers"

const refreshTokenInStorage = localStorage.getItem("starbrix_refresh_token")
const parsedRefreshToken = refreshTokenInStorage ? JSON.parse(refreshTokenInStorage) : null

const initialState: AuthState = {
  isAuthenticated: parsedRefreshToken != null,
  user: null,
  refreshToken: parsedRefreshToken,
  accessToken: null,
  tokenExpiresAt: null,
  tokenRefreshStatus: "idle",
  authError: null,
}

const usersSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    SetAuthState: setAuthStateCase,
    SetRefreshToken: setRefreshTokenCase,
  },
  extraReducers({ addCase }) {
    addCase(actions.FetchUser.fulfilled, setUserCase)
    addCase(actions.VerifyAccount.fulfilled, setUserCase)
    addCase(actions.RefreshAccessToken.pending, refreshingAccessTokenCase)
    addCase(actions.RefreshAccessToken.fulfilled, accessTokenRefreshedCase)
    addCase(actions.RefreshAccessToken.rejected, accessTokenRefreshFailedCase)
    addCase(actions.UpdateUserInfo.fulfilled, setUserCase)
    addCase(actions.ResetAuthState.toString(), resetAuthStateCase)
    addCase(actions.ClearStorageAndAuthState.fulfilled, resetAuthStateCase)
  },
})

export const { SetAuthState, SetRefreshToken } = usersSlice.actions
export default usersSlice.reducer

export type AuthState = {
  isAuthenticated: boolean
  user: null | IUserNormalized
  refreshToken: null | string
  accessToken: null | string
  tokenExpiresAt: null | number
  tokenRefreshStatus?: "idle" | "pending" | "fulfilled" | "rejected"
  authError: string | null
}
