import authReducer from "./users/store"
import invitationsReducer from "./invitations/store"
import organisationsReducer from "./organisations/store"
import workspacesReducer from "./workspaces/store"
import customersReducer from "./customers/store"
import suppliersReducer from "./suppliers/store"
import membershipsReducer from "./memberships/store"
import optionsReducer from "./options/store"
import permissionsReducer from "./permissions/store"
import contextOptionsReducer from "./context-options/store"
import rolesReducer from "./roles/store"

import { createSelectorHook, useDispatch } from "react-redux"
import { combineReducers, configureStore, getDefaultMiddleware } from "@reduxjs/toolkit"
import { axiosInstance } from "./services/api-service"
import { Action, ThunkAction } from "@reduxjs/toolkit"
import { ResetAuthState } from "./users/store/actions"
import { api } from "./api"

export const rootReducer = combineReducers({
  auth: authReducer,
  customers: customersReducer,
  invitations: invitationsReducer,
  memberships: membershipsReducer,
  orgs: organisationsReducer,
  suppliers: suppliersReducer,
  workspaces: workspacesReducer,
  options: optionsReducer,
  permissions: permissionsReducer,
  contextOptions: contextOptionsReducer,
  roles: rolesReducer,
  [api.reducerPath]: api.reducer,
})

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }).concat(api.middleware),
})

store.subscribe(() => {
  const { accessToken } = store.getState().auth
  if (accessToken) {
    axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`
  }
})

store.subscribe(() => {
  const { authError } = store.getState().auth
  if (authError) store.dispatch(ResetAuthState())
})

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector = createSelectorHook<RootState>()

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>
export type AppStore = typeof store

export default store
