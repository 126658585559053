import { TodosViewOptions } from "../../treegrid/todo-list/TodoList"
import { TreeGridColors } from "../../treegrid/types"
import { AppMainContextType, AppSubContextType } from "../../types/app-context"
import { TodoResponse } from "./todo-response"

export const makeTodoViewModel = (todoResponse: TodoResponse): TodoViewModel => {
  const isUserTodo = todoResponse.organisation === null
  const isOrgTodo = todoResponse.organisation !== null
  const isCompleted = todoResponse.completed !== null
  const title = todoResponse.title === "Untitled" ? "" : todoResponse.title
  const ganttBarColor = todoResponse.ganttBarColor || "Default"

  const checkIsVisibleInView = (view: TodosViewOptions) => {
    if (view === "all") return true
    if (view === "completed") return isCompleted
    return !isCompleted
  }

  const checkIsVisibleInContext = (context: AppContextType, userMembershipIds: string[]) => {
    if (!context) return false
    const responsibleIds = todoResponse.responsible.map((r) => r.id)
    const customerIds = todoResponse.customers.map((c) => c.id)
    const workspaceIds = todoResponse.workspaces.map((w) => w.id)

    if (context.type === "user") {
      const isUserCreator = isUserTodo && context.id === todoResponse.creatorUserId
      const isUserResponsible = responsibleIds.some((rId) => userMembershipIds.includes(rId))
      return isUserCreator || isUserResponsible
    }
    if (context.type === "org") return todoResponse.organisation?.id === context.id
    if (context.type === "customer") return customerIds.includes(context.id)
    if (context.type === "member") return responsibleIds.includes(context.id)
    if (context.type === "workspace") return workspaceIds.includes(context.id)

    return false
  }

  return {
    ...todoResponse,
    ganttBarColor,
    title,
    isUserTodo,
    isOrgTodo,
    isCompleted,
    checkIsVisibleInView,
    checkIsVisibleInContext,
  }
}

export type TodoViewModel = TodoResponse & {
  ganttBarColor: TreeGridColors
  isOrgTodo: boolean
  isUserTodo: boolean
  isCompleted: boolean
  checkIsVisibleInView: (view: TodosViewOptions) => boolean
  checkIsVisibleInContext: (context: AppContextType, userMembershipIds: string[]) => boolean
}

type AppContextType = AppMainContextType | AppSubContextType | null
