import { PayloadAction } from "@reduxjs/toolkit"
import { RolesState } from "."
import { RoleNormalized } from "../../organisations/interfaces/role-normalized"
import { keyBy } from "lodash"

export const setRoleCase = (state: RolesState, action: PayloadAction<RoleNormalized>) => {
  const role = action.payload
  const { id } = role
  state.byIds[id] = role
  if (!state.allIds.includes(id)) state.allIds.push(id)
}

export const setRolesCase = (state: RolesState, action: PayloadAction<RoleNormalized[]>) => {
  const roles = action.payload
  const incomingRoles = keyBy(roles, "id") || {}
  state.byIds = {
    ...state.byIds,
    ...incomingRoles,
  }
  roles.forEach((role) => {
    if (!state.allIds.includes(role.id)) state.allIds.push(role.id)
  })
}
