import { useSnackbar } from "notistack"
import * as todoApi from "../api"
import { useI18n } from "../../hooks/use-i18n"
import { StringMap } from "../../types/common"

export const useTodoMutations = () => {
  const translations = useTranslations()
  const { enqueueSnackbar } = useSnackbar()
  const [createTodoMutation] = todoApi.useCreateTodoMutation()
  const [updateInfoMutation] = todoApi.useUpdateTodoInfoMutation()
  const [updateStatusMutation] = todoApi.useUpdateTodoStatusMutation()
  const [updateProjectMutation] = todoApi.useUpdateTodoProjectMutation()
  const [updateTaskMutation] = todoApi.useUpdateTodoTaskMutation()
  const [updateWorkspacesMutation] = todoApi.useUpdateTodoWorkspacesMutation()
  const [updateResponsibleMutation] = todoApi.useUpdateTodoResponsibleMutation()
  const [updateCustomersMutation] = todoApi.useUpdateTodoCustomersMutation()
  const [deleteTodoMutation] = todoApi.useDeleteTodoMutation()

  const handleError = (error: any) => {
    const errorMessage = "error" in error ? error.error : "message" in error ? error.message : "Unknown error"
    enqueueSnackbar(errorMessage, { variant: "error" })
  }

  const createTodo = async (newTodoData: todoApi.NewTodoData) => {
    return await createTodoMutation(newTodoData).unwrap().catch(handleError)
  }

  const updateInfo = async (todoId: string, updateData: todoApi.TodoInfoUpdateData) => {
    return await updateInfoMutation({ todoId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateStatus = async (todoId: string, updateData: todoApi.TodoStatusUpdateData) => {
    return await updateStatusMutation({ todoId, ...updateData })
      .unwrap()
      .then((todo) => {
        const message = updateData.completed
          ? translations.todoCompletedSuccessMessage
          : translations.todoUncompletedSuccessMessage
        enqueueSnackbar(message, { variant: "success" })
        return todo
      })
      .catch(() => {
        const message = updateData.completed
          ? translations.todoCompletedErrorMessage
          : translations.todoUncompletedErrorMessage
        enqueueSnackbar(message, { variant: "error" })
      })
  }

  const updateProject = async (todoId: string, updateData: todoApi.TodoProjectUpdateData) => {
    return await updateProjectMutation({ todoId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateTask = async (todoId: string, updateData: todoApi.TodoTaskUpdateData) => {
    return await updateTaskMutation({ todoId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateWorkspaces = async (todoId: string, updateData: todoApi.TodoWorkspaceUpdateData) => {
    return await updateWorkspacesMutation({ todoId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateResponsible = async (todoId: string, updateData: todoApi.TodoResponsibleUpdateData) => {
    return await updateResponsibleMutation({ todoId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const updateCustomers = async (todoId: string, updateData: todoApi.TodoCustomerUpdateData) => {
    return await updateCustomersMutation({ todoId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const deleteTodo = async (todoId: string) => {
    return await deleteTodoMutation(todoId)
      .unwrap()
      .then(() => {
        enqueueSnackbar(translations.deleteTodoSuccessMessage, { variant: "success" })
        return true
      })
      .catch(() => {
        enqueueSnackbar(translations.deleteTodoErrorMessage, { variant: "error" })
        return false
      })
  }

  const initTodoUpdate = async (todoId: string, updateData: InitTodoUpdateData) => {
    switch (updateData.field) {
      case "title":
      case "description":
      case "dueDate":
      case "enableTimeComponent":
      case "ganttBarColor":
        return updateInfo(todoId, { [updateData.field]: updateData.value })
      case "project":
        return updateProject(todoId, { projectId: updateData.value, boardId: updateData.boardId })
      case "responsible":
        return updateResponsible(todoId, { responsible: updateData.value })
      case "task":
        return updateTask(todoId, { taskId: updateData.value, boardId: updateData.boardId })
      case "workspaces":
        return updateWorkspaces(todoId, { workspaces: updateData.value, boardId: updateData.boardId })
      case "customers":
        return updateCustomers(todoId, { customers: updateData.value, boardId: updateData.boardId })
      case "completed":
        return updateStatus(todoId, { completed: Boolean(updateData.value), boardId: updateData.boardId })
      default:
        // @ts-expect-error
        console.error(`Field ${updateData.field} can not be updated via initTodoUpdate`) // eslint-disable-line
    }
  }

  return {
    createTodo,
    updateInfo,
    updateStatus,
    updateProject,
    updateTask,
    updateWorkspaces,
    updateResponsible,
    updateCustomers,
    deleteTodo,
    initTodoUpdate,
  }
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("todo")
  const deleteTodoDialogTranslations = (t?.deleteTodoDialog || {}) as StringMap
  const deleteTodoDialog = {
    title: deleteTodoDialogTranslations?.title || defaults.deleteTodoDialog.title,
    text: deleteTodoDialogTranslations?.text || defaults.deleteTodoDialog.text,
    primaryActionButtonLabel:
      deleteTodoDialogTranslations?.primaryActionButtonLabel || defaults.deleteTodoDialog.primaryActionButtonLabel,
  }

  const {
    deleteTodoSuccessMessage = defaults.deleteTodoSuccessMessage,
    deleteTodoErrorMessage = defaults.deleteTodoErrorMessage,
    todoCompletedSuccessMessage = defaults.todoCompletedSuccessMessage,
    todoCompletedErrorMessage = defaults.todoCompletedErrorMessage,
    todoUncompletedSuccessMessage = defaults.todoUncompletedSuccessMessage,
    todoUncompletedErrorMessage = defaults.todoUncompletedErrorMessage,
  } = t

  return {
    deleteTodoDialog,
    deleteTodoSuccessMessage,
    deleteTodoErrorMessage,
    todoCompletedSuccessMessage,
    todoCompletedErrorMessage,
    todoUncompletedSuccessMessage,
    todoUncompletedErrorMessage,
  }
}

const defaultTranslations = {
  deleteTodoDialog: {
    title: "Do you want to delete this todo?",
    text: "This action will erase the todo from database and it can not be recovered.",
    primaryActionButtonLabel: "Delete todo",
  },
  deleteTodoSuccessMessage: "Todo deleted",
  deleteTodoErrorMessage: "Failed to delete todo",
  todoCompletedSuccessMessage: "Todo marked as completed",
  todoCompletedErrorMessage: "Failed to mark todo as completed",
  todoUncompletedSuccessMessage: "Todo marked as not completed",
  todoUncompletedErrorMessage: "Failed to mark todo as not completed",
}

type Translations = typeof defaultTranslations

type InitTodoUpdateData =
  | {
      field: "title"
      value: string
    }
  | {
      field: "description" | "dueDate" | "ganttBarColor"
      value: string | null
    }
  | {
      field: "project" | "task" | "completed"
      value: string | null
      boardId?: string
    }
  | {
      field: "responsible" | "workspaces" | "customers"
      value: string[]
      boardId?: string
    }
  | {
      field: "enableTimeComponent"
      value: boolean
    }
