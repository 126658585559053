import api from "../../services/api-service"
import { AxiosRequestConfig } from "axios"
import { makeMembershipApiRoutes } from "../../memberships/utils/make-api-routes"
import { MembershipPermissionResponse } from "../interfaces/membership-permission"
import { IMembershipResponse } from "../../memberships/interfaces/membership-response"

const membershipApiRoutes = makeMembershipApiRoutes()

export const fetchMembershipPermissions = (membershipId: string, config: AxiosRequestConfig = {}) => {
  return api.get<MembershipPermissionResponse>(membershipApiRoutes.membershipPermissions(membershipId), config)
}

export const fetchManyMembershipPermissions = (orgId: string, config: AxiosRequestConfig = {}) => {
  return api.get<MembershipPermissionResponse[]>(membershipApiRoutes.orgMembershipPermissions(orgId), config)
}

export const updateMembershipPermissions = (
  membershipId: string,
  updateData: MembershipPermissionsUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<MembershipPermissionResponse>(
    membershipApiRoutes.membershipPermissions(membershipId),
    updateData,
    config
  )
}

export const updateManyMembershipPermissions = (
  orgId: string,
  updateData: ManyMembershipPermissionsUpdateData,
  config: AxiosRequestConfig = {}
) => {
  const membershipId = updateData.membershipIds
  return api.put<MembershipPermissionResponse[]>(
    membershipApiRoutes.membershipsPermissions(),
    { orgId, ...updateData, membershipId },
    config
  )
}

export const updateMembershipAbilities = (
  updateData: MembershipAbilitiesUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<MembershipPermissionResponse[]>(membershipApiRoutes.membershipsPermissions(), updateData, config)
}

export const updateMemberAssociations = (
  membershipId: string,
  updateData: MemberAssociationsUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<MembershipPermissionResponse>(membershipApiRoutes.memberAssociations(membershipId), updateData, config)
}

export const updateMemberAbilitiesInResources = (
  membershipId: string,
  updateData: MemberAbilitiesInResourcesUpdateData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<MembershipPermissionResponse>(
    membershipApiRoutes.memberAbilitiesInResources(membershipId),
    updateData,
    config
  )
}

export const copyMembershipPermissions = (
  membershipId: string,
  updateData: MembershipPermissionsCopyData,
  config: AxiosRequestConfig = {}
) => {
  return api.put<CopyPermissionsResponse>(
    membershipApiRoutes.membershipCopyPermissions(membershipId),
    updateData,
    config
  )
}

export type MembershipPermissionsUpdateData = {
  abilitiesToAdd: string[]
  abilitiesToRemove: string[]
  resourceId?: string
}

export type ManyMembershipPermissionsUpdateData = {
  membershipIds: string[]
  abilitiesToAdd: string[]
  abilitiesToRemove: string[]
  resourceId?: string
}

export type MembershipAbilitiesUpdateData = {
  membershipId: string | string[]
  orgId: string
  abilitiesToAdd?: string[]
  abilitiesToRemove?: string[]
}

export type MemberAbilitiesInResourcesUpdateData = {
  resourceId: string
  abilitiesToAdd?: string[]
  abilitiesToRemove?: string[]
}

export type MemberAssociationsUpdateData = {
  associationsToAdd?: string[]
  associationsToRemove?: string[]
}

export type MembershipPermissionsCopyData = {
  copyPermissionsFrom: string
}

type CopyPermissionsResponse = {
  membership: IMembershipResponse
  membershipPermission: MembershipPermissionResponse
}
