import FirstDayOfWeekSelectWidget from "../../components/FirstDayOfWeekSelectWidget"
import TimeZoneSelectWidget from "../../components/TimeZoneSelectWidget"
import WeekendDaysSelectWidget from "../../components/WeekendDaysSelectWidget"
import MembersTable from "../../components/MembersTable"
import AbilitiesTable from "../../components/AbilitiesTable/AbilitiesTable"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import Checkbox from "@material-ui/core/Checkbox"
import Dialog from "@material-ui/core/Dialog"
import DialogTitle from "@material-ui/core/DialogTitle"
import Divider from "@material-ui/core/Divider"
import FormControl from "@material-ui/core/FormControl"
import Grid from "@material-ui/core/Grid"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import Typography from "@material-ui/core/Typography"
import EnableHolidaysWidget from "./EnableHolidaysWidget"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { StringMap } from "i18next"
import { ChevronDown, MinusSquare } from "react-feather"
import { COLOR_PRIMARY, COLOR_WHITE } from "../../constants"
import { useI18n } from "../../hooks"
import { ChangeEvent, useState } from "react"
import { ProjectInfoUpdateData } from "../api"
import { concat, sortBy, without } from "lodash"
import { useAuthUserMembership } from "../../memberships/hooks/use-auth-user-membership"
import { ProjectViewModel } from "../api/project"
import { MembershipViewModel } from "../../memberships/api/membership"
import { ProjectPermissionRecord } from "../api/project-permission-response"
import { useProjectMutations } from "../hooks/use-project-mutations"
import { useIsSmallScreen } from "../../hooks/use-is-small-screen"

type ProjectSettingsViewProps = {
  project: ProjectViewModel
  projectPermissions: ProjectPermissionRecord[]
  memberships: MembershipViewModel[]
}

const useProjectSettingsView = ({
  project,
  projectPermissions: permissions,
  memberships,
}: ProjectSettingsViewProps) => {
  const projectId = project.id
  const projectActions = useProjectMutations()
  const organisationId = project.isOrgProject ? project.maintainerId : undefined
  const { canUpdateAnyMembershipPermissions } = useAuthUserMembership(organisationId)

  const [membersFilter, setMembersFilter] = useState<MembersFilterType>("all")

  const onChangeMembersFilter = (e: ChangeEvent<{ value: unknown }>) => {
    const val = e.target.value as MembersFilterType
    setMembersFilter(val)
    setSelectedMembers([])
  }
  const projectCreatorMembership = memberships.find((membership) => membership.userId === project.creatorId)
  const projectResources = [...project.managers, ...project.participants].map(({ id }) => id)
  if (projectCreatorMembership) {
    projectResources.push(projectCreatorMembership.userId)
  }
  const activeMemberRows = memberships
    .filter(($m) => !$m.isDeactivated)
    .map((membership) => ({
      id: membership.id,
      userId: membership.userId,
      initials: membership.userInitials,
      fullname: membership.fullname,
      email: membership.userEmail,
      disabled: membership.isOwner,
    }))
  const memberRowsToShow = sortBy(
    activeMemberRows.filter((row) => {
      const isResource = projectResources.includes(row.id) || projectResources.includes(row.userId)

      if (membersFilter === "resources") return isResource
      if (membersFilter === "nonResources") return !isResource
      return true
    }),
    "disabled"
  )
  const editableMembers = memberships.filter(
    (membership) => !membership.isOwner && memberRowsToShow.some((r) => r.id === membership.id)
  )
  const [selectedMembers, setSelectedMembers] = useState<string[]>([])
  const selectedMembersNames = memberships.filter((m) => selectedMembers.includes(m.id)).map((m) => m.fullname)
  const selectedCount = selectedMembers.length
  const editableMembersCount = editableMembers.length
  const selectAllChecked = !!selectedCount && selectedCount === editableMembersCount
  const selectAllIndeterminate = !!selectedCount && selectedCount !== editableMembersCount
  const selectAllDisabled = !editableMembersCount

  const onRowSelect = (id: string) => {
    if (selectedMembers.includes(id)) {
      setSelectedMembers(without(selectedMembers, id))
    } else {
      setSelectedMembers(concat(selectedMembers, id))
    }
  }
  const onSelectAll = () => {
    if (selectedCount === editableMembersCount) {
      setSelectedMembers([])
    } else {
      setSelectedMembers(editableMembers.map((membership) => membership.id))
    }
  }
  const [dialogOpen, setDialogOpen] = useState<boolean>(false)
  const openPermissionsDialog = () => {
    setDialogOpen(true)
  }
  const closePermissionsDialog = () => {
    setDialogOpen(false)
  }
  const onClickManagePermissionsButton = (id?: string) => {
    if (id) setSelectedMembers([id])
    openPermissionsDialog()
  }
  const groupedAbilities: any = groupAbilities(
    permissions.filter(($permission) => selectedMembers.includes($permission.membershipId))
  )

  const onUpdateProjectInfo = async (projectData: ProjectInfoUpdateData) => {
    await projectActions.updateInfo(projectId, projectData)
  }

  const onUpdateAbilities = async ({
    abilitiesToAdd,
    abilitiesToRemove,
  }: {
    abilitiesToAdd?: string[]
    abilitiesToRemove?: string[]
  }) => {
    await projectActions.updatePermissions(projectId, {
      membershipIds: selectedMembers,
      abilitiesToAdd,
      abilitiesToRemove,
    })
  }

  return {
    project,
    groupedAbilities,
    memberRowsToShow,
    membersFilter,
    selectedMembers,
    selectedMembersNames,
    selectAllChecked,
    selectAllDisabled,
    selectAllIndeterminate,
    dialogOpen,
    onRowSelect,
    onSelectAll,
    openPermissionsDialog,
    closePermissionsDialog,
    onChangeMembersFilter,
    onUpdateProjectInfo,
    onClickManagePermissionsButton,
    onUpdateAbilities,
    canUpdateAnyMembershipPermissions,
  }
}

const ProjectSettingsView = (props: ProjectSettingsViewProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const isSmallScreen = useIsSmallScreen()
  const {
    project: projectData,
    memberRowsToShow,
    membersFilter,
    selectedMembers,
    selectedMembersNames,
    selectAllChecked,
    selectAllDisabled,
    selectAllIndeterminate,
    dialogOpen,
    groupedAbilities,
    onSelectAll,
    onRowSelect,
    openPermissionsDialog,
    closePermissionsDialog,
    onChangeMembersFilter,
    onUpdateProjectInfo,
    onClickManagePermissionsButton,
    onUpdateAbilities,
    canUpdateAnyMembershipPermissions,
  } = useProjectSettingsView(props)

  return (
    <Grid container direction="column" style={{ maxWidth: 840, margin: "auto" }}>
      <Grid item container direction="row" className={classes.daysSection}>
        <WeekendDaysSelectWidget
          weekendDays={projectData.weekendDays || [6, 0]}
          onWeekendDaysChange={onUpdateProjectInfo}
          disabled={!projectData.canUpdateDetails}
        />
        <FirstDayOfWeekSelectWidget
          firstDayOfWeek={projectData.firstDayOfWeek}
          onFirstDayOfWeekChange={onUpdateProjectInfo}
          disabled={!projectData.canUpdateDetails}
        />
        <TimeZoneSelectWidget
          timeZone={projectData.timeZone}
          onTimeZoneChange={onUpdateProjectInfo}
          disabled={!projectData.canUpdateDetails}
        />
      </Grid>
      <Divider />
      <Grid item container direction="row" className={classes.daysSection}>
        <EnableHolidaysWidget
          enableHolidays={projectData.enableHolidays}
          isOrgProject={projectData.isOrgProject}
          onEnableHolidayChange={({ enableHolidays }) => onUpdateProjectInfo({ enableHolidays })}
          disabled={!projectData.canUpdateDetails}
        />
      </Grid>

      {projectData.isOrgProject && canUpdateAnyMembershipPermissions && (
        <>
          <Grid item className={classes.permissionsHeader}>
            <Typography component="header" variant="h6" gutterBottom>
              {translations.permissionsSectionTitle}
            </Typography>
            <Typography className="subheader">{translations.permissionsSubheader}</Typography>
          </Grid>
          <Grid item>
            {isSmallScreen ? (
              <MemberCards members={memberRowsToShow} onClickManagePermissions={onClickManagePermissionsButton} />
            ) : (
              <>
                <div className={classes.toolbar}>
                  <div className="left">
                    <Checkbox
                      checked={selectAllChecked}
                      color="primary"
                      indeterminate={selectAllIndeterminate}
                      indeterminateIcon={<MinusSquare color={COLOR_PRIMARY} />}
                      className="checkbox"
                      onChange={onSelectAll}
                      disabled={selectAllDisabled}
                    />
                    {!selectedMembers.length && (
                      <Typography variant="subtitle2" component="span">
                        {translations.selectAllCheckboxLabel}
                      </Typography>
                    )}
                    {!!selectedMembers.length && (
                      <Button color="primary" variant="contained" size="small" onClick={openPermissionsDialog}>
                        {translations.managePermissionsBtnLabel}
                      </Button>
                    )}
                  </div>
                  <div className="right">
                    <FormControl variant="outlined" className={classes.formControl}>
                      <Select
                        value={membersFilter}
                        onChange={onChangeMembersFilter}
                        IconComponent={ChevronDown}
                        autoWidth
                      >
                        <MenuItem value="all">{translations.allMembersLabel}</MenuItem>
                        <MenuItem value="resources">{translations.resourcesLabel}</MenuItem>
                        <MenuItem value="nonResources">{translations.nonResourcesLabel}</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>

                <MembersTable
                  memberRows={memberRowsToShow}
                  selectedMemberships={selectedMembers}
                  onManagePermissionsClick={onClickManagePermissionsButton}
                  onRowSelect={onRowSelect}
                />
              </>
            )}
            <Dialog open={dialogOpen} onClose={closePermissionsDialog} maxWidth="md">
              <div className={classes.dialogContentWrapper}>
                <DialogTitle>
                  {translations.dialogTitle.replace("{{members}}", selectedMembersNames.join(", "))}
                </DialogTitle>
                <Divider />
                <AbilitiesTable groupedAbilities={groupedAbilities} onUpdateAbilities={onUpdateAbilities} />
              </div>
            </Dialog>
          </Grid>
        </>
      )}
    </Grid>
  )
}

const MemberCards = ({
  members,
  onClickManagePermissions,
}: {
  members: MemberRow[]
  onClickManagePermissions: (id: string) => void
}) => {
  const translations = useTranslations()
  return (
    <>
      {members.map((member) => (
        <Paper key={member.id} style={{ padding: 16, marginBottom: 8 }} variant="outlined">
          <Typography variant="h6">{member.fullname}</Typography>
          <Typography variant="body2" color="textSecondary">
            {member.email}
          </Typography>
          <Button
            style={{ marginLeft: -8, marginTop: 16 }}
            color="primary"
            onClick={() => onClickManagePermissions(member.id)}
          >
            {translations.managePermissionsBtnLabel}
          </Button>
        </Paper>
      ))}
    </>
  )
}

const useTranslations = (defaults = defaultPermissions) => {
  const { translations: t } = useI18n("project")
  const translations = (t.projectSettingsPage || {}) as StringMap

  const {
    pageTitle = defaults.pageTitle,
    permissionsSectionTitle = defaults.permissionsSectionTitle,
    backButtonLabel = defaults.backButtonLabel,
    permissionsSubheader = defaults.permissionsSubheader,
    selectAllCheckboxLabel = defaults.selectAllCheckboxLabel,
    managePermissionsBtnLabel = defaults.managePermissionsBtnLabel,
    allMembersLabel = defaults.allMembersLabel,
    resourcesLabel = defaults.resourcesLabel,
    nonResourcesLabel = defaults.nonResourcesLabel,
    dialogTitle = defaults.dialogTitle,
  } = translations

  return {
    pageTitle,
    permissionsSectionTitle,
    backButtonLabel,
    permissionsSubheader,
    selectAllCheckboxLabel,
    managePermissionsBtnLabel,
    allMembersLabel,
    resourcesLabel,
    nonResourcesLabel,
    dialogTitle,
  }
}

const defaultPermissions = {
  pageTitle: "Project settings",
  backButtonLabel: "Back to project",
  permissionsSectionTitle: "Project permissions",
  permissionsSubheader: `You can manage permissions for project resources from here.
    Also you can give permission to to perform certain actions 
    to other members of the organisation who is not a resource in this project.`,
  selectAllCheckboxLabel: "Select all",
  managePermissionsBtnLabel: "Manage permissions",
  allMembersLabel: "All members",
  resourcesLabel: "Project resources",
  nonResourcesLabel: "Not project resources",
  dialogTitle: "Managing permissions of {{members}}",
}

const useStyles = makeStyles((theme: Theme) => ({
  permissionsHeader: {
    margin: theme.spacing(1.5, 0),
    padding: theme.spacing(2),
    "& .headerRow": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: theme.spacing(2, 0),
    },
    "& .subheader": {
      textAlign: "justify",
    },
  },
  permissions: {
    paddingBottom: theme.spacing(10),
  },
  daysSection: {
    backgroundColor: COLOR_WHITE,
  },
  pageTitle: {
    margin: theme.spacing(2, 0),
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "white",
    padding: theme.spacing(1.5),
    borderTopLeftRadius: theme.spacing(),
    borderTopRightRadius: theme.spacing(),
    border: `1px solid ${theme.palette.divider}`,
    borderBottomColor: "transparent",
    "& .checkbox": {
      marginRight: 20,
    },
  },
  formControl: {
    "& .MuiOutlinedInput-root": {
      background: theme.palette.grey[200],
    },
    "& .MuiSelect-icon": {
      width: 20,
      height: 20,
      color: theme.palette.common.black,
      top: "calc(50% - 8px)",
    },
  },
  dialogContentWrapper: {
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      minWidth: theme.spacing(80),
    },
  },
}))

const groupAbilities = (
  permissions: ProjectPermissionRecord[]
): Array<{ resource: string; abilities: { [key: string]: boolean }[] }> => {
  return [
    {
      resource: "project",
      abilities: permissions.map(($permission) => ({
        ReadProject: $permission.canRead,
        DeleteProject: $permission.canDelete,
        UpdateProjectDetails: $permission.canUpdateDetails,
        UpdateProjectPlan: $permission.canUpdatePlan,
        UpdateProjectStatus: $permission.canUpdateStatus,
        UpdateProjectManagers: $permission.canUpdateManagers,
        UpdateProjectParticipants: $permission.canUpdateParticipants,
        UpdateProjectCustomers: $permission.canUpdateCustomers,
        UpdateProjectWorkspaces: $permission.canUpdateWorkspaces,
        UpdateProjectSuppliers: $permission.canUpdateSuppliers,
        UpdateProjectPermissions: $permission.canUpdatePermissions,
        ManageProjectNotifications: $permission.canManageProjectNotifications,
      })),
    },
    {
      resource: "task",
      abilities: permissions.map(($permission) => ({
        CreateProjectTasks: $permission.canCreateProjectTasks,
      })),
    },
    {
      resource: "calendarEvent",
      abilities: permissions.map(($permission) => ({
        CreateProjectCalendarEvents: $permission.canCreateProjectCalendarEvents,
      })),
    },
    {
      resource: "todo",
      abilities: permissions.map(($permission) => ({
        CreateProjectTodos: $permission.canCreateProjectTodos,
      })),
    },
  ]
}

type MembersFilterType = "all" | "resources" | "nonResources"
export default ProjectSettingsView

type MemberRow = {
  id: string
  userId: string
  initials: string
  fullname: string
  email: string
  disabled?: boolean
}
