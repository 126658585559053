import React from "react"
import { TimeZoneType } from "../../constants/timezones"
import { useRouter } from "../../hooks"
import { ProjectViewModel } from "../../projects/api/project"
import { useTranslations } from "../hooks/use-translations"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { makeTreegridLayout } from "./layout"
import { paths } from "../../paths"
import { makeTreeGridTemplateRows } from "./rows"
import { createTreegrid, destroyTreegrid } from "../utils/tree-grid"

const id = "__treegrid_template_list__"

const useTemplateList = ({ canCreateProjects }: TemplateListProps) => {
  const translations = useTranslations()
  const { history } = useRouter()
  const [navigateTo, setNavigateTo] = React.useState<string | null>(null)
  const { createPathWithContext } = useUrlWithContext()

  React.useEffect(() => {
    if (navigateTo) history.push(createPathWithContext(navigateTo))
    return () => setNavigateTo(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateTo])

  const layout = makeTreegridLayout({ id, canCreateProjects, translations })

  window.Grids.OnClick = function (grid, row, col) {
    if (col === "open") {
      const isProjectRow = row.Def.Name !== "Group"
      if (isProjectRow) setNavigateTo(paths.projectBasic(row.id))
    }
    if (col === "AddProject") setNavigateTo(paths.newProject())
  }

  return { layout }
}

const TempalateList = (props: TemplateListProps) => {
  const { layout } = useTemplateList(props)

  React.useEffect(() => {
    const data = makeTreeGridTemplateRows({ ...props })
    createTreegrid({ id, data, layout })
    return () => destroyTreegrid(id)
  })

  return <div id={id} style={{ height: "calc(100vh - 128px)" }}></div>
}

type TemplateListProps = {
  projects: ProjectViewModel[]
  canCreateProjects: boolean
  timeZone: TimeZoneType
  dateFormat: string
}

export default TempalateList
