import * as actions from "./actions"
import * as orgActions from "../../organisations/store/actions"
import { createSlice } from "@reduxjs/toolkit"
import { setOptionsCase, deleteOrganisationCase, setOptionCase, removeOptionCase } from "./case-reducer"
import { IOptionNormalized } from "../interfaces/options-normalized"

const initialState: OptionsState = {
  byIds: {},
  allIds: [],
}

export const optionsSlice = createSlice({
  name: "options",
  initialState,
  reducers: {
    SetOption: setOptionCase,
    RemoveOption: removeOptionCase,
  },
  extraReducers: (builder) => {
    builder.addCase(actions.FetchOptions.fulfilled, setOptionsCase)
    builder.addCase(actions.FetchUserOptions.fulfilled, setOptionsCase)
    builder.addCase(orgActions.DeleteOrganisation.fulfilled, deleteOrganisationCase)
  },
})

export default optionsSlice.reducer
export const { SetOption, RemoveOption } = optionsSlice.actions

export type OptionsState = {
  byIds: { [id: string]: IOptionNormalized }
  allIds: string[]
}
