import React from "react"

export const useAddedRows = <T>({ gridId }: { gridId: string }) => {
  const grid = window.Grids[gridId]
  const [addedRows, setAddedRows] = React.useState<Array<T & AddedRow>>([])

  React.useEffect(() => {
    if (!grid || !addedRows.length) return
    const rowToFocus = grid.GetRowById(addedRows.find((r) => r.focus)?.id || "")
    const rowToScroll = grid.GetRowById(addedRows.find((r) => r.scrollTo)?.id || "")
    if (rowToFocus) grid.Focus(rowToFocus, "title")
    if (rowToScroll) grid.ScrollToCell(rowToScroll, "title", 0)
  }, [addedRows, grid])

  return {
    addedRows,
    setAddedRows,
    isAddedRow: (id: string) => addedRows.some((r) => r.id === id),
  }
}

export type AddedRow = { id: string; focus: boolean; scrollTo: boolean }
