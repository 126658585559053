import { useContextOptions } from "../context-options/hooks/use-context-options"
import { useRouter } from "./use-router"

export const useUrlWithContext = () => {
  const { contextOptions } = useContextOptions()
  const query = useRouter().query as { mainContext?: string; subContext?: string }

  const createPathWithGivenContext = (params: { path: string; mainContextId?: string; subContextId?: string }) => {
    const url = new URL(params.path, window.location.origin)
    const userOption = contextOptions.find((option) => option.type === "user")
    if (params.mainContextId) {
      url.searchParams.set("mainContext", params.mainContextId)
      if (params.subContextId) url.searchParams.set("subContext", params.subContextId)
    } else {
      url.searchParams.set("mainContext", userOption?.id || "")
    }
    return `${url.pathname}${url.search}`
  }

  const createPathWithContext = (path: string, options?: { withSubContext: boolean }) => {
    const mainContextId = query.mainContext
    const subContextId = query.subContext

    const shouldNotCreatePathWithSubContext = options?.withSubContext === false || !subContextId
    if (shouldNotCreatePathWithSubContext) {
      return createPathWithGivenContext({
        path,
        mainContextId,
      })
    }

    return createPathWithGivenContext({
      path,
      mainContextId,
      subContextId,
    })
  }

  return {
    createPathWithGivenContext,
    createPathWithContext,
  }
}
