import { TimeZoneType } from "../../constants/timezones"
import { DateTimeService } from "../../services/date-time-service"
import {
  getColorHtmlForColor,
  getStateIconHtml,
  makeEnumKeysFromStatusOptions,
  makeEnumString,
  mapColorToTranslations,
} from "../utils"
import { TreeGridProject, TreeGridProjectCellPermissions } from "./types"
import { sortBy } from "lodash"
import { IOptionNormalized } from "../../options/interfaces/options-normalized"
import { getCustomerOptions, getMembershipOptions, getSupplierOptions, getWorkspaceOptions } from "../../options/utils"
import { isProjectPlannedEndDatePastDue, isProjectPlannedStartDatePastDue } from "../../projects/utils/date-utils"
import { ProjectViewModel } from "../../projects/api/project"
import { TreeGridTranslations } from "../hooks/use-translations"
import { mapStatusToTranslations } from "../utils"
import { getGanttGanttHtmlRight } from "../utils/tree-grid"
import { Status } from "../../types/common"

export const makeTreegridProjectRows = ({ projects, ...rest }: MakeRowsProps): TreeGridProject[] => {
  return projects.map((project) => makeTreeGridProjectRow({ project, ...rest }))
}

export const makeTreeGridProjectRow = (props: MakeProjectRowProps): TreeGridProject => {
  const { project, dateFormat, timeZone, options, translations } = props
  const { id, title, description, statusDescription, enableTimeComponent } = project
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const dateTimeFormat = dateTimeService.getFormat()
  const projectNumber = project.customProjectNumber || project.projectNumber
  const customers = project.customers.map((c) => c.id).join(";")
  const participants = project.participants.map((p) => p.id).join(";")
  const managers = project.managers.map((m) => m.id).join(";")
  const suppliers = project.suppliers.map((s) => s.id).join(";")
  const workspaces = project.workspaces.map((w) => w.id).join(";")
  const plannedStartDate = getPlannedStartDate() // number of milliseconds since epoch, or null
  const plannedEndDate = getPlannedEndDate() // number of milliseconds since epoch, or null
  const actualStartDate = getActualStartDate() // number of milliseconds since epoch, or null
  const actualEndDate = getActualEndDate() // number of milliseconds since epoch, or null
  const actualBarStartDate = getActualBarStartDate() // number of milliseconds since epoch, or null
  const actualBarEndDate = getActualBarEndDate() // number of milliseconds since epoch, or null
  const rejectedDate = getRejectedDate() // number of milliseconds since epoch, or null
  const cellPermissions = getTreeGridProjectCellPermissions()
  const state = getStateIconHtml(project.state) // html for the state icon
  const plannedStartDateClass = getPlannedStartDateClass() // css class for rendering red text if project is past due
  const plannedEndDateClass = getPlannedEndDateClass() // css class for rendering red text if project is past due
  const statusDescriptionTip = getStatusDescriptionTip() // Text showing the user who made the last update and when it was made
  const status = mapStatusToTranslations(translations)[project.customStatus as Status] || project.customStatus
  const statusEnum = getStatusEnum()
  const statusEnumKeys = makeEnumKeysFromStatusOptions(project.statusOptions)

  const ganttGanttHtmlRight = getGanttGanttHtmlRight({
    title: project.title,
    startDate: project.plannedStartDate,
    endDate: project.plannedEndDate,
    dateTimeService,
    showTime: enableTimeComponent,
  })
  const GanttGanttHtmlRight = "*ganttGanttHtmlRight*"
  const translatedColor = mapColorToTranslations(translations)[project.ganttBarColor]
  const ganttBarColor = getColorHtmlForColor({ color: project.ganttBarColor, translatedColor })
  const GanttGanttClass = project.ganttBarColor === "Default" ? "Blue" : project.ganttBarColor

  const membershipOptions = getMembershipOptions(options).filter(({ orgId }) => project.maintainerId === orgId)
  const managerNames = membershipOptions.map(({ name }) => name)
  const managerIds = membershipOptions.map(({ id }) => id)
  const deactivatedManagers = project.managers.filter(({ id }) => !managerIds.includes(id))
  const deactivatedManagersNames = deactivatedManagers.map(({ name }) => name)
  const deactivatedManagersIds = deactivatedManagers.map(({ id }) => id)
  const managersEnum = makeEnumString([...managerNames, ...deactivatedManagersNames])
  const managersEnumKeys = makeEnumString([...managerIds, ...deactivatedManagersIds])

  const participantOptions = membershipOptions.filter(({ id }) => !managers.includes(id))
  const participantNames = participantOptions.map(({ name }) => name)
  const participantIds = participantOptions.map(({ id }) => id)
  const deactivatedParticipants = project.participants.filter(({ id }) => !participantIds.includes(id))
  const deactivatedParticipantsNames = deactivatedParticipants.map(({ name }) => name)
  const deactivatedParticipantsIds = deactivatedParticipants.map(({ id }) => id)
  const participantsEnum = makeEnumString([...participantNames, ...deactivatedParticipantsNames])
  const participantsEnumKeys = makeEnumString([...participantIds, ...deactivatedParticipantsIds])

  const supplierOptions = getSupplierOptions(options).filter(({ orgId }) => project.maintainerId === orgId)
  const supplierNames = supplierOptions.map(({ name }) => name)
  const supplierIds = supplierOptions.map(({ id }) => id)
  const deactivatedSuppliers = project.suppliers.filter(({ id }) => !supplierIds.includes(id))
  const deactivatedSuppliersNames = deactivatedSuppliers.map(({ name }) => name)
  const deactivatedSuppliersIds = deactivatedSuppliers.map(({ id }) => id)
  const suppliersEnum = makeEnumString([...supplierNames, ...deactivatedSuppliersNames])
  const suppliersEnumKeys = makeEnumString([...supplierIds, ...deactivatedSuppliersIds])

  const workspaceOptions = getWorkspaceOptions(options).filter(({ orgId }) => project.maintainerId === orgId)
  const workspaceNames = workspaceOptions.map(({ name }) => name)
  const workspaceIds = workspaceOptions.map(({ id }) => id)
  const deactivatedWorkspaces = project.workspaces.filter(({ id }) => !workspaceIds.includes(id))
  const deactivatedWorkspacesNames = deactivatedWorkspaces.map(({ name }) => name)
  const deactivatedWorkspacesIds = deactivatedWorkspaces.map(({ id }) => id)
  const workspacesEnum = makeEnumString([...workspaceNames, ...deactivatedWorkspacesNames])
  const workspacesEnumKeys = makeEnumString([...workspaceIds, ...deactivatedWorkspacesIds])

  const customerOptions = getCustomerOptions(options).filter(({ orgId }) => project.maintainerId === orgId)
  const customerNames = customerOptions.map(({ name }) => name)
  const customerIds = customerOptions.map(({ id }) => id)
  const deactivatedCustomers = project.customers.filter(({ id }) => !customerIds.includes(id))
  const deactivatedCustomersNames = deactivatedCustomers.map(({ name }) => name)
  const deactivatedCustomersIds = deactivatedCustomers.map(({ id }) => id)
  const customersEnum = makeEnumString([...customerNames, ...deactivatedCustomersNames])
  const customersEnumKeys = makeEnumString([...customerIds, ...deactivatedCustomersIds])

  const linksHtml = project.links.map((link) => link.anchorTag).join(", ")

  return {
    id,
    open: "/external-link-icon.svg",
    title,
    description,
    status,
    state,
    statusDescription,
    projectNumber,
    customers,
    participants,
    managers,
    suppliers,
    workspaces,
    plannedStartDate,
    plannedEndDate,
    actualStartDate,
    actualEndDate,
    actualBarStartDate,
    actualBarEndDate,
    rejectedDate,
    plannedStartDateClass,
    plannedEndDateClass,
    statusDescriptionTip,
    enableTimeComponent,
    GanttGanttHtmlRight,
    ganttGanttHtmlRight,
    ganttBarColor,
    ganttBarColorFilterValue: translatedColor,
    GanttGanttClass,
    plannedStartDateFormat: dateTimeFormat,
    plannedEndDateFormat: dateTimeFormat,
    actualStartDateFormat: dateTimeFormat,
    actualEndDateFormat: dateTimeFormat,
    rejectedDateFormat: dateTimeFormat,
    managersEnum,
    managersEnumKeys,
    participantsEnum,
    participantsEnumKeys,
    suppliersEnum,
    suppliersEnumKeys,
    workspacesEnum,
    workspacesEnumKeys,
    customersEnum,
    customersEnumKeys,
    links: linksHtml,
    Height: 48,
    MaxHeight: 48,
    ...cellPermissions,
    statusEnum,
    statusEnumKeys,
    completionPercentage: project.completionPercentage,
    completionPercentageHtmlPostfix: "%",
    completionPercentageBasedOnTasks: project.completionPercentageBasedOnTasks,
    completionPercentageBasedOnTasksHtmlPostfix: "%",
  }

  function getActualStartDate(): number | string {
    return project.actualStartDate ? dateTimeService.removeTimezoneOffset(project.actualStartDate, "UTC").getTime() : ""
  }

  function getActualEndDate(): number | string {
    return project.actualEndDate ? dateTimeService.removeTimezoneOffset(project.actualEndDate, "UTC").getTime() : ""
  }

  function getActualBarStartDate(): number | string {
    return getActualStartDate()
  }

  function getActualBarEndDate(): number | string {
    const now = new Date().toISOString()
    return project.isInProgress ? dateTimeService.removeTimezoneOffset(now, "UTC").getTime() : getActualEndDate()
  }

  function getPlannedStartDate(): number | string {
    return project.plannedStartDate
      ? dateTimeService.removeTimezoneOffset(project.plannedStartDate, "UTC").getTime()
      : ""
  }

  function getPlannedEndDate(): number | string {
    return project.plannedEndDate ? dateTimeService.removeTimezoneOffset(project.plannedEndDate, "UTC").getTime() : ""
  }

  function getRejectedDate(): number | string {
    return project.rejectedDate ? dateTimeService.removeTimezoneOffset(project.rejectedDate, "UTC").getTime() : ""
  }

  function getPlannedStartDateClass(): string {
    const isPastDue = isProjectPlannedStartDatePastDue({ project, dateTimeService })
    return isPastDue ? "redText" : ""
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isProjectPlannedEndDatePastDue({ project, dateTimeService })
    return isPastDue ? "redText" : ""
  }

  function getStatusDescriptionTip() {
    const sortedUpdates = sortBy(project.statusDescriptionUpdates, "updatedAt")
    const latestUpdate = sortedUpdates[sortedUpdates.length - 1]
    let tip = ""
    if (latestUpdate) {
      const formatStr = `${dateFormat} HH:mm`
      const { updatedBy, updatedAt } = latestUpdate
      const offsetDate = dateTimeService.removeTimezoneOffset(updatedAt)
      // if there is a latest update
      tip += updatedBy // include the user who made the update
      tip += "&nbsp;&nbsp;&bull;&nbsp;&nbsp;" // some spacing
      tip += dateTimeService.format(offsetDate, formatStr) // and formatted date of update
    }
    return tip
  }

  function getStatusEnum() {
    return makeEnumString(
      project.statusOptions.map(({ label }) => {
        return mapStatusToTranslations(translations)[label as Status] || label
      })
    )
  }

  function getTreeGridProjectCellPermissions(): TreeGridProjectCellPermissions {
    return {
      titleCanEdit: project.canUpdateDetails ? 1 : 0,
      descriptionCanEdit: project.canUpdateDetails ? 1 : 0,
      ganttBarColorCanEdit: project.canUpdateDetails ? 1 : 0,
      projectNumberCanEdit: project.canUpdateDetails ? 1 : 0,
      plannedStartDateCanEdit: project.canUpdatePlan ? 1 : 0,
      plannedEndDateCanEdit: project.canUpdatePlan ? 1 : 0,
      statusCanEdit: project.canUpdateStatus ? 1 : 0,
      stateCanEdit: project.canUpdateStatus ? 1 : 0,
      statusDescriptionCanEdit: project.canUpdateStatus ? 1 : 0,
      actualEndDateCanEdit: project.canUpdateStatus ? 1 : 0,
      actualStartDateCanEdit: project.canUpdateStatus ? 1 : 0,
      managersCanEdit: project.canUpdateManagers ? 1 : 0,
      suppliersCanEdit: project.canUpdateSuppliers ? 1 : 0,
      workspacesCanEdit: project.canUpdateWorkspaces ? 1 : 0,
      participantsCanEdit: project.canUpdateParticipants ? 1 : 0,
      customersCanEdit: project.canUpdateCustomers ? 1 : 0,
      completionPercentageCanEdit: project.canUpdateStatus ? 1 : 0,
      completionPercentageBasedOnTasksCanEdit: 0,
    }
  }
}

type MakeProjectRowProps = {
  project: ProjectViewModel
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
}

type MakeRowsProps = {
  projects: ProjectViewModel[]
  dateFormat: string
  timeZone: TimeZoneType
  options: IOptionNormalized[]
  translations: TreeGridTranslations
}
