import ReminderForm from "./ReminderForm"
import Box from "@material-ui/core/Box"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import Tooltip from "@material-ui/core/Tooltip"
import { useReminderMutations } from "../hooks/use-reminder-mutations"
import { Check, Edit, Maximize2, MessageSquare, Trash, X } from "react-feather"
import { ReminderViewModel } from "../api/reminder"
import { Chip, makeStyles, Theme } from "@material-ui/core"
import { COLOR_LIGHT } from "../../constants"
import { useTranslations } from "../hooks/use-translations"
import { useReminderSummary } from "../hooks/use-reminder-summary"
import { upperFirst } from "lodash"
import { StatusOption } from "../../lib/status-options"

const Reminder = ({
  reminder,
  refStartDate,
  refEndDate,
  isExpanded,
  onCollapse,
  onExpand,
  statusOptions,
}: ReminderProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { deleteReminder } = useReminderMutations()
  const { recipientSummary, triggerSummary } = useReminderSummary({ reminder })

  if (isExpanded) {
    return (
      <Box className={classes.formContainer}>
        <IconButton
          className={classes.closeIcon}
          onClick={onCollapse}
          edge="end"
          aria-label="close editor"
          color="inherit"
        >
          <X />
        </IconButton>
        <ReminderForm
          reminder={reminder}
          refStartDate={refStartDate}
          refEndDate={refEndDate}
          statusOptions={statusOptions}
        />
      </Box>
    )
  }

  const secondary = (
    <div className={classes.secondaryTextWrapper}>
      {reminder.note ? (
        <div className={classes.noteWrapper}>
          <MessageSquare size={20} className={classes.messageIcon} />
          <Typography variant="body1" color="textPrimary" gutterBottom>
            {reminder.note}
          </Typography>
        </div>
      ) : null}
      <Typography variant="body2">{recipientSummary}</Typography>
    </div>
  )

  return (
    <ListItem key={reminder.id} className={classes.reminderListItem} divider>
      <ListItemText
        primary={upperFirst(triggerSummary)}
        secondary={secondary}
        secondaryTypographyProps={{ component: "div" }}
      />
      <ListItemSecondaryAction className={classes.reminderActions}>
        {reminder.isExpired && !reminder.sentAt ? (
          <Chip size="small" label={translations.expired} className={classes.expiredChip} />
        ) : null}
        {reminder.sentAt ? <Chip size="small" label={translations.sent} icon={<Check />} color="primary" /> : null}
        <Tooltip title={reminder.sentAt ? "" : translations.editNotification}>
          <IconButton onClick={onExpand} edge="end" aria-label={translations.editNotification} color="inherit">
            {reminder.sentAt ? <Maximize2 size={20} /> : <Edit size={20} />}
          </IconButton>
        </Tooltip>
        <Tooltip title={translations.deleteNotification}>
          <IconButton
            onClick={() => deleteReminder(reminder.id)}
            aria-label={translations.deleteNotification}
            color="inherit"
            edge="end"
          >
            <Trash size={20} />
          </IconButton>
        </Tooltip>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  formContainer: {
    position: "relative",
  },
  closeIcon: {
    display: "block",
    marginLeft: "auto",
    position: "absolute",
    top: 16,
    right: 40,
    zIndex: 1,
  },
  reminderListItem: {
    background: COLOR_LIGHT,
    marginBottom: 4,
    borderRadius: 4,
    "& .MuiListItemText-primary": {
      fontWeight: 500,
    },
    [theme.breakpoints.down("xs")]: {
      paddingBottom: 48,
    },
  },
  reminderActions: {
    [theme.breakpoints.down("xs")]: {
      position: "unset",
      inset: "unset",
      marginTop: -32,
      marginLeft: 12,
    },
  },
  expiredChip: {
    background: theme.palette.error.main,
    color: "white",
    marginRight: 8,
  },
  secondaryTextWrapper: { maxWidth: "80%", marginTop: 8 },
  noteWrapper: { display: "flex", marginBottom: 8, marginTop: 16 },
  messageIcon: { marginTop: 4, marginRight: 12, color: theme.palette.text.primary },
}))

export default Reminder

type ReminderProps = {
  reminder: ReminderViewModel
  refStartDate: Date | null
  refEndDate: Date | null
  isExpanded: boolean
  onCollapse: () => void
  onExpand: () => void
  statusOptions?: StatusOption[]
}
