import { cloneDeep } from "lodash"
import { ProjectViewModel } from "../api/project"

const getTime = (date: string | undefined) => (date ? new Date(date).getTime() : Infinity)

export const sortProjectsByPlan = (projects: ProjectViewModel[]): ProjectViewModel[] => {
  const cloned = cloneDeep(projects)
  return cloned.sort((a, b) => {
    const endTimeA = getTime(a.plannedEndDate)
    const endTimeB = getTime(b.plannedEndDate)

    if (endTimeA !== endTimeB) return endTimeA - endTimeB

    const startTimeA = getTime(a.plannedStartDate)
    const startTimeB = getTime(b.plannedStartDate)

    return startTimeA - startTimeB
  })
}
