import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  makeStyles,
  Theme,
} from "@material-ui/core"
import _ from "lodash"
import { AlertTriangle, AtSign, Globe, Phone } from "react-feather"
import { Alert, AlertTitle } from "@material-ui/lab"
import { StringMap } from "i18next"
import { useConfirmDialog } from "../../components/ConfirmDialog"
import { useNetworkStatus, useI18n } from "../../hooks"
import { languageMap } from "../../utils/languages"
import { IPersonCustomerNormalized } from "../interfaces/customer-normalized"

const PersonInfoCard = ({ customer, onChangeStatus }: PersonInfoCardProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const activationStatus = useNetworkStatus({ resetDelayInMS: 300 })
  const { phone, email, language, fullname, isActive, initials } = customer

  const confirmChangeStatusDialog = useConfirmDialog({
    onConfirm: onChangeStatusConfirmed,
    title: isActive ? translations.deactivationConfirmDialogTitle : translations.activationConfirmDialogTitle,
    text: isActive ? translations.deactivationConfirmDialogText : translations.activationConfirmDialogText,
  })
  async function onChangeStatusConfirmed() {
    activationStatus.setStatus("pending")
    try {
      const action: any = await onChangeStatus()
      const requestStatus = action?.meta?.requestStatus || "rejected"
      activationStatus.setStatus(requestStatus)
    } catch (error) {
      activationStatus.setStatus("rejected")
    }
  }

  let alertTitle = ""
  let alertMessage = ""
  let changeStatusButtonLabel = isActive
    ? translations.deactivateButtonLabel_idle
    : translations.activateButtonLabel_idle

  let activationButtonIcon: null | JSX.Element = null
  let progressIcon = <CircularProgress size={16} />

  if (activationStatus.isPending()) {
    changeStatusButtonLabel = !isActive
      ? translations.activateButtonLabel_pending
      : translations.deactivateButtonLabel_pending
    activationButtonIcon = progressIcon
  }

  if (activationStatus.isRejected()) {
    activationButtonIcon = <AlertTriangle size={20} />
    changeStatusButtonLabel = !isActive
      ? translations.activateButtonLabel_rejected
      : translations.deactivateButtonLabel_rejected
    alertTitle = !isActive ? translations.activationFailedAlertTitle : translations.deactivationFailedAlertTitle
    alertMessage = !isActive ? translations.activationFailedAlertMessage : translations.deactivationFailedAlertMessage
  }
  const activeChip = <Chip label={translations.activeChipLabel} size="small" color="secondary" />
  const inactiveChip = <Chip label={translations.inactiveChipLabel} size="small" disabled />
  const statusChip = isActive ? activeChip : inactiveChip

  return (
    <Card variant="outlined" className={classes.card}>
      <CardHeader
        avatar={<Avatar className={classes.avatar}>{initials}</Avatar>}
        title={_.upperFirst(_.truncate(fullname, { length: 20 }))}
        subheader={
          <span>
            {translations.personLabel} {statusChip}
          </span>
        }
        titleTypographyProps={{ variant: "subtitle1" }}
        subheaderTypographyProps={{
          variant: "body2",
          color: "textSecondary",
        }}
      />
      <Divider />
      <CardContent>
        <Grid container spacing={2} alignItems="center">
          <Grid item className={classes.icon}>
            <AtSign size={12} />
          </Grid>
          <Grid item>{email ? email : translations.noEmail}</Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item className={classes.icon}>
            <Phone size={12} />
          </Grid>
          <Grid item>{phone ? phone : translations.noPhoneNumber}</Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center">
          <Grid item className={classes.icon}>
            <Globe size={12} />
          </Grid>
          <Grid item>{language ? languageMap[language] : translations.noLanguagePreferred}</Grid>
        </Grid>
        {alertMessage && (
          <Alert severity="error">
            <AlertTitle>{alertTitle}</AlertTitle>
            {alertMessage}
          </Alert>
        )}
      </CardContent>
      <CardActions className={classes.cardActions}>
        {customer.canUpdate && (
          <Button
            variant="outlined"
            fullWidth
            className={classes.button}
            onClick={confirmChangeStatusDialog.confirm}
            startIcon={activationButtonIcon}
            data-test={`change-status-${isActive ? "deactivate" : "activate"}`}
          >
            {changeStatusButtonLabel}
          </Button>
        )}
      </CardActions>
    </Card>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = (t || {}) as StringMap

  const { translations: customerTranslations } = useI18n("customer")
  const customerHomePage = (customerTranslations?.customerHomePage || {}) as StringMap

  const {
    noEmail = defaults.noEmail,
    noPhoneNumber = defaults.noPhoneNumber,
    noLanguagePreferred = defaults.noLanguagePreferred,
  } = translations

  const {
    personLabel = defaults.personLabel,
    activeChipLabel = defaults.activeChipLabel,
    inactiveChipLabel = defaults.inactiveChipLabel,

    activateButtonLabel_idle = defaults.activateButtonLabel_idle,
    activateButtonLabel_pending = defaults.activateButtonLabel_pending,
    activateButtonLabel_rejected = defaults.activateButtonLabel_rejected,
    activationFailedAlertTitle = defaults.activationFailedAlertTitle,
    activationFailedAlertMessage = defaults.activationFailedAlertMessage,
    activationConfirmDialogTitle = defaults.activationConfirmDialogTitle,
    activationConfirmDialogText = defaults.activationConfirmDialogText,

    deactivateButtonLabel_idle = defaults.deactivateButtonLabel_idle,
    deactivateButtonLabel_pending = defaults.deactivateButtonLabel_pending,
    deactivateButtonLabel_rejected = defaults.deactivateButtonLabel_rejected,
    deactivationFailedAlertTitle = defaults.deactivationFailedAlertTitle,
    deactivationFailedAlertMessage = defaults.deactivationFailedAlertMessage,
    deactivationConfirmDialogTitle = defaults.deactivationConfirmDialogTitle,
    deactivationConfirmDialogText = defaults.deactivationConfirmDialogText,
  } = customerHomePage

  return {
    personLabel,
    noEmail,
    noPhoneNumber,
    noLanguagePreferred,
    activeChipLabel,
    inactiveChipLabel,
    activateButtonLabel_idle,
    activateButtonLabel_pending,
    activateButtonLabel_rejected,
    activationFailedAlertTitle,
    activationFailedAlertMessage,
    activationConfirmDialogTitle,
    activationConfirmDialogText,
    deactivateButtonLabel_idle,
    deactivateButtonLabel_pending,
    deactivateButtonLabel_rejected,
    deactivationFailedAlertTitle,
    deactivationFailedAlertMessage,
    deactivationConfirmDialogTitle,
    deactivationConfirmDialogText,
  }
}

const defaultTranslations = {
  personLabel: "Person",
  noEmail: "No email",
  noPhoneNumber: "No phone",
  noLanguagePreferred: "No language preferred",
  activateButtonLabel_idle: "Activate",
  activateButtonLabel_pending: "Activating",
  activateButtonLabel_rejected: "Failed",
  activationFailedAlertMessage: "Failed to deactivate",
  activationFailedAlertTitle: "Error",
  activationConfirmDialogText: `Do you want to re-activate this customer`,
  activationConfirmDialogTitle: "Activate customer",
  deactivateButtonLabel_idle: "Deactivate",
  deactivateButtonLabel_pending: "Deactivating",
  deactivateButtonLabel_rejected: "Failed",
  deactivationFailedAlertMessage: "Failed to deactivate",
  deactivationFailedAlertTitle: "Error",
  deactivationConfirmDialogText: `You will still be able to view this customer 
    in deactivated customers list and you will be able to reactivate
    this customer later if needed.`,
  deactivationConfirmDialogTitle: "Deactivate customer",
  activeChipLabel: "Active",
  inactiveChipLabel: "Inactive",
}

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    padding: theme.spacing(2.5),
    maxWidth: theme.spacing(40),
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  avatarContainer: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    backgroundColor: theme.palette.info.main,
    padding: theme.spacing(3),
    textTransform: "uppercase",
  },
  primaryText: {
    padding: theme.spacing(0.75),
  },
  secondaryText: {
    color: theme.palette.grey[500],
    padding: theme.spacing(0.75),
  },
  icon: {
    display: "flex",
    alignItems: "center",
  },
  cardActions: {
    display: "flex",
  },
  button: {
    minHeight: 38,
  },
}))

type PersonInfoCardProps = {
  customer: IPersonCustomerNormalized
  onChangeStatus: () => void
}

type Translations = typeof defaultTranslations
export default PersonInfoCard
