import React from "react"
import Button from "@material-ui/core/Button"
import Paper from "@material-ui/core/Paper"
import BoardCard from "./BoardCard"
import IconButton from "@material-ui/core/IconButton"
import TextareaAutosize from "@material-ui/core/TextareaAutosize"
import InlineEditableText from "../../components/InlineEditableText"
import Tooltip from "@material-ui/core/Tooltip"
import { Droppable } from "react-beautiful-dnd"
import { COLOR_BLACK, COLOR_LIGHT } from "../../constants"
import { makeStyles, Theme } from "@material-ui/core"
import { Plus, X } from "react-feather"
import { BoardColumnNameUpdateData, NewCardData } from "../api"
import { BoardPopulated, ColumnPopulated } from "../api/board"
import { getTranslatedDefaultColumnNames } from "../utils"
import { useTranslations } from "../hooks/use-translations"

const BoardColumn = ({
  board,
  column,
  canAddTasks,
  canAddTodos,
  onAddTask,
  onAddTodo,
  onSaveColumnName,
}: BoardColumnProps) => {
  const classes = useStyles()
  const [cardFormVisible, setCardFormVisible] = React.useState(false)
  const [newCardTitle, setNewCardTitle] = React.useState("")
  const translations = useTranslations()
  const showAddNewCardButton = !cardFormVisible && column.position < 3 && (canAddTasks || canAddTodos)
  const showCardForm = () => {
    setNewCardTitle("")
    setCardFormVisible(true)
  }
  const hideCardForm = () => {
    setNewCardTitle("")
    setCardFormVisible(false)
  }
  const onTitleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewCardTitle(e.target.value)
  }
  const onKeyDown = async (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.stopPropagation()
    if (e.key === "Enter") {
      if (canAddTasks) {
        await onAddTask({
          boardId: column.board,
          columnId: column.id,
          title: newCardTitle,
        })
      } else if (canAddTodos) {
        await onAddTodo({
          boardId: column.board,
          columnId: column.id,
          title: newCardTitle,
        })
      }
      setNewCardTitle("")
    }
  }

  const onClickAddTask = async () => {
    await onAddTask({
      boardId: column.board,
      columnId: column.id,
      title: newCardTitle,
    })
    hideCardForm()
  }
  const onClickAddTodo = async () => {
    await onAddTodo({
      boardId: column.board,
      columnId: column.id,
      title: newCardTitle,
    })
    hideCardForm()
  }

  const saveColumnName = (name: string) => {
    onSaveColumnName({
      boardId: column.board,
      columnId: column.id,
      name,
    })
  }

  const translatedDefaultColumn = getTranslatedDefaultColumnNames({ columnName: column.defaultName, translations })

  return (
    <>
      <div className={classes.container}>
        <span className={classes.columnTitle}>
          <InlineEditableText
            value={column.name || translatedDefaultColumn}
            onSave={saveColumnName}
            TypographyProps={{ variant: "h6" }}
            isMultiline={false}
          />
        </span>
        {showAddNewCardButton && (
          <Button size="small" startIcon={<Plus size={16} />} className={classes.addButton} onClick={showCardForm}>
            {translations.addCardButtonLabel}
          </Button>
        )}
        {cardFormVisible && column.position < 3 && (
          <div style={{ transition: "height 0.2s" }}>
            <Paper className={classes.cardPaper}>
              <TextareaAutosize
                value={newCardTitle}
                onChange={onTitleChange}
                placeholder={translations.cardTitlePlaceholder}
                className={classes.cardTextArea}
                onKeyDown={onKeyDown}
                autoFocus
              />
            </Paper>
            <div style={{ display: "flex", marginBottom: 12, justifyContent: "flex-start", flexWrap: "wrap" }}>
              {canAddTasks && (
                <Tooltip title={translations.addTaskButtonTip}>
                  <Button
                    color="primary"
                    size="small"
                    style={{ marginRight: 8, marginTop: 4 }}
                    onClick={onClickAddTask}
                    variant="contained"
                  >
                    {translations.addTaskButtonLabel}
                  </Button>
                </Tooltip>
              )}
              {canAddTodos && (
                <Tooltip title={translations.addTodoButtonTip}>
                  <Button
                    variant={!canAddTasks ? "contained" : "outlined"}
                    color="primary"
                    style={{ marginTop: 4 }}
                    size="small"
                    onClick={onClickAddTodo}
                  >
                    {translations.addTodoButtonLabel}
                  </Button>
                </Tooltip>
              )}
              <IconButton className={classes.closeCardFormButton} onClick={hideCardForm}>
                <X size={18} color={COLOR_BLACK} />
              </IconButton>
            </div>
          </div>
        )}
        <Droppable droppableId={column.id}>
          {(provided) => (
            <div className={classes.droppableArea} {...provided.droppableProps} ref={provided.innerRef}>
              {column.cards.allIds.map((cardId) => {
                const card = column.cards.byId[cardId]
                return <BoardCard key={card.id} card={card} board={board} />
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 8,
    margin: 4,
    background: theme.palette.divider,
    border: 0,
    display: "flex",
    flexDirection: "column",
    flex: "1 0",
    borderRadius: 3,
    maxHeight: "calc(100vh - 156px)",
    overflowY: "auto",
    alignItems: "stretch",
  },
  columnTitle: {
    background: COLOR_LIGHT,
    margin: 0,
    marginBottom: 8,
    borderRadius: 3,
    "& .MuiTypography-root": {
      padding: 0,
      overflow: "hidden",
    },
  },
  addButton: {
    marginBottom: 8,
    color: theme.palette.text.secondary,
  },
  droppableArea: {
    flexGrow: 1,
    minHeight: 64,
  },
  titleField: {
    paddingBottom: 4,
    fontWeight: 500,
    fontSize: 20,
  },
  cardPaper: {
    marginBottom: 8,
  },
  cardTextArea: {
    border: 0,
    borderRadius: "inherit",
    outline: 0,
    width: "100%",
    resize: "none",
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.body2.fontWeight,
    fontFamily: theme.typography.body2.fontFamily,
    letterSpacing: theme.typography.body1.letterSpacing,
    padding: theme.spacing(1, 1, 4, 1),
  },
  closeCardFormButton: {},
}))

type BoardColumnProps = {
  board: BoardPopulated
  column: ColumnPopulated
  canAddTasks: boolean
  canAddTodos: boolean
  onAddTask: (task: NewCardData) => unknown
  onAddTodo: (todo: NewCardData) => unknown
  onSaveColumnName: (columnData: BoardColumnNameUpdateData) => unknown
}
export default BoardColumn
