import Skeleton from "@material-ui/lab/Skeleton"

import { makeStyles, Theme } from "@material-ui/core/styles"

const useSplashScreenStyles = makeStyles((theme: Theme) => ({
  "@keyframes svgPulse": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 0.9,
    },
  },
  root: {
    position: "fixed",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  logoSvg: {
    height: theme.spacing(6),
    marginTop: `-${theme.spacing(8)}px`,
    transition: theme.transitions.create(["height", "width", "color"], {
      duration: 500,
      easing: theme.transitions.easing.easeInOut,
    }),
    [theme.breakpoints.down("md")]: {
      height: theme.spacing(5),
    },
    [theme.breakpoints.down("xs")]: {
      height: theme.spacing(4),
    },
    "& path": {
      transition: theme.transitions.create(["opacity", "color"], {
        duration: 1500,
        easing: theme.transitions.easing.easeIn,
      }),
      animation: "MuiSkeleton-keyframes-pulse 1.5s ease-in-out 0.5s infinite",
    },
  },
  waveCircles: {
    display: "flex",
    marginTop: theme.spacing(3),
    "& span": {
      width: theme.spacing(1.5),
      height: theme.spacing(1.5),
      margin: theme.spacing(0, 1),
      backgroundColor: theme.palette.info.main,
    },
  },
}))

const AppSplashScreen = () => {
  const classes = useSplashScreenStyles()
  return (
    <div className={classes.root}>
      <div>
        <svg className={classes.logoSvg} viewBox="0 0 104 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.287844 21.024C0.095948 20.768 0 20.448 0 20.064C0 19.5093 0.277183 19.04 0.831549 18.656C1.13005 18.464 1.43922 18.368 1.75905 18.368C2.2068 18.368 2.63324 18.5707 3.03835 18.976C3.67801 19.6373 4.33898 20.128 5.02128 20.448C5.7249 20.768 6.56711 20.928 7.54791 20.928C9.21101 20.864 10.0426 20.2347 10.0426 19.04C10.0426 18.4213 9.75471 17.952 9.17903 17.632C8.62466 17.312 7.75047 16.992 6.55645 16.672C5.31978 16.3307 4.307 15.9893 3.51809 15.648C2.75051 15.3067 2.07887 14.7947 1.50319 14.112C0.948819 13.408 0.671636 12.4693 0.671636 11.296C0.671636 10.272 0.95948 9.38667 1.53517 8.64C2.13218 7.872 2.89976 7.28533 3.83792 6.88C4.7974 6.45333 5.81018 6.24 6.87627 6.24C7.96368 6.24 9.05109 6.44267 10.1385 6.848C11.2259 7.25333 12.0788 7.85067 12.6971 8.64C12.9743 8.98133 13.1129 9.36533 13.1129 9.792C13.1129 10.2827 12.9103 10.688 12.5052 11.008C12.2067 11.2427 11.8656 11.36 11.4818 11.36C11.0127 11.36 10.6289 11.2107 10.3304 10.912C9.94661 10.4427 9.44555 10.0693 8.82722 9.792C8.23021 9.51467 7.54791 9.376 6.78033 9.376C5.15987 9.376 4.34964 9.96267 4.34964 11.136C4.34964 11.6053 4.4989 11.9893 4.7974 12.288C5.09591 12.5653 5.46904 12.7893 5.91679 12.96C6.38587 13.1093 7.03619 13.3013 7.86774 13.536C9.01911 13.856 9.97859 14.1867 10.7462 14.528C11.5138 14.8693 12.1641 15.392 12.6971 16.096C13.2302 16.8 13.4967 17.728 13.4967 18.88C13.4967 19.9253 13.1875 20.8427 12.5692 21.632C11.9509 22.4 11.1513 22.9867 10.1705 23.392C9.21101 23.7973 8.21955 24 7.1961 24C5.78886 24 4.47757 23.7653 3.26223 23.296C2.04689 22.8267 1.05543 22.0693 0.287844 21.024Z"
            fill="#3717CB"
          />
          <path
            d="M22.8944 20.064C23.1929 20.064 23.4595 20.2133 23.694 20.512C23.9499 20.7893 24.0778 21.1627 24.0778 21.632C24.0778 22.208 23.758 22.6987 23.1183 23.104C22.5 23.488 21.7964 23.68 21.0074 23.68C19.6855 23.68 18.5661 23.4027 17.6493 22.848C16.7538 22.272 16.306 21.0667 16.306 19.232V10.4H14.8348C14.3231 10.4 13.8966 10.2293 13.5555 9.888C13.2143 9.54667 13.0438 9.12 13.0438 8.608C13.0438 8.11733 13.2143 7.712 13.5555 7.392C13.8966 7.05067 14.3231 6.88 14.8348 6.88H16.306V4.832C16.306 4.27733 16.4872 3.81867 16.8497 3.456C17.2335 3.072 17.7026 2.88 18.2569 2.88C18.79 2.88 19.2377 3.072 19.6002 3.456C19.9627 3.81867 20.1439 4.27733 20.1439 4.832V6.88H22.4147C22.9264 6.88 23.3528 7.05067 23.694 7.392C24.0351 7.73333 24.2057 8.16 24.2057 8.672C24.2057 9.16267 24.0351 9.57867 23.694 9.92C23.3528 10.24 22.9264 10.4 22.4147 10.4H20.1439V19.072C20.1439 19.52 20.2612 19.8507 20.4957 20.064C20.7303 20.256 21.0501 20.352 21.4552 20.352C21.6258 20.352 21.8603 20.3093 22.1588 20.224C22.4147 20.1173 22.6599 20.064 22.8944 20.064Z"
            fill="#3717CB"
          />
          <path
            d="M39.5125 6.24C40.0669 6.24 40.5253 6.42133 40.8878 6.784C41.2502 7.14667 41.4315 7.616 41.4315 8.192V21.728C41.4315 22.2827 41.2502 22.752 40.8878 23.136C40.5253 23.4987 40.0669 23.68 39.5125 23.68C38.9581 23.68 38.4997 23.4987 38.1372 23.136C37.7961 22.7733 37.6149 22.3147 37.5935 21.76C37.0605 22.3787 36.3356 22.912 35.4187 23.36C34.5232 23.7867 33.5744 24 32.5723 24C31.1011 24 29.7685 23.6267 28.5744 22.88C27.3804 22.112 26.4316 21.056 25.728 19.712C25.0457 18.368 24.7045 16.8427 24.7045 15.136C24.7045 13.4293 25.0457 11.904 25.728 10.56C26.4103 9.19467 27.3378 8.13867 28.5105 7.392C29.7045 6.624 31.0158 6.24 32.4443 6.24C33.4678 6.24 34.4273 6.432 35.3228 6.816C36.2183 7.17867 36.9752 7.648 37.5935 8.224V8.192C37.5935 7.63733 37.7748 7.17867 38.1372 6.816C38.4997 6.432 38.9581 6.24 39.5125 6.24ZM33.052 20.48C34.4379 20.48 35.568 19.9787 36.4422 18.976C37.3164 17.952 37.7535 16.672 37.7535 15.136C37.7535 13.6 37.3164 12.32 36.4422 11.296C35.568 10.272 34.4379 9.76 33.052 9.76C31.6874 9.76 30.568 10.272 29.6938 11.296C28.8196 12.32 28.3825 13.6 28.3825 15.136C28.3825 16.672 28.809 17.952 29.6618 18.976C30.536 19.9787 31.6661 20.48 33.052 20.48Z"
            fill="#3717CB"
          />
          <path
            d="M52.4163 6.24C53.056 6.24 53.589 6.42133 54.0155 6.784C54.4632 7.14667 54.6871 7.57333 54.6871 8.064C54.6871 8.72533 54.5165 9.22667 54.1754 9.568C53.8342 9.888 53.4291 10.048 52.96 10.048C52.6402 10.048 52.2778 9.97333 51.8726 9.824C51.8087 9.80267 51.6594 9.76 51.4249 9.696C51.2117 9.632 50.9771 9.6 50.7213 9.6C50.1669 9.6 49.6339 9.77067 49.1221 10.112C48.6104 10.4533 48.184 10.976 47.8428 11.68C47.523 12.3627 47.3631 13.184 47.3631 14.144V21.728C47.3631 22.2827 47.1818 22.752 46.8194 23.136C46.4569 23.4987 45.9985 23.68 45.4441 23.68C44.8898 23.68 44.4313 23.4987 44.0689 23.136C43.7064 22.752 43.5252 22.2827 43.5252 21.728V8.512C43.5252 7.95733 43.7064 7.49867 44.0689 7.136C44.4313 6.752 44.8898 6.56 45.4441 6.56C45.9985 6.56 46.4569 6.752 46.8194 7.136C47.1818 7.49867 47.3631 7.95733 47.3631 8.512V8.928C47.8535 8.05333 48.5571 7.392 49.4739 6.944C50.3908 6.47467 51.3716 6.24 52.4163 6.24Z"
            fill="#3717CB"
          />
          <path
            d="M64.1863 6.24C65.6575 6.24 66.9901 6.624 68.1841 7.392C69.3781 8.13867 70.3163 9.184 70.9986 10.528C71.7022 11.872 72.054 13.3973 72.054 15.104C72.054 16.8107 71.7129 18.3467 71.0306 19.712C70.3483 21.056 69.4101 22.112 68.2161 22.88C67.0434 23.6267 65.7428 24 64.3142 24C63.2908 24 62.3206 23.7973 61.4038 23.392C60.5083 22.9653 59.762 22.4533 59.165 21.856V22.048C59.165 22.6027 58.9838 23.072 58.6213 23.456C58.2588 23.8187 57.8004 24 57.2461 24C56.6917 24 56.2333 23.8187 55.8708 23.456C55.5083 23.0933 55.3271 22.624 55.3271 22.048V1.952C55.3271 1.39733 55.5083 0.938665 55.8708 0.575998C56.2333 0.191999 56.6917 0 57.2461 0C57.8004 0 58.2588 0.191999 58.6213 0.575998C58.9838 0.938665 59.165 1.39733 59.165 1.952V8.64C59.6767 8 60.391 7.44533 61.3079 6.976C62.2247 6.48533 63.1842 6.24 64.1863 6.24ZM63.7066 20.48C65.0712 20.48 66.1905 19.968 67.0647 18.944C67.9389 17.92 68.376 16.64 68.376 15.104C68.376 13.568 67.9389 12.2987 67.0647 11.296C66.2119 10.272 65.0925 9.76 63.7066 9.76C62.3206 9.76 61.1906 10.272 60.3164 11.296C59.4422 12.2987 59.0051 13.568 59.0051 15.104C59.0051 16.64 59.4422 17.92 60.3164 18.944C61.1906 19.968 62.3206 20.48 63.7066 20.48Z"
            fill="#3717CB"
          />
          <path
            d="M82.0794 6.24C82.7191 6.24 83.2521 6.42133 83.6786 6.784C84.1263 7.14667 84.3502 7.57333 84.3502 8.064C84.3502 8.72533 84.1796 9.22667 83.8385 9.568C83.4973 9.888 83.0922 10.048 82.6231 10.048C82.3033 10.048 81.9408 9.97333 81.5357 9.824C81.4718 9.80267 81.3225 9.76 81.088 9.696C80.8748 9.632 80.6402 9.6 80.3844 9.6C79.83 9.6 79.2969 9.77067 78.7852 10.112C78.2735 10.4533 77.8471 10.976 77.5059 11.68C77.1861 12.3627 77.0262 13.184 77.0262 14.144V21.728C77.0262 22.2827 76.8449 22.752 76.4825 23.136C76.12 23.4987 75.6616 23.68 75.1072 23.68C74.5528 23.68 74.0944 23.4987 73.732 23.136C73.3695 22.752 73.1882 22.2827 73.1882 21.728V8.512C73.1882 7.95733 73.3695 7.49867 73.732 7.136C74.0944 6.752 74.5528 6.56 75.1072 6.56C75.6616 6.56 76.12 6.752 76.4825 7.136C76.8449 7.49867 77.0262 7.95733 77.0262 8.512V8.928C77.5166 8.05333 78.2202 7.392 79.137 6.944C80.0539 6.47467 81.0347 6.24 82.0794 6.24Z"
            fill="#3717CB"
          />
          <path
            d="M88.6042 21.728C88.6042 22.2827 88.423 22.752 88.0605 23.136C87.6981 23.4987 87.2396 23.68 86.6853 23.68C86.1309 23.68 85.6725 23.4987 85.31 23.136C84.9475 22.752 84.7663 22.2827 84.7663 21.728V8.512C84.7663 7.95733 84.9475 7.49867 85.31 7.136C85.6725 6.752 86.1309 6.56 86.6853 6.56C87.2396 6.56 87.6981 6.752 88.0605 7.136C88.423 7.49867 88.6042 7.95733 88.6042 8.512V21.728ZM86.6533 4.48C85.9283 4.48 85.4166 4.36267 85.1181 4.128C84.8196 3.89333 84.6704 3.47733 84.6704 2.88V2.272C84.6704 1.67467 84.8303 1.25867 85.1501 1.024C85.4699 0.789333 85.9816 0.671999 86.6853 0.671999C87.4315 0.671999 87.9539 0.789333 88.2524 1.024C88.5509 1.25867 88.7002 1.67467 88.7002 2.272V2.88C88.7002 3.49867 88.5403 3.92533 88.2204 4.16C87.9219 4.37333 87.3995 4.48 86.6533 4.48Z"
            fill="#3717CB"
          />
          <path
            d="M103.084 20.64C103.361 20.9813 103.5 21.3653 103.5 21.792C103.5 22.3253 103.297 22.784 102.892 23.168C102.487 23.552 102.039 23.744 101.549 23.744C100.931 23.744 100.408 23.4667 99.9819 22.912L96.144 18.144L92.402 22.912C92.2101 23.168 91.9756 23.3707 91.6984 23.52C91.4425 23.648 91.1653 23.712 90.8668 23.712C90.4191 23.712 89.9926 23.5413 89.5875 23.2C89.1824 22.8587 88.9799 22.464 88.9799 22.016C88.9799 21.5893 89.1398 21.184 89.4596 20.8L93.9052 15.36L89.2997 9.632C89.0225 9.29067 88.8839 8.90667 88.8839 8.48C88.8839 7.94667 89.0758 7.49867 89.4596 7.136C89.8647 6.752 90.3231 6.56 90.8349 6.56C91.4532 6.56 91.9756 6.82667 92.402 7.36L96.3359 12.256L99.9819 7.424C100.387 6.848 100.899 6.56 101.517 6.56C102.029 6.56 102.455 6.74133 102.796 7.104C103.159 7.44533 103.34 7.84 103.34 8.288C103.34 8.75733 103.191 9.17333 102.892 9.536L98.4787 14.912L103.084 20.64Z"
            fill="#3717CB"
          />
        </svg>
      </div>
      <div className={classes.waveCircles}>
        <Skeleton variant="circle" />
        <Skeleton variant="circle" />
        <Skeleton variant="circle" />
      </div>
    </div>
  )
}

export default AppSplashScreen
