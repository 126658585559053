import { TimeZoneType } from "../../constants/timezones"
import { DateTimeService } from "../../services/date-time-service"
import { Status } from "../../types/common"
import { useAuthUser } from "../../users/hooks/use-auth-user"
import { useAuthUserLocale } from "../../users/hooks/use-auth-user-locale"
import { ReminderViewModel } from "../api/reminder"
import { useTranslations } from "./use-translations"

export const useReminderSummary = ({ reminder }: { reminder: ReminderViewModel }): Summary => {
  const translations = useTranslations()
  const { dateFormat, timeZone } = useAuthUserLocale()
  const { user: authUser } = useAuthUser()
  const user = { timeZone, dateFormat, email: authUser?.email || "" }
  const recipientSummary = getRecipientsSummary({ reminder, user, translations })
  const triggerSummary = getReminderTriggerSummary({ reminder, user, translations })
  return { recipientSummary, triggerSummary }
}

const formatListToString = (list: string[]): string => {
  const listLength = list.length
  if (listLength === 0) return ""
  if (listLength === 1) return list[0]
  const allButLast = list.slice(0, -1).join(", ")
  const last = list[listLength - 1]
  return `${allButLast} & ${last}`
}

const getExpiredReminderSummary = (params: GetSummaryFnParams): string => {
  if (!params.reminder.isExpired) return ""
  return params.translations.notificationExpiredSummary
}

const getTriggeredButNoOneToNotifySummary = (params: GetSummaryFnParams): string => {
  const { reminder, user, translations } = params

  const { timeZone, dateFormat } = user
  if (!reminder.sentAt || reminder.sentTo.length !== 0) return ""
  const dateTimeService = new DateTimeService({ timeZone, dateFormat, enableTimeComponent: true })
  const formattedSentTime = dateTimeService.format(reminder.sentAt)
  return translations.notificationTriggeredWithoutRecipients
    .replace("{sentAt}", formattedSentTime)
    .replace("{timezone}", timeZone)
}

const getSentReminderSummary = (params: GetSummaryFnParams): string => {
  const { reminder, user, translations } = params
  if (!reminder.sentAt || reminder.sentTo.length === 0) return ""

  const roleRecipients: string[] = reminder.recipients.roles.map((role) => translations[role].toLowerCase())
  const formattedRecipients = reminder.isPersonal
    ? user?.email
    : reminder.sentAt
    ? formatListToString(reminder.sentTo)
    : formatListToString([...roleRecipients, ...reminder.recipients.emails])

  const { timeZone, dateFormat } = user
  const dateTimeService = new DateTimeService({ timeZone, dateFormat, enableTimeComponent: true })
  const sentAtTime = dateTimeService.format(reminder.sentAt)
  return `${translations.notificationWasSentTo} ${formattedRecipients} @ ${sentAtTime} (${timeZone})`
}

const getActiveReminderSummary = (params: GetSummaryFnParams): string => {
  if (params.reminder.sentAt || params.reminder.isExpired) return ""
  const { reminder, translations, user } = params
  const roleRecipients: string[] = reminder.recipients.roles.map((role) => translations[role].toLowerCase())
  const formattedRecipients = reminder.isPersonal
    ? user?.email
    : reminder.sentAt
    ? formatListToString(reminder.sentTo)
    : formatListToString([...roleRecipients, ...reminder.recipients.emails])
  return `${translations.notificationWillBeSentTo} ${formattedRecipients}`
}

const getRecipientsSummary = (params: GetSummaryFnParams): string => {
  const { reminder } = params
  if (reminder.sentAt) {
    return reminder.sentTo.length === 0 ? getTriggeredButNoOneToNotifySummary(params) : getSentReminderSummary(params)
  }
  return reminder.isExpired ? getExpiredReminderSummary(params) : getActiveReminderSummary(params)
}

const getRelativeReminderTriggerSummary = (params: GetSummaryFnParams): string => {
  if (!params.reminder.reminderTimeDetails) return ""
  const { reminder, translations } = params
  const { value, unit, isAfter, reference } = reminder.reminderTimeDetails
  let summary = `${value} ${translations[unit]} `
  summary += isAfter ? `${translations.after} ` : `${translations.before} `
  summary += reminder.isCalendarEventReminder
    ? translations.calendarEvent.toLowerCase()
    : reminder.isTodoReminder
    ? `${translations.todo.toLowerCase()} ${translations.dueDate.toLowerCase()}`
    : reference === "plannedStart"
    ? translations.plannedStartDate
    : translations.plannedEndDate
  return summary
}

const getAbsoluteReminderTriggerSummary = (params: GetSummaryFnParams): string => {
  if (!params.reminder.reminderTime) return ""
  const { reminder, user, translations } = params
  const { timeZone, dateFormat } = user
  const dateTimeService = new DateTimeService({ timeZone, dateFormat, enableTimeComponent: true })
  const timeZonedReminderTime = dateTimeService.removeTimezoneOffset(reminder.reminderTime)
  const formattedReminderTime = dateTimeService.format(timeZonedReminderTime)
  return `${translations.at} ${formattedReminderTime} (${timeZone})`
}

const getStatusReminderTriggerSummary = (params: GetSummaryFnParams): string => {
  if (!params.reminder.triggerStatus) return ""
  const { reminder, translations } = params
  const { whenStatusChanges } = translations

  const statusTranslationMap: Record<string, string> = {
    [Status.COMPLETED]: translations.completed,
    [Status.IN_PROGRESS]: translations.inProgress,
    [Status.NOT_STARTED]: translations.notStarted,
  }

  const statusName = reminder.triggerStatusName || reminder.triggerStatus
  const translatedStatus = statusTranslationMap[statusName] || statusName
  return `${whenStatusChanges} ${translatedStatus}`
}

const getReminderTriggerSummary = (params: GetSummaryFnParams): string => {
  const { reminder } = params
  if (reminder.isRelativeTimeReminder) return getRelativeReminderTriggerSummary(params)
  if (reminder.isAbsoluteTimeReminder) return getAbsoluteReminderTriggerSummary(params)
  if (reminder.isStatusChangeReminder) return getStatusReminderTriggerSummary(params)
  return ""
}

type Translations = ReturnType<typeof useTranslations>

type GetSummaryFnParams = {
  reminder: ReminderViewModel
  user: { timeZone: TimeZoneType; dateFormat: string; email: string }
  translations: Translations
}

type Summary = {
  recipientSummary: string
  triggerSummary: string
}
