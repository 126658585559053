import CircularProgress from "@material-ui/core/CircularProgress"
import Chip from "@material-ui/core/Chip"
import MuiSelect from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Typography from "@material-ui/core/Typography"

import { ChangeEvent, useState } from "react"
import { Check, AlertTriangle, ChevronDown } from "react-feather"
import { makeStyles, Theme, FormControl, SelectProps } from "@material-ui/core"
import { useNetworkStatus } from "../../hooks"
import { capitalize } from "lodash"

const AutoSaveSelect = ({ name, initialValue, onSave, label, options = [], ...selectProps }: AutoSaveSelectProps) => {
  const classes = useStyles()
  const networkStatus = useNetworkStatus({ resetDelayInMS: 2000 })
  const [error, setError] = useState("")
  const { isPending, isFulfilled, isRejected, setStatus } = networkStatus

  const handleChange = async (event: ChangeEvent<{ value: unknown }>) => {
    const newValue = event.target.value
    if (newValue !== initialValue) {
      try {
        setStatus("pending")
        await onSave({ [name]: newValue })
        setStatus("fulfilled")
      } catch (error) {
        setStatus("rejected")
        if (error instanceof Error) setError(error.message)
        else if (typeof error === "string") setError(error)
        else setError("Something went wrong")
      }
    }
  }

  const inputLabel = label ? `${label}:` : `${capitalize(name)}:`
  let chipLabel = ""
  let chipIcon: null | JSX.Element = null
  let chipColor: "default" | "secondary" | "primary" = "default"

  if (isPending()) {
    chipLabel = "Saving"
    chipIcon = <CircularProgress size={12} />
  }

  if (isRejected()) {
    chipLabel = "Failed"
    chipColor = "secondary"
    chipIcon = <AlertTriangle size={12} />
  }

  if (isFulfilled()) {
    chipLabel = "Saved"
    chipColor = "primary"
    chipIcon = <Check size={12} />
  }

  return (
    <FormControl className={classes.formControl}>
      <div className={classes.labelContainer}>
        <Typography variant="subtitle2" component="label" className={classes.label}>
          {inputLabel}
          {selectProps?.required && (
            <Typography component="span" color="textSecondary" variant="caption">
              (required)
            </Typography>
          )}
        </Typography>
        {chipLabel && chipIcon && (
          <Chip
            variant="outlined"
            label={chipLabel}
            color={chipColor}
            size="small"
            className={classes.chip}
            icon={chipIcon}
          />
        )}
      </div>
      <MuiSelect
        {...selectProps}
        id={name}
        name={name}
        value={initialValue}
        variant="outlined"
        onChange={handleChange}
        IconComponent={ChevronDown}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} data-test={`${option.label}`}>
            {option.label}
          </MenuItem>
        ))}
      </MuiSelect>
      {error && (
        <Typography variant="caption" className={classes.errorMessage}>
          {error}
        </Typography>
      )}
    </FormControl>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  labelContainer: {
    display: "flex",
    padding: theme.spacing(0.5, 0),
    alignItems: "center",
    "& > div ": {
      marginLeft: theme.spacing(2),
    },
  },
  label: {
    fontWeight: 300,
    letterSpacing: theme.spacing(0.1),
  },
  chip: {
    border: "none",
    "&.MuiChip-colorSecondary": {
      color: theme.palette.error.light,
    },
  },
  errorMessage: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.error.main,
  },
  formControl: {
    width: "100%",
    "& .MuiInputBase-input.Mui-disabled": {
      color: theme.palette.text.primary,
      cursor: "not-allowed",
    },
  },
}))

type SelectOption = {
  value: string
  label: string
}

type AutoSaveSelectProps = SelectProps & {
  name: string
  initialValue: string
  onSave: (value: any) => Promise<any>
  options: SelectOption[]
  label?: string
}

export default AutoSaveSelect
