import { CustomStatusHandler } from "../../lib/custom-status-handler"
import { StatusOption } from "../../lib/status-options"
import { LinkViewModel, makeLinkViewModel } from "../../links/api/link"
import { TreeGridColors } from "../../treegrid/types"
import { MaintainerTypeEnum, Status } from "../../types/common"
import { TaskResponse } from "../api/task-response"

const statusTranslationKeys: Record<Status, StatusTranslationKey> = {
  [Status.COMPLETED]: "completed",
  [Status.IN_PROGRESS]: "inProgress",
  [Status.NOT_STARTED]: "notStarted",
  [Status.REJECTED]: "rejected",
}

function getStatusTranslationKey(status: string): StatusTranslationKey | undefined {
  return statusTranslationKeys[status as Status]
}

export const makeTaskViewModel = (task: TaskResponse): TaskViewModel => {
  const isOrgTask = task.maintainerType === MaintainerTypeEnum.ORG
  const isUserTask = task.maintainerType === MaintainerTypeEnum.USER
  const title = task.title === "Untitled task" ? "" : task.title
  const shownTaskNumber = task.customTaskNumber || task.taskNumber
  const taskLinks = (task.links || []).map(makeLinkViewModel)
  const statusHandler = new CustomStatusHandler(task)
  const taskStatus = statusHandler.getSelectedStatus()
  const statusTranslationKey = getStatusTranslationKey(taskStatus)
  const statusOptions = statusHandler.getVisibleOptions()

  return {
    ...task,
    ganttBarColor: task.ganttBarColor || "Default",
    title,
    shownTaskNumber,
    isOrgTask,
    isUserTask,
    isCompleted: task.status === Status.COMPLETED,
    isNotStarted: task.status === Status.NOT_STARTED,
    isInProgress: task.status === Status.IN_PROGRESS,
    isRejected: task.status === Status.REJECTED,
    links: taskLinks,
    customStatus: taskStatus,
    statusOptions,
    statusTranslationKey,
  }
}

export type TaskViewModel = Omit<TaskResponse, "links"> & {
  ganttBarColor: TreeGridColors
  shownTaskNumber: string
  isOrgTask: boolean
  isUserTask: boolean
  isCompleted: boolean
  isNotStarted: boolean
  isInProgress: boolean
  isRejected: boolean
  links: LinkViewModel[]
  customStatus: string
  statusOptions: StatusOption[]
  statusTranslationKey?: StatusTranslationKey
}

type StatusTranslationKey = "completed" | "inProgress" | "notStarted" | "rejected"
