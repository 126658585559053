import React from "react"
import Alert from "@material-ui/lab/Alert"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import { Form, Formik, FormikConfig } from "formik"
import { makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import { Autocomplete } from "@material-ui/lab"
import { getDefaultDateFormat } from "../../utils/date"
import FormikInput from "../../components/FormikInput"
import DatePicker from "../../components/DatePicker"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"
import { MONDAY } from "../../constants"
import { NewProjectData } from "../api"
import ButtonLink from "../../components/ButtonLink"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { paths } from "../../paths"
import { ProjectViewModel } from "../api/project"

const initialValues: FormikData = {
  title: "",
  description: "",
  plannedEndDate: "",
  plannedStartDate: "",
}

const ProjectForm = ({
  onSubmit,
  dateFormat = getDefaultDateFormat(),
  datePickerCalendarStartDay = MONDAY,
  isProjectTemplateForm = false,
  projects = [],
}: ProjectFormProps) => {
  const classes = useStyles()
  const translationsFromHook = useTranslations(defaultTranslations)
  const translations = translationsFromHook
  const [submissionError, setSubmissionError] = React.useState("")
  const { createPathWithContext } = useUrlWithContext()
  const options = projects.map((project) => ({
    title: `${project.shownProjectNumber}: ${project.title}`,
    id: project.id,
  }))
  const [selectedTemplate, setSelectedTemplate] = React.useState<string | null>(null)
  const showCreateTemplateButton = !isProjectTemplateForm

  const handleSubmit = (projectData: FormikData) => {
    let data: any = {
      title: projectData.title,
      description: projectData.description,
      plannedEndDate: projectData.plannedEndDate && new Date(projectData.plannedEndDate).toISOString(),
      plannedStartDate: projectData.plannedStartDate && new Date(projectData.plannedStartDate).toISOString(),
    }
    if (selectedTemplate) {
      data = { ...data, templateId: selectedTemplate }
    }
    onSubmit(data).catch((error: any) => {
      if (typeof error === "string") {
        setSubmissionError(error)
      } else {
        const submissionFailedMessage = isProjectTemplateForm
          ? translations.templateSubmissionFailedMessage
          : translations.submissionFailedMessage
        setSubmissionError(submissionFailedMessage)
      }
    })
  }

  const formikProps: FormikConfig<FormikData> = {
    initialValues,
    onSubmit: handleSubmit,
    initialErrors: { title: translations.projectTitleRequiredMessage },
  }

  return (
    <Formik {...formikProps}>
      {({ isSubmitting, setFieldValue, values, errors }) => {
        const { plannedStartDate, plannedEndDate } = values
        const isFormDisabled = Boolean(isSubmitting || submissionError)
        const startDateTextFieldProps = {
          disabled: isFormDisabled,
          label: translations.projectPlannedStartLabel,
          "data-test": "project-start-date-input",
        }
        const endDateTextFieldProps = {
          disabled: isFormDisabled,
          label: translations.projectPlannedEndLabel,
          "data-test": "project-end-date-input",
        }
        const selectedStartDate = plannedStartDate ? new Date(plannedStartDate) : null
        const selectedEndDate = plannedEndDate ? new Date(plannedEndDate) : null
        return (
          <Form>
            <FormikInput
              id="title"
              name="title"
              variant="outlined"
              label={translations.projectTitleLabel}
              autoComplete="off"
              disabled={isFormDisabled}
              className={classes.formControl}
              autoFocus
              data-test="project-title-input"
            />
            <FormikInput
              id="description"
              name="description"
              variant="outlined"
              autoComplete="off"
              label={translations.projectDescriptionLabel}
              disabled={isFormDisabled}
              className={classes.formControl}
              data-test="project-description-input"
            />
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  selected={selectedStartDate}
                  onChange={(d) => setFieldValue("plannedStartDate", d)}
                  textFieldProps={startDateTextFieldProps}
                  calendarStartDay={datePickerCalendarStartDay}
                  dateFormat={dateFormat}
                  placeholderText={dateFormat}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <DatePicker
                  selected={selectedEndDate}
                  onChange={(d) => setFieldValue("plannedEndDate", d)}
                  textFieldProps={endDateTextFieldProps}
                  calendarStartDay={datePickerCalendarStartDay}
                  dateFormat={dateFormat}
                  placeholderText={dateFormat}
                  {...(!showCreateTemplateButton ? { className: classes.formControl } : {})}
                />
              </Grid>
            </Grid>
            {showCreateTemplateButton && (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <Autocomplete
                    disabled={isFormDisabled}
                    className={classes.autoComplete}
                    options={options}
                    value={options.find((option) => option.id === selectedTemplate)}
                    getOptionLabel={(option) => option.title}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={(_event, value) => setSelectedTemplate(value?.id || null)}
                    noOptionsText={translations.projectTemplateEmptyMessage}
                    renderInput={(params) => (
                      <TextField variant="outlined" label={translations.selectTemplateLabel} {...params} />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography component="span" color="textSecondary" className={classes.or}>
                    {translations.or}
                  </Typography>
                  <ButtonLink
                    to={createPathWithContext(paths.newProjectTemplate())}
                    variant="text"
                    color="primary"
                    disabled={isFormDisabled}
                  >
                    {translations.createProjectTemplateButtonLabel}
                  </ButtonLink>
                </Grid>
              </Grid>
            )}

            {submissionError && (
              <Alert className={classes.formControl} severity="error">
                {submissionError}
              </Alert>
            )}
            <Button
              variant="contained"
              type="submit"
              color="primary"
              disabled={Boolean(isFormDisabled || errors.title || !values.title)}
              data-test="create-project-button"
            >
              {isProjectTemplateForm
                ? translations.createProjectTemplateButtonLabel
                : translations.createProjectButtonLabel}
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("project")
  const translations = (t?.projectForm || {}) as StringMap
  const templateTranslations = (t?.projectTemplateForm || {}) as StringMap

  const {
    submissionFailedMessage = defaults.submissionFailedMessage,
    projectTitleRequiredMessage = defaults.projectTitleRequiredMessage,
    projectTitleLabel = defaults.projectTitleLabel,
    projectDescriptionLabel = defaults.projectDescriptionLabel,
    projectPlannedStartLabel = defaults.projectPlannedStartLabel,
    projectPlannedEndLabel = defaults.projectPlannedEndLabel,
    createProjectButtonLabel = defaults.createProjectButtonLabel,
    or = defaults.or,
    selectTemplateLabel = defaults.selectTemplateLabel,
  } = translations

  const createProjectTemplateButtonLabel =
    templateTranslations.createProjectButtonLabel || defaults.createProjectTemplateButtonLabel
  const templateSubmissionFailedMessage =
    templateTranslations.submissionFailedMessage || defaults.templateSubmissionFailedMessage
  const projectTemplateEmptyMessage = t.projectTemplateEmptyMessage || defaults.projectTemplateEmptyMessage

  return {
    submissionFailedMessage,
    projectTitleRequiredMessage,
    projectTitleLabel,
    projectDescriptionLabel,
    projectPlannedStartLabel,
    projectPlannedEndLabel,
    createProjectButtonLabel,
    or,
    selectTemplateLabel,
    createProjectTemplateButtonLabel,
    templateSubmissionFailedMessage,
    projectTemplateEmptyMessage,
  }
}

const defaultTranslations = {
  submissionFailedMessage: "Failed to create project",
  projectTitleRequiredMessage: "Project title is required",
  projectTitleLabel: "Project title",
  projectDescriptionLabel: "Project description",
  projectPlannedStartLabel: "Planned start date",
  projectPlannedEndLabel: "Planned end date",
  createProjectButtonLabel: "Create project",
  or: "Or",
  selectTemplateLabel: "Select a template",
  templateSubmissionFailedMessage: "Failed to create project template",
  createProjectTemplateButtonLabel: "Create project template",
  projectTemplateEmptyMessage: "No project templates available",
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: {
    width: "100%",
    marginBottom: theme.spacing(2.5),
  },
  autoComplete: {
    padding: theme.spacing(1, 0, 2, 0),
  },
  or: {
    paddingRight: theme.spacing(1),
  },
}))

type Translations = typeof defaultTranslations
type FormikData = Omit<NewProjectData, "type">
export type ProjectFormData = FormikData & { templateId?: string }
type ProjectFormProps = {
  isProjectTemplateForm?: boolean
  onSubmit: (project: ProjectFormData) => Promise<void>
  datePickerCalendarStartDay?: DayOfWeek
  dateFormat?: string
  projects?: ProjectViewModel[]
}

export default ProjectForm
