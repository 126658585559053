import { makeReminderApiEndpoints } from "./endpoints"
import { api } from "../../api"
import { ReminderEntityType, ReminderResponse, ReminderTriggerStatus } from "./reminder-response"
import { makeReminderViewModel, ReminderViewModel } from "./reminder"
import { makeUrlSearchParams } from "../../utils/url"

const apiEndpoints = makeReminderApiEndpoints()

export const reminderApi = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getReminders: builder.query<ReminderViewModel[], GetRemindersQuery>({
        query: (params) => {
          return {
            method: "GET",
            url: apiEndpoints.reminders(),
            params: params ? makeUrlSearchParams(params) : undefined,
          }
        },
        transformResponse: (response: ReminderResponse[]) => response.map(makeReminderViewModel),
        providesTags: ["Reminder"],
      }),
      createReminder: builder.mutation<ReminderViewModel, NewReminderData>({
        query: (reminderData) => {
          return {
            method: "POST",
            url: apiEndpoints.reminders(),
            body: reminderData,
          }
        },
        invalidatesTags: ["Reminder"],
      }),
      updateReminderTrigger: builder.mutation<ReminderViewModel, { reminderId: string } & ReminderTriggerUpdateData>({
        query: ({ reminderId, ...updateData }) => {
          return {
            method: "PUT",
            url: apiEndpoints.reminderTrigger(reminderId),
            body: updateData,
          }
        },
        invalidatesTags: ["Reminder"],
      }),
      updateReminderRecipients: builder.mutation<
        ReminderViewModel,
        { reminderId: string } & ReminderRecipientsUpdateData
      >({
        query: ({ reminderId, ...updateData }) => {
          return {
            method: "PUT",
            url: apiEndpoints.reminderRecipients(reminderId),
            body: updateData,
          }
        },
        invalidatesTags: ["Reminder"],
      }),
      updateNote: builder.mutation<ReminderViewModel, { reminderId: string } & ReminderNoteUpdateData>({
        query: ({ reminderId, note }) => {
          return {
            method: "PUT",
            url: apiEndpoints.reminderNote(reminderId),
            body: { note },
          }
        },
        invalidatesTags: ["Reminder"],
      }),
      deleteReminder: builder.mutation<void, string>({
        query: (reminderId) => {
          return {
            method: "DELETE",
            url: apiEndpoints.reminderItem(reminderId),
          }
        },
        invalidatesTags: ["Reminder"],
      }),
    }
  },
})

export const {
  useGetRemindersQuery,
  useCreateReminderMutation,
  useUpdateReminderRecipientsMutation,
  useUpdateReminderTriggerMutation,
  useUpdateNoteMutation,
  useDeleteReminderMutation,
} = reminderApi

export type BaseNewReminderData = {
  entityType: ReminderEntityType
  entityId: string
  triggerType: "absoluteTime" | "relativeTime" | "statusChange"
  recipients: {
    roles: ReminderResponse["recipients"]["roles"]
    emails: string[]
  }
}
type NewRelativeReminderData = {
  reminderTimeDetails: ReminderResponse["reminderTimeDetails"]
  reminderTime?: undefined
}
type NewAbsoluteReminderData = { reminderTime: string; reminderTimeDetails?: undefined }
type AbsoluteReminderTimeUpdateData = { reminderTime: string }
type RelativeReminderTimeUpdateData = { reminderTimeDetails: ReminderResponse["reminderTimeDetails"] }
type NewStatusReminderData = { triggerStatus: ReminderTriggerStatus }

export type NewReminderData = BaseNewReminderData &
  (NewRelativeReminderData | NewAbsoluteReminderData | NewStatusReminderData)
export type ReminderTimeUpdateData = AbsoluteReminderTimeUpdateData | RelativeReminderTimeUpdateData
export type ReminderRecipientsUpdateData = Partial<ReminderResponse["recipients"]>
export type DeleteAllExpiredRemindersQuery = { project: string }
export type ReminderNoteUpdateData = { note: string | null }

type AbsoluteReminderUpdateData = {
  triggerType: "absoluteTime"
  reminderTime: string
}

type RelativeReminderUpdateData = {
  triggerType: "relativeTime"
  reminderTimeDetails: ReminderResponse["reminderTimeDetails"]
}

type StatusChangeReminderUpdateData = {
  triggerType: "statusChange"
  triggerStatus: ReminderTriggerStatus
  triggerSubStatusId?: string | null
}

type GetRemindersQuery = { project: string } | { task: string } | { calendarEvent: string } | { todo: string }

export type ReminderTriggerUpdateData =
  | AbsoluteReminderUpdateData
  | RelativeReminderUpdateData
  | StatusChangeReminderUpdateData
