import { RouteComponentProps } from "react-router-dom"
import { useTask } from "../../../tasks/hooks/use-task"
import { NewCalendarEventData, useGetCalendarEventsQuery } from "../../../calendar-events/api"
import { NewTaskData, useGetTasksQuery } from "../../../tasks/api"
import { NewTodoData, useGetTodosQuery } from "../../../todos/api"
import { useCalendarEventMutations } from "../../../calendar-events/hooks/use-calendar-event-mutations"
import { useTaskMutations } from "../../../tasks/hooks/use-task-mutations"
import { useTodoMutations } from "../../../todos/hooks/use-todo-mutations"
import CircularProgress from "@material-ui/core/CircularProgress"
import Calendar from "../../../calendar-events/components/Calendar"
import { adaptTask, adaptCalendarEvent, adaptTodo, adaptRecurringEvent } from "../../../calendar-events/utils"
import React from "react"
import { keyBy, merge } from "lodash"
import ErrorList from "../../../components/ErrorList"
import { useI18n } from "../../../hooks"
import { StringMap } from "../../../types/common"
import { getHolidaysQueryFromContext } from "../../../context-options/utils"
import { useAppContext } from "../../../hooks/use-app-context"
import { useGetHolidaysQuery } from "../../../holidays/api"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const useLoader = ({ taskId }: { taskId: string }) => {
  const translations = useTranslations()
  const {
    appContext: { mainContext },
  } = useAppContext()
  const queryErrors = {} as QueryErrors
  const queryParams = { task: taskId }
  const holidaysQuery = getHolidaysQueryFromContext(mainContext)

  const { task, isLoading: isLoadingTask, isError: isErrorTask } = useTask(taskId, queryOptions)
  const {
    data: calendarEvents = [],
    isLoading: isLoadingCalendarEvents,
    isError: isErrorCalendarEvents,
  } = useGetCalendarEventsQuery(queryParams, queryOptions)
  const {
    data: tasks = [],
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetTasksQuery(queryParams, queryOptions)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
  } = useGetTodosQuery(queryParams, queryOptions)

  const {
    data: holidays = [],
    isLoading: isLoadingHolidays,
    isError: isErrorHolidays,
  } = useGetHolidaysQuery(holidaysQuery, queryOptions)

  const isLoading = isLoadingTask || isLoadingCalendarEvents || isLoadingTasks || isLoadingTodos || isLoadingHolidays
  const isError = isErrorTask || isErrorCalendarEvents || isErrorTasks || isErrorTodos || isErrorHolidays

  if (isErrorTask) queryErrors.task = translations.fetchErrorTask
  if (isErrorCalendarEvents) queryErrors.calendarEvent = translations.fetchErrorCalendarEvents
  if (isErrorTasks) queryErrors.tasks = translations.fetchErrorTasks
  if (isErrorTodos) queryErrors.todo = translations.fetchErrorTodos
  if (isErrorHolidays) queryErrors.holidays = translations.fetchErrorHolidays

  return {
    task,
    calendarEvents,
    tasks,
    todos,
    holidays,
    isLoading,
    isError,
    queryErrors,
  }
}

const TaskCalendarRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const { taskId } = match.params
  const { task, calendarEvents, tasks, todos, isLoading, isError, queryErrors, holidays } = useLoader({
    taskId,
  })
  const { createCalendarEvent, updateInfo: updateCalendarEvent } = useCalendarEventMutations()
  const { createOrgTask, createUserTask, updatePlan: updateTask } = useTaskMutations()
  const { createTodo, updateInfo: updateTodo } = useTodoMutations()

  const { events, recurringEvents, entitiesById } = React.useMemo(() => {
    const taskEvents = tasks.filter((t) => (t.plannedEndDate || t.plannedStartDate) && t.id !== taskId).map(adaptTask)
    const todoEvents = todos.filter((todo) => todo.dueDate).map(adaptTodo)
    const adaptedCalenderEvents = calendarEvents.filter((e) => !e.isRecurringEvent).map(adaptCalendarEvent)
    const adaptedRecurringEvents = calendarEvents.filter((e) => e.isRecurringEvent).map(adaptRecurringEvent)
    return {
      events: [...adaptedCalenderEvents, ...taskEvents, ...todoEvents],
      recurringEvents: adaptedRecurringEvents,
      entitiesById: merge(keyBy(calendarEvents, "id"), keyBy(tasks, "id"), keyBy(todos, "id")),
    }
  }, [calendarEvents, todos, tasks, taskId])

  if (isLoading) return <CircularProgress />
  if (!task) return <ErrorList errors={[translations.taskNotFoundError]} />
  if (isError) return <ErrorList errors={queryErrors} />

  const onAddCalendarEvent = (eventData: NewCalendarEventData) => {
    return createCalendarEvent({
      ...eventData,
      task: task.id,
      project: task.projectId,
      organisation: task.isOrgTask ? task.maintainerId : null,
    })
  }

  const onAddTask = async (taskData: Omit<NewTaskData, "projectId">) => {
    const newTaskData: NewTaskData = { ...taskData, projectId: task.projectId, parentTaskId: task.id }
    const response = task.isOrgTask
      ? await createOrgTask(task.maintainerId, newTaskData)
      : await createUserTask(task.maintainerId, newTaskData)
    if (response) return response.created
  }

  const onAddTodo = (todoData: NewTodoData) => {
    return createTodo({
      ...todoData,
      task: task.id,
      project: task.projectId,
      organisation: task.isOrgTask ? task.maintainerId : null,
    })
  }

  return (
    <Calendar
      events={events}
      recurringEvents={recurringEvents}
      entitiesById={entitiesById}
      canAddCalendarEvents={task.canCreateTaskCalendarEvents}
      canAddTasks={task.canCreate}
      canAddTodos={task.canCreateTaskTodos}
      onAddCalendarEvent={onAddCalendarEvent}
      onAddTask={onAddTask}
      onAddTodo={onAddTodo}
      onUpdateCalendarEvent={updateCalendarEvent}
      onUpdateTask={updateTask}
      onUpdateTodo={updateTodo}
      weekendDays={task.weekendDays}
      firstDayOfWeek={task.firstDayOfWeek}
      holidays={task.enableHolidays ? holidays : []}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translations")
  const translations = t || ({} as StringMap)

  const {
    contextNotFoundError = defaults.contextNotFoundError,
    taskNotFoundError = defaults.taskNotFoundError,
    fetchErrorCalendarEvents = defaults.fetchErrorCalendarEvents,
    fetchErrorTask = defaults.fetchErrorTask,
    fetchErrorTasks = defaults.fetchErrorTasks,
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorHolidays = defaults.fetchErrorHolidays,
  } = translations

  return {
    contextNotFoundError,
    taskNotFoundError,
    fetchErrorCalendarEvents,
    fetchErrorTask,
    fetchErrorTasks,
    fetchErrorTodos,
    fetchErrorHolidays,
  }
}
const defaultTranslations = {
  contextNotFoundError: "Context not found",
  taskNotFoundError: "Task not found",
  fetchErrorCalendarEvents: "Failed to fetch calendar events",
  fetchErrorTask: "Failed to fetch task",
  fetchErrorTasks: "Failed to fetch tasks",
  fetchErrorTodos: "Failed to fetch todos",
  fetchErrorHolidays: "Failed to fetch holidays",
}
type Translations = typeof defaultTranslations
type RouteParams = { taskId: string }
export default TaskCalendarRoute

type QueryErrors = {
  calendarEvent?: string
  task?: string
  todo?: string
  tasks?: string
  organisation?: string
  holidays?: string
}
