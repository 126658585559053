import React from "react"
import RecipientEmail from "./RecipientEmail"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import FormGroup from "@material-ui/core/FormGroup"
import Typography from "@material-ui/core/Typography"
import { makeStyles, type Theme } from "@material-ui/core"
import { uniq } from "lodash"
import { useTranslations } from "../hooks/use-translations"
import { ReminderViewModel } from "../api/reminder"
import { useReminderMutations } from "../hooks/use-reminder-mutations"

const RecipientEmailRecipients = ({ reminder }: RecipientEmailRecipientsProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { updateReminderRecipients } = useReminderMutations()
  const [showNewEmailInput, setShowNewEmailInput] = React.useState(false)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const emails = reminder.recipients.emails
  const hasSingleRecipient = [...reminder.recipients.roles, ...reminder.recipients.emails].length === 1
  const isInputDisabled = (hasSingleRecipient && reminder.hasEmailRecipients) || reminder.isReadOnly

  const removeRecipientEmail = (email: string) => {
    const updatedEmails = emails.filter((e) => e !== email)
    updateReminderRecipients(reminder.id, { emails: updatedEmails })
  }

  const saveRecipientEmail = (updatedEmail: string, index: number) => {
    const updatedEmails = emails.map((email, idx) => (idx === index ? updatedEmail : email))
    updateReminderRecipients(reminder.id, { emails: updatedEmails })
  }

  const addRecipientEmail = (email: string) => {
    if (!email) {
      setShowNewEmailInput(false)
      return
    }

    const updatedEmails = uniq([...emails, email])
    updateReminderRecipients(reminder.id, { emails: updatedEmails })
    const inputEl = inputRef.current?.querySelector("input")
    if (inputEl) inputEl.value = ""
  }

  return (
    <FormGroup className={classes.formGroup}>
      <Typography variant="subtitle2" component="legend" gutterBottom>
        {translations.emailRecipients}
      </Typography>
      {emails.map((email, index) => (
        <RecipientEmail
          key={index}
          email={email}
          onSaveRecipientEmail={(email) => saveRecipientEmail(email, index)}
          onRemoveRecipientEmail={removeRecipientEmail}
          isInputDisabled={isInputDisabled}
        />
      ))}
      {showNewEmailInput ? (
        <>
          <RecipientEmail
            ref={inputRef}
            email=""
            onSaveRecipientEmail={addRecipientEmail}
            onRemoveRecipientEmail={removeRecipientEmail}
            autoFocus
          />
          <Box>
            <Button
              style={{ marginRight: 8 }}
              variant="outlined"
              size="small"
              onClick={() => setShowNewEmailInput(false)}
            >
              {translations.add}
            </Button>
            <Button size="small" onClick={() => setShowNewEmailInput(false)}>
              {translations.cancel}
            </Button>
          </Box>
        </>
      ) : (
        <Button
          variant="outlined"
          className={classes.addEmailButton}
          size="small"
          onClick={() => setShowNewEmailInput(true)}
          disabled={reminder.isReadOnly}
        >
          {translations.addEmailRecipientLabel}
        </Button>
      )}
    </FormGroup>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  formGroup: {
    margin: theme.spacing(1.5, 0),
  },

  addEmailButton: {
    maxWidth: 300,
    marginTop: 20,
  },
}))

export default RecipientEmailRecipients

type RecipientEmailRecipientsProps = {
  reminder: ReminderViewModel
}
