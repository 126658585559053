import { useAuthUser } from "./use-auth-user"
import { useDateFormat } from "./use-date-format"

export const useAuthUserLocale = () => {
  const { user } = useAuthUser()
  const { dateFormat, dateSeparator, dateTimeFormat } = useDateFormat()

  return {
    dateFormat,
    dateSeparator,
    language: user?.language || "en",
    timeZone: user?.timeZone || "UTC",
    dateTimeFormat,
  }
}
