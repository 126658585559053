import { z } from "zod"

export const HOLIDAY_TYPES = ["Single", "Range"] as const
export const HolidayTypeSchema = z.enum(HOLIDAY_TYPES)

const BaseHolidaySchema = z.object({
  id: z.string(),
  createdAt: z.coerce.date(),
  updatedAt: z.coerce.date(),
  name: z.string(),
  recurring: z.boolean(),
})

export const SingleBaseHolidaySchema = BaseHolidaySchema.extend({
  type: z.literal("Single"),
  date: z.coerce.date(),
  startDate: z.null().optional().default(null),
  endDate: z.null().optional().default(null),
})
const SingleUserHolidaySchema = SingleBaseHolidaySchema.extend({
  userId: z.string(),
  orgId: z.null().optional().default(null),
})
const SingleOrgHolidaySchema = SingleBaseHolidaySchema.extend({
  orgId: z.string(),
  userId: z.null().optional().default(null),
})
const SingleHolidaySchema = z.union([SingleUserHolidaySchema, SingleOrgHolidaySchema])

export const RangeBaseHolidaySchema = BaseHolidaySchema.extend({
  type: z.literal("Range"),
  startDate: z.coerce.date(),
  endDate: z.coerce.date(),
  date: z.null().optional().default(null),
})
const RangeUserHolidaySchema = RangeBaseHolidaySchema.extend({
  userId: z.string(),
  orgId: z.null().optional().default(null),
})
const RangeOrgHolidaySchema = RangeBaseHolidaySchema.extend({
  orgId: z.string(),
  userId: z.null().optional().default(null),
})
const RangeHolidaySchema = z.union([RangeUserHolidaySchema, RangeOrgHolidaySchema])

export const HolidayResponseSchema = z.union([SingleHolidaySchema, RangeHolidaySchema])

export const validateHolidayResponse = (holidayData: HolidayResponse): HolidayResponse => {
  const parseResult = HolidayResponseSchema.safeParse(holidayData)
  if (parseResult.success) return parseResult.data
  throw new Error(parseResult.error.errors.map((e) => e.message).join("\n"))
}

export type HolidayType = z.infer<typeof HolidayTypeSchema>
export type SingleHolidayResponse = z.infer<typeof SingleHolidaySchema>
export type RangeHolidayResponse = z.infer<typeof RangeHolidaySchema>
export type HolidayResponse = z.infer<typeof HolidayResponseSchema>
