import { Mail } from "react-feather"
import { COLOR_BLACK } from "../../constants"
import { alpha, Button, Grid, makeStyles, Theme, Typography } from "@material-ui/core"

const InvitationSentAlert = ({ alertText, onCancelInvitation }: InvitationSentAlertProps) => {
  const classes = useStyles()
  return (
    <Grid className={classes.root} container alignItems="center" justifyContent="space-between">
      <Grid
        item
        md={9}
        container
        spacing={2}
        alignItems="flex-start"
        justifyContent="flex-start"
        style={{ flexWrap: "nowrap" }}
      >
        <Grid item>
          <Mail size={20} color={COLOR_BLACK} style={{ marginTop: 2 }} />
        </Grid>
        <Grid item>
          <Typography variant="body1" color="textPrimary">
            {alertText}
          </Typography>
        </Grid>
      </Grid>
      {onCancelInvitation ? (
        <Grid item md={3} className={classes.action}>
          <Button variant="outlined" size="small" onClick={onCancelInvitation}>
            Cancel invitation
          </Button>
        </Grid>
      ) : null}
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    background: alpha(theme.palette.info.main, 0.1),
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(1),
    flexWrap: "wrap",
  },
  action: {
    marginRight: -16,
    [theme.breakpoints.down("sm")]: {
      marginTop: 16,
    },
  },
}))

type InvitationSentAlertProps = {
  alertText: string
  onCancelInvitation?: () => void
}

export default InvitationSentAlert
