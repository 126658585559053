import { RouteComponentProps } from "react-router-dom"
import { APP_MAX_WIDTH } from "../../../constants"
import { Grid, Theme, makeStyles } from "@material-ui/core"
import ProjectCardTitle from "../../../projects/components/ProjectCardTitle"
import ProjectDetails from "../../../projects/components/ProjectDetails"
import { useProject } from "../../../projects/hooks/use-project"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const ProjectBasicRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const { project } = useProject(match.params.projectId, queryOptions)
  const classes = useStyles()
  if (!project) return null

  return (
    <Grid container alignItems="center" direction="column" style={{ marginTop: project.archived ? 16 : "auto" }}>
      <Grid item container alignItems="center" direction="column" spacing={2} className={classes.container}>
        <Grid item className={classes.projectTitleGrid}>
          <ProjectCardTitle project={project} />
        </Grid>
        <Grid item className={classes.projectDetailsGrid}>
          <ProjectDetails projectId={project.id} />
        </Grid>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: theme.palette.common.white,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(2),
    maxWidth: APP_MAX_WIDTH,
  },
  projectTitleGrid: { width: "100%" },
  projectDetailsGrid: { width: "100%" },
}))

export default ProjectBasicRoute

type RouteParams = { projectId: string }
