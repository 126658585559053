export class Storage {
  private _key: string
  private _prefix = "starbrix_"
  private _value: string | null
  constructor(key: string, value?: any) {
    if (!window.localStorage) {
      throw new Error("Localstorage not available")
    }
    this._key = this._prefix + key
    if (value) {
      this._value = JSON.stringify(value)
      this.setValue(value)
    } else {
      this._value = window.localStorage.getItem(this._key) || null
    }
  }

  getValue(deserialize?: (v: string) => any) {
    this._value = window.localStorage.getItem(this._key)
    if (this._value == null) return null
    if (typeof deserialize === "function") {
      return deserialize(this._value)
    }
    return JSON.parse(this._value)
  }

  setValue<T = string>(value: T, serialize?: (v: T) => string): T {
    try {
      if (serialize) {
        this._value = serialize(value)
      }
      this._value = JSON.stringify(value)
      window.localStorage.setItem(this._key, this._value)
      return value
    } catch (error) {
      throw new Error(`Can not serialize ${this._value}`)
    }
  }

  resetValue() {
    this._value = null
    window.localStorage.removeItem(this._key)
    return null
  }
}
