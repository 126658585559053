import { RouteComponentProps } from "react-router-dom"
import { useProject } from "../../../projects/hooks/use-project"
import { useCalendarEventMutations } from "../../../calendar-events/hooks/use-calendar-event-mutations"
import { NewCalendarEventData, useGetCalendarEventsQuery } from "../../../calendar-events/api"
import { NewTaskData, useGetTasksQuery } from "../../../tasks/api"
import { useTaskMutations } from "../../../tasks/hooks/use-task-mutations"
import { useTodoMutations } from "../../../todos/hooks/use-todo-mutations"
import { NewTodoData, useGetTodosQuery } from "../../../todos/api"
import React from "react"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useGetOrganisationByIdQuery } from "../../../organisations/api"
import Calendar from "../../../calendar-events/components/Calendar"
import { adaptCalendarEvent, adaptRecurringEvent, adaptTask, adaptTodo } from "../../../calendar-events/utils"
import { keyBy, merge } from "lodash"
import ErrorList from "../../../components/ErrorList"
import { StringMap } from "../../../types/common"
import { useI18n } from "../../../hooks"
import { useAppContext } from "../../../hooks/use-app-context"
import { getHolidaysQueryFromContext } from "../../../context-options/utils"
import { useGetHolidaysQuery } from "../../../holidays/api"

const queryOptions = { refetchOnMountOrArgChange: true } as const

const useLoader = ({ projectId }: { projectId: string }) => {
  const translations = useTranslations()
  const {
    appContext: { mainContext },
  } = useAppContext()
  const queryErrors = {} as QueryErrors
  const queryParams = { project: projectId }
  const holidaysQuery = getHolidaysQueryFromContext(mainContext)

  const { project, isLoading: isLoadingProject, isError: isErrorProject } = useProject(projectId, queryOptions)
  const {
    data: calendarEvents = [],
    isLoading: isLoadingCalendarEvents,
    isError: isErrorCalendarEvents,
  } = useGetCalendarEventsQuery(queryParams, queryOptions)
  const {
    data: tasks = [],
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
  } = useGetTasksQuery(queryParams, queryOptions)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
  } = useGetTodosQuery(queryParams, queryOptions)

  const organisationId = project && project?.isOrgProject ? project.maintainerId : ""
  const skipFetchingOrganisation = !organisationId
  const {
    data: organisation,
    isLoading: isLoadingOrganisation,
    isError: isErrorOrganisation,
  } = useGetOrganisationByIdQuery(organisationId, {
    ...queryOptions,
    skip: skipFetchingOrganisation,
  })

  const {
    data: holidays = [],
    isLoading: isLoadingHolidays,
    isError: isErrorHolidays,
  } = useGetHolidaysQuery(holidaysQuery, queryOptions)

  const isLoading =
    isLoadingProject ||
    isLoadingCalendarEvents ||
    isLoadingTasks ||
    isLoadingTodos ||
    isLoadingOrganisation ||
    isLoadingHolidays
  const isError =
    isErrorProject || isErrorCalendarEvents || isErrorTasks || isErrorTodos || isErrorOrganisation || isErrorHolidays

  if (isErrorProject) queryErrors.project = translations.fetchErrorProject
  if (isErrorCalendarEvents) queryErrors.calendarEvent = translations.fetchErrorCalendarEvents
  if (isErrorTasks) queryErrors.task = translations.fetchErrorTasks
  if (isErrorTodos) queryErrors.todo = translations.fetchErrorTodos
  if (isErrorOrganisation) queryErrors.organisation = translations.fetchErrorOrganisation
  if (isErrorHolidays) queryErrors.holidays = translations.fetchErrorHolidays

  return {
    project,
    calendarEvents,
    tasks,
    todos,
    organisation,
    holidays,
    isLoading,
    isError,
    queryErrors,
  }
}

const ProjectCalendarRoute = ({ match }: RouteComponentProps<RouteParams>) => {
  const translations = useTranslations()
  const { projectId } = match.params
  const { project, calendarEvents, tasks, todos, isLoading, isError, queryErrors, holidays } = useLoader({
    projectId,
  })
  const { createCalendarEvent, updateInfo: updateCalendarEvent } = useCalendarEventMutations()
  const { createOrgTask, createUserTask, updatePlan: updateTask } = useTaskMutations()
  const { createTodo, updateInfo: updateTodo } = useTodoMutations()

  const { events, recurringEvents, entitiesById } = React.useMemo(() => {
    const taskEvents = tasks.filter((t) => t.plannedEndDate || t.plannedStartDate).map(adaptTask)
    const todoEvents = todos.filter((todo) => todo.dueDate).map(adaptTodo)
    const adaptedCalenderEvents = calendarEvents.filter((e) => !e.isRecurringEvent).map(adaptCalendarEvent)
    const adaptedRecurringEvents = calendarEvents.filter((e) => e.isRecurringEvent).map(adaptRecurringEvent)
    return {
      events: [...adaptedCalenderEvents, ...taskEvents, ...todoEvents],
      recurringEvents: adaptedRecurringEvents,
      entitiesById: merge(keyBy(calendarEvents, "id"), keyBy(tasks, "id"), keyBy(todos, "id")),
    }
  }, [calendarEvents, todos, tasks])

  if (isLoading) return <CircularProgress />
  if (!project) return <ErrorList errors={[translations.projectNotFoundError]} />
  if (isError) return <ErrorList errors={queryErrors} />

  const onAddCalendarEvent = (eventData: NewCalendarEventData) => {
    return createCalendarEvent({
      ...eventData,
      project: project.id,
      organisation: project.isOrgProject ? project.maintainerId : null,
    })
  }

  const onAddTask = async (taskData: Omit<NewTaskData, "projectId">) => {
    const response = project.isOrgProject
      ? await createOrgTask(project.maintainerId, { ...taskData, projectId: project.id })
      : await createUserTask(project.maintainerId, { ...taskData, projectId: project.id })
    if (response) return response.created
  }

  const onAddTodo = (todoData: NewTodoData) => {
    return createTodo({
      ...todoData,
      project: project.id,
      organisation: project.isOrgProject ? project.maintainerId : null,
    })
  }

  return (
    <Calendar
      events={events}
      recurringEvents={recurringEvents}
      entitiesById={entitiesById}
      canAddCalendarEvents={project.canCreateProjectCalendarEvents}
      canAddTasks={project.canCreateProjectTasks}
      canAddTodos={project.canCreateProjectTodos}
      onAddCalendarEvent={onAddCalendarEvent}
      onAddTask={onAddTask}
      onAddTodo={onAddTodo}
      onUpdateCalendarEvent={updateCalendarEvent}
      onUpdateTask={updateTask}
      onUpdateTodo={updateTodo}
      weekendDays={project.weekendDays}
      firstDayOfWeek={project.firstDayOfWeek}
      holidays={project.enableHolidays ? holidays : []}
    />
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translations")
  const translations = t || ({} as StringMap)

  const {
    projectNotFoundError = defaults.projectNotFoundError,
    fetchErrorCalendarEvents = defaults.fetchErrorCalendarEvents,
    fetchErrorProject = defaults.fetchErrorProject,
    fetchErrorTasks = defaults.fetchErrorTasks,
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorOrganisation = defaults.fetchErrorOrganisation,
    fetchErrorHolidays = defaults.fetchErrorHolidays,
  } = translations

  return {
    projectNotFoundError,
    fetchErrorCalendarEvents,
    fetchErrorProject,
    fetchErrorTasks,
    fetchErrorTodos,
    fetchErrorOrganisation,
    fetchErrorHolidays,
  }
}
const defaultTranslations = {
  projectNotFoundError: "Project not found",
  fetchErrorCalendarEvents: "Failed to fetch calendar events",
  fetchErrorProject: "Failed to fetch project",
  fetchErrorTasks: "Failed to fetch tasks",
  fetchErrorTodos: "Failed to fetch todos",
  fetchErrorOrganisation: "Failed to fetch organisation",
  fetchErrorHolidays: "Failed to fetch holidays",
}
type Translations = typeof defaultTranslations
type RouteParams = { projectId: string }
export default ProjectCalendarRoute

type QueryErrors = {
  calendarEvent?: string
  task?: string
  todo?: string
  project?: string
  organisation?: string
  holidays?: string
}
