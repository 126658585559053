import TaskNavbarDefault from "./TaskNavbarDefault"
import TaskNavbarMobile from "./TaskNavbarMobile"
import { useIsSmallScreen } from "../../../hooks/use-is-small-screen"
import { TaskViewModel } from "../../api/task"

const TaskNavbar = (props: TaskNavbarProps) => {
  const isSmallScreen = useIsSmallScreen()
  return isSmallScreen ? <TaskNavbarMobile {...props} /> : <TaskNavbarDefault {...props} />
}

type TaskNavbarProps = {
  task: TaskViewModel
  isFullWidth?: boolean
}

export default TaskNavbar
