import PersonInfoCard from "./PersonInfoCard"
import PersonBasicInfoForm from "./PersonBasicInfoForm"

import { Button, Card, Grid, makeStyles, Theme } from "@material-ui/core"
import { ArrowLeft, Plus } from "react-feather"
import { Link } from "react-router-dom"
import { useI18n, useRouter } from "../../hooks"
import { paths } from "../../paths"
import { useCustomer } from "../hooks/use-customer"
import { IPersonCustomerNormalized } from "../interfaces/customer-normalized"
import { StringMap } from "../../types/common"
import { useUrlWithContext } from "../../hooks/use-url-with-context"

const PersonCustomerHome = ({ customer }: PersonCustomerHomeProps) => {
  const classes = useStyles()
  const translations = useTranslations()
  const router = useRouter()
  const created = (router.query as { created: string }).created

  const { onChangeStatus } = useCustomer(customer.id)
  const { createPathWithContext } = useUrlWithContext()

  const onClickBackToCustomers = () => {
    const path = createPathWithContext(paths.orgCustomers(), { withSubContext: false })
    router.push(path)
  }
  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="center"
        component="header"
        data-test="page-header"
      >
        <Grid item>
          <Button variant="text" startIcon={<ArrowLeft size={16} />} onClick={onClickBackToCustomers}>
            {translations.allCustomersButtonLabel}
          </Button>
        </Grid>
        {created === "now" && (
          <Grid item>
            <Button
              size="small"
              to={createPathWithContext(paths.orgNewCustomer(), { withSubContext: false })}
              color="primary"
              component={Link}
              variant="contained"
              endIcon={<Plus height={16} />}
              data-test="add-another-customer-button"
            >
              {translations.addAnotherCustomerButtonLabel}
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid item container spacing={1}>
        <Grid item xs={12} md={4} lg={3}>
          <PersonInfoCard customer={customer} onChangeStatus={onChangeStatus} />
        </Grid>
        <Grid item xs={12} md={8} lg={9}>
          <Card variant="outlined" className={classes.card}>
            <PersonBasicInfoForm customer={customer} canUpdateBasicInfo={customer.canUpdate} />
          </Card>
        </Grid>
      </Grid>
    </Grid>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("customer")
  const translations = (t?.customerHomePage || {}) as StringMap

  const {
    allCustomersButtonLabel = defaults.allCustomersButtonLabel,
    addAnotherCustomerButtonLabel = defaults.addAnotherCustomerButtonLabel,
  } = translations

  return {
    allCustomersButtonLabel,
    addAnotherCustomerButtonLabel,
  }
}

const defaultTranslations = {
  allCustomersButtonLabel: "All customers",
  addAnotherCustomerButtonLabel: "Add another customer",
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: "calc(100% - 49px)",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  card: {
    padding: theme.spacing(2),
  },
}))

type Translations = typeof defaultTranslations
type PersonCustomerHomeProps = { customer: IPersonCustomerNormalized }
export default PersonCustomerHome
