import React from "react"
import { TaskViewModel } from "../api/task"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Dialog from "@material-ui/core/Dialog"
import IconButton from "@material-ui/core/Button"
import FullPath from "../../components/FullPath"
import Typography from "@material-ui/core/Typography"
import { makeTaskTree, TaskNode } from "../utils/tree"
import { useDateFormat } from "../../users/hooks/use-date-format"
import { DateTimeService } from "../../services/date-time-service"
import { Calendar, ExternalLink, User, PlusSquare, MinusSquare, X } from "react-feather"
import { makeStyles, Theme, useTheme } from "@material-ui/core"
import TaskDetails from "./TaskDetails"
import TaskCardTitle from "./TaskCardTitle"
import TaskEmptyMessage from "./TasksEmptyMessage"
import { NewBulkTaskData } from "../api"
import NewTaskDialog from "./NewTaskDialog"
import { useI18n } from "../../hooks"

const TaskListMobile = ({ rootTaskId, tasks, canAddTasks = false, onAddTask }: TaskListMobileProps) => {
  const [openTaskId, setOpenTaskId] = React.useState<string | null>(null)
  const onOpenTask = (taskId: string) => setOpenTaskId(taskId)
  const classes = useStyles()
  const openTask = tasks.find((task) => task.id === openTaskId)
  const nodes = React.useMemo(() => makeTaskTree(tasks, rootTaskId), [tasks, rootTaskId])
  const translations = useTranslations()

  const handleAddTask = (title: string, position: "top" | "bottom") => {
    if (canAddTasks && onAddTask) {
      const order = position === "top" ? 0 : tasks.length
      onAddTask({ title, order, taskCount: 1 })
    }
  }

  const newTaskDialog = canAddTasks ? <NewTaskDialog onAddTask={handleAddTask} /> : null

  if (!tasks.length) {
    return <TaskEmptyMessage>{newTaskDialog}</TaskEmptyMessage>
  }

  return (
    <>
      <div className={classes.header}>
        <Typography>{translations.tasks}</Typography>
        {newTaskDialog}
      </div>
      <TaskTree nodes={nodes} onOpenTask={onOpenTask} onAddTask={onAddTask} />
      {openTask ? (
        <Dialog open={openTaskId !== null} PaperProps={{ className: classes.dialogPaper }}>
          <div className={classes.dialogHeader}>
            <div className={classes.dialogHeaderLeft}>
              <FullPath
                projectLink={openTask.projectLink}
                taskLink={openTask.taskLink}
                ancestorTaskLinks={openTask.ancestorTaskLinks}
                projectOrTaskNumber={openTask.shownTaskNumber}
              />
            </div>
            <IconButton className={classes.closeButton} onClick={() => setOpenTaskId(null)}>
              <X size={24} />
            </IconButton>
          </div>
          <div className={classes.taskCardTitleContainer}>
            <TaskCardTitle task={openTask} />
          </div>
          <TaskDetails taskId={openTask.id} />
        </Dialog>
      ) : null}
    </>
  )
}

const TaskTree = ({ nodes, onOpenTask, onAddTask }: TaskTreeProps) => {
  const classes = useStyles()
  const theme = useTheme()
  const { dateFormat } = useDateFormat()
  const dateTimeService = new DateTimeService({ dateFormat })
  const [expandedTaskId, setExpandedTaskId] = React.useState<string | null>(null)
  const translations = useTranslations()

  return (
    <>
      {nodes.map((task) => {
        const { managers, plannedStartDate, plannedEndDate } = task
        const formattedPlannedStart = plannedStartDate
          ? dateTimeService.format(plannedStartDate)
          : translations.noStartDate
        const formattedPlannedEnd = plannedEndDate ? dateTimeService.format(plannedEndDate) : translations.noEndDate
        const hasSchedule = Boolean(plannedStartDate || plannedEndDate)
        const schedule = hasSchedule ? `${formattedPlannedStart} - ${formattedPlannedEnd}` : ""
        const formattedManagers = managers.map(({ name }) => name).join(", ")

        return (
          <Accordion
            key={task.id}
            expanded={expandedTaskId === task.id}
            onChange={(event) => {
              setExpandedTaskId(expandedTaskId === task.id ? null : task.id)
            }}
            className={classes.accordion}
          >
            <AccordionSummary>
              <div className={classes.summaryContainer}>
                <IconButton className={classes.summaryIconButton}>
                  {expandedTaskId === task.id ? (
                    <MinusSquare size={16} color={theme.palette.text.secondary} />
                  ) : (
                    <PlusSquare size={16} />
                  )}
                </IconButton>
                <div className={classes.summaryOverflow}>
                  <div className={classes.fullPathWrapper}>
                    <FullPath
                      projectLink={null}
                      taskLink={task.taskLink}
                      ancestorTaskLinks={task.ancestorTaskLinks}
                      projectOrTaskNumber={task.shownTaskNumber}
                      showTaskLink
                    />
                  </div>
                  <Typography variant="body2" component="h2" className={classes.summaryTypography} gutterBottom>
                    {task.shownTaskNumber}: {task.title}
                  </Typography>
                </div>
              </div>
              <IconButton
                className="maximize"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  onOpenTask(task.id)
                }}
              >
                <ExternalLink size={18} />
              </IconButton>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              {formattedManagers && (
                <div className={classes.managerInfo}>
                  <User className={classes.icon} size={16} />
                  <Typography variant="body2">{formattedManagers}</Typography>
                </div>
              )}

              {schedule ? (
                <div className={classes.scheduleInfo}>
                  <Calendar className={classes.icon} size={16} />
                  <Typography variant="body2">{schedule}</Typography>
                </div>
              ) : null}

              <TaskSubTasks task={task} onOpenTask={onOpenTask} onAddTask={onAddTask} />
            </AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )
}

const TaskSubTasks = ({ task, onOpenTask, onAddTask }: TaskSubTasksProps) => {
  const translations = useTranslations()
  const classes = useStyles()
  const canAddTasks = task.canCreate

  const handleAddTask = (title: string, position: "top" | "bottom") => {
    if (canAddTasks && onAddTask) {
      const order = position === "top" ? 0 : task.children.length
      onAddTask({ title, order, taskCount: 1, parentTaskId: task.id })
    }
  }

  const newTaskDialog = canAddTasks ? <NewTaskDialog onAddTask={handleAddTask} /> : null

  if (task.children.length === 0) {
    return <TaskEmptyMessage>{newTaskDialog}</TaskEmptyMessage>
  }

  return (
    <>
      <div className={classes.subTaskHeader}>
        <Typography variant="body2" className={classes.subTaskTitle}>
          {translations.subTasks}
        </Typography>
        {newTaskDialog}
      </div>
      <TaskTree nodes={task.children} onOpenTask={onOpenTask} onAddTask={onAddTask} />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
  managerInfo: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
    margin: theme.spacing(1, 0),
  },
  scheduleInfo: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.text.secondary,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  accordion: {
    "&.Mui-expanded": {
      "& .MuiAccordionDetails-root": {
        marginTop: -16,
      },
    },
    "& .MuiAccordionSummary-root": {
      paddingLeft: 0,
      paddingRight: 0,
      maxHeight: 56,
    },
    "& .MuiAccordionSummary-content": {
      justifyContent: "space-between",
      "&:hover": {
        "& .maximize": {
          opacity: 1,
        },
      },
    },
    "& .maximize": {
      opacity: 0,
      transition: theme.transitions.create("opacity"),
    },
    "&:hover": {
      "& .MuiAccordionSummary-content.Mui-expanded .maximize": {
        opacity: 1,
      },
    },
  },
  dialogPaper: {
    minWidth: 300,
    width: "100%",
    padding: theme.spacing(2),
  },
  dialogHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dialogHeaderLeft: {
    flex: 1,
  },
  closeButton: {
    marginRight: -20,
  },
  taskCardTitleContainer: {
    marginLeft: -12,
    marginBottom: 16,
  },
  summaryContainer: {
    display: "flex",
    alignItems: "center",
  },
  summaryIconButton: {
    minWidth: "unset",
    padding: 16,
    color: theme.palette.text.secondary,
  },
  summaryOverflow: {
    overflow: "hidden",
  },
  fullPathWrapper: {
    maxHeight: 20,
    overflow: "hidden",
    "& span": {
      color: theme.palette.primary.main,
    },
  },
  summaryTypography: {
    fontSize: 12,
    fontWeight: 500,
  },
  accordionDetails: {
    flexDirection: "column",
  },
  subTaskTitle: {
    fontWeight: 500,
    marginBottom: theme.spacing(),
  },
  subTaskHeader: {
    marginTop: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: theme.spacing(2),
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations = {} } = useI18n()
  return { ...defaults, ...translations, tasks: translations?.appNavBar?.tasksLabel || defaults.tasks }
}

const defaultTranslations = {
  tasks: "Tasks",
  subTasks: "Sub-tasks",
  noStartDate: "No start date",
  noEndDate: "No end date",
  noSchedule: "No schedule yet",
}

export default TaskListMobile

type TaskListMobileProps = {
  rootTaskId: string | null
  tasks: TaskViewModel[]
  canAddTasks?: boolean
  onAddTask: (newTask: Omit<NewBulkTaskData, "projectId" | "orgId">) => unknown
}

type TaskTreeProps = {
  nodes: TaskNode[]
  onOpenTask: (id: string) => void
  onAddTask: (newTask: Omit<NewBulkTaskData, "projectId" | "orgId">) => unknown
}

type TaskSubTasksProps = {
  task: TaskNode
  onOpenTask: (id: string) => void
  onAddTask: (newTask: Omit<NewBulkTaskData, "projectId" | "orgId">) => unknown
}

type Translations = typeof defaultTranslations
