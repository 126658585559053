/* eslint-disable no-console */
import React from "react"
import { useHistory } from "react-router-dom"
import { useSnackbar } from "notistack"
import { useUserContextOption } from "../../context-options/hooks/use-user-context-option"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { paths } from "../../paths"
import { useOrganisation } from "./use-organisation"
import { useOrganisationActions } from "./use-organisation-actions"

export const useConfirmDeleteOrganisation = ({ orgId, deleteErrorMessage, deleteSuccessMessage }: Params) => {
  const history = useHistory()
  const { userContext } = useUserContextOption()
  const { organisation } = useOrganisation(orgId)
  const { deleteOrganisation } = useOrganisationActions()
  const { enqueueSnackbar } = useSnackbar()
  const { createPathWithGivenContext } = useUrlWithContext()
  const [dialogOpen, setDialogOpen] = React.useState(false)
  const [status, setStatus] = React.useState<Status>("idle")
  const [confirmationText, setConfirmationText] = React.useState("")

  const isIdle = status === "idle"
  const isDeleting = status === "pending"
  const disableDeletion = !isIdle || isDeleting || confirmationText !== organisation?.name
  const closeDialog = () => setDialogOpen(false)
  const openDialog = () => setDialogOpen(true)
  const confirmAndCloseDialog = async () => {
    console.log("deleting organisation")
    setStatus("pending")
    setConfirmationText("")
    try {
      const deleted = await deleteOrganisation(orgId)
      console.log("organisation deleted", deleted)
      setStatus(deleted ? "success" : "error")
    } catch (error) {
      console.log(error)
      setStatus("error")
    }
  }

  /**
   * This effect handles when user clicks on the delete button and the delete request either succeeds or fails.
   */
  React.useEffect(() => {
    /**
     * When the status changes to success, we can assume that the organisation has been deleted successfully.
     * We can then show a success message and redirect the user to the home page.
     */
    if (status === "success") {
      enqueueSnackbar(deleteSuccessMessage, { variant: "success" })
      const userHomePage = createPathWithGivenContext({
        path: paths.home(),
        mainContextId: userContext?.id,
      })
      history.push(userHomePage)
    }

    /**
     * When the status changes to error, we can assume that the organisation has not been deleted successfully.
     * We can then show an error message.
     * And we reset the status to idle so that the user can try again.
     */
    if (status === "error") {
      enqueueSnackbar(deleteErrorMessage, { variant: "error" })
      setStatus("idle")
    }
  }, [
    status,
    enqueueSnackbar,
    history,
    deleteSuccessMessage,
    deleteErrorMessage,
    userContext,
    createPathWithGivenContext,
    userContext?.id,
  ])

  return {
    dialogOpen,
    closeDialog,
    openDialog,
    confirmAndCloseDialog,
    confirmationText,
    setConfirmationText,
    isDeleting,
    disableDeletion,
  }
}

type Params = {
  orgId: string
  deleteSuccessMessage: string
  deleteErrorMessage: string
}

type Status = "idle" | "success" | "pending" | "error"
