import { ReminderEntityType, ReminderTimeDetails } from "../api/reminder-response"

const oneMinute = 60 * 1000
const oneHour = 60 * oneMinute
const twentyFourHours = 24 * oneHour

const isAfterOneHour = (date: Date): boolean => {
  const afterOneHour = new Date(Date.now() + oneHour + oneMinute)
  return date > afterOneHour
}

const isAfterOneDay = (date: Date): boolean => {
  const after24Hours = new Date(Date.now() + twentyFourHours + oneMinute)
  return date > after24Hours
}

export const guessReminderTime = (
  refStartDate: Date | null,
  refEndDate: Date | null,
  parentEntityType?: ReminderEntityType
): ReminderTimeDetails | Date => {
  if (parentEntityType === "calendarEvent") {
    return guessCalendarEventReminderTime(refStartDate as Date)
  } else {
    return guessProjectReminderTime(refStartDate, refEndDate)
  }
}

const guessCalendarEventReminderTime = (refStartDate: Date): ReminderTimeDetails => {
  if (isAfterOneHour(refStartDate)) {
    return {
      value: 1,
      unit: "hours",
      reference: "plannedStart",
      refDate: refStartDate,
    }
  }

  return {
    value: 10,
    unit: "minutes",
    reference: "plannedStart",
    refDate: refStartDate,
  }
}

const guessProjectReminderTime = (refStartDate: Date | null, refEndDate: Date | null): ReminderTimeDetails | Date => {
  const isStartDateInFuture = refStartDate && isAfterOneHour(refStartDate)
  const isEndDateInFuture = refEndDate && isAfterOneHour(refEndDate)

  if (isStartDateInFuture) {
    const unit = isAfterOneDay(refStartDate) ? "days" : "hours"
    return {
      value: 1,
      unit: unit,
      reference: "plannedStart",
      refDate: refStartDate,
    }
  }

  if (isEndDateInFuture) {
    const unit = isAfterOneDay(refEndDate) ? "days" : "hours"
    return {
      value: 1,
      unit: unit,
      reference: "plannedEnd",
      refDate: refEndDate,
    }
  }

  const guessedDate = new Date(Date.now() + twentyFourHours)
  const currentUtcMinutes = guessedDate.getUTCMinutes()
  guessedDate.setUTCMilliseconds(0)
  guessedDate.setUTCSeconds(0)
  guessedDate.setUTCMinutes(currentUtcMinutes - (currentUtcMinutes % 5))
  return guessedDate
}
