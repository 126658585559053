import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { IMembershipNormalized } from "../../memberships/interfaces/membership-normalized"

const WorkspaceMemberCard = ({ member, canRemove, onRemove, removeButtonLabel }: WorkspaceMemberCardProps) => {
  return (
    <Paper variant="outlined" style={{ padding: 16, width: 320, maxWidth: "100%" }}>
      <Typography variant="h6" gutterBottom>
        {member.fullname}
      </Typography>
      <Typography variant="body2" color="textSecondary">
        {member.userEmail}
      </Typography>

      <br />

      {canRemove ? (
        <Button size="small" style={{ marginLeft: -4 }} color="primary" onClick={() => onRemove(member.id)}>
          {removeButtonLabel}
        </Button>
      ) : null}
    </Paper>
  )
}

export default WorkspaceMemberCard

type WorkspaceMemberCardProps = {
  member: IMembershipNormalized
  removeButtonLabel: string
  onRemove: (id: string) => void
  canRemove: boolean
}
