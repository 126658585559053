export const getTranslatedDefaultColumnNames = (params: GetTranslatedDefaultColumnNamesParams) => {
  const { columnName, translations } = params
  const columnNameMap: { [key: string]: string } = {
    "Input queue": translations.inputQueueLabel,
    "Not started": translations.notStartedLabel,
    Backlog: translations.backlogLabel,
    Planning: translations.planningLabel,
    Implementation: translations.implementationLabel,
    Review: translations.reviewLabel,
    Testing: translations.testingLabel,
    Deployment: translations.deploymentLabel,
    Completed: translations.completedLabel,
  }
  return columnNameMap[columnName] || columnName
}
type GetTranslatedDefaultColumnNamesParams = {
  columnName: string
  translations: {
    inputQueueLabel: string
    backlogLabel: string
    notStartedLabel: string
    planningLabel: string
    implementationLabel: string
    reviewLabel: string
    testingLabel: string
    deploymentLabel: string
    completedLabel: string
  }
}
