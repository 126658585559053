import { DateFormatOptionType, DateFormatSeparatorOptionType } from "../../organisations/constants"
import { useAppSelector } from "../../store"
import { getDefaultDateFormat } from "../../utils"
import { getDateFormat, getDateFormatSeparator } from "../store/selectors"

const defaultDateSeparator = "."

export const useDateFormat = () => {
  const _dateSeparator = useAppSelector(getDateFormatSeparator)
  const _dateFormat = useAppSelector(getDateFormat)
  const dateSeparator = _dateSeparator || defaultDateSeparator
  const dateFormat = makeDateFormat(_dateFormat, _dateSeparator)

  return {
    dateFormat,
    dateSeparator,
    dateTimeFormat: `${dateFormat} HH:mm`,
  }
}

const makeDateFormat = (dmy?: DateFormatOptionType, separator: DateFormatSeparatorOptionType = ".") => {
  if (dmy) {
    return dmy.split(".").join(separator).replace("DD", "dd").replace("YYYY", "yyyy")
  } else {
    return getDefaultDateFormat()
  }
}
