import React from "react"
import {
  Button,
  Checkbox,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Typography,
  Toolbar as MuiToolbar,
  Link as MuiLink,
  alpha,
} from "@material-ui/core"
import { Link } from "react-router-dom"
import { paths } from "../../paths"
import { find, uniq, without } from "lodash"
import { useConfirmDialog } from "../../components/ConfirmDialog"
import { WorkspaceStatus } from "../../workspaces/constants"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"
import { useSnackbar } from "notistack"
import { useOrgWorkspaces } from "../../workspaces/hooks/use-org-workspaces"
import doublet from "../../utils/doublet"
import { useUrlWithContext } from "../../hooks/use-url-with-context"

const useOrgWorkspacesTable = (orgId: string) => {
  const [selectedRows, setSelectedRows] = React.useState<string[]>([])
  const [filter, setFilter] = React.useState<WorkspaceFilter>("active")
  const [actionState, setActionState] = React.useState<ActionState>("idle")
  const { enqueueSnackbar } = useSnackbar()
  const translations = useTranslations(defaultTranslations)
  const { workspaces, activeWorkspaces, inactiveWorkspaces, canUpdateAnyWorkspace, updateWorkspaceStatus } =
    useOrgWorkspaces(orgId)

  // Computed values from state
  const selectedRowCount = selectedRows.length
  const showActiveWorkspaces = filter === "active"
  const showInactiveWorkspaces = filter === "inactive"
  const showAllWorkspaces = filter === "all"
  const formattedActivateBtnLabel = translations.activateBtnLabel.replace("{{count}}", `${selectedRowCount}`)
  const formattedDeactivateBtnLabel = translations.deactivateBtnLabel.replace("{{count}}", `${selectedRowCount}`)
  const activateOrDeactivateBtnLabel = showActiveWorkspaces ? formattedDeactivateBtnLabel : formattedActivateBtnLabel
  const workspacesToShow = showActiveWorkspaces
    ? activeWorkspaces
    : showInactiveWorkspaces
    ? inactiveWorkspaces
    : workspaces
  const idsOfWorkspacesToShow = workspacesToShow.map(({ id }) => id)
  const enableRowSelection = canUpdateAnyWorkspace && Boolean(workspacesToShow.length) && !showAllWorkspaces
  const disableActivateOrDeactivateBtn = !selectedRowCount || actionState === "busy"

  // Handlers
  const onConfirmActivateWorkspaces = async () => {
    setActionState("busy")
    for (let workspaceId of selectedRows) {
      const updateData = { status: WorkspaceStatus.ACTIVE }
      const [error] = await doublet(updateWorkspaceStatus, workspaceId, updateData)
      if (error) {
        const workspace = find(workspaces, { id: workspaceId })
        const defaultMessage = `Failed to activate workspace ${workspace?.name}`
        const message = error.message || defaultMessage
        enqueueSnackbar(message, { variant: "error" })
      } else {
        const message = `${selectedRowCount} workspaces activated`
        enqueueSnackbar(message, { variant: "success" })
      }
    }
    setSelectedRows([])
    setActionState("idle")
  }

  const onConfirmDeactivateWorkspaces = async () => {
    setActionState("busy")
    for (let workspaceId of selectedRows) {
      const updateData = { status: WorkspaceStatus.INACTIVE }
      const [error] = await doublet(updateWorkspaceStatus, workspaceId, updateData)
      if (error) {
        const workspace = find(workspaces, { id: workspaceId })
        const defaultMessage = `Failed to deactivate workspace ${workspace?.name}`
        const message = error.message || defaultMessage
        enqueueSnackbar(message, { variant: "error" })
      } else {
        const message = `${selectedRowCount} workspaces deactivated`
        enqueueSnackbar(message, { variant: "success" })
      }
    }
    setSelectedRows([])
    setActionState("idle")
  }

  const { confirm: confirmActivateWorkspaces } = useConfirmDialog({
    onConfirm: onConfirmActivateWorkspaces,
    primaryActionButtonLabel: formattedActivateBtnLabel,
    title: translations.activateDialogTitle,
    text: translations.activateDialogText.replace("{{count}}", `${selectedRowCount}`),
  })

  const { confirm: confirmDeactivateWorkspaces } = useConfirmDialog({
    onConfirm: onConfirmDeactivateWorkspaces,
    primaryActionButtonLabel: formattedDeactivateBtnLabel,
    title: translations.deactivateDialogTitle,
    text: translations.deactivateDialogText.replace("{{count}}", `${selectedRowCount}`),
  })

  const onChangeFilter = (filter: WorkspaceFilter) => {
    setSelectedRows([])
    setFilter(filter)
  }

  const onClickActivateOrDeactivate = () => {
    if (showActiveWorkspaces) {
      confirmDeactivateWorkspaces()
    } else {
      confirmActivateWorkspaces()
    }
  }

  return {
    selectedRows,
    setSelectedRows,
    filter,
    onChangeFilter,
    activateOrDeactivateBtnLabel,
    idsOfWorkspacesToShow,
    workspacesToShow,
    onClickActivateOrDeactivate,
    enableRowSelection,
    disableActivateOrDeactivateBtn,
  }
}

const OrgWorkspacesTable = ({ orgId }: { orgId: string }) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const { createPathWithGivenContext } = useUrlWithContext()

  const {
    selectedRows,
    setSelectedRows,
    filter,
    onChangeFilter,
    activateOrDeactivateBtnLabel,
    idsOfWorkspacesToShow,
    workspacesToShow,
    onClickActivateOrDeactivate,
    enableRowSelection,
    disableActivateOrDeactivateBtn,
  } = useOrgWorkspacesTable(orgId)

  return (
    <div className={classes.tableRoot}>
      {/** Toolbar  */}
      <MuiToolbar variant="dense" disableGutters>
        <Grid container spacing={1} justifyContent={"space-between"}>
          <Grid item>
            {enableRowSelection && (
              <Button size="small" onClick={onClickActivateOrDeactivate} disabled={disableActivateOrDeactivateBtn}>
                {activateOrDeactivateBtnLabel}
              </Button>
            )}
          </Grid>
          <Grid item>
            <FormControl>
              <Select
                variant="outlined"
                value={filter}
                onChange={(e) => onChangeFilter(e.target.value as WorkspaceFilter)}
              >
                <MenuItem value={"all"}>{translations.allWorkspacesOption}</MenuItem>
                <MenuItem value={"active"}>{translations.activeWorkspacesOption}</MenuItem>
                <MenuItem value={"inactive"}>{translations.inactiveWorkspacesOption}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </MuiToolbar>
      {/** Table  */}
      <TableContainer component={Paper} elevation={0}>
        <Table aria-label="workspaces table">
          <TableHead>
            <TableRow>
              {enableRowSelection && (
                <TableCell padding="checkbox">
                  <Checkbox
                    size="small"
                    color="primary"
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedRows([...idsOfWorkspacesToShow])
                      } else {
                        setSelectedRows([])
                      }
                    }}
                    checked={Boolean(selectedRows.length && selectedRows.length === idsOfWorkspacesToShow.length)}
                    indeterminate={Boolean(selectedRows.length && selectedRows.length !== idsOfWorkspacesToShow.length)}
                  />
                </TableCell>
              )}
              <TableCell padding="checkbox">{translations.workspaceNameHeader}</TableCell>
              <TableCell padding="checkbox">{translations.workspaceManagerHeader}</TableCell>
              <TableCell padding="checkbox">{translations.workspaceMemberHeader}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {workspacesToShow.map(($workspace) => (
              <TableRow key={$workspace.id}>
                {enableRowSelection && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={selectedRows.includes($workspace.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedRows((prev) => uniq([...prev, $workspace.id]))
                        } else {
                          setSelectedRows((prev) => without(prev, $workspace.id))
                        }
                      }}
                    />
                  </TableCell>
                )}
                <TableCell padding="checkbox">
                  {$workspace.homePage ? (
                    <MuiLink
                      component={Link}
                      to={createPathWithGivenContext({
                        path: paths.home(),
                        mainContextId: $workspace.orgId,
                        subContextId: $workspace.id,
                      })}
                    >
                      {$workspace.name}
                    </MuiLink>
                  ) : (
                    $workspace.name
                  )}
                </TableCell>
                <TableCell padding="checkbox">
                  {$workspace.managers.map(($option) => $option.name).join(", ") || "-"}
                </TableCell>
                <TableCell padding="checkbox">
                  {$workspace.members.map(($option) => $option.name).join(", ") || "-"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!idsOfWorkspacesToShow.length && (
          <Typography className="noData" color="textSecondary" variant="subtitle1">
            {translations.noWorkspacesText}
          </Typography>
        )}
      </TableContainer>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  pageContainer: {
    padding: theme.spacing(0, 2),
    maxWidth: 1440,
    margin: "auto",
  },
  header: {
    padding: theme.spacing(2, 0),
  },
  tableRoot: {
    maxWidth: 1440,
    borderRadius: 7,
    padding: theme.spacing(1, 0),
    width: "100%",
    background: theme.palette.common.white,
    "& .MuiToolbar-root": {
      padding: theme.spacing(0, 2),
      "& .MuiButton-root": {
        marginRight: theme.spacing(),
        transition: theme.transitions.create("background"),
        background: alpha(theme.palette.primary.main, 0.05),
        "&:hover": {
          background: alpha(theme.palette.primary.main, 0.1),
        },
      },
      "& .MuiSelect-outlined": {
        padding: "6px 9px",
        paddingRight: 32,
        fontSize: 14,
      },
    },
    "& .MuiTableContainer-root": {
      borderTop: `1px solid ${theme.palette.divider}`,
      borderRadius: 0,
      "& .MuiTableRow-root th": {
        textTransform: "uppercase",
        fontSize: 12,
        color: theme.palette.text.secondary,
        borderBottom: `1px solid ${theme.palette.divider}`,
      },
      "& .MuiTableCell-paddingCheckbox": {
        padding: theme.spacing(0.5),
        width: "auto",
      },
      "& .noData": {
        textAlign: "center",
        padding: 20,
      },
    },
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("workspace")
  const translations = (t?.workspacesPage || {}) as StringMap
  const membersPageTranslations = (t?.membersPage || {}) as StringMap

  const {
    deactivateBtnLabel = defaults.deactivateBtnLabel,
    deactivateDialogTitle = defaults.deactivateDialogTitle,
    deactivateDialogText = defaults.deactivateDialogText,
    activateBtnLabel = defaults.activateBtnLabel,
    activateDialogTitle = defaults.activateDialogTitle,
    activateDialogText = defaults.activateDialogText,
    allWorkspacesOption = defaults.allWorkspacesOption,
    activeWorkspacesOption = defaults.activeWorkspacesOption,
    inactiveWorkspacesOption = defaults.inactiveWorkspacesOption,
    workspaceNameHeader = defaults.workspaceNameHeader,
    workspaceMemberHeader = defaults.workspaceMemberHeader,
    noWorkspacesText = defaults.noWorkspacesText,
    workspaces = defaults.workspaces,
    addBtnLabel = defaults.addBtnLabel,
  } = translations

  const workspaceManagerHeader = membersPageTranslations.managerHeaderText || defaults.workspaceManagerHeader

  return {
    deactivateBtnLabel,
    deactivateDialogTitle,
    deactivateDialogText,
    activateBtnLabel,
    activateDialogTitle,
    activateDialogText,
    allWorkspacesOption,
    activeWorkspacesOption,
    inactiveWorkspacesOption,
    workspaceNameHeader,
    workspaceManagerHeader,
    workspaceMemberHeader,
    noWorkspacesText,
    workspaces,
    addBtnLabel,
  }
}

const defaultTranslations = {
  deactivateBtnLabel: "Deactivate selected workspaces",
  deactivateDialogTitle: "Deactivate workspaces",
  deactivateDialogText: "Are you sure you want to deactivate {{count}} workspaces?",
  activateBtnLabel: "Activate selected workspaces",
  activateDialogTitle: "Activate workspaces",
  activateDialogText: "Are you sure you want to activate {{count}} workspaces?",
  allWorkspacesOption: "All workspaces",
  activeWorkspacesOption: "Active workspaces",
  inactiveWorkspacesOption: "Inactive workspaces",
  workspaceNameHeader: "Name",
  workspaceMemberHeader: "Members",
  workspaceManagerHeader: "Responsible",
  noWorkspacesText: "No workspaces",
  workspaces: "Workspaces",
  addBtnLabel: "Add workspace",
}

type WorkspaceFilter = "all" | "active" | "inactive"
type ActionState = "idle" | "busy"
type Translations = typeof defaultTranslations

export default OrgWorkspacesTable
