import { StringMap } from "../types/common"
import { useI18n } from "./use-i18n"

export const useDateTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")
  const { translations: commonTranslations } = useI18n("common")
  const dateFormat = translations.dateFormatSelectWidget || ({} as StringMap)
  const daysTranslations = commonTranslations.days || defaultDaysTranslations
  const monthsTranslations = commonTranslations.months || defaultMonthsTranslations

  const { dayLabel = defaults.dayLabel, monthLabel = defaults.monthLabel } = dateFormat
  const { long, short } = commonTranslations.week || ({} as StringMap)
  const weekLabel = long || defaults.weekLabel
  const shortWeekLabel = short || defaults.shortWeekLabel
  const fullDayNames = formatTranslationsToArray(daysTranslations.long || defaultDaysTranslations.long)
  const abbreviatedDayNames = formatTranslationsToArray(daysTranslations["3alpha"] || defaultDaysTranslations["3alpha"])
  const shortDayNames = formatTranslationsToArray(daysTranslations["2alpha"] || defaultDaysTranslations["2alpha"])
  const narrowDayNames = formatTranslationsToArray(daysTranslations["1alpha"] || defaultDaysTranslations["1alpha"])
  const fullMonthNames = formatTranslationsToArray(monthsTranslations.long || defaultMonthsTranslations.long)
  const shortMonthsNames = formatTranslationsToArray(monthsTranslations.short || defaultMonthsTranslations.short)

  return {
    dayLabel,
    weekLabel,
    shortWeekLabel,
    monthLabel,
    fullDayNames,
    abbreviatedDayNames,
    shortDayNames,
    narrowDayNames,
    fullMonthNames,
    shortMonthsNames,
  }
}

const formatTranslationsToArray = (translations: StringMap) => {
  return Object.keys(translations).map((key) => translations[key])
}

const defaultTranslations = {
  dayLabel: "Day",
  weekLabel: "Week",
  shortWeekLabel: "wk",
  monthLabel: "Month",
  fullDayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
  abbreviatedDayNames: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  shortDayNames: ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"],
  narrowDayNames: ["S", "M", "T", "W", "T", "F", "S"],
  fullMonthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  shortMonthsNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
}
const defaultDaysTranslations = {
  long: {
    "0": "Sunday",
    "1": "Monday",
    "2": "Tuesday",
    "3": "Wednesday",
    "4": "Thursday",
    "5": "Friday",
    "6": "Saturday",
  },
  "3alpha": {
    "0": "Sun",
    "1": "Mon",
    "2": "Tue",
    "3": "Wed",
    "4": "Thu",
    "5": "Fri",
    "6": "Sat",
  },
  "2alpha": {
    "0": "Su",
    "1": "Mo",
    "2": "Tu",
    "3": "We",
    "4": "Th",
    "5": "Fr",
    "6": "Sa",
  },
  "1alpha": {
    "0": "S",
    "1": "M",
    "2": "T",
    "3": "W",
    "4": "T",
    "5": "F",
    "6": "S",
  },
}
const defaultMonthsTranslations = {
  long: {
    "1": "January",
    "2": "February",
    "3": "March",
    "4": "April",
    "5": "May",
    "6": "June",
    "7": "July",
    "8": "August",
    "9": "September",
    "10": "October",
    "11": "November",
    "12": "December",
  },
  short: {
    "1": "Jan",
    "2": "Feb",
    "3": "Mar",
    "4": "Apr",
    "5": "May",
    "6": "Jun",
    "7": "Jul",
    "8": "Aug",
    "9": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec",
  },
}

type Translations = typeof defaultTranslations
