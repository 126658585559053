import InlineEditableText from "../../../components/InlineEditableText"
import Tooltip from "@material-ui/core/Tooltip"
import ProjectMenu from "./ProjectMenu"
import ProjectNavTabs from "./ProjectNavTabs"
import ProjectPlan from "./ProjectPlan"
import ProjectBoardColumnChooser from "./ProjectBoardColumnChooser"
import FullscreenToggleButton from "./FullscreenToggleButton"
import { makeStyles, Theme } from "@material-ui/core"
import { useProjectMutations } from "../../hooks/use-project-mutations"
import { ProjectViewModel } from "../../api/project"

const ProjectNavbarDefault = ({ project, isFullWidth = false }: ProjectNavbarProps) => {
  const actions = useProjectMutations()
  const classes = useStyles({ isFullWidth })

  const isTitleEditable = Boolean(project.canUpdateDetails)
  const isProjectNumberEditable = Boolean(project.canUpdateDetails)
  const updateTitle = (title: string) => actions.updateInfo(project.id, { title })
  const updateProjectNumber = (customProjectNumber: string) => {
    actions.updateProjectNumber(project.id, customProjectNumber)
  }

  return (
    <>
      <div className={classes.container}>
        {/*Left side of the navbar with project number, project title and plan */}
        <div className={classes.navbarLeftWrapper}>
          <InlineEditableText
            isEditable={isProjectNumberEditable}
            value={project.shownProjectNumber}
            onSave={updateProjectNumber}
            TypographyProps={{ variant: "subtitle1", style: { fontWeight: 500 } }}
          />

          <span className={classes.divider} />

          <Tooltip title={`${project.shownProjectNumber}: ${project.title}`}>
            <div style={{ overflow: "hidden", minWidth: "40px" }}>
              <InlineEditableText
                isEditable={isTitleEditable}
                value={project.title}
                onSave={updateTitle}
                TypographyProps={{ variant: "subtitle1", style: { fontWeight: 500 } }}
              />
            </div>
          </Tooltip>

          <ProjectPlan project={project} />
        </div>
        {/*Right side of the navbar with project Navigation tabs and icon menus */}
        <div className={classes.navbarRightWrapper}>
          <ProjectNavTabs project={project} />
          <div className={classes.iconButtons}>
            <FullscreenToggleButton />
            <ProjectBoardColumnChooser project={project} />
            <ProjectMenu project={project} />
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles<Theme, StyleProps>((theme: Theme) => ({
  container: {
    margin: theme.spacing(-1.5, "auto", 0, "auto"),
    zIndex: 1,
    width: ({ isFullWidth }) => (isFullWidth ? "100%" : 1440),
    maxWidth: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap",
  },
  navbarLeftWrapper: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
  },
  navbarRightWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    flexWrap: "nowrap",
  },
  divider: {
    width: 1,
    borderRight: `1px solid ${theme.palette.grey[500]}`,
    height: theme.spacing(2),
  },
  iconButtons: {
    display: "flex",
    alignItems: "center",
    paddingRight: theme.spacing(1),
    "& button": {
      background: theme.palette.divider,
      borderRadius: theme.shape.borderRadius,
      padding: theme.spacing(0.5),
      marginLeft: theme.spacing(1),
    },
  },
}))

type ProjectNavbarProps = {
  project: ProjectViewModel
  isFullWidth?: boolean
}

type StyleProps = {
  isFullWidth: boolean
}

export default ProjectNavbarDefault
