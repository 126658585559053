import { isAfter } from "date-fns"
import { DateTimeService } from "../../services/date-time-service"
import { useAuthUserLocale } from "../../users/hooks/use-auth-user-locale"
import { ProjectViewModel } from "../api/project"
import { useProjectMutations } from "./use-project-mutations"

export const useProjectPlannedStart = (project: ProjectViewModel) => {
  const actions = useProjectMutations()
  const translations = useTranslations()
  const { dateFormat, timeZone } = useAuthUserLocale()

  const { enableTimeComponent, lockPlannedStartDate } = project
  const dateTimeService = new DateTimeService({ enableTimeComponent, dateFormat, timeZone })
  const canUpdatePlannedStart = Boolean(lockPlannedStartDate && project.canUpdatePlan)
  const plannedStartDate = project.plannedStartDate
    ? dateTimeService.removeTimezoneOffset(project.plannedStartDate)
    : null

  const formattedPlannedStart = plannedStartDate ? dateTimeService.format(plannedStartDate) : translations.noStartDate

  const plannedStartDue = plannedStartDate
    ? project.enableTimeComponent
      ? dateTimeService.endOfDay(new Date(plannedStartDate))
      : new Date(plannedStartDate)
    : null

  const now = new Date()
  const isPlannedStartPastDue =
    plannedStartDue && project.isNotStarted
      ? isAfter(now, dateTimeService.removeTimezoneOffset(plannedStartDue))
      : false

  const updatePlannedStart = (newStart: Date | null) => {
    if (!newStart) {
      actions.updatePlan(project.id, { plannedStartDate: "" })
    } else if (newStart !== plannedStartDate) {
      actions.updatePlan(project.id, { plannedStartDate: newStart.toISOString() })
    }
  }

  return {
    canUpdatePlannedStart,
    plannedStartDate,
    formattedPlannedStart,
    isPlannedStartPastDue,
    updatePlannedStart,
    isInheritedFromTasks: !project.lockPlannedStartDate,
  }
}

export const useProjectPlannedEnd = (project: ProjectViewModel) => {
  const actions = useProjectMutations()
  const translations = useTranslations()
  const { dateFormat, timeZone } = useAuthUserLocale()

  const { enableTimeComponent, lockPlannedEndDate } = project
  const dateTimeService = new DateTimeService({ enableTimeComponent, dateFormat, timeZone })
  const canUpdatePlannedEnd = Boolean(lockPlannedEndDate && project.canUpdatePlan)
  const plannedEndDate = project.plannedEndDate ? dateTimeService.removeTimezoneOffset(project.plannedEndDate) : null

  const formattedPlannedEnd = plannedEndDate ? dateTimeService.format(plannedEndDate) : translations.noEndDate

  const plannedEndDue = plannedEndDate
    ? project.enableTimeComponent
      ? dateTimeService.endOfDay(new Date(plannedEndDate))
      : new Date(plannedEndDate)
    : null

  const now = new Date()
  const isPlannedEndPastDue =
    plannedEndDue && (project.isNotStarted || project.isInProgress)
      ? isAfter(now, dateTimeService.removeTimezoneOffset(plannedEndDue))
      : false

  const updatePlannedEnd = (newEnd: Date | null) => {
    if (!newEnd) {
      actions.updatePlan(project.id, { plannedEndDate: "" })
    } else if (newEnd !== plannedEndDate) {
      actions.updatePlan(project.id, { plannedEndDate: newEnd.toISOString() })
    }
  }

  return {
    canUpdatePlannedEnd,
    plannedEndDate,
    formattedPlannedEnd,
    isPlannedEndPastDue,
    updatePlannedEnd,
    isInheritedFromTasks: !project.lockPlannedEndDate,
  }
}

const useTranslations = () => {
  return {
    noStartDate: "No start date",
    noEndDate: "No end date",
  }
}
