import CircularProgress from "@material-ui/core/CircularProgress"
import ListItem, { ListItemProps } from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"

import { LogOut as LogOutIcon } from "react-feather"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import { useState } from "react"
import { NetworkState } from "../../types/common"
import { COLOR_BLACK } from "../../constants"
import { useAppDispatch } from "../../store"
import { LogoutUser } from "../../users/store/actions"

const useLogoutButtonStyles = makeStyles((theme: Theme) => ({
  icon: {
    minWidth: theme.spacing(5),
    "& svg": {
      color: theme.palette.common.black,
    },
  },
}))

const Logout = (props: ListItemProps = {}) => {
  const [status, setStatus] = useState<NetworkState>("idle")
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const classes = useLogoutButtonStyles()

  const handleLogout = async () => {
    setStatus("pending")

    try {
      const response = await dispatch(LogoutUser())
      if (response.type.match("rejected")) {
        throw new Error("Failed")
      }
      window.location.reload()
    } catch (error: any) {
      // eslint-disable-next-line no-console
      console.log(error)
      window.location.reload()
    }

    setStatus("idle")
  }

  const logOutIcon =
    status !== "pending" ? <LogOutIcon size={16} color={COLOR_BLACK} /> : <CircularProgress color="inherit" size={20} />

  const logOutText = status !== "pending" ? t("logout", "Logout") : t("loggingOut", "Logging out")

  return (
    <ListItem {...(props as any)} button onClick={handleLogout} data-test="logout-button">
      <ListItemIcon className={classes.icon}>{logOutIcon}</ListItemIcon>
      <ListItemText>{logOutText}</ListItemText>
    </ListItem>
  )
}

export default Logout
