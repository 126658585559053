import React from "react"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardContent from "@material-ui/core/CardContent"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import { FallbackProps } from "react-error-boundary"
import { useDispatch } from "react-redux"
import { Storage } from "../utils/local-storage"
import { REFRESH_TOKEN } from "../constants"
import { useI18n } from "../hooks"
import { ResetAuthState } from "../users/store/actions"
import { StringMap } from "../types/common"

const refreshTokenStore = new Storage(REFRESH_TOKEN)

const AppErrorFallback = ({ resetErrorBoundary }: FallbackProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const translations = useTranslations()

  const handleClickLogin = (e: React.MouseEvent) => {
    refreshTokenStore.resetValue()
    dispatch(ResetAuthState())
    resetErrorBoundary(e)
  }
  const handleClickReload = () => window.location.reload()

  return (
    <Card className={classes.card} variant="outlined">
      <CardContent>
        <Typography variant="h5" color="error">
          {translations.appFallbackErrorMessage}
        </Typography>
        <Typography variant="body2">{translations.appFallbackHelperMessage}</Typography>
      </CardContent>
      <CardActions>
        <Button size="small" variant="contained" color="primary" onClick={handleClickReload} fullWidth>
          {translations.reload}
        </Button>
        <Button size="small" variant="outlined" color="secondary" onClick={handleClickLogin} fullWidth>
          {translations.login}
        </Button>
      </CardActions>
    </Card>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    minWidth: 275,
    maxWidth: 550,
    margin: theme.spacing(8, 0, 6, 0),
    padding: theme.spacing(2),
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = t || ({} as StringMap)
  const {
    appFallbackErrorMessage = defaults.appFallbackErrorMessage,
    appFallbackHelperMessage = defaults.appFallbackHelperMessage,
    reload = defaults.reload,
    login = defaults.login,
  } = translations
  return { appFallbackErrorMessage, appFallbackHelperMessage, reload, login }
}
const defaultTranslations = {
  appFallbackErrorMessage: "Oops! Something went wrong",
  appFallbackHelperMessage: "Please login if the reload button does not work",
  reload: "Reload",
  login: "Login",
}
type Translations = typeof defaultTranslations

export default AppErrorFallback
