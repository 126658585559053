import { DateTimeService } from "../../services/date-time-service"
import { RootNode } from "../types"
import { TimeZoneType } from "../../constants/timezones"
import { TaskViewModel } from "../../tasks/api/task"
import { ProjectViewModel } from "../../projects/api/project"

export const makeRootNode = ({ root, dateFormat, timeZone }: MakeRootNodeParams): RootNode => {
  const enableTimeComponent = Boolean(root.enableTimeComponent)
  const dateTimeService = new DateTimeService({
    enableTimeComponent,
    dateFormat,
  })
  const { plannedStartDate, plannedEndDate } = root
  const start = plannedStartDate ? dateTimeService.removeTimezoneOffset(new Date(plannedStartDate), timeZone) : null // date instance of planned start date or null
  const end = plannedEndDate ? dateTimeService.removeTimezoneOffset(new Date(plannedEndDate), timeZone) : null // date instance of planned end date or null
  const formattedStart = start && dateTimeService.format(start)
  const formattedEnd = end && dateTimeService.format(end)
  const isProjectNode = "canCreateProjectTasks" in root
  const canCreateTasks = isProjectNode ? root.canCreateProjectTasks : root.canCreate
  const lockPlannedEndDate = isProjectNode ? root.lockPlannedEndDate : true
  const lockPlannedStartDate = isProjectNode ? root.lockPlannedStartDate : true

  // Summary is the title with the planned start and end dates
  // Summary will be used as a page header while the grid is being printed
  // e.g. "PS2: Project 1 01/01/2020 - 01/02/2020"
  const serialNumber = isProjectNode
    ? root.customProjectNumber || root.projectNumber
    : root.customTaskNumber || root.taskNumber
  let summary = `${serialNumber}: ${root.title}`
  if (formattedStart) summary += ` ${formattedStart}`
  if (formattedEnd) summary += ` - ${formattedEnd}`

  // Gantt base and Gantt finish dates http://www.treegrid.com/Doc/GanttCompute.htm#CGanttBase
  // Gantt base date is the start date of the whole project. The tasks should not start before this date.
  const ganttBaseDate = start && lockPlannedStartDate ? start.getTime() : null
  const ganttFinishDate = end && lockPlannedEndDate ? end.getTime() : null
  const enableHolidays = isProjectNode ? Boolean(root.enableHolidays) : false

  return {
    type: isProjectNode ? "project" : "task",
    id: root.id,
    title: root.title,
    summary,
    enableTimeComponent,
    enableHolidays,
    dateFormat: dateTimeService.getDateFormat(),
    plannedStartDate: start?.getTime() || null, // Timezone adjusted start date in number of milliseconds
    plannedEndDate: end?.getTime() || null, // Timezone adjusted end date in number of milliseconds
    formattedStart, // Timezone adjusted start date formatted according to the date format
    formattedEnd, // Timezone adjusted end date formatted according to the date format
    originalPlannedEndDate: root.plannedEndDate, // original planned end date of the project before adjusting timezone
    originalPlannedStartDate: root.plannedStartDate, // original planned start date of the project before adjusting timezone
    isProjectNode: isProjectNode,
    isTaskNode: "canCreate" in root,
    canCreateTasks,
    projectId: isProjectNode ? root.id : root.projectId,
    lockPlannedStartDate,
    lockPlannedEndDate,
    isArchived: root.archived,
    belongsToUser: isProjectNode ? root.isUserProject : root.isUserTask,
    ganttBaseDate,
    ganttFinishDate,
    timeZone,
  }
}

type MakeRootNodeParams = {
  root: TaskViewModel | ProjectViewModel
  dateFormat: string
  timeZone: TimeZoneType
}
