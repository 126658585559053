import React from "react"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import UserGeneralSettings from "../../../users/components/UserGeneralSettings"
import UserNotificationSettings from "../../../users/components/UserNotificationSettings"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { useAppContext } from "../../../hooks/use-app-context"
import { useI18n, useRouter } from "../../../hooks"
import { Grid, Box, Typography } from "@material-ui/core"
import UserHolidays from "./holidays"

const UserSettingsRoute = () => {
  const classes = useStyles()
  const router = useRouter()
  const query = router.query as { tab?: string }
  const currentTab = query?.tab === "notifications" ? 1 : query?.tab === "holidays" ? 2 : 0
  const { appContext } = useAppContext()
  const { mainContext } = appContext
  const translations = useTranslations()

  if (!mainContext || mainContext.type !== "user") return null

  return (
    <div className={classes.container}>
      <Grid item style={{ marginBottom: 32 }}>
        <Typography variant="h5" data-test="page-header" style={{ fontWeight: 500 }}>
          <Box color="text.secondary" component="span">
            {mainContext.name}
          </Box>
          {" - "}
          {translations.settings}
        </Typography>
      </Grid>
      <Tabs
        variant="scrollable"
        value={currentTab}
        onChange={(e, newValue) => {
          const currentSearch = new URLSearchParams(router.location.search)
          currentSearch.set("tab", newValue === 1 ? "notifications" : newValue === 2 ? "holidays" : "general")
          router.push({ search: currentSearch.toString() })
        }}
        indicatorColor="primary"
        className={classes.tabs}
      >
        <Tab className={classes.tab} disableRipple label={translations.general} />
        <Tab className={classes.tab} disableRipple label={translations.notifications} />
        <Tab className={classes.tab} disableRipple label={translations.holidays} />
      </Tabs>
      <TabPanel currentTab={currentTab} index={0} element={<UserGeneralSettings />} />
      <TabPanel currentTab={currentTab} index={1} element={<UserNotificationSettings />} />
      <TabPanel currentTab={currentTab} index={2} element={<UserHolidays />} />
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 800,
    margin: "auto",
    paddingTop: 8,
    paddingBottom: 32,
    borderRadius: 8,
  },
  tabs: { borderBottom: `1px solid ${theme.palette.divider}` },
  tab: { minWidth: 120 },
  tabPanel: { padding: theme.spacing(3, 0) },
}))

function TabPanel({ element, currentTab, index }: TabPanelProps) {
  const classes = useStyles()
  const hidden = currentTab !== index
  return (
    <div role="tabpanel" hidden={hidden} aria-hidden={hidden} className={classes.tabPanel}>
      {currentTab === index && element}
    </div>
  )
}

const defaultTranslations = {
  settings: "Settings",
  general: "General",
  notifications: "Notifications",
  holidays: "Holidays",
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations = {} } = useI18n("translation")

  return {
    settings: translate("settings"),
    general: translate("general"),
    notifications: translate("notifications"),
    holidays: translate("holidays"),
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

type Translations = typeof defaultTranslations

type TabPanelProps = {
  element: React.ReactNode
  index: any
  currentTab: any
}

export default UserSettingsRoute
