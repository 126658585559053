import { TaskViewModel } from "../api/task"

export type TaskNode = TaskViewModel & {
  level: number
  children: TaskNode[]
}

export const makeTaskTree = (tasks: TaskViewModel[], rootTaskId: string | null = null): TaskNode[] => {
  const taskMap: { [key: string]: TaskNode } = {}

  tasks.forEach((task) => {
    taskMap[task.id] = { ...task, children: [], level: task.ancestors.length + 1 }
  })

  const rootTasks: TaskNode[] = []

  tasks.forEach((task) => {
    const taskNode = taskMap[task.id]
    const isRoot = checkIsRoot(task)

    if (isRoot) {
      rootTasks.push(taskNode)
    } else {
      const parentTaskNode = taskMap[task.parentTaskId]
      if (parentTaskNode) {
        parentTaskNode.children.push(taskNode)
        // Sort children based on the order field
        parentTaskNode.children.sort((a, b) => a.order - b.order)
      }
    }
  })

  rootTasks.sort((a, b) => a.order - b.order)

  return rootTasks

  function checkIsRoot(task: TaskViewModel): boolean {
    return task.parentTaskId === rootTaskId || !task.parentTaskId
  }
}

export const flattenTaskTree = (taskNodes: TaskNode[]): TaskViewModel[] => {
  const result: TaskViewModel[] = []

  function traverse(node: TaskNode) {
    result.push(node)
    node.children.forEach((child) => traverse(child))
  }

  taskNodes.forEach((node) => traverse(node))

  return result
}

export const orderTasksByTree = (tasks: TaskViewModel[]): TaskViewModel[] => {
  const taskTree = makeTaskTree(tasks)
  return flattenTaskTree(taskTree)
}
