import UserMenuIcon from "./UserMenuIcon"
import UserMenuPopper from "./UserMenuPopper"
import React from "react"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import AppLanguageSwitch from "../../LanguageSwitch"
import Logout from "../../Logout"
import ListItemText from "@material-ui/core/ListItemText"
import { useRef } from "react"
import { useAuthUser } from "../../../users/hooks/use-auth-user"
import { ListItemIcon } from "@material-ui/core"
import { MessageSquare } from "react-feather"
import { COLOR_BLACK } from "../../../constants"
import { Link } from "react-router-dom"
import { paths } from "../../../paths"
import { useUrlWithContext } from "../../../hooks/use-url-with-context"
import { useI18n } from "../../../hooks"

interface UserMenuProps {
  menuOpen: boolean
  anchorEl?: HTMLElement | null
  handleClose: () => void
  toggleUserMenu: (e: React.MouseEvent<HTMLDivElement>) => void
}

const UserMenu = ({ menuOpen, anchorEl, handleClose, toggleUserMenu }: UserMenuProps) => {
  const anchorRef = useRef<HTMLElement>(null)
  const { user } = useAuthUser()
  const { createPathWithContext } = useUrlWithContext()
  const translations = useTranslations()

  return (
    <>
      <UserMenuIcon onClick={toggleUserMenu} ref={anchorRef} />
      <UserMenuPopper menuOpen={menuOpen} anchorEl={anchorRef.current} handleClose={handleClose}>
        <List>
          <ListItem divider dense>
            <ListItemText
              primary={user?.fullname}
              secondary={user?.email}
              primaryTypographyProps={{
                variant: "body1",
              }}
              secondaryTypographyProps={{
                variant: "subtitle2",
                color: "textSecondary",
              }}
            />
          </ListItem>
          <ListItem>
            <AppLanguageSwitch />
          </ListItem>
          <Logout />
          <ListItem button component={Link} to={createPathWithContext(paths.support())}>
            <ListItemIcon style={{ minWidth: 40 }}>
              <MessageSquare size={16} color={COLOR_BLACK} />
            </ListItemIcon>
            <ListItemText>{translations.contactSupport}</ListItemText>
          </ListItem>
        </List>
      </UserMenuPopper>
    </>
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t = {} } = useI18n("translation")

  return {
    contactSupport: t.contactSupport || defaults.contactSupport,
  }
}

const defaultTranslations = {
  contactSupport: "Contact support",
}

type Translations = typeof defaultTranslations

export default UserMenu
