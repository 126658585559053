import ResourceManager from "../../components/ResourceManager"
import { useTodoMutations } from "../hooks/use-todo-mutations"
import { ResourceType } from "../../components/ResourceManager/ResourceManager"
import { useI18n } from "../../hooks/use-i18n"
import { StringMap } from "../../types/common"
import { TodoViewModel } from "../api/todo"

const TodoTaskWidget = ({ todo, taskOptions }: TodoTaskWidgetProps) => {
  const { updateTask } = useTodoMutations()
  const translations = useTranslations(defaultTranslations)
  const todoTask = todo.task ? [{ id: todo.task.id, name: todo.task.title }] : []

  const onAddTask = (task: string) => updateTask(todo.id, { taskId: task })
  const onRemoveTask = () => updateTask(todo.id, { taskId: null })

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={taskOptions}
      resources={todoTask}
      canAddResource={todo.canUpdate}
      canRemoveResource={todo.canUpdate}
      onAddResource={onAddTask}
      onRemoveResource={onRemoveTask}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection={false}
    />
  )
}

const useTranslations = (defaults = defaultTranslations) => {
  const { translations: t } = useI18n("todo")
  const translations = (t?.taskWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: "Task",
  emptyOptionsMessage: "No tasks to connect this todo",
  emptyResourcesMessage: "This todo is not connected to any task",
}

type TodoTaskWidgetProps = {
  todo: TodoViewModel
  taskOptions: ResourceType[]
}

export default TodoTaskWidget
