import ResourceManager from "../../components/ResourceManager"
import { StringMap } from "i18next"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import { useProjectMutations } from "../hooks/use-project-mutations"
import { useI18n } from "../../hooks/use-i18n"
import { ProjectViewModel } from "../api/project"

const ProjectParticipantsWidget = ({ project }: { project: ProjectViewModel }) => {
  const translations = useTranslations(defaultTranslations)
  const { membershipOptions } = useOrgOptions(project.maintainerId)
  const { addParticipants, removeParticipants } = useProjectMutations()
  const isNotManager = ({ id }: { id: string }) => project.managers.findIndex((m) => m.id === id) < 0

  return (
    <ResourceManager
      title={translations.title}
      resourceOptions={membershipOptions.filter(isNotManager)}
      resources={project.participants}
      onAddResources={(ids) => addParticipants(project.id, ids)}
      onRemoveResource={(id) => removeParticipants(project.id, [id])}
      canAddResource={project.canUpdateParticipants}
      canRemoveResource={project.canUpdateParticipants}
      emptyOptionsMessage={translations.emptyOptionsMessage}
      emptyResourcesMessage={translations.emptyResourcesMessage}
      isMultipleSelection
    />
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("project")
  const translations = (t?.participantsWidget || {}) as StringMap

  const {
    title = defaults.title,
    emptyOptionsMessage = defaults.emptyOptionsMessage,
    emptyResourcesMessage = defaults.emptyResourcesMessage,
  } = translations

  return {
    title,
    emptyOptionsMessage,
    emptyResourcesMessage,
  }
}

const defaultTranslations = {
  title: "Participants",
  emptyOptionsMessage: "No members in this organisation",
  emptyResourcesMessage: "No participant in this project",
}

type Translations = typeof defaultTranslations

export default ProjectParticipantsWidget
