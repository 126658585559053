import React from "react"
import Chip from "@material-ui/core/Chip"
import FormControl from "@material-ui/core/FormControl"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import MenuItem from "@material-ui/core/MenuItem"
import Checkbox from "@material-ui/core/Checkbox"
import ListItemText from "@material-ui/core/ListItemText"
import { makeStyles, Theme } from "@material-ui/core"
import { useI18n } from "../hooks"
import { entries } from "lodash"
import { ChevronDown } from "react-feather"

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const DAY_BY_INDEX = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
}

const sortSundayToLast = (d1: DayOfWeek, d2: DayOfWeek) => {
  if (d1 === 0) return 1
  if (d2 === 0) return -1
  return d1 - d2
}

const DaysSelect = ({ label = null, value, onChange, disabled }: DaysSelectProps) => {
  const classes = useStyles()
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value as any)
  }
  const { translations } = useI18n("common")
  const dayByIndex = translations?.days?.long || DAY_BY_INDEX
  const dayEntries = entries(translations?.days?.long) as [string, string][]
  const orderedDays: [string, string][] = [...dayEntries.slice(1), dayEntries[0]]

  return (
    <FormControl className={classes.formControl} disabled={disabled}>
      {label && <InputLabel id="day-select">Tag</InputLabel>}
      <Select
        labelId="day-select"
        id="day-select"
        multiple={Array.isArray(value)}
        value={value}
        onChange={handleChange}
        input={<DaySelectInput />}
        MenuProps={MenuProps}
        IconComponent={ChevronDown}
        renderValue={(selected) => {
          const selectedValues: DayOfWeek[] = Array.isArray(selected) ? [...selected] : [selected]
          const orderedSelectedValues = selectedValues.sort(sortSundayToLast)
          return (
            <div className={classes.chips}>
              {orderedSelectedValues.map((value) => (
                <Chip key={value} label={dayByIndex[value]} className={classes.chip} />
              ))}
            </div>
          )
        }}
      >
        {orderedDays.map(([dayIndexStr, dayLabel]) => {
          const dayIndex = Number.parseInt(dayIndexStr) as DayOfWeek
          return (
            <MenuItem key={dayIndex} value={dayIndex}>
              {Array.isArray(value) && <Checkbox color="primary" checked={value.indexOf(dayIndex) > -1} />}
              <ListItemText primary={dayLabel} />
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const DaySelectInput = (props: any) => {
  const classes = useStyles()
  return <OutlinedInput {...props} className={classes.dayInput} />
}
const MenuProps = {
  PaperProps: {
    "data-test": "day-select-menu",
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const useStyles = makeStyles((theme: Theme) => ({
  formControl: { width: "100%" },
  dayInput: {
    "& .MuiOutlinedInput-input": {
      paddingTop: 8,
      paddingBottom: 8,
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
    borderRadius: 2,
    background: `${theme.palette.primary.main}21`,
  },
}))

type DaysMultipleSelectProps = {
  label?: string | null
  value: DayOfWeek[]
  onChange: (value: DayOfWeek[]) => void
  disabled?: boolean
}

type DaySingleSelectProps = {
  label?: string | null
  value: DayOfWeek
  onChange: (value: DayOfWeek) => void
  disabled?: boolean
}

type DaysSelectProps = XOR<DaysMultipleSelectProps, DaySingleSelectProps>

export default DaysSelect
