import React from "react"
import Button from "@material-ui/core/Button"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { Link } from "react-router-dom"
import { makeStyles, alpha } from "@material-ui/core"
import { ICustomerNormalized } from "../../customers/interfaces/customer-normalized"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { paths } from "../../paths"
import { Settings } from "react-feather"
import { useOrgCustomers } from "../../customers/hooks/use-org-customers"
import { useI18n } from "../../hooks"
import { StringMap } from "../../types/common"

const OrgCustomerCards = ({ orgId }: { orgId: string }) => {
  const { customers, activeCustomers, inactiveCustomers } = useOrgCustomers(orgId)
  const [filter, setFilter] = React.useState("active")
  const classes = useStyles()
  const translations = useTranslations()
  const filteredCustomers =
    filter === "active" ? activeCustomers : filter === "inactive" ? inactiveCustomers : customers

  return (
    <div style={{ marginTop: 24 }}>
      <InputLabel className={classes.filterLabel}>{translations.filter}</InputLabel>
      <Select
        className={classes.filter}
        value={filter}
        onChange={(e) => setFilter(e.target.value as string)}
        variant="outlined"
      >
        <MenuItem value="all">
          <em>{translations.allCustomersOption}</em>
        </MenuItem>
        <MenuItem value={"active"}>{translations.activeCustomersOption}</MenuItem>
        <MenuItem value={"inactive"}>{translations.inactiveCustomersOption}</MenuItem>
      </Select>
      {filteredCustomers.map((customer) => (
        <OrgCustomerCard key={customer.id} customer={customer} />
      ))}
      {filteredCustomers.length === 0 && (
        <div className={classes.emptyMessage}>
          <Typography variant="body2" color="textSecondary">
            {translations.noCustomersText}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default OrgCustomerCards

const OrgCustomerCard = ({ customer }: { customer: ICustomerNormalized }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { createPathWithGivenContext } = useUrlWithContext()

  const customerHomePath = createPathWithGivenContext({
    path: paths.home(),
    mainContextId: customer.orgId,
    subContextId: customer.id,
  })

  const customerSettingsPath = createPathWithGivenContext({
    path: paths.customerSettings(),
    mainContextId: customer.orgId,
    subContextId: customer.id,
  })

  const defaultContact = customer.isOrgCustomer ? customer.defaultContact : null

  return (
    <Paper variant="outlined" className={classes.card}>
      <Typography variant="subtitle1" component={Link} to={customerHomePath} gutterBottom>
        {customer.fullname}
      </Typography>

      {customer.customerNumber ? (
        <Typography variant="body2" component="p" color="textSecondary">
          {translations.customerNumberHeader}: {customer.customerNumber}
        </Typography>
      ) : null}

      {defaultContact ? (
        <Typography variant="body2" component="p" color="textSecondary">
          {translations.customerContactHeader}: {defaultContact.firstname} {defaultContact.lastname}
        </Typography>
      ) : null}

      <br />

      <Button
        color="primary"
        className={classes.settingsButton}
        component={Link}
        to={customerSettingsPath}
        size="small"
        startIcon={<Settings size={14} />}
      >
        {translations.settings}
      </Button>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
      textDecoration: "none",
    },
    "& .text": {
      marginBottom: theme.spacing(0.5),
    },
  },
  settingsButton: {
    marginTop: theme.spacing(1),
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  filter: {
    minWidth: 120,
    width: "calc(100% - 24px)",
    marginLeft: theme.spacing(1.5),
  },
  filterLabel: {
    marginLeft: theme.spacing(2),
  },
  emptyMessage: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n("translation")
  const { translations: customerTranslations } = useI18n("customer")
  const customersPageTranslations = (customerTranslations?.customersPage || {}) as StringMap

  const {
    allCustomersOption = defaults.allCustomersOption,
    activeCustomersOption = defaults.activeCustomersOption,
    inactiveCustomersOption = defaults.inactiveCustomersOption,
    customerNameHeader = defaults.customerNameHeader,
    customerNumberHeader = defaults.customerNumberHeader,
    customerLanguageHeader = defaults.customerLanguageHeader,
    customerContactHeader = defaults.customerContactHeader,
    noCustomersText = defaults.noCustomersText,
  } = customersPageTranslations

  return {
    allCustomersOption,
    activeCustomersOption,
    inactiveCustomersOption,
    customerNameHeader,
    customerNumberHeader,
    customerLanguageHeader,
    customerContactHeader,
    noCustomersText,
    filter: translations.filter || defaults.filter,
    settings: translations.settings || defaults.settings,
  }
}

const defaultTranslations = {
  allCustomersOption: "All customers",
  activeCustomersOption: "Active customers",
  inactiveCustomersOption: "Inactive customers",
  customerNameHeader: "Customer name",
  customerNumberHeader: "Customer number",
  customerLanguageHeader: "Language",
  customerContactHeader: "Contact",
  noCustomersText: "No customers",
  filter: "Filter",
  settings: "Settings",
}

type Translations = typeof defaultTranslations
