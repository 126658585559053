import clsx from "clsx"
import React from "react"
import Typography from "@material-ui/core/Typography"
import IconButton from "@material-ui/core/IconButton"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import Checkbox from "@material-ui/core/Checkbox"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import { X, Plus } from "react-feather"
import { COLOR_BLACK, COLOR_LIGHT } from "../../constants"
import { makeStyles, Theme } from "@material-ui/core"
import { differenceBy, filter, truncate } from "lodash"

export const ResourceManager = ({
  title,
  resourceOptions,
  resources,
  onAddResource,
  onAddResources,
  onRemoveResource,
  canAddResource,
  canRemoveResource,
  emptyOptionsMessage,
  emptyResourcesMessage,
  isMultipleSelection,
}: ResourceManagerProps) => {
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null)

  const openResourceMenu = (e: React.MouseEvent<HTMLElement>) => {
    canAddResource && setMenuAnchor(e.currentTarget)
  }

  const onCloseMenu = (checked: string | string[]) => {
    setMenuAnchor(null)
    if (typeof checked === "string" && !isMultipleSelection) {
      onAddResource?.(checked)
    } else if (Array.isArray(checked) && isMultipleSelection) {
      if (checked.length) onAddResources?.(checked)
    }
  }

  return (
    <>
      <div className={clsx(classes.resourceHeader, canAddResource && "editable")} onClick={openResourceMenu}>
        <Typography component="h4" variant="body1" role="button">
          <strong>{title}</strong>
        </Typography>
        <IconButton className="plusIcon" onClick={openResourceMenu}>
          {canAddResource && <Plus size={18} color={COLOR_BLACK} />}
        </IconButton>
      </div>

      <ResourceList
        resources={resources}
        onRemoveResource={onRemoveResource}
        canRemoveResource={canRemoveResource}
        emptyResourcesMessage={emptyResourcesMessage}
        openResourceMenu={openResourceMenu}
      />
      <ResourceMenu
        anchorEl={menuAnchor}
        menuItems={differenceBy(resourceOptions, resources, "id")}
        onCloseMenu={onCloseMenu}
        emptyOptionsMessage={emptyOptionsMessage}
        isMultipleSelection={isMultipleSelection}
      />
    </>
  )
}

export const ResourceList = ({
  resources,
  onRemoveResource,
  canRemoveResource,
  emptyResourcesMessage,
  openResourceMenu,
}: ResourceListProps) => {
  const classes = useStyles()

  if (!resources.length) {
    return (
      <div className={classes.emptyMessageDisplay} onClick={openResourceMenu}>
        <Typography component="p" variant="caption" color="textSecondary" style={{ marginBottom: 12 }}>
          {emptyResourcesMessage}
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.resourceList}>
      {resources.map((resource) => {
        return (
          <Resource
            key={resource.id}
            isReadOnly={!canRemoveResource}
            resource={resource}
            onRemoveResource={() => onRemoveResource(resource.id)}
          />
        )
      })}
    </div>
  )
}

const Resource = ({ resource, isReadOnly, onRemoveResource }: ResourceProps) => {
  const classes = useStyles()
  return (
    <div className={classes.resource}>
      <div className={classes.resourceInfo} data-test={`resource-${resource.name}`}>
        <Tooltip title={resource.name.length > 24 ? resource.name : ""} PopperProps={{ placement: "top" }}>
          <Typography variant="body1">{truncate(resource.name, { length: 24 })}</Typography>
        </Tooltip>
      </div>
      {!isReadOnly && (
        <IconButton onClick={onRemoveResource} data-test={`remove-resource-${resource.name}`}>
          <X size={18} color={COLOR_BLACK} />
        </IconButton>
      )}
    </div>
  )
}

const ResourceMenu = ({
  anchorEl,
  onCloseMenu,
  menuItems,
  emptyOptionsMessage,
  isMultipleSelection,
}: ResourceMenuProps) => {
  const [checked, setChecked] = React.useState<string[]>([])

  const makeOnChangeCheckbox = (id: string) => () => {
    const index = checked.indexOf(id)
    if (index >= 0) {
      return setChecked(filter(checked, (checkedId) => id !== checkedId))
    } else {
      if (isMultipleSelection) return setChecked([...checked, id])
      else return setChecked([id])
    }
  }

  const onClose = () => {
    if (isMultipleSelection) onCloseMenu(checked as any)
    else onCloseMenu(checked[0] as any)
    setChecked([])
  }

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      PaperProps={{ style: { minWidth: 280 } }}
      disablePortal
    >
      {!menuItems.length && (
        <MenuItem>
          <ListItemText primary={emptyOptionsMessage} />
        </MenuItem>
      )}
      {menuItems.map((resource) => (
        <MenuItem key={resource.id} value={resource.id} onClick={makeOnChangeCheckbox(resource.id)} button>
          <Checkbox
            color="primary"
            size="small"
            checked={checked.includes(resource.id)}
            onChange={makeOnChangeCheckbox(resource.id)}
          />
          <ListItemText primary={resource.name} />
        </MenuItem>
      ))}
    </Menu>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  resourceHeader: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: theme.spacing(5),
    borderRadius: theme.spacing(),
    transition: theme.transitions.create("background"),
    "&.editable:hover": {
      cursor: "pointer",
      marginLeft: theme.spacing(-1),
      paddingLeft: theme.spacing(),
      background: COLOR_LIGHT,
    },
    "& h4": {
      marginRight: theme.spacing(4),
    },
  },
  resourceList: {
    padding: theme.spacing(1, 0),
  },
  resource: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: 4,
    padding: theme.spacing(0, 0, 0, 1.5),
    marginBottom: theme.spacing(0.75),
    height: 42,
  },
  resourceInfo: {
    display: "flex",
    alignItems: "center",
    "& .MuiAvatar-root": {
      background: theme.palette.info.main,
      marginRight: theme.spacing(1.5),
      height: 28,
      width: 28,
      fontSize: theme.typography.body1.fontSize,
    },
  },
  emptyMessageDisplay: {
    margin: theme.spacing(1, 0),
    padding: theme.spacing(0.5),
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: theme.spacing(0.125),
    textAlign: "center",
    cursor: "pointer",
  },
}))

type ResourceManagerPropsMultipleSelection = {
  title: string
  resources: ResourceType[]
  resourceOptions: ResourceType[]
  onAddResources: (resourceIds: string[]) => any
  onRemoveResource: (resourceId: string) => any
  emptyResourcesMessage: string
  emptyOptionsMessage: string
  canAddResource: boolean
  canRemoveResource: boolean
  isMultipleSelection: true
}
type ResourceManagerPropsSingleSelection = {
  title: string
  resources: ResourceType[]
  resourceOptions: ResourceType[]
  onAddResource: (resourceId: string) => any
  onRemoveResource: (resourceId: string) => any
  emptyResourcesMessage: string
  emptyOptionsMessage: string
  canAddResource: boolean
  canRemoveResource: boolean
  isMultipleSelection?: false
}
type ResourceManagerProps = XOR<ResourceManagerPropsMultipleSelection, ResourceManagerPropsSingleSelection>

type ResourceListProps = {
  resources: ResourceType[]
  onRemoveResource: (resourceId: string) => any
  canRemoveResource: boolean
  emptyResourcesMessage: string
  openResourceMenu?: (e: React.MouseEvent<HTMLElement>) => void
}

type ResourceProps = {
  resource: ResourceType
  onRemoveResource: () => any
  isReadOnly: boolean
}

type ResourceMenuPropsMultipleSelection = {
  anchorEl: HTMLElement | null
  onCloseMenu: (checked: string[]) => void
  menuItems: ResourceType[]
  emptyOptionsMessage: string
  isMultipleSelection: true
}
type ResourceMenuPropsSingleSelection = {
  anchorEl: HTMLElement | null
  onCloseMenu: (checked: string) => void
  menuItems: ResourceType[]
  emptyOptionsMessage: string
  isMultipleSelection?: false
}
type ResourceMenuProps = XOR<ResourceMenuPropsMultipleSelection, ResourceMenuPropsSingleSelection>

export type ResourceType = {
  id: string
  name: string
}

export default ResourceManager
