import Typography from "@material-ui/core/Typography"
import { makeStyles, Theme } from "@material-ui/core"
import { useI18n } from "../hooks"

interface InputLabelProps {
  label: string
  isRequired?: boolean
}

const InputLabel = ({ label, isRequired = false }: InputLabelProps) => {
  const { t } = useI18n("translation")
  const required = t("required") || "Required"
  const classes = useStyles()

  return (
    <Typography component="label" variant="subtitle2" className={classes.label}>
      {`${label}:`}
      {isRequired && (
        <Typography className={classes.required} component="span" color="textSecondary" variant="caption">
          {`(${required})`}
        </Typography>
      )}
    </Typography>
  )
}

export default InputLabel

const useStyles = makeStyles((theme: Theme) => ({
  label: {
    display: "block",
    fontWeight: 600,
    letterSpacing: theme.spacing(0.1),
    marginBottom: theme.spacing(0.5),
  },
  required: {
    marginLeft: theme.spacing(0.5),
  },
}))
