import { useTheme } from "@material-ui/core"
import Stack from "../../components/Stack"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { ProjectViewModel } from "../api/project"
import { sortProjectsByPlan } from "../utils/sort"
import ProjectCard from "./ProjectCard"
import { useI18n, useRouter } from "../../hooks"
import { ProjectsViewOptions } from "./ProjectsViewSelect"
import { paths } from "../../paths"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { Link } from "react-router-dom"
import { StringMap } from "../../types/common"

const ProjectCards = ({ projects }: { projects: ProjectViewModel[] }) => {
  if (projects.length) {
    const sortedProjects = sortProjectsByPlan(projects)
    return (
      <Stack spacing={1}>
        {sortedProjects.map((project) => (
          <ProjectCard key={project.id} project={project} />
        ))}
      </Stack>
    )
  }

  return <ProjectsEmptyMessage />
}

const ProjectsEmptyMessage = () => {
  const { createPathWithContext } = useUrlWithContext()
  const theme = useTheme()
  const translations = useTranslations()
  const router = useRouter()
  const { view } = router.query as { view?: ProjectsViewOptions }
  const isCurrentAndFutureProjectsView = view === "currentAndFuture"
  const newProjectPath = createPathWithContext(paths.newProject())

  return (
    <div
      style={{
        marginTop: theme.spacing(3),
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        overflow: "hidden",
      }}
    >
      <Stack alignItems="center">
        <Typography variant="subtitle1">{translations.projectsEmpty}</Typography>
        {isCurrentAndFutureProjectsView ? (
          <Button variant="contained" color="primary" component={Link} to={newProjectPath}>
            {translations.addProject}
          </Button>
        ) : null}
      </Stack>
    </div>
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const translations = t || ({} as StringMap)

  return {
    projectsEmpty: translations.projectsEmpty || defaults.projectsEmpty,
    addProject: translations.projectsViewHeader?.addProject || defaults.addProject,
  }
}

const defaultTranslations = {
  projectsEmpty: "You don't have any projects here yet",
  addProject: "Add project",
}
type Translations = typeof defaultTranslations

export default ProjectCards
