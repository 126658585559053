import Box from "@material-ui/core/Box"
import Paper from "@material-ui/core/Paper"
import Slide from "@material-ui/core/Slide"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import ContactDetails from "../../components/ContactDetails"
import ContactForm from "../../components/ContactForm"
import ContactNotFound from "../../components/ContactNotFound"
import BasicInfoForm from "./BasicInfoForm"
import ContactsTable from "../../components/ContactsTable"

import { makeStyles, Theme } from "@material-ui/core"
import { PropsWithChildren, useEffect, useState } from "react"
import { useI18n, useRouter } from "../../hooks"
import { values } from "lodash"
import { paths } from "../../paths"
import type { ISupplierNormalized } from "../interfaces/supplier-normalized"
import { useAppDispatch } from "../../store"
import { unwrapResult } from "@reduxjs/toolkit"
import { IContact } from "../../types/contact"
import { ChangeSupplierDefaultContact, DeleteSupplierContact, UpdateSupplierContact } from "../store/actions"
import { useUrlWithContext } from "../../hooks/use-url-with-context"

const SupplierDetails = ({ supplier, onAddContact }: SupplierDetailsProps) => {
  const translations = useTranslations(defaultTranslations)
  const classes = useStyles()
  const dispatch = useAppDispatch()
  const router = useRouter()
  const { createPathWithContext } = useUrlWithContext()
  const { tab, contact = "" } = router.query as {
    tab: string
    contact?: string
  }

  const [value, setValue] = useState(() => {
    if (tab === "contacts") return 1
    if (tab === "add-contact") return 2
    if (tab === "contact") return 3
    return 0
  })

  const selectedContact = supplier.contacts[contact] || null
  const basicInfoTabLabel = translations.basicInfoTabLabel
  const allContactsTabLabel = translations.allContactsTabLabel
  const addContactTabLabel = translations.addContactTabLabel

  const changeTab = (index: 0 | 1 | 2) => () => {
    setValue(index)
    const pathWithoutContext = paths.supplierSettings()

    if (index === 1) {
      const path = createPathWithContext(pathWithoutContext + "?tab=contacts")
      router.push(path)
    } else if (index === 2) {
      const path = createPathWithContext(pathWithoutContext + "?tab=add-contact")
      router.push(path)
    } else {
      const path = createPathWithContext(pathWithoutContext)
      router.push(path)
    }
  }

  /** Effect to sync the query with the tab */
  useEffect(() => {
    if (!tab && value !== 0) {
      setValue(0)
    }
    if (value !== 1 && tab === "contacts") {
      setValue(1)
    }
    if (value !== 2 && tab === "add-contact") {
      setValue(2)
    }
    if (value !== 3 && tab === "contact") {
      setValue(3)
    }
  }, [value, tab, router])

  async function addContactAndRedirect(contact: any) {
    try {
      await onAddContact(contact)
      const route = createPathWithContext(`${paths.supplierSettings()}?tab=contacts`)
      router.push(route)
    } catch (error) {}
  }

  async function deleteContactAndRedirect() {
    try {
      await deleteContact()
      const route = createPathWithContext(`${paths.supplierSettings()}?tab=contacts`)
      router.push(route)
    } catch (error) {}
  }

  async function makeDefaultContact() {
    await dispatch(
      ChangeSupplierDefaultContact({
        supplierId: selectedContact.supplierId as string,
        contactId: selectedContact.id,
      })
    )
  }

  async function deleteContact() {
    await dispatch(
      DeleteSupplierContact({
        supplierId: selectedContact.supplierId as string,
        contactId: selectedContact.id,
      })
    )
  }

  async function onEditContact(props: { [k in keyof IContact]?: string }) {
    unwrapResult(
      await dispatch(
        UpdateSupplierContact({
          supplierId: selectedContact.supplierId as string,
          contactId: selectedContact.id,
          ...props,
        })
      )
    )
  }

  return (
    <Paper elevation={0} variant="outlined" className={classes.paper}>
      <div className={classes.tabsContainer}>
        <Tabs value={value} variant="scrollable" indicatorColor="primary" textColor="primary">
          <Tab label={basicInfoTabLabel} onClick={changeTab(0)} data-test="basic-info-tab" />
          <Tab label={allContactsTabLabel} onClick={changeTab(1)} data-test="all-contacts-tab" />
          <Tab
            label={addContactTabLabel}
            style={{
              display: supplier.isActive && supplier.canUpdate ? "block" : "none",
            }}
            onClick={changeTab(2)}
            data-test="add-contact-tab"
          />
          <Tab label="contact" style={{ display: "none" }} />
        </Tabs>
      </div>
      <TabPanel index={0} value={value}>
        <BasicInfoForm canUpdateBasicInfo={supplier.canUpdate} supplier={supplier} />
      </TabPanel>
      <TabPanel index={1} value={value}>
        <ContactsTable contacts={values(supplier.contacts)} canAddContact={supplier.canUpdate} />
      </TabPanel>
      <TabPanel index={2} value={value}>
        <ContactForm onAddContact={addContactAndRedirect} />
      </TabPanel>
      <TabPanel index={3} value={value}>
        {selectedContact && (
          <ContactDetails
            contact={selectedContact}
            editable={supplier.isActive && supplier.canUpdate}
            onEditContact={onEditContact}
            onMakeDefaultContact={makeDefaultContact}
            onDeleteContact={deleteContactAndRedirect}
          />
        )}
        {!selectedContact && <ContactNotFound />}
      </TabPanel>
    </Paper>
  )
}

const TabPanel = ({ value, index, children }: PropsWithChildren<TabPanelProps>) => {
  return (
    <div role="tabpanel" hidden={value !== index} id={`tab-panel-${index}`} aria-labelledby={`full-width-tab-${index}`}>
      {value === index && (
        <Slide direction="left" in={value === index} mountOnEnter unmountOnExit>
          <Box pt={2} pb={2}>
            {children}
          </Box>
        </Slide>
      )}
    </div>
  )
}

const useTranslations = (defaults: SupplierDetailsTranslations = defaultTranslations): SupplierDetailsTranslations => {
  const { translations: t } = useI18n("supplier")
  const translations = (t.supplierHomePage || {}) as { [k: string]: string }

  const {
    basicInfoTabLabel = defaults.basicInfoTabLabel,
    allContactsTabLabel = defaults.allContactsTabLabel,
    addContactTabLabel = defaults.addContactTabLabel,
  } = translations

  return {
    basicInfoTabLabel,
    allContactsTabLabel,
    addContactTabLabel,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    minWidth: "100%",
    maxWidth: "100%",
  },
  tabsContainer: {
    maxWidth: "100%",
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}))

const defaultTranslations: SupplierDetailsTranslations = {
  basicInfoTabLabel: "Basic info",
  allContactsTabLabel: "All contacts",
  addContactTabLabel: "Add contact",
}

interface SupplierDetailsProps {
  onAddContact: (contact: any) => Promise<void>
  supplier: ISupplierNormalized
}

interface TabPanelProps {
  value: number
  index: number
}

interface SupplierDetailsTranslations {
  basicInfoTabLabel: string
  allContactsTabLabel: string
  addContactTabLabel: string
}

export default SupplierDetails
