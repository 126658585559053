import AutoSaveInput from "../AutoSaveInput"
import { Button, Grid, Paper, Theme, makeStyles } from "@material-ui/core"
import { useI18n } from "../../hooks"
import { COLOR_DARK_100, COLOR_WHITE } from "../../constants"

const InvoicingAddress = ({ invoicingAddress, onChange }: InvoicingAddressProps) => {
  const translations = useTranslations()
  const classes = useStyles()
  const isEmailInvoice = invoicingAddress.invoicingMethod === "email"

  const handleClickMethod = (method: "email" | "eInvoice") => () => {
    onChange({ invoicingMethod: method })
  }

  return (
    <Paper elevation={0} className={classes.paper}>
      <Grid container direction="row" className={classes.buttonContainer}>
        <Grid item className={classes.item}>
          <Button
            fullWidth
            onClick={handleClickMethod("email")}
            variant={isEmailInvoice ? "contained" : "text"}
            className={classes.button}
            data-test="email-invoice-button"
          >
            {translations.emailInvoiceMethodLabel}
          </Button>
        </Grid>
        <Grid item className={classes.item}>
          <Button
            fullWidth
            onClick={handleClickMethod("eInvoice")}
            variant={isEmailInvoice ? "text" : "contained"}
            className={classes.button}
            data-test="e-invoice-button"
          >
            {translations.eInvoiceMethodLabel}
          </Button>
        </Grid>
      </Grid>

      {isEmailInvoice ? (
        <AutoSaveInput
          name="invoicingEmail"
          label={translations.invoicingEmailLabel}
          placeholder={translations.invoicingEmailPlaceholder}
          onSave={onChange}
          initialValue={invoicingAddress.invoicingEmail}
          disabled={false}
          inputProps={{ "data-test": "invoicing-email-input" }}
        />
      ) : (
        <>
          <AutoSaveInput
            name="electronicInvoicingAddress"
            label={translations.electronicInvoicingAddressLabel}
            placeholder={translations.electronicInvoicingAddressPlaceholder}
            onSave={onChange}
            initialValue={invoicingAddress.electronicInvoicingAddress}
            disabled={false}
            inputProps={{ "data-test": "electronic-invoicing-address-input" }}
          />
          <AutoSaveInput
            name="invoicingOperatorId"
            label={translations.invoicingOperatorIdLabel}
            placeholder={translations.invoicingOperatorIdPlaceholder}
            onSave={onChange}
            initialValue={invoicingAddress.invoicingOperatorId}
            disabled={false}
            inputProps={{ "data-test": "invoicing-operator-id-input" }}
          />
        </>
      )}
      <AutoSaveInput
        name="vatNumber"
        label={translations.vatNumberLabel}
        placeholder={translations.vatNumberPlaceholder}
        onSave={onChange}
        initialValue={invoicingAddress.vatNumber}
        disabled={false}
        inputProps={{ "data-test": "vat-number-input" }}
      />
      <AutoSaveInput
        name="invoicingReferenceNumber"
        label={translations.invoicingReferenceNumberLabel}
        placeholder={translations.invoicingReferenceNumberPlaceholder}
        onSave={onChange}
        initialValue={invoicingAddress.invoicingReferenceNumber}
        disabled={false}
        inputProps={{ "data-test": "invoicing-reference-number-input" }}
      />
    </Paper>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("org")
  const translations = (t.orgBillingPage || {}) as { [k: string]: string }

  const {
    emailInvoiceMethodLabel = defaults.emailInvoiceMethodLabel,
    eInvoiceMethodLabel = defaults.eInvoiceMethodLabel,
    invoicingEmailLabel = defaults.invoicingEmailLabel,
    invoicingEmailPlaceholder = defaults.invoicingEmailPlaceholder,
    vatNumberLabel = defaults.vatNumberLabel,
    vatNumberPlaceholder = defaults.vatNumberPlaceholder,
    invoicingReferenceNumberLabel = defaults.invoicingReferenceNumberLabel,
    invoicingReferenceNumberPlaceholder = defaults.invoicingReferenceNumberPlaceholder,
    electronicInvoicingAddressLabel = defaults.electronicInvoicingAddressLabel,
    electronicInvoicingAddressPlaceholder = defaults.electronicInvoicingAddressPlaceholder,
    invoicingOperatorIdLabel = defaults.invoicingOperatorIdLabel,
    invoicingOperatorIdPlaceholder = defaults.invoicingOperatorIdPlaceholder,
  } = translations

  return {
    emailInvoiceMethodLabel,
    eInvoiceMethodLabel,
    invoicingEmailLabel,
    invoicingEmailPlaceholder,
    vatNumberLabel,
    vatNumberPlaceholder,
    invoicingReferenceNumberLabel,
    invoicingReferenceNumberPlaceholder,
    electronicInvoicingAddressLabel,
    electronicInvoicingAddressPlaceholder,
    invoicingOperatorIdLabel,
    invoicingOperatorIdPlaceholder,
  }
}

const defaultTranslations = {
  emailInvoiceMethodLabel: "Invoice By Email",
  eInvoiceMethodLabel: "E-invoice",
  invoicingEmailLabel: "Invoicing email address",
  invoicingEmailPlaceholder: "Enter invoicing email address",
  vatNumberLabel: "Vat number",
  vatNumberPlaceholder: "Enter vat number",
  invoicingReferenceNumberLabel: "Your reference",
  invoicingReferenceNumberPlaceholder: "Enter your reference",
  electronicInvoicingAddressLabel: "Electronic invoicing address",
  electronicInvoicingAddressPlaceholder: "Enter electronic invoicing address",
  invoicingOperatorIdLabel: "Invoicing operator id",
  invoicingOperatorIdPlaceholder: "Enter invoicing operator id",
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    "& > form, & > div": {
      padding: theme.spacing(1, 2),
    },
  },
  buttonContainer: {
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
  },
  item: {
    flexGrow: 1,
  },
  button: {
    textTransform: "capitalize",

    "&.MuiButton-text": {
      color: COLOR_DARK_100,
    },
    "&.MuiButton-contained": {
      backgroundColor: COLOR_DARK_100,
      color: COLOR_WHITE,
    },
  },
}))

type InvoicingAddressProps = {
  invoicingAddress: {
    invoicingMethod: "email" | "eInvoice"
    invoicingEmail: string
    vatNumber: string
    invoicingReferenceNumber: string
    electronicInvoicingAddress: string
    invoicingOperatorId: string
  }
  onChange: (value: { [key: string]: string }) => Promise<void>
}

type Translations = typeof defaultTranslations

export default InvoicingAddress
