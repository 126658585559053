import React from "react"
import Menu from "@material-ui/core/Menu"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import Tooltip from "@material-ui/core/Tooltip"
import IconButton from "@material-ui/core/IconButton"
import { Bell, MoreVertical, Settings } from "react-feather"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { useTranslations } from "./use-translations"
import { useProjectMutations } from "../../hooks/use-project-mutations"
import { paths } from "../../../paths"
import { useUrlWithContext } from "../../../hooks/use-url-with-context"
import { Link, useHistory, useLocation } from "react-router-dom"
import { useConfirmDialog } from "../../../components/ConfirmDialog"
import { ProjectViewModel } from "../../api/project"
import { Save, Archive, Trash } from "react-feather"
import { CheckIcon, EyeIcon, isGanttRoute } from "./utils"
import { DateTimeService } from "../../../services/date-time-service"
import { useAuthUserLocale } from "../../../users/hooks/use-auth-user-locale"
import { ProjectRouteContext } from "../../../routes/projects/$projectId"

const useProjectMenuActions = ({ project }: { project: ProjectViewModel }) => {
  const actions = useProjectMutations()
  const translations = useTranslations()
  const { createPathWithContext } = useUrlWithContext()
  const projectsPath = createPathWithContext(paths.projects())
  const history = useHistory()

  const deleteProject = async () => {
    actions.deleteProject(project.id).then(() => history.push(projectsPath))
  }

  const { confirm: confirmSaveBasePlan } = useConfirmDialog({
    onConfirm: () => actions.saveBasePlan(project.id),
    text: translations.saveBasePlanConfirmText,
    title: translations.saveBasePlanConfirmTitle,
    primaryActionButtonLabel: translations.saveBasePlanConfirmBtnLabel,
  })

  const { confirm: confirmDeleteProject } = useConfirmDialog({
    onConfirm: deleteProject,
    text: translations.deleteProjectConfirmText,
    title: translations.deleteProjectConfirmTitle,
    primaryActionButtonLabel: translations.deleteProjectBtnLabel,
  })

  const { confirm: confirmArchiveProject } = useConfirmDialog({
    onConfirm: () => actions.archiveProject(project.id),
    text: translations.archiveProjectConfirmText,
    title: translations.archiveProjectConfirmTitle,
    primaryActionButtonLabel: translations.archiveProjectBtnLabel,
  })

  const toggleTimeComponent = () => {
    const enableTimeComponent = !project.enableTimeComponent
    actions.updateTimeComponent(project.id, { enableTimeComponent })
  }

  return {
    confirmSaveBasePlan,
    confirmDeleteProject,
    confirmArchiveProject,
    toggleTimeComponent,
  }
}

const useMenuState = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const toggleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const closeMenu = () => setAnchorEl(null)

  return { anchorEl, isOpen: Boolean(anchorEl), toggleMenu, closeMenu }
}

const useProjectMenuItems = ({ project }: { project: ProjectViewModel }) => {
  const menuActions = useProjectMenuActions({ project })
  const translations = useTranslations()
  const { dateFormat, timeZone } = useAuthUserLocale()
  const projectRouteContext = React.useContext(ProjectRouteContext)
  const { createPathWithContext } = useUrlWithContext()
  const location = useLocation()

  const dateTimeService = new DateTimeService({
    enableTimeComponent: project.enableTimeComponent,
    dateFormat,
    timeZone,
  })
  const projectSettingsPath = createPathWithContext(paths.projectSettings(project.id))
  const projectNotificationsPath = createPathWithContext(paths.projectReminders(project.id))
  const isGantt = isGanttRoute(location.pathname)
  const isEnableTimeComponentCheckboxVisible = project.canUpdatePlan
  const isSaveBasePlanButtonVisible = !project.basePlanSavedDate && project.canUpdatePlan && isGantt
  const isToggleBaselineBarButtonVisible = Boolean(project.basePlanSavedDate) && isGantt
  const isToggleActualBarButtonVisible = isGantt
  const isArchiveProjectButtonVisible = project.canDelete && !project.archived
  const isDeleteProjectButtonVisible = project.canDelete
  const isBaselineBarShown = Boolean(projectRouteContext?.isBaselineBarShown)
  const isActualBarShown = Boolean(projectRouteContext?.isActualBarShown)

  const getBaselineSavedInfo = (): null | string => {
    if (!project.basePlanSavedDate) return null
    const formattedDate = dateTimeService.format(new Date(project.basePlanSavedDate))
    const savedBy = project.basePlanSavedBy?.split(":")[1] || translations.savedByYou
    return translations.baselineSavedText.replace("{{date}}", formattedDate).replace("{{saved_by}}", savedBy)
  }

  return [
    {
      id: "enableTimeComponentCheckbox",
      isVisible: isEnableTimeComponentCheckboxVisible,
      primaryText: translations.enableTimeComponentLabel,
      onClick: menuActions.toggleTimeComponent,
      icon: <CheckIcon isChecked={project.enableTimeComponent} />,
    },
    {
      id: "saveBasePlanButton",
      isVisible: isSaveBasePlanButtonVisible,
      primaryText: translations.saveBasePlanBtnLabel,
      onClick: menuActions.confirmSaveBasePlan,
      icon: <Save size={18} />,
    },
    {
      id: "toggleBaselineBarButton",
      isVisible: isToggleBaselineBarButtonVisible,
      primaryText: isBaselineBarShown ? translations.hideBaselineBarText : translations.showBaselineBarText,
      secondaryText: getBaselineSavedInfo(),
      onClick: projectRouteContext?.toggleBaselineBar,
      icon: <EyeIcon isShown={isBaselineBarShown} size={18} />,
    },
    {
      id: "toggleActualBarButton",
      isVisible: isToggleActualBarButtonVisible,
      primaryText: isActualBarShown ? translations.hideActualBarText : translations.showActualBarText,
      onClick: projectRouteContext?.toggleActualBar,
      icon: <EyeIcon isShown={!!isActualBarShown} size={18} />,
    },
    {
      id: "settingsPageLink",
      primaryText: translations.projectSettingsButtonLabel,
      icon: <Settings size={18} />,
      to: projectSettingsPath,
    },
    {
      id: "notificationsPageLink",
      primaryText: translations.notificationsLabel,
      icon: <Bell size={18} />,
      to: projectNotificationsPath,
    },
    {
      id: "archiveProjectButton",
      isVisible: isArchiveProjectButtonVisible,
      primaryText: translations.archiveProjectBtnLabel,
      onClick: menuActions.confirmArchiveProject,
      icon: <Archive size={18} data-test="archive-project-button" />,
    },
    {
      id: "deleteProjectButton",
      isVisible: isDeleteProjectButtonVisible,
      primaryText: translations.deleteProjectBtnLabel,
      secondaryText: translations.deleteProjectSecondaryText,
      onClick: menuActions.confirmDeleteProject,
      icon: <Trash size={18} data-test="delete-project-button" />,
    },
  ]
}

const ProjectMenu = ({ project }: { project: ProjectViewModel }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { anchorEl, isOpen, toggleMenu, closeMenu } = useMenuState()
  const menuItems = useProjectMenuItems({ project })
  const projectRouteContext = React.useContext(ProjectRouteContext)

  if (projectRouteContext?.isFullScreen) return null
  const visibleMenuItems = menuItems.filter((item) => item.isVisible !== false)

  return (
    <>
      <Tooltip title={translations.menuAriaLabel}>
        <IconButton
          aria-label={translations.menuAriaLabel}
          size="small"
          onClick={toggleMenu}
          data-test="project-three-dotted-menu"
        >
          <MoreVertical size={16} className={classes.menuIcon} />
        </IconButton>
      </Tooltip>
      <Menu
        id="project-three-dotted-menu"
        open={isOpen}
        anchorEl={anchorEl}
        onClose={closeMenu}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        variant="menu"
        PaperProps={{ className: classes.paper }}
        disablePortal
        keepMounted
      >
        {visibleMenuItems.map((menuItem) => {
          const { icon, primaryText, secondaryText = null, isVisible, ...listItemProps } = menuItem
          return (
            <ListItem
              {...listItemProps}
              key={menuItem.id}
              className={classes.listItem}
              button
              component={listItemProps.to ? Link : "li"}
            >
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={primaryText} secondary={secondaryText} />
            </ListItem>
          )
        })}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  menuIcon: { color: theme.palette.text.primary },
  paper: { maxWidth: 320 },
  listItem: { "& .MuiListItemIcon-root": { color: theme.palette.text.primary } },
}))

export default ProjectMenu
