import { createSelector } from "@reduxjs/toolkit"
import { filter, values } from "lodash"
import { RootState } from "../../store"
import { IOrgNormalized } from "../interfaces/org-normalized"

export const getOrganisationById = (state: RootState, orgId: string) => {
  return state.orgs.byIds[orgId]
}

export const getOrganisations: (state: RootState, predicate?: any) => IOrgNormalized[] = createSelector(
  (state: RootState) => state.orgs.byIds,
  (_state: RootState, predicate: any) => predicate,
  (organisations, predicate) => {
    return filter(values(organisations), predicate)
  }
)

export const getAllOrganisations = createSelector(
  (state: RootState) => state.orgs.byIds,
  (orgs) => values(orgs)
)
