import { sortBy } from "lodash"
import { TreeGridTask, TreeGridTaskCellPermissions } from "../types"
import {
  getColorHtmlForColor,
  getStateIconHtml,
  makeEnumKeysFromStatusOptions,
  makeStatusEnumFromStatusOptions,
  mapColorToTranslations,
  mapStatusToTranslations,
  serializeDepedency,
  treegridDateFormat,
  treegridDateTimeFormat,
} from "../utils"
import { GanttDependency } from "../../tasks/types"
import { DateTimeService } from "../../services/date-time-service"
import { TimeZoneType } from "../../constants/timezones"
import { isTaskPlannedEndDatePastDue, isTaskPlannedStartDatePastDue } from "../../tasks/utils/date"
import { TaskViewModel } from "../../tasks/api/task"
import { TreeGridTranslations } from "../hooks/use-translations"
import { getGanttGanttHtmlRight } from "../utils/tree-grid"
import { Status } from "../../types/common"

export const makeTreeGridTask = ({
  task,
  dateFormat,
  timeZone,
  translations,
}: {
  task: TaskViewModel
  dateFormat: string
  timeZone: TimeZoneType
  translations: TreeGridTranslations
}): TreeGridTask => {
  const { id, title, description, statusDescription, projectId, enableTimeComponent } = task
  const treegridFormat = enableTimeComponent ? treegridDateTimeFormat : treegridDateFormat
  const dateTimeService = new DateTimeService({ dateFormat, timeZone, enableTimeComponent })
  const taskNumber = task.customTaskNumber || task.taskNumber
  const participants = task.participants.map((p) => p.id).join(";")
  const managers = task.managers.map((m) => m.id).join(";")
  const suppliers = task.suppliers.map((s) => s.id).join(";")
  const workspaces = task.workspaces.map((w) => w.id).join(";")
  const plannedStartDate = getPlannedStartDate() // number of milliseconds since epoch, or null
  const plannedEndDate = getPlannedEndDate() // number of milliseconds since epoch, or null
  const basePlanStartDate = getBasePlanStartDate() // number of milliseconds since epoch, or null
  const basePlanEndDate = getBasePlanEndDate() // number of milliseconds since epoch, or null
  const actualStartDate = getActualStartDate() // number of milliseconds since epoch, or null
  const actualEndDate = getActualEndDate() // number of milliseconds since epoch, or null
  const actualBarStartDate = getActualBarStartDate() // number of milliseconds since epoch, or null
  const actualBarEndDate = getActualBarEndDate() // number of milliseconds since epoch, or null
  const ganttGanttHtmlRight = getGanttGanttHtmlRight({
    title: task.title,
    startDate: task.plannedStartDate,
    endDate: task.plannedEndDate,
    dateTimeService,
    showTime: enableTimeComponent,
  })
  const GanttGanttHtmlRight = "*ganttGanttHtmlRight*"

  const cellPermissions = getTreeGridTaskCellPermissions()
  const ganttDescendants = getGanttDescendants() // string representation of gantt dependencies
  const ganttAncestors = getGanttAncestors() // string representation of gantt dependencies
  const state = getStateIconHtml(task.state) // html for the state icon
  const plannedStartDateClass = getPlannedStartDateClass() // css class for rendering red text if task is past due
  const plannedEndDateClass = getPlannedEndDateClass() // css class for rendering red text if task is past due
  const statusDescriptionTip = getStatusDescriptionTip() // Text showing the user who made the last update and when it was made
  const dependencies = JSON.stringify(task.ganttAncestors.length ? task.ganttAncestors : [])
  const statusTranslations = mapStatusToTranslations(translations)
  const status = statusTranslations[task.customStatus as Status] || task.customStatus
  const statusEnum = makeStatusEnumFromStatusOptions(task.statusOptions, translations)
  const statusEnumKeys = makeEnumKeysFromStatusOptions(task.statusOptions)
  const translatedColor = mapColorToTranslations(translations)[task.ganttBarColor]
  const ganttBarColor = getColorHtmlForColor({ color: task.ganttBarColor, translatedColor })
  const GanttGanttClass = task.ganttBarColor
  const linksHtml = task.links.map((link) => link.anchorTag).join(", ")

  return {
    open: "/external-link-icon.svg",
    id,
    title,
    description,
    status,
    state,
    statusDescription,
    taskNumber,
    participants,
    managers,
    suppliers,
    workspaces,
    duration: task.duration,
    durationAlign: "center",
    daysLeft: task.daysLeft,
    daysLeftAlign: "center",
    plannedStartDate,
    plannedEndDate,
    actualStartDate,
    actualEndDate,
    actualBarStartDate,
    actualBarEndDate,
    ganttDescendants,
    ganttAncestors,
    GanttGanttHtmlRight,
    ganttGanttHtmlRight,
    ganttBarColor,
    ganttBarColorFilterValue: translatedColor,
    GanttGanttClass,
    plannedStartDateClass,
    plannedEndDateClass,
    statusDescriptionTip,
    parentTaskId: task.parentTaskId || null,
    projectId,
    order: task.order,
    canCreateTasks: task.canCreate,
    dependencies,
    basePlanEndDate,
    basePlanStartDate,
    links: linksHtml,
    statusEnum,
    statusEnumKeys,
    completionPercentage: task.completionPercentage,
    completionPercentageHtmlPostfix: "%",
    ...cellPermissions,
  }

  function getActualStartDate(): number | string {
    return task.actualStartDate ? dateTimeService.removeTimezoneOffset(task.actualStartDate, "UTC").getTime() : ""
  }

  function getActualEndDate(): number | string {
    return task.actualEndDate ? dateTimeService.removeTimezoneOffset(task.actualEndDate, "UTC").getTime() : ""
  }

  function getActualBarStartDate(): number | string {
    return getActualStartDate()
  }

  function getActualBarEndDate(): number | string {
    const now = new Date().toISOString()
    return task.isInProgress ? dateTimeService.removeTimezoneOffset(now, "UTC").getTime() : getActualEndDate()
  }

  function getPlannedStartDate(): string {
    if (!task.plannedStartDate) return ""
    const formattedDate = dateTimeService.convertToTimeZoneAndFormat(task.plannedStartDate, treegridFormat)
    return formattedDate
  }

  function getPlannedEndDate(): string {
    if (!task.plannedEndDate) return ""
    const formattedDate = dateTimeService.convertToTimeZoneAndFormat(task.plannedEndDate, treegridFormat)
    return formattedDate
  }

  function getBasePlanStartDate(): number | string {
    return task.basePlanStartDate ? dateTimeService.removeTimezoneOffset(task.basePlanStartDate, "UTC").getTime() : ""
  }

  function getBasePlanEndDate(): number | string {
    return task.basePlanEndDate ? dateTimeService.removeTimezoneOffset(task.basePlanEndDate, "UTC").getTime() : ""
  }

  function getGanttDescendants(): string {
    const inactive = task.inactiveGanttDescendants.map(serializeInactiveDependency)
    const active = task.ganttDescendants.map(serializeActiveDependency)
    return [...inactive, ...active].join(";")
  }

  function getGanttAncestors(): string {
    const inactive = task.inactiveGanttAncestors.map(serializeInactiveDependency)
    const active = task.ganttAncestors.map(serializeActiveDependency)
    return [...inactive, ...active].join(";")
  }

  function serializeInactiveDependency(dependency: GanttDependency): string {
    return serializeDepedency(dependency, { inactive: true })
  }

  function serializeActiveDependency(dependency: GanttDependency): string {
    return serializeDepedency(dependency)
  }

  function getPlannedStartDateClass(): string {
    const isPastDue = isTaskPlannedStartDatePastDue({ task, dateTimeService })
    return isPastDue ? "redText" : ""
  }

  function getPlannedEndDateClass(): string {
    const isPastDue = isTaskPlannedEndDatePastDue({ task, dateTimeService })
    return isPastDue ? "redText" : ""
  }

  function getStatusDescriptionTip() {
    // const format = dateTimeService.format.bind(dateTimeService)
    const sortedUpdates = sortBy(task.statusDescriptionUpdates, "updatedAt")
    const latestUpdate = sortedUpdates[sortedUpdates.length - 1]
    let tip = ""
    if (latestUpdate) {
      const formatStr = `${dateFormat} HH:mm`
      const { updatedBy, updatedAt } = latestUpdate
      const offsetDate = dateTimeService.removeTimezoneOffset(updatedAt)
      // if there is a latest update
      tip += updatedBy // include the user who made the update
      tip += "&nbsp;&nbsp;&bull;&nbsp;&nbsp;" // some spacing
      tip += dateTimeService.format(offsetDate, formatStr) // and formatted date of update
    }
    return tip
  }

  function getTreeGridTaskCellPermissions(): TreeGridTaskCellPermissions {
    return {
      CanDelete: task.canDelete ? 1 : 0,
      CanDrag: task.canUpdateDetails ? 1 : 0,
      titleCanEdit: task.canUpdateDetails ? 1 : 0,
      descriptionCanEdit: task.canUpdateDetails ? 1 : 0,
      ganttBarColorCanEdit: task.canUpdateDetails ? 1 : 0,
      taskNumberCanEdit: task.canUpdateDetails ? 1 : 0,
      plannedStartDateCanEdit: task.canUpdatePlan ? 1 : 0,
      plannedEndDateCanEdit: task.canUpdatePlan ? 1 : 0,
      durationCanEdit: task.canUpdatePlan ? 1 : 0,
      daysLeftCanEdit: task.canUpdatePlan && task.canUpdateStatus && !task.isCompleted ? 1 : 0,
      statusCanEdit: task.canUpdateStatus ? 1 : 0,
      stateCanEdit: task.canUpdateStatus ? 1 : 0,
      statusDescriptionCanEdit: task.canUpdateStatus ? 1 : 0,
      actualEndDateCanEdit: task.canUpdateStatus ? 1 : 0,
      actualStartDateCanEdit: task.canUpdateStatus ? 1 : 0,
      managersCanEdit: task.canUpdateManagers ? 1 : 0,
      suppliersCanEdit: task.canUpdateSuppliers ? 1 : 0,
      workspacesCanEdit: task.canUpdateWorkspaces ? 1 : 0,
      participantsCanEdit: task.canUpdateParticipants ? 1 : 0,
      // TODO: Need to find out why Gantt works this way
      GanttCanEdit: task.canUpdatePlan ? 0 : 1,
      ganttAncestorsCanEdit: task.canUpdatePlan ? 1 : 0,
      completionPercentageCanEdit: task.canUpdateStatus && !task.hasActiveChildren ? 1 : 0,
    }
  }
}
