import WorkspaceMemberCard from "../components/WorkspaceMemberCard"
import AddMembershipDialog from "../components/AddMembershipDialog"
import { makeStyles, Theme, Typography } from "@material-ui/core"
import { StringMap } from "../../types/common"
import { useI18n } from "../../hooks"
import { useWorkspace } from "../hooks/use-workspace"
import { useOrgMemberships } from "../../memberships/hooks/use-org-memberships"
import { keyBy } from "lodash"
import { useAppContext } from "../../hooks/use-app-context"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import { useConfirmDialog } from "../../components/ConfirmDialog"

const WorkspaceMembersView = ({ workspaceId }: { workspaceId: string }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { appContext } = useAppContext()
  const { mainContext, subContext } = appContext
  const { workspace, onRemoveManager, onRemoveMember, onAddMember, onAddManager } = useWorkspace(workspaceId)
  const { memberships } = useOrgMemberships(workspace.orgId)
  const { membershipOptions } = useOrgOptions(workspace.orgId)

  const { confirm: confirmRemoveManager } = useConfirmDialog({
    onConfirm: onRemoveManager,
    primaryActionButtonLabel: translations.removeMemberButtonLabel,
    title: translations.removeResponsibleDialogTitle,
    text: translations.removeResponsibleDialogText,
  })

  const { confirm: confirmRemoveMember } = useConfirmDialog({
    onConfirm: onRemoveMember,
    primaryActionButtonLabel: translations.removeMemberButtonLabel,
    title: translations.removeMemberDialogTitle,
    text: translations.removeMemberDialogText,
  })

  if (!mainContext || mainContext.type === "user") return null

  const membershipMap = keyBy(memberships, "id")
  const workspaceManagers = workspace.managers
    .map(({ id }) => id)
    .map((managerId: string) => membershipMap[managerId])
    .filter(Boolean)
  const workspaceMembers = workspace.members
    .map(({ id }) => id)
    .map((managerId: string) => membershipMap[managerId])
    .filter(Boolean)

  const managerOptions = membershipOptions.map(({ id, name }) => ({
    membershipId: id,
    fullname: name,
    isSelected: workspace.managers.map(({ id }) => id).includes(id),
  }))
  const memberOptions = membershipOptions.map(({ id, name }) => ({
    membershipId: id,
    fullname: name,
    isSelected: workspace.members.map(({ id }) => id).includes(id),
  }))

  const responsibleHeader = `${subContext?.name} - ${translations.managerHeaderText}`
  const membersHeader = `${subContext?.name} - ${translations.membersHeaderText}`

  return (
    <div className={classes.container}>
      <section>
        <div className={classes.header}>
          <Typography variant="subtitle1" component="header" data-test="page-header">
            {responsibleHeader}
          </Typography>
          <AddMembershipDialog workspace={workspace} memberships={managerOptions} handleAddMembership={onAddManager} />
        </div>
        <div className={classes.cards}>
          {workspaceManagers.map((manager) => (
            <WorkspaceMemberCard
              key={manager.id}
              member={manager}
              canRemove={workspace.canUpdate}
              onRemove={confirmRemoveManager}
              removeButtonLabel={translations.removeMemberButtonLabel}
            />
          ))}
        </div>
        {workspaceManagers.length === 0 ? (
          <div className={classes.emptyMessage}>
            <Typography variant="body2" color="textSecondary">
              {translations.noResponsibleText}
            </Typography>
          </div>
        ) : null}
      </section>

      <section>
        <div className={classes.header}>
          <Typography variant="subtitle1" component="header" data-test="page-header">
            {membersHeader}
          </Typography>
          <AddMembershipDialog workspace={workspace} memberships={memberOptions} handleAddMembership={onAddMember} />
        </div>
        <div className={classes.cards}>
          {workspaceMembers.map((manager) => (
            <WorkspaceMemberCard
              key={manager.id}
              member={manager}
              onRemove={confirmRemoveMember}
              removeButtonLabel={translations.removeMemberButtonLabel}
              canRemove={workspace.canUpdate}
            />
          ))}
        </div>
        {workspaceMembers.length === 0 ? (
          <div className={classes.emptyMessage}>
            <Typography variant="body2" color="textSecondary">
              {translations.noMemberText}
            </Typography>
          </div>
        ) : null}
      </section>
    </div>
  )
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n("workspace")
  const translations = (t?.membersPage || {}) as StringMap

  const {
    membersHeaderText = defaults.membersHeaderText,
    managerHeaderText = defaults.managerHeaderText,
    addManagerButtonLabel = defaults.addManagerButtonLabel,
    addMemberButtonLabel = defaults.addMemberButtonLabel,
    removeMemberButtonLabel = defaults.removeMemberButtonLabel,
    removeMemberDialogTitle = defaults.removeMemberDialogTitle,
    removeMemberDialogText = defaults.removeMemberDialogText,
    removeResponsibleDialogTitle = defaults.removeResponsibleDialogTitle,
    removeResponsibleDialogText = defaults.removeResponsibleDialogText,
    noResponsibleText = defaults.noResponsibleText,
    noMemberText = defaults.noMemberText,
  } = translations

  return {
    membersHeaderText,
    managerHeaderText,
    addManagerButtonLabel,
    addMemberButtonLabel,
    removeMemberButtonLabel,
    removeMemberDialogTitle,
    removeMemberDialogText,
    removeResponsibleDialogTitle,
    removeResponsibleDialogText,
    noResponsibleText,
    noMemberText,
  }
}

const defaultTranslations = {
  membersHeaderText: "Member",
  managerHeaderText: "Manager",
  addManagerButtonLabel: "Add",
  addMemberButtonLabel: "Add",
  removeMemberButtonLabel: "Remove",
  removeMemberDialogTitle: "Remove member",
  removeMemberDialogText: "Are you sure you want to remove the member from this workspace?",
  removeResponsibleDialogTitle: "Remove responsible",
  removeResponsibleDialogText:
    "Are you sure you want to remove this member from their responsible role in this workspace?",
  noResponsibleText: "This workspace does not have a responsible",
  noMemberText: "This workspace does not have any members",
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: theme.breakpoints.values.md,
    margin: "0 auto",
    "& section": {
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.paper,
      marginBottom: theme.spacing(4),
    },
  },
  header: {
    marginBottom: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontWeight: 500,
    },
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  emptyMessage: {
    padding: theme.spacing(2),
    textAlign: "center",
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
  },
}))

type Translations = typeof defaultTranslations
export default WorkspaceMembersView
