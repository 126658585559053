import * as supplierAPI from "../supplier-api"
import { useAuthUserMembership } from "../../memberships/hooks/use-auth-user-membership"
import { useAppDispatch, useAppSelector } from "../../store"
import { getOrgSuppliers } from "../store/selectors"
import { UpdateSupplierInfo, UpdateSupplierStatus } from "../store/actions"
import { unwrapResult } from "@reduxjs/toolkit"

export const useOrgSuppliers = (orgId: string) => {
  const dispatch = useAppDispatch()
  const suppliers = useAppSelector((s) => getOrgSuppliers(s, orgId))
  const { canUpdateAnySupplier } = useAuthUserMembership()
  const activeSuppliers = suppliers.filter((supplier) => supplier.isActive)
  const inactiveSuppliers = suppliers.filter((supplier) => !supplier.isActive)

  const updateSupplierStatus = async (supplierId: string, updateData: supplierAPI.SupplierStatusUpdateData) => {
    const thunkArg = { supplierId, ...updateData }
    const result = await dispatch(UpdateSupplierStatus(thunkArg))
    unwrapResult(result)
  }

  const updateSupplierInfo = async (supplierId: string, updateData: supplierAPI.SupplierInfoUpdateData) => {
    const thunkArg = { supplierId, ...updateData }
    const result = await dispatch(UpdateSupplierInfo(thunkArg))
    unwrapResult(result)
  }

  return {
    suppliers,
    activeSuppliers,
    inactiveSuppliers,
    canUpdateAnySupplier,
    updateSupplierStatus,
    updateSupplierInfo,
  }
}
