import { useSnackbar } from "notistack"
import * as boardApi from "../api"

export const useBoardMutations = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [addTaskCardMutation] = boardApi.useAddTaskCardMutation()
  const [addTodoCardMutation] = boardApi.useAddTodoCardMutation()
  const [moveTaskCardMutation] = boardApi.useMoveTaskCardMutation()
  const [moveTodoCardMutation] = boardApi.useMoveTodoCardMutation()
  const [updateColumnMutation] = boardApi.useUpdateColumnMutation()
  const [updateColumnNameMutation] = boardApi.useUpdateColumnNameMutation()

  const handleError = (error: any) => {
    const errorMessage = "error" in error ? error.error : "message" in error ? error.message : "Unknown error"
    enqueueSnackbar(errorMessage, { variant: "error" })
  }

  const addTaskCard = async (cardData: boardApi.NewCardData) => {
    return await addTaskCardMutation(cardData).unwrap().catch(handleError)
  }

  const addTodoCard = async (cardData: boardApi.NewCardData) => {
    return await addTodoCardMutation(cardData).unwrap().catch(handleError)
  }

  const moveTaskCard = async (cardData: boardApi.MoveCardData) => {
    return await moveTaskCardMutation(cardData).unwrap().catch(handleError)
  }

  const moveTodoCard = async (cardData: boardApi.MoveCardData) => {
    return await moveTodoCardMutation(cardData).unwrap().catch(handleError)
  }

  const updateColumn = async (columnData: boardApi.BoardColumnUpdateData) => {
    return await updateColumnMutation(columnData).unwrap().catch(handleError)
  }

  const updateColumnName = async (columnData: boardApi.BoardColumnNameUpdateData) => {
    return await updateColumnNameMutation(columnData).unwrap().catch(handleError)
  }

  return {
    addTaskCard,
    addTodoCard,
    moveTaskCard,
    moveTodoCard,
    updateColumn,
    updateColumnName,
  }
}
