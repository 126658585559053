import React from "react"
import Autocomplete from "@material-ui/lab/Autocomplete"
import InputLabel from "@material-ui/core/FormLabel"
import TextField from "@material-ui/core/TextField"
import Stack from "../../components/Stack"
import Typography from "@material-ui/core/Typography"
import { StringMap } from "../../types/common"
import { useI18n } from "../../hooks"

const AssociationsManager = (props: AssociationsManagerProps) => {
  const translations = useTranslations()
  const {
    associations,
    abilitiesInResources,
    setAbilitiesInResources,
    setAssociations,
    customerOptions,
    supplierOptions,
  } = props

  const lacksReadAnyCustomerAbility = props.abilities.indexOf("ReadAnyCustomer") === -1
  const lacksReadAnySupplierAbility = props.abilities.indexOf("ReadAnySupplier") === -1
  const visibleCustomers = getVisibleCustomers(abilitiesInResources, customerOptions)
  const visibleSuppliers = getVisibleSuppliers(abilitiesInResources, supplierOptions)
  const associatedCustomers = getAssociatedCustomers(associations, customerOptions)
  const associatedSuppliers = getAssociatedSuppliers(associations, supplierOptions)

  const onChangeCustomerAssociations = (value: Option[]) => {
    const nextCustomerAssociations = value.map((option) => option.id)
    const prevSupplierAssociations = associatedSuppliers.map((option) => option.id)
    const nextAssociations = [...nextCustomerAssociations, ...prevSupplierAssociations]
    setAssociations(nextAssociations)
  }

  const onChangeSupplierAssociations = (value: Option[]) => {
    const nextSupplierAssociations = value.map((option) => option.id)
    const prevCustomerAssociations = associatedCustomers.map((option) => option.id)
    const nextAssociations = [...prevCustomerAssociations, ...nextSupplierAssociations]
    setAssociations(nextAssociations)
  }

  const onChangeVisibleCustomers = (value: Option[]) => {
    const nextVisibleCustomers = value.map(createReadCustomerAbilityFromOption)
    const prevVisibleSuppliers = visibleSuppliers.map(createReadSupplierAbilityFromOption)
    const nextAbilitiesInResources = [...nextVisibleCustomers, ...prevVisibleSuppliers]
    setAbilitiesInResources(nextAbilitiesInResources)
  }

  const onChangeVisibleSuppliers = (value: Option[]) => {
    const nextVisibleSuppliers = value.map(createReadSupplierAbilityFromOption)
    const prevVisibleCustomers = visibleCustomers.map(createReadCustomerAbilityFromOption)
    const nextAbilitiesInResources = [...prevVisibleCustomers, ...nextVisibleSuppliers]
    setAbilitiesInResources(nextAbilitiesInResources)
  }

  return (
    <>
      <Stack>
        <Typography variant="h6" component="header">
          {translations.associationsHeader}
        </Typography>
        {lacksReadAnyCustomerAbility && (
          <div>
            <InputLabel htmlFor="visibleCustomers">{translations.customersOptionLabel}</InputLabel>
            <Autocomplete
              size="small"
              value={visibleCustomers}
              onChange={(e, value) => onChangeVisibleCustomers(value)}
              options={customerOptions}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField name="visibleCustomers" {...params} variant="outlined" />}
              filterSelectedOptions
              multiple
              disabled={!props.canUpdatePermissions}
            />
          </div>
        )}
        <div>
          <InputLabel htmlFor="associatedCustomers">{translations.navigationCustomersLabel}</InputLabel>
          <Autocomplete
            size="small"
            value={associatedCustomers}
            onChange={(e, value) => onChangeCustomerAssociations(value)}
            options={customerOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField name="associatedCustomers" {...params} variant="outlined" />}
            filterSelectedOptions
            multiple
            disabled={!props.canUpdatePermissions}
          />
        </div>
        {lacksReadAnySupplierAbility && (
          <div>
            <InputLabel htmlFor="visibleSuppliers">{translations.suppliersOptionLabel}</InputLabel>
            <Autocomplete
              size="small"
              value={visibleSuppliers}
              onChange={(e, value) => onChangeVisibleSuppliers(value)}
              options={supplierOptions}
              getOptionLabel={(option) => option.name}
              getOptionSelected={(option, value) => option.id === value.id}
              renderInput={(params) => <TextField name="visibleSuppliers" {...params} variant="outlined" />}
              filterSelectedOptions
              multiple
              disabled={!props.canUpdatePermissions}
            />
          </div>
        )}
        <div>
          <InputLabel htmlFor="associatedSuppliers">{translations.navigationSuppliersLabel}</InputLabel>
          <Autocomplete
            size="small"
            value={associatedSuppliers}
            onChange={(e, value) => onChangeSupplierAssociations(value)}
            options={supplierOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField name="associatedSuppliers" {...params} variant="outlined" />}
            filterSelectedOptions
            multiple
            disabled={!props.canUpdatePermissions}
          />
        </div>
      </Stack>
    </>
  )
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("membership")
  const translations = (t?.membershipSettingsView || {}) as StringMap

  const {
    associationsHeader = defaults.associationsHeader,
    customersOptionLabel = defaults.customersOptionLabel,
    suppliersOptionLabel = defaults.suppliersOptionLabel,
    navigationCustomersLabel = defaults.navigationCustomersLabel,
    navigationSuppliersLabel = defaults.navigationSuppliersLabel,
  } = translations

  return {
    associationsHeader,
    customersOptionLabel,
    suppliersOptionLabel,
    navigationCustomersLabel,
    navigationSuppliersLabel,
  }
}

const defaultTranslations = {
  associationsHeader: "Associations",
  customersOptionLabel: "Can view name and contact details of these customers",
  suppliersOptionLabel: "Can view name and contact details of these suppliers",
  navigationCustomersLabel: "Can view projects (and other activities) of these customers",
  navigationSuppliersLabel: "Can view projects (and other activities) of these suppliers",
}

const findOption = (options: Option[], id: string) => {
  return options.find((option) => option.id === id)
}

const getVisibleCustomers = (abilitiesInResources: AbilitiesInResources, customerOptions: Option[]) => {
  return abilitiesInResources
    .filter((a) => a.abilities.includes("ReadCustomer"))
    .map((a) => {
      return { id: a.resourceId, name: findOption(customerOptions, a.resourceId)?.name || "" }
    })
}

const getVisibleSuppliers = (abilitiesInResources: AbilitiesInResources, supplierOptions: Option[]) => {
  return abilitiesInResources
    .filter((a) => a.abilities.includes("ReadSupplier"))
    .map((a) => {
      return { id: a.resourceId, name: findOption(supplierOptions, a.resourceId)?.name || "" }
    })
}

const getAssociatedCustomers = (associations: string[], customerOptions: Option[]) => {
  return associations.reduce((acc, association) => {
    const option = findOption(customerOptions, association)
    if (option) acc.push(option)
    return acc
  }, [] as Option[])
}

const getAssociatedSuppliers = (associations: string[], supplierOptions: Option[]) => {
  return associations.reduce((acc, association) => {
    const option = findOption(supplierOptions, association)
    if (option) acc.push(option)
    return acc
  }, [] as Option[])
}

const createReadCustomerAbilityFromOption = (option: Option) => {
  return { resourceId: option.id, abilities: ["ReadCustomer"] }
}

const createReadSupplierAbilityFromOption = (option: Option) => {
  return { resourceId: option.id, abilities: ["ReadSupplier"] }
}

type Translations = typeof defaultTranslations
type Option = { id: string; name: string }
type AbilitiesInResources = Array<{ resourceId: string; abilities: string[] }>
type AssociationsManagerProps = {
  canUpdatePermissions: boolean
  abilities: string[]
  associations: string[]
  abilitiesInResources: AbilitiesInResources
  setAssociations: (associations: string[]) => any
  updateAbilities: (args: { abilitiesToAdd?: string[]; abilitiesToRemove?: string[] }) => any
  setAbilitiesInResources: (abilitiesInResources: AbilitiesInResources) => any
  customerOptions: Option[]
  supplierOptions: Option[]
}

export default AssociationsManager
