import { find, flatten } from "lodash"
import { BoardCardData, BoardData, BoardType } from "../api/board"
import { BoardColumn } from "./column"

export class Board {
  readonly id: string
  columns: BoardColumn[]
  type: BoardType

  static allBoardTypes = ["customer", "member", "project", "supplier", "task", "user", "workspace"] as const

  constructor({ id, type, columns = [] }: Optional<BoardData, "columns">) {
    this.id = id
    this.type = type
    this.columns = columns.map((col) => new BoardColumn({ ...col }))
  }

  getColumnByPosition(position: number): BoardColumn | null {
    return find(this.columns, { position }) || null
  }

  getColumnById(id: string): BoardColumn | null {
    return find(this.columns, { id }) || null
  }

  getVisibleColumns(): BoardColumn[] {
    return this.columns.filter((col) => col.isVisible)
  }

  getNextVisibleColumn(position: number): BoardColumn | null {
    return this.columns.find((col) => col.position > position && col.isVisible) || null
  }

  getPrevVisibleColumn(position: number): BoardColumn | null {
    return (
      this.columns
        .slice()
        .reverse()
        .find((col) => col.position < position && col.isVisible) || null
    )
  }

  getCardById(id: string): (BoardCardData & { column: number }) | null {
    const allCards = flatten(
      this.columns.map((col) => {
        return col.getCards().map((card) => ({ ...card, column: col.position }))
      })
    )
    return allCards.find((card) => card.id === id) || null
  }

  addCard(card: Optional<BoardCardData, "position">, columnPosition: number): void {
    const existingCard = this.getCardById(card.id)
    if (!existingCard) this.getColumnByPosition(columnPosition)?.addCard(card)
  }

  moveCard(cardId: string, fromCol: string, toCol: string, toPos: number): void {
    const origColumn = this.getColumnById(fromCol) as BoardColumn
    const destColumn = this.getColumnById(toCol) as BoardColumn
    const card = origColumn.getCardById(cardId)
    if (!card) throw new Error("Card not found")
    origColumn.removeCard(cardId)
    destColumn.addCard({ id: cardId, type: card.type, position: toPos })
  }

  removeCard(cardId: string): void {
    const card = this.getCardById(cardId)
    if (card) this.getColumnByPosition(card.column)?.removeCard(cardId)
  }

  updateColumn(idOrPosition: number | string, update: { name: string }): void {
    const column =
      typeof idOrPosition === "string" ? this.getColumnById(idOrPosition) : this.getColumnByPosition(idOrPosition)
    column?.setName(update.name)
  }

  toObject(): BoardData {
    return {
      ...this,
      columns: this.columns.map((col) => col.toObject()),
    }
  }
}
