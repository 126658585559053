import { makeStyles, Theme } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import Checkbox from "@material-ui/core/Checkbox"
import Divider from "@material-ui/core/Divider"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import clsx from "clsx"
import { Check } from "react-feather"
import { DateTimeService } from "../../services/date-time-service"
import { StringMap } from "../../types/common"
import DateWidget from "../../components/DateWidget"
import DescriptionEditor from "../../components/DescriptionEditor"
import { useI18n } from "../../hooks"
import { useAuthUser } from "../../users/hooks/use-auth-user"
import TodoResponsibleWidget from "./TodoResponsibleWidget"
import TodoWorkspaceWidget from "./TodoWorkspaceWidget"
import { useOrgOptions } from "../../options/hooks/use-org-options"
import TodoCustomerWidget from "./TodoCustomerWidget"
import { useTodoMutations } from "../hooks/use-todo-mutations"
import { useDeleteTodo } from "../hooks/use-delete-todo"
import { TodoViewModel } from "../api/todo"
import { isTodoDueDatePastDue } from "../utils/is-past-due"
import ColorSelectionWidget from "../../components/ColorSelectionWidget"
import CreationMetaDataWidget from "../../components/CreationMetaDataWidget"
import TodoReminders from "./TodoReminders"
import React from "react"
import { useGetProjectsQuery } from "../../projects/api"
import { adaptProjectToResource, adaptTaskToResource } from "../../components/ResourceManager"
import TodoProjectWidget from "./TodoProjectWidget"
import TodoTaskWidget from "./TodoTaskWidget"
import { useGetTasksQuery } from "../../tasks/api"
import { PROJECT_TYPE } from "../../projects/constants"
import { useIsSmallScreen } from "../../hooks/use-is-small-screen"

const useTodoDetails = ({ todo }: TodoDetailsProps) => {
  const todoActions = useTodoMutations()
  const { deleteTodo } = useDeleteTodo()
  const { dateFormat } = useAuthUser()
  const orgOptions = useOrgOptions(todo?.organisation?.id || "")
  const hasWorkspaceOptions = Boolean(orgOptions?.workspaceOptions?.length)
  const hasCustomerOptions = Boolean(orgOptions?.customerOptions?.length)
  const dateTimeService = new DateTimeService({
    dateFormat,
    enableTimeComponent: todo.enableTimeComponent,
  })
  const format = dateTimeService.format.bind(dateTimeService)

  const projectQuery = React.useMemo(() => {
    if (!todo.organisation) return
    return { type: PROJECT_TYPE.NORMAL, organisation: todo.organisation.id }
  }, [todo.organisation])
  const { data: projects = [] } = useGetProjectsQuery(projectQuery)
  const projectOptions = projects.map((project) => adaptProjectToResource(project))

  const taskQuery = React.useMemo(() => {
    if (!todo.project) return
    return { project: todo.project.id }
  }, [todo.project])
  const { data: tasks = [] } = useGetTasksQuery(taskQuery)
  const taskOptions = tasks.map((task) => adaptTaskToResource(task))

  const saveDueDate = (dueDate: Date | null) => {
    let enableTimeComponent = todo.enableTimeComponent
    if (dueDate === null) enableTimeComponent = false
    if (dueDate && dateTimeService.hasMidnightTime(dueDate)) enableTimeComponent = false
    if (dueDate && !dateTimeService.hasMidnightTime(dueDate)) enableTimeComponent = true
    const updatedDueDate = dueDate ? dueDate.toISOString() : null
    return todoActions.updateInfo(todo.id, { dueDate: updatedDueDate, enableTimeComponent })
  }

  const dueDateIsPastDue = isTodoDueDatePastDue({ todo, dateTimeService })
  const firstDayOfWeek = todo.project ? todo.project.firstDayOfWeek : todo.organisation?.firstDayOfWeek

  return {
    format,
    taskOptions,
    projectOptions,
    hasCustomerOptions,
    hasWorkspaceOptions,
    saveDueDate,
    dueDateIsPastDue,
    firstDayOfWeek,
    deleteTodo,
  }
}

const TodoDetailsMobile = (props: TodoDetailsProps) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const todoActions = useTodoMutations()
  const { todo, boardId } = props
  const {
    format,
    saveDueDate,
    dueDateIsPastDue,
    firstDayOfWeek,
    projectOptions,
    taskOptions,
    hasCustomerOptions,
    hasWorkspaceOptions,
    deleteTodo,
  } = useTodoDetails(props)

  return (
    <>
      <section style={{ marginTop: -20 }}>
        <DescriptionEditor
          description={todo.description || ""}
          onSaveDescription={(description) => todoActions.updateInfo(todo.id, { description })}
          TextAreaAutosizeProps={{
            placeholder: todo.canUpdate
              ? translations.descriptionPlaceholder
              : translations.readOnlyDescriptionPlaceholder,
            disabled: !todo.canUpdate,
          }}
        />
      </section>
      <section>
        <FormControlLabel
          className={classes.checkboxLabel}
          label={
            todo.canUpdate || Boolean(todo.completed) ? translations.completedLabel : translations.notCompletedLabel
          }
          control={
            <Checkbox
              name="todoCompleted"
              icon={<CheckboxIconUnchecked />}
              checkedIcon={<CheckboxIconChecked />}
              checked={Boolean(todo.completed)}
              onChange={(e) => todoActions.updateStatus(todo.id, { completed: e.target.checked, boardId })}
              disabled={!todo.canUpdate}
            />
          }
        />
      </section>
      <section className={classes.mobileSection}>
        <div>
          <DateWidget
            formattedDate={todo.dueDate ? format(todo.dueDate) : null}
            isoDate={todo.dueDate || null}
            onDateChange={saveDueDate}
            isReadOnly={!todo.canUpdate}
            label={translations.dueDateWidgetLabel}
            emptyDateMessage={translations.emptyDueDateMessage}
            clearButtonHint={translations.clearDueDateHint}
            isPastDue={dueDateIsPastDue}
            showTimeSelect={todo.enableTimeComponent}
            isClearable
            {...(firstDayOfWeek && { firstDayOfWeek })}
          />
        </div>
        {Boolean(todo.dueDate) ? (
          <FormControlLabel
            className={classes.checkboxLabel}
            label={translations.showTimeLabel}
            control={
              <Checkbox
                name="wholeDay"
                color="primary"
                checked={todo.enableTimeComponent}
                onChange={(e) => todoActions.updateInfo(todo.id, { enableTimeComponent: e.target.checked })}
                disabled={!todo.canUpdate}
                size="small"
              />
            }
          />
        ) : null}
      </section>

      <section className={classes.mobileSection}>
        <TodoProjectWidget todo={todo} projectOptions={projectOptions} />
      </section>

      {todo.project && (
        <>
          <section className={classes.mobileSection}>
            <TodoTaskWidget todo={todo} taskOptions={taskOptions} />
          </section>
        </>
      )}

      {todo.isOrgTodo && (
        <section className={classes.mobileSection}>
          {hasCustomerOptions && <TodoCustomerWidget todo={todo} boardId={boardId} />}
          <TodoResponsibleWidget todo={todo} boardId={boardId} />
          {hasWorkspaceOptions && <TodoWorkspaceWidget todo={todo} boardId={boardId} />}
        </section>
      )}

      <section>
        <TodoReminders todo={todo} />
      </section>

      <section style={{ marginBottom: 16 }}>
        <ColorSelectionWidget
          selectedColor={todo.ganttBarColor}
          onColorChange={(color) => todoActions.updateInfo(todo.id, { ganttBarColor: color })}
          disabled={!todo.canUpdate}
        />
      </section>

      <Divider />

      {todo.canDelete && (
        <Button
          onClick={() => deleteTodo(todo.id)}
          variant="outlined"
          style={{ marginBottom: 8, marginTop: 16 }}
          fullWidth
        >
          {translations.deleteButtonLabel}
        </Button>
      )}

      <section>
        <CreationMetaDataWidget creatorName={todo.creatorFullname} creationDate={todo.createdAt} />
      </section>
    </>
  )
}

const TodoDetailsDefault = ({ todo, boardId }: TodoDetailsProps) => {
  const classes = useStyles()
  const translations = useTranslations(defaultTranslations)
  const todoActions = useTodoMutations()
  const {
    format,
    saveDueDate,
    dueDateIsPastDue,
    firstDayOfWeek,
    projectOptions,
    taskOptions,
    hasCustomerOptions,
    hasWorkspaceOptions,
    deleteTodo,
  } = useTodoDetails({ todo, boardId })

  return (
    <Grid container spacing={4} wrap="nowrap">
      <Grid item className={classes.main}>
        <DescriptionEditor
          description={todo.description || ""}
          onSaveDescription={(description) => todoActions.updateInfo(todo.id, { description })}
          TextAreaAutosizeProps={{
            placeholder: todo.canUpdate
              ? translations.descriptionPlaceholder
              : translations.readOnlyDescriptionPlaceholder,
            disabled: !todo.canUpdate,
          }}
        />
        {todo.isOrgTodo && (
          <>
            <Grid container spacing={3}>
              {hasCustomerOptions && (
                <Grid item style={{ flex: 1 }}>
                  <TodoCustomerWidget todo={todo} boardId={boardId} />
                </Grid>
              )}
              <Grid item style={{ flex: 1 }}>
                <TodoResponsibleWidget todo={todo} boardId={boardId} />
              </Grid>
            </Grid>
            <Grid container spacing={3}>
              {hasWorkspaceOptions && (
                <Grid item style={{ flex: 1 }}>
                  <TodoWorkspaceWidget todo={todo} boardId={boardId} />
                </Grid>
              )}
            </Grid>
          </>
        )}

        <TodoReminders todo={todo} />

        <section style={{ marginTop: 24 }}>
          <ColorSelectionWidget
            selectedColor={todo.ganttBarColor}
            onColorChange={(color) => todoActions.updateInfo(todo.id, { ganttBarColor: color })}
            disabled={!todo.canUpdate}
          />
        </section>
      </Grid>
      <Grid item className={classes.sidebar}>
        <section className={classes.sidebarWidget}>
          <DateWidget
            formattedDate={todo.dueDate ? format(todo.dueDate) : null}
            isoDate={todo.dueDate || null}
            onDateChange={saveDueDate}
            isReadOnly={!todo.canUpdate}
            label={translations.dueDateWidgetLabel}
            emptyDateMessage={translations.emptyDueDateMessage}
            clearButtonHint={translations.clearDueDateHint}
            isPastDue={dueDateIsPastDue}
            showTimeSelect={todo.enableTimeComponent}
            isClearable
            {...(firstDayOfWeek && { firstDayOfWeek })}
          />
          {Boolean(todo.dueDate) ? (
            <FormControlLabel
              className={classes.checkboxLabel}
              label={translations.showTimeLabel}
              control={
                <Checkbox
                  name="wholeDay"
                  color="primary"
                  checked={todo.enableTimeComponent}
                  onChange={(e) => todoActions.updateInfo(todo.id, { enableTimeComponent: e.target.checked })}
                  disabled={!todo.canUpdate}
                  size="small"
                />
              }
            />
          ) : null}
        </section>
        <Divider />

        <section className={classes.sidebarWidget}>
          <Typography variant="body1" component="h6">
            <strong>{translations.completionStatusWidgetLabel}</strong>
          </Typography>
          <FormControlLabel
            className={classes.checkboxLabel}
            label={
              todo.canUpdate || Boolean(todo.completed) ? translations.completedLabel : translations.notCompletedLabel
            }
            control={
              <Checkbox
                name="todoCompleted"
                icon={<CheckboxIconUnchecked />}
                checkedIcon={<CheckboxIconChecked />}
                checked={Boolean(todo.completed)}
                onChange={(e) => todoActions.updateStatus(todo.id, { completed: e.target.checked, boardId })}
                disabled={!todo.canUpdate}
              />
            }
          />
        </section>
        <Divider />

        <section className={classes.sidebarWidget}>
          <TodoProjectWidget todo={todo} projectOptions={projectOptions} />
        </section>
        <Divider />

        {todo.project && (
          <section className={classes.sidebarWidget}>
            <TodoTaskWidget todo={todo} taskOptions={taskOptions} />
          </section>
        )}

        {todo.canDelete && (
          <Button
            onClick={() => deleteTodo(todo.id)}
            variant="outlined"
            style={{ marginBottom: 8, marginTop: 16 }}
            fullWidth
          >
            {translations.deleteButtonLabel}
          </Button>
        )}

        <section className={classes.sidebarWidget}>
          <CreationMetaDataWidget creatorName={todo.creatorFullname} creationDate={todo.createdAt} />
        </section>
      </Grid>
    </Grid>
  )
}

const CheckboxIconUnchecked = () => {
  const classes = useStyles()
  return (
    <span className={clsx(classes.checkboxIcon, "unchecked")}>
      <Check size={12} />
    </span>
  )
}

const CheckboxIconChecked = () => {
  const classes = useStyles()
  return (
    <span className={clsx(classes.checkboxIcon, "checked")}>
      <Check size={12} />
    </span>
  )
}

const useTranslations = (defaults: TodoDetailsTranslations): TodoDetailsTranslations => {
  const { translations: t } = useI18n("todo")
  const translations = (t?.todoDetails || {}) as StringMap
  const { translations: treegridTranslations } = useI18n("treegrid")
  const completionStatusWidgetLabel = treegridTranslations.statusColumnHeader || defaults.completionStatusWidgetLabel

  const {
    completedLabel = defaults.completedLabel,
    notCompletedLabel = defaults.notCompletedLabel,
    dueDateWidgetLabel = defaults.dueDateWidgetLabel,
    emptyDueDateMessage = defaults.emptyDueDateMessage,
    clearDueDateHint = defaults.clearDueDateHint,
    resourcesSectionHeader = defaults.resourcesSectionHeader,
    actionsSectionHeader = defaults.actionsSectionHeader,
    deleteButtonLabel = defaults.deleteButtonLabel,
    showTimeLabel = defaults.showTimeLabel,
    descriptionPlaceholder = defaults.descriptionPlaceholder,
    readOnlyDescriptionPlaceholder = defaults.readOnlyDescriptionPlaceholder,
  } = translations

  return {
    completionStatusWidgetLabel,
    completedLabel,
    notCompletedLabel,
    dueDateWidgetLabel,
    emptyDueDateMessage,
    clearDueDateHint,
    resourcesSectionHeader,
    actionsSectionHeader,
    deleteButtonLabel,
    showTimeLabel,
    descriptionPlaceholder,
    readOnlyDescriptionPlaceholder,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  main: { flexGrow: 1 },
  sidebar: { minWidth: 300 },
  sidebarWidget: {
    padding: theme.spacing(1.5, 0),
    "&:first-child": {
      marginTop: -theme.spacing(2.5),
    },
  },
  checkboxLabel: {
    marginTop: theme.spacing(),
    "&.Mui-disabled": {
      "& .MuiCheckbox-root": { display: "none" },
      "& .MuiFormControlLabel-label": {
        color: theme.palette.text.primary,
        paddingLeft: theme.spacing(1.5),
        paddingTop: theme.spacing(1.5),
      },
    },
  },
  checkboxIcon: {
    height: 20,
    width: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    zIndex: 9,
    "&.unchecked": {
      border: `1px solid ${theme.palette.common.black}`,
      "& svg": {
        opacity: 0,
        transition: theme.transitions.create("opacity"),
      },
      "&:hover": {
        "& svg": {
          opacity: 1,
        },
      },
    },
    "&.checked": {
      border: `1px solid transparent`,
      backgroundColor: theme.palette.secondary.main,
      transition: theme.transitions.create("background"),
      "& svg": {
        color: theme.palette.common.white,
      },
    },
  },
  mobileSection: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}))

const defaultTranslations = {
  completionStatusWidgetLabel: "Status",
  showTimeLabel: "Show time",
  completedLabel: "Completed",
  notCompletedLabel: "Not Completed",
  dueDateWidgetLabel: "Due date",
  emptyDueDateMessage: "Due date is not given",
  clearDueDateHint: "Clear due date",
  resourcesSectionHeader: "Todo Resources",
  actionsSectionHeader: "Todo actions",
  deleteButtonLabel: "Delete this todo",
  descriptionPlaceholder: "Add a description",
  readOnlyDescriptionPlaceholder: "This todo has no description",
}

export default function TodoDetails(props: TodoDetailsProps) {
  const isSmallScreen = useIsSmallScreen()

  if (!props.todo) return null
  return isSmallScreen ? <TodoDetailsMobile {...props} /> : <TodoDetailsDefault {...props} />
}

type TodoDetailsProps = { todo: TodoViewModel; boardId?: string }
type TodoDetailsTranslations = typeof defaultTranslations
