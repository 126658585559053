import { TreeGridTranslations } from "../hooks/use-translations"

export function makeColumns({ translations }: MakeColumnProps): Column[] {
  const nameColumn = {
    Name: "name",
    MinWidth: 160,
    RelWidth: 1,
    CaseSensitive: 0,
    header: translations.nameColumnHeader,
  }

  const startDateColumn = {
    Name: "startDate",
    Type: "Date",
    header: translations.fromColumnHeader,
    Width: 154,
  }

  const endDateColumn = {
    Name: "endDate",
    Type: "Date",
    header: translations.toColumnHeader,
    Width: 154,
  }

  const type = {
    Name: "type",
    header: "HiddenColumn",
    MinWidth: 120,
    Visible: 0,
  }

  const isRecurringColumn = {
    Name: "isRecurring",
    Type: "Bool",
    header: translations.isRecurringColumnHeader,
    MinWidth: 120,
  }

  const panelColumn = { Name: "Panel", CanHide: 0 }

  return [panelColumn, nameColumn, startDateColumn, endDateColumn, isRecurringColumn, type]
}

type MakeColumnProps = {
  translations: TreeGridTranslations
}

type Column = Partial<TCol> & {
  header?: string
}
