import React from "react"
import clsx from "clsx"
import Typography, { TypographyProps } from "@material-ui/core/Typography"
import autosize from "autosize"

import { COLOR_LIGHT } from "../../constants"
import { makeStyles, Theme } from "@material-ui/core"

const InlineEditableText = ({
  placeholder,
  value,
  onSave,
  isEditable = true,
  isMultiline = false,
  TypographyProps = {},
  autoFocus = false,
  autoSelect = false,
}: InlineEditableTextProps) => {
  const [inputValue, setInputValue] = React.useState(value)
  const [isEditing, setIsEditing] = React.useState(autoFocus)
  const classes = useStyles()

  React.useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value
    setInputValue(newValue)
    autosize(e.currentTarget)
  }

  const handleClickOnTypography = (e: any) => {
    if (isEditable) {
      setIsEditing(true)
      const textareaEl = e.target.nextElementSibling
      delayedFocusTextArea(textareaEl)
    }
  }

  const handleBlur = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    setIsEditing(false)
    if (inputValue !== value) onSave(inputValue)
  }

  const handleKeyUpOnInput = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      const inputEl = e.target as HTMLTextAreaElement
      inputEl.blur()
    }
  }

  const handleKeyDownOnInput = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" || e.keyCode === 13) {
      e.preventDefault()
    }
  }

  const handleKeyUpOnTypography = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      if (isEditable) setIsEditing(true)
    }
  }

  const onFocusTextArea = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    autoSelect && e.currentTarget.select()
    autosize(e.currentTarget)
  }

  return (
    <Typography
      variant="body1"
      {...TypographyProps}
      className={clsx(classes.container, TypographyProps?.className || "")}
    >
      <span
        className={clsx(
          classes.typography,
          classes.text,
          isEditable ? "" : "disabled",
          isEditing ? classes.displayNone : classes.displayBlock
        )}
        tabIndex={0}
        onClick={handleClickOnTypography}
        onKeyUp={handleKeyUpOnTypography}
      >
        {inputValue || placeholder}
      </span>
      <textarea
        placeholder={placeholder}
        rows={isMultiline ? undefined : 1}
        value={inputValue}
        onChange={handleChange}
        className={clsx(classes.typography, classes.textarea, isEditing ? classes.displayBlock : classes.displayNone)}
        onBlur={handleBlur}
        onKeyUp={isMultiline ? undefined : handleKeyUpOnInput}
        onKeyDown={isMultiline ? undefined : handleKeyDownOnInput}
        onFocus={onFocusTextArea}
        autoFocus={autoFocus}
      />
    </Typography>
  )
}

function delayedFocusTextArea(el: HTMLTextAreaElement): void {
  setTimeout(() => {
    el.focus()
  }, 0)
}

const useStyles = makeStyles((theme: Theme) => ({
  displayBlock: { display: "block" },
  displayNone: { display: "none" },
  container: { padding: theme.spacing(1) },
  typography: {
    padding: theme.spacing(0.5),
    width: "100%",
    maxWidth: 350,
    border: `2px solid transparent`,
    borderRadius: theme.spacing(0.5),
    whiteSpace: "pre-line",
    color: "inherit",
    fontFamily: "inherit",
    fontSize: "inherit",
    fontWeight: "inherit",
    lineHeight: "inherit",
    letterSpacing: "inherit",
    outline: 0,
    boxShadow: "none",
  },
  text: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    "&.disabled:hover": {
      border: `2px solid transparent`,
    },
    "&:hover": {
      border: `2px solid ${theme.palette.divider}`,
    },
  },
  textarea: {
    width: "100%",
    resize: "none",
    overflow: "hidden",
    overflowWrap: "break-word",
    transition: theme.transitions.create(["background", "border-color"]),
    background: COLOR_LIGHT,
    "&:focus": {
      border: `2px solid ${theme.palette.info.main}`,
    },
  },
}))

type InlineEditableTextProps = React.PropsWithoutRef<{
  value: string
  onSave: (value: string) => unknown
  isEditable?: boolean
  isMultiline?: boolean
  TypographyProps?: TypographyProps
  placeholder?: string
  autoFocus?: boolean
  autoSelect?: boolean
}>

export default InlineEditableText
