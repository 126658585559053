import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import React from "react"
import { Theme, makeStyles } from "@material-ui/core"
import { ChevronDown } from "react-feather"

const AssociationsAndPermissionsManagerAccordion = ({
  title,
  children,
}: React.PropsWithChildren<{ title: string }>) => {
  const classes = useStyles()

  if (!children) return null

  return (
    <Accordion className={classes.accordionRoot}>
      <AccordionSummary className={classes.accordionSummary} expandIcon={<ChevronDown />}>
        <Typography className={classes.accordionHeader}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Container maxWidth={false} disableGutters>
          {children}
        </Container>
      </AccordionDetails>
    </Accordion>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  accordionRoot: {
    width: "100%",
    boxShadow: "none",
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": { borderBottom: 0 },
    "&.Mui-expanded": { borderBottom: `1px solid ${theme.palette.divider}` },
  },
  accordionSummary: {
    "& .MuiIconButton-root": { color: theme.palette.primary.main },
  },
  accordionHeader: {
    color: theme.palette.primary.main,
    fontSize: 14,
    fontWeight: 500,
  },
}))

export default AssociationsAndPermissionsManagerAccordion
