import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardMedia from "@material-ui/core/CardMedia"
import Typography from "@material-ui/core/Typography"
import { ArrowLeft } from "react-feather"
import { Link } from "react-router-dom"
import { paths } from "../../paths"
import { useUrlWithContext } from "../../hooks/use-url-with-context"

const SupplierNotFound = () => {
  const { createPathWithContext } = useUrlWithContext()

  return (
    <Box p={2} maxWidth="780px" margin="auto">
      <Card variant="outlined">
        <Box py={6} display="flex" alignItems="space-around" flexDirection="column">
          <Box display="flex" justifyContent="space-around">
            <CardMedia
              component="img"
              image="/not-found.svg"
              alt="no supplier"
              style={{ display: "block", width: 340 }}
            />
          </Box>
          <Box my={6} display="flex" justifyContent="center">
            <Typography variant="h4">Oops! Supplier not found 😞</Typography>
          </Box>
          <Box display="flex" justifyContent="center" mb={6}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              startIcon={<ArrowLeft size={16} />}
              to={createPathWithContext(paths.orgSuppliers())}
            >
              Go to all suppliers
            </Button>
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default SupplierNotFound
