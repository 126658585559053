import DatePicker, { DatePickerProps } from "./DatePicker"
import Typography from "@material-ui/core/Typography"
import { Calendar } from "react-feather"
import { useAuthUser } from "../../users/hooks/use-auth-user"
import { DateTimeService } from "../../services/date-time-service"
import { useDateFormat } from "../../users/hooks/use-date-format"

const DateWidget = (props: DateWidgetProps) => {
  const {
    isClearable,
    label,
    isReadOnly,
    isoDate,
    onDateChange,
    clearButtonHint,
    calendarStartDay,
    showTimeSelect = false,
    isPastDue = false,
  } = props

  /** Date manipulation based on the selected timezone */
  const { user } = useAuthUser()
  const { dateFormat } = useDateFormat()
  const timeZone = user?.timeZone
  const dateTimeService = new DateTimeService({
    timeZone,
    dateFormat,
    enableTimeComponent: showTimeSelect,
  })
  const selectedDate = isoDate ? dateTimeService.removeTimezoneOffset(isoDate) : null
  const formattedDate = selectedDate ? dateTimeService.format(selectedDate) : null
  const handleDateChange = (date: Date | [Date, Date] | null) => {
    if (timeZone !== dateTimeService.getOsTimeZone() && date) {
      const modifiedDate = dateTimeService.addTimezoneOffset(date as Date)
      onDateChange(modifiedDate)
    } else if (isClearable) {
      onDateChange(date as Date)
    } else if (date) {
      onDateChange(date as Date)
    }
  }
  /*************************************************** */

  const datePickerProps = isClearable
    ? ({
        isClearable,
        label: formattedDate ? formattedDate : label,
        formattedDate,
        onChange: handleDateChange,
        clearButtonHint,
        calendarStartDay: calendarStartDay || user?.firstDayOfWeek,
        selected: selectedDate,
        showTimeSelect,
        timeZone,
      } as DatePickerProps)
    : ({
        isClearable,
        label: formattedDate,
        formattedDate,
        onChange: handleDateChange,
        calendarStartDay: calendarStartDay || user?.firstDayOfWeek,
        selected: selectedDate,
        showTimeSelect,
        timeZone,
      } as DatePickerProps)

  const typographyProps = isPastDue
    ? {
        color: "error",
      }
    : ({} as any)

  if (isReadOnly && formattedDate) {
    return (
      <>
        <Typography variant="body1" style={{ marginBottom: 12 }}>
          <strong>{label}</strong>
        </Typography>
        <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
          <Calendar size={18} />
          <Typography variant="body1" {...typographyProps}>
            {formattedDate}
          </Typography>
        </div>
      </>
    )
  }
  if (isClearable && isReadOnly && !formattedDate) {
    return (
      <>
        <Typography variant="body1" style={{ marginBottom: 12 }}>
          <strong>{label}</strong>
        </Typography>
        <Typography variant="body1">{props.emptyDateMessage}</Typography>
      </>
    )
  }

  if (!isReadOnly && formattedDate) {
    return (
      <>
        <Typography variant="body1" style={{ marginBottom: 4 }}>
          <strong>{label}</strong>
        </Typography>
        <Typography component="div" variant="body1" {...typographyProps}>
          <DatePicker {...datePickerProps} />
        </Typography>
      </>
    )
  }

  if (!isReadOnly && !formattedDate) {
    return (
      <Typography component="div" variant="body1" {...typographyProps}>
        <DatePicker {...datePickerProps} />
      </Typography>
    )
  }

  return <div>Error</div>
}

export default DateWidget

type ClearableDateWidgetProps = {
  isClearable: true
  isReadOnly: boolean
  label: string
  formattedDate: string | null
  isoDate: string | null
  onDateChange: (date: Date | null) => void
  emptyDateMessage: string
  clearButtonHint: string
  calendarStartDay?: number
  showTimeSelect?: boolean
  isPastDue?: boolean
}

type UnclearableDateWidgetProps = {
  isClearable: false
  isReadOnly: boolean
  label: string
  formattedDate: string
  isoDate: string
  onDateChange: (date: Date) => void
  calendarStartDay?: number
  showTimeSelect?: boolean
  isPastDue?: boolean
}

type DateWidgetProps = XOR<ClearableDateWidgetProps, UnclearableDateWidgetProps>
