import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import InputLabel from "@material-ui/core/InputLabel"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import React from "react"
import { MembershipViewModel } from "../../memberships/api/membership"
import { useOrgMemberships } from "../../memberships/hooks/use-org-memberships"
import { useTranslations } from "../hooks/use-translations"
import { getInvitationStatusText, getRoleTranslationKey } from "../utils"
import { alpha, makeStyles } from "@material-ui/core"
import { useUrlWithContext } from "../../hooks/use-url-with-context"
import { paths } from "../../paths"
import { Link } from "react-router-dom"
import { Settings } from "react-feather"

const OrgMembershipCards = ({ orgId }: { orgId: string }) => {
  const translations = useTranslations()
  const { activeMemberships, inactiveMemberships } = useOrgMemberships(orgId)
  const [filter, setFilter] = React.useState("active")
  const classes = useStyles()

  const filteredMemberships =
    filter === "active"
      ? activeMemberships
      : filter === "inactive"
      ? inactiveMemberships
      : [activeMemberships, inactiveMemberships].flat()

  return (
    <div style={{ marginTop: 24 }}>
      <InputLabel className={classes.filterLabel} id="filter">
        {translations.filter}
      </InputLabel>
      <Select
        labelId="filter"
        id="filter-select"
        className={classes.filter}
        value={filter}
        onChange={(e) => setFilter(e.target.value as string)}
        variant="outlined"
      >
        <MenuItem value="all">
          <em>{translations.all}</em>
        </MenuItem>
        <MenuItem value={"active"}>{translations.activeMembershipsOption}</MenuItem>
        <MenuItem value={"inactive"}>{translations.inactiveMembershipsOption}</MenuItem>
      </Select>
      {filteredMemberships.map((membership) => (
        <OrgMembershipCard key={membership.id} membership={membership} />
      ))}
      {filteredMemberships.length === 0 && (
        <div className={classes.emptyMessage}>
          <Typography variant="body2" color="textSecondary">
            {translations.noData}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default OrgMembershipCards

const OrgMembershipCard = ({ membership }: { membership: MembershipViewModel }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const { createPathWithGivenContext } = useUrlWithContext()

  const membershipHomePath = createPathWithGivenContext({
    path: paths.home(),
    mainContextId: membership.orgId,
    subContextId: membership.id,
  })

  const membershipSettingsPath = createPathWithGivenContext({
    path: paths.membershipSettings(),
    mainContextId: membership.orgId,
    subContextId: membership.id,
  })

  return (
    <Paper variant="outlined" className={classes.card}>
      <Typography variant="subtitle1" component={Link} to={membershipHomePath}>
        {membership.fullname}
      </Typography>
      <Typography variant="body2" component="p" color="textSecondary">
        {membership.userEmail}
      </Typography>
      <br />
      <Typography variant="body2" component="p" className="text">
        {translations.roleHeaderText}:&nbsp;
        {membership.isConnectedToUser
          ? translations[getRoleTranslationKey(membership.role)]
          : translations.noAccessText}
      </Typography>
      <Typography variant="body2" component="p" className="text">
        {translations.invitationStatusHeaderText}: {getInvitationStatusText(membership.invitationStatus, translations)}
      </Typography>
      <Button
        color="primary"
        className={classes.settingsButton}
        component={Link}
        to={membershipSettingsPath}
        size="small"
        startIcon={<Settings size={14} />}
      >
        {translations.settings}
      </Button>
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2),
    margin: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
      textDecoration: "none",
    },
    "& .text": {
      marginBottom: theme.spacing(0.5),
    },
  },
  settingsButton: {
    marginTop: theme.spacing(1),
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  filter: {
    minWidth: 120,
    width: "calc(100% - 24px)",
    marginLeft: theme.spacing(1.5),
  },
  filterLabel: {
    marginLeft: theme.spacing(2),
  },
  emptyMessage: {
    marginTop: theme.spacing(4),
    textAlign: "center",
  },
}))
