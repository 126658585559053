import React from "react"
import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import ListItem from "@material-ui/core/ListItem"
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction"
import ListItemText from "@material-ui/core/ListItemText"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import { makeStyles, Theme } from "@material-ui/core/styles"
import { ChevronDown, ChevronLeft, ChevronRight, Eye, EyeOff } from "react-feather"
import { BoardPopulated, ColumnPopulated } from "../api/board"
import { useBoardMutations } from "../hooks/use-board-mutations"
import { getTranslatedDefaultColumnNames } from "../utils"
import { COLOR_BLACK } from "../../constants"
import { useTranslations } from "../hooks/use-translations"

const BoardColumnNav = (props: BoardColumnNavProps) => {
  const classes = useStyles()

  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item style={{ width: 48, marginLeft: 4 }}>
        <IconButton edge="start" className={classes.arrowIconButton} onClick={props.showPrevColumn}>
          <ChevronLeft />
        </IconButton>
      </Grid>
      <Grid item xs={6} container justifyContent="center" alignItems="center">
        <ColumnMenu {...props} />
      </Grid>
      <Grid item style={{ width: 48, marginRight: 6 }}>
        <IconButton edge="end" className={classes.arrowIconButton} onClick={props.showNextColumn}>
          <ChevronRight />
        </IconButton>
      </Grid>
    </Grid>
  )
}

const ColumnMenu = ({ board, selectedColumn, showSelectedColumn }: BoardColumnNavProps) => {
  const { updateColumn } = useBoardMutations()
  const translations = useTranslations()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)

  const columnMenuItems = board.columns.allIds.map((columnId) => {
    const column = board.columns.byId[columnId]
    const columnIndex = board.columns.orderedVisibleColumnIds.indexOf(columnId)

    return {
      id: columnId,
      isOn: column.isVisible,
      primaryText: column.name || getTranslatedDefaultColumnNames({ columnName: column.defaultName, translations }),
      toggleColumnVisibility: () => updateColumn({ boardId: board.id, columnId, isVisible: !column.isVisible }),
      showColumn: () => {
        showSelectedColumn(columnIndex)
        handleClose()
      },
    }
  })

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        variant="outlined"
        size="large"
        endIcon={<ChevronDown size={20} />}
        fullWidth
      >
        {selectedColumn.name}
      </Button>
      <Menu id="simple-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {columnMenuItems.map((menuItem) => {
          return (
            <ListItem key={menuItem.id} button onClick={menuItem.showColumn} dense>
              <ListItemText primary={menuItem.primaryText} />
              <ListItemSecondaryAction>
                <IconButton onClick={menuItem.toggleColumnVisibility}>
                  {menuItem.isOn && <Eye color={COLOR_BLACK} size={20} />}
                  {!menuItem.isOn && <EyeOff color={COLOR_BLACK} size={20} />}
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          )
        })}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  arrowIconButton: {
    color: theme.palette.common.white,
    borderRadius: 4,
    paddingTop: 7,
    paddingBottom: 7,
    margin: 0,
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}))

export default BoardColumnNav

type BoardColumnNavProps = {
  board: BoardPopulated
  selectedColumn: ColumnPopulated
  showNextColumn: () => void
  showPrevColumn: () => void
  showSelectedColumn: (index: number) => void
}
