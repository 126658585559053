import React from "react"
import ReactDOM from "react-dom"
import { registerGlobalEvents } from "./global-events"
import AppBackdrop from "./components/AppBackdrop"
import CssBaseline from "@material-ui/core/CssBaseline"
import App from "./App"
import theme from "./constants/theme"
import store from "./store"

import { Suspense } from "react"
import { Provider as StoreProvider } from "react-redux"
import { ThemeProvider } from "@material-ui/core/styles"
import { SnackbarProvider } from "notistack"
import { ConfirmDialogProvider } from "./components/ConfirmDialog"
import { initI18n } from "./i18n"
import { EventSourcePolyfill } from "event-source-polyfill"

import "./assets/styles"

// @ts-ignore
window.EventSourcePolyfill = EventSourcePolyfill

/** Global events registration */
registerGlobalEvents()

/** Initializations */
initI18n()

// Remove loading screen
const loadingEl = document.getElementById("loading") as HTMLDivElement
loadingEl.innerHTML = "<span></span>"

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<AppBackdrop />}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: "bottom", horizontal: "center" }} preventDuplicate>
          <ConfirmDialogProvider>
            <CssBaseline>
              <StoreProvider store={store}>
                <App />
              </StoreProvider>
            </CssBaseline>
          </ConfirmDialogProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals()
