import * as actions from "./actions"
import * as orgActions from "../../organisations/store/actions"
import { createSlice } from "@reduxjs/toolkit"
import { IWorkspaceNormalized } from "../interfaces/workspace-normalized"
import { setWorkspaceCase, setWorkspacesCase, deleteOrganisationCase } from "./case-reducers"

const initialState: WorkspacesState = {
  byIds: {},
  allIds: [],
}

export const workspacesSlice = createSlice({
  name: "workspaces",
  initialState,
  reducers: {
    SetWorkspace: setWorkspaceCase,
    SetWorkspaces: setWorkspacesCase,
  },
  extraReducers({ addCase }) {
    addCase(actions.CreateWorkspace.fulfilled, setWorkspaceCase)
    addCase(actions.FetchWorkspaceById.fulfilled, setWorkspaceCase)
    addCase(actions.FetchWorkspaces.fulfilled, setWorkspacesCase)
    addCase(actions.AddWorkspaceManager.fulfilled, setWorkspaceCase)
    addCase(actions.AddWorkspaceMember.fulfilled, setWorkspaceCase)
    addCase(actions.RemoveWorkspaceManager.fulfilled, setWorkspaceCase)
    addCase(actions.RemoveWorkspaceMember.fulfilled, setWorkspaceCase)
    addCase(actions.UpdateWorkspaceInfo.fulfilled, setWorkspaceCase)
    addCase(actions.UpdateWorkspaceStatus.fulfilled, setWorkspaceCase)
    addCase(orgActions.DeleteOrganisation.fulfilled, deleteOrganisationCase)
  },
})

export const { SetWorkspace, SetWorkspaces } = workspacesSlice.actions
export default workspacesSlice.reducer

export type WorkspacesState = {
  byIds: { [key: string]: IWorkspaceNormalized }
  allIds: string[]
}
