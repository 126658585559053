import { makeStyles, TextField, Theme, Typography } from "@material-ui/core"
import { useI18n } from "../../hooks"
import { ProjectViewModel } from "../api/project"
import { useState } from "react"
import { useProjectMutations } from "../hooks/use-project-mutations"

const ProjectCompletionPercentageWidget = ({ project }: ProjectCompletionPercentageWidgetProps) => {
  const translations = useTranslations()
  const classes = useStyles()
  const { updateProjectCompletionPercentage } = useProjectMutations()
  const canUpdate = project.canUpdateStatus

  const [projectCompletionPercentage, setProjectCompletionPercentage] = useState<number>(project.completionPercentage)
  const handleChangeCompletionPercentage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const parsedValue = value ? parseInt(value) : 0
    const modifiedValue = parsedValue > 100 ? 100 : parsedValue < 0 ? 0 : parsedValue
    setProjectCompletionPercentage(modifiedValue)
  }

  const saveUpdatedCompletionPercentage = () => {
    if (projectCompletionPercentage !== project.completionPercentage) {
      updateProjectCompletionPercentage(project.id, projectCompletionPercentage)
    }
  }

  return (
    <div>
      <Typography variant="body1" style={{ marginBottom: 4 }}>
        <strong>{translations.completionHeader}</strong>
      </Typography>
      <section style={{ paddingTop: 8 }}>
        {canUpdate ? (
          <>
            <Typography variant="body1" component={"span"}>
              {translations.manuallyUpdatedLabel}
              {": "}
            </Typography>
            <TextField
              size="small"
              type="number"
              variant="outlined"
              value={projectCompletionPercentage}
              onChange={handleChangeCompletionPercentage}
              onBlur={saveUpdatedCompletionPercentage}
              InputProps={{ inputProps: { min: 0, max: 100 } }}
              className={classes.input}
            />
            {"%"}
          </>
        ) : (
          <Typography variant="body1">{`${translations.manuallyUpdatedLabel}: ${projectCompletionPercentage}%`}</Typography>
        )}
      </section>
      <section style={{ paddingTop: 8 }}>
        <Typography variant="body1">{`${translations.basedOnTasksLabel}: ${project.completionPercentageBasedOnTasks}%`}</Typography>
      </section>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  input: {
    maxWidth: 56,
    margin: theme.spacing(0, 1),
    "& input": {
      height: 8,
      padding: theme.spacing(1.25, 0.5, 1.25, 1),
    },
  },
}))

const defaultTranslations = {
  completionHeader: "Completion",
  manuallyUpdatedLabel: "Manually updated",
  basedOnTasksLabel: "Based on tasks",
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n("translation")
  const { translations: treegridTranslations } = useI18n("treegrid")
  const completionHeader = treegridTranslations.completionColumnHeader || defaults.completionHeader
  const { manuallyUpdatedLabel = defaults.manuallyUpdatedLabel, basedOnTasksLabel = defaults.basedOnTasksLabel } =
    t || {}

  return {
    completionHeader,
    manuallyUpdatedLabel,
    basedOnTasksLabel,
  }
}

export default ProjectCompletionPercentageWidget
type ProjectCompletionPercentageWidgetProps = {
  project: ProjectViewModel
}
type Translations = typeof defaultTranslations
