import { cloneDeep } from "lodash"
import { TodoViewModel } from "../api/todo"

const getTime = (date: string | undefined) => (date ? new Date(date).getTime() : Infinity)

export const sortTodosByDueDateAndCompleted = (todos: TodoViewModel[]): TodoViewModel[] => {
  const cloned = cloneDeep(todos)
  return cloned.sort((todoA, todoB) => {
    const dueDateA = todoA.isCompleted ? Infinity : getTime(todoA.dueDate ?? undefined)
    const dueDateB = todoB.isCompleted ? Infinity : getTime(todoB.dueDate ?? undefined)

    const areBothCompleted = todoA.isCompleted && todoB.isCompleted
    const areBothNotCompleted = !todoA.isCompleted && !todoB.isCompleted

    if (areBothCompleted) return getTime(todoA.updatedAt) - getTime(todoB.updatedAt) // Sort latest updated to top
    if (areBothNotCompleted) return dueDateA - dueDateB // Sort by due date
    return todoA.isCompleted ? 1 : -1 // Sort completed to bottom
  })
}
